import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { creaNuovoUtenteEsterno } from '../../../actions/utenteEsterno';




const NuovoUtenteEsterno = ({ alert, creaNuovoUtenteEsterno, setOpenNuovoUtenteEsterno }) => {


    useEffect(() => {
        return () => {
            setDatiUtenteEsterno(utenteEsternoInitialState)
        }
    }, []);

    useEffect(() => {
        const size = alert.length;
        if (size > 0) {
            if (alert[size - 1].alertType === "success") {
                setOpenNuovoUtenteEsterno(false);
                setDatiUtenteEsterno(utenteEsternoInitialState);
            }
        }
    }, [alert]);

    const utenteEsternoInitialState = {
        nome: "",
        cognome: "",
        username: "",
        password: ""
    }

    const [datiUtenteEsterno, setDatiUtenteEsterno] = useState(utenteEsternoInitialState);


    const {
        nome,
        cognome,
        username,
        password
    } = datiUtenteEsterno;


    const onChangesetDatiUtenteEsterno = e => setDatiUtenteEsterno({ ...datiUtenteEsterno, [e.target.name]: e.target.value });



    const onSubmitCreaUtenteEsterno = async e => {
        e.preventDefault();
        creaNuovoUtenteEsterno({ nome, cognome, username, password })
    };


    return (
        <div className="dati_container modifica_dipendente">
            <div className="fun_assegnazione_incarichi">
                <div className="row row_datapicker">
                    <div className="item_2">
                        <h4>Nuovo utente esterno</h4>
                        <div>
                            <div className="w-form">
                                <form id="nuovo_dipendente" name="email-form-2" data-name="Email Form 2" method="get" aria-label="Email Form 2">
                                    <div className="div_wrap_100">
                                        <div className="div_wrap_25">
                                            <div className="col_input">
                                                <label htmlFor="name">Nome</label>
                                                <input type="text" className="input w-input" maxLength="256" name="nome" value={nome} data-name="nome" placeholder="Inserisci nome" id="nome" onChange={e => onChangesetDatiUtenteEsterno(e)} />
                                            </div>
                                        </div>
                                        <div className="div_wrap_25">
                                            <div className="col_input">
                                                <label htmlFor="name">Cognome</label>
                                                <input type="text" className="input w-input" maxLength="256" name="cognome" value={cognome} data-name="cognome" placeholder="Inserisci cognome" id="cognome" onChange={e => onChangesetDatiUtenteEsterno(e)} />
                                            </div>
                                        </div>
                                        <div className="div_wrap_25">
                                            <div className="col_input">
                                                <label htmlFor="name">Username</label>
                                                <input type="text" className="input w-input" maxLength="256" name="username" value={username} data-name="username" required="true" placeholder="Inserisci username" id="username" onChange={e => onChangesetDatiUtenteEsterno(e)} />
                                            </div>
                                        </div>
                                        <div className="div_wrap_25">
                                            <div className="col_input">
                                                <label htmlFor="name">Password</label>
                                                <input type="text" className="input w-input" maxLength="256" name="password" value={password} data-name="password" required="true" placeholder="Inserisci password" id="password" onChange={e => onChangesetDatiUtenteEsterno(e)} />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="item_4 m_top_5">
                        <div className="item_5_function">
                            <div id="w-node-d27a7e1c-7034-f43d-edeb-5fcab2bbc790-b8dac9c0" className="div_item_empty"></div>
                            <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc40-b8dac9c0" className="div_item_empty"></div>
                            <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc40-b8dac9c0" className="div_item_empty"></div>
                            <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc43-b8dac9c0" className="div_item_button">
                                <a id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc44-b8dac9c0" onClick={() => setOpenNuovoUtenteEsterno(false)} className="button w-button">Chiudi</a>
                            </div>
                            <div id="w-node-d409c487-9a0c-6b1f-803c-c7c11a8afa3e-b8dac9c0" className="div_item_button">
                                <a onClick={(e) => onSubmitCreaUtenteEsterno(e)} className="button w-button">Salva</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

NuovoUtenteEsterno.protoTypes = {
    creaNuovoUtenteEsterno: PropTypes.func.isRequired,
    alert: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    alert: state.alert
});

export default connect(mapStateToProps, { creaNuovoUtenteEsterno })(NuovoUtenteEsterno);