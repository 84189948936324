import React from 'react';

const Topbar = () => {

	return (
		<div class="footer"><p>Software sviluppato da 22AM Digital Studio © 2022 - v.1 ASSISTENZA Tecnica - Andrea tel: +39 351 625 6022</p></div>
	)
}

export default (Topbar);

