import React from 'react';

const TopbarVuota = ({}) => {
	

	return (
		<div className="header wf-section">
			<div data-animation="default" data-collapse="medium" data-duration="400" data-easing="ease" data-easing2="ease" role="banner" className="navbar w-nav">
				<div className="menu_header w-container">
					<a href="/" className="brand w-nav-brand">
						<img src="" loading="lazy" alt="" className="logo_header" /></a>
					<div className="menu-button w-nav-button">
						<div className="w-icon-nav-menu"></div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default TopbarVuota;

