import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import DatePicker from 'react-date-picker';
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import endOfMonth from "date-fns/endOfMonth";
import Moment from 'react-moment'
import Spinner from '../../layout/Spinner';
import { useReactToPrint } from 'react-to-print';
import { v4 as uuid } from 'uuid';
import ColonneFatturazioneGPG from './ColonneFatturazioneGPG'
import ColonneFatturazioneOTS from './ColonneFatturazioneOTS'
import disponibilitaJSON from "../../../utils/disponibilita.json"
import serviziEffJSON from "../../../utils/serviziEff.json"
import indennitaDiFunzioneJSON from "../../../utils/indennitaDiFunzione.json"
import { getIncarichiDipendente, getDipendente, salvaPDFIncarichiDipendente } from '../../../actions/dipendente';
const NuovoPDFIncarichi = ({ auth: { user }, dipendente: { incarichiDipendente, incarichiDipendenteSalvati }, alert, getIncarichiDipendente, idDipendente, setOpenIncarichiDipendente, getDipendente, salvaPDFIncarichiDipendente, dipendenteData, dataInizioInput, dataFineInput, setDataInizioInput, setDataFineInput }) => {
    const minuti22 = 22 * 60;
    const minuti06 = 6 * 60;
    const minuti24 = 24 * 60;
    const minuti16 = 16 * 60;

    const arrayIdMansioniIndennitaDiFunzione = ["7", "8", "9"];
    const initialStateIndennita = [
        { codice: "051", nome: "MAGG. FEST. LAVORATA", automatica: true, modalita: "giorni", oreGiorni: 0, importo: "", annotazioni: "" },
        { codice: "054", nome: "PREMIO DI RISULTATO", automatica: false, modalita: "importo", oreGiorni: 0, importo: "", annotazioni: "" },
        { codice: "063", nome: "IND. RISCHIO PIANT.FISSO", automatica: true, modalita: "giorni", oreGiorni: 0, importo: "", annotazioni: "" },
        { codice: "068", nome: "PREMIO PRODUZIONE", automatica: false, modalita: "importo", oreGiorni: 0, importo: "", annotazioni: "" },
        { codice: "070", nome: "RIMBORSO NOTE SPESE", automatica: false, modalita: "importo", oreGiorni: 0, importo: "", annotazioni: "" },
        { codice: "071", nome: "IND. PRESENZA IMPIEGATI VDOSV", automatica: true, modalita: "giorni", oreGiorni: 0, importo: "", annotazioni: "" },
        { codice: "080", nome: "REC.RIMB NOTE SPESE", automatica: false, modalita: "importo", oreGiorni: 0, importo: "", annotazioni: "" },
        { codice: "084", nome: "ORE MULTA no inps x VDOSV", automatica: false, modalita: "ore", oreGiorni: 0, importo: "", annotazioni: "" },
        { codice: "094", nome: "IND. LAV. NOTT. Z. T. SV.", automatica: true, modalita: "giorni", oreGiorni: 0, importo: "", annotazioni: "" },
        { codice: "096", nome: "IND. LAV. NOTT. P.F.", automatica: true, modalita: "giorni", oreGiorni: 0, importo: "", annotazioni: "" },
        { codice: "101", nome: "FEST. NON GODUTA", automatica: true, modalita: "giorni", oreGiorni: 0, importo: "", annotazioni: "" },
        { codice: "104", nome: "IND. RISCHIO S. TV. AR", automatica: true, modalita: "giorni", oreGiorni: 0, importo: "", annotazioni: "" },
        { codice: "112", nome: "MAGG.RIP, SPOST. 30%", automatica: false, modalita: "giorni", oreGiorni: 0, importo: "", annotazioni: "" },
        { codice: "113", nome: "MAGG. RIP. SPOST. 40%", automatica: true, modalita: "giorni", oreGiorni: 0, importo: "", annotazioni: "" },
        { codice: "114", nome: "MAGG. RIP. SPOST. 30% Decimi", automatica: true, modalita: "giorni", oreGiorni: 0, importo: "", annotazioni: "" },
        { codice: "131", nome: "REPERIBILITA ", automatica: false, modalita: "ore", oreGiorni: 0, importo: "", annotazioni: "" },
        //{ codice: "135", nome: "PROVVIGIONI", automatica: false, modalita: "importo", oreGiorni: 0, importo: "", annotazioni: "" },
        { codice: "140", nome: "ORE VISITA MEDICA", automatica: false, modalita: "ore", oreGiorni: 0, importo: "", annotazioni: "" },
        { codice: "141", nome: "ORE TIRO A SEGNO", automatica: true, modalita: "ore", oreGiorni: 0, importo: "", annotazioni: "" },
        { codice: "152", nome: "RIM. SPESE RINNOVO PORTO D'ARMI", automatica: false, modalita: "importo", oreGiorni: 0, importo: "", annotazioni: "" },
        { codice: "166", nome: "Indennita Capo macchina", automatica: false, modalita: "ore", oreGiorni: 0, importo: "", annotazioni: "" },
        { codice: "168", nome: "ORE MULTA per Imperia o Rapallo", automatica: false, modalita: "ore", oreGiorni: 0, importo: "", annotazioni: "" },
        { codice: "175", nome: "ORE/GG NON LAVORATI", automatica: true, modalita: "ore", oreGiorni: 0, importo: "", annotazioni: "" },
        //{ codice: "179", nome: "REC.H.ATT.FORM.154 MAR", automatica: false, modalita: "ore", oreGiorni: 0, importo: "", annotazioni: "" },
        //{ codice: "204", nome: "IND.RISCHIO P.FISSO", automatica: true, modalita: "giorni", oreGiorni: 0, importo: "", annotazioni: "" },
        { codice: "211", nome: "IND.RISCHIO P.FISSO", automatica: true, modalita: "giorni", oreGiorni: 0, importo: "", annotazioni: "" },
        { codice: "212", nome: "IND. RISCHIO S. TV. AR.", automatica: true, modalita: "giorni", oreGiorni: 0, importo: "", annotazioni: "" },
        { codice: "213", nome: "IND. LAV. NOTT. P.F.", automatica: true, modalita: "giorni", oreGiorni: 0, importo: "", annotazioni: "" },
        { codice: "214", nome: "IND. LAV. NOTT. Z. T. SV.", automatica: true, modalita: "giorni", oreGiorni: 0, importo: "", annotazioni: "" },
        { codice: "215", nome: "RECUPERO ACCONTO", automatica: false, modalita: "importo", oreGiorni: 0, importo: "", annotazioni: "" },
        { codice: "265", nome: "PERMESSI NON FRUTI", automatica: false, modalita: "giorni", oreGiorni: 0, importo: "", annotazioni: "" },
        { codice: "293", nome: "maggiorazione 40%", automatica: false, modalita: "giorni", oreGiorni: 0, importo: "", annotazioni: "" },
        { codice: "294", nome: "indennità di reperibilità", automatica: false, modalita: "giorni", oreGiorni: 0, importo: "", annotazioni: "" },
        { codice: "311", nome: "IND.PRESENZA IMPIEGATI", automatica: true, modalita: "giorni", oreGiorni: 0, importo: "", annotazioni: "" },
        { codice: "325", nome: "ASSEMBLEA RETRIBUITA", automatica: true, modalita: "ore", oreGiorni: 0, importo: "", annotazioni: "" },
        { codice: "331", nome: "ORE PERMESSI SINDACALI", automatica: true, modalita: "ore", oreGiorni: 0, importo: "", annotazioni: "" },
        { codice: "349", nome: "IND.OPERATORE DI CENTRALE B C", automatica: true, modalita: "giorni", oreGiorni: 0, importo: "", annotazioni: "" },
        { codice: "350", nome: "IND.ADDETTO CONTR.RADIOGENO", automatica: false, modalita: "importo", oreGiorni: 0, importo: "", annotazioni: "" },
        { codice: "366", nome: "Indennita Capo Turno", automatica: false, modalita: "ore", oreGiorni: 0, importo: "", annotazioni: "" },
        { codice: "372", nome: "AGG.PROFESSIONALE", automatica: false, modalita: "ore", oreGiorni: 0, importo: "", annotazioni: "" },
        { codice: "424", nome: "FESTIVITA' GODUTA", automatica: true, modalita: "giorni", oreGiorni: 0, importo: "", annotazioni: "" },
        { codice: "673", nome: "MAGG. RIP. SPOST. 10%", automatica: true, modalita: "giorni", oreGiorni: 0, importo: "", annotazioni: "" },
        { codice: "679", nome: "MAGG. RIP. SPOST. 15%", automatica: true, modalita: "giorni", oreGiorni: 0, importo: "", annotazioni: "" }
    ]
    const [csvData, setCsvData] = useState([]);
    const [note, setNote] = useState("");
    const [bancaOreCorrezione, setBancaOreCorrezione] = useState(0);
    const [incarichiDipendenteArray, setIncarichiDipendenteArray] = useState([]);
    const [tutteIndennitaiDipendenteArray, setTutteIndennitaiDipendenteArray] = useState([]);
    const [incarichiDipendenteSalvatiArray, setIncarichiDipendenteSalvatiArray] = useState([]);
    const [incarichiLoading, setIncarichiLoading] = useState(true);
    const [indennita, setIndennita] = useState(initialStateIndennita);
    const [colonnaPersonalizzata1, setColonnaPersonalizzata1] = useState(null);
    const [colonnaPersonalizzata2, setColonnaPersonalizzata2] = useState(null);
    const [indennitaPersonalizzata1, setIndennitaPersonalizzata1] = useState({ codice: "", nome: "INDENNITA PERSONALIZZATA", automatica: false, modalita: "giorni", oreGiorni: 0, importo: "", annotazioni: "" });
    const [indennitaPersonalizzata2, setIndennitaPersonalizzata2] = useState({ codice: "", nome: "INDENNITA PERSONALIZZATA", automatica: false, modalita: "giorni", oreGiorni: 0, importo: "", annotazioni: "" });
    const [indennitaPersonalizzata3, setIndennitaPersonalizzata3] = useState({ codice: "", nome: "INDENNITA PERSONALIZZATA", automatica: false, modalita: "giorni", oreGiorni: 0, importo: "", annotazioni: "" });
    const [offsetFlessibilita, setOffsetFlessibilita] = useState(0);
    const [riposiLavorati, setRiposiLavorati] = useState(0);
    const [riposiRecuperati, setRiposiRecuperati] = useState(0);


    const getOreBetweenDates = (start, end) => {
        start = start.split(":");
        end = end.split(":");
        var startDate = new Date(0, 0, 0, start[0], start[1], 0);
        var endDate = new Date(0, 0, 0, end[0], end[1], 0);
        var diff = endDate.getTime() - startDate.getTime();
        var hours = Math.floor(diff / 1000 / 60 / 60);
        diff -= hours * 1000 * 60 * 60;
        var minutes = Math.floor(diff / 1000 / 60);
        if (hours < 0)
            hours = hours + 24;
        if (isNaN(hours) || isNaN(minutes)) {
            return ""
        }
        return (hours <= 9 ? "0" : "") + hours + ":" + (minutes <= 9 ? "0" : "") + minutes;
    }


    const calcoloOreLavorateString = (incarichi) => {
        let hours = 0, minutes = 0;
        incarichi.forEach((incarico) => {
            if (incarico.ore) {
                const split = incarico.ore.split(':');
                hours += parseInt(split[0]);
                minutes += parseInt(split[1]);
            }
        });
        hours += Math.floor(minutes / 60);
        minutes = minutes % 60;
        return formattaOre(hours) + ':' + formattaMinuti(minutes)
    }


    const calcoloTotaliOre = (incarichi, field) => {
        let hours = 0, minutes = 0;
        incarichi.forEach((incarico) => {
            if (incarico[field]) {
                const split = incarico[field].split(':');
                hours += parseInt(split[0]);
                minutes += parseInt(split[1]);
            }
        });
        hours += Math.floor(minutes / 60);
        minutes = minutes % 60;
        if (hours == 0 && minutes == 0) {
            return "00:00"
        } else {
            return hours + ':' + formattedNumber(minutes)
        }
    }
    const calcoloTotaliOreStrOTSOLD = (incarichi, field) => {
        let hours = 0, minutes = 0;
        incarichi.forEach((incarico) => {
            if (incarico.oreStraordinarie) {
                const split = incarico.oreStraordinarie.split(':');
                if (field == 'oreStraordinarieNotte') {
                    if (incarico.sumMinTotInt <= 2880 && incarico.isNotturna) {
                        hours += parseInt(split[0]);
                        minutes += parseInt(split[1]);
                        hours += Math.floor(minutes / 60);
                        minutes = minutes % 60;
                    }
                } else if (field == 'oreStraordinarieMin48') {
                    if (incarico.sumMinTotInt <= 2880 && !incarico.isNotturna) {
                        hours += parseInt(split[0]);
                        minutes += parseInt(split[1]);
                        hours += Math.floor(minutes / 60);
                        minutes = minutes % 60;
                    }
                } else {
                    if (incarico.sumMinTotInt > 2880 && !incarico.isNotturna) {
                        hours += parseInt(split[0]);
                        minutes += parseInt(split[1]);
                        hours += Math.floor(minutes / 60);
                        minutes = minutes % 60;
                    }
                }
            }
        });
        if (hours == 0 && minutes == 0) {
            return "00:00"
        } else {
            return hours + ':' + formattedNumber(minutes)
        }
    }

    const isNotturna = (orarioInizio, orarioFine) => {
        const splitInizio = orarioInizio.split(':');
        const splitFine = orarioFine.split(':');
        let oraInizio = parseInt(splitInizio[0]);
        let oraFine = parseInt(splitFine[0]);
        if (oraInizio > 21 || oraInizio < 6) {
            return true
        } else {
            if (oraFine > 21 || oraFine < 6) {
                return true
            } else {
                return false
            }
        }
    }

    const calcoloGiorniLavorati = (incarichi) => {
        let giorni = 0;
        incarichi.forEach((incarico) => {
            if (incarico.effettivo == "AS" && incarico.daCalcolare) {
                if (!incarico.multiplo || (incarico.multiplo && incarico.isLastMultiplo)) {
                    giorni = giorni + 1
                }
            }
        });
        return giorni
    }

    const calcoloTotaliFlessibilita = (incarichi) => {
        let totale = 0;
        incarichi.forEach((incarico) => {
            if (incarico.flessibilita) {
                console.log(incarico)
                totale += incarico.flessibilita;
            }
        });
        return totale
    }

    const tableStyle = {
        width: "100%"
    };


    const Prints = () => (
        <>
            <style>{`@page { margin: ${"20px"} ${"20px"} ${"20px"} ${"20px"} !important; }`}</style>
            <div>
                <h2>{user.filiale && user.filiale.nome}</h2>
                <h4>INCARICHI DIPENDENTI (<Moment format='MMMM'>{dataInizioInput}</Moment>)</h4>
                <h3>{dipendenteData.cognome.toUpperCase()} {dipendenteData.nome.toUpperCase()}</h3>
                <p style={{ padding: "10px 0 10px 0px" }}>I presenti turni di servizio programmati, potranno essere soggetti a modifiche, in caso di necessità improvvise e non prevedibili nella normale programmazione lavorativa.</p>
                <table style={tableStyle}>
                    <colgroup>
                        <col span="1" style={{ width: "15%" }} />
                        <col span="1" style={{ width: "7%" }} />
                        <col span="1" style={{ width: "7%" }} />
                        <col span="1" style={{ width: "41%" }} />
                        <col span="1" style={{ width: "10%" }} />
                        <col span="1" style={{ width: "10%" }} />
                        <col span="1" style={{ width: "10%" }} />
                    </colgroup>
                    <thead>
                        <tr style={{ textAlign: "left" }}>
                            <th>Data</th>
                            <th>Disp.</th>
                            <th>Eff.</th>
                            <th>Servizio</th>
                            <th>Dalle</th>
                            <th>Alle</th>
                            <th>Ore fatte</th>
                        </tr>
                    </thead>
                    <tbody>
                        {incarichiDipendenteArray.map((el, i) => (
                            <tr style={{ borderStyle: "solid", borderWidth: "1px" }} className={`${el.idRaggruppamento == "16" || el.idRaggruppamento == "42" || el.idRaggruppamento == "27" ? "red_soft" : ""}`} >
                                <td>{el.data}</td>
                                <td>{el.disponibilita}</td>
                                {el.disponibilita == "DI" && el.effettivo == "DI" ?
                                    <>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </>
                                    :
                                    el.effettivo != "VU" ?
                                        <>
                                            <td>{el.effettivo}</td>
                                            <td>{el.servizio}{el.localita ? " - " + el.localita : ''}{el.arrayIndennita.map(e => { return e.codiceIndennitaOperativa && e.dominante ? "(indennita op. " + e.codiceIndennitaOperativa + " di " + e.oreGiorni + " " + (e.modalita == 'ore' ? "minuti" : "giorni") + ")" : "" })} {el.arrayIndennita.map(e => { return e.codiceIndennitaDiFunzione && e.dominante ? "(indennita funz. " + e.codiceIndennitaDiFunzione + " di " + e.oreGiorni + " " + (e.modalita == 'ore' ? "minuti" : "giorni") + ")" : "" })}</td>
                                            <td>{el.oraInizio}</td>
                                            <td>{el.oraFine}</td>
                                            <td>{el.ore}</td>
                                        </>
                                        :
                                        <>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </>
                                }
                            </tr>
                        ))}
                    </tbody>
                </table>
                <table style={tableStyle}>
                    <colgroup>
                        <col span="1" style={{ width: "70%" }} />
                        <col span="1" style={{ width: "20%" }} />
                        <col span="1" style={{ width: "10%" }} />
                    </colgroup>
                    <thead>
                        <tr style={{ textAlign: "left" }}>
                            <th></th>
                            <th>TOTALE ORE</th>
                            <th>{calcoloOreLavorateString(incarichiDipendenteArray)}</th>
                        </tr>
                    </thead>
                </table>
                <table style={tableStyle}>
                    <colgroup>
                        <col span="1" style={{ width: "100%" }} />
                    </colgroup>
                    <thead>
                        <tr style={{ textAlign: "left" }}>
                            <th>NOTE:</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{note}</td>
                        </tr>
                    </tbody>
                </table>
                <p>---------------------------------------------------------------------------------------------------------------------------------------</p>
                <h4>Lista indennità</h4>
                <table style={tableStyle}>
                    <colgroup>
                        <col span="1" style={{ width: "15%" }} />
                        <col span="1" style={{ width: "7%" }} />
                        <col span="1" style={{ width: "7%" }} />
                        <col span="1" style={{ width: "41%" }} />
                        <col span="1" style={{ width: "10%" }} />
                        <col span="1" style={{ width: "10%" }} />
                        <col span="1" style={{ width: "10%" }} />
                    </colgroup>
                    <thead>
                        <tr style={{ textAlign: "left" }}>
                            <th>Data</th>
                            <th>Voce</th>
                            <th></th>
                            <th>Somma durata servizi</th>
                            <th>Valore</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tutteIndennitaiDipendenteArray.map((el, i) => (
                            <tr style={{ borderStyle: "solid", borderWidth: "1px" }}>
                                {i > 0 && el.data == tutteIndennitaiDipendenteArray[i - 1].data ?
                                    <td></td>
                                    :
                                    <td>{el.data}</td>
                                }
                                <td>{el.codiceIndennitaDiFunzione ? el.codiceIndennitaDiFunzione : ''}{el.codiceIndennitaOperativa ? el.codiceIndennitaOperativa : ''}</td>
                                <td>{el.codiceIndennitaDiFunzione ? '(fu)' : ''}{el.codiceIndennitaOperativa ? '(op)' : ''}</td>
                                <td>{el.contatore ? el.contatore + " (min)" : ''}</td>
                                <td>{el.oreGiorni} {el.modalita == 'ore' ? '(min)' : '(g)'}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );



    useEffect(() => {
        const size = alert.length;
        if (size > 0) {
            if (alert[size - 1].alertType === "success") {
                //setOpenIncarichiDipendente(false);
            }
        }
    }, [alert]);


    useEffect(() => {
        getIncarichiDipendente({ dataInizio: dataInizioInput, dataFine: dataFineInput, idDipendente: idDipendente });
        setIndennitaPersonalizzata1({ codice: "", nome: "INDENNITA PERSONALIZZATA", automatica: false, modalita: "giorni", oreGiorni: 0, importo: "", annotazioni: "" });
        setIndennitaPersonalizzata2({ codice: "", nome: "INDENNITA PERSONALIZZATA", automatica: false, modalita: "giorni", oreGiorni: 0, importo: "", annotazioni: "" });
        setIndennitaPersonalizzata3({ codice: "", nome: "INDENNITA PERSONALIZZATA", automatica: false, modalita: "giorni", oreGiorni: 0, importo: "", annotazioni: "" });
        setOffsetFlessibilita(0)
    }, [getIncarichiDipendente, idDipendente, dataInizioInput, dataFineInput]);


    const aggiornaOreDopoModificaOrario = (incarico) => {
        let rigeneraMultiplo = false
        incarico.ore = getOreBetweenDates(incarico.oraInizio, incarico.oraFine)
        incarico.oreInt = getOreInt(incarico.ore);
        incarico.minutiInt = getMinutiInt(incarico.ore);
        if (incarico.multiplo) {
            let arrayMultipli = incarichiDipendenteArray.filter(el => el.idLastMultiplo == incarico.id);
            if (incarico.isLastMultiplo) {
                let sommaOre = arrayMultipli.map(item => item.oreInt).reduce((prev, curr) => prev + curr, 0);
                let sommaMinuti = arrayMultipli.map(item => item.minutiInt).reduce((prev, curr) => prev + curr, 0);
                incarico.oreMultipliInt = sommaOre + getOreInt(incarico.ore);
                incarico.minutiMultipliInt = sommaMinuti + getMinutiInt(incarico.ore);
                if (incarico.minutiMultipliInt >= 60) {
                    let objNewMinutiOre = fromMinutiToOre(incarico.minutiMultipliInt)
                    incarico.oreMultipliInt = incarico.oreMultipliInt + objNewMinutiOre.ore
                    incarico.minutiMultipliInt = objNewMinutiOre.minuti
                }
            } else {
                rigeneraMultiplo = true
            }
        }
        incarico = getCalcoliIncarico(incarico, incarico, user.filiale.tipologiaFatturazione)
        return { incarico: incarico, rigeneraMultiplo: rigeneraMultiplo };
    }

    useEffect(() => {
        if (incarichiDipendente && incarichiDipendente.incarichi) {
            costruisciTabella(incarichiDipendente.incarichi, incarichiDipendente.dipendente, user.filiale.tipologiaFatturazione)
        }
    }, [incarichiDipendente]);






    const costruisciTabella = (incarichi, dipendente, tipologiaFatturazione) => {
        let array = [];
        incarichi = ordinaDate(incarichi)
        incarichi.map((el) => {
            let arrayFilter = [];
            if (array.length > 0) {
                //trovo turni dello stesso giorno
                //arrayFilter = array.filter(element => sameDay(new Date(element.dataNotFormatted), new Date(el.data)));
                //modifica tiro
                arrayFilter = array.filter(element => sameDay(new Date(element.dataNotFormatted), new Date(el.data)) && element.daCalcolare);
            }
            let objIncarico;
            objIncarico = {
                id: uuid(),
                nome: el.nome,
                cognome: el.cognome,
                qualifica: dipendente.qualifica,
                partTime: dipendente.partTime ? dipendente.partTime : false,
                data: toIsoString(new Date(el.data)),
                dataNotFormatted: el.data,
                giornoSettimana: el.giornoSettimana,
                numeroSettimana: el.numeroSettimana,
                festivo: el.festivo,
                festivoGiornoDopo: el.festivoGiornoDopo,
                disponibilita: el.disponibilita,
                disponibilitaGiornoDopo: el.disponibilitaGiornoDopo,
                effettivo: el.effettivo,
                percBancaOre: dipendente.percBancaOre,
                oraInizio: el.oraInizio,
                oraFine: el.oraFine,
                isNotturna: isNotturna(el.oraInizio, el.oraFine),
                servizio: el.servizio + (el.localita ? " - " + el.localita : ""),
                codiceCliente: el.codiceCliente,
                ragioneSocialeCliente: el.ragioneSocialeCliente,
                codicePuntoServizio: el.puntoVendita.codice,
                denominazionePuntoServizio: el.puntoVendita.denominazione,
                idServiziEff: el.puntoVendita.idServiziEff,
                idIndennitaDiFunz: el.puntoVendita.idIndennitaDiFunz,
                idRaggruppamento: el.puntoVendita && el.puntoVendita.idRaggruppa ? el.puntoVendita.idRaggruppa : "",
                codiceContratto: el.codiceContratto,
                ore: el.ore,
                oreInt: getOreInt(el.ore),
                minutiInt: getMinutiInt(el.ore),
                sumMinTotInt: 0,
                sumStrNotte: 0,
                oreOrdinarie: "",
                bancaOreRetrib: "",
                bancaOreDaFruire: "",
                oreStraordinarie: "",
                oreStraordinarieNotteOTS: "",
                oreStraordinarieOTS1: "",
                oreStraordinarieOTS2: "",
                oreSupplementari: "",
                oreStraordinarieFestive: "",
                oreStraordinarieFestiveOTSGiorno: "",
                oreStraordinarieFestiveOTSNotte: "",
                oreMultipliInt: 0,
                minutiMultipliInt: 0,
                oreStrRipSp: "",
                ferOrd: dipendente.ferOrd.replace(".", ":"),
                ferStr: dipendente.ferStr.replace(".", ":"),
                indennitaDomenicale: "",
                indennitaDomenicaleOTSGiorno: "",
                indennitaDomenicaleOTSNotte: "",
                multiplo: false,
                isLastMultiplo: false,
                idLastMultiplo: null,
                flessibilita: 0,
                flessibilitaModificata: false,
                oreNonLavorate: "",
                oreSciopero: "",
                oreAllattamento: "",
                permessiL104: "",
                oreROLGoduti: "",
                bancaOreGoduta: "",
                oreSupPartTime: "",
                daCalcolare: true,
                arrayIndennita: [],
                recuperoRiposo: el.recuperoRiposo ? el.recuperoRiposo : null
            }
            //Controllo se visita media o tiro a segno, escludendo account specifici
            if(user.filiale._id != '627bc5eb4c18c77549ce9b3c' && user.filiale._id != '62448517c739c87145aa2610'){
                //if(objIncarico.idRaggruppamento == 42 || objIncarico.idRaggruppamento == 27 || objIncarico.idRaggruppamento == 16){
                if(objIncarico.idRaggruppamento == 42 || objIncarico.idRaggruppamento == 27){
                        objIncarico.oreInt = 0 
                    objIncarico.minutiInt = 0
                    //objIncarico.ore = 0
                    objIncarico.daCalcolare = false
                    //modifica tiro
                        objIncarico.multiplo= false
                }
            }
            

            if (arrayFilter.length > 0 && objIncarico.daCalcolare) {
                //se è un turno con multipli
                let sommaOre = arrayFilter.map(item => item.oreInt).reduce((prev, curr) => prev + curr, 0);
                let sommaMinuti = arrayFilter.map(item => item.minutiInt).reduce((prev, curr) => prev + curr, 0);
                let sommaStrNotte = arrayFilter.map(item => item.oreStraordinarieNotteOTS).reduce((prev, curr) => fromStringToMinuti(prev) + fromStringToMinuti(curr), 0);
                objIncarico.oreMultipliInt = sommaOre + getOreInt(el.ore);
                objIncarico.minutiMultipliInt = sommaMinuti + getMinutiInt(el.ore);
                if (objIncarico.minutiMultipliInt >= 60) {
                    let objNewMinutiOre = fromMinutiToOre(objIncarico.minutiMultipliInt)
                    objIncarico.oreMultipliInt = objIncarico.oreMultipliInt + objNewMinutiOre.ore
                    objIncarico.minutiMultipliInt = objNewMinutiOre.minuti
                }
                objIncarico.sumStrNotte = sommaStrNotte
                objIncarico.multiplo = true
                objIncarico.isLastMultiplo = true
                objIncarico.idLastMultiplo = null

                if (objIncarico.qualifica == 'gpg') {
                    objIncarico = getCalcoliIncaricoGPG(objIncarico, tipologiaFatturazione)

                } else if(objIncarico.qualifica == 'impiegato'){
                    objIncarico = getCalcoliImpiegato(objIncarico)
                }else {
                    objIncarico = getCalcoliIncaricoOTS(objIncarico, tipologiaFatturazione)
                }

                arrayFilter.map((elFil) => {
                    let indexArray = array.findIndex(eleArr => eleArr.id == elFil.id);
                    if (indexArray >= -1) {
                        array[indexArray].oreOrdinarie = "";
                        array[indexArray].bancaOreRetrib = "";
                        array[indexArray].bancaOreDaFruire = "";
                        array[indexArray].oreStraordinarie = "";
                        array[indexArray].oreStraordinarieNotteOTS = "";
                        array[indexArray].oreSupplementari = "";
                        array[indexArray].oreStraordinarieFestive = "";
                        array[indexArray].oreStraordinarieFestiveOTSGiorno = "";
                        array[indexArray].oreStraordinarieFestiveOTSNotte = "";
                        array[indexArray].oreStraordinarieOTS1 = "";
                        array[indexArray].oreStraordinarieOTS2 = "";
                        array[indexArray].oreStrRipSp = "";
                        array[indexArray].indennitaDomenicale = "";
                        array[indexArray].indennitaDomenicaleOTSGiorno = "";
                        array[indexArray].indennitaDomenicaleOTSNotte = "";
                        array[indexArray].isLastMultiplo = false;
                        array[indexArray].idLastMultiplo = objIncarico.id;
                        array[indexArray].oreMultipliInt = null;
                        array[indexArray].minutiMultipliInt = null;
                        array[indexArray].flessibilita = 0;
                        array[indexArray].flessibilitaModificata = false;
                        array[indexArray].multiplo = true;
                        array[indexArray].oreNonLavorate = "";
                        array[indexArray].oreSupPartTime = "";
                    }
                })
            } else {
                //se è un turno senza multipli
                if (objIncarico.qualifica == 'gpg') {
                    objIncarico = getCalcoliIncaricoGPG(objIncarico, tipologiaFatturazione)
                } else if(objIncarico.qualifica == 'impiegato'){
                    objIncarico = getCalcoliImpiegato(objIncarico)
                } else {
                    objIncarico = getCalcoliIncaricoOTS(objIncarico, tipologiaFatturazione)
                }
            }
            array.push(objIncarico)
        })
        array = calcolaSumMinTotInt(array)
        //if (dipendente.qualifica == 'gpg') {
            array = popolaIndennita(array, tipologiaFatturazione)

            let calcoloIndennita = calcolaIndennitaDominante(array)
            let arrayIndennitaGiorni = calcoloIndennita.arrayIndennitaGiorni
            let arrayIndennitaDominanti = calcoloIndennita.indennita
            setTutteIndennitaiDipendenteArray(arrayIndennitaGiorni)
            let indennitaArray = [...indennita]
            indennitaArray = generaListaIndennita(arrayIndennitaDominanti, indennitaArray, incarichiDipendente.dipendente)
            console.log(indennitaArray)
            setIndennita(indennitaArray)
        //}
        if (dipendente.qualifica == 'ots') {
            array = calcolaStraordinariOTS(array)
        }
        calcolaRiposiDaRecuperare(array)
        setIncarichiDipendenteArray(array)
        setIncarichiLoading(false)
    }

    const calcolaRiposiDaRecuperare = (array) => {
        let riposiLavorati = 0;
        let riposiRecuperati = 0;
        array.map(el => {
            if(!el.multiplo || (el.multiplo && el.isLastMultiplo)){
                if(el.disponibilita == "RP"){
                    if(el.effettivo == "AS"){
                        riposiLavorati = riposiLavorati + 1
                    }
                }
                if(el.disponibilita == "DI"){
                    if(el.effettivo == "RR"){
                        riposiRecuperati = riposiRecuperati + 1
                    }
                }
            }
        })
        setRiposiLavorati(riposiLavorati)
        setRiposiRecuperati(riposiRecuperati)
    }

        const calcolaStraordinariOTS = (array) => {
            array.map(el => {
                el.oreStraordinarieOTS1 = calcolaStraordinari48(el).primaColonna
                el.oreStraordinarieOTS2 = calcolaStraordinari48(el).secondaColonna
            })
            return array
        }


        function differenzaDate(date1, date2) {
            const ONE_DAY_MS = 1000 * 60 * 60 * 24; // Numero di millisecondi in un giorno
            
            // Converti le stringhe ISO 8601 in oggetti Date
            const d1 = new Date(date1);
            const d2 = new Date(date2);
            
            // Calcola la differenza in millisecondi
            const diffMs = Math.abs(d1 - d2);
            
            // Converti la differenza in giorni
            const diffDays = diffMs / ONE_DAY_MS;
            console.log(d1)
            console.log(d2)
            console.log(diffDays)
            // Verifica se la differenza è minore o maggiore di 7 giorni
            if (diffDays <= 7) {
              return true
            } else {
                return false
            }
          }

    const sommeOre349MultiplaNelGiorno = ({incarichi}) =>{
        let sommaMinuti = 0;
        incarichi.map(elemento => {
            if(elemento.idIndennitaDiFunz == "1"){
                sommaMinuti = sommaMinuti + (elemento.oreInt*60) + elemento.minutiInt

            }
        })

        return sommaMinuti/60
    }

    const popolaIndennita = (incarichi, tipologiaFatturazione) => {
        incarichi.map(objIncarico => {
            let objIndennitaDiServizio = {};
            let objIndennitaDiFunzione = {};
            if (objIncarico.effettivo == "AS") {
                let indexIndennitaDiServizio = serviziEffJSON.serviziEff.findIndex(indennita => indennita.id == objIncarico.idServiziEff)
                if (indexIndennitaDiServizio > -1) {
                    let indennitaDiServizio = serviziEffJSON.serviziEff[indexIndennitaDiServizio]
                    if (indennitaDiServizio.modalitaCalcolo == "ore") {
                        objIndennitaDiServizio = { oreGiorni: (objIncarico.oreInt * 60) + objIncarico.minutiInt, idServiziEff: objIncarico.idServiziEff, idIndennitaDiFunz: "", codiceIndennitaOperativa: indennitaDiServizio.codiceIndennita, codiceIndennitaDiFunzione: "", GPGEccedenza: indennitaDiServizio.GPGEccedenza, modalita: "ore", dominante: false }
                    } else {
                        objIndennitaDiServizio = { oreGiorni: 1, idServiziEff: objIncarico.idServiziEff, idIndennitaDiFunz: "", codiceIndennitaOperativa: indennitaDiServizio.codiceIndennita, codiceIndennitaDiFunzione: "", GPGEccedenza: indennitaDiServizio.GPGEccedenza, modalita: "giorni", dominante: false }
                    }
                } 
                let indexIndennitaDiFunzione = indennitaDiFunzioneJSON.indennitaDiFunzione.findIndex(indennita => indennita.id == objIncarico.idIndennitaDiFunz)
                if (indexIndennitaDiFunzione > -1) {
                    let indennitaDiFunzione = indennitaDiFunzioneJSON.indennitaDiFunzione[indexIndennitaDiFunzione]
                    if (indennitaDiFunzione.codice == 349) {
                        let incarichiStessoGiorno = incarichi.filter(el => el.data == objIncarico.data)
                        if (objIncarico.ferOrd == "08:00") {
                            if(objIncarico.multiplo && objIncarico.isLastMultiplo){
                                let sommaOreMultipliInt = sommeOre349MultiplaNelGiorno({incarichi: incarichiStessoGiorno})
                                if (sommaOreMultipliInt >= 4) {
                                    objIndennitaDiFunzione = { oreGiorni: 1, idServiziEff: "", idIndennitaDiFunz: objIncarico.idIndennitaDiFunz, codiceIndennitaOperativa: "", codiceIndennitaDiFunzione: indennitaDiFunzione.codice + "", GPGEccedenza: "", modalita: "giorni", dominante: false }
                                }
                            }else{
                                if (objIncarico.oreInt >= 4) {
                                    objIndennitaDiFunzione = { oreGiorni: 1, idServiziEff: "", idIndennitaDiFunz: objIncarico.idIndennitaDiFunz, codiceIndennitaOperativa: "", codiceIndennitaDiFunzione: indennitaDiFunzione.codice + "", GPGEccedenza: "", modalita: "giorni", dominante: false }
                                }
                            }
                        }
                        if (objIncarico.ferOrd == "07:00") {
                            if(objIncarico.multiplo && objIncarico.isLastMultiplo){
                                let sommaOreMultipliInt = sommeOre349MultiplaNelGiorno({incarichi: incarichiStessoGiorno})
                                if (sommaOreMultipliInt >= 3.5) {
                                    objIndennitaDiFunzione = { oreGiorni: 1, idServiziEff: "", idIndennitaDiFunz: objIncarico.idIndennitaDiFunz, codiceIndennitaOperativa: "", codiceIndennitaDiFunzione: indennitaDiFunzione.codice + "", GPGEccedenza: "", modalita: "giorni", dominante: false }
                                }
                            }else{
                                if ((objIncarico.oreInt == 3 && objIncarico.minutiInt >= 30) || (objIncarico.oreInt >= 4)) {
                                    objIndennitaDiFunzione = { oreGiorni: 1, idServiziEff: "", idIndennitaDiFunz: objIncarico.idIndennitaDiFunz, codiceIndennitaOperativa: "", codiceIndennitaDiFunzione: indennitaDiFunzione.codice + "", GPGEccedenza: "", modalita: "giorni", dominante: false }
                                }
                            }
                        }
                    } else {
                        if (indennitaDiFunzione.modalitaCalcolo == "ore") {
                            objIndennitaDiFunzione = { oreGiorni: (objIncarico.oreInt * 60) + objIncarico.minutiInt, idServiziEff: "", idIndennitaDiFunz: objIncarico.idIndennitaDiFunz, codiceIndennitaOperativa: "", codiceIndennitaDiFunzione: indennitaDiFunzione.codice + "", GPGEccedenza: "", modalita: "ore", dominante: false }
                        } else {
                            objIndennitaDiFunzione = { oreGiorni: 1, idServiziEff: "", idIndennitaDiFunz: objIncarico.idIndennitaDiFunz, codiceIndennitaOperativa: "", codiceIndennitaDiFunzione: indennitaDiFunzione.codice + "", GPGEccedenza: "", modalita: "giorni", dominante: false }
                        }
                    }
                }
                objIncarico.arrayIndennita.push(objIndennitaDiServizio)
                objIncarico.arrayIndennita.push(objIndennitaDiFunzione)
                let festivo = 0;
                if(objIncarico.festivo){
                    festivo = festivo + 1 
                }
                //if(objIncarico.festivoGiornoDopo){
                    //if(fromStringToMinuti(objIncarico.oraInizio) > fromStringToMinuti(objIncarico.oraFine)){
                        //festivo = festivo + 1 
                    //}
                //}
                //if(tipologiaFatturazione == "B"){
                    //if(fromStringToMinuti(objIncarico.indennitaDomenicale) > 0){
                        //let objIndennitaDomenicaleGPG = { oreGiorni: 1, idServiziEff: "", idIndennitaDiFunz: "", codiceIndennitaOperativa: "", codiceIndennitaDiFunzione: "293", GPGEccedenza: "", modalita: "giorni", dominante: true }
                        //objIncarico.arrayIndennita.push(objIndennitaDomenicaleGPG)
                    //}
                //}
                if(festivo > 0){
                    console.log("FESTIVO")
                    let objIndennitaFestivo1 = { oreGiorni: festivo, idServiziEff: "", idIndennitaDiFunz: "", codiceIndennitaOperativa: "", codiceIndennitaDiFunzione: "051", GPGEccedenza: "", modalita: "giorni", dominante: true }
                    let objIndennitaFestivo2 = { oreGiorni: festivo, idServiziEff: "", idIndennitaDiFunz: "", codiceIndennitaOperativa: "", codiceIndennitaDiFunzione: "101", GPGEccedenza: "", modalita: "giorni", dominante: false }
                    objIncarico.arrayIndennita.push(objIndennitaFestivo1)
                    objIncarico.arrayIndennita.push(objIndennitaFestivo2)
                }
                if(objIncarico.disponibilita == "RP" && objIncarico.recuperoRiposo){
                    console.log(objIncarico)
                    let differenzaG = differenzaDate(objIncarico.dataNotFormatted, objIncarico.recuperoRiposo.dataInizio)
                    if( differenzaG <= 7){
                        console.log("minore o uguale a 7")
                        if(objIncarico.qualifica == 'ots'){
                            objIncarico.arrayIndennita.push({ oreGiorni: 1, idServiziEff: "", idIndennitaDiFunz: "", codiceIndennitaOperativa: "", codiceIndennitaDiFunzione: "673", GPGEccedenza: "", modalita: "giorni", dominante: true })
                        }
                        if(objIncarico.qualifica == 'gpg'){
                            objIncarico.arrayIndennita.push({ oreGiorni: 1, idServiziEff: "", idIndennitaDiFunz: "", codiceIndennitaOperativa: "", codiceIndennitaDiFunzione: "114", GPGEccedenza: "", modalita: "giorni", dominante: true })
                        }
                    }
                    if( differenzaG > 7){
                        console.log("maggiore a 7")
                        if(objIncarico.qualifica == 'ots'){
                            objIncarico.arrayIndennita.push({ oreGiorni: 1, idServiziEff: "", idIndennitaDiFunz: "", codiceIndennitaOperativa: "", codiceIndennitaDiFunzione: "679", GPGEccedenza: "", modalita: "giorni", dominante: true })
                        }
                        if(objIncarico.qualifica == 'gpg'){
                            objIncarico.arrayIndennita.push({ oreGiorni: 1, idServiziEff: "", idIndennitaDiFunz: "", codiceIndennitaOperativa: "", codiceIndennitaDiFunzione: "113", GPGEccedenza: "", modalita: "giorni", dominante: true })
                        }
                    }
                }
            }
            if(objIncarico.effettivo == "FG"){
                let objIndennitaFG = { oreGiorni: 1, idServiziEff: "", idIndennitaDiFunz: "", codiceIndennitaOperativa: "", codiceIndennitaDiFunzione: "424", GPGEccedenza: "", modalita: "giorni", dominante: true }
                objIncarico.arrayIndennita.push(objIndennitaFG)
            }
        })
        return incarichi
    }

    const OLDcalcolaIndennitaDominante = (turniArray) => {
        let arraIndennitaDominanti = [];
        let giorni = turniArray.map(el => { return el.data })
        giorni = [...new Set(giorni)];
        giorni.map(giorno => {
            let indexIIO = -1;
            let indexGIO = -1;
            let indexIIF = -1;
            let indexGIF = -1;
            let sommaOldO = 0;
            let sommaOldF = 0;
            for (let i = 0; i < turniArray.length; i++) {
                if (turniArray[i].data === giorno) {
                    if (turniArray[i].arrayIndennita.length > 0) {
                        for (let j = 0; j < turniArray[i].arrayIndennita.length; j++) {
                            if (turniArray[i].arrayIndennita[j].codiceIndennitaOperativa) {
                                if (indexIIO >= 0) {
                                    if (sommaOldO < (turniArray[i].oreInt * 60) + turniArray[i].minutiInt) {
                                        sommaOldO = (turniArray[i].oreInt * 60) + turniArray[i].minutiInt
                                        indexGIO = i
                                        indexIIO = j
                                    }
                                } else {
                                    sommaOldO = (turniArray[i].oreInt * 60) + turniArray[i].minutiInt
                                    indexGIO = i
                                    indexIIO = j
                                }
                            }
                            if (turniArray[i].arrayIndennita[j].codiceIndennitaDiFunzione) {
                                if (indexIIF >= 0) {
                                    if (sommaOldF < (turniArray[i].oreInt * 60) + turniArray[i].minutiInt) {
                                        sommaOldF = (turniArray[i].oreInt * 60) + turniArray[i].minutiInt
                                        indexGIF = i
                                        indexIIF = j
                                    }
                                } else {
                                    sommaOldF = (turniArray[i].oreInt * 60) + turniArray[i].minutiInt
                                    indexGIF = i
                                    indexIIF = j
                                }
                            }
                        }
                    }
                }
            }
            if (indexGIO > -1 && indexIIO > -1) {
                arraIndennitaDominanti.push(turniArray[indexGIO].arrayIndennita[indexIIO])
                turniArray[indexGIO].arrayIndennita[indexIIO].dominante = true
            }
            if (indexGIF > -1 && indexIIF > -1) {
                arraIndennitaDominanti.push(turniArray[indexGIF].arrayIndennita[indexIIF])
                turniArray[indexGIF].arrayIndennita[indexIIF].dominante = true
            }
        })
        return { turni: turniArray, indennita: arraIndennitaDominanti }

    }

    const calcolaIndennitaDominante = (turniArray) => {
        let arrayIndennitaGiorni = [];
        let arrayIndennitaGiorniClean = [];
        turniArray.map(el => {
            let index = arrayIndennitaGiorni.findIndex(element => { return element.data == el.data })
            if (index > -1) {
                el.arrayIndennita.map(ele => {
                    if (ele.codiceIndennitaDiFunzione && ele.codiceIndennitaDiFunzione.length > 0) {
                        let indexElementF = arrayIndennitaGiorni[index].arrayIndennita.findIndex(x => (x.codiceIndennitaDiFunzione == ele.codiceIndennitaDiFunzione))
                        if (indexElementF > -1) {
                            if (ele.modalita == 'ore') {
                                arrayIndennitaGiorni[index].arrayIndennita[indexElementF].oreGiorni = parseInt(arrayIndennitaGiorni[index].arrayIndennita[indexElementF].oreGiorni) + parseInt(ele.oreGiorni)
                            }
                        } else {
                            arrayIndennitaGiorni[index].arrayIndennita.push({ codiceIndennitaDiFunzione: ele.codiceIndennitaDiFunzione, oreGiorni: ele.oreGiorni, modalita: ele.modalita, GPGEccedenza: ele.GPGEccedenza, dominante: true })
                        }
                    }
                    if (ele.codiceIndennitaOperativa && ele.codiceIndennitaOperativa.length > 0) {
                        let sum = (el.oreInt * 60) + el.minutiInt
                        let indexElementO = arrayIndennitaGiorni[index].arrayIndennita.findIndex(x => (x.codiceIndennitaOperativa == ele.codiceIndennitaOperativa))
                        if (indexElementO > -1) {
                            arrayIndennitaGiorni[index].arrayIndennita[indexElementO].contatore = arrayIndennitaGiorni[index].arrayIndennita[indexElementO].contatore + sum
                            if (ele.modalita == 'ore') {
                                arrayIndennitaGiorni[index].arrayIndennita[indexElementO].oreGiorni = parseInt(arrayIndennitaGiorni[index].arrayIndennita[indexElementO].oreGiorni) + parseInt(ele.oreGiorni)
                            }
                        } else {
                            arrayIndennitaGiorni[index].arrayIndennita.push({ codiceIndennitaOperativa: ele.codiceIndennitaOperativa, oreGiorni: ele.oreGiorni, modalita: ele.modalita, GPGEccedenza: ele.GPGEccedenza, contatore: sum })
                        }
                    }
                })
            } else {
                if (el.arrayIndennita.length > 0) {
                    let newObj = { data: el.data, arrayIndennita: [] }
                    el.arrayIndennita.map(ele => {
                        if (ele.codiceIndennitaDiFunzione && ele.codiceIndennitaDiFunzione.length > 0) {
                            let indexElementF = newObj.arrayIndennita.indexOf(x => (x.codiceIndennitaDiFunzione == ele.codiceIndennitaDiFunzione))
                            if (indexElementF > -1) {
                                if (ele.modalita == 'ore') {
                                    newObj.arrayIndennita[indexElementF].oreGiorni = parseInt(newObj.arrayIndennita[indexElementF].oreGiorni) + parseInt(ele.oreGiorni)
                                }
                            } else {
                                newObj.arrayIndennita.push({ codiceIndennitaDiFunzione: ele.codiceIndennitaDiFunzione, oreGiorni: ele.oreGiorni, modalita: ele.modalita, GPGEccedenza: ele.GPGEccedenza })
                            }
                        }
                        if (ele.codiceIndennitaOperativa && ele.codiceIndennitaOperativa.length > 0) {
                            let sum = (el.oreInt * 60) + el.minutiInt
                            let indexElementO = newObj.arrayIndennita.findIndex(x => (x.codiceIndennitaOperativa == ele.codiceIndennitaOperativa))
                            if (indexElementO > -1) {
                                newObj.arrayIndennita[indexElementO].contatore = newObj.arrayIndennita[indexElementO].contatore + sum
                                if (ele.modalita == 'ore') {
                                    newObj.arrayIndennita[indexElementO].oreGiorni = parseInt(newObj.arrayIndennita[indexElementO].oreGiorni) + parseInt(ele.oreGiorni)
                                }
                            } else {
                                newObj.arrayIndennita.push({ codiceIndennitaOperativa: ele.codiceIndennitaOperativa, oreGiorni: ele.oreGiorni, modalita: ele.modalita, GPGEccedenza: ele.GPGEccedenza, contatore: sum })
                            }
                        }
                    })
                    arrayIndennitaGiorni.push(newObj)
                }
            }

        })
        let indennitaDominanti = []
        arrayIndennitaGiorni.map(el => {
            let indennitaOperativaDominante = {}
            el.arrayIndennita.map(e => {
                arrayIndennitaGiorniClean.push({ ...e, data: el.data })
                if (e.codiceIndennitaOperativa && e.codiceIndennitaOperativa.length > 0) {
                    if (indennitaOperativaDominante && indennitaOperativaDominante.contatore) {
                        if (e.contatore > indennitaOperativaDominante.contatore) {
                            indennitaOperativaDominante = e
                        }
                    } else {
                        indennitaOperativaDominante = e
                    }
                } else {
                    indennitaDominanti.push(e)
                }
            })
            if (indennitaOperativaDominante && indennitaOperativaDominante.codiceIndennitaOperativa && indennitaOperativaDominante.codiceIndennitaOperativa.length > 0) {
                indennitaDominanti.push(indennitaOperativaDominante)
            }
        })
        return { arrayIndennitaGiorni: arrayIndennitaGiorniClean, indennita: indennitaDominanti }

    }

    const generaListaIndennita = (arrayIndennitaDominanti, listaIndennita, dipendente) => {
        if (dipendente.qualifica == "gpg") {
            arrayIndennitaDominanti.map(giorno => {
                let indexTabIndennita = listaIndennita.findIndex(indennitaElement => indennitaElement.codice == giorno.codiceIndennitaOperativa)
                if (indexTabIndennita > -1) {
                    listaIndennita[indexTabIndennita].oreGiorni = parseInt(listaIndennita[indexTabIndennita].oreGiorni) + parseInt(giorno.oreGiorni);
                }
                if (dipendente.qualifica == "gpg" && dipendente.eccedenza) {
                    let indexTabGPGEccedenza = listaIndennita.findIndex(indennitaElement => indennitaElement.codice == giorno.GPGEccedenza)
                    if (indexTabGPGEccedenza > -1) {
                        listaIndennita[indexTabGPGEccedenza].oreGiorni = parseInt(listaIndennita[indexTabGPGEccedenza].oreGiorni) + parseInt(giorno.oreGiorni);
                    }
                }
                if (dipendente.qualifica == "gpg" && arrayIdMansioniIndennitaDiFunzione.includes(dipendente.idMansioni)) {
                    if(giorno.codiceIndennitaDiFunzione != "051" && giorno.codiceIndennitaDiFunzione != "101" && giorno.codiceIndennitaDiFunzione != "424"){
                        let indexTabIndennitaDiFunzione = listaIndennita.findIndex(indennitaElement => indennitaElement.codice == giorno.codiceIndennitaDiFunzione)
                        if (indexTabIndennitaDiFunzione > -1) {
                            listaIndennita[indexTabIndennitaDiFunzione].oreGiorni = parseInt(listaIndennita[indexTabIndennitaDiFunzione].oreGiorni) + parseInt(giorno.oreGiorni);
                        }
                    }
                }
                if(giorno.codiceIndennitaDiFunzione == "051"){
                    let indexTabIndennitaDiFunzione = listaIndennita.findIndex(indennitaElement => indennitaElement.codice == giorno.codiceIndennitaDiFunzione)
                    if (indexTabIndennitaDiFunzione > -1) {
                        listaIndennita[indexTabIndennitaDiFunzione].oreGiorni = parseInt(listaIndennita[indexTabIndennitaDiFunzione].oreGiorni) + 1 ;
                    }
                }
                if(giorno.codiceIndennitaDiFunzione == "101"){
                    let indexTabIndennitaDiFunzione = listaIndennita.findIndex(indennitaElement => indennitaElement.codice == giorno.codiceIndennitaDiFunzione)
                    if (indexTabIndennitaDiFunzione > -1) {
                        listaIndennita[indexTabIndennitaDiFunzione].oreGiorni = parseInt(listaIndennita[indexTabIndennitaDiFunzione].oreGiorni) + 1;
                    }
                }
                if(giorno.codiceIndennitaDiFunzione == "424"){
                    let indexTabIndennitaDiFunzione = listaIndennita.findIndex(indennitaElement => indennitaElement.codice == giorno.codiceIndennitaDiFunzione)
                    if (indexTabIndennitaDiFunzione > -1) {
                        listaIndennita[indexTabIndennitaDiFunzione].oreGiorni = parseInt(listaIndennita[indexTabIndennitaDiFunzione].oreGiorni) + 1;
                    }
                }
            })
            //Modifica Camarda
            if(dipendente._id == '63e61d7051332088f516395d'){
                let index = listaIndennita.findIndex(el => el.codice == '214')
                if(index >= 0){
                    listaIndennita[index].codice = '062'
                }
            }
        }        
        if (dipendente.qualifica == "ots"){
            arrayIndennitaDominanti.map(giorno => {
                if(giorno.codiceIndennitaDiFunzione == "673" || giorno.codiceIndennitaDiFunzione == "679"){
                    let indexTabIndennita = listaIndennita.findIndex(indennitaElement => indennitaElement.codice == giorno.codiceIndennitaDiFunzione)
                    if (indexTabIndennita > -1) {
                        listaIndennita[indexTabIndennita].oreGiorni = parseInt(listaIndennita[indexTabIndennita].oreGiorni) + parseInt(giorno.oreGiorni);
                    }
                }
            })
        }

        return listaIndennita
    }

    const calcolaIndennita = (turniArray, indennitaArray, dipendente) => {
        let arrayGiorni = [];
        turniArray.map(el => {
            if (el.arrayIndennita.length > 0) {
                let minutiServizioIndennitaOperativa = 0
                let oreGiorniIndennitaOperativa = 0
                let minutiServizioIndennitaDiFunzione = 0
                let oreGiorniIndennitaDiFunzione = 0
                let codiceIndennitaOperativa = "";
                let GPGEccedenza = ""
                let codiceIndennitaDiFunzione = ""
                el.arrayIndennita.forEach((elArrayIndennita) => {
                    //cosa succede se ho indennita normale e indannita con gpg ma minore?
                    if (elArrayIndennita && elArrayIndennita.idServiziEff) {
                        let sommaMinutiOperativa = (el.oreInt * 60) + el.minutiInt
                        if (sommaMinutiOperativa > minutiServizioIndennitaOperativa) {
                            minutiServizioIndennitaOperativa = sommaMinutiOperativa;
                            oreGiorniIndennitaOperativa = elArrayIndennita.oreGiorni;
                            codiceIndennitaOperativa = elArrayIndennita.codiceIndennitaOperativa
                            GPGEccedenza = elArrayIndennita.GPGEccedenza
                        }
                    }
                    if (elArrayIndennita && elArrayIndennita.idIndennitaDiFunz) {
                        let sommaMinutiFunzione = (el.oreInt * 60) + el.minutiInt
                        if (sommaMinutiFunzione > minutiServizioIndennitaDiFunzione) {
                            minutiServizioIndennitaDiFunzione = sommaMinutiFunzione;
                            oreGiorniIndennitaDiFunzione = elArrayIndennita.oreGiorni;
                            codiceIndennitaDiFunzione = elArrayIndennita.codiceIndennitaDiFunzione
                        }

                    }
                })
                let indexGiorno = arrayGiorni.findIndex(giorno => { return giorno.data == el.data })
                if (indexGiorno == -1) {
                    arrayGiorni.push({ data: el.data, GPGEccedenza: GPGEccedenza, codiceIndennitaOperativa: codiceIndennitaOperativa, oreGiorniIndennitaOperativa: oreGiorniIndennitaOperativa, minutiServizioIndennitaOperativa: minutiServizioIndennitaOperativa, codiceIndennitaDiFunzione: codiceIndennitaDiFunzione, oreGiorniIndennitaDiFunzione: oreGiorniIndennitaDiFunzione, minutiServizioIndennitaDiFunzione: minutiServizioIndennitaDiFunzione })
                } else {
                    if (minutiServizioIndennitaOperativa > arrayGiorni[indexGiorno].minutiServizioIndennitaOperativa) {
                        arrayGiorni[indexGiorno].codiceIndennitaOperativa = codiceIndennitaOperativa;
                        arrayGiorni[indexGiorno].oreGiorniIndennitaOperativa = oreGiorniIndennitaOperativa;
                        arrayGiorni[indexGiorno].minutiServizioIndennitaOperativa = minutiServizioIndennitaOperativa;
                        arrayGiorni[indexGiorno].GPGEccedenza = GPGEccedenza;
                    }
                    if (minutiServizioIndennitaDiFunzione > arrayGiorni[indexGiorno].minutiServizioIndennitaDiFunzione) {
                        arrayGiorni[indexGiorno].codiceIndennitaDiFunzione = codiceIndennitaDiFunzione;
                        arrayGiorni[indexGiorno].oreGiorniIndennitaDiFunzione = oreGiorniIndennitaDiFunzione;
                        arrayGiorni[indexGiorno].minutiServizioIndennitaDiFunzione = minutiServizioIndennitaDiFunzione;
                    }
                }
            }
        })
        //console.log("---------------------------")
        arrayGiorni.map(giorno => {
            //console.log(giorno.codiceIndennitaOperativa)
            let indexTabIndennita = indennitaArray.findIndex(indennitaElement => indennitaElement.codice == giorno.codiceIndennitaOperativa)
            if (indexTabIndennita > -1) {
                indennitaArray[indexTabIndennita].oreGiorni = parseInt(indennitaArray[indexTabIndennita].oreGiorni) + parseInt(giorno.oreGiorniIndennitaOperativa);
            }
            //console.log(indennitaArray[22])
            //console.log(indennitaArray[23])
            if (dipendente.qualifica == "gpg" && dipendente.eccedenza) {
                let indexTabGPGEccedenza = indennitaArray.findIndex(indennitaElement => indennitaElement.codice == giorno.GPGEccedenza)
                if (indexTabGPGEccedenza > -1) {
                    indennitaArray[indexTabGPGEccedenza].oreGiorni = parseInt(indennitaArray[indexTabGPGEccedenza].oreGiorni) + parseInt(giorno.oreGiorniIndennitaOperativa);
                }
            }
            if (dipendente.qualifica == "gpg" && arrayIdMansioniIndennitaDiFunzione.includes(dipendente.idMansioni)) {
                let indexTabIndennitaDiFunzione = indennitaArray.findIndex(indennitaElement => indennitaElement.codice == giorno.codiceIndennitaDiFunzione)
                //console.log(indexTabIndennitaDiFunzione)
                if (indexTabIndennitaDiFunzione > -1) {
                    indennitaArray[indexTabIndennitaDiFunzione].oreGiorni = parseInt(indennitaArray[indexTabIndennitaDiFunzione].oreGiorni) + parseInt(giorno.oreGiorniIndennitaDiFunzione);
                }
            }
        })
        //console.log(arrayGiorni)
        return indennitaArray
    }



    useEffect(() => {
        setIncarichiLoading(true)
        setCsvData([])
        setIncarichiDipendenteArray([])
        setTutteIndennitaiDipendenteArray([])
        setRiposiLavorati(0)
        setRiposiRecuperati(0)
        setIndennita(initialStateIndennita)
    }, [dataInizioInput, dataFineInput, idDipendente]);

    useEffect(() => {
        setIncarichiDipendenteSalvatiArray(incarichiDipendenteSalvati)
    }, [incarichiDipendenteSalvati]);

    useEffect(() => {
        if (colonnaPersonalizzata1) {
            cleanValoriColonna(colonnaPersonalizzata1)
        }
    }, [colonnaPersonalizzata1]);

    useEffect(() => {
        if (colonnaPersonalizzata2) {
            cleanValoriColonna(colonnaPersonalizzata2)
        }
    }, [colonnaPersonalizzata2]);

    const onChangeRangeDateInizio = (data) => {
        setDataFineInput(setHours(setMinutes(endOfMonth(data), 59), 23))
        setDataInizioInput(data)
    }


    const onChangeRangeDateFine = (data) => {
        setDataFineInput(data)
    }


    const toIsoString = (date) => {
        const pad = function (num) {
            return (num < 10 ? '0' : '') + num;
        };

        return pad(date.getDate()) +
            '-' + pad(date.getMonth() + 1) +
            '-' + date.getFullYear()
    }
    const formattedNumber = (num) => ("0" + num).slice(-2);

    const calcolaStraordinariBancaOre = ({ ore, percBancaOre }) => {
        //MANCA DA SOSTITUIRE IL 7 CON LE ORE ORDINARIE PERSONALI DEL DIPENDENTE
        let hours = 0, minutes = 0;
        const split = ore.split(':');
        hours = parseInt(split[0]);
        minutes = parseInt(split[1]);
        if (hours >= 7) {
            hours = hours - 7
            if ((hours > 1 || (hours == 1 && minutes > 0)) && percBancaOre == "100") {
                hours = hours - 1
                return { straordinari: "01:00", bancaOre: formattedNumber(hours) + ':' + formattedNumber(minutes) }
            } else {
                return { straordinari: formattedNumber(hours) + ':' + formattedNumber(minutes), bancaOre: "" }
            }
        } else {
            return { straordinari: "", bancaOre: "" }
        }
    }

    const sameDay = (d1, d2) => {
        return d1.getFullYear() === d2.getFullYear() &&
            d1.getMonth() === d2.getMonth() &&
            d1.getDate() === d2.getDate();
    }

    const getOreInt = (ore) => {
        if (ore && ore.length > 0) {
            const split = ore.split(':');
            return parseInt(split[0])
        } else {
            return 0
        }
    }

    const getMinutiInt = (ore) => {
        if (ore && ore.length > 0) {
            const split = ore.split(':');
            return parseFloat(split[1])
        } else {
            return 0
        }
    }

    const formattaOre = (ore) => {
        return (ore <= 9 ? "0" : "") + ore
    }

    const formattaMinuti = (minuti) => {
        return (minuti <= 9 ? "0" : "") + minuti;
    }


    const fromMinutiToOreString = (minutiTotali) => {
        if (minutiTotali > 0) {
            var minuti = minutiTotali % 60;
            var ore = (minutiTotali - minuti) / 60;
            if (ore == 0 && minuti == 0) {
                return ""
            } else {
                return formattaOre(ore) + ":" + formattaMinuti(minuti)
            }
        } else {
            return ""
        }
    }

    const fromStringToMinuti = (oreString) => {
        var minuti = getMinutiInt(oreString);
        var ore = getOreInt(oreString) * 60
        return ore + minuti
    }

    const getOreOrdinarieOLD = (ore, minuti) => {
        if (ore >= 7) {
            return "07:00"
        } else {
            return formattaOre(ore) + ":" + formattaMinuti(minuti)
        }
    }

    const getOreOrdinarie = (ore, minuti, ferOrd) => {
        let oreFerOrd = getOreInt(ferOrd)
        let minFerOrd = getMinutiInt(ferOrd)
        if (ore > oreFerOrd) {
            return ferOrd
        } else if (ore == oreFerOrd) {
            if (minuti > minFerOrd) {
                return ferOrd
            } else {
                return formattaOre(ore) + ":" + formattaMinuti(minuti)
            }
        } else {
            return formattaOre(ore) + ":" + formattaMinuti(minuti)
        }
    }

    const getOreNonLavorateOLD = (ore, minuti) => {
        if (ore >= 7) {
            return ""
        } else {
            let minutiTotali = (ore * 60) + (minuti);
            const minutiNonLavorati = (7 * 60) - minutiTotali;
            return fromMinutiToOreString(minutiNonLavorati)
        }
    }

    const getOreNonLavorate = (ore, minuti, ferOrd) => {
        let oreFerOrd = getOreInt(ferOrd)
        let minFerOrd = getMinutiInt(ferOrd)
        if (ore > oreFerOrd) {
            return ""
        } else if (ore == oreFerOrd) {
            if (minuti > minFerOrd) {
                return ""
            } else {
                let minutiTotali = (ore * 60) + (minuti);
                const minutiNonLavorati = (oreFerOrd * 60) + minFerOrd - minutiTotali;
                return minutiNonLavorati == 0 ? "" : fromMinutiToOreString(minutiNonLavorati)
            }
        } else {
            let minutiTotali = (ore * 60) + (minuti);
            const minutiNonLavorati = (oreFerOrd * 60) + minFerOrd - minutiTotali;
            return minutiNonLavorati == 0 ? "" : fromMinutiToOreString(minutiNonLavorati)
        }
    }

    const getOreStraordinarieOLD = (ore, minuti) => {
        if (ore >= 7) {
            if (ore >= 8) {
                return "01:00";
            }
            else {
                return "00:" + formattaMinuti(minuti);
            }
        } else {
            return ""
        }
    }

    const getOreStraordinarie = (ore, minuti, ferOrd, qualifica) => {
        let oreFerOrd = getOreInt(ferOrd)
        let minFerOrd = getMinutiInt(ferOrd)
        let sommaMinFerOrd = (oreFerOrd * 60) + minFerOrd
        let sommaMinServizio = (ore * 60) + minuti
        let diff = sommaMinServizio - sommaMinFerOrd
        if (qualifica == 'gpg') {
            if (diff <= 0) {
                return ""
            } else {
                if (diff > 60) {
                    return "01:00"
                } else {
                    return fromMinutiToOreString(diff)
                }
            }
        } else {
            if (diff <= 0) {
                return ""
            } else {
                return fromMinutiToOreString(diff)
            }
        }
    }

    const getOreStraordinarieGPG = (ore, minuti, ferOrd) => {
        let oreFerOrd = getOreInt(ferOrd)
        let minFerOrd = getMinutiInt(ferOrd)
        let sommaMinFerOrd = (oreFerOrd * 60) + minFerOrd
        let sommaMinServizio = (ore * 60) + minuti
        let diff = sommaMinServizio - sommaMinFerOrd
        if (diff <= 0) {
            return ""
        } else {
            if (diff > 60) {
                return "01:00"
            } else {
                return fromMinutiToOreString(diff)
            }
        }
    }

    const getOreStraordinarieOTS = (ore, minuti, ferOrd) => {
        let oreFerOrd = getOreInt(ferOrd)
        let minFerOrd = getMinutiInt(ferOrd)
        let sommaMinFerOrd = (oreFerOrd * 60) + minFerOrd
        let sommaMinServizio = (ore * 60) + minuti
        let diff = sommaMinServizio - sommaMinFerOrd
        if (diff <= 0) {
            return ""
        } else {
            return fromMinutiToOreString(diff)
        }
    }

    const getOreLavorateDomenicaIniziandoSabato = (oraInizio, oraFine) => {
        let minutiIntInizio = fromStringToMinuti(oraInizio)
        let minutiIntFine = fromStringToMinuti(oraFine)
        if (minutiIntInizio > minutiIntFine) {
            if (minutiIntFine <= minuti06) {
                return { giorno: 0, notte: minutiIntFine, exist: true }
            }
            if (minutiIntFine > minuti06 && minutiIntFine <= minuti22) {
                return { giorno: minutiIntFine - minuti06, notte: minuti06, exist: true }
            }
            if (minutiIntFine > minuti22) {
                let minutiDopoLe22 = minutiIntFine - minuti22
                return { giorno: minutiIntFine - minuti06 - minutiDopoLe22, notte: minuti06 + minutiDopoLe22, exist: true }
            }
        } else {
            return { giorno: 0, notte: 0, exist: false }
        }
    }

    const getOreStraordinarieFestiveOLD = (ore, minuti) => {
        if (ore <= 7) {
            if (minuti > 0) {
                return "00:" + formattaMinuti(minuti);
            } else {
                return "00:00";
            }
        } else {
            let oreStraordinarieFestive = ore - 7;
            return formattaOre(oreStraordinarieFestive) + ":" + formattaMinuti(minuti);
        }
    }

    const getOreStraordinarieFestive = (ore, minuti, ferOrd) => {
        let oreFerOrd = getOreInt(ferOrd)
        let minFerOrd = getMinutiInt(ferOrd)
        let sommaMinFerOrd = (oreFerOrd * 60) + minFerOrd
        let sommaMinServizio = (ore * 60) + minuti
        let diff = sommaMinServizio - sommaMinFerOrd
        if (diff <= 0) {
            return ""
        } else {
            return fromMinutiToOreString(diff)
        }
    }

    const getOreStraordinarieRiposoOLD = (ore, minuti) => {
        if (ore <= 7) {
            if (minuti > 0) {
                return "00:" + formattaMinuti(minuti);
            } else {
                return "00:00";
            }
        } else {
            let oreStraordinarieRiposo = ore - 7;
            return formattaOre(oreStraordinarieRiposo) + ":" + formattaMinuti(minuti);
        }
    }

    const getOreStraordinarieRiposo = (ore, minuti, ferOrd, tipologiaFatturazione) => {
        let sommaMinServizio = (ore * 60) + minuti
        if (tipologiaFatturazione == "B") {
            return fromMinutiToOreString(sommaMinServizio)
        } else {
            let oreFerOrd = getOreInt(ferOrd)
            let minFerOrd = getMinutiInt(ferOrd)
            let sommaMinFerOrd = (oreFerOrd * 60) + minFerOrd
            let diff = sommaMinServizio - sommaMinFerOrd
            if (diff <= 0) {
                return ""
            } else {
                return fromMinutiToOreString(diff)
            }
        }
    }

    const getOreBancaOreOLD = (ore, minuti) => {
        if (ore > 8 || (ore === 8 && minuti > 0)) {
            if (ore === 8) {
                return "00:" + formattaMinuti(minuti);
            } else {
                let oreBancaOre = ore - 8;
                return formattaOre(oreBancaOre) + ":" + formattaMinuti(minuti);
            }
        } else {
            return "";
        }
    }

    const getOreBancaOre = (ore, minuti, ferOrd) => {
        let oreFerOrd = getOreInt(ferOrd)
        let minFerOrd = getMinutiInt(ferOrd)
        let sommaMinFerOrd = (oreFerOrd * 60) + minFerOrd
        let sommaMinServizio = (ore * 60) + minuti
        let diff = sommaMinServizio - 60 - sommaMinFerOrd
        if (diff <= 0) {
            return ""
        } else {
            return fromMinutiToOreString(diff)
        }
    }

    const getMetaBancaOreOLD = (ore, minuti) => {
        if (ore > 8 || (ore === 8 && minuti > 0)) {
            if (ore === 8) {
                return "00:" + formattaMinuti(minuti / 2);
            } else {
                let oreBancaOre = ore - 8;
                return formattaOre(oreBancaOre / 2) + ":" + formattaMinuti(minuti / 2);
            }
        } else {
            return "";
        }
    }

    const getMetaBancaOre = (ore, minuti, ferOrd) => {
        let oreFerOrd = getOreInt(ferOrd)
        let minFerOrd = getMinutiInt(ferOrd)
        let sommaMinFerOrd = (oreFerOrd * 60) + minFerOrd
        let sommaMinServizio = (ore * 60) + minuti
        let diff = sommaMinServizio - 60 - sommaMinFerOrd
        if (diff <= 0) {
            return ""
        } else {
            return fromMinutiToOreString(diff / 2)
        }
    }

    const cleanValoriColonna = (nomeColonna) => {
        let array = [...incarichiDipendenteArray];
        array.map((el) => {
            el[nomeColonna] = "";
        })
        setIncarichiDipendenteArray(array)
    }

    const timeToDecimal = (t) => {
        let arr = t.split(':');
        let dec = parseInt((arr[1] / 6) * 10, 10);
        let totale = parseFloat(parseInt(arr[0], 10) + '.' + (dec < 10 ? '0' : '') + dec)
        return Math.round(totale * 100) / 100
    }

    const getOreNotturneStessoGiorno = (oraInizio, oraFine) => {
        let oreInizio = fromStringToMinuti(oraInizio)
        let oreFine = fromStringToMinuti(oraFine)
        let oreNotte = 0
        if (oreInizio < minuti06 && oreFine <= minuti06) {
            oreNotte = oreFine - oreInizio
        } else if (oreInizio >= minuti22 && oreFine > minuti22) {
            oreNotte = oreFine - oreInizio
        } else {
            if (oreInizio < minuti06) {
                oreNotte = minuti06 - oreInizio
            } else if (oreInizio >= minuti22) {
                oreNotte = oreInizio - minuti22
            }

            if (oreFine <= minuti06) {
                oreNotte = oreNotte + oreFine
            } else if (oreFine > minuti22) {
                oreNotte = oreNotte + (oreFine - minuti22)
            }
        }
        return oreNotte
    }

    const getOreNotturneFineLavorate = (oraInizio, oraFine, oreStraordinarie) => {
        let oreIntFine = getOreInt(oraFine)
        let minutiIntInizio = fromStringToMinuti(oraInizio)
        let minutiIntFine = fromStringToMinuti(oraFine)
        let minutiStraordianari = fromStringToMinuti(oreStraordinarie)
        let diff;
        if (minutiIntFine >= minutiIntInizio) {
            diff = minutiIntFine - (22 * 60);
            if (diff > 0) {
                return fromMinutiToOreString(diff)
            } else {
                return ""
            }
        } else {
            if (oreIntFine >= 22) {
                diff = minutiIntFine - (22 * 60);
                if (diff > 0) {
                    if (diff > minutiStraordianari) {
                        return fromMinutiToOreString(diff)
                    } else {
                        return oreStraordinarie
                    }
                } else {
                    return ""
                }
            } else {
                if (oreIntFine > 6) {
                    if (minutiStraordianari > 360) {
                        return "06:00"
                    } else {
                        return oreStraordinarie
                    }
                } else {
                    if (minutiStraordianari > minutiIntFine) {
                        return oraFine
                    } else {
                        return oreStraordinarie
                    }
                }
            }
        }

    }

    const getOreNotte = (oraInizio, oraFine) => {
        let minutiOraInizio = fromStringToMinuti(oraInizio)
        let minutiOraFine = fromStringToMinuti(oraFine)
        let somma = 0;
        let diff;
        if (minutiOraInizio > minutiOraFine) {
            if (minutiOraInizio > minuti22) {
                somma = minutiOraInizio - minuti22
            } else {
                somma = 120
            }
            if (minutiOraFine >= minuti06) {
                somma = somma + minuti06
            } else {
                diff = minuti06 - minutiOraFine
                somma = somma + diff
            }
        } else {
            if (minutiOraInizio < minuti06) {
                somma = minuti06 - minutiOraInizio
            }
            if (minutiOraFine > minuti22) {
                diff = minutiOraFine - minuti22
                somma = somma + diff
            }
        }
        if (somma == 0) {
            return ""
        } else {
            return somma
        }
    }

    const getOreStraordinarieNotte = (oraInizio, oraFine, oreStraordinarie) => {
        let minutiOreStraordinarie = fromStringToMinuti(oreStraordinarie)
        let somma = 0;
        if (minutiOreStraordinarie > 0) {
            let minutiOraInizio = fromStringToMinuti(oraInizio)
            let minutiOraFine = fromStringToMinuti(oraFine)
            if (minutiOraInizio < minutiOraFine) {
                if (minutiOraFine > minuti22) {
                    somma = minutiOraFine - minuti22
                }
            }
            if (minutiOraInizio > minutiOraFine) {
                let x = minutiOraFine - minutiOreStraordinarie
                if (x < 0) {
                    if (minutiOraFine >= minuti06) {
                        somma = minuti06
                        if (x < -120) {
                            somma = somma + 120
                        } else {
                            somma = somma + (-x)
                        }
                    }
                    if (minutiOraFine >= 0 && minutiOraFine < minuti06) {
                        if (x <= -120) {
                            somma = 120 + minutiOraFine
                        } else {
                            somma = minutiOraFine + (-x)
                        }
                        //somma = minutiOreStraordinarie + (-x)
                    }
                }
                if (x >= 0 && x <= minuti06) {
                    if (minutiOraFine <= minuti06) {
                        somma = minutiOreStraordinarie
                    } else {
                        somma = minuti06 - x
                    }
                }
                if (x > minuti22) {
                    somma = minuti22 - x
                }
            }
        }
        if (somma == 0) {
            return ""
        } else {
            return somma
        }
    }

    const getCalcoliIncarico = (el, objIncarico, tipologiaFatturazione) => {
        if (el.effettivo == "AS") {
            let giornoSettimana = getFirstLetterOfDay(objIncarico.giornoSettimana)
            objIncarico.oraInizio = el.oraInizio;
            objIncarico.oraFine = el.oraFine;
            let oreInt = objIncarico.oreInt;
            let minutiInt = objIncarico.minutiInt;
            let ferOrd = objIncarico.ferOrd
            if (objIncarico.multiplo && objIncarico.isLastMultiplo) {
                oreInt = objIncarico.oreMultipliInt;
                minutiInt = objIncarico.minutiMultipliInt;
            }
            objIncarico.oreOrdinarie = getOreOrdinarie(oreInt, minutiInt, ferOrd);
            objIncarico.oreNonLavorate = getOreNonLavorate(oreInt, minutiInt, ferOrd)
            if (objIncarico.disponibilita !== "RP" && objIncarico.disponibilita !== "RR" && !objIncarico.festivo) {
                if (objIncarico.qualifica == 'gpg') {
                    objIncarico.oreStraordinarie = getOreStraordinarie(oreInt, minutiInt, ferOrd, objIncarico.qualifica)
                } else {
                    objIncarico.oreStraordinarie = getOreStraordinarieOTS(oreInt, minutiInt, ferOrd)
                    if (objIncarico.oreStraordinarie.length > 0) {
                        objIncarico.minutiNotteLavoratiFine = getOreNotturneFineLavorate(objIncarico.oraInizio, objIncarico.oraFine, objIncarico.oreStraordinarie)

                    }
                }
                if (giornoSettimana !== "D") {
                    if (!objIncarico.percBancaOre || objIncarico.percBancaOre == "0") {
                        objIncarico.bancaOreRetrib = getOreBancaOre(oreInt, minutiInt, ferOrd)
                    } else if (objIncarico.percBancaOre == "50") {
                        objIncarico.bancaOreRetrib = getMetaBancaOre(oreInt, minutiInt, ferOrd)
                        objIncarico.bancaOreDaFruire = getMetaBancaOre(oreInt, minutiInt, ferOrd)
                    } else if (objIncarico.percBancaOre == "100") {
                        objIncarico.bancaOreDaFruire = getOreBancaOre(oreInt, minutiInt, ferOrd)
                    }
                }
            }
            if (tipologiaFatturazione == "A") {
                if (giornoSettimana === "D") {
                    if (objIncarico.qualifica == 'ots') {
                        if (objIncarico.isNotturna) {
                            let diff = getOreNotte(objIncarico.oraInizio, objIncarico.oraFine)
                            objIncarico.indennitaDomenicaleOTSNotte = fromMinutiToOreString(diff);
                            let oreGiorno = fromStringToMinuti(objIncarico.ore) - diff
                            objIncarico.indennitaDomenicaleOTSGiorno = fromMinutiToOreString(oreGiorno);
                        } else {
                            objIncarico.indennitaDomenicaleOTSGiorno = objIncarico.ore;
                        }
                    } else {
                        objIncarico.indennitaDomenicale = objIncarico.ore;
                    }
                }
                if (objIncarico.festivo) {
                    if (objIncarico.qualifica == 'ots') {
                        if (objIncarico.isNotturna) {
                            objIncarico.oreStraordinarieFestiveOTSNotte = getOreStraordinarieFestive(oreInt, minutiInt, ferOrd)
                        } else {
                            objIncarico.oreStraordinarieFestiveOTSGiorno = getOreStraordinarieFestive(oreInt, minutiInt, ferOrd)
                        }
                    } else {
                        objIncarico.oreStraordinarieFestive = getOreStraordinarieFestive(oreInt, minutiInt, ferOrd)
                    }
                }
                if (objIncarico.disponibilita === "RP") {
                    objIncarico.oreStrRipSp = getOreStraordinarieRiposo(oreInt, minutiInt, ferOrd, tipologiaFatturazione);
                }
            }
            if (tipologiaFatturazione == "B") {
                if (objIncarico.qualifica == 'ots') {
                    if (giornoSettimana === "S") {
                        objIncarico.bancaOreRetrib = "";
                        objIncarico.indennitaDomenicale = "";
                        objIncarico.bancaOreDaFruire = "";
                        objIncarico.oreOrdinarie = ""
                        objIncarico.oreNonLavorate = ""
                        objIncarico.oreStraordinarie = fromMinutiToOreString((oreInt * 60) + minutiInt);
                    }
                    if (giornoSettimana === "D") {
                        objIncarico.bancaOreRetrib = "";
                        objIncarico.indennitaDomenicale = "";
                        objIncarico.bancaOreDaFruire = "";
                        objIncarico.oreOrdinarie = ""
                        objIncarico.oreNonLavorate = ""
                        objIncarico.oreStraordinarie = fromMinutiToOreString((oreInt * 60) + minutiInt);
                        if (objIncarico.isNotturna) {
                            objIncarico.indennitaDomenicaleOTSNotte = objIncarico.oreStraordinarie;
                        } else {
                            objIncarico.indennitaDomenicaleOTSGiorno = objIncarico.oreStraordinarie;
                        }
                    }
                } else {
                    if (giornoSettimana === "S") {
                        objIncarico.bancaOreRetrib = fromMinutiToOreString((oreInt * 60) + minutiInt)
                        objIncarico.indennitaDomenicale = "";
                        objIncarico.bancaOreDaFruire = "";
                        objIncarico.oreOrdinarie = ""
                        objIncarico.oreStraordinarie = "";
                    }
                    if (giornoSettimana === "D") {
                        objIncarico.bancaOreRetrib = fromMinutiToOreString((oreInt * 60) + minutiInt)
                        objIncarico.indennitaDomenicale = fromMinutiToOreString((oreInt * 60) + minutiInt);
                        objIncarico.bancaOreDaFruire = "";
                        objIncarico.oreOrdinarie = ""
                        objIncarico.oreStraordinarie = "";
                    }
                }





                if (objIncarico.festivo) {
                    if (objIncarico.qualifica == 'ots') {
                        if (objIncarico.isNotturna) {
                            objIncarico.oreStraordinarieFestiveOTSNotte = getOreStraordinarieFestive(oreInt, minutiInt, ferOrd)
                        } else {
                            objIncarico.oreStraordinarieFestiveOTSGiorno = getOreStraordinarieFestive(oreInt, minutiInt, ferOrd)
                        }
                    } else {
                        objIncarico.oreStraordinarieFestive = getOreStraordinarieFestive(oreInt, minutiInt, ferOrd)
                    }
                }
            }
        }
        return objIncarico
    }

    const getCalcoliIncaricoGPGSAVE = (incarico, tipologiaFatturazione) => {
        if (incarico.effettivo == "AS") {
            let giornoSettimana = getFirstLetterOfDay(incarico.giornoSettimana)
            let oreInt = incarico.oreInt;
            let minutiInt = incarico.minutiInt;
            let ferOrd = incarico.ferOrd
            if (incarico.multiplo && incarico.isLastMultiplo) {
                oreInt = incarico.oreMultipliInt;
                minutiInt = incarico.minutiMultipliInt;
            }
            incarico.oreOrdinarie = getOreOrdinarie(oreInt, minutiInt, ferOrd);
            incarico.oreNonLavorate = getOreNonLavorate(oreInt, minutiInt, ferOrd)
            if (incarico.disponibilita !== "RP" && incarico.disponibilita !== "RR" && !incarico.festivo) {
                incarico.oreStraordinarie = getOreStraordinarieGPG(oreInt, minutiInt, ferOrd, incarico.qualifica)
                if (giornoSettimana !== "D") {
                    if (!incarico.percBancaOre || incarico.percBancaOre == "0") {
                        incarico.bancaOreRetrib = getOreBancaOre(oreInt, minutiInt, ferOrd)
                    } else if (incarico.percBancaOre == "50") {
                        incarico.bancaOreRetrib = getMetaBancaOre(oreInt, minutiInt, ferOrd)
                        incarico.bancaOreDaFruire = getMetaBancaOre(oreInt, minutiInt, ferOrd)
                    } else if (incarico.percBancaOre == "100") {
                        incarico.bancaOreDaFruire = getOreBancaOre(oreInt, minutiInt, ferOrd)
                    }
                }
            }
            if (incarico.festivo) {
                incarico.oreStraordinarieFestive = getOreStraordinarieFestive(oreInt, minutiInt, ferOrd)
            }

            if (tipologiaFatturazione == "A") {
                if (giornoSettimana === "D") {
                    incarico.indennitaDomenicale = incarico.ore;
                }
                if (incarico.disponibilita === "RP") {
                    incarico.oreStrRipSp = getOreStraordinarieRiposo(oreInt, minutiInt, ferOrd, tipologiaFatturazione);
                }
            }

            //se filiale Elia tutti i sabati e le domeniche vanno in banca ore retribuita
            if (tipologiaFatturazione == "B") {
                if (giornoSettimana === "S") {
                    incarico.bancaOreRetrib = fromMinutiToOreString((oreInt * 60) + minutiInt)
                    incarico.indennitaDomenicale = "";
                    incarico.bancaOreDaFruire = "";
                    //incarico.oreOrdinarie = ""
                    incarico.oreStraordinarie = "";
                    incarico.oreNonLavorate = "";
                }
                if (giornoSettimana === "D") {
                    incarico.bancaOreRetrib = fromMinutiToOreString((oreInt * 60) + minutiInt)
                    incarico.indennitaDomenicale = fromMinutiToOreString((oreInt * 60) + minutiInt);
                    incarico.bancaOreDaFruire = "";
                    //incarico.oreOrdinarie = ""
                    incarico.oreStraordinarie = "";
                    incarico.oreNonLavorate = "";
                }
            }
        }
        return incarico
    }

    const getOreStraordinariePrimaEDopoMezzaNotteGPG = (oreInizio, oreFine, sommaOreTurni, oreOrdinarie) => {
        let prima = 0;
        let dopo = 0;
        if (oreInizio < oreFine) {
            let oreStraordinarie = sommaOreTurni - oreOrdinarie
            prima = oreStraordinarie > 0 ? oreStraordinarie : 0
        } else {
            let oreLavoratePrimaMezzanotte = minuti24 - oreInizio
            let oreLavorateDopoMezzanotte = oreFine
            let oreLavorateTotali = oreLavoratePrimaMezzanotte + oreLavorateDopoMezzanotte
            let oreLavoraTurniMultipli = sommaOreTurni - oreLavorateTotali
            oreLavoraTurniMultipli = oreLavoraTurniMultipli > 0 ? oreLavoraTurniMultipli : 0
            let oreStraordinariePrima = oreLavoratePrimaMezzanotte - oreOrdinarie
            prima = oreStraordinariePrima > 0 ? oreStraordinariePrima : 0
            let offset = (oreInizio + oreOrdinarie - oreLavoraTurniMultipli) - minuti24
            offset = offset > 0 ? offset : 0
            let oreStraordinarieDopo = oreFine - offset
            dopo = oreStraordinarieDopo > 0 ? oreStraordinarieDopo : 0
        }
        return { prima: prima, dopo: dopo }
    }

    const getOreLavoratePrimaEDopo = (oreInizio, oreFine) => {
        let prima = 0;
        let dopo = 0;
        if (oreInizio < oreFine) {
            prima = oreFine - oreInizio
        } else {
            prima = minuti24 - oreInizio
            dopo = oreFine
        }
        return { prima: prima, dopo: dopo }
    }


    const getCalcoliIncaricoGPGSAVE2 = (incarico, tipologiaFatturazione) => {
        if (incarico.effettivo == "AS") {
            let oreStraordiariePrima = 0
            let oreStraordiarieDopo = 0
            let oreTotaliStraordiarie = 0
            let giornoSettimana = getFirstLetterOfDay(incarico.giornoSettimana)
            let oreInt = incarico.oreInt;
            let minutiInt = incarico.minutiInt;
            let ferOrd = incarico.ferOrd
            let oreOrdinarie = fromStringToMinuti(ferOrd)
            let oreInizio = fromStringToMinuti(incarico.oraInizio)
            let oreFine = fromStringToMinuti(incarico.oraFine)

            if (incarico.multiplo && incarico.isLastMultiplo) {
                oreInt = incarico.oreMultipliInt;
                minutiInt = incarico.minutiMultipliInt;
            }
            let minutiTotali = minutiInt + (oreInt * 60)
            let oreLavoratePrimaDopo = getOreLavoratePrimaEDopo(oreInizio, oreFine)
            let oreLavoratePrima = oreLavoratePrimaDopo.prima
            let oreLavorateDopo = oreLavoratePrimaDopo.dopo

            incarico.oreOrdinarie = getOreOrdinarie(oreInt, minutiInt, ferOrd);
            incarico.oreNonLavorate = getOreNonLavorate(oreInt, minutiInt, ferOrd)
            if (!incarico.oreNonLavorate) {

                let oreStraordinarie = getOreStraordinariePrimaEDopoMezzaNotteGPG(oreInizio, oreFine, minutiTotali, oreOrdinarie)
                oreStraordiariePrima = oreStraordinarie.prima
                oreStraordiarieDopo = oreStraordinarie.dopo
            }
            oreTotaliStraordiarie = oreStraordiariePrima + oreStraordiarieDopo

            if (!incarico.festivo && !incarico.festivoGiornoDopo) {
                if (incarico.disponibilita == "DI") {
                    if (incarico.disponibilitaGiornoDopo == "DI") {
                        incarico.oreStraordinarie = oreTotaliStraordiarie > 60 ? "01:00" : fromMinutiToOreString(oreTotaliStraordiarie)
                        oreTotaliStraordiarie = oreTotaliStraordiarie - 60
                        oreTotaliStraordiarie = oreTotaliStraordiarie > 0 ? oreTotaliStraordiarie : 0
                        if (!incarico.percBancaOre || incarico.percBancaOre == "0") {
                            incarico.bancaOreRetrib = fromMinutiToOreString(oreTotaliStraordiarie)
                        } else if (incarico.percBancaOre == "50") {
                            let meta = oreTotaliStraordiarie / 2
                            incarico.bancaOreRetrib = fromMinutiToOreString(meta)
                            incarico.bancaOreDaFruire = fromMinutiToOreString(meta)
                        } else if (incarico.percBancaOre == "100") {
                            incarico.bancaOreDaFruire = fromMinutiToOreString(oreTotaliStraordiarie)
                        }
                    } else {
                        incarico.oreStraordinarie = oreStraordiariePrima > 60 ? "01:00" : fromMinutiToOreString(oreStraordiariePrima)
                        oreStraordiariePrima = oreStraordiariePrima - 60
                        oreStraordiariePrima = oreStraordiariePrima > 0 ? oreStraordiariePrima : 0
                        if (!incarico.percBancaOre || incarico.percBancaOre == "0") {
                            incarico.bancaOreRetrib = fromMinutiToOreString(oreStraordiariePrima)
                        } else if (incarico.percBancaOre == "50") {
                            let meta = oreStraordiariePrima / 2
                            incarico.bancaOreRetrib = fromMinutiToOreString(meta)
                            incarico.bancaOreDaFruire = fromMinutiToOreString(meta)
                        } else if (incarico.percBancaOre == "100") {
                            incarico.bancaOreDaFruire = fromMinutiToOreString(oreStraordiariePrima)
                        }
                        incarico.oreStrRipSp = fromMinutiToOreString(oreStraordiarieDopo);
                    }
                } else {
                    if (incarico.disponibilitaGiornoDopo == "DI") {
                        incarico.oreStraordinarie = oreStraordiarieDopo > 60 ? "01:00" : fromMinutiToOreString(oreStraordiarieDopo)
                        oreStraordiarieDopo = oreStraordiarieDopo - 60
                        oreStraordiarieDopo = oreStraordiarieDopo > 0 ? oreStraordiarieDopo : 0
                        if (!incarico.percBancaOre || incarico.percBancaOre == "0") {
                            incarico.bancaOreRetrib = fromMinutiToOreString(oreStraordiarieDopo)
                        } else if (incarico.percBancaOre == "50") {
                            let meta = oreStraordiarieDopo / 2
                            incarico.bancaOreRetrib = fromMinutiToOreString(meta)
                            incarico.bancaOreDaFruire = fromMinutiToOreString(meta)
                        } else if (incarico.percBancaOre == "100") {
                            incarico.bancaOreDaFruire = fromMinutiToOreString(oreStraordiarieDopo)
                        }
                        incarico.oreStrRipSp = fromMinutiToOreString(oreStraordiariePrima);
                    } else {
                        incarico.oreStrRipSp = fromMinutiToOreString(oreLavoratePrimaDopo);
                    }
                }
            } else if (incarico.festivo && !incarico.festivoGiornoDopo) {

                incarico.oreStraordinarieFestive = fromMinutiToOreString(oreOrdinarie + oreStraordiariePrima)
                if (incarico.disponibilitaGiornoDopo == "DI") {
                    let gap = (oreInizio + oreOrdinarie) - minuti24
                    gap = gap > 0 ? gap : 0
                    let oreStraordinarie = oreLavorateDopo - gap
                    incarico.oreStraordinarie = oreStraordinarie > 60 ? "01:00" : fromMinutiToOreString(oreStraordinarie)
                    oreStraordinarie = oreStraordinarie - 60
                    oreStraordinarie = oreStraordinarie > 0 ? oreStraordinarie : 0
                    incarico.oreOrdinarie = gap > 0 ? fromMinutiToOreString(gap) : ""
                    if (!incarico.percBancaOre || incarico.percBancaOre == "0") {
                        incarico.bancaOreRetrib = fromMinutiToOreString(oreStraordinarie)
                    } else if (incarico.percBancaOre == "50") {
                        let meta = oreStraordinarie / 2
                        incarico.bancaOreRetrib = fromMinutiToOreString(meta)
                        incarico.bancaOreDaFruire = fromMinutiToOreString(meta)
                    } else if (incarico.percBancaOre == "100") {
                        incarico.bancaOreDaFruire = fromMinutiToOreString(oreStraordinarie)
                    }
                } else {
                    incarico.oreStrRipSp = fromMinutiToOreString(oreLavorateDopo)
                }
            } else if (!incarico.festivo && incarico.festivoGiornoDopo) {
                incarico.oreStraordinarieFestive = fromMinutiToOreString(oreLavorateDopo)
                let oreLavoratePrimaTotali = minutiTotali - oreLavorateDopo
                let oreStraordinarie = oreLavoratePrimaTotali - oreOrdinarie
                oreStraordinarie = oreStraordinarie > 0 ? oreStraordinarie : 0
                if (incarico.disponibilita == "DI") {
                    incarico.oreStraordinarie = oreStraordinarie > 60 ? "01:00" : fromMinutiToOreString(oreStraordinarie)
                    oreStraordinarie = oreStraordinarie - 60
                    oreStraordinarie = oreStraordinarie > 0 ? oreStraordinarie : 0
                    if (!incarico.percBancaOre || incarico.percBancaOre == "0") {
                        incarico.bancaOreRetrib = fromMinutiToOreString(oreStraordinarie)
                    } else if (incarico.percBancaOre == "50") {
                        let meta = oreStraordinarie / 2
                        incarico.bancaOreRetrib = fromMinutiToOreString(meta)
                        incarico.bancaOreDaFruire = fromMinutiToOreString(meta)
                    } else if (incarico.percBancaOre == "100") {
                        incarico.bancaOreDaFruire = fromMinutiToOreString(oreStraordinarie)
                    }
                    incarico.oreOrdinarie = fromMinutiToOreString(oreLavoratePrima)
                } else {
                    incarico.oreStrRipSp = fromMinutiToOreString(oreLavoratePrimaTotali)
                }
            }


            //se filiale Elia tutti i sabati e le domeniche vanno in banca ore retribuita
            if (tipologiaFatturazione == "B") {
                if (giornoSettimana === "S") {
                    incarico.bancaOreRetrib = fromMinutiToOreString((oreInt * 60) + minutiInt)
                    incarico.indennitaDomenicale = "";
                    incarico.bancaOreDaFruire = "";
                    //incarico.oreOrdinarie = ""
                    incarico.oreStraordinarie = "";
                    incarico.oreNonLavorate = "";
                }
                if (giornoSettimana === "D") {
                    incarico.bancaOreRetrib = fromMinutiToOreString((oreInt * 60) + minutiInt)
                    incarico.indennitaDomenicale = fromMinutiToOreString((oreInt * 60) + minutiInt);
                    incarico.bancaOreDaFruire = "";
                    //incarico.oreOrdinarie = ""
                    incarico.oreStraordinarie = "";
                    incarico.oreNonLavorate = "";
                }
            }
        }
        return incarico
    }

    const getCalcoliIncaricoGPGCONSTRAORDINARIDIVISI = (incarico, tipologiaFatturazione) => {
        if(incarico.daCalcolare){
            if (incarico.effettivo == "AS") {
                let giornoSettimana = getFirstLetterOfDay(incarico.giornoSettimana)
                let oreInizio = fromStringToMinuti(incarico.oraInizio)
                let oreFine = fromStringToMinuti(incarico.oraFine)
                let oreLavorateIncarico = (incarico.oreInt * 60) + incarico.minutiInt
                let oreLavorateMultiplePost = 0
                if (incarico.multiplo && incarico.isLastMultiplo) {
                    oreLavorateMultiplePost = (incarico.oreMultipliInt * 60) + incarico.minutiMultipliInt
                    oreLavorateMultiplePost = oreLavorateMultiplePost - oreLavorateIncarico
                    oreLavorateMultiplePost = oreLavorateMultiplePost > 0 ? oreLavorateMultiplePost : 0
                }
                let orelavorateTotali = oreLavorateIncarico + oreLavorateMultiplePost
                let oreOrdinarieContratto = fromStringToMinuti(incarico.ferOrd)
                let oreOrdinarielavorate = orelavorateTotali - oreOrdinarieContratto
                oreOrdinarielavorate = oreOrdinarielavorate > 0 ? oreOrdinarieContratto : orelavorateTotali
                let oreStraordinarieTotali = orelavorateTotali - oreOrdinarieContratto
                oreStraordinarieTotali = oreStraordinarieTotali > 0 ? oreStraordinarieTotali : 0
                let oreLavoratePrimaDopo = getOreLavoratePrimaEDopo(oreInizio, oreFine)
                let oreLavoratePrima = oreLavoratePrimaDopo.prima
                let oreLavorateDopo = oreLavoratePrimaDopo.dopo
                let oreLavoratePrimaTotali = oreLavoratePrima + oreLavorateMultiplePost
                let oreOrdinarielavoratePrima = oreLavoratePrimaTotali - oreOrdinarieContratto
                oreOrdinarielavoratePrima = oreOrdinarielavoratePrima > 0 ? oreOrdinarieContratto : oreLavoratePrimaTotali
                let oreOrdinarielavorateDopo = oreOrdinarieContratto - oreLavoratePrima
                oreOrdinarielavorateDopo = oreOrdinarielavorateDopo > 0 ? (oreLavorateDopo - oreOrdinarielavorateDopo) : 0
                oreOrdinarielavorateDopo = oreOrdinarielavorateDopo > 0 ? oreOrdinarielavorateDopo : 0
                let oreStraordinariePrimaEDopo = getOreStraordinariePrimaEDopoMezzaNotteGPG(oreInizio, oreFine, orelavorateTotali, oreOrdinarieContratto)
                let oreStraordiariePrima = oreStraordinariePrimaEDopo.prima
                let oreStraordiarieDopo = oreStraordinariePrimaEDopo.dopo
                let oreStraordinarieDI = oreStraordinarieTotali > 60 ? 60 : oreStraordinarieTotali
                let oreBancaDI = oreStraordinarieTotali - 60
                oreBancaDI = oreBancaDI > 0 ? oreBancaDI : 0
                let oreStraordinarieDIPrima = oreStraordiariePrima > 60 ? 60 : oreStraordiariePrima
                let oreBancaPrima = oreStraordiariePrima - 60
                oreBancaPrima = oreBancaPrima > 0 ? oreBancaPrima : 0
                let oreStraordinarieDIDopo = oreStraordiarieDopo > 60 ? 60 : oreStraordiarieDopo
                let oreBancaDopo = oreStraordiarieDopo - 60
                oreBancaDopo = oreBancaDopo > 0 ? oreBancaDopo : 0
                let oreNonLavorate = oreOrdinarieContratto - orelavorateTotali
                oreNonLavorate = oreNonLavorate > 0 ? oreNonLavorate : 0
                incarico.oreNonLavorate = fromMinutiToOreString(oreNonLavorate)
                //INIZIO CALCOLI
                if ((incarico.disponibilita == "DI" || incarico.disponibilita == "PC") && (incarico.disponibilitaGiornoDopo == "DI" || incarico.disponibilitaGiornoDopo == "PC")) {
                    incarico.oreOrdinarie = fromMinutiToOreString(oreOrdinarielavorate)
                    incarico.oreStraordinarie = fromMinutiToOreString(oreStraordinarieDI)
                    if (!incarico.percBancaOre || incarico.percBancaOre == "0") {
                        incarico.bancaOreRetrib = fromMinutiToOreString(oreBancaDI)
                    } else if (incarico.percBancaOre == "50") {
                        let meta = oreBancaDI / 2
                        incarico.bancaOreRetrib = fromMinutiToOreString(meta)
                        incarico.bancaOreDaFruire = fromMinutiToOreString(meta)
                    } else if (incarico.percBancaOre == "100") {
                        incarico.bancaOreDaFruire = fromMinutiToOreString(oreBancaDI)
                    }
                    if (incarico.festivo && !incarico.festivoGiornoDopo) {
                        incarico.oreStraordinarie = fromMinutiToOreString(oreStraordinarieDIDopo)
                        incarico.oreStraordinarieFestive = fromMinutiToOreString(oreStraordiariePrima)
                        incarico.oreNonLavorate = ""
                        if (!incarico.percBancaOre || incarico.percBancaOre == "0") {
                            incarico.bancaOreRetrib = fromMinutiToOreString(oreBancaDopo)
                        } else if (incarico.percBancaOre == "50") {
                            let meta = oreBancaDopo / 2
                            incarico.bancaOreRetrib = fromMinutiToOreString(meta)
                            incarico.bancaOreDaFruire = fromMinutiToOreString(meta)
                        } else if (incarico.percBancaOre == "100") {
                            incarico.bancaOreDaFruire = fromMinutiToOreString(oreBancaDopo)
                        }
                    }
                    if (!incarico.festivo && incarico.festivoGiornoDopo) {
                        incarico.oreStraordinarie = fromMinutiToOreString(oreStraordinarieDIPrima)
                        incarico.oreStraordinarieFestive = fromMinutiToOreString(oreStraordiarieDopo)
                        if (!incarico.percBancaOre || incarico.percBancaOre == "0") {
                            incarico.bancaOreRetrib = fromMinutiToOreString(oreBancaPrima)
                        } else if (incarico.percBancaOre == "50") {
                            let meta = oreBancaPrima / 2
                            incarico.bancaOreRetrib = fromMinutiToOreString(meta)
                            incarico.bancaOreDaFruire = fromMinutiToOreString(meta)
                        } else if (incarico.percBancaOre == "100") {
                            incarico.bancaOreDaFruire = fromMinutiToOreString(oreBancaPrima)
                        }
                    }
                    if (incarico.festivo && incarico.festivoGiornoDopo) {
                        incarico.oreNonLavorate = ""
                        incarico.bancaOreRetrib = ""
                        incarico.bancaOreDaFruire = ""
                        incarico.oreOrdinarie = fromMinutiToOreString(oreOrdinarielavorate)
                        incarico.oreStraordinarie = ""
                        incarico.oreStraordinarieFestive = fromMinutiToOreString(oreStraordinarieTotali)
                    }
                } else if ((incarico.disponibilita == "DI" || incarico.disponibilita == "PC") && (incarico.disponibilitaGiornoDopo != "DI" && incarico.disponibilitaGiornoDopo != "PC")) {
                    incarico.oreOrdinarie = fromMinutiToOreString(oreOrdinarielavorate)
                    incarico.oreStraordinarie = fromMinutiToOreString(oreStraordinarieDIPrima)
                    if (!incarico.percBancaOre || incarico.percBancaOre == "0") {
                        incarico.bancaOreRetrib = fromMinutiToOreString(oreBancaPrima)
                    } else if (incarico.percBancaOre == "50") {
                        let meta = oreBancaPrima / 2
                        incarico.bancaOreRetrib = fromMinutiToOreString(meta)
                        incarico.bancaOreDaFruire = fromMinutiToOreString(meta)
                    } else if (incarico.percBancaOre == "100") {
                        incarico.bancaOreDaFruire = fromMinutiToOreString(oreBancaPrima)
                    }
                    incarico.oreStrRipSp = fromMinutiToOreString(oreStraordiarieDopo);
                    if (incarico.festivo && !incarico.festivoGiornoDopo) {
                        incarico.oreNonLavorate = ""
                        incarico.oreStraordinarie = ""
                        incarico.oreStraordinarieFestive = fromMinutiToOreString(oreStraordiariePrima)
                        incarico.bancaOreRetrib = ""
                        incarico.bancaOreDaFruire = ""
                    }
                    if (!incarico.festivo && incarico.festivoGiornoDopo) {
                        incarico.oreStrRipSp = ""
                        incarico.oreStraordinarieFestive = fromMinutiToOreString(oreStraordiarieDopo)
                    }
                    if (incarico.festivo && incarico.festivoGiornoDopo) {
                        incarico.oreNonLavorate = ""
                        incarico.bancaOreRetrib = ""
                        incarico.bancaOreDaFruire = ""
                        incarico.oreStrRipSp = ""
                        incarico.oreStraordinarie = ""
                        incarico.oreStraordinarieFestive = fromMinutiToOreString(oreStraordiariePrima + oreStraordiarieDopo)
                    }
                } else if ((incarico.disponibilita != "DI" && incarico.disponibilita != "PC") && (incarico.disponibilitaGiornoDopo == "DI" || incarico.disponibilitaGiornoDopo == "PC")) {
                    //incarico.oreNonLavorate = ""
                    incarico.oreOrdinarie = fromMinutiToOreString(oreOrdinarielavorate)
                    incarico.oreStrRipSp = fromMinutiToOreString(oreStraordiariePrima);
                    incarico.oreStraordinarie = fromMinutiToOreString(oreStraordinarieDIDopo)
                    if (!incarico.percBancaOre || incarico.percBancaOre == "0") {
                        incarico.bancaOreRetrib = fromMinutiToOreString(oreBancaDopo)
                    } else if (incarico.percBancaOre == "50") {
                        let meta = oreBancaDopo / 2
                        incarico.bancaOreRetrib = fromMinutiToOreString(meta)
                        incarico.bancaOreDaFruire = fromMinutiToOreString(meta)
                    } else if (incarico.percBancaOre == "100") {
                        incarico.bancaOreDaFruire = fromMinutiToOreString(oreBancaDopo)
                    }
                    if (incarico.festivo && !incarico.festivoGiornoDopo) {
                        incarico.oreStraordinarieFestive = fromMinutiToOreString(oreStraordiariePrima)
                        incarico.oreStrRipSp = ""
                    }
                    if (!incarico.festivo && incarico.festivoGiornoDopo) {
                        incarico.oreStraordinarie = ""
                        incarico.oreStraordinarieFestive = fromMinutiToOreString(oreStraordiarieDopo)
                        incarico.bancaOreRetrib = ""
                        incarico.bancaOreDaFruire = ""
                    }
                    if (incarico.festivo && incarico.festivoGiornoDopo) {
                        incarico.bancaOreRetrib = ""
                        incarico.bancaOreDaFruire = ""
                        incarico.oreStrRipSp = ""
                        incarico.oreStraordinarie = ""
                        incarico.oreStraordinarieFestive = fromMinutiToOreString(oreStraordiariePrima + oreStraordiarieDopo)
                    }
                } else if (incarico.disponibilita != "DI" && incarico.disponibilitaGiornoDopo != "DI") {
                    //incarico.oreNonLavorate = ""
                    incarico.oreOrdinarie = fromMinutiToOreString(oreOrdinarielavorate)
                    incarico.oreStrRipSp = fromMinutiToOreString(oreStraordiariePrima + oreStraordiarieDopo);
                    if (incarico.festivo && !incarico.festivoGiornoDopo) {
                        incarico.oreStrRipSp = fromMinutiToOreString(oreStraordiarieDopo);
                        incarico.oreStraordinarieFestive = fromMinutiToOreString(oreStraordiariePrima)
                    }
                    if (!incarico.festivo && incarico.festivoGiornoDopo) {
                        incarico.oreStrRipSp = fromMinutiToOreString(oreStraordiariePrima);
                        incarico.oreStraordinarieFestive = fromMinutiToOreString(oreStraordiarieDopo)
                    }
                    if (incarico.festivo && incarico.festivoGiornoDopo) {
                        incarico.oreStrRipSp = "";
                        incarico.oreStraordinarieFestive = fromMinutiToOreString(oreStraordiariePrima + oreStraordiarieDopo)
                    }
                }
    
                //DA RIVEDERE CON FESTIVI E NON
                if (tipologiaFatturazione == "B") {
                    if (giornoSettimana == "V") {
                    }
                    if (giornoSettimana == "S" && !incarico.festivo) {
                        incarico.bancaOreRetrib = fromMinutiToOreString(orelavorateTotali)
                        incarico.bancaOreDaFruire = "";
                        incarico.oreStrRipSp = ""
                        incarico.oreOrdinarie = ""
                        incarico.oreStraordinarie = "";
                        incarico.oreNonLavorate = "";
                    }
                    if (giornoSettimana == "D" && !incarico.festivo) {
                        incarico.bancaOreRetrib = fromMinutiToOreString(orelavorateTotali)
                        incarico.bancaOreDaFruire = "";
                        incarico.oreStrRipSp = ""
                        incarico.oreOrdinarie = ""
                        incarico.oreStraordinarie = "";
                        incarico.oreNonLavorate = "";
                    }
                }
    
                //AGGIUNGO INDENNITA DOMENICALE
                if (giornoSettimana === "S") {
                    incarico.indennitaDomenicale = fromMinutiToOreString(oreLavorateDopo)
                }
                if (giornoSettimana === "D") {
                    incarico.indennitaDomenicale = fromMinutiToOreString(oreLavoratePrimaTotali)
                }
            }
        }
        return incarico
    }

    const getCalcoliIncaricoGPG = (incarico, tipologiaFatturazione) => {
        if(incarico.daCalcolare){
            if (incarico.effettivo == "AS") {
                let giornoSettimana = getFirstLetterOfDay(incarico.giornoSettimana)
                let oreInizio = fromStringToMinuti(incarico.oraInizio)
                let oreFine = fromStringToMinuti(incarico.oraFine)
                let oreLavorateIncarico = (incarico.oreInt * 60) + incarico.minutiInt
                let oreLavorateMultiplePost = 0
                if (incarico.multiplo && incarico.isLastMultiplo) {
                    oreLavorateMultiplePost = (incarico.oreMultipliInt * 60) + incarico.minutiMultipliInt
                    oreLavorateMultiplePost = oreLavorateMultiplePost - oreLavorateIncarico
                    oreLavorateMultiplePost = oreLavorateMultiplePost > 0 ? oreLavorateMultiplePost : 0
                }
                let orelavorateTotali = oreLavorateIncarico + oreLavorateMultiplePost
                let oreOrdinarieContratto = fromStringToMinuti(incarico.ferOrd)
                let oreOrdinarielavorate = orelavorateTotali - oreOrdinarieContratto
                oreOrdinarielavorate = oreOrdinarielavorate > 0 ? oreOrdinarieContratto : orelavorateTotali
                let oreStraordinarieTotali = orelavorateTotali - oreOrdinarieContratto
                oreStraordinarieTotali = oreStraordinarieTotali > 0 ? oreStraordinarieTotali : 0
                let oreStraordinarieDI = oreStraordinarieTotali > 60 ? 60 : oreStraordinarieTotali
                let oreLavoratePrimaDopo = getOreLavoratePrimaEDopo(oreInizio, oreFine)
                let oreLavoratePrima = oreLavoratePrimaDopo.prima
                let oreLavorateDopo = oreLavoratePrimaDopo.dopo
                let oreLavoratePrimaTotali = oreLavoratePrima + oreLavorateMultiplePost
                let oreOrdinarielavoratePrima = oreLavoratePrimaTotali - oreOrdinarieContratto
                oreOrdinarielavoratePrima = oreOrdinarielavoratePrima > 0 ? oreOrdinarieContratto : oreLavoratePrimaTotali
                let oreOrdinarielavorateDopo = oreOrdinarieContratto - oreLavoratePrima
                oreOrdinarielavorateDopo = oreOrdinarielavorateDopo > 0 ? (oreLavorateDopo - oreOrdinarielavorateDopo) : 0
                oreOrdinarielavorateDopo = oreOrdinarielavorateDopo > 0 ? oreOrdinarielavorateDopo : 0
                let oreBancaDI = oreStraordinarieTotali - 60
                oreBancaDI = oreBancaDI > 0 ? oreBancaDI : 0
                let oreNonLavorate = oreOrdinarieContratto - orelavorateTotali
                oreNonLavorate = oreNonLavorate > 0 ? oreNonLavorate : 0
                incarico.oreNonLavorate = fromMinutiToOreString(oreNonLavorate)
                //INIZIO CALCOLI
                if(incarico.partTime){
                    incarico.oreOrdinarie = fromMinutiToOreString(oreOrdinarielavorate)
                    //incarico.oreSupPartTime = fromMinutiToOreString(oreStraordinarieTotali)
                    //AGGIUNGO INDENNITA DOMENICALE
                    if (giornoSettimana === "S") {
                        incarico.indennitaDomenicale = fromMinutiToOreString(oreLavorateDopo)
                    }
                    if (giornoSettimana === "D") {
                        incarico.indennitaDomenicale = fromMinutiToOreString(oreLavoratePrimaTotali)
                    }
                    
                }else{
                    if (incarico.disponibilita == "DI" || incarico.disponibilita == "PC") {
                        incarico.oreOrdinarie = fromMinutiToOreString(oreOrdinarielavorate)
                        incarico.oreStraordinarie = fromMinutiToOreString(oreStraordinarieDI)
                        if (!incarico.percBancaOre || incarico.percBancaOre == "0") {
                            incarico.bancaOreRetrib = fromMinutiToOreString(oreBancaDI)
                        } else if (incarico.percBancaOre == "50") {
                            let meta = oreBancaDI / 2
                            incarico.bancaOreRetrib = fromMinutiToOreString(meta)
                            incarico.bancaOreDaFruire = fromMinutiToOreString(meta)
                        } else if (incarico.percBancaOre == "100") {
                            incarico.bancaOreDaFruire = fromMinutiToOreString(oreBancaDI)
                        }
                        if (incarico.festivo) {
                            //incarico.oreNonLavorate = ""
                            incarico.bancaOreRetrib = ""
                            incarico.bancaOreDaFruire = ""
                            incarico.oreOrdinarie = fromMinutiToOreString(oreOrdinarielavorate)
                            incarico.oreStraordinarie = ""
                            incarico.oreStraordinarieFestive = fromMinutiToOreString(oreStraordinarieTotali)
                        }
                    } else if(incarico.disponibilita == "RP" ){
                        incarico.oreOrdinarie = fromMinutiToOreString(oreOrdinarielavorate)
                        incarico.oreStrRipSp = fromMinutiToOreString(oreStraordinarieTotali);
                    }else if (incarico.disponibilita != "DI" && incarico.disponibilita != "PC") {
                        incarico.oreOrdinarie = fromMinutiToOreString(oreOrdinarielavorate)
                        incarico.oreStraordinarie = fromMinutiToOreString(oreStraordinarieTotali);
                        if (incarico.festivo) {
                            incarico.oreNonLavorate = ""
                            incarico.bancaOreRetrib = ""
                            incarico.bancaOreDaFruire = ""
                            incarico.oreStraordinarie = ""
                            incarico.oreStraordinarieFestive = fromMinutiToOreString(oreStraordinarieTotali)
                        }
                    } 
        
                    //DA RIVEDERE CON FESTIVI E NON
                    if (tipologiaFatturazione == "B") {
                        if (giornoSettimana == "V") {
                        }
                        if (giornoSettimana == "S" && !incarico.festivo) {
                            incarico.bancaOreRetrib = fromMinutiToOreString(orelavorateTotali)
                            incarico.bancaOreDaFruire = "";
                            incarico.oreStrRipSp = ""
                            incarico.oreOrdinarie = ""
                            incarico.oreStraordinarie = "";
                            //incarico.oreNonLavorate = "";
                        }
                        if (giornoSettimana == "D" && !incarico.festivo) {
                            incarico.bancaOreRetrib = fromMinutiToOreString(orelavorateTotali)
                            incarico.bancaOreDaFruire = "";
                            incarico.oreStrRipSp = ""
                            incarico.oreOrdinarie = ""
                            incarico.oreStraordinarie = "";
                            //incarico.oreNonLavorate = "";
                        }
                    }
        
                    //AGGIUNGO INDENNITA DOMENICALE
                    if (giornoSettimana === "S") {
                        incarico.indennitaDomenicale = fromMinutiToOreString(oreLavorateDopo)
                    }
                    if (giornoSettimana === "D") {
                        incarico.indennitaDomenicale = fromMinutiToOreString(oreLavoratePrimaTotali)
                    }
                }
                
            }
        }
        return incarico
    }

    const getCalcoliImpiegato = (incarico, tipologiaFatturazione) => {
        if(incarico.daCalcolare){
            if (incarico.disponibilita == "DI") {
                let oreOrdinarieContratto = fromStringToMinuti(incarico.ferOrd)
                incarico.oreOrdinarie = fromMinutiToOreString(oreOrdinarieContratto)
            }
        }
        return incarico
    }


    const getCalcoliIncaricoOTSAVE = (incarico, tipologiaFatturazione) => {
        if (incarico.effettivo == "AS") {
            let giornoSettimana = getFirstLetterOfDay(incarico.giornoSettimana)
            let oreInt = incarico.oreInt;
            let minutiInt = incarico.minutiInt;
            let ferOrd = incarico.ferOrd
            if (incarico.multiplo && incarico.isLastMultiplo) {
                oreInt = incarico.oreMultipliInt;
                minutiInt = incarico.minutiMultipliInt;
            }
            incarico.oreOrdinarie = getOreOrdinarie(oreInt, minutiInt, ferOrd);
            incarico.oreNonLavorate = getOreNonLavorate(oreInt, minutiInt, ferOrd)
            if (tipologiaFatturazione == "B") {
                if (incarico.festivo) {
                    //Da chiedere a patrizia
                } else {
                    if (incarico.disponibilita !== "RP" && incarico.disponibilita !== "RR" && !incarico.festivo) {
                        incarico.oreStraordinarie = getOreStraordinarieOTS(oreInt, minutiInt, ferOrd)
                        incarico.oreStraordinarieNotteOTS = ""
                        if (incarico.oreStraordinarie.length > 0) {
                            let oreStraordinarieNotte = getOreStraordinarieNotte(incarico.oraInizio, incarico.oraFine, incarico.oreStraordinarie)
                            incarico.oreStraordinarieNotteOTS = fromMinutiToOreString(oreStraordinarieNotte);
                            let oreStraordinarieGiorno = fromStringToMinuti(incarico.oreStraordinarie) - oreStraordinarieNotte
                            incarico.oreStraordinarie = fromMinutiToOreString(oreStraordinarieGiorno);
                        }
                    } else {
                        incarico.bancaOreRetrib = "";
                        incarico.indennitaDomenicale = "";
                        incarico.bancaOreDaFruire = "";
                        incarico.oreNonLavorate = ""
                        //incarico.oreStraordinarie = fromMinutiToOreString((oreInt * 60) + minutiInt);
                        if (giornoSettimana === "S") {
                            incarico.oreStraordinarie = getOreStraordinarieOTS(oreInt, minutiInt, ferOrd)
                            incarico = calcolaOreSabatoOTSFattB(incarico)
                        }
                        if (giornoSettimana === "D") {
                            incarico = calcolaOreDomenicaOTSFattB(incarico)
                        }
                    }
                }
            }

            if (tipologiaFatturazione == "A") {
                if (incarico.festivo) {
                    //chiedere a patrizia
                } else {
                    if (incarico.disponibilita !== "RP" && incarico.disponibilita !== "RR" && !incarico.festivo) {
                        incarico.oreStraordinarie = getOreStraordinarieOTS(oreInt, minutiInt, ferOrd)
                        if (giornoSettimana === "S") {
                            incarico.oreStraordinarie = getOreStraordinarieOTS(oreInt, minutiInt, ferOrd)
                            incarico = calcolaOreSabatoOTSFattA(incarico)
                        } else if (giornoSettimana === "D") {
                            incarico = calcolaOreDomenicaOTSFattA(incarico)
                        } else {
                            if (incarico.oreStraordinarie.length > 0) {
                                let oreStraordinarieNotte = getOreStraordinarieNotte(incarico.oraInizio, incarico.oraFine, incarico.oreStraordinarie)
                                incarico.oreStraordinarieNotteOTS = fromMinutiToOreString(oreStraordinarieNotte);
                                let oreStraordinarieGiorno = fromStringToMinuti(incarico.oreStraordinarie) - oreStraordinarieNotte
                                incarico.oreStraordinarie = fromMinutiToOreString(oreStraordinarieGiorno);
                            }
                        }

                    } else {
                        //chiedere a patrizia
                    }
                }
            }
        }
        return incarico
    }

    const getCalcoliIncaricoOTSSAVE = (incarico, tipologiaFatturazione) => {
        if (incarico.effettivo == "AS") {
            let giornoSettimana = getFirstLetterOfDay(incarico.giornoSettimana)
            let oreInt = incarico.oreInt;
            let minutiInt = incarico.minutiInt;
            let ferOrd = incarico.ferOrd
            if (incarico.multiplo && incarico.isLastMultiplo) {
                oreInt = incarico.oreMultipliInt;
                minutiInt = incarico.minutiMultipliInt;
            }
            incarico.oreOrdinarie = getOreOrdinarie(oreInt, minutiInt, ferOrd);
            incarico.oreNonLavorate = getOreNonLavorate(oreInt, minutiInt, ferOrd)
            if (tipologiaFatturazione == "B") {
                if (incarico.disponibilita !== "RP" && incarico.disponibilita !== "RR") {
                    incarico.oreStraordinarie = getOreStraordinarieOTS(oreInt, minutiInt, ferOrd)
                    incarico.oreStraordinarieNotteOTS = ""
                    if (incarico.oreStraordinarie.length > 0) {
                        let oreStraordinarieNotte = getOreStraordinarieNotte(incarico.oraInizio, incarico.oraFine, incarico.oreStraordinarie)
                        incarico.oreStraordinarieNotteOTS = fromMinutiToOreString(oreStraordinarieNotte);
                        let oreStraordinarieGiorno = fromStringToMinuti(incarico.oreStraordinarie) - oreStraordinarieNotte
                        incarico.oreStraordinarie = fromMinutiToOreString(oreStraordinarieGiorno);
                    }
                } else {
                    incarico.bancaOreRetrib = "";
                    incarico.indennitaDomenicale = "";
                    incarico.bancaOreDaFruire = "";
                    incarico.oreNonLavorate = ""
                    //incarico.oreStraordinarie = fromMinutiToOreString((oreInt * 60) + minutiInt);
                    if (giornoSettimana === "S") {
                        incarico.oreStraordinarie = getOreStraordinarieOTS(oreInt, minutiInt, ferOrd)
                        incarico = calcolaOreSabatoOTSFattB(incarico)
                    }
                    if (giornoSettimana === "D") {
                        incarico = calcolaOreDomenicaOTSFattB(incarico)
                    }
                }
            }

            if (tipologiaFatturazione == "A") {
                if (incarico.disponibilita !== "RP" && incarico.disponibilita !== "RR") {
                    incarico.oreStraordinarie = getOreStraordinarieOTS(oreInt, minutiInt, ferOrd)
                    if (giornoSettimana === "S") {
                        incarico.oreStraordinarie = getOreStraordinarieOTS(oreInt, minutiInt, ferOrd)
                        incarico = calcolaOreSabatoOTSFattA(incarico)
                    } else if (giornoSettimana === "D") {
                        incarico = calcolaOreDomenicaOTSFattA(incarico)
                    } else {
                        if (incarico.oreStraordinarie.length > 0) {
                            let oreStraordinarieNotte = getOreStraordinarieNotte(incarico.oraInizio, incarico.oraFine, incarico.oreStraordinarie)
                            incarico.oreStraordinarieNotteOTS = fromMinutiToOreString(oreStraordinarieNotte);
                            let oreStraordinarieGiorno = fromStringToMinuti(incarico.oreStraordinarie) - oreStraordinarieNotte
                            incarico.oreStraordinarie = fromMinutiToOreString(oreStraordinarieGiorno);
                        }
                    }

                } else {
                    //chiedere a patrizia
                }
            }
        }
        return incarico
    }

    const getOreStraordinariePrimaOTS = (oreInizio, oreFine, oreOrdinarie, oreLavorateMultiplePost) => {
        let oreStraordinarieGiorno = 0
        let oreStraordinarieNotte = 0
        let newOreFine = oreFine
        if (oreInizio > oreFine) {
            newOreFine = minuti24
        }
        let oreLavorateIncarico = newOreFine - oreInizio
        let oreStraordinarie = (oreLavorateIncarico + oreLavorateMultiplePost) - oreOrdinarie
        oreStraordinarie = oreStraordinarie > 0 ? oreStraordinarie : 0
        if (oreStraordinarie > 0) {
            let gap = oreOrdinarie - oreLavorateMultiplePost
            gap = gap > 0 ? gap : 0
            let x = oreInizio + gap
            if (x < minuti06 && newOreFine <= minuti06) {
                oreStraordinarieNotte = newOreFine - x
            } else if (x < minuti06 && newOreFine <= minuti22) {
                oreStraordinarieNotte = minuti06 - x
                oreStraordinarieGiorno = newOreFine - minuti06
            } else if (x < minuti06 && newOreFine > minuti22) {
                oreStraordinarieGiorno = minuti16
                oreStraordinarieNotte = minuti06 - x
                oreStraordinarieNotte = oreStraordinarieNotte + (newOreFine - minuti22)
            } else if (x >= minuti06 && newOreFine <= minuti22) {
                oreStraordinarieGiorno = newOreFine - x
            } else if (x >= minuti06 && newOreFine > minuti22) {
                oreStraordinarieNotte = newOreFine - minuti22
                oreStraordinarieGiorno = minuti22 - x
            } else if (x >= minuti22 && newOreFine > minuti22) {
                oreStraordinarieNotte = newOreFine - x
            }
        }

        return { giorno: oreStraordinarieGiorno, notte: oreStraordinarieNotte }
    }

    const getOreStraordinarieDopoOTS = (oreInizio, oreFine, oreOrdinarie, oreLavorateMultiplePost) => {
        let oreStraordinarieGiorno = 0
        let oreStraordinarieNotte = 0
        if (oreInizio > oreFine){
            let oreLavorateIncarico = minuti24 - oreInizio
            oreLavorateIncarico = oreLavorateIncarico + oreFine
            let oreStraordinarie = (oreLavorateIncarico + oreLavorateMultiplePost) - oreOrdinarie
            oreStraordinarie = oreStraordinarie > 0 ? oreStraordinarie : 0
            if (oreStraordinarie > 0) {
                let gap = oreOrdinarie - oreLavorateMultiplePost - (minuti24 - oreInizio)
                gap = gap > 0 ? gap : 0
                let x = gap
                if (x < minuti06 && oreFine <= minuti06) {
                    oreStraordinarieNotte = oreFine - x
                } else if (x < minuti06 && oreFine <= minuti22) {
                    oreStraordinarieNotte = minuti06 - x
                    oreStraordinarieGiorno = oreFine - minuti06
                } else if (x < minuti06 && oreFine > minuti22) {
                    oreStraordinarieGiorno = minuti16
                    oreStraordinarieNotte = minuti06 - x
                    oreStraordinarieNotte = oreStraordinarieNotte + (oreFine - minuti22)
                } else if (x >= minuti06 && oreFine <= minuti22) {
                    oreStraordinarieGiorno = oreFine - x
                } else if (x >= minuti06 && oreFine > minuti22) {
                    oreStraordinarieNotte = oreFine - minuti22
                    oreStraordinarieGiorno = minuti22 - x
                } else if (x >= minuti22 && oreFine > minuti22) {
                    oreStraordinarieNotte = oreFine - x
                }
            }
        }


        return { giorno: oreStraordinarieGiorno, notte: oreStraordinarieNotte }
    }

    const getOreLavorateGiornoENotte = (oreInizio, oreFine) => {
        let notte = 0;
        let giorno = 0;
        if (oreInizio < minuti06 && oreFine <= minuti06) {
            if(oreFine > oreInizio){
                notte = oreFine - oreInizio
            }else{
                notte = (minuti06 - oreInizio) + oreFine + 120
                giorno = minuti16
            }
        } else if (oreInizio < minuti06 && oreFine <= minuti22) {
            notte = minuti06 - oreInizio
            giorno = oreFine - minuti06
        } else if (oreInizio < minuti06 && oreFine > minuti22) {
            giorno = minuti16
            notte = minuti06 - oreInizio
            notte = notte + (oreFine - minuti22)
        } else if (oreInizio >= minuti22 && oreFine > minuti22) {
            if(oreFine > oreInizio){
                notte = oreFine - oreInizio
            }else{
                giorno = minuti16
                notte = minuti06 + (120 - oreInizio - oreFine)
            }
        }
        else if (oreInizio >= minuti06 && oreFine <= minuti22) {
            if(oreFine > oreInizio){
                giorno = oreFine - oreInizio
            }else{
                giorno = minuti24 - oreInizio - 120
                giorno = giorno > 0 ? giorno : 0
                if(oreFine > minuti06){
                    giorno = giorno + (oreFine - minuti06)
                }
                let scarto = minuti06 - oreFine
                scarto = scarto > 0 ? scarto : 0
                notte = 120 + (minuti06 - scarto)

            }
        }
        else if (oreInizio >= minuti06 && oreFine > minuti22) {
            notte = oreFine - minuti22
            giorno = minuti22 - oreInizio
        } 
        return {giorno: giorno, notte: notte}
    }

    const getCalcoliIncaricoOTS = (incarico, tipologiaFatturazione) => {
        if(incarico.daCalcolare){
            if (incarico.effettivo == "AS") {
                let giornoSettimana = getFirstLetterOfDay(incarico.giornoSettimana)
                let oreInt = incarico.oreInt;
                let minutiInt = incarico.minutiInt;
                if (incarico.multiplo && incarico.isLastMultiplo) {
                    oreInt = incarico.oreMultipliInt;
                    minutiInt = incarico.minutiMultipliInt;
                }
                let oreStraordinarieNottePost = incarico.sumStrNotte
                let ferOrd = incarico.ferOrd
                let oreLavorateIncarico = (incarico.oreInt * 60) + incarico.minutiInt
                let oreLavorateMultiplePost = 0
                if (incarico.multiplo && incarico.isLastMultiplo) {
                    oreLavorateMultiplePost = (incarico.oreMultipliInt * 60) + incarico.minutiMultipliInt
                    oreLavorateMultiplePost = oreLavorateMultiplePost - oreLavorateIncarico
                    oreLavorateMultiplePost = oreLavorateMultiplePost > 0 ? oreLavorateMultiplePost : 0
                }
                let oreOrdinarie = fromStringToMinuti(ferOrd)
                let oreInizio = fromStringToMinuti(incarico.oraInizio)
                let oreFine = fromStringToMinuti(incarico.oraFine)
                let oreLavoratePrimaDopo = getOreLavoratePrimaEDopo(oreInizio, oreFine)
                let oreLavoratePrima = oreLavoratePrimaDopo.prima
                let oreLavorateDopo = oreLavoratePrimaDopo.dopo
                let oreLavorateTotali = minutiInt + (oreInt * 60)
                let oreStraordiariePrima = getOreStraordinariePrimaOTS(oreInizio, oreFine, oreOrdinarie, oreLavorateMultiplePost)
                let oreStraordiariePrimaGiorno = oreStraordiariePrima.giorno
                let oreStraordiariePrimaNotte = oreStraordiariePrima.notte
                let oreStraordiarieDopo = getOreStraordinarieDopoOTS(oreInizio, oreFine, oreOrdinarie, oreLavorateMultiplePost)
                let oreStraordiarieDopoGiorno = oreStraordiarieDopo.giorno
                let oreStraordiarieDopoNotte = oreStraordiarieDopo.notte
                incarico.oreNonLavorate = getOreNonLavorate(oreInt, minutiInt, ferOrd)
                if(incarico.partTime){
                    incarico.oreOrdinarie = getOreOrdinarie(oreInt, minutiInt, ferOrd);
                    if (giornoSettimana === "S") {
                        if(oreInizio > oreFine){
                            let oreLavorateDomenicaGiornoENotte = getOreLavorateGiornoENotte(0,oreFine)
                            let oreLavorateDomenicaGiorno = oreLavorateDomenicaGiornoENotte.giorno
                            let oreLavorateDomenicaNotte = oreLavorateDomenicaGiornoENotte.notte
                            let oreLavorateDomenicaOrdinarieGiorno = oreLavorateDomenicaGiorno - oreStraordiarieDopoGiorno
                            let oreLavorateDomenicaOrdinarieNotte = oreLavorateDomenicaNotte - oreStraordiarieDopoNotte
                            incarico.indennitaDomenicaleOTSGiorno = fromMinutiToOreString(oreLavorateDomenicaOrdinarieGiorno)
                            incarico.indennitaDomenicaleOTSNotte = fromMinutiToOreString(oreLavorateDomenicaOrdinarieNotte)
                        } 
                    }
                    if (giornoSettimana === "D") {
                        let oreLavorateDomenicaGiornoENotte;
                        if(oreInizio > oreFine){
                            oreLavorateDomenicaGiornoENotte = getOreLavorateGiornoENotte(oreInizio, minuti24)
                        }else{
                            oreLavorateDomenicaGiornoENotte = getOreLavorateGiornoENotte(oreInizio, oreFine)
                        }
                        let oreLavorateDomenicaGiorno = oreLavorateDomenicaGiornoENotte.giorno
                        let oreLavorateDomenicaNotte = oreLavorateDomenicaGiornoENotte.notte
                        incarico.indennitaDomenicaleOTSGiorno = fromMinutiToOreString(oreLavorateDomenicaGiorno - oreStraordiariePrimaGiorno)
                        incarico.indennitaDomenicaleOTSNotte = fromMinutiToOreString(oreLavorateDomenicaNotte - oreStraordiariePrimaNotte)
                    }
                }else{
                    if (incarico.disponibilita == "DI" || incarico.disponibilita == "PC" || incarico.disponibilita == "RP") {
                        incarico.oreOrdinarie = getOreOrdinarie(oreInt, minutiInt, ferOrd);
                        if(incarico.festivo){
                            if (giornoSettimana === "D") {
                                if(oreInizio > oreFine){
                                    let oreLavorateDomenica = getOreLavorateGiornoENotte(oreInizio,minuti24)
                                    incarico.indennitaDomenicaleOTSGiorno = fromMinutiToOreString(oreLavorateDomenica.giorno)
                                    incarico.indennitaDomenicaleOTSNotte = fromMinutiToOreString(oreLavorateDomenica.notte)
                                    incarico.oreStraordinarieFestiveOTSGiorno = fromMinutiToOreString(oreStraordiariePrimaGiorno)
                                    incarico.oreStraordinarieFestiveOTSNotte = fromMinutiToOreString(oreStraordiariePrimaNotte)
                                    incarico.oreStraordinarie = fromMinutiToOreString(oreStraordiarieDopoGiorno)
                                    incarico.oreStraordinarieNotteOTS = fromMinutiToOreString(oreStraordiarieDopoNotte)
                                    incarico.oreOrdinarie = fromMinutiToOreString(oreFine - oreStraordiarieDopoGiorno - oreStraordiarieDopoNotte)
                                    incarico.oreNonLavorate = ""
                                }else{
                                    let oreLavorateGiornoENotte = getOreLavorateGiornoENotte(oreInizio,oreFine)
                                    incarico.indennitaDomenicaleOTSGiorno = fromMinutiToOreString(oreLavorateGiornoENotte.giorno)
                                    incarico.indennitaDomenicaleOTSNotte = fromMinutiToOreString(oreLavorateGiornoENotte.notte)
                                    incarico.oreStraordinarieFestiveOTSGiorno = fromMinutiToOreString(oreStraordiariePrimaGiorno)
                                    incarico.oreStraordinarieFestiveOTSNotte = fromMinutiToOreString(oreStraordiariePrimaNotte)
                                    //incarico.oreOrdinarie = ""
                                    incarico.oreNonLavorate= ""
                                }
                            }else
                            if (giornoSettimana === "S") {
                                if(oreInizio > oreFine){
                                    let oreLavorateGiornoENotte = getOreLavorateGiornoENotte(oreInizio,oreFine)
                                    incarico.indennitaDomenicaleOTSGiorno = fromMinutiToOreString(oreLavorateGiornoENotte.giorno)
                                    incarico.indennitaDomenicaleOTSNotte = fromMinutiToOreString(oreLavorateGiornoENotte.notte)
                                    incarico.oreStraordinarieFestiveOTSGiorno = fromMinutiToOreString(oreStraordiariePrimaGiorno + oreStraordiarieDopoGiorno)
                                    incarico.oreStraordinarieFestiveOTSNotte = fromMinutiToOreString(oreStraordiariePrimaNotte + oreStraordiarieDopoNotte)
                                    //incarico.oreOrdinarie = fromMinutiToOreString(oreFine - oreStraordiarieDopoGiorno - oreStraordiarieDopoNotte)
                                    //incarico.oreOrdinarie = ""
                                    incarico.oreNonLavorate = ""
    
                                }else{
                                    let oreLavorateGiornoENotte = getOreLavorateGiornoENotte(oreInizio,oreFine)
                                    incarico.indennitaDomenicaleOTSGiorno = fromMinutiToOreString(oreLavorateGiornoENotte.giorno)
                                    incarico.indennitaDomenicaleOTSNotte = fromMinutiToOreString(oreLavorateGiornoENotte.notte)
                                    incarico.oreStraordinarieFestiveOTSGiorno = fromMinutiToOreString(oreStraordiariePrimaGiorno)
                                    incarico.oreStraordinarieFestiveOTSNotte = fromMinutiToOreString(oreStraordiariePrimaNotte)
                                    //incarico.oreOrdinarie = ""
                                    incarico.oreNonLavorate= ""
                                }
                            }
                            else{
                                if(oreInizio > oreFine){
                                    let oreLavoratePrimoGiorno = getOreLavorateGiornoENotte(oreInizio,minuti24)
                                    incarico.indennitaDomenicaleOTSGiorno = fromMinutiToOreString(oreLavoratePrimoGiorno.giorno)
                                    incarico.indennitaDomenicaleOTSNotte = fromMinutiToOreString(oreLavoratePrimoGiorno.notte)
                                    incarico.oreStraordinarieFestiveOTSGiorno = fromMinutiToOreString(oreStraordiariePrimaGiorno)
                                    incarico.oreStraordinarieFestiveOTSNotte = fromMinutiToOreString(oreStraordiariePrimaNotte)
                                    incarico.oreStraordinarie = fromMinutiToOreString(oreStraordiarieDopoGiorno)
                                    incarico.oreStraordinarieNotteOTS = fromMinutiToOreString(oreStraordiarieDopoNotte)
                                    incarico.oreOrdinarie = fromMinutiToOreString(oreFine - oreStraordiarieDopoGiorno - oreStraordiarieDopoNotte)
                                    incarico.oreNonLavorate = ""
    
                                }else{
                                    let oreLavorateGiornoENotte = getOreLavorateGiornoENotte(oreInizio,oreFine)
                                    incarico.indennitaDomenicaleOTSGiorno = fromMinutiToOreString(oreLavorateGiornoENotte.giorno)
                                    incarico.indennitaDomenicaleOTSNotte = fromMinutiToOreString(oreLavorateGiornoENotte.notte)
                                    incarico.oreStraordinarieFestiveOTSGiorno = fromMinutiToOreString(oreStraordiariePrimaGiorno)
                                    incarico.oreStraordinarieFestiveOTSNotte = fromMinutiToOreString(oreStraordiariePrimaNotte)
                                    //incarico.oreOrdinarie = ""
                                    incarico.oreNonLavorate= ""
                                }
                            }
                        }else{
                            if (giornoSettimana === "S") {
                                incarico.oreStraordinarie = fromMinutiToOreString(oreStraordiariePrimaGiorno)
                                incarico.oreStraordinarieNotteOTS = fromMinutiToOreString(oreStraordiariePrimaNotte)
                                if(oreInizio > oreFine){
                                    let oreLavorateDomenicaGiornoENotte = getOreLavorateGiornoENotte(0,oreFine)
                                    let oreLavorateDomenicaGiorno = oreLavorateDomenicaGiornoENotte.giorno
                                    let oreLavorateDomenicaNotte = oreLavorateDomenicaGiornoENotte.notte
                                    let oreLavorateDomenicaOrdinarieGiorno = oreLavorateDomenicaGiorno - oreStraordiarieDopoGiorno
                                    let oreLavorateDomenicaOrdinarieNotte = oreLavorateDomenicaNotte - oreStraordiarieDopoNotte
                                    incarico.indennitaDomenicaleOTSGiorno = fromMinutiToOreString(oreLavorateDomenicaOrdinarieGiorno)
                                    incarico.indennitaDomenicaleOTSNotte = fromMinutiToOreString(oreLavorateDomenicaOrdinarieNotte)
                                    incarico.oreStraordinarieFestiveOTSGiorno = fromMinutiToOreString(oreStraordiarieDopoGiorno)
                                    incarico.oreStraordinarieFestiveOTSNotte = fromMinutiToOreString(oreStraordiarieDopoNotte)
                                } 
                            } else if (giornoSettimana === "D") {
                                incarico.oreStraordinarieFestiveOTSGiorno = fromMinutiToOreString(oreStraordiariePrimaGiorno)
                                incarico.oreStraordinarieFestiveOTSNotte = fromMinutiToOreString(oreStraordiariePrimaNotte)
                                let oreLavorateDomenicaGiornoENotte;
                                if(oreInizio > oreFine){
                                    oreLavorateDomenicaGiornoENotte = getOreLavorateGiornoENotte(oreInizio, minuti24)
                                    incarico.oreStraordinarie = fromMinutiToOreString(oreStraordiarieDopoGiorno)
                                    incarico.oreStraordinarieNotteOTS = fromMinutiToOreString(oreStraordiarieDopoNotte)
                                }else{
                                    oreLavorateDomenicaGiornoENotte = getOreLavorateGiornoENotte(oreInizio, oreFine)
                                }
                                let oreLavorateDomenicaGiorno = oreLavorateDomenicaGiornoENotte.giorno
                                let oreLavorateDomenicaNotte = oreLavorateDomenicaGiornoENotte.notte
                                incarico.indennitaDomenicaleOTSGiorno = fromMinutiToOreString(oreLavorateDomenicaGiorno - oreStraordiariePrimaGiorno)
                                incarico.indennitaDomenicaleOTSNotte = fromMinutiToOreString(oreLavorateDomenicaNotte - oreStraordiariePrimaNotte)
                            } else {
                                incarico.oreStraordinarie = fromMinutiToOreString(oreStraordiariePrimaGiorno + oreStraordiarieDopoGiorno)
                                incarico.oreStraordinarieNotteOTS = fromMinutiToOreString(oreStraordiariePrimaNotte + oreStraordiarieDopoNotte)
                            }
                        }
                    }else{
                        if (giornoSettimana === "S") {
                            let oreLavorateGiornoENotte;
                            if(oreInizio > oreFine){
                                oreLavorateGiornoENotte = getOreLavorateGiornoENotte(oreInizio, minuti24)
                                let oreLavorateDomenicaGiornoENotte = getOreLavorateGiornoENotte(0, oreFine)
                                incarico.indennitaDomenicaleOTSGiorno = fromMinutiToOreString(oreLavorateDomenicaGiornoENotte.giorno - oreStraordiarieDopoGiorno)
                                incarico.indennitaDomenicaleOTSNotte = fromMinutiToOreString(oreLavorateDomenicaGiornoENotte.notte - oreStraordiarieDopoNotte)
                            }else{
                                oreLavorateGiornoENotte = getOreLavorateGiornoENotte(oreInizio, oreFine)
                            }
                            incarico.oreStraordinarie = fromMinutiToOreString(oreLavorateGiornoENotte.giorno)
                            incarico.oreStraordinarieNotteOTS = fromMinutiToOreString(oreLavorateGiornoENotte.notte)
                        } else if (giornoSettimana === "D") {
                            console.log("D")
                            let oreLavorateGiornoENotte;
                            if(oreInizio > oreFine){
                                console.log("QUI")
                                oreLavorateGiornoENotte = getOreLavorateGiornoENotte(oreInizio, minuti24)
                                //oreLavorateGiornoENotte = getOreLavorateGiornoENotte(oreInizio, oreFine)
                                incarico.indennitaDomenicaleOTSGiorno = fromMinutiToOreString(oreLavorateGiornoENotte.giorno)
                                incarico.indennitaDomenicaleOTSNotte = fromMinutiToOreString(oreLavorateGiornoENotte.notte)    
                            }else{
                                oreLavorateGiornoENotte = getOreLavorateGiornoENotte(oreInizio, oreFine)
                                incarico.indennitaDomenicaleOTSGiorno = fromMinutiToOreString(oreLavorateGiornoENotte.giorno)
                                incarico.indennitaDomenicaleOTSNotte = fromMinutiToOreString(oreLavorateGiornoENotte.notte)    
                            }
                            console.log(oreLavorateGiornoENotte)
                        } else {
                            let oreLavorateTotali;
                            if(oreInizio > oreFine){
                                oreLavorateTotali = getOreLavorateGiornoENotte(oreInizio, minuti24)
                                oreLavorateTotali = oreLavorateTotali + getOreLavorateGiornoENotte(0, oreFine)
                            }else{
                                oreLavorateTotali = getOreLavorateGiornoENotte(oreInizio, oreFine)
                            }
                            incarico.oreStraordinarie = fromMinutiToOreString(oreLavorateTotali.giorno)
                            incarico.oreStraordinarieNotteOTS = fromMinutiToOreString(oreLavorateTotali.notte)
        
                        }
                    }
                }
            }
        }
        return incarico
    }

    const calcolaOreSabatoOTSFattBSAVE = (incarico) => {
        let oreInizio = fromStringToMinuti(incarico.oraInizio);
        let oreFine = fromStringToMinuti(incarico.oraFine);
        let oreOrdinarie = fromStringToMinuti(incarico.oreOrdinarie);
        let dg = 0;
        let dn = 0;
        let strFestG = 0;
        let strFestN = 0;
        let strG = 0;
        let strN = 0;
        incarico.oreStraordinarieNotteOTS = "";
        incarico.oreStraordinarieFestiveOTSGiorno = "";
        incarico.oreStraordinarieFestiveOTSNotte = "";
        if (oreInizio > oreFine) {
            strN = getOreStraordinarieNotte(incarico.oraInizio, incarico.oraFine, incarico.oreStraordinarie)
            strG = fromStringToMinuti(incarico.oreStraordinarie) - strN
            if (oreFine < minuti06) {
                dn = oreFine;
            }
            if (oreFine >= minuti06 && oreFine <= minuti22) {
                dn = minuti06;
                dg = oreFine - minuti06
            }
            if (oreFine > minuti22) {
                dn = minuti06 + (oreFine - minuti22)
                dg = minuti16
            }
            let x = oreInizio + oreOrdinarie - minuti24;
            if (x >= 0) {
                if (oreFine > x) {
                    if (x < minuti06) {
                        strFestN = minuti06 - x
                        strFestG = oreFine - minuti06
                    }
                    if (x >= minuti06 && x < minuti22) {
                        strFestN = 0
                        strFestG = oreFine - x
                    }
                    if (x >= minuti22) {
                        strFestG = 0
                        strFestN = oreFine - x
                    }
                }
                dn = dn - strFestN
                dg = dg - strFestG
            } else {
                strFestG = dg;
                strFestN = dn;
                dg = 0;
                dn = 0;
            }
            strFestG = strFestG + dg;
            strFestN = strFestN + dn
            //incarico.indennitaDomenicaleOTSGiorno = fromMinutiToOreString(dg);
            //incarico.indennitaDomenicaleOTSNotte = fromMinutiToOreString(dn);
            incarico.oreStraordinarieFestiveOTSGiorno = fromMinutiToOreString(strFestG);
            incarico.oreStraordinarieFestiveOTSNotte = fromMinutiToOreString(strFestN);
            strN = strN - strFestN
            strG = strG - strFestG
            strN = strN > 0 ? strN : 0
            strG = strG > 0 ? strG : 0
            incarico.oreStraordinarieNotteOTS = fromMinutiToOreString(strN);
            incarico.oreStraordinarie = fromMinutiToOreString(strG);
        } else {
            strN = getOreStraordinarieNotte(incarico.oraInizio, incarico.oraFine, incarico.oreStraordinarie)
            strG = fromStringToMinuti(incarico.oreStraordinarie) - strN
            incarico.oreStraordinarieNotteOTS = fromMinutiToOreString(strN);
            incarico.oreStraordinarie = fromMinutiToOreString(strG);
        }
        return incarico
    }

    const calcolaOreSabatoOTSFattB = (incarico) => {
        let oreInizio = fromStringToMinuti(incarico.oraInizio);
        let oreFine = fromStringToMinuti(incarico.oraFine);
        let strFestG = 0;
        let strFestN = 0;
        incarico.oreStraordinarieNotteOTS = "";
        incarico.oreStraordinarieFestiveOTSGiorno = "";
        incarico.oreStraordinarieFestiveOTSNotte = "";
        if (oreInizio > oreFine) {
            let oreNotturneSabato = getOreNotturneStessoGiorno(incarico.oraInizio, "24:00")
            let oreTotaliSabato = minuti24 - oreInizio
            let oreStraordinarieGiornoSabato = oreTotaliSabato - oreNotturneSabato
            if (oreFine <= minuti06) {
                strFestN = oreFine
            }
            if (oreFine > minuti22) {
                strFestN = minuti06 + (oreFine - minuti22)
                strFestG = minuti16
            }
            if (oreFine > minuti06 && oreFine <= minuti22) {
                strFestN = minuti06
                strFestG = oreFine - minuti06
            }
            incarico.oreStraordinarieFestiveOTSGiorno = fromMinutiToOreString(strFestG);
            incarico.oreStraordinarieFestiveOTSNotte = fromMinutiToOreString(strFestN);
            incarico.oreStraordinarieNotteOTS = fromMinutiToOreString(oreNotturneSabato);
            incarico.oreStraordinarie = fromMinutiToOreString(oreStraordinarieGiornoSabato);
            incarico.oreOrdinarie = ""
        } else {
            let oreNotturne = getOreNotturneStessoGiorno(incarico.oraInizio, incarico.oraFine)
            let oreTotali = oreFine - oreInizio
            incarico.oreStraordinarieNotteOTS = fromMinutiToOreString(oreNotturne);
            incarico.oreStraordinarie = fromMinutiToOreString(oreTotali - oreNotturne);
            incarico.oreOrdinarie = ""
        }
        return incarico
    }

    const calcolaOreDomenicaOTSFattB = (incarico) => {
        let oreInizio = fromStringToMinuti(incarico.oraInizio);
        let oreFine = fromStringToMinuti(incarico.oraFine);
        let strFestG = 0;
        let strFestN = 0;
        let oreOrdinarie = fromStringToMinuti(incarico.oreOrdinarie);
        incarico.oreStraordinarieNotteOTS = "";
        incarico.oreStraordinarieFestiveOTSGiorno = "";
        incarico.oreStraordinarieFestiveOTSNotte = "";
        if (oreInizio > oreFine) {
            let oreNotturneD = getOreNotturneStessoGiorno(incarico.oraInizio, "24:00")
            let oreTotaliDomenica = minuti24 - oreInizio
            let oreGiornoD = oreTotaliDomenica - oreNotturneD

            let gapL = (oreInizio + oreOrdinarie) - minuti24;
            gapL = gapL > 0 ? gapL : 0
            let oreOrdinarieLunedi = gapL

            let oreNotturneL = getOreNotturneStessoGiorno("00:00", incarico.oraFine)
            let oreTotaliLunedi = oreFine
            let oreGiornoL = oreTotaliLunedi - oreNotturneL

            if (gapL > 0 && gapL < minuti06) {
                oreNotturneL = oreNotturneL - gapL
                oreNotturneL = oreNotturneL > 0 ? oreNotturneL : 0
            }
            if (gapL >= minuti06 && gapL < minuti22) {
                oreNotturneL = oreNotturneL - minuti06
                oreNotturneL = oreNotturneL > 0 ? oreNotturneL : 0
                oreGiornoL = oreGiornoL - (gapL - minuti06)
                oreGiornoL = oreGiornoL > 0 ? oreGiornoL : 0
            }
            if (gapL >= minuti22) {
                oreNotturneL = oreNotturneL - minuti06 - (gapL - minuti22)
                oreGiornoL = 0
            }
            incarico.oreStraordinarieFestiveOTSNotte = fromMinutiToOreString(oreNotturneD);
            incarico.oreStraordinarieFestiveOTSGiorno = fromMinutiToOreString(oreGiornoD);
            incarico.oreOrdinarie = fromMinutiToOreString(oreOrdinarieLunedi);
            //incarico.oreOrdinarie = "";
            incarico.oreStraordinarie = fromMinutiToOreString(oreGiornoL);
            incarico.oreStraordinarieNotteOTS = fromMinutiToOreString(oreNotturneL);
        } else {
            let oreNotturne = getOreNotturneStessoGiorno(incarico.oraInizio, incarico.oraFine)
            let oreTotali = oreFine - oreInizio
            incarico.oreStraordinarieFestiveOTSNotte = fromMinutiToOreString(oreNotturne);
            incarico.oreStraordinarieFestiveOTSGiorno = fromMinutiToOreString(oreTotali - oreNotturne);
            incarico.oreOrdinarie = ""
        }
        return incarico
    }

    const calcolaOreSabatoOTSFattA = (incarico) => {
        let oreInizio = fromStringToMinuti(incarico.oraInizio);
        let oreFine = fromStringToMinuti(incarico.oraFine);
        let oreOrdinarie = fromStringToMinuti(incarico.oreOrdinarie);
        let dg = 0;
        let dn = 0;
        let strFestG = 0;
        let strFestN = 0;
        let strG = 0;
        let strN = 0;
        incarico.oreStraordinarieNotteOTS = "";
        incarico.oreStraordinarieFestiveOTSGiorno = "";
        incarico.oreStraordinarieFestiveOTSNotte = "";
        if (oreInizio > oreFine) {
            strN = getOreStraordinarieNotte(incarico.oraInizio, incarico.oraFine, incarico.oreStraordinarie)
            strG = fromStringToMinuti(incarico.oreStraordinarie) - strN
            if (oreFine < minuti06) {
                dn = oreFine;
            }
            if (oreFine >= minuti06 && oreFine <= minuti22) {
                dn = minuti06;
                dg = oreFine - minuti06
            }
            if (oreFine > minuti22) {
                dn = minuti06 + (oreFine - minuti22)
                dg = minuti16
            }
            let x = oreInizio + oreOrdinarie - minuti24;
            if (x >= 0) {
                if (oreFine > x) {
                    if (x < minuti06) {
                        strFestN = minuti06 - x
                        strFestG = oreFine - minuti06
                    }
                    if (x >= minuti06 && x < minuti22) {
                        strFestN = 0
                        strFestG = oreFine - x
                    }
                    if (x >= minuti22) {
                        strFestG = 0
                        strFestN = oreFine - x
                    }
                }
                dn = dn - strFestN
                dg = dg - strFestG
            } else {
                strFestG = dg;
                strFestN = dn;
                dg = 0;
                dn = 0;
            }
            //strFestG = strFestG + dg;
            //strFestN = strFestN + dn
            incarico.indennitaDomenicaleOTSGiorno = fromMinutiToOreString(dg);
            incarico.indennitaDomenicaleOTSNotte = fromMinutiToOreString(dn);
            incarico.oreStraordinarieFestiveOTSGiorno = fromMinutiToOreString(strFestG);
            incarico.oreStraordinarieFestiveOTSNotte = fromMinutiToOreString(strFestN);
            strN = strN - strFestN
            strG = strG - strFestG
            strN = strN > 0 ? strN : 0
            strG = strG > 0 ? strG : 0
            incarico.oreStraordinarieNotteOTS = fromMinutiToOreString(strN);
            incarico.oreStraordinarie = fromMinutiToOreString(strG);
        } else {
            strN = getOreStraordinarieNotte(incarico.oraInizio, incarico.oraFine, incarico.oreStraordinarie)
            strG = fromStringToMinuti(incarico.oreStraordinarie) - strN
            incarico.oreStraordinarieNotteOTS = fromMinutiToOreString(strN);
            incarico.oreStraordinarie = fromMinutiToOreString(strG);
        }
        return incarico
    }

    const calcolaOreDomenicaOTSFattA = (incarico) => {
        let oreInizio = fromStringToMinuti(incarico.oraInizio);
        let oreFine = fromStringToMinuti(incarico.oraFine);
        let oreOrdinarie = fromStringToMinuti(incarico.oreOrdinarie);
        let dg = 0;
        let dn = 0;
        let strFestG = 0;
        let strFestN = 0;
        let strG = 0;
        let strN = 0;
        let oreLunediOrdinarie = 0;
        if (oreInizio > oreFine) {
            //conteggio domenicali
            if (oreInizio < minuti06) {
                dn = (minuti06 - oreInizio) + 2;
                dg = minuti16;
            }
            if (oreInizio >= minuti06 && oreInizio <= minuti22) {
                dg = minuti22 - oreInizio;
                dn = 120
            }
            if (oreInizio > minuti22) {
                dn = minuti24 - oreInizio;
                dg = 0;
            }
            //conteggio straoridnari festivi
            let x = oreInizio + oreOrdinarie
            if (x <= minuti22) {
                strFestG = minuti22 - x;
                dg = dg > 0 ? dg - strFestG : 0
                strFestN = 120;
                dn = dn > 0 ? dn - strFestN : 0
            }
            if (x > minuti22) {
                strFestG = 0;
                if (x >= minuti24) {
                    strFestN = 0;
                } else {
                    strFestN = 120 - (x - minuti22);
                    dn = dn > 0 ? dn - strFestN : 0;
                }
            }
            //conteggio del lunedi
            let y = x - minuti24
            if (oreFine <= minuti06) {
                strG = 0;
                if (y > 0) {
                    strN = oreFine - y;
                    //per ora non ci faccio nulla
                    oreLunediOrdinarie = y;
                } else {
                    strN = oreFine;
                }
            }
            if (oreFine > minuti06 && oreFine <= minuti22) {
                if (y > 0) {
                    strN = minuti06 - y;
                    strN = strN > 0 ? strN : 0;
                    strG = oreFine - minuti06;
                    if (y > minuti06) {
                        strG = strG - (y - minuti06);
                    }
                } else {
                    strN = minuti06;
                    strG = oreFine - minuti06;
                }
            }
            if (oreFine > minuti22) {
                strN = strN + (minuti24 - oreFine)
            }
        } else {
            if (oreInizio < minuti06) {
                dn = minuti06 - oreInizio
            }
            if (oreFine < minuti06) {
                dn = oreFine - oreInizio
            }
            let xx = oreInizio + oreOrdinarie
            if (oreFine >= minuti06 && oreFine < minuti22) {
                if (oreInizio >= minuti06) {
                    dg = oreFine - oreInizio
                } else {
                    dg = oreFine - minuti06
                }
                if (oreFine > xx) {
                    strFestG = oreFine - xx
                    dg = dg - strFestG
                }
            }
            if (oreFine >= minuti22) {
                if (oreInizio >= minuti06) {
                    dg = minuti22 - oreInizio
                } else {
                    dg = minuti22 - minuti06
                }
                let z = oreFine - minuti22
                dn = dn + z
                if (oreFine > xx) {
                    if (xx >= minuti22) {
                        strFestN = oreFine - xx
                        dn = dn - strFestN
                    }
                }
            }
        }
        //strFestG = strFestG + dg;
        //strFestN = strFestN + dn
        incarico.oreStraordinarie = fromMinutiToOreString(strG);
        incarico.oreStraordinarieNotteOTS = fromMinutiToOreString(strN);
        incarico.indennitaDomenicaleOTSGiorno = fromMinutiToOreString(dg);
        incarico.indennitaDomenicaleOTSNotte = fromMinutiToOreString(dn);
        incarico.oreStraordinarieFestiveOTSGiorno = fromMinutiToOreString(strFestG);
        incarico.oreStraordinarieFestiveOTSNotte = fromMinutiToOreString(strFestN);
        return incarico
    }

    const calcolaOreDomenicaOTSFattBSAVE = (incarico) => {
        let oreInizio = fromStringToMinuti(incarico.oraInizio);
        let oreFine = fromStringToMinuti(incarico.oraFine);
        let oreOrdinarie = fromStringToMinuti(incarico.oreOrdinarie);
        let dg = 0;
        let dn = 0;
        let strFestG = 0;
        let strFestN = 0;
        let strG = 0;
        let strN = 0;
        let oreLunediOrdinarie = 0;
        if (oreInizio > oreFine) {
            //conteggio domenicali
            if (oreInizio < minuti06) {
                dn = (minuti06 - oreInizio) + 2;
                dg = minuti16;
            }
            if (oreInizio >= minuti06 && oreInizio <= minuti22) {
                dg = minuti22 - oreInizio;
                dn = 120
            }
            if (oreInizio > minuti22) {
                dn = minuti24 - oreInizio;
                dg = 0;
            }
            //conteggio straoridnari festivi
            let x = oreInizio + oreOrdinarie
            if (x <= minuti22) {
                strFestG = minuti22 - x;
                dg = dg > 0 ? dg - strFestG : 0
                strFestN = 120;
                dn = dn > 0 ? dn - strFestN : 0
            }
            if (x > minuti22) {
                strFestG = 0;
                if (x >= minuti24) {
                    strFestN = 0;
                } else {
                    strFestN = 120 - (x - minuti22);
                    dn = dn > 0 ? dn - strFestN : 0;
                }
            }
            //conteggio del lunedi
            let y = x - minuti24
            if (oreFine <= minuti06) {
                strG = 0;
                if (y > 0) {
                    strN = oreFine - y;
                    //per ora non ci faccio nulla
                    oreLunediOrdinarie = y;
                } else {
                    strN = oreFine;
                }
            }
            if (oreFine > minuti06 && oreFine <= minuti22) {
                if (y > 0) {
                    strN = minuti06 - y;
                    strN = strN > 0 ? strN : 0;
                    strG = oreFine - minuti06;
                    if (y > minuti06) {
                        strG = strG - (y - minuti06);
                    }
                } else {
                    strN = minuti06;
                    strG = oreFine - minuti06;
                }
            }
            if (oreFine > minuti22) {
                strN = strN + (minuti24 - oreFine)
            }
        } else {
            if (oreInizio < minuti06) {
                dn = minuti06 - oreInizio
            }
            if (oreFine < minuti06) {
                dn = oreFine - oreInizio
            }
            let xx = oreInizio + oreOrdinarie
            if (oreFine >= minuti06 && oreFine < minuti22) {
                if (oreInizio >= minuti06) {
                    dg = oreFine - oreInizio
                } else {
                    dg = oreFine - minuti06
                }
                if (oreFine > xx) {
                    strFestG = oreFine - xx
                    dg = dg - strFestG
                }
            }
            if (oreFine >= minuti22) {
                if (oreInizio >= minuti06) {
                    dg = minuti22 - oreInizio
                } else {
                    dg = minuti22 - minuti06
                }
                let z = oreFine - minuti22
                dn = dn + z
                if (oreFine > xx) {
                    if (xx >= minuti22) {
                        strFestN = oreFine - xx
                        dn = dn - strFestN
                    }
                }
            }
        }
        strFestG = strFestG + dg;
        strFestN = strFestN + dn
        incarico.oreStraordinarie = fromMinutiToOreString(strG);
        incarico.oreStraordinarieNotteOTS = fromMinutiToOreString(strN);
        //incarico.indennitaDomenicaleOTSGiorno = fromMinutiToOreString(dg);
        //incarico.indennitaDomenicaleOTSNotte = fromMinutiToOreString(dn);
        incarico.oreStraordinarieFestiveOTSGiorno = fromMinutiToOreString(strFestG);
        incarico.oreStraordinarieFestiveOTSNotte = fromMinutiToOreString(strFestN);
        return incarico
    }

    const calcolaSumMinTotIntSAVE = (incarichi) => {
        let somma = 0;
        for (let i = 0; i < incarichi.length; i++) {
            if (checkIfIsLastMultiplo(incarichi[i])) {
                somma = somma + incarichi[i].minutiMultipliInt + (incarichi[i].oreMultipliInt * 60)
            } else if (!incarichi[i].multiplo) {
                somma = somma + incarichi[i].minutiInt + (incarichi[i].oreInt * 60)
            }
            incarichi[i].sumMinTotInt = somma
        }
        return incarichi
    }

    const calcolaSumMinTotIntSAVE2 = (incarichi) => {
        let somma = 0;
        let numeroSettimana;
        let minuti;
        for (let i = 0; i < incarichi.length; i++) {
            if (checkIfIsLastMultiplo(incarichi[i])) {
                minuti = incarichi[i].minutiMultipliInt + (incarichi[i].oreMultipliInt * 60)
            } else if (!incarichi[i].multiplo) {
                minuti = incarichi[i].minutiInt + (incarichi[i].oreInt * 60)
            }
            if (numeroSettimana == incarichi[i].numeroSettimana) {
                somma = somma + minuti
                incarichi[i].sumMinTotInt = somma
            } else {
                somma = 0
                numeroSettimana = incarichi[i].numeroSettimana
                incarichi[i].sumMinTotInt = minuti
            }
        }
        return incarichi
    }

    const calcolaSumMinTotInt = (incarichi) => {
        let somma = 0;
        let numeroSettimana;
        let minuti;
        for (let i = 0; i < incarichi.length; i++) {
            minuti = incarichi[i].minutiInt + (incarichi[i].oreInt * 60)
            if (numeroSettimana == incarichi[i].numeroSettimana) {
                somma = somma + minuti
                incarichi[i].sumMinTotInt = somma
            } else {
                somma = minuti
                numeroSettimana = incarichi[i].numeroSettimana
                incarichi[i].sumMinTotInt = minuti
            }
        }
        return incarichi
    }

    const ordinaDate = (incarichi) => {
        incarichi = incarichi.sort(
            (a, b) => {
                if (a.data == b.data) {
                    let oreInizioA = fromStringToMinuti(a.oraInizio)
                    let oreInizioB = fromStringToMinuti(b.oraInizio)
                    return (oreInizioA < oreInizioB) ? -1 : (oreInizioA > oreInizioB) ? 1 : 0
                } else {
                    return 0
                }
            })
        return incarichi
    }


    const getCalcoliIncaricoSAVE = (el, objIncarico, tipologiaFatturazione) => {
        if (el.effettivo == "AS") {
            let giornoSettimana = getFirstLetterOfDay(objIncarico.giornoSettimana)
            objIncarico.oraInizio = el.oraInizio;
            objIncarico.oraFine = el.oraFine;
            let oreInt = objIncarico.oreInt;
            let minutiInt = objIncarico.minutiInt;
            let ferOrd = objIncarico.ferOrd
            if (objIncarico.multiplo && objIncarico.isLastMultiplo) {
                oreInt = objIncarico.oreMultipliInt;
                minutiInt = objIncarico.minutiMultipliInt;
            }
            objIncarico.oreOrdinarie = getOreOrdinarie(oreInt, minutiInt, ferOrd);
            objIncarico.oreNonLavorate = getOreNonLavorate(oreInt, minutiInt, ferOrd)
            if (objIncarico.disponibilita !== "RP" && objIncarico.disponibilita !== "RR" && !objIncarico.festivo) {
                objIncarico.oreStraordinarie = getOreStraordinarie(oreInt, minutiInt, ferOrd, objIncarico.qualifica)
                if (giornoSettimana !== "D") {
                    if (!objIncarico.percBancaOre || objIncarico.percBancaOre == "0") {
                        objIncarico.bancaOreRetrib = getOreBancaOre(oreInt, minutiInt, ferOrd)
                    } else if (objIncarico.percBancaOre == "50") {
                        objIncarico.bancaOreRetrib = getMetaBancaOre(oreInt, minutiInt, ferOrd)
                        objIncarico.bancaOreDaFruire = getMetaBancaOre(oreInt, minutiInt, ferOrd)
                    } else if (objIncarico.percBancaOre == "100") {
                        objIncarico.bancaOreDaFruire = getOreBancaOre(oreInt, minutiInt, ferOrd)
                    }
                }
            }
            if (tipologiaFatturazione == "A") {
                if (giornoSettimana === "D") {
                    objIncarico.indennitaDomenicale = objIncarico.ore;
                }
                if (objIncarico.festivo) {
                    objIncarico.oreStraordinarieFestive = getOreStraordinarieFestive(oreInt, minutiInt, ferOrd)
                }
                if (objIncarico.disponibilita === "RP") {
                    objIncarico.oreStrRipSp = getOreStraordinarieRiposo(oreInt, minutiInt, ferOrd, tipologiaFatturazione);
                }
            }
            if (tipologiaFatturazione == "B") {
                if (giornoSettimana === "D" || giornoSettimana === "S") {
                    objIncarico.bancaOreRetrib = fromMinutiToOreString((oreInt * 60) + minutiInt)
                    objIncarico.indennitaDomenicale = "";
                    objIncarico.bancaOreDaFruire = "";
                    objIncarico.oreOrdinarie = ""
                    objIncarico.oreStraordinarie = "";
                }
                if (giornoSettimana === "D") {
                    objIncarico.indennitaDomenicale = objIncarico.bancaOreRetrib;
                }
            }
        }
        return objIncarico
    }

    const fromMinutiToOre = (n) => {
        var num = n;
        var hours = (num / 60);
        var rhours = Math.floor(hours);
        var minutes = (hours - rhours) * 60;
        var rminutes = Math.round(minutes);
        return { ore: rhours, minuti: rminutes };
    }

    const checkIndennitaPersonalizzata = (indennitaPersonalizzata) => {
        if(indennitaPersonalizzata.codice.length > 0 && indennitaPersonalizzata.modalita.length > 0){
            if(indennitaPersonalizzata.modalita != "importo" && indennitaPersonalizzata.oreGiorni.length > 0){
                return indennitaPersonalizzata
            }else if(indennitaPersonalizzata.modalita == "importo" && indennitaPersonalizzata.importo.length > 0){
                return indennitaPersonalizzata
            }else {
                return []
            }
        }
    }
    
    const onClickSalvaIncarichiDipendenteModificati = () => {
        if (incarichiDipendenteArray.length > 0) {
            const indennitaPersonalizzataFiltered1 = checkIndennitaPersonalizzata(indennitaPersonalizzata1)
            const indennitaPersonalizzataFiltered2 = checkIndennitaPersonalizzata(indennitaPersonalizzata2)
            const indennitaPersonalizzataFiltered3 = checkIndennitaPersonalizzata(indennitaPersonalizzata3)
            let arrayIndennita = indennita.concat(indennitaPersonalizzataFiltered1).concat(indennitaPersonalizzataFiltered2).concat(indennitaPersonalizzataFiltered3);
            salvaPDFIncarichiDipendente({ incarichiDipendente: incarichiDipendenteArray, dataInizio: dataInizioInput, dataFine: dataFineInput, note: note, idDipendente: idDipendente, indennita: arrayIndennita })
            setIncarichiDipendenteArray([]);
            setTutteIndennitaiDipendenteArray([])
            setNote("")
            setBancaOreCorrezione(0)
            getIncarichiDipendente({ dataInizio: dataInizioInput, dataFine: dataFineInput, idDipendente: idDipendente });
            setIndennitaPersonalizzata1({ codice: "", nome: "INDENNITA PERSONALIZZATA", automatica: false, modalita: "giorni", oreGiorni: 0, importo: "", annotazioni: "" });
            setIndennitaPersonalizzata2({ codice: "", nome: "INDENNITA PERSONALIZZATA", automatica: false, modalita: "giorni", oreGiorni: 0, importo: "", annotazioni: "" });
            setIndennitaPersonalizzata3({ codice: "", nome: "INDENNITA PERSONALIZZATA", automatica: false, modalita: "giorni", oreGiorni: 0, importo: "", annotazioni: "" });
            setIndennita(initialStateIndennita)
        }
    }

    const onChangeSetNote = e => setNote(e.target.value);

    const onChangeSetDisponibilitaDipendenteSalvati = (e, i) => {
        let array = [...incarichiDipendenteArray];
        const index = disponibilitaJSON.disponibilita.findIndex(el => el.codice == e.target.value);
        if (index >= 0) {
            array[i].servizio = disponibilitaJSON.disponibilita[index].nome;
        }
        array[i].effettivo = e.target.value;
        setIncarichiDipendenteArray(array)
    }

    const onChangeOreSenzaCalcoli = (e, i) => {
        let array = [...incarichiDipendenteArray];
        array[i][e.target.name] = e.target.value;
        setIncarichiDipendenteArray(array)
    }

    const onChangeOreStraordinariOTSSenzaCalcoli = (e, i) => {
        let array = [...incarichiDipendenteArray];
        let oldstraordinari = fromStringToMinuti(array[i].oreStraordinarie)
        console.log(e.target.value)
        array[i].oreStraordinarie = e.target.value;
        array[i].sumMinTotInt =  array[i].sumMinTotInt - oldstraordinari + fromStringToMinuti(array[i].oreStraordinarie)
        setIncarichiDipendenteArray(array)
    }

    const onChangeOreFlessibilitaA = (e, i) => {
        let offset = 5
        let array = [...incarichiDipendenteArray];
        let valore = parseFloat(e.target.value);
        let action = valore > array[i].flessibilita ? offset : -offset
        if (valore >= 0 && valore <= 120) {
            let index = findFirstOreNonlavorate(array, i)
            if(offsetFlessibilita > 0 && offsetFlessibilita >= offset){
                let oreStraordinarie = fromStringToMinuti(array[i].oreStraordinarie)
                if (oreStraordinarie > 0) {
                    oreStraordinarie = oreStraordinarie - action
                    if (oreStraordinarie >= 0) {
                        array[i][e.target.name] = array[i][e.target.name] + action;
                        array[i].oreStraordinarie = fromMinutiToOreString(oreStraordinarie)
                        setOffsetFlessibilita(offsetFlessibilita - action)
                        setIncarichiDipendenteArray(array)
                    }
                } else if (fromStringToMinuti(array[i].bancaOreRetrib) > 0) {
                    let oreBancaOreRetrib = fromStringToMinuti(array[i].bancaOreRetrib)
                    oreBancaOreRetrib = oreBancaOreRetrib - action
                    if (oreBancaOreRetrib >= 0) {
                        array[i][e.target.name] = array[i][e.target.name] + action;
                        array[i].bancaOreRetrib = fromMinutiToOreString(oreBancaOreRetrib)
                        setOffsetFlessibilita(offsetFlessibilita - action)
                        setIncarichiDipendenteArray(array)
                    }
                } else if (fromStringToMinuti(array[i].bancaOreDaFruire) > 0) {
                    let oreBancaOreDaFruire = fromStringToMinuti(array[i].bancaOreDaFruire)
                    oreBancaOreDaFruire = oreBancaOreDaFruire - action
                    if (oreBancaOreDaFruire >= 0) {
                        array[i][e.target.name] = array[i][e.target.name] + action;
                        array[i].bancaOreDaFruire = fromMinutiToOreString(oreBancaOreDaFruire)
                        setOffsetFlessibilita(offsetFlessibilita - action)
                        setIncarichiDipendenteArray(array)
                    }
                }
            }
            else if (index >= 0 && fromStringToMinuti(array[index].oreNonLavorate) >= offset) {
                let oreNonLavorate = fromStringToMinuti(array[index].oreNonLavorate)
                let oreStraordinarie = fromStringToMinuti(array[i].oreStraordinarie)
                if (oreStraordinarie > 0) {
                    oreStraordinarie = oreStraordinarie - action
                    if (oreStraordinarie >= 0) {
                        array[i][e.target.name] = array[i][e.target.name] + action;
                        array[i].oreStraordinarie = fromMinutiToOreString(oreStraordinarie)
                        array[index].oreNonLavorate = fromMinutiToOreString(oreNonLavorate - action)
                        setIncarichiDipendenteArray(array)
                    }
                } else if (fromStringToMinuti(array[i].bancaOreRetrib) > 0) {
                    let oreBancaOreRetrib = fromStringToMinuti(array[i].bancaOreRetrib)
                    oreBancaOreRetrib = oreBancaOreRetrib - action
                    if (oreBancaOreRetrib >= 0) {
                        array[i][e.target.name] = array[i][e.target.name] + action;
                        array[i].bancaOreRetrib = fromMinutiToOreString(oreBancaOreRetrib)
                        array[index].oreNonLavorate = fromMinutiToOreString(oreNonLavorate - action)
                        setIncarichiDipendenteArray(array)
                    }
                } else if (fromStringToMinuti(array[i].bancaOreDaFruire) > 0) {
                    let oreBancaOreDaFruire = fromStringToMinuti(array[i].bancaOreDaFruire)
                    oreBancaOreDaFruire = oreBancaOreDaFruire - action
                    if (oreBancaOreDaFruire >= 0) {
                        array[i][e.target.name] = array[i][e.target.name] + action;
                        array[i].bancaOreDaFruire = fromMinutiToOreString(oreBancaOreDaFruire)
                        array[index].oreNonLavorate = fromMinutiToOreString(oreNonLavorate - action)
                        setIncarichiDipendenteArray(array)
                    }
                }
            }
        }
    }

    const onChangeOreFlessibilitaASAVE = (e, i) => {
        let offset = 5
        let array = [...incarichiDipendenteArray];
        let valore = parseFloat(e.target.value);
        let action = valore > array[i].flessibilita ? offset : -offset
        if (valore >= 0 && valore <= 120) {
            let index = findFirstOreNonlavorate(array, i)
            if (index >= 0 && fromStringToMinuti(array[index].oreNonLavorate) >= offset) {
                let oreNonLavorate = fromStringToMinuti(array[index].oreNonLavorate)
                let oreStraordinarie = fromStringToMinuti(array[i].oreStraordinarie)
                if (oreStraordinarie > 0) {
                    oreStraordinarie = oreStraordinarie - action
                    if (oreStraordinarie >= 0) {
                        array[i][e.target.name] = array[i][e.target.name] + action;
                        array[i].oreStraordinarie = fromMinutiToOreString(oreStraordinarie)
                        array[index].oreNonLavorate = fromMinutiToOreString(oreNonLavorate - action)
                        setIncarichiDipendenteArray(array)
                    }
                } else if (fromStringToMinuti(array[i].bancaOreRetrib) > 0) {
                    let oreBancaOreRetrib = fromStringToMinuti(array[i].bancaOreRetrib)
                    oreBancaOreRetrib = oreBancaOreRetrib - action
                    if (oreBancaOreRetrib >= 0) {
                        array[i][e.target.name] = array[i][e.target.name] + action;
                        array[i].bancaOreRetrib = fromMinutiToOreString(oreBancaOreRetrib)
                        array[index].oreNonLavorate = fromMinutiToOreString(oreNonLavorate - action)
                        setIncarichiDipendenteArray(array)
                    }
                } else if (fromStringToMinuti(array[i].bancaOreDaFruire) > 0) {
                    let oreBancaOreDaFruire = fromStringToMinuti(array[i].bancaOreDaFruire)
                    oreBancaOreDaFruire = oreBancaOreDaFruire - action
                    if (oreBancaOreDaFruire >= 0) {
                        array[i][e.target.name] = array[i][e.target.name] + action;
                        array[i].bancaOreDaFruire = fromMinutiToOreString(oreBancaOreDaFruire)
                        array[index].oreNonLavorate = fromMinutiToOreString(oreNonLavorate - action)
                        setIncarichiDipendenteArray(array)
                    }
                }
            }
        }
    }

    const onChangeOreFlessibilitaOTS = (e, i) => {
        let offset = 5
        let array = [...incarichiDipendenteArray];
        let valore = parseFloat(e.target.value);
        let action = valore > array[i].flessibilita ? offset : -offset
        if (valore >= 0 && valore <= 120) {
            let index = findFirstOreNonlavorate(array, i)
            if (index >= 0 && fromStringToMinuti(array[index].oreNonLavorate) >= offset) {
                let oreNonLavorate = fromStringToMinuti(array[index].oreNonLavorate)
                let oreStraordinarie = fromStringToMinuti(array[i].oreStraordinarie)
                if (oreStraordinarie > 0) {
                    oreStraordinarie = oreStraordinarie - action
                    if (oreStraordinarie >= 0) {
                        array[i][e.target.name] = array[i][e.target.name] + action;
                        array[i].oreStraordinarie = fromMinutiToOreString(oreStraordinarie)
                        array[index].oreNonLavorate = fromMinutiToOreString(oreNonLavorate - action)
                        array[i].flessibilitaModificata = true
                        array[index].flessibilitaModificata = true
                        array = calcolaSumMinTotInt(array)
                        setIncarichiDipendenteArray(array)
                    }
                }
            }
        }
    }

    const findFirstOreNonlavorate = (incarichi, index) => {
        for (var i = 0; i < incarichi.length; i++) {
            if (i !== index) {
                if (fromStringToMinuti(incarichi[i].oreNonLavorate) > 0) {
                    return i
                }
            }
        }
        return -1
    }

    const onChangeOreFlessibilitaOLD = (e, i) => {
        let array = [...incarichiDipendenteArray];
        array[i][e.target.name] = parseFloat(e.target.value);
        setIncarichiDipendenteArray(array)
    }

    const onChangeOreStraordinarie = (e, i) => {
        let array = [...incarichiDipendenteArray];
        array[i][e.target.name] = e.target.value;
        setIncarichiDipendenteArray(array)
    }

    const onChangeOreColonnaPersonalizzata = (e, i) => {
        let array = [...incarichiDipendenteArray];
        array[i][e.target.name] = e.target.value;
        setIncarichiDipendenteArray(array)
    }

    const onChangeOrariServiziDipendenteSalvatiOTS = (e, i) => {
        let array = [...incarichiDipendenteArray];
        array[i][e.target.name] = e.target.value;
        let oreAggiornate = aggiornaOreDopoModificaOrarioOTS(array[i], user.filiale.tipologiaFatturazione)
        array[i] = oreAggiornate.incarico;
        if (oreAggiornate.rigeneraMultiplo) {
            array[i].oreOrdinarie = "";
            array[i].bancaOreRetrib = "";
            array[i].bancaOreDaFruire = "";
            array[i].oreStraordinarie = "";
            array[i].oreStraordinarieNotteOTS = "";
            array[i].oreStraordinarieOTS1 = "";
            array[i].oreStraordinarieOTS2 = "";
            array[i].oreSupplementari = "";
            array[i].oreStraordinarieFestive = "";
            array[i].oreStraordinarieFestiveOTSGiorno = "";
            array[i].oreStraordinarieFestiveOTSNotte = "";
            array[i].oreStrRipSp = "";
            array[i].indennitaDomenicale = "";
            array[i].indennitaDomenicaleOTSGiorno = "";
            array[i].indennitaDomenicaleOTSNotte = "";
            array[i].flessibilita = 0;
            array[i].flessibilitaModificata = false;
            array[i].oreNonLavorate = "";
            setIncarichiDipendenteArray(array)
            let lastMultiploIndex = array.findIndex(el => el.id == array[i].idLastMultiplo);
            if (lastMultiploIndex > -1) {
                let oreAggiornateMultiplo = aggiornaOreDopoModificaOrarioOTS(array[lastMultiploIndex], user.filiale.tipologiaFatturazione)
                array[lastMultiploIndex] = oreAggiornateMultiplo.incarico;
                setIncarichiDipendenteArray(array)
            }
        }
        array = calcolaSumMinTotInt(array)
        setIncarichiDipendenteArray(array)
        console.log(array)
    }

    const aggiornaOreDopoModificaOrarioOTS = (incarico, tipologiaFatturazione) => {
        let rigeneraMultiplo = false
        incarico.ore = getOreBetweenDates(incarico.oraInizio, incarico.oraFine)
        incarico.oreInt = getOreInt(incarico.ore);
        incarico.minutiInt = getMinutiInt(incarico.ore);
        if (incarico.multiplo) {
            if (incarico.isLastMultiplo) {
                let arrayMultipli = incarichiDipendenteArray.filter(el => el.idLastMultiplo == incarico.id);
                let sommaOre = arrayMultipli.map(item => item.oreInt).reduce((prev, curr) => prev + curr, 0);
                let sommaMinuti = arrayMultipli.map(item => item.minutiInt).reduce((prev, curr) => prev + curr, 0);
                incarico.oreMultipliInt = sommaOre + getOreInt(incarico.ore);
                incarico.minutiMultipliInt = sommaMinuti + getMinutiInt(incarico.ore);
                if (incarico.minutiMultipliInt >= 60) {
                    let objNewMinutiOre = fromMinutiToOre(incarico.minutiMultipliInt)
                    incarico.oreMultipliInt = incarico.oreMultipliInt + objNewMinutiOre.ore
                    incarico.minutiMultipliInt = objNewMinutiOre.minuti
                }
            } else {
                rigeneraMultiplo = true
            }
        }
        incarico = getCalcoliIncaricoOTS(incarico, tipologiaFatturazione)
        return { incarico: incarico, rigeneraMultiplo: rigeneraMultiplo };
    }

    const onChangeOrariServiziDipendenteSalvatiGPG = (e, i) => {
        let array = [...incarichiDipendenteArray];
        setIndennita(initialStateIndennita)
        setTutteIndennitaiDipendenteArray([])
        console.log(array[i])
        array[i][e.target.name] = e.target.value;
        array[i].arrayIndennita = []
        let oreAggiornate = aggiornaOreDopoModificaOrarioGPG(array[i], user.filiale.tipologiaFatturazione)
        array[i] = oreAggiornate.incarico;
        setIncarichiDipendenteArray(array)
        if (oreAggiornate.rigeneraMultiplo) {
            array[i].oreOrdinarie = "";
            array[i].bancaOreRetrib = "";
            array[i].bancaOreDaFruire = "";
            array[i].oreStraordinarie = "";
            array[i].oreStraordinarieNotteOTS = "";
            array[i].oreStraordinarieOTS1 = "";
            array[i].oreStraordinarieOTS2 = "";
            array[i].oreSupplementari = "";
            array[i].oreStraordinarieFestive = "";
            array[i].oreStraordinarieFestiveOTSGiorno = "";
            array[i].oreStraordinarieFestiveOTSNotte = "";
            array[i].oreStrRipSp = "";
            array[i].indennitaDomenicale = "";
            array[i].indennitaDomenicaleOTSGiorno = "";
            array[i].indennitaDomenicaleOTSNotte = "";
            array[i].flessibilita = 0;
            array[i].flessibilitaModificata = false;
            array[i].oreNonLavorate = "";
            array[i].oreMultipliInt = null;
            array[i].minutiMultipliInt = null;
            array[i].arrayIndennita = []
            let lastMultiploIndex = array.findIndex(el => el.id == array[i].idLastMultiplo);
            if (lastMultiploIndex > -1) {
                let oreAggiornateMultiplo = aggiornaOreDopoModificaOrarioGPG(array[lastMultiploIndex], user.filiale.tipologiaFatturazione)
                array[lastMultiploIndex] = oreAggiornateMultiplo.incarico;
                setIncarichiDipendenteArray(array)
            }
        }
        let Newarray = calcolaSumMinTotInt(array)
        let arrayPopolato = popolaIndennita(Newarray, user.filiale.tipologiaFatturazione)
        let calcoloIndennita = calcolaIndennitaDominante(arrayPopolato)
        let arrayIndennitaGiorni = calcoloIndennita.arrayIndennitaGiorni
        let arrayIndennitaDominanti = calcoloIndennita.indennita
        setTutteIndennitaiDipendenteArray(arrayIndennitaGiorni)
        let indennitaArray = initialStateIndennita
        indennitaArray = generaListaIndennita(arrayIndennitaDominanti, indennitaArray, incarichiDipendente.dipendente)
        setIndennita(indennitaArray)

        setIncarichiDipendenteArray(arrayPopolato)
    }

    const aggiornaOreDopoModificaOrarioGPG = (incarico, tipologiaFatturazione) => {
        let rigeneraMultiplo = false
        incarico.ore = getOreBetweenDates(incarico.oraInizio, incarico.oraFine)
        incarico.oreInt = getOreInt(incarico.ore);
        incarico.minutiInt = getMinutiInt(incarico.ore);
        incarico.arrayIndennita = []
        console.log(incarico)
        if (incarico.multiplo) {
            if (incarico.isLastMultiplo) {
                console.log("Ultimo")
                let arrayMultipli = incarichiDipendenteArray.filter(el => el.idLastMultiplo == incarico.id);
                let sommaOre = arrayMultipli.map(item => item.oreInt).reduce((prev, curr) => prev + curr, 0);
                let sommaMinuti = arrayMultipli.map(item => item.minutiInt).reduce((prev, curr) => prev + curr, 0);
                incarico.oreMultipliInt = sommaOre + getOreInt(incarico.ore);
                incarico.minutiMultipliInt = sommaMinuti + getMinutiInt(incarico.ore);
                if (incarico.minutiMultipliInt >= 60) {
                    let objNewMinutiOre = fromMinutiToOre(incarico.minutiMultipliInt)
                    incarico.oreMultipliInt = incarico.oreMultipliInt + objNewMinutiOre.ore
                    incarico.minutiMultipliInt = objNewMinutiOre.minuti
                }
            } else {
                rigeneraMultiplo = true
            }
        }
        incarico = getCalcoliIncaricoGPG(incarico, tipologiaFatturazione)
        return { incarico: incarico, rigeneraMultiplo: rigeneraMultiplo };
    }



    const onChangeOrariServiziDipendenteSalvati = (e, i) => {
        let array = [...incarichiDipendenteArray];
        let oraInizio;
        let oraFine;
        array[i][e.target.name] = e.target.value;
        //if (e.target.name == 'oraInizio') {
        //    oraInizio = e.target.value
        //    oraFine = array[i].oraFine
        //} else {
        //    oraInizio = array[i].oraInizio
        //    oraFine = e.target.value
        //}
        //array[i].ore = getOreBetweenDates(oraInizio, oraFine)
        //QUI devo rifare i calcoli
        let oreAggiornate = aggiornaOreDopoModificaOrario(array[i])
        array[i] = oreAggiornate.incarico;
        setIncarichiDipendenteArray(array)
        if (oreAggiornate.rigeneraMultiplo) {
            array[i].oreOrdinarie = "";
            array[i].bancaOreRetrib = "";
            array[i].bancaOreDaFruire = "";
            array[i].oreStraordinarie = "";
            array[i].oreStraordinarieFestiveOTSGiorno = "";
            array[i].oreStraordinarieFestiveOTSNotte = "";
            array[i].oreStraordinarieOTS1 = "";
            array[i].oreStraordinarieOTS2 = "";
            array[i].oreSupplementari = "";
            array[i].oreStraordinarieFestive = "";
            array[i].oreStrRipSp = "";
            array[i].indennitaDomenicale = "";
            array[i].indennitaDomenicaleOTSGiorno = "";
            array[i].indennitaDomenicaleOTSNotte = "";
            array[i].oreMultipliInt = null;
            array[i].minutiMultipliInt = null;
            let lastMultiploIndex = array.findIndex(el => el.id == array[i].idLastMultiplo);
            if (lastMultiploIndex > -1) {
                let oreAggiornateMultiplo = aggiornaOreDopoModificaOrario(array[lastMultiploIndex])
                array[lastMultiploIndex] = oreAggiornateMultiplo.incarico;
                setIncarichiDipendenteArray(array)
            }
        }
        //let calcoloOreStraordinarieBancaOre = calcolaStraordinariBancaOre({ ore: array[i].ore, percBancaOre: array[i].percBancaOre });
        //array[i].lavoratoInRiposo = array[i].disponibilita == "RP" ? calcoloOreStraordinarieBancaOre.straordinari : ""
        //array[i].straordinari = array[i].disponibilita == "RP" ? "" : calcoloOreStraordinarieBancaOre.straordinari
        //array[i].bancaOre = calcoloOreStraordinarieBancaOre.bancaOre;
        //array[i].indennitaDomenicale = array[i].giornoSettimana == 0 ? array[i].ore : "";
    }

    function getDecimalPart(num) {
        if (Number.isInteger(num)) {
          return 0;
        }
      
        const decimalStr = num.toString().split('.')[1];
        return Number(decimalStr);
      }

      
    const onChangeSetIndennita = (i, field, value) => {
        let arrayIndennita = [...indennita];
        let indennita_element = { ...arrayIndennita[i] };
        let valore = value
        if(indennita_element.modalita == 'ore'){
            let numero = parseFloat(value)
            if(numero){
                console.log(numero) 
                var hours = Math.floor(numero); 
                console.log(hours) 
                var minutes = getDecimalPart(numero);
                minutes = Number("0."+minutes) * 60
                console.log(minutes) 
                valore = (hours * 60) + minutes
                indennita_element[field] = valore;
                arrayIndennita[i] = indennita_element;
                setIndennita(arrayIndennita)
            }
            
        }else{
            indennita_element[field] = valore;
            arrayIndennita[i] = indennita_element;
            setIndennita(arrayIndennita)
        }
       
    }

    const getFirstLetterOfDay = (day) => {
        switch (day) {
            case 0:
                day = "D";
                break;
            case 1:
                day = "L";
                break;
            case 2:
                day = "M";
                break;
            case 3:
                day = "M";
                break;
            case 4:
                day = "G";
                break;
            case 5:
                day = "V";
                break;
            case 6:
                day = "S";
        }
        return day
    }

    const onClickCalcolaFlessibilita = () => {
        if(incarichiDipendente.dipendente && incarichiDipendente.dipendente.qualifica == "gpg"){
            calcolaFlessibilitaGPG()
        }else{
            console.log("NULLA")
        }
    }
    const calcolaFlessibilitaGPG = () => {
        let incarichi = [...incarichiDipendenteArray];
        let sommaMinutiNonLavorati = getSommaMinutiNonLavorati(incarichi);
        let sommaOffsetFlessibilita = parseInt(offsetFlessibilita)
        sommaMinutiNonLavorati = sommaMinutiNonLavorati + sommaOffsetFlessibilita
        incarichi.map(incarico => {
            if(incarico.flessibilita < 120){
                if(incarico.oreStraordinarie.length > 0){
                    if(sommaMinutiNonLavorati > 0){
                        let calcoli = generaFlessibilitaSuOreStraoridinarieGPG(incarico, sommaMinutiNonLavorati, incarico.flessibilita)
                        incarico.oreStraordinarie = fromMinutiToOreString(calcoli.oreStraordinarie)
                        sommaMinutiNonLavorati = calcoli.sommaMinutiNonLavorati
                        incarico.flessibilita = calcoli.flessibilita
                    }
                }
                if(incarico.bancaOreRetrib.length > 0){
                    if(sommaMinutiNonLavorati > 0){
                        let calcoli = generaFlessibilitaSuBancaOreRetribGPG(incarico, sommaMinutiNonLavorati, incarico.flessibilita)
                        incarico.bancaOreRetrib = fromMinutiToOreString(calcoli.minutiBancaOreRetrib)
                        sommaMinutiNonLavorati = calcoli.sommaMinutiNonLavorati
                        incarico.flessibilita = calcoli.flessibilita
                    }
                }
                if(incarico.bancaOreDaFruire.length > 0){
                    if(sommaMinutiNonLavorati > 0){
                        let calcoli = generaFlessibilitaSuBancaOreDaFruireGPG(incarico, sommaMinutiNonLavorati, incarico.flessibilita)
                        incarico.bancaOreDaFruire = fromMinutiToOreString(calcoli.minutiBancaOreDaFruire)
                        sommaMinutiNonLavorati = calcoli.sommaMinutiNonLavorati
                        incarico.flessibilita = calcoli.flessibilita
                    }
                }
            }
        })
        let sommaFlessibilita = getSommaMinutiFlessibilita(incarichi);
        incarichi = distribuisciOreNonLavorate(incarichi, sommaFlessibilita)
        setIncarichiDipendenteArray(incarichi)
        let diff = sommaOffsetFlessibilita - sommaFlessibilita
        if(diff <= 0){
            setOffsetFlessibilita(0)
        }else{
            setOffsetFlessibilita(diff)
        }
        //console.log(sommaMinutiNonLavorati)
    }

    const distribuisciOreNonLavorate = (incarichi, sommaFlessibilita) => {
        let newFlessibilita = sommaFlessibilita
        incarichi.map(el => { 
            if(el.oreNonLavorate.length > 0){
                let minutiNonLavorati = fromStringToMinuti(el.oreNonLavorate)
                let diff = minutiNonLavorati - newFlessibilita
                if(diff >= 0){
                    el.oreNonLavorate = fromMinutiToOreString(diff)
                    newFlessibilita = 0
                }else{
                    el.oreNonLavorate = ""
                    newFlessibilita = newFlessibilita - minutiNonLavorati
                }
            }
        })
        return incarichi
    }

    const generaFlessibilitaSuBancaOreRetribGPG = (incarico, sommaMinutiNonLavorati, flessibilita) => {
        let minutiBancaOreRetrib = fromStringToMinuti(incarico.bancaOreRetrib)
        let diffFlessibilita = 120 - flessibilita
        if(diffFlessibilita > 0){
            let minutiNonLavoratiUtilizzabili = sommaMinutiNonLavorati > diffFlessibilita ? diffFlessibilita : sommaMinutiNonLavorati
            let diff = minutiBancaOreRetrib - minutiNonLavoratiUtilizzabili
            if(diff >= 0){
                sommaMinutiNonLavorati = sommaMinutiNonLavorati - minutiNonLavoratiUtilizzabili
                minutiBancaOreRetrib = diff
                flessibilita = flessibilita + minutiNonLavoratiUtilizzabili
            }else{
                sommaMinutiNonLavorati = sommaMinutiNonLavorati - minutiBancaOreRetrib
                flessibilita = flessibilita + minutiBancaOreRetrib
                minutiBancaOreRetrib = 0
            }
        }
        return {minutiBancaOreRetrib: minutiBancaOreRetrib, sommaMinutiNonLavorati: sommaMinutiNonLavorati, flessibilita: flessibilita}
    }

    const generaFlessibilitaSuBancaOreDaFruireGPG = (incarico, sommaMinutiNonLavorati, flessibilita) => {
        let minutiBancaOreDaFruire = fromStringToMinuti(incarico.bancaOreDaFruire)
        let diffFlessibilita = 120 - flessibilita
        if(diffFlessibilita > 0){
            let minutiNonLavoratiUtilizzabili = sommaMinutiNonLavorati > diffFlessibilita ? diffFlessibilita : sommaMinutiNonLavorati
            let diff = minutiBancaOreDaFruire - minutiNonLavoratiUtilizzabili
            if(diff >= 0){
                sommaMinutiNonLavorati = sommaMinutiNonLavorati - minutiNonLavoratiUtilizzabili
                minutiBancaOreDaFruire = diff
                flessibilita = flessibilita + minutiNonLavoratiUtilizzabili
            }else{
                sommaMinutiNonLavorati = sommaMinutiNonLavorati - minutiBancaOreDaFruire
                flessibilita = flessibilita + minutiBancaOreDaFruire
                minutiBancaOreDaFruire = 0
            }
        }
        return {minutiBancaOreDaFruire: minutiBancaOreDaFruire, sommaMinutiNonLavorati: sommaMinutiNonLavorati, flessibilita: flessibilita}
    }

    const generaFlessibilitaSuOreStraoridinarieGPG = (incarico, sommaMinutiNonLavorati, flessibilita) => {
        let minutiStraordinari = fromStringToMinuti(incarico.oreStraordinarie)
        let minutiNonLavoratiUtilizzabili = sommaMinutiNonLavorati > 60 ? 60 : sommaMinutiNonLavorati
        let diff = minutiStraordinari - minutiNonLavoratiUtilizzabili
        if(diff >= 0){
            minutiStraordinari = diff
            sommaMinutiNonLavorati = sommaMinutiNonLavorati - minutiNonLavoratiUtilizzabili
            flessibilita = flessibilita + minutiNonLavoratiUtilizzabili
        }else{
            sommaMinutiNonLavorati = sommaMinutiNonLavorati - minutiStraordinari
            flessibilita = flessibilita + minutiStraordinari
            minutiStraordinari = 0
        }
        return {oreStraordinarie: minutiStraordinari, sommaMinutiNonLavorati: sommaMinutiNonLavorati, flessibilita: flessibilita}
    }

    const getSommaMinutiFlessibilita = (incarichi) => {
        let sommaMinutiFlessibilita = 0;
        incarichi.map(el => { 
            if(el.flessibilita > 0){
                sommaMinutiFlessibilita = sommaMinutiFlessibilita + el.flessibilita
            }
        })
        return sommaMinutiFlessibilita
    }

    const getSommaMinutiNonLavorati = (incarichi) => {
        let sommaMinutiNonLavorati = 0;
        incarichi.map(el => { 
            if(el.oreNonLavorate.length > 0){
                sommaMinutiNonLavorati = sommaMinutiNonLavorati + fromStringToMinuti(el.oreNonLavorate)
            }
        })
        return sommaMinutiNonLavorati
    }

    const onClickCalcolaFlessibilitaSAVE = () => {
        let incarichi = [...incarichiDipendenteArray];
        for (var i = 0; i < incarichi.length; i++) {
            if (checkIfNotMultiploOrLastMultiplo(incarichi[i])) {
                console.log("Incarico del: " + incarichi[i].data)
                for (var j = 0; j < incarichi.length; j++) {
                    if (j != i) {
                        let oreNonLavorate = fromStringToMinuti(incarichi[i].oreNonLavorate);
                        if (oreNonLavorate > 0) {
                            console.log("Ore non lavorate: " + oreNonLavorate)
                            let oldFlessibilita = incarichi[j].flessibilita
                            if ((checkIfNotMultiploOrLastMultiplo(incarichi[j]) && oldFlessibilita < 120)) {
                                console.log("Adeguato")
                                //console.log("Flessibilita: "+incarichi[j].flessibilita)
                                //console.log("Ore Straordinarie prima: "+incarichi[j].oreStraordinarie)
                                incarichi[j] = sottraiFlessibilitaDaStraordinari(incarichi[j], oreNonLavorate)
                                let diffFlessibilita = incarichi[j].flessibilita - oldFlessibilita
                                console.log("Ho scalato: " + diffFlessibilita)
                                console.log("Ovvero: " + incarichi[j].flessibilita)
                                console.log("meno: " + oldFlessibilita)

                                //console.log("Ore Straordinarie dopo: "+incarichi[j].oreStraordinarie)
                                //console.log("Flssibilita dopo: "+incarichi[j].flessibilita)
                                oreNonLavorate = oreNonLavorate - diffFlessibilita
                                //console.log(diffFlessibilita)
                                //console.log(oreNonLavorate)

                            }
                            incarichi[i].oreNonLavorate = fromMinutiToOreString(oreNonLavorate)
                        }
                    }
                };
            }
        };
        setIncarichiDipendenteArray(incarichi)
    }


    const prova = () => {
        let incarichi = [...incarichiDipendenteArray];
        for (var i = 0; i < incarichi.length; i++) {
            if (checkIfNotMultiploOrLastMultiplo(incarichi[i])) {
                let oreNonLavorate = fromStringToMinuti(incarichi[i].oreNonLavorate);
                if (oreNonLavorate > 0) {
                    for (var j = 0; j < incarichi.length; j++) {
                        if (j != i) {
                            if (checkIfNotMultiploOrLastMultiplo(incarichi[j])) {
                                let oreStraordinarie = fromStringToMinuti(incarichi[j].oreStraordinarie)
                                if (oreStraordinarie > 0 && (incarichi[j].flessibilita < 120)) {
                                    let possibilita = 120 - incarichi[j].flessibilita
                                    let diff = oreStraordinarie - oreNonLavorate
                                    if (diff > 0) {
                                        if (diff >= 60 && possibilita >= 60) {
                                            oreNonLavorate = oreNonLavorate - 60
                                            oreStraordinarie = oreStraordinarie - 60
                                            incarichi[j].flessibilita = incarichi[j].flessibilita + 60
                                        }
                                        else if (diff < 60 && possibilita > oreNonLavorate) {
                                            if (oreNonLavorate <= 60) {
                                                oreNonLavorate = 0
                                                oreStraordinarie = oreStraordinarie - oreNonLavorate
                                                incarichi[j].flessibilita = incarichi[j].flessibilita + oreNonLavorate
                                            }
                                            else {
                                                oreNonLavorate = oreNonLavorate - 60
                                                oreStraordinarie = oreStraordinarie - 60
                                                incarichi[j].flessibilita = incarichi[j].flessibilita + 60
                                            }
                                        }




                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    const sottraiFlessibilitaDaStraordinari = (incarico, oreNonLavorate) => {
        let oreStraordinarie = fromStringToMinuti(incarico.oreStraordinarie)
        let flessibilita = incarico.flessibilita
        console.log("Ore starordinarie: " + oreStraordinarie)
        console.log("Flessibilita: " + flessibilita)
        if (oreStraordinarie > 0) {
            if (oreNonLavorate < 60 && (oreNonLavorate < (120 - flessibilita))) {
                flessibilita = 120 - oreNonLavorate
                console.log("Modifico Flessibilita: " + flessibilita)
            }
            if (oreStraordinarie >= 60) {
                if (flessibilita <= 60) {
                    if (oreNonLavorate < 60) {
                        incarico.oreStraordinarie = fromMinutiToOreString(oreStraordinarie - oreNonLavorate)
                        incarico.flessibilita = flessibilita + oreNonLavorate
                    } else {
                        incarico.oreStraordinarie = fromMinutiToOreString(oreStraordinarie - 60)
                        incarico.flessibilita = flessibilita + 60
                    }
                } else {
                    if (oreNonLavorate < (120 - flessibilita)) {
                        incarico.oreStraordinarie = fromMinutiToOreString(oreStraordinarie - oreNonLavorate)
                        incarico.flessibilita = flessibilita + oreNonLavorate
                    } else {
                        incarico.oreStraordinarie = fromMinutiToOreString(oreStraordinarie - (120 - flessibilita))
                        incarico.flessibilita = 120
                    }
                }
            } else {
                if (flessibilita <= 60) {
                    if (oreNonLavorate < (120 - flessibilita)) {
                        incarico.oreStraordinarie = fromMinutiToOreString(oreStraordinarie - oreNonLavorate)
                        incarico.flessibilita = flessibilita + oreNonLavorate
                    } else {
                        incarico.oreStraordinarie = ""
                        incarico.flessibilita = flessibilita + oreStraordinarie
                    }
                } else {
                    if (oreStraordinarie >= (120 - flessibilita)) {
                        if (oreNonLavorate < (120 - flessibilita)) {
                            incarico.oreStraordinarie = fromMinutiToOreString(oreStraordinarie - oreNonLavorate)
                            incarico.flessibilita = flessibilita + oreNonLavorate
                        } else {
                            incarico.oreStraordinarie = fromMinutiToOreString(oreStraordinarie - (120 - flessibilita))
                            incarico.flessibilita = 120
                        }
                    } else {
                        if (oreNonLavorate < (120 - flessibilita)) {
                            incarico.oreStraordinarie = fromMinutiToOreString(oreStraordinarie - oreNonLavorate)
                            incarico.flessibilita = flessibilita + oreNonLavorate
                        } else {
                            incarico.oreStraordinarie = ""
                            incarico.flessibilita = flessibilita + oreStraordinarie
                        }
                    }
                }
            }
        }
        return incarico
    }

    const checkIfNotMultiploOrLastMultiplo = (incarico) => {
        if ((!incarico.multiplo || (incarico.multiplo && incarico.isLastMultiplo))) {
            return true
        } else {
            return false
        }
    }

    const checkIfIsLastMultiplo = (incarico) => {
        if (incarico.multiplo && incarico.isLastMultiplo) {
            return true
        } else {
            return false
        }
    }

    const onClickCalcolaFlessibilitaOLD = () => {
        let incarichi = [...incarichiDipendenteArray];
        for (var i = 0; i < incarichi.length; i++) {
            if (incarichi[i].oreNonLavorate.length) {
                console.log("Incarico in DATA:" + incarichi[i].data + " con ore ORE NON LAVORATE:" + incarichi[i].oreNonLavorate)
                let calcoli = togliStraordinariBancaOrePerFlessibilita(incarichi, incarichi[i])
                incarichi = calcoli.incarichi
                incarichi[i] = calcoli.incarico
            }
            console.log("_______________________")
        };
        setIncarichiDipendenteArray(incarichi)
    }

    const togliStraordinariBancaOrePerFlessibilita = (incarichi, incarico) => {
        let oreNonLavorateInt = getOreInt(incarico.oreNonLavorate);
        let minutiNonLavorateInt = getMinutiInt(incarico.oreNonLavorate);
        let oreNonLavorateInMinuti = (oreNonLavorateInt * 60) + minutiNonLavorateInt;
        console.log("inizio ricerca con for loop")
        console.log("ORE NON LAVORATE:" + oreNonLavorateInMinuti)
        for (var i = 0; i < incarichi.length; i++) {
            if (((!incarichi[i].multiplo || (incarichi[i].multiplo && incarichi[i].isLastMultiplo)) && incarichi[i].flessibilita < 120)) {
                console.log("trovato elemento valido")
                let oreStraordinariInt = getOreInt(incarichi[i].oreStraordinarie);
                let minutiStraordinariInt = getMinutiInt(incarichi[i].oreStraordinarie);
                let straordinariInMinuti = (oreStraordinariInt * 60) + minutiStraordinariInt;
                let oreBancaOreDaFruireInt = getOreInt(incarichi[i].bancaOreDaFruire);
                let minutiBancaOreDaFruireInt = getMinutiInt(incarichi[i].bancaOreDaFruire);
                console.log("ORE:" + oreBancaOreDaFruireInt)
                console.log("MINUTI:" + minutiBancaOreDaFruireInt)
                let bancaOreDaFruireInMinuti = (oreBancaOreDaFruireInt * 60) + minutiBancaOreDaFruireInt;
                let oreBancaOreRetribInt = getOreInt(incarichi[i].bancaOreRetrib);
                let minutiBancaOreRetribInt = getMinutiInt(incarichi[i].bancaOreRetrib);
                let bancaOreRetribInMinuti = (oreBancaOreRetribInt * 60) + minutiBancaOreRetribInt;
                let flessibilitaResiduaInMinuti = 120 - (incarichi[i].flessibilita);
                if (straordinariInMinuti > 0) {
                    console.log("elemento in DATA:" + incarichi[i].data + " con STRAORDINARI:" + straordinariInMinuti)
                    if (oreNonLavorateInMinuti > flessibilitaResiduaInMinuti) {
                        console.log("Le ore non lavorate sono MAGGIORI della flessibilita residua (" + flessibilitaResiduaInMinuti + ")")
                        incarichi[i].oreStraordinarie = straordinariInMinuti - flessibilitaResiduaInMinuti < 0 ? "00:00" : fromMinutiToOreString(straordinariInMinuti - flessibilitaResiduaInMinuti)
                        oreNonLavorateInMinuti = oreNonLavorateInMinuti - (straordinariInMinuti - flessibilitaResiduaInMinuti < 0 ? straordinariInMinuti : flessibilitaResiduaInMinuti)
                        incarichi[i].flessibilita = incarichi[i].flessibilita + (oreNonLavorateInMinuti - straordinariInMinuti < 0 ? oreNonLavorateInMinuti : straordinariInMinuti);
                        console.log("le ore straordinarie 2 diventano:" + incarichi[i].oreStraordinarie)
                        console.log("la flsessibilita 2 diventa:" + incarichi[i].flessibilita)
                        //esco continuo con bancaore
                    } else {
                        console.log("Le ore non lavorate sono MINORI della flessibilita residua (" + flessibilitaResiduaInMinuti + ")")
                        incarichi[i].oreStraordinarie = straordinariInMinuti - oreNonLavorateInMinuti < 0 ? "00:00" : fromMinutiToOreString(straordinariInMinuti - oreNonLavorateInMinuti)
                        incarichi[i].flessibilita = incarichi[i].flessibilita + (oreNonLavorateInMinuti - straordinariInMinuti < 0 ? oreNonLavorateInMinuti : straordinariInMinuti);
                        console.log("le ore straordinarie diventano:" + incarichi[i].oreStraordinarie)
                        console.log("la flessibilita diventa:" + incarichi[i].flessibilita)
                        oreNonLavorateInMinuti = oreNonLavorateInMinuti - (straordinariInMinuti - oreNonLavorateInMinuti < 0 ? straordinariInMinuti : oreNonLavorateInMinuti)
                        //esco continuo con bancaore
                    }
                    console.log("rimangono " + oreNonLavorateInMinuti + " ore non valorate")
                    if (oreNonLavorateInMinuti <= 0) {
                        incarico.oreNonLavorate = ""
                        return { incarichi: incarichi, incarico: incarico }
                    }
                }
                if (bancaOreDaFruireInMinuti > 0 && incarichi[i].flessibilita < 120) {
                    console.log("cerco in banca ore da fruire")
                    flessibilitaResiduaInMinuti = 120 - (incarichi[i].flessibilita);
                    if (oreNonLavorateInMinuti > flessibilitaResiduaInMinuti) {
                        console.log("Le ore non lavorate sono MAGGIORI della flessibilita residua (" + flessibilitaResiduaInMinuti + ")")
                        console.log("ECCOLO " + bancaOreDaFruireInMinuti)
                        incarichi[i].bancaOreDaFruire = bancaOreDaFruireInMinuti - flessibilitaResiduaInMinuti < 0 ? "00:00" : fromMinutiToOreString(bancaOreDaFruireInMinuti - flessibilitaResiduaInMinuti)
                        oreNonLavorateInMinuti = oreNonLavorateInMinuti - (bancaOreDaFruireInMinuti - flessibilitaResiduaInMinuti < 0 ? bancaOreDaFruireInMinuti : flessibilitaResiduaInMinuti)
                        incarichi[i].flessibilita = incarichi[i].flessibilita + (flessibilitaResiduaInMinuti)
                        console.log("le ore bancaOreDaFruire diventano:" + incarichi[i].bancaOreDaFruire)
                        console.log("la flessibilita diventa:" + incarichi[i].flessibilita)
                        //esco continuo con bancaore
                    } else {
                        console.log("Le ore non lavorate sono MINORI della flessibilita residua (" + flessibilitaResiduaInMinuti + ")")
                        incarichi[i].bancaOreDaFruire = flessibilitaResiduaInMinuti - oreNonLavorateInMinuti < 0 ? "00:00" : fromMinutiToOreString(flessibilitaResiduaInMinuti - oreNonLavorateInMinuti)
                        oreNonLavorateInMinuti = oreNonLavorateInMinuti - (flessibilitaResiduaInMinuti - oreNonLavorateInMinuti < 0 ? flessibilitaResiduaInMinuti : oreNonLavorateInMinuti)
                        incarichi[i].flessibilita = incarichi[i].flessibilita + (oreNonLavorateInMinuti)
                        console.log("le ore bancaOreDaFruire diventano:" + incarichi[i].bancaOreDaFruire)
                        console.log("la flessibilita diventa:" + incarichi[i].flessibilita)
                        //esco continuo con bancaore
                    }
                    console.log("rimangono " + oreNonLavorateInMinuti + " ore non valorate")
                    if (oreNonLavorateInMinuti <= 0) {
                        incarico.oreNonLavorate = ""
                        return { incarichi: incarichi, incarico: incarico }
                    }
                }
                if (bancaOreRetribInMinuti > 0 && incarichi[i].flessibilita < 120) {
                    console.log("cerco in banca ore retrib")
                    flessibilitaResiduaInMinuti = 120 - (incarichi[i].flessibilita);
                    if (oreNonLavorateInMinuti > flessibilitaResiduaInMinuti) {
                        console.log("Le ore non lavorate sono MAGGIORI della flessibilita residua (" + flessibilitaResiduaInMinuti + ")")
                        incarichi[i].bancaOreRetrib = bancaOreRetribInMinuti - flessibilitaResiduaInMinuti < 0 ? "00:00" : fromMinutiToOreString(bancaOreRetribInMinuti - flessibilitaResiduaInMinuti)
                        oreNonLavorateInMinuti = oreNonLavorateInMinuti - (bancaOreRetribInMinuti - flessibilitaResiduaInMinuti < 0 ? bancaOreRetribInMinuti : flessibilitaResiduaInMinuti)
                        incarichi[i].flessibilita = incarichi[i].flessibilita + (flessibilitaResiduaInMinuti)
                        console.log("le ore bancaOreRetrib diventano:" + incarichi[i].bancaOreRetrib)
                        console.log("la flessibilita diventa:" + incarichi[i].flessibilita)
                        //esco continuo con bancaore
                    } else {
                        console.log("Le ore non lavorate sono MINORI della flessibilita residua (" + flessibilitaResiduaInMinuti + ")")
                        incarichi[i].bancaOreRetrib = flessibilitaResiduaInMinuti - oreNonLavorateInMinuti < 0 ? "00:00" : fromMinutiToOreString(flessibilitaResiduaInMinuti - oreNonLavorateInMinuti)
                        oreNonLavorateInMinuti = oreNonLavorateInMinuti - (flessibilitaResiduaInMinuti - oreNonLavorateInMinuti < 0 ? flessibilitaResiduaInMinuti : oreNonLavorateInMinuti)
                        incarichi[i].flessibilita = incarichi[i].flessibilita + (oreNonLavorateInMinuti)
                        console.log("le ore bancaOreRetrib diventano:" + incarichi[i].bancaOreRetrib)
                        console.log("la flessibilita diventa:" + incarichi[i].flessibilita)
                        //esco continuo con bancaore
                    }
                    console.log("rimangono " + oreNonLavorateInMinuti + " ore non valorate")
                    if (oreNonLavorateInMinuti <= 0) {
                        incarico.oreNonLavorate = ""
                        return { incarichi: incarichi, incarico: incarico }
                    }
                }
            }
        }
        incarico.oreNonLavorate = fromMinutiToOreString(oreNonLavorateInMinuti)
        return { incarichi: incarichi, incarico: incarico }
    }

    const calcoloTotaliOreStrOTSSAVE = (incarichi, field) => {
        let hours = 0, minutes = 0;
        incarichi.forEach((incarico) => {
            if (incarico.oreStraordinarie) {
                const split = incarico.oreStraordinarie.split(':');
                if (field == 'oreStraordinarieNotte') {
                    if (incarico.sumMinTotInt <= 2880 && incarico.isNotturna) {
                        hours += parseInt(split[0]);
                        minutes += parseInt(split[1]);
                        hours += Math.floor(minutes / 60);
                        minutes = minutes % 60;
                    }
                } else if (field == 'oreStraordinarieMin48') {
                    if (incarico.sumMinTotInt <= 2880 && !incarico.isNotturna) {
                        hours += parseInt(split[0]);
                        minutes += parseInt(split[1]);
                        hours += Math.floor(minutes / 60);
                        minutes = minutes % 60;
                    }
                } else {
                    if (incarico.sumMinTotInt > 2880 && !incarico.isNotturna) {
                        hours += parseInt(split[0]);
                        minutes += parseInt(split[1]);
                        hours += Math.floor(minutes / 60);
                        minutes = minutes % 60;
                    }
                }
            }
        });
        if (hours == 0 && minutes == 0) {
            return "00:00"
        } else {
            return hours + ':' + formattedNumber(minutes)
        }
    }

    const calcoloTotaliOreStrOTS = (incarichi, field) => {
        let somma = 0;
        incarichi.forEach((incarico) => {
            let minutiStraordinari = fromStringToMinuti(incarico.oreStraordinarie)
            let diffSumMinTotInt;
            if (minutiStraordinari > 0) {
                if (field == 'oreStraordinarieNotte') {
                    if (incarico.oreStraordinarieNotteOTS.length > 0) {
                        somma = somma + fromStringToMinuti(incarico.oreStraordinarieNotteOTS)
                    }
                } else {
                    if (incarico.sumMinTotInt > 2880) {
                        diffSumMinTotInt = incarico.sumMinTotInt - minutiStraordinari
                        if (diffSumMinTotInt < 2880) {
                            let calcoloStr2 = incarico.sumMinTotInt - 2880
                            let calcoloStr1 = 2880 - diffSumMinTotInt
                            if (field == 'oreStraordinarieMin48') {
                                somma = somma + calcoloStr1
                            }
                            if (field == 'oreStraordinarieMax48') {
                                somma = somma + calcoloStr2
                            }
                        } else {
                            if (field == 'oreStraordinarieMax48') {
                                somma = somma + minutiStraordinari
                            }
                        }
                    } else {
                        if (field == 'oreStraordinarieMin48') {
                            somma = somma + minutiStraordinari
                        }
                    }
                }

            }
        });
        if (somma == 0) {
            return "00:00"
        } else {
            return fromMinutiToOreString(somma)
        }
    }



    const calcolaStraordinari48 = (incarico) => {
        let minutiStraordinari = fromStringToMinuti(incarico.oreStraordinarie)
        let diffSumMinTotInt;
        let minutiNotte = 0;
        if (minutiStraordinari > 0) {
            if (incarico.sumMinTotInt > 2880) {
                diffSumMinTotInt = incarico.sumMinTotInt - minutiStraordinari
                if (diffSumMinTotInt < 2880) {
                    let calcoloStr2 = incarico.sumMinTotInt - 2880
                    let calcoloStr1 = 2880 - diffSumMinTotInt
                    return { primaColonna: fromMinutiToOreString(calcoloStr1), secondaColonna: fromMinutiToOreString(calcoloStr2) }
                } else {
                    return { primaColonna: "", secondaColonna: fromMinutiToOreString(minutiStraordinari) }
                }
            } else {
                return { primaColonna: fromMinutiToOreString(minutiStraordinari), secondaColonna: "" }
            }
        } else {
            return { primaColonna: "", secondaColonna: "" }
        }
    }

    return (
        <div className="div_wrap_100">
            <div className="div_wrap_60">
                <div className="div_container">
                    <div className="title_window">
                        <div className="title_window_p">Nuovo PDF incarichi dipendente</div>
                    </div>
                    <div className="dati_container">
                        <div className="fun_assegnazione_incarichi">
                            <div className="row row_datapicker">
                                <div className="item_1 filtro_wrap_incarichi">
                                    <div id="w-node-_284812ee-7924-9f38-86bb-8d2cc43dff3c-867f153d" className="div_item_1">
                                        <div id="w-node-_80bb2acb-3043-3649-0498-379a652f3b04-867f153d" className="label_text">Data inizio</div>
                                        <div data-hover="false" data-delay="0" className="select w-dropdown">
                                            <DatePicker
                                                value={dataInizioInput}
                                                format="dd/MM/y"
                                                weekPicker
                                                onChange={onChangeRangeDateInizio}
                                                locale="it-IT"
                                                clearIcon={null}
                                                weekStartDayIndex={1}
                                            />
                                        </div>
                                    </div>
                                    <div id="w-node-_284812ee-7924-9f38-86bb-8d2cc43dff3c-867f153d" className="div_item_1">
                                        <div id="w-node-_80bb2acb-3043-3649-0498-379a652f3b04-867f153d" className="label_text">Data fine</div>
                                        <div data-hover="false" data-delay="0" className="select w-dropdown">
                                            <DatePicker
                                                value={dataFineInput}
                                                format="dd/MM/y"
                                                weekPicker
                                                onChange={onChangeRangeDateFine}
                                                locale="it-IT"
                                                clearIcon={null}
                                                weekStartDayIndex={1}
                                            />
                                        </div>
                                    </div>
                                    <div id="w-node-_714e71a9-0550-fe0e-26e9-8b45ac42ab89-867f153d" className="div_item_1">
                                        <div className="w-form">
                                            <form id="email-form" name="email-form" data-name="Email Form" method="get">
                                                <label className="w-checkbox checkbox-field"><span className="label_text w-form-label" htmlFor="checkbox-2"></span></label>
                                                <a id="w-node-_714e71a9-0550-fe0e-26e9-8b45ac42ab90-867f153d" onClick={() => (onClickCalcolaFlessibilita())} className="button w-button">Calcola flessibilità</a>
                                            </form>
                                        </div>
                                    </div>
                                    <div id="w-node-_284812ee-7924-9f38-86bb-8d2cc43dff3c-867f153d" className="div_item_1">
                                        <div id="w-node-_80bb2acb-3043-3649-0498-379a652f3b04-867f153d" className="label_text">Minuti non lavorati mese precedente</div>
                                        <input type="number" className="w-input select button_list" maxLength="256" name="offsetFlessibilita" data-name="offsetFlessibilita" value={offsetFlessibilita} placeholder="Inserisci email" id="offsetFlessibilita" onChange={e => setOffsetFlessibilita(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                            <div className="row row_datapicker">
                                <div className="item_1 filtro_wrap_incarichi">
                                    <div id="w-node-_284812ee-7924-9f38-86bb-8d2cc43dff3c-867f153d" className="div_item_1">
                                        <div id="w-node-_80bb2acb-3043-3649-0498-379a652f3b04-867f153d" className="label_text">Riposi lavorati</div>
                                        <input type="number" className="w-input select button_list" maxLength="256" name="riposiLavorati" data-name="riposiLavorati" value={riposiLavorati} id="riposiLavorati" />
                                    </div>
                                    <div id="w-node-_284812ee-7924-9f38-86bb-8d2cc43dff3c-867f153d" className="div_item_1">
                                        <div id="w-node-_80bb2acb-3043-3649-0498-379a652f3b04-867f153d" className="label_text">Riposi recuperati</div>
                                        <input type="number" className="w-input select button_list" maxLength="256" name="riposiRecuperati" data-name="riposiRecuperati" value={riposiRecuperati} id="riposiRecuperati" />
                                    </div>
                                </div>
                                
                            </div>
                            <div className="row">
                                <h4>{dipendenteData.cognome.toUpperCase()} {dipendenteData.nome.toUpperCase()} - <Moment format='DD/MM/YYYY'>{dataInizioInput}</Moment> - <Moment format='DD/MM/YYYY'>{dataFineInput}</Moment></h4>
                            </div>
                            {dipendenteData.qualifica == 'gpg' || dipendenteData.qualifica == 'impiegato' ?
                                <ColonneFatturazioneGPG
                                    setColonnaPersonalizzata1={setColonnaPersonalizzata1}
                                    colonnaPersonalizzata1={colonnaPersonalizzata1}
                                    colonnaPersonalizzata2={colonnaPersonalizzata2}
                                    setColonnaPersonalizzata2={setColonnaPersonalizzata2}
                                    incarichiLoading={incarichiLoading}
                                    incarichiDipendenteArray={incarichiDipendenteArray}
                                    onChangeSetDisponibilitaDipendenteSalvati={onChangeSetDisponibilitaDipendenteSalvati}
                                    onChangeOrariServiziDipendenteSalvatiGPG={onChangeOrariServiziDipendenteSalvatiGPG}
                                    onChangeOreSenzaCalcoli={onChangeOreSenzaCalcoli}
                                    onChangeOreStraordinarie={onChangeOreStraordinarie}
                                    onChangeOreFlessibilitaA={onChangeOreFlessibilitaA}
                                    onChangeOreColonnaPersonalizzata={onChangeOreColonnaPersonalizzata}
                                    calcoloGiorniLavorati={calcoloGiorniLavorati}
                                    calcoloTotaliOre={calcoloTotaliOre}
                                    calcoloTotaliFlessibilita={calcoloTotaliFlessibilita}
                                    fromMinutiToOreString={fromMinutiToOreString}
                                    indennita={indennita}
                                    getFirstLetterOfDay={getFirstLetterOfDay}
                                    disponibilitaJSON={disponibilitaJSON}
                                    onChangeSetIndennita={onChangeSetIndennita}
                                    setIndennitaPersonalizzata1={setIndennitaPersonalizzata1}
                                    setIndennitaPersonalizzata2={setIndennitaPersonalizzata2}
                                    setIndennitaPersonalizzata3={setIndennitaPersonalizzata3}
                                    indennitaPersonalizzata1={indennitaPersonalizzata1}
                                    indennitaPersonalizzata2={indennitaPersonalizzata2}
                                    indennitaPersonalizzata3={indennitaPersonalizzata3}
                                />
                                :
                                <ColonneFatturazioneOTS
                                    setColonnaPersonalizzata1={setColonnaPersonalizzata1}
                                    colonnaPersonalizzata1={colonnaPersonalizzata1}
                                    colonnaPersonalizzata2={colonnaPersonalizzata2}
                                    setColonnaPersonalizzata2={setColonnaPersonalizzata2}
                                    incarichiLoading={incarichiLoading}
                                    incarichiDipendenteArray={incarichiDipendenteArray}
                                    onChangeSetDisponibilitaDipendenteSalvati={onChangeSetDisponibilitaDipendenteSalvati}
                                    onChangeOrariServiziDipendenteSalvatiOTS={onChangeOrariServiziDipendenteSalvatiOTS}
                                    onChangeOreSenzaCalcoli={onChangeOreSenzaCalcoli}
                                    onChangeOreStraordinariOTSSenzaCalcoli={onChangeOreStraordinariOTSSenzaCalcoli}
                                    onChangeOreStraordinarie={onChangeOreStraordinarie}
                                    onChangeOreFlessibilitaOTS={onChangeOreFlessibilitaOTS}
                                    onChangeOreColonnaPersonalizzata={onChangeOreColonnaPersonalizzata}
                                    calcoloGiorniLavorati={calcoloGiorniLavorati}
                                    calcoloTotaliOre={calcoloTotaliOre}
                                    calcoloTotaliOreStrOTS={calcoloTotaliOreStrOTS}
                                    calcoloTotaliFlessibilita={calcoloTotaliFlessibilita}
                                    fromMinutiToOreString={fromMinutiToOreString}
                                    indennita={indennita}
                                    getFirstLetterOfDay={getFirstLetterOfDay}
                                    disponibilitaJSON={disponibilitaJSON}
                                    onChangeSetIndennita={onChangeSetIndennita}
                                    setIndennitaPersonalizzata1={setIndennitaPersonalizzata1}
                                    setIndennitaPersonalizzata2={setIndennitaPersonalizzata2}
                                    setIndennitaPersonalizzata3={setIndennitaPersonalizzata3}
                                    indennitaPersonalizzata1={indennitaPersonalizzata1}
                                    indennitaPersonalizzata2={indennitaPersonalizzata2}
                                    indennitaPersonalizzata3={indennitaPersonalizzata3}
                                    calcolaStraordinari48={calcolaStraordinari48}
                                />
                            }
                            <div className="row">
                                <div className="item_2">
                                    <div className="w-form">
                                        <form id="nuovo_punto_servizio" name="email-form-2" data-name="Email Form 2" method="get" aria-label="Email Form 2">
                                            <div className="div_wrap_100">
                                                <div className="div_wrap_75">
                                                    <div className="col_input">
                                                        <label htmlFor="name">Note</label>
                                                        <textarea type="text" className="input w-input altezza_text" maxLength="500" name="note" value={note} data-name="note" placeholder="Inserisci note" id="note" onChange={e => onChangeSetNote(e)} />
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="item_3 m_top_5">
                                    <div className="list_button only_button">
                                        <div id="w-node-_47761b2d-160d-03bc-cd09-8911d6401437-867f153d" className="div_item_button">
                                            <a id="w-node-_12c52e6d-210d-5526-7356-6c64cce43733-867f153d" onClick={() => (onClickSalvaIncarichiDipendenteModificati())} className={`button w-button`}>Salva PDF</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="div_wrap_40">
                <div className="div_container">
                    <div className="title_window">
                        <div className="title_window_p">Anteprima PDF</div>
                    </div>
                    <div className="dati_container anteprima_pdf">
                        {Prints()}
                    </div>
                </div>
            </div>
        </div>
    )
}

NuovoPDFIncarichi.protoTypes = {
    getIncarichiDipendente: PropTypes.func.isRequired,
    getDipendente: PropTypes.func.isRequired,
    salvaPDFIncarichiDipendente: PropTypes.func.isRequired,
    dipendente: PropTypes.object.isRequired,
    alert: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    dipendente: state.dipendente,
    alert: state.alert,
    auth: state.auth
});

export default connect(mapStateToProps, { getIncarichiDipendente, getDipendente, salvaPDFIncarichiDipendente })(NuovoPDFIncarichi);