import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { creaNuovaOrganizzazione } from '../../../actions/organizzazione';
const NuovaOrganizzazione = ({ openNuovaOrganizzazione, setOpenNuovaOrganizzazione, creaNuovaOrganizzazione, alert }) => {

    useEffect(() => {
        const size = alert.length;
        if(size>0){
            if(alert[size-1].alertType === "success"){
                setOpenNuovaOrganizzazione(false);
                setDatiOrganizzazione(organizzazioneInitialState);
            }
        }
    }, [alert]);

    const organizzazioneInitialState = {
        nome: '',
        indirizzo: ''
    }
    const [datiOrganizzazione, setDatiOrganizzazione] = useState(organizzazioneInitialState);
    const { nome, indirizzo } = datiOrganizzazione;


    const onChangeSetDatiOrganizzazione = e => setDatiOrganizzazione({ ...datiOrganizzazione, [e.target.name]: e.target.value });

    const onSubmitCreaOrganizzazione = async e => {
        e.preventDefault();
        creaNuovaOrganizzazione({ nome, indirizzo });
    };
    return (
        <Fragment>
        <div className={`modal fade ${openNuovaOrganizzazione ? 'show' : null}`} style={{display: `${openNuovaOrganizzazione ? 'block' : 'none'}`}}>
            <form className="kt-form"  onSubmit={e => onSubmitCreaOrganizzazione(e)}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="myModalLabel">Crea nuova organizzazione</h4>
                        </div>
                        <div className="modal-body">
                            <div className="form-group row">
                                <div className="col-lg-6 form-group">
                                    <label>Nome organizzazione:</label>
                                    <input type="text" name="nome" className="form-control" placeholder="Inserisci nome" onChange={e => onChangeSetDatiOrganizzazione(e)} required />
                                </div>
                                <div className="col-lg-6 form-group">
                                    <label className="">Indirizzo organizzazione:</label>
                                    <input type="text" name="indirizzo" className="form-control" placeholder="Inserisci indirizzo" onChange={e => onChangeSetDatiOrganizzazione(e)} required />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" onClick={() => setOpenNuovaOrganizzazione(false)} className="btn btn-default">Chiudi</button>
                            <button type="submit" className="btn btn-primary">Aggiungi organizzazione</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        </Fragment>
    )
}


NuovaOrganizzazione.protoTypes = {
    creaNuovaOrganizzazione: PropTypes.func.isRequired,
    alert: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    alert: state.alert
});

export default connect(mapStateToProps, { creaNuovaOrganizzazione })(NuovaOrganizzazione);



