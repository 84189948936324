import axios from 'axios';
import { GET_ESITI_INVIO_TURNI, INVIA_TURNI, INVIA_TURNI_DIPENDENTE_VIA_EMAIL, INVIO_TURNI_ERROR } from './types';
import { setAlert } from './alert';


export const getEsitiInvioTurni = ({dataInizio, dataFine}) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({
        dataInizio,
        dataFine
        }); 
    try{
        const res = await axios.post('/api/invioTurni/getEsitiInvioTurni', body, config);
        dispatch({
            type: GET_ESITI_INVIO_TURNI,
            payload: res.data
        });
    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        dispatch({
            type: INVIO_TURNI_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const inviaTurni = ({dataInizio, dataFine}) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({
        dataInizio,
        dataFine
        }); 
    try{
        const res = await axios.post('/api/invioTurni/inviaTurni', body, config);
        dispatch({
            type: INVIA_TURNI,
            payload: res.data
        });
        dispatch(setAlert('Procedura invio email iniziata.', 'success'));
    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        dispatch({
            type: INVIO_TURNI_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};


export const inviaTurniDipendenteViaEmail = ({email, turni, idDipendente}) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({
        email,
        turni,
        idDipendente
        }); 
    try{
        const res = await axios.post('/api/invioTurni/inviaTurniDipendenteViaEmail', body, config);
        dispatch({
            type: INVIA_TURNI_DIPENDENTE_VIA_EMAIL
        });
        dispatch(setAlert('Email inviata con successo!', 'success'));
    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        dispatch({
            type: INVIO_TURNI_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};
