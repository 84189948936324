import { GET_FILIALI, GET_FILIALE, CREA_FILIALE, MODIFICA_FILIALE, GET_INCARICHI_FILIALE, GET_FILIALE_ORGANIZZAZIONE, FILIALE_ERROR } from "../actions/types";

const initialState = {
    filiale: null,
    filiali: [],
    incarichiFiliale: [],
    numberFiliali: 0,
    error: {},
    loading: true
}

export default function(state = initialState, action){
    const { type, payload } = action;

    switch(type){
        case GET_FILIALE_ORGANIZZAZIONE:
        case GET_FILIALI:
            return {
                ...state,
                filiali: payload.filiali,
                numberFiliali: payload.numberFiliali,
                loading: false
            };
        case GET_FILIALE:
            return {
                ...state,
                filiale: payload,
                loading: false
            };
        case CREA_FILIALE:
            let arrayCreaFiliale = state.filiali;
            arrayCreaFiliale.push(payload);
            arrayCreaFiliale.sort((a,b) => (a.nome > b.nome) ? 1 : ((b.nome > a.nome) ? -1 : 0)); 
            return {
                ...state,
                filiali: arrayCreaFiliale,
                numberFiliali: arrayCreaFiliale.length,
                loading: false
            };
        case MODIFICA_FILIALE:
            const index = state.filiali.findIndex(x => x._id == payload._id );
            let arrayModificaFiliale = state.filiali;
            arrayModificaFiliale[index] = payload
            arrayModificaFiliale.sort((a,b) => (a.nome > b.nome) ? 1 : ((b.nome > a.nome) ? -1 : 0)); 
            return {
                ...state,
                filiali: arrayModificaFiliale,
                loading: false
            }
        case GET_INCARICHI_FILIALE:
            return {
                ...state,
                incarichiFiliale: payload,
                loading: false
            }
        case FILIALE_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            };
        default:
            return state;
    }
}