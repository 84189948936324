import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import Spinner from '../../layout/Spinner';
import {Helmet} from "react-helmet";
import NuovoPDFIncarichi from '../dipendenti/NuovoPDFIncarichi';
import PDFIncarichiGPG from '../dipendenti/PDFIncarichiGPG';
import PDFIncarichiOTS from '../dipendenti/PDFIncarichiOTS';
import InvioTuttiDipendentiPDF from '../dipendenti/InvioTuttiDipendentiPDF';
import { getDipendentiAmministrazione, cleanIncarichiPDFDipendenti } from '../../../actions/dipendente';
import { getFiliali } from '../../../actions/filiale';
const Amministrazione = ({ dipendente: { dipendenti, loading }, getDipendentiAmministrazione, cleanIncarichiPDFDipendenti, filiale: { filiali }, getFiliali, auth: {user} }) => {

    const [dataInizioInput, setDataInizioInput] = useState((setHours(setMinutes(startOfMonth(new Date()), 0), 0)));
    const [dataFineInput, setDataFineInput] = useState((setHours(setMinutes(endOfMonth(new Date()), 59), 23)));
    const [openIncarichiDipendente, setOpenIncarichiDipendente] = useState(false);
    const [page, setPage] = useState(0);
    const pagination = 10;
    const [dipendenteSelected, setDipendenteSelected] = useState({ _id: null, dipendente: null });
    const [testoFiltraDipendenti, setTestoFiltraDipendenti] = useState("");
    const [filtraFiliale, setFiltraFiliale] = useState("tutti");
    const [dipendentiList, setDipendentiList] = useState([]);
    const [openStampaTuttiDipendentiPDF, setOpenStampaTuttiDipendentiPDF] = useState(false);
    const [mostraDipendentiCessati, setMostraDipendentiCessati] = useState(false);
    const [mostraDipendentiGPG, setMostraDipendentiGPG] = useState(true);
    const [mostraDipendentiOTS, setMostraDipendentiOTS] = useState(true);
    const [filialiList, setFilialiList] = useState([]);


    useEffect(() => {
        getDipendentiAmministrazione(page, pagination, mostraDipendentiCessati);
    }, [getDipendentiAmministrazione]);

    useEffect(() => {
        if (user && user.role == "organizzazione") {
            getFiliali();
        }
    }, [user]);

    useEffect(() => {
        if (filiali.length > 0) {
            let array = [];
            filiali.map(filiale => {
                const index = user.vistaFiliali.findIndex(el => el._id == filiale._id);
                if(index >= 0){
                    array.push(filiale)
                }
            })
            setFilialiList(array)
        }
    }, [filiali]);


    useEffect(() => {
        let arrayDipendenti = [...dipendenti]
        if(!mostraDipendentiGPG){
            arrayDipendenti = filtraPerGPG(arrayDipendenti)
        }
        if(!mostraDipendentiOTS){
            arrayDipendenti = filtraPerOTS(arrayDipendenti)
        }
        if (testoFiltraDipendenti.length > 0) {
            arrayDipendenti = filtraPerTesto(arrayDipendenti, testoFiltraDipendenti)
        }      
        if(filtraFiliale != "tutti"){
            arrayDipendenti = filtraPerFiliale(arrayDipendenti, filtraFiliale)
        }else{
            arrayDipendenti = filtraPerFilialeTutti(arrayDipendenti)

        }      
        setDipendentiList(arrayDipendenti)
    }, [dipendenti]);


    const filtraPerFiliale = (dipendenti, filialeId) => {
        let array = dipendenti.filter(function (dipendente) {
            return dipendente.filiale._id == filialeId;
        })
        return array
    }

    const filtraPerFilialeTutti = (dipendenti) => {
        let array = dipendenti.filter(function (dipendente) {
            const index = user.vistaFiliali.findIndex(el => el._id == dipendente.filiale._id);
                if(index >= 0){
                    return true
                }else {
                    return false
                }
        })
        return array
    }

    const filtraPerOTS = (dipendenti) => {
        let array = dipendenti.filter(function (dipendente) {
            return dipendente.qualifica !== 'ots';
        })
        return array
    }

    const filtraPerGPG = (dipendenti) => {
        let array = dipendenti.filter(function (dipendente) {
            return dipendente.qualifica !== 'gpg';
        })
        return array
    }

    const filtraPerTesto = (dipendenti, testo) => {
        let array = dipendenti.filter(function (dipendente) {
            return dipendente.nome.toLowerCase().includes(testo.toLowerCase())
                || dipendente.cognome.toLowerCase().includes(testo.toLowerCase());
        })
        return array
    }

    const onClickOpenIncarichiDipendente = (idDipendente) => {
        if (idDipendente) {
            setOpenIncarichiDipendente(true)
            setOpenStampaTuttiDipendentiPDF(false)
        }
    }

    const onClickStampaTuttiDipendentiPDF = () => {
        cleanIncarichiPDFDipendenti()
        setOpenStampaTuttiDipendentiPDF(true)
        setOpenIncarichiDipendente(false)
    }


    const onChangeFiltraDipendenti = e => {
        setTestoFiltraDipendenti(e.target.value)
        let arrayDipendenti = [...dipendenti]
        console.log(arrayDipendenti)
        if(!mostraDipendentiGPG){
            arrayDipendenti = filtraPerGPG(arrayDipendenti)
        }
        if(!mostraDipendentiOTS){
            arrayDipendenti = filtraPerOTS(arrayDipendenti)
        }
        if (testoFiltraDipendenti.length > 0) {
            arrayDipendenti = filtraPerTesto(arrayDipendenti, e.target.value)
        } 
        if(filtraFiliale != "tutti"){
            arrayDipendenti = filtraPerFiliale(arrayDipendenti, filtraFiliale)
        }else{
            arrayDipendenti = filtraPerFilialeTutti(arrayDipendenti)

        }  
        setDipendentiList(arrayDipendenti)
    };

    const onChangeFiltraDipendentiPerFiliale = e => {
        setFiltraFiliale(e.target.value)
        let arrayDipendenti = [...dipendenti]
        if(!mostraDipendentiGPG){
            arrayDipendenti = filtraPerGPG(arrayDipendenti)
        }
        if(!mostraDipendentiOTS){
            arrayDipendenti = filtraPerOTS(arrayDipendenti)
        }
        if (testoFiltraDipendenti.length > 0) {
            arrayDipendenti = filtraPerTesto(arrayDipendenti, testoFiltraDipendenti)
        } 
        if(e.target.value != "tutti"){
            arrayDipendenti = filtraPerFiliale(arrayDipendenti, e.target.value)
        }else{
            arrayDipendenti = filtraPerFilialeTutti(arrayDipendenti)

        }  
        setDipendentiList(arrayDipendenti)
    };

    const onChangeSetCheckboxDipendentiCessati = () => {
        getDipendentiAmministrazione(page, pagination, !mostraDipendentiCessati);
        setMostraDipendentiCessati(!mostraDipendentiCessati)
    }

    const onChangeSetCheckboxDipendentiGPG = () => {
        let arrayDipendenti = [...dipendenti]
        if(!!mostraDipendentiGPG){
            arrayDipendenti = filtraPerGPG(arrayDipendenti)
        }
        if(!mostraDipendentiOTS){
            arrayDipendenti = filtraPerOTS(arrayDipendenti)
        }
        if (testoFiltraDipendenti.length > 0) {
            arrayDipendenti = filtraPerTesto(arrayDipendenti, testoFiltraDipendenti)
        } 
        setDipendentiList(arrayDipendenti)
        setMostraDipendentiGPG(!mostraDipendentiGPG)
    }

    const onChangeSetCheckboxDipendentiOTS = () => {
        let arrayDipendenti = [...dipendenti]
        if(!mostraDipendentiGPG){
            arrayDipendenti = filtraPerGPG(arrayDipendenti)
        }
        if(!!mostraDipendentiOTS){
            arrayDipendenti = filtraPerOTS(arrayDipendenti)
        }
        if (testoFiltraDipendenti.length > 0) {
            arrayDipendenti = filtraPerTesto(arrayDipendenti, testoFiltraDipendenti)
        } 
        setDipendentiList(arrayDipendenti)
        setMostraDipendentiOTS(!mostraDipendentiOTS)
    }

    return (loading ? <Spinner /> :
        <div className="body wf-section">
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`Amministrazione - Gestionale Vigili dell'ordine`}</title>
            </Helmet>
            <div className="div_wrap">
                <div className="div_wrap_100">
                    <div className="div_wrap_50">
                        <div className="div_container">
                            <div className="title_window">
                                <div className="title_window_p">Dipendenti</div>
                            </div>
                            <div className="dati_container">
                                <div className="fun_assegnazione_incarichi">
                                    <div className="row">
                                        <div className="item_1 filtro_wrap">
                                            <div id="w-node-_7b40e076-1929-a489-3ae4-e7e497d010f9-867f153d" className="div_item_1">
                                                <div id="w-node-_7b40e076-1929-a489-3ae4-e7e497d010fa-867f153d" className="label_text">Filtra dipendenti</div>
                                                <div data-hover="false" data-delay="0" className="w-dropdown">
                                                    <input type="text" className="w-input select" maxLength="256" name="filtraDipendenti" data-name="filtraDipendenti" value={testoFiltraDipendenti} placeholder="Inserisci dipendente" id="filtraDipendenti" onChange={e => onChangeFiltraDipendenti(e)} />
                                                </div>
                                            </div>
                                            <div id="w-node-_714e71a9-0550-fe0e-26e9-8b45ac42ab89-867f153d" className="div_item_1">
                                                <div className="w-form">
                                                    <form id="email-form" name="email-form" data-name="Email Form" method="get">
                                                        <label className="w-checkbox checkbox-field"><span className="label_text w-form-label" htmlFor="checkbox-2"></span></label>
                                                        <a id="w-node-_714e71a9-0550-fe0e-26e9-8b45ac42ab90-867f153d" onClick={() => { onClickStampaTuttiDipendentiPDF() }} className="button w-button">Stampa tutti</a>
                                                    </form>
                                                </div>
                                            </div> 
                                            <div id="w-node-_284812ee-7924-9f38-86bb-8d2cc43dff3c-867f153d" className="div_item_1">
                                            <div id="w-node-_7b40e076-1929-a489-3ae4-e7e497d010fa-867f153d" className="label_text">Filiale</div>
                                            <div data-hover="false" data-delay="0" className="select w-dropdown">
                                                        <select className="form-control select" name="idCliente" onChange={e => onChangeFiltraDipendentiPerFiliale(e)} required>
                                                        <option selected={'tutti' == filtraFiliale} value={'tutti'} >Tutti</option>
                                                            {filialiList.map((elementoFiliale, i) => (
                                                                <option key={i} value={elementoFiliale._id} selected={elementoFiliale._id == filtraFiliale} >{elementoFiliale.nome}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                            </div>
                                            <div></div>
                                            <div>
                                                <label className="w-checkbox checkbox-field">
                                                    <input type="checkbox" id="checkbox" name="checkbox" data-name="Checkbox" className="w-checkbox-input" checked={mostraDipendentiCessati} onChange={e => onChangeSetCheckboxDipendentiCessati(e)}/>
                                                    <span className="label_text w-form-label" htmlFor="checkbox">cessati</span>
                                                </label>
                                                <label className="w-checkbox checkbox-field">
                                                    <input type="checkbox" id="checkbox" name="checkbox" data-name="Checkbox" className="w-checkbox-input" checked={mostraDipendentiGPG} onChange={e => onChangeSetCheckboxDipendentiGPG(e)}/>
                                                    <span className="label_text w-form-label" htmlFor="checkbox">gpg</span>
                                                </label>
                                                <label className="w-checkbox checkbox-field">
                                                    <input type="checkbox" id="checkbox" name="checkbox" data-name="Checkbox" className="w-checkbox-input" checked={mostraDipendentiOTS} onChange={e => onChangeSetCheckboxDipendentiOTS(e)}/>
                                                    <span className="label_text w-form-label" htmlFor="checkbox">ots</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="item_2">
                                            <div className="intestazione_table">
                                                <div className="w-layout-grid div_column_dipendenti intestazione">
                                                    <div className="div_item_column">
                                                        <div className="item_table">Qualifica</div>
                                                    </div>
                                                    <div className="div_item_column">
                                                        <div className="item_table">Cognome</div>
                                                    </div>
                                                    <div className="div_item_column">
                                                        <div className="item_table">Nome</div>
                                                    </div>
                                                    <div className="div_item_column">
                                                        <div className="item_table">Codice Fiscale</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="body_table">
                                                {dipendentiList.map((dipendente, i) => (
                                                    <div key={i} className={`w-layout-grid div_column_dipendenti cursor ${dipendenteSelected._id == dipendente._id ? 'dipendente_selected' : ''}`} onClick={() => setDipendenteSelected({ _id: dipendente._id, dipendente: dipendente })}>
                                                        <div className="div_item_column">
                                                            <div className="item_table">{dipendente.qualifica}</div>
                                                        </div>
                                                        <div className="div_item_column">
                                                            <div className="item_table">{dipendente.cognome}</div>
                                                        </div>
                                                        <div className="div_item_column">
                                                            <div className="item_table">{dipendente.nome}</div>
                                                        </div>
                                                        <div className="div_item_column">
                                                            <div className="item_table">{dipendente.codicefiscale}</div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="item_3 m_top_5">
                                            <div className="list_button only_button">
                                                <div id="w-node-_47761b2d-160d-03bc-cd09-8911d6401437-867f153d" className="div_item_button">
                                                    <a id="w-node-_12c52e6d-210d-5526-7356-6c64cce43733-867f153d" onClick={() => (dipendenteSelected._id ? onClickOpenIncarichiDipendente(dipendenteSelected._id) : null)} className={`button w-button ${dipendenteSelected._id ? "" : "button_disabled"}`}>PDF incarichi dipendente</a>
                                                </div>
                                                <div id="w-node-bf561da2-25a7-b3ef-c44c-a819295560e3-867f153d" className="div_item_empty"></div>
                                                <div id="w-node-bf561da2-25a7-b3ef-c44c-a819295560e3-867f153d" className="div_item_empty"></div>
                                                <div id="w-node-bf561da2-25a7-b3ef-c44c-a819295560e3-867f153d" className="div_item_empty"></div>
                                                <div id="w-node-bf561da2-25a7-b3ef-c44c-a819295560e3-867f153d" className="div_item_empty"></div>
                                                <div id="w-node-bf561da2-25a7-b3ef-c44c-a819295560e3-867f153d" className="div_item_empty"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {openStampaTuttiDipendentiPDF ? 
                        <InvioTuttiDipendentiPDF 
                            setOpenStampaTuttiDipendentiPDF = {setOpenStampaTuttiDipendentiPDF}
                        />
                    :
                        ''
                    }
                    {openIncarichiDipendente ? 
                    dipendenteSelected.dipendente.qualifica === 'gpg' ?
                        <PDFIncarichiGPG 
                            dataInizioInput={dataInizioInput}
                            dataFineInput={dataFineInput}
                            dipendenteData={dipendenteSelected.dipendente}
                            idDipendente={dipendenteSelected._id}
                        /> 
                        :
                        <PDFIncarichiOTS 
                            dataInizioInput={dataInizioInput}
                            dataFineInput={dataFineInput}
                            dipendenteData={dipendenteSelected.dipendente}
                            idDipendente={dipendenteSelected._id}
                        /> 
                    :
                        ''
                    }
                </div>
                    {openIncarichiDipendente ?
                        <NuovoPDFIncarichi
                            idDipendente={dipendenteSelected._id}
                            dipendenteData={dipendenteSelected.dipendente}
                            setOpenIncarichiDipendente={setOpenIncarichiDipendente}
                            dataInizioInput={dataInizioInput}
                            setDataInizioInput={setDataInizioInput}
                            dataFineInput={dataFineInput}
                            setDataFineInput={setDataFineInput}
                        />
                    :
                        ''
                    }
            </div>
        </div>
    )
}

Amministrazione.protoTypes = {
    getDipendentiAmministrazione: PropTypes.func.isRequired,
    getFiliali: PropTypes.func.isRequired,
    dipendente: PropTypes.object.isRequired,
    filiale: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    cleanIncarichiPDFDipendenti: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    dipendente: state.dipendente,
    filiale: state.filiale,
    auth: state.auth
});

export default connect(mapStateToProps, { getDipendentiAmministrazione, cleanIncarichiPDFDipendenti, getFiliali })(Amministrazione);