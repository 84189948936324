import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import DatePicker from 'react-date-picker';
import add from "date-fns/add";
import Spinner from '../../layout/Spinner';
import { storage } from "../../../firebase"
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { getDipendente, modificaDipendente } from '../../../actions/dipendente';
import { creaTipologiaCorso, getTipologieCorsi } from '../../../actions/documento';
import mansioniJSON from "../../../utils/mansioni.json";
import DocumentoDipendente from "./DocumentoDipendente"
import Documento from "./Documento"
import DocumentoVisitaMedica from "./DocumentoVisitaMedica"
import DocumentoCorso from "./DocumentoCorso"
import DocumentoTiroASegno from "./DocumentoTiroASegno"
import { v4 as uuid } from 'uuid';




const ModificaCliente = ({ auth: { user }, dipendente: { dipendente, loading }, alert, documento: { tipologieCorsi, tipologieDocumenti, tipologieDocumentiDipendenti }, getDipendente, modificaDipendente, idDipendente, setOpenModificaDipendente, creaTipologiaCorso, getTipologieCorsi }) => {

    const [utente, setUtente] = useState({
		username: '',
		nome: '',
		cognome: '',
		ruolo: '',
		vista: '',
		nomeFiliale: '',
		nomeOrganizzazione: '',
		idFiliale: ''
	});

    useEffect(() => {
		setUtente({
			username: loading || !user ? '' : user.username,
			nome: loading || !user ? '' : user.nome,
			cognome: loading || !user ? '' : user.cognome,
			ruolo: loading || !user ? '' : user.role,
			vista: loading || !user ? '' : user.vista,
			nomeFiliale: loading || !user || !user.filiale ? '' : user.filiale.nome,
			nomeOrganizzazione: loading || !user || !user.organizzazione ? '' : user.organizzazione.nome,
			idOrganizzazione: loading || !user || !user.organizzazione ? '' : user.organizzazione._id
		})

        setTabSelected(user && user.vista == "scadenze" ? "vestiario" : "anagrafica")
	}, [user, loading]);

	const { vista } = utente;


    useEffect(() => {
        const size = alert.length;
        if (size > 0) {
            if (alert[size - 1].field === "crea_nuova_tipologia_documento") {
                setNuovaTipologiaCorso({ nome: "", scadenza: "" })
                setPopUpAggiungiTipologiaCorso(false)
            }
        }
    }, [alert]);

    useEffect(() => {
        getTipologieCorsi()
        return () => {
            setDatiDipendente(dipendenteInitialState)
        }
    }, []);

    useEffect(() => {
        getDipendente(idDipendente);
    }, [getDipendente, idDipendente]);

    const dipendenteInitialState = {
        _id: "",
        idZoneRes: "",
        idZoneAss: "",
        idStato: "",
        idTitoliStu: "",
        idInvalidita: "",
        idAziendeLav: "",
        idQualifiche: "",
        idMansioni: "",
        idTipiDisponib: "",
        matricola: "",
        codice: "",
        nome: "",
        cognome: "",
        luogoRes: "",
        provRes: "",
        cap: "",
        indirizzo: "",
        telefono1: "",
        telefono2: "",
        sesso: "",
        luogoNasc: "",
        provNasc: "",
        dataNasc: null,
        codicefiscale: "",
        nPortoArmi: "",
        rinPortArmi: "",
        scadPortArmi: "",
        nLicenza: "",
        rinLicenza: "",
        scadLicenza: "",
        nDecreto: "",
        rinDecreto: "",
        scadDecreto: "",
        nLibretto: "",
        rinLibretto: "",
        scadLibretto: "",
        dataInizioAss: null,
        dataFineAss: null,
        tipoPatente: "",
        email: "",
        dispStraord: "",
        cadenza: "",
        dataInizioCadenza: null,
        ferSup: "",
        ferUsu: "",
        ferStr: "",
        fesOrd: "",
        fesSup: "",
        fesUsu: "",
        fesStr: "",
        ripOrd: "",
        ripSup: "",
        ripUsu: "",
        ripStr: "",
        percBancaOre: 0,
        dipendenza: "",
        bancaOreIniziale: 0,
        bancaOreProgressivo: 0,
        ferieIniziale: 0,
        permessiIniziale: 0,
        qualifica: "",
        note: "",
        ferOrd: "",
        ferStr: "",
        prestabile: false,
        eccedenza: false,
        sussidiari: false,
        nomeSussidiari: "",
        tagliaPantaloni: "",
        tagliaCamicia: "",
        tagliaMaglione: "",
        tagliaGiacca: "",
        tagliaScarpa: "",
        documenti: [],
        documentiTiroASegno: [],
        partTime: false,
        somministrato: false
    }

    const [datiDipendente, setDatiDipendente] = useState(dipendenteInitialState);
    const [popUpAggiungiTipologiaCorso, setPopUpAggiungiTipologiaCorso] = useState(false);
    const [nuovaTipologiaCorso, setNuovaTipologiaCorso] = useState({
        nome: "",
        scadenza: ""
    });
    const [corsiDipendente, setCorsiDipendente] = useState([]);
    const [visitaMedicaDipendente, setVisitaMedicaDipendente] = useState([]);
    const [documentiDipendente, setDocumentiDipendente] = useState([]);
    const [documentiDipendenteDipendente, setDocumentiDipendenteDipendente] = useState([]);
    const [documentiTiroASegnoDipendente, setDocumentiTiroASegnoDipendente] = useState([]);

    

    useEffect(() => {
        setDatiDipendente({
            _id: loading || !dipendente ? '' : dipendente._id,
            matricola: loading || !dipendente ? '' : dipendente.matricola,
            nome: loading || !dipendente ? '' : dipendente.nome,
            cognome: loading || !dipendente ? '' : dipendente.cognome,
            luogoRes: loading || !dipendente ? '' : dipendente.luogoRes,
            provRes: loading || !dipendente ? '' : dipendente.provRes,
            cap: loading || !dipendente ? '' : dipendente.cap,
            indirizzo: loading || !dipendente ? '' : dipendente.indirizzo,
            telefono1: loading || !dipendente ? '' : dipendente.telefono1,
            telefono2: loading || !dipendente ? '' : dipendente.telefono2,
            luogoNasc: loading || !dipendente ? '' : dipendente.luogoNasc,
            provNasc: loading || !dipendente ? '' : dipendente.provNasc,
            dataNasc: dipendente && dipendente.dataNasc ? new Date(dipendente.dataNasc) : null,
            codicefiscale: loading || !dipendente ? '' : dipendente.codicefiscale,
            email: loading || !dipendente ? '' : dipendente.email,
            codicefiscale: loading || !dipendente ? '' : dipendente.codicefiscale,
            cadenza: loading || !dipendente ? '' : dipendente.cadenza,
            dataInizioCadenza: dipendente && dipendente.dataInizioCadenza ? new Date(dipendente.dataInizioCadenza) : null,
            dataInizioAss: dipendente && dipendente.dataInizioAss ? new Date(dipendente.dataInizioAss) : null,
            dataFineAss: dipendente && dipendente.dataFineAss ? new Date(dipendente.dataFineAss) : null,
            dipendenza: loading || !dipendente ? '' : dipendente.dipendenza,
            percBancaOre: loading || !dipendente ? 0 : dipendente.percBancaOre,
            bancaOreIniziale: loading || !dipendente ? 0 : dipendente.bancaOreIniziale,
            bancaOreProgressivo: loading || !dipendente ? 0 : dipendente.bancaOreProgressivo,
            ferieIniziale: loading || !dipendente ? 0 : dipendente.ferieIniziale,
            permessiIniziale: loading || !dipendente ? 0 : dipendente.permessiIniziale,
            qualifica: loading || !dipendente ? 0 : dipendente.qualifica,
            note: loading || !dipendente ? "" : dipendente.note,
            ferOrd: loading || !dipendente ? "" : dipendente.ferOrd.replace(".", ":"),
            ferStr: loading || !dipendente ? "" : dipendente.ferStr.replace(".", ":"),
            prestabile: loading || !dipendente ? false : dipendente.prestabile,
            eccedenza: loading || !dipendente ? false : dipendente.eccedenza,
            idMansioni: loading || !dipendente ? false : dipendente.idMansioni,
            sussidiari: loading || !dipendente ? false : dipendente.sussidiari,
            nomeSussidiari: loading || !dipendente ? false : dipendente.nomeSussidiari,
            tagliaPantaloni: loading || !dipendente || !dipendente.tagliaPantaloni ? "" : dipendente.tagliaPantaloni,
            tagliaCamicia: loading || !dipendente || !dipendente.tagliaCamicia ? "" : dipendente.tagliaCamicia,
            tagliaMaglione: loading || !dipendente || !dipendente.tagliaMaglione ? "" : dipendente.tagliaMaglione,
            tagliaGiacca: loading || !dipendente || !dipendente.tagliaGiacca ? "" : dipendente.tagliaGiacca,
            tagliaScarpa: loading || !dipendente || !dipendente.tagliaScarpa ? "" : dipendente.tagliaScarpa,
            documenti: loading || !dipendente ? [] : dipendente.documenti,
            documentiTiroASegno: loading || !dipendente ? [] : dipendente.documentiTiroASegno,
            partTime: loading || !dipendente ? false : dipendente.partTime,
            somministrato: loading || !dipendente ? false : dipendente.somministrato
        });
        console.log(dipendente)
    }, [dipendente, loading]);


    useEffect(() => {
        if(documentiTiroASegnoDipendente.length > 0 && documentiTiroASegnoDipendente[documentiTiroASegnoDipendente.length - 1].eseguito3){
            let array;
            array = [...documentiTiroASegnoDipendente]
            let nuovaDataTiroASegno = add(new Date(array[array.length - 1].dataScadenza3), { months: 4 })
            array.push({ id: uuid(), nome: "Tiro a segno", tipologia: "tiro-a-segno", dataScadenza1: nuovaDataTiroASegno, eseguito1: false, dataScadenza2: null, eseguito2: false, dataScadenza3: null, eseguito3: false, archiviato: false })
            setDocumentiTiroASegnoDipendente(array)
        }
    }, [documentiTiroASegnoDipendente]);

    

    const {
        _id,
        idZoneRes,
        idZoneAss,
        idStato,
        idTitoliStu,
        idInvalidita,
        idAziendeLav,
        idQualifiche,
        idMansioni,
        idTipiDisponib,
        matricola,
        codice,
        nome,
        cognome,
        luogoRes,
        provRes,
        cap,
        indirizzo,
        telefono1,
        telefono2,
        sesso,
        luogoNasc,
        provNasc,
        dataNasc,
        codicefiscale,
        nPortoArmi,
        rinPortArmi,
        scadPortArmi,
        nLicenza,
        rinLicenza,
        scadLicenza,
        nDecreto,
        rinDecreto,
        scadDecreto,
        nLibretto,
        rinLibretto,
        scadLibretto,
        dataInizioAss,
        dataFineAss,
        tipoPatente,
        email,
        dispStraord,
        cadenza,
        dataInizioCadenza,
        ferOrd,
        ferSup,
        ferUsu,
        ferStr,
        fesOrd,
        fesSup,
        fesUsu,
        fesStr,
        ripOrd,
        ripSup,
        ripUsu,
        ripStr,
        dipendenza,
        percBancaOre,
        bancaOreIniziale,
        bancaOreProgressivo,
        ferieIniziale,
        permessiIniziale,
        qualifica,
        note,
        prestabile,
        eccedenza,
        sussidiari,
        nomeSussidiari,
        tagliaPantaloni,
        tagliaCamicia,
        tagliaMaglione,
        tagliaGiacca,
        tagliaScarpa,
        documenti,
        documentiTiroASegno,
        partTime,
        somministrato
    } = datiDipendente;

    
    useEffect(() => {
        let arrayCorsi = []
        let arrayDocumenti = []
        let arrayDocumentiDipendente = []
        let arrayVisitaMedica = []
        let arrayDocumentiTiroASegno = []
        documenti.map(el => {
            if (el.tipologia == "corso") {
                arrayCorsi.push({ id: uuid(), nome: el.nome, dataRilascio: el.dataRilascio, tipologia: el.tipologia, url: el.url, scadenza: el.scadenza, dataScadenza: new Date(el.dataScadenza) })
            }
            if (el.tipologia == "documento") {
                arrayDocumenti.push({ id: uuid(), nome: el.nome, dataRilascio: el.dataRilascio, tipologia: el.tipologia, url: el.url, scadenza: el.scadenza, numero: el.numero, dataScadenza: new Date(el.dataScadenza), bloccaAvviso: el.bloccaAvviso })
            }
            if (el.tipologia == "documento-dipendente") {
                arrayDocumentiDipendente.push({ id: uuid(), nome: el.nome, tipologia: el.tipologia, url: el.url })
            }
            if (el.tipologia == "visita-medica") {
                arrayVisitaMedica.push({ id: uuid(), nome: el.nome, tipologia: el.tipologia, url: el.url, scadenza: el.scadenza, dataRilascio: new Date(el.dataRilascio), dataScadenza: new Date(el.dataScadenza), archiviato: el.archiviato })
            }
        })
        documentiTiroASegno.map(el => {
            arrayDocumentiTiroASegno.push({ id: uuid(), nome: el.nome, tipologia: el.tipologia, dataScadenza1: el.dataScadenza1 ? new Date(el.dataScadenza1) : null, eseguito1: el.eseguito1, dataScadenza2: el.dataScadenza2 ? new Date(el.dataScadenza2) : null, eseguito2: el.eseguito2, dataScadenza3: el.dataScadenza3 ? new Date(el.dataScadenza3) : null, eseguito3: el.eseguito3, archiviato: el.archiviato, bloccaAvviso: el.bloccaAvviso })

        })
        setCorsiDipendente(arrayCorsi)
        setDocumentiDipendente(arrayDocumenti)
        setDocumentiDipendenteDipendente(arrayDocumentiDipendente)
        setVisitaMedicaDipendente(arrayVisitaMedica)
        setDocumentiTiroASegnoDipendente(arrayDocumentiTiroASegno)
    }, [documenti]);

    const [tabSelected, setTabSelected] = useState("");

    function capitalize(str) {
        const lowerCaseString = str.toLowerCase(), // convert string to lower case  
              firstLetter = str.charAt(0).toUpperCase(), // upper case the first character
              strWithoutFirstChar = lowerCaseString.slice(1); // remove first character from lower case string 
      
        return firstLetter + strWithoutFirstChar; 
      
      }

    const onChangeSetDatiDipendente = e => {
        if(e.target.name == "nome" || e.target.name == "cognome"){
            setDatiDipendente({ ...datiDipendente, [e.target.name]: capitalize(e.target.value) });
        }else{
            setDatiDipendente({ ...datiDipendente, [e.target.name]: e.target.value });
        }
    }

    const onChangeSetCheckbox = e => {
        setDatiDipendente({ ...datiDipendente, [e.target.name]: e.target.value == 'true' ? false : true });
    }


    const onChangeCadenza = (i, value) => {
        let strCadenza = cadenza;
        if (value.length == 0) {
            strCadenza = strCadenza.slice(0, -1)
        } else {
            if (value.length > 1) {
                let char = value.charAt(value.length - 1);
                strCadenza = strCadenza.concat(char);
            } else {
                strCadenza = strCadenza.concat(value);
            }
        }
        setDatiDipendente({ ...datiDipendente, 'cadenza': strCadenza.toUpperCase() });
    }


    const onSubmitModificaDipendente = async e => {
        e.preventDefault();
        let arrayDocumenti = [...corsiDipendente, ...documentiDipendente, ...documentiDipendenteDipendente, ...visitaMedicaDipendente]
        console.log(documentiDipendente)
        modificaDipendente({
            idDipendente,
            nome,
            cognome,
            codicefiscale,
            matricola,
            luogoRes,
            provRes,
            cap,
            indirizzo,
            telefono1,
            telefono2,
            email,
            luogoNasc,
            provNasc,
            dataNasc,
            cadenza: cadenza.replace(/ /g, '').slice(0, 14),
            dataInizioCadenza,
            dataInizioAss,
            dataFineAss,
            dipendenza,
            percBancaOre,
            bancaOreIniziale,
            ferieIniziale,
            permessiIniziale,
            qualifica,
            note,
            ferOrd,
            ferStr,
            prestabile,
            eccedenza,
            idMansioni,
            sussidiari,
            nomeSussidiari,
            tagliaPantaloni,
            tagliaCamicia,
            tagliaMaglione,
            tagliaGiacca,
            tagliaScarpa,
            documenti: arrayDocumenti,
            documentiTiroASegno: documentiTiroASegnoDipendente,
            partTime,
            somministrato
        });
    };

    const onChangeUploadFile = (e, id, tipologia) => {
        if (e.target.files[0]) {
            const file = e.target.files[0]
            if (!file) return;
            const storageRef = ref(storage, `${user.organizzazione.nome}-${user.organizzazione._id}/${user.filiale.nome}-${user.filiale._id}/dipendenti/${cognome}-${nome}-${_id}/${id}-${file.name}`);
            const uploadTask = uploadBytesResumable(storageRef, file);

            uploadTask.on("state_changed",
                (snapshot) => {
                    const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                    console.log(progress);
                },
                (error) => {
                    console.log(error)
                },
                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        let array = [];
                        if (tipologia == "corso") {
                            array = [...corsiDipendente]
                        }
                        if (tipologia == "documento") {
                            array = [...documentiDipendente]
                        }
                        if (tipologia == "documento-dipendente") {
                            array = [...documentiDipendenteDipendente]
                        }
                        if (tipologia == "documento-visita-medica") {
                            array = [...visitaMedicaDipendente]
                        }
                        let index = array.findIndex(el => { return el.id == id })
                        if (index > -1) {
                            array[index].url = downloadURL
                        } else {
                            array.push({ nome: "", url: downloadURL, tipologia: tipologia })
                        }
                        if (tipologia == "corso") {
                            setCorsiDipendente(array)
                        }
                        if (tipologia == "documento") {
                            setDocumentiDipendente(array)
                        }
                        if (tipologia == "documento-dipendente") {
                            setDocumentiDipendenteDipendente(array)
                        }
                        if (tipologia == "documento-visita-medica") {
                            setVisitaMedicaDipendente(array)
                        }
                    });
                }
            );
        }
    }



    const existDocumento = (nome) => {
        if (documenti.length > 0) {
            let index = documenti.findIndex(el => { return el.nome == nome })
            if (index > -1) {
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    }


    const getIndexDocumento = (nome) => {
        let index = documenti.findIndex(el => { return el.nome == nome })
        if (index > -1) {
            return index
        } else {
            return -1
        }
    }

    const pulisciFileDocumento = (id) => {
        let array = [...documentiDipendente];
        let index = array.findIndex(el => { return el.id == id })
        if (index > -1) {
            array[index].url = ""
            setDocumentiDipendente(array);
        }
    }

    const pulisciFileDocumentoDipendente = (id) => {
        let array = [...documentiDipendenteDipendente];
        let index = array.findIndex(el => { return el.id == id })
        if (index > -1) {
            array[index].url = ""
            setDocumentiDipendenteDipendente(array);
        }
    }

    const pulisciFileVisitaMedica = (id) => {
        let array = [...visitaMedicaDipendente];
        let index = array.findIndex(el => { return el.id == id })
        if (index > -1) {
            array[index].url = ""
            setVisitaMedicaDipendente(array);
        }
    }

    const pulisciFileCorso = (id) => {
        let array = [...corsiDipendente];
        let index = array.findIndex(el => { return el.id == id })
        if (index > -1) {
            array[index].url = ""
            setCorsiDipendente(array);
        }
    }


    const eliminaCorso = (id) => {
        let array = [...corsiDipendente];
        let index = array.findIndex(el => { return el.id == id })
        if (index > -1) {
            array.splice(index, 1)
            setCorsiDipendente(array);
        }
    }

    const eliminaDocumento = (id) => {
        let array = [...documentiDipendente];
        let index = array.findIndex(el => { return el.id == id })
        if (index > -1) {
            array.splice(index, 1)
            setDocumentiDipendente(array);
        }
    }

    const eliminaDocumentoTiroASegno = (id) => {
        let array = [...documentiTiroASegnoDipendente];
        let index = array.findIndex(el => { return el.id == id })
        if (index > -1) {
            array.splice(index, 1)
            setDocumentiTiroASegnoDipendente(array);
        }
    }
    

    const eliminaDocumentoDipendente = (id) => {
        let array = [...documentiDipendenteDipendente];
        let index = array.findIndex(el => { return el.id == id })
        if (index > -1) {
            array.splice(index, 1)
            setDocumentiDipendenteDipendente(array);
        }
    }

    const eliminaVisitaMedica = (id) => {
        let array = [...visitaMedicaDipendente];
        let index = array.findIndex(el => { return el.id == id })
        if (index > -1) {
            array.splice(index, 1)
            setVisitaMedicaDipendente(array);
        }
    }




    const onChangeDataDocumento = (nomeDocumento, campo, e, i, tipologia) => {
        let arrayDocumenti = documenti;
        if (i > -1) {
            arrayDocumenti[i][campo] = e.target.value
            arrayDocumenti[i].tipologia = tipologia
        } else {
            arrayDocumenti.push({ nome: nomeDocumento, [campo]: e.target.value, tipologia: tipologia })
        }
        setDatiDipendente({ ...datiDipendente, documenti: arrayDocumenti });
    }

    const onChangeTipologiaCorso = (index, id) => {
        let array = [...corsiDipendente];
        let i = array.findIndex(el => { return el.id == id })
        if (i > -1) {
            array[i].nome = tipologieCorsi[index].nome
            array[i].scadenza = tipologieCorsi[index].scadenzaDefault
            array[i].tipologia = "corso"
            setCorsiDipendente(array)
        }
    }

    const onChangeTipologiaDocumentoDipendente = (nome, id) => {
        let array = [...documentiDipendenteDipendente];
        let i = array.findIndex(el => { return el.id == id })
        if (i > -1) {
            array[i].nome = nome
            setDocumentiDipendenteDipendente(array)
        }
    }

    const onChangeTipologiaDocumento = (i, id) => {
        let array = [...documentiDipendente];
        let index = array.findIndex(el => { return el.id == id })
        if (index > -1) {
            array[index].nome = tipologieDocumenti[i].nome
            array[index].scadenza = tipologieDocumenti[i].scadenzaDefault
            setDocumentiDipendente(array)
        }
    }

    const onChangeNumeroDocumento = (e, id) => {
        let array = [...documentiDipendente];
        let i = array.findIndex(el => { return el.id == id })
        if (i > -1) {
            array[i].numero = e.target.value
            setDocumentiDipendente(array)
        }
    }



    const onChangeSetDataRilascioDocumento = (value, id) => {
        let array = [...documentiDipendente];
        let i = array.findIndex(el => { return el.id == id })
        let data;
        if (i > -1) {
            array[i].dataRilascio = new Date(value)
            data = add(new Date(array[i].dataRilascio), { months: array[i].scadenza })
            array[i].dataScadenza = new Date(data)
            setDocumentiDipendente(array);
        }
    }

    const onChangeSetDataRilascioVisitaMedica = (value, id) => {
        let array = [...visitaMedicaDipendente];
        let i = array.findIndex(el => { return el.id == id })
        let data;
        if (i > -1) {
            array[i].dataRilascio = new Date(value)
            data = add(new Date(array[i].dataRilascio), { months: array[i].scadenza })
            array[i].dataScadenza = new Date(data)
            setVisitaMedicaDipendente(array);
        }
    }


    const onChangeSetDataRilascioCorso = (value, id,) => {
        let array = [...corsiDipendente];
        let data;
        let index = array.findIndex(el => { return el.id == id })
        if (index > -1) {
            array[index].dataRilascio = new Date(value)
            data = add(new Date(array[index].dataRilascio), { months: array[index].scadenza })
            array[index].dataScadenza = new Date(data)
        }
        setCorsiDipendente(array);
    }

    const onChangeSetScadenzaDocumento = (value, id) => {
        let array = [...documentiDipendente];
        let i = array.findIndex(el => { return el.id == id })
        if (i > -1) {
            array[i].scadenza = value
            let data;
            if (array[i].dataRilascio) {
                data = add(new Date(array[i].dataRilascio), { months: parseInt(value) })
            } else {
                data = add(new Date(), { months: parseInt(value) })
            }
            array[i].dataScadenza = new Date(data)
            setDocumentiDipendente(array)
        }
    }

    const onChangeSetCheckboxDocumenti = (value, id) => {
        let array = [...documentiDipendente];
        let i = array.findIndex(el => { return el.id == id })
        if (i > -1) {
            array[i].bloccaAvviso = value == 'true' ? false : true 
            setDocumentiDipendente(array)
        }
    }

    const onChangeSetCheckboxTiroASegno = (value, id) => {
        let array = [...documentiTiroASegnoDipendente];
        let i = array.findIndex(el => { return el.id == id })
        if (i > -1) {
            array[i].bloccaAvviso = value == 'true' ? false : true 
            setDocumentiDipendente(array)
        }
    }
    

    const onChangeSetTiroASegno = (campo, value, id) => {
        let array = [...documentiTiroASegnoDipendente];
        let i = array.findIndex(el => { return el.id == id })
        if (i > -1) {
            if(campo == 'eseguito1' || campo == 'eseguito2' || campo == 'eseguito3'){
                array[i][campo] = value == 'true' ? false : true 
                if(campo == 'eseguito3'){
                    let nuovaDataTiroASegno = add(new Date(array[i].dataScadenza3), { months: 4 })
                    array.push({ id: uuid(), nome: "Tiro a segno", tipologia: "tiro-a-segno", dataScadenza1: nuovaDataTiroASegno, eseguito1: false, dataScadenza2: null, eseguito2: false, dataScadenza3: null, eseguito3: false, archiviato: false })
                    console.log(array)

                }
            }else if(campo == 'dataScadenza1' || campo == 'dataScadenza2' || campo == 'dataScadenza3'){
                array[i][campo] = new Date(value)
                if(campo == 'dataScadenza1'){
                    array[i].dataScadenza2 = add(new Date(array[i].dataScadenza1), { months: 4 })
                    array[i].dataScadenza3 = add(new Date(array[i].dataScadenza1), { months: 8 })
                }else if(campo == 'dataScadenza2'){
                    array[i].dataScadenza3 = add(new Date(array[i].dataScadenza2), { months: 4 })
                }
            }else{
                array[i][campo] = value
            }
            console.log(documentiDipendente)
            setDocumentiTiroASegnoDipendente(array)
        }
    }
    

    const onChangeSetScadenzaVisitaMedica = (value, id) => {
        let array = [...visitaMedicaDipendente];
        let i = array.findIndex(el => { return el.id == id })
        if (i > -1) {
            array[i].scadenza = value
            let data;
            if (array[i].dataRilascio) {
                data = add(new Date(array[i].dataRilascio), { months: parseInt(value) })
            } else {
                data = add(new Date(), { months: parseInt(value) })
            }
            array[i].dataScadenza = new Date(data)
            setVisitaMedicaDipendente(array)
        }
    }

    const onChangeSetArchiviatoVisitaMedica = (value, id) => {
        let array = [...visitaMedicaDipendente];
        let i = array.findIndex(el => { return el.id == id })
        if (i > -1) {
            array[i].archiviato = value == 'true' ? false : true
            setVisitaMedicaDipendente(array)
        }
    }


    const onChangeSetScadenzaCorso = (value, id) => {
        let array = [...corsiDipendente];
        let index = array.findIndex(el => { return el.id == id })
        if (index > -1) {
            array[index].scadenza = value
            let data;
            data = add(new Date(array[index].dataRilascio), { months: parseInt(value) })
            array[index].dataScadenza = new Date(data)
        }
        setCorsiDipendente(array)
    }

    const onClickCreaNuovaTipologiaCorso = () => {
        creaTipologiaCorso({ nome: nuovaTipologiaCorso.nome, scadenza: nuovaTipologiaCorso.scadenza })
    }

    const onClickAggiungiRiga = (tabSelected) => {
        let array;
        let data;
        if (tabSelected == "corsi") {
            array = [...corsiDipendente]
            data = add(new Date(), { months: parseInt(tipologieCorsi[0].scadenzaDefault) })
            array.push({ id: uuid(), nome: tipologieCorsi[0].nome, scadenza: tipologieCorsi[0].scadenzaDefault, dataScadenza: new Date(data), dataRilascio: new Date(), tipologia: "corso" })
            setCorsiDipendente(array)
        }
        if (tabSelected == "documenti") {
            array = [...documentiDipendente]
            data = add(new Date(), { months: parseInt(tipologieDocumenti[0].scadenzaDefault) })
            array.push({ id: uuid(), nome: tipologieDocumenti[0].nome, scadenza: tipologieDocumenti[0].scadenzaDefault, dataScadenza: new Date(data), dataRilascio: new Date(), tipologia: "documento" })
            setDocumentiDipendente(array)
        }
        if (tabSelected == "documenti-dipendente") {
            array = [...documentiDipendenteDipendente]
            data = add(new Date(), { months: parseInt(tipologieDocumentiDipendenti[0].scadenzaDefault) })
            array.push({ id: uuid(), nome: tipologieDocumentiDipendenti[0].nome, tipologia: "documento-dipendente" })
            setDocumentiDipendenteDipendente(array)
        }
        if (tabSelected == "visita-medica") {
            array = [...visitaMedicaDipendente]
            array.push({ id: uuid(), nome: "Visita medica", tipologia: "visita-medica", dataRilascio: null, dataScadenza: null, scadenza: "24", archiviato: false })
            setVisitaMedicaDipendente(array)
        }
        if (tabSelected == "tiro-a-segno") {
            array = [...documentiTiroASegnoDipendente]
            array.push({ id: uuid(), nome: "Tiro a segno", tipologia: "tiro-a-segno", dataScadenza1: null, eseguito1: false, dataScadenza2: null, eseguito2: false, dataScadenza3: null, eseguito3: false, archiviato: false })
            setDocumentiTiroASegnoDipendente(array)
        }
        
    }

    return (loading ? <Spinner /> :
        <div className="dati_container modifica_dipendente">
            <div className="fun_assegnazione_incarichi">
                <div className="row">
                    <div className="item_4 m_top_5">
                        <div className="item_5_function">
                            {vista == 'scadenze' ? 
                            ''
                            :
                            <div id="w-node-d409c487-9a0c-6b1f-803c-c7c11a8afa3e-b8dac9c0" className="div_item_button">
                                <a onClick={() => setTabSelected("anagrafica")} className={`button w-button ${tabSelected == "anagrafica" ? '' : 'button_disabled'}`}>Anagrafica</a>
                            </div>
                            }
                            <div id="w-node-d409c487-9a0c-6b1f-803c-c7c11a8afa3e-b8dac9c0" className="div_item_button">
                                <a onClick={() => setTabSelected("vestiario")} className={`button w-button ${tabSelected == "vestiario" ? '' : 'button_disabled'}`}>Vestiario</a>
                            </div>
                            <div id="w-node-d409c487-9a0c-6b1f-803c-c7c11a8afa3e-b8dac9c0" className="div_item_button">
                                <a onClick={() => setTabSelected("documenti-dipendente")} className={`button w-button ${tabSelected == "documenti-dipendente" ? '' : 'button_disabled'}`}>Documenti dipendente</a>
                            </div>
                            <div id="w-node-d409c487-9a0c-6b1f-803c-c7c11a8afa3e-b8dac9c0" className="div_item_button">
                                <a onClick={() => setTabSelected("documenti")} className={`button w-button ${tabSelected == "documenti" ? '' : 'button_disabled'}`}>Documenti</a>
                            </div>
                            {vista == 'scadenze' ? 
                            ''
                            :
                            <div id="w-node-d409c487-9a0c-6b1f-803c-c7c11a8afa3e-b8dac9c0" className="div_item_button">
                                <a onClick={() => setTabSelected("visita-medica")} className={`button w-button ${tabSelected == "visita-medica" ? '' : 'button_disabled'}`}>Visita Medica</a>
                            </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="item_4 m_top_5">
                        <div className="item_5_function">
                            <div id="w-node-d409c487-9a0c-6b1f-803c-c7c11a8afa3e-b8dac9c0" className="div_item_button">
                                <a onClick={() => setTabSelected("corsi")} className={`button w-button ${tabSelected == "corsi" ? '' : 'button_disabled'}`}>Corsi</a>
                            </div>
                            <div id="w-node-d409c487-9a0c-6b1f-803c-c7c11a8afa3e-b8dac9c0" className="div_item_button">
                                <a onClick={() => setTabSelected("tiro-a-segno")} className={`button w-button ${tabSelected == "tiro-a-segno" ? '' : 'button_disabled'}`}>Tiro a segno</a>
                            </div>
                        </div>
                    </div>
                </div>
                {tabSelected == "vestiario" ?
                    <div className="row tab_modifica_dip">
                        <div className="item_2">
                        <h4>Modifica vestiario</h4>
                        <div>
                            <div className="w-form">
                                <form id="vestiario" name="email-form-vestiario" data-name="Email Form vestiario" method="get" aria-label="Email Form vestiario">
                                    <div className="div_wrap_100">
                                        <div className="div_wrap_25">
                                            <div className="col_input">
                                                <label htmlFor="tagliaPantaloni">Pantaloni</label>
                                                <input type="text" className="input w-input" maxLength="256" name="tagliaPantaloni" value={tagliaPantaloni} data-name="tagliaPantaloni" id="tagliaPantaloni" onChange={e => onChangeSetDatiDipendente(e)} />
                                            </div>
                                        </div>
                                        <div className="div_wrap_25">
                                            <div className="col_input">
                                                <label htmlFor="tagliaCamicia">Camicia</label>
                                                <input type="text" className="input w-input" maxLength="256" name="tagliaCamicia" data-name="tagliaCamicia" value={tagliaCamicia} id="tagliaCamicia" onChange={e => onChangeSetDatiDipendente(e)} />
                                            </div>
                                        </div>
                                        <div className="div_wrap_25">
                                            <div className="col_input">
                                                <label htmlFor="tagliaMaglione">Maglione</label>
                                                <input type="text" className="input w-input" maxLength="256" name="tagliaMaglione" data-name="tagliaMaglione" value={tagliaMaglione} id="tagliaMaglione" onChange={e => onChangeSetDatiDipendente(e)} />
                                            </div>
                                        </div>
                                        <div className="div_wrap_25">
                                            <div className="col_input">
                                                <label htmlFor="tagliaGiacca">Giacca</label>
                                                <input type="text" className="input w-input" maxLength="256" name="tagliaGiacca" data-name="tagliaGiacca" value={tagliaGiacca} id="tagliaGiacca" onChange={e => onChangeSetDatiDipendente(e)} />
                                            </div>
                                        </div>
                                        <div className="div_wrap_25">
                                            <div className="col_input">
                                                <label htmlFor="tagliaScarpa">Scarpa</label>
                                                <input type="text" className="input w-input" maxLength="256" name="tagliaScarpa" data-name="tagliaScarpa" value={tagliaScarpa} id="tagliaScarpa" onChange={e => onChangeSetDatiDipendente(e)} />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        </div>
                    </div>
                    : 
                    tabSelected == "anagrafica" ?
                    <div className="row tab_modifica_dip row_datapicker">
                    <div className="item_2">
                        <h4>Modifica dipendente</h4>
                        <div>
                            <div className="w-form">
                                <form id="nuovo_dipendente" name="email-form-2" data-name="Email Form 2" method="get" aria-label="Email Form 2">
                                    <div className="div_wrap_100">
                                        <div className="div_wrap_25">
                                            <div className="col_input">
                                                <label htmlFor="name">Nome</label>
                                                <input type="text" className="input w-input" maxLength="256" name="nome" value={nome} data-name="nome" placeholder="Inserisci nome" id="nome" onChange={e => onChangeSetDatiDipendente(e)} />
                                            </div>
                                        </div>
                                        <div className="div_wrap_25">
                                            <div className="col_input">
                                                <label htmlFor="name">Cognome</label>
                                                <input type="text" className="input w-input" maxLength="256" name="cognome" data-name="cognome" value={cognome} placeholder="Inserisci cognome" id="cognome" onChange={e => onChangeSetDatiDipendente(e)} />
                                            </div>
                                        </div>
                                        <div className="div_wrap_25">
                                            <div className="col_input">
                                                <label htmlFor="name">Codice fiscale</label>
                                                <input type="text" className="input w-input" maxLength="256" name="codicefiscale" data-name="codicefiscale" value={codicefiscale} placeholder="Inserisci codicefiscale" id="codicefiscale" onChange={e => onChangeSetDatiDipendente(e)} />
                                            </div>
                                        </div>
                                        <div className="div_wrap_25">
                                            <div className="col_input">
                                                <label htmlFor="name">Matricola</label>
                                                <input type="text" className="input w-input" maxLength="256" name="matricola" data-name="matricola" value={matricola} placeholder="Inserisci matricola" id="matricola" onChange={e => onChangeSetDatiDipendente(e)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="div_wrap_100">
                                        <div className="div_wrap_25">
                                            <div className="col_input">
                                                <label htmlFor="name">Indirizzo</label>
                                                <input type="text" className="input w-input" maxLength="256" name="indirizzo" data-name="indirizzo" value={indirizzo} placeholder="Inserisci indirizzo" id="indirizzo" onChange={e => onChangeSetDatiDipendente(e)} />
                                            </div>
                                        </div>
                                        <div className="div_wrap_25">
                                            <div className="col_input">
                                                <label htmlFor="name">Luogo residenza</label>
                                                <input type="text" className="input w-input" maxLength="256" name="luogoRes" data-name="luogoRes" value={luogoRes} placeholder="Inserisci luogo residenza" id="luogoRes" onChange={e => onChangeSetDatiDipendente(e)} />
                                            </div>
                                        </div>
                                        <div className="div_wrap_25">
                                            <div className="col_input">
                                                <label htmlFor="name">Provincia residenza</label>
                                                <input type="text" className="input w-input" maxLength="256" name="provRes" value={provRes} data-name="provRes" placeholder="Inserisci provincia residenza" id="provRes" onChange={e => onChangeSetDatiDipendente(e)} />
                                            </div>
                                        </div>
                                        <div className="div_wrap_25">
                                            <div className="col_input">
                                                <label htmlFor="name">CAP</label>
                                                <input type="text" className="input w-input" maxLength="256" name="cap" data-name="cap" value={cap} placeholder="Inserisci cap" id="cap" onChange={e => onChangeSetDatiDipendente(e)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="div_wrap_100">
                                        <div className="div_wrap_25">
                                            <div className="col_input">
                                                <label htmlFor="name">Telefono 1</label>
                                                <input type="text" className="input w-input" maxLength="256" name="telefono1" data-name="telefono1" value={telefono1} placeholder="Inserisci telefono" id="telefono1" onChange={e => onChangeSetDatiDipendente(e)} />
                                            </div>
                                        </div>
                                        <div className="div_wrap_25">
                                            <div className="col_input">
                                                <label htmlFor="name">Telefono 2</label>
                                                <input type="text" className="input w-input" maxLength="256" name="telefono2" data-name="telefono2" value={telefono2} placeholder="Inserisci telefono" id="telefono2" onChange={e => onChangeSetDatiDipendente(e)} />
                                            </div>
                                        </div>
                                        <div className="div_wrap_25">
                                            <div className="col_input">
                                                <label htmlFor="name">Email</label>
                                                <input type="text" className="input w-input" maxLength="256" name="email" data-name="email" value={email} placeholder="Inserisci email" id="email" onChange={e => onChangeSetDatiDipendente(e)} />
                                            </div>
                                        </div>
                                        <div className="div_wrap_25">
                                        </div>
                                    </div>
                                    <div className="div_wrap_100">
                                        <div className="div_wrap_25">
                                            <div className="col_input">
                                                <label htmlFor="name">Luogo nascita</label>
                                                <input type="text" className="input w-input" maxLength="256" name="luogoNasc" data-name="luogoNasc" value={luogoNasc} placeholder="Inserisci luogo nascita" id="luogoNasc" onChange={e => onChangeSetDatiDipendente(e)} />
                                            </div>
                                        </div>
                                        <div className="div_wrap_25">
                                            <div className="col_input">
                                                <label htmlFor="name">Provincia nascita</label>
                                                <input type="text" className="input w-input" maxLength="256" name="provNasc" data-name="provNasc" value={provNasc} placeholder="Inserisci provincia nascita" id="provNasc" onChange={e => onChangeSetDatiDipendente(e)} />
                                            </div>
                                        </div>
                                        <div className="div_wrap_25">
                                            <div className="col_input"><label htmlFor="name-11">Data nascita</label>
                                                <div data-hover="false" data-delay="0" className="select w-dropdown select_data">
                                                    <DatePicker
                                                        onChange={(value) => onChangeSetDatiDipendente({ target: { value: value, name: 'dataNasc' } })}
                                                        value={dataNasc}
                                                        clearIcon={null}
                                                        className={'form-control'}
                                                        disableCalendar={false}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="div_wrap_25">

                                        </div>
                                    </div>
                                    <div className="div_wrap_100">
                                        <div className="div_wrap_50">
                                            <div className="col_input">
                                                <label htmlFor="name-11">Cadenza</label>
                                                <div class="div_wrap_more_input">
                                                    <input type="text" class="input cadenza w-input" name="cadenza-0" data-name="Cadenza-0" placeholder="" id="cadenza-0" value={cadenza.charAt(0)} onChange={(e) => onChangeCadenza(0, e.target.value)} />
                                                    <input type="text" class="input cadenza w-input" name="cadenza-1" data-name="Cadenza-1" placeholder="" id="cadenza-1" value={cadenza.charAt(1)} onChange={(e) => onChangeCadenza(1, e.target.value)} disabled />
                                                    <input type="text" class="input cadenza w-input" name="cadenza-2" data-name="Cadenza-2" placeholder="" id="cadenza-2" value={cadenza.charAt(2)} onChange={(e) => onChangeCadenza(2, e.target.value)} disabled />
                                                    <input type="text" class="input cadenza w-input" name="cadenza-3" data-name="Cadenza-3" placeholder="" id="cadenza-3" value={cadenza.charAt(3)} onChange={(e) => onChangeCadenza(3, e.target.value)} disabled />
                                                    <input type="text" class="input cadenza w-input" name="cadenza-4" data-name="Cadenza-4" placeholder="" id="cadenza-4" value={cadenza.charAt(4)} onChange={(e) => onChangeCadenza(4, e.target.value)} disabled />
                                                    <input type="text" class="input cadenza w-input" name="cadenza-5" data-name="Cadenza-5" placeholder="" id="cadenza-5" value={cadenza.charAt(5)} onChange={(e) => onChangeCadenza(5, e.target.value)} disabled />
                                                    <input type="text" class="input cadenza w-input" name="cadenza-6" data-name="Cadenza-6" placeholder="" id="cadenza-6" value={cadenza.charAt(6)} onChange={(e) => onChangeCadenza(6, e.target.value)} disabled />
                                                    <input type="text" class="input cadenza w-input" name="cadenza-7" data-name="Cadenza-7" placeholder="" id="cadenza-7" value={cadenza.charAt(7)} onChange={(e) => onChangeCadenza(7, e.target.value)} disabled />
                                                    <input type="text" class="input cadenza w-input" name="cadenza-8" data-name="Cadenza-8" placeholder="" id="cadenza-8" value={cadenza.charAt(8)} onChange={(e) => onChangeCadenza(8, e.target.value)} disabled />
                                                    <input type="text" class="input cadenza w-input" name="cadenza-9" data-name="Cadenza-9" placeholder="" id="cadenza-9" value={cadenza.charAt(9)} onChange={(e) => onChangeCadenza(9, e.target.value)} disabled />
                                                    <input type="text" class="input cadenza w-input" name="cadenza-10" data-name="Cadenza-10" placeholder="" id="cadenza-10" value={cadenza.charAt(10)} onChange={(e) => onChangeCadenza(10, e.target.value)} disabled />
                                                    <input type="text" class="input cadenza w-input" name="cadenza-11" data-name="Cadenza-11" placeholder="" id="cadenza-11" value={cadenza.charAt(11)} onChange={(e) => onChangeCadenza(11, e.target.value)} disabled />
                                                    <input type="text" class="input cadenza w-input" name="cadenza-12" data-name="Cadenza-12" placeholder="" id="cadenza-12" value={cadenza.charAt(12)} onChange={(e) => onChangeCadenza(12, e.target.value)} disabled />
                                                    <input type="text" class="input cadenza w-input" name="cadenza-13" data-name="Cadenza-13" placeholder="" id="cadenza-13" value={cadenza.charAt(13)} onChange={(e) => onChangeCadenza(13, e.target.value)} disabled />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="div_wrap_25">
                                            <div className="col_input"><label htmlFor="name-11">Data inizio cadenza</label>
                                                <div data-hover="false" data-delay="0" className="select w-dropdown select_data">
                                                    <DatePicker
                                                        onChange={(value) => onChangeSetDatiDipendente({ target: { value: value, name: 'dataInizioCadenza' } })}
                                                        value={dataInizioCadenza}
                                                        clearIcon={null}
                                                        className={'form-control'}
                                                        disableCalendar={false}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="div_wrap_25">
                                        </div>
                                    </div>
                                    <div className="div_wrap_100">
                                        <div className="div_wrap_25">
                                            <div className="col_input">
                                                <label htmlFor="name">Dipendenza</label>
                                                <input type="text" className="input w-input" maxLength="256" name="dipendenza" data-name="dipendenza" value={dipendenza} placeholder="Inserisci dipendenza" id="dipendenza" onChange={e => onChangeSetDatiDipendente(e)} />
                                            </div>
                                        </div>
                                        <div className="div_wrap_25">
                                            <div className="col_input col_select">
                                                <label htmlFor="name">Qualifica</label>
                                                <div data-hover="false" data-delay="0" className="select w-dropdown">
                                                    <select className="form-control select" name="qualifica" onChange={e => onChangeSetDatiDipendente(e)} required>
                                                        <option selected value={null} disabled >Seleziona qualifica...</option>
                                                        <option selected={'ots' == qualifica} value={'ots'}>OTS</option>
                                                        <option selected={'gpg' == qualifica} value={'gpg'}>GPG</option>
                                                        <option selected={'impiegato' == qualifica} value={'impiegato'}>IMPIEGATO</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="div_wrap_25">
                                            <div className="col_input"><label htmlFor="name-11">Data inizio assunzione</label>
                                                <div data-hover="false" data-delay="0" className="select w-dropdown select_data">
                                                    <DatePicker
                                                        onChange={(value) => onChangeSetDatiDipendente({ target: { value: value, name: 'dataInizioAss' } })}
                                                        value={dataInizioAss}
                                                        clearIcon={null}
                                                        className={'form-control'}
                                                        disableCalendar={false}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="div_wrap_25">
                                            <div className="col_input"><label htmlFor="name-11">Data fine assunzione</label>
                                                <div data-hover="false" data-delay="0" className="select w-dropdown select_data">
                                                    <DatePicker
                                                        onChange={(value) => onChangeSetDatiDipendente({ target: { value: value, name: 'dataFineAss' } })}
                                                        value={dataFineAss}
                                                        clearIcon={null}
                                                        className={'form-control'}
                                                        disableCalendar={false}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="div_wrap_100">
                                        <div className="div_wrap_25">
                                            <div className="col_input">
                                                <label htmlFor="name">Banca ore iniziali</label>
                                                <input type="text" className="input w-input" maxLength="256" name="bancaOreIniziale" data-name="bancaOreIniziale" value={bancaOreIniziale} placeholder="Inserisci ore" id="bancaOreIniziale" onChange={e => onChangeSetDatiDipendente(e)} />
                                            </div>
                                        </div>
                                        <div className="div_wrap_25">
                                            <div className="col_input">
                                                <label htmlFor="name">Percentuale banca ore</label>
                                                <input type="text" className="input w-input" maxLength="256" name="percBancaOre" data-name="percBancaOre" value={percBancaOre} placeholder="Inserisci percentuale banca ore" id="percBancaOre" onChange={e => onChangeSetDatiDipendente(e)} />
                                            </div>
                                        </div>
                                        <div className="div_wrap_25">
                                            <div className="col_input">
                                                <label htmlFor="name">Ferie iniziali</label>
                                                <input type="text" className="input w-input" maxLength="256" name="ferieIniziale" data-name="ferieIniziale" value={ferieIniziale} placeholder="Inserisci giorni" id="ferieIniziale" onChange={e => onChangeSetDatiDipendente(e)} />
                                            </div>
                                        </div>
                                        <div className="div_wrap_25">
                                            <div className="col_input">
                                                <label htmlFor="name">Permessi iniziali</label>
                                                <input type="text" className="input w-input" maxLength="256" name="permessiIniziale" data-name="permessiIniziale" value={permessiIniziale} placeholder="Inserisci giorni" id="permessiIniziale" onChange={e => onChangeSetDatiDipendente(e)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="div_wrap_100">
                                        <div className="div_wrap_25">
                                            <div className="col_input">
                                                <label htmlFor="name">Ore ordinarie</label>
                                                <input type="time" className="input w-input" maxLength="256" name="ferOrd" data-name="ferOrd" value={ferOrd} placeholder="Inserisci ore ordinarie" id="ferOrd" onChange={e => onChangeSetDatiDipendente(e)} />
                                            </div>
                                        </div>
                                        <div className="div_wrap_25">
                                            <div className="col_input">
                                                <label htmlFor="name">Ore straordinarie</label>
                                                <input type="time" className="input w-input" maxLength="256" name="ferStr" data-name="ferStr" value={ferStr} placeholder="Inserisci ore straordinarie" id="ferStr" onChange={e => onChangeSetDatiDipendente(e)} />
                                            </div>
                                        </div>
                                        <div className="div_wrap_25">
                                            <div className="col_input">
                                                <label htmlFor="partTime">Part-time/chiamata</label>
                                                <label className="w-checkbox checkbox-field m_right_10">
                                                    <input type="checkbox" id={"partTime"} className="w-checkbox-input" name="partTime" checked={partTime} value={partTime} onChange={e => onChangeSetCheckbox(e)} />
                                                    <span className="label_text w-form-label" htmlFor="checkbox-3">Si</span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="div_wrap_25">
                                            <div className="col_input">
                                                <label htmlFor="partTime">Somministrato</label>
                                                <label className="w-checkbox checkbox-field m_right_10">
                                                    <input type="checkbox" id={"somministrato"} className="w-checkbox-input" name="somministrato" checked={somministrato} value={somministrato} onChange={e => onChangeSetCheckbox(e)} />
                                                    <span className="label_text w-form-label" htmlFor="checkbox-3">Si</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="div_wrap_100">
                                        <div className="div_wrap_25">
                                            <div className="col_input col_select">
                                                <label htmlFor="name">Mansione</label>
                                                <div data-hover="false" data-delay="0" className="select w-dropdown">
                                                    <select className="form-control select" name="idMansioni" onChange={e => onChangeSetDatiDipendente(e)} required>
                                                        <option selected value={null} disabled >Seleziona mansione</option>
                                                        {mansioniJSON.mansioni.map((el) => (
                                                            <option selected={el.id == idMansioni} value={el.id}>{el.nota}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="div_wrap_25">
                                            <div className="col_input">
                                                <label htmlFor="name">Eccedenza</label>
                                                <label className="w-checkbox checkbox-field m_right_10">
                                                    <input type="checkbox" id={"eccedenza"} className="w-checkbox-input" name="eccedenza" checked={eccedenza} value={eccedenza} onChange={e => onChangeSetCheckbox(e)} />
                                                    <span className="label_text w-form-label" htmlFor="checkbox-3">Si</span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="div_wrap_25">
                                            <div className="col_input">
                                                <label htmlFor="name">Prestabile</label>
                                                <label className="w-checkbox checkbox-field m_right_10">
                                                    <input type="checkbox" id={"prestabile"} className="w-checkbox-input" name="prestabile" checked={prestabile} value={prestabile} onChange={e => onChangeSetCheckbox(e)} />
                                                    <span className="label_text w-form-label" htmlFor="checkbox-3">Si</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="div_wrap_100 mb-5">
                                        <div className="div_wrap_50">
                                            <div className="col_input">
                                                <label htmlFor="name">Sussidiari</label>
                                                <label className="w-checkbox checkbox-field m_right_10">
                                                    <input type="checkbox" id={"sussidiari"} className="w-checkbox-input" name="sussidiari" checked={sussidiari} value={sussidiari} onChange={e => onChangeSetCheckbox(e)} />
                                                    <span className="label_text w-form-label" htmlFor="checkbox-3">Si</span>
                                                </label>
                                            </div>
                                        </div>
                                        {sussidiari ?
                                            <div className="div_wrap_50">
                                                <div className="col_input">
                                                    <label htmlFor="name">Nome sussidiari</label>
                                                    <input type="text" className="input w-input" maxLength="256" name="nomeSussidiari" data-name="nomeSussidiari" value={nomeSussidiari} placeholder="Inserisci nome sussidiari" id="nomeSussidiari" onChange={e => onChangeSetDatiDipendente(e)} />
                                                </div>
                                            </div>
                                            :
                                            ''
                                        }
                                    </div>
                                    <div className="div_wrap_100">
                                        <div className="div_wrap_50">
                                            <div className="col_input">
                                                <label htmlFor="name">Note</label>
                                                <textarea type="text" className="input w-input altezza_text" maxLength="500" name="note" value={note} data-name="note" placeholder="Inserisci note" id="note" onChange={e => onChangeSetDatiDipendente(e)} />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                        : tabSelected == "documenti-dipendente" ?
                            <div className="row tab_modifica_dip">
                                <div className="item_2">
                                    <h4>Modifica documenti dipendente</h4>
                                    <div>
                                        <div className="w-form">
                                            <form id="documenti-dipendente" name="email-form-documenti-dipendente" data-name="Email Form documenti-dipendente" method="get" aria-label="Email Form documenti dipendente">
                                                <section class="p-section-faq">
                                                    <div class="l-inner">
                                                        {documentiDipendenteDipendente.map(documento => (
                                                            <details class="p-section-faq__item p-faq js-faq-details">
                                                                <summary class="p-faq__question js-faq-question">{documento.nome}<span class="p-faq__icon"></span></summary>
                                                                <div class="p-faq__answer js-faq-answer">
                                                                    <div class="p-faq__answer-inner">
                                                                        <DocumentoDipendente
                                                                            id={documento.id}
                                                                            url={documento.url}
                                                                            nome={documento.nome}
                                                                            pulisciFileDocumentoDipendente={pulisciFileDocumentoDipendente}
                                                                            onChangeUploadFile={onChangeUploadFile}
                                                                            tipologieDocumentiDipendenti={tipologieDocumentiDipendenti}
                                                                            onChangeTipologiaDocumentoDipendente={onChangeTipologiaDocumentoDipendente}
                                                                            eliminaDocumentoDipendente={eliminaDocumentoDipendente}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </details>
                                                        ))}
                                                    </div>
                                                </section>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : tabSelected == "documenti" ?
                                <div className="row tab_modifica_dip">
                                    <div className="item_2">
                                        <h4>Modifica documenti</h4>
                                        <div>
                                            <div className="w-form">
                                                <form id="documenti" name="email-form-documenti" data-name="Email Form documenti" method="get" aria-label="Email Form documenti">
                                                    <section class="p-section-faq">
                                                        <div class="l-inner">
                                                            {documentiDipendente.map(documento => (
                                                                <details class="p-section-faq__item p-faq js-faq-details">
                                                                    <summary class="p-faq__question js-faq-question">{documento.nome}<span class="p-faq__icon"></span></summary>
                                                                    <div class="p-faq__answer js-faq-answer">
                                                                        <div class="p-faq__answer-inner">
                                                                            <Documento
                                                                                id={documento.id}
                                                                                url={documento.url}
                                                                                nome={documento.nome}
                                                                                numero={documento.numero}
                                                                                scadenza={documento.scadenza}
                                                                                dataRilascio={documento.dataRilascio ? new Date(documento.dataRilascio) : new Date()}
                                                                                pulisciFileDocumento={pulisciFileDocumento}
                                                                                onChangeUploadFile={onChangeUploadFile}
                                                                                tipologieDocumenti={tipologieDocumenti}
                                                                                onChangeTipologiaDocumento={onChangeTipologiaDocumento}
                                                                                eliminaDocumento={eliminaDocumento}
                                                                                onChangeSetDataRilascioDocumento={onChangeSetDataRilascioDocumento}
                                                                                onChangeNumeroDocumento={onChangeNumeroDocumento}
                                                                                onChangeSetScadenzaDocumento={onChangeSetScadenzaDocumento}
                                                                                onChangeSetCheckboxDocumenti={onChangeSetCheckboxDocumenti}
                                                                                bloccaAvviso={documento.bloccaAvviso}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </details>
                                                            ))}
                                                        </div>
                                                    </section>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : tabSelected == "visita-medica" ?
                                    <div className="row tab_modifica_dip">
                                        <div className="item_2">
                                            <h4>Modifica documenti visita medica</h4>
                                            <div>
                                                <div className="w-form">
                                                    <form id="documenti" name="email-form-documenti" data-name="Email Form documenti" method="get" aria-label="Email Form documenti">
                                                        <section class="p-section-faq">
                                                            <div class="l-inner">
                                                                {visitaMedicaDipendente.map(documento => (
                                                                    <details class="p-section-faq__item p-faq js-faq-details">
                                                                        <summary class="p-faq__question js-faq-question">Visita medica<span class="p-faq__icon"></span></summary>
                                                                        <div class="p-faq__answer js-faq-answer">
                                                                            <div class="p-faq__answer-inner">
                                                                                <DocumentoVisitaMedica 
                                                                                    id={documento.id}
                                                                                    nome={documento.nome}
                                                                                    url={documento.url}
                                                                                    archiviato={documento.archiviato}
                                                                                    dataRilascio={documento.dataRilascio ? new Date(documento.dataRilascio) : null}
                                                                                    pulisciFileVisitaMedica={pulisciFileVisitaMedica}
                                                                                    onChangeUploadFile={onChangeUploadFile}
                                                                                    onChangeSetDataRilascioVisitaMedica={onChangeSetDataRilascioVisitaMedica}
                                                                                    onChangeSetScadenzaVisitaMedica={onChangeSetScadenzaVisitaMedica}
                                                                                    onChangeSetArchiviatoVisitaMedica={onChangeSetArchiviatoVisitaMedica}
                                                                                    scadenza={documento.scadenza}
                                                                                    eliminaVisitaMedica={eliminaVisitaMedica}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </details>
                                                                ))}
                                                            </div>
                                                        </section>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : tabSelected == "corsi" ?
                                        <>
                                            {popUpAggiungiTipologiaCorso ?
                                                <div className="row">
                                                    <div className="item_2">
                                                        <h4>Crea tipologia corso</h4>
                                                    </div>
                                                    <div className="w-form">
                                                        <form id="nuovo_punto_servizio" name="email-form-2" data-name="Email Form 2" method="get" aria-label="Email Form 2">
                                                            <div className="div_wrap_100">
                                                                <div className="div_wrap_25">
                                                                    <div className="col_input">
                                                                        <label htmlFor="tagliaPantaloni">Nome</label>
                                                                        <input type="text" className="input w-input" maxLength="256" name="nome" value={nuovaTipologiaCorso.nome} data-name="nome" id="nome" onChange={e => setNuovaTipologiaCorso({ ...nuovaTipologiaCorso, nome: e.target.value })} />
                                                                    </div>
                                                                </div>
                                                                <div className="div_wrap_25">
                                                                    <div className="col_input col_select">
                                                                        <label htmlFor="name">Scadenza (mesi)</label>
                                                                        <div data-hover="false" data-delay="0" className="select w-dropdown">
                                                                            <select className="form-control select" name="scadenza" onChange={e => setNuovaTipologiaCorso({ ...nuovaTipologiaCorso, scadenza: e.target.value })} required>
                                                                                <option selected={true} value={null} disabled>seleziona...</option>
                                                                                <option selected={nuovaTipologiaCorso.scadenza == '12'} value={'12'}>12 mesi</option>
                                                                                <option selected={nuovaTipologiaCorso.scadenza == '24'} value={'24'}>24 mesi</option>
                                                                                <option selected={nuovaTipologiaCorso.scadenza == '36'} value={'36'}>36 mesi</option>
                                                                                <option selected={nuovaTipologiaCorso.scadenza == '48'} value={'48'}>48 mesi</option>
                                                                                <option selected={nuovaTipologiaCorso.scadenza == '60'} value={'60'}>60 mesi</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="div_wrap_25">
                                                                </div>
                                                                <div className="div_wrap_25">
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                                :
                                                <div className="row">
                                                    <div className="item_2">
                                                        <h4>Modifica corsi</h4>
                                                        <div>
                                                            <div className="w-form">
                                                                <form id="documenti" name="email-form-documenti" data-name="Email Form documenti" method="get" aria-label="Email Form documenti">
                                                                    <section class="p-section-faq">
                                                                        <div class="l-inner">
                                                                            <div className='mb-30'>
                                                                                <div className="div_wrap_100">
                                                                                    <div className="div_wrap_25">
                                                                                        <span></span>
                                                                                    </div>
                                                                                    <div className="div_wrap_25">
                                                                                        <span></span>
                                                                                    </div>
                                                                                    <div className="div_wrap_25">
                                                                                        <span></span>
                                                                                    </div>
                                                                                    <div className="div_wrap_25">
                                                                                        <a id="w-node-_571e9ca7-8650-59ca-aaad-79ac7369ac52-867f153d" onClick={(e) => { setPopUpAggiungiTipologiaCorso(true) }} className={`button w-button`}>Nuova tipologia corso</a>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            {corsiDipendente.map(corso => (
                                                                                <details class="p-section-faq__item p-faq js-faq-details">
                                                                                    <summary class="p-faq__question js-faq-question">{corso.nome}<span class="p-faq__icon"></span></summary>
                                                                                    <div class="p-faq__answer js-faq-answer">
                                                                                        <div class="p-faq__answer-inner">
                                                                                            <DocumentoCorso
                                                                                                nome={corso.nome}
                                                                                                id={corso.id}
                                                                                                tipologieCorsi={tipologieCorsi}
                                                                                                url={corso.url ? corso.url : ""}
                                                                                                getIndexDocumento={getIndexDocumento}
                                                                                                pulisciFileCorso={pulisciFileCorso}
                                                                                                onChangeUploadFile={onChangeUploadFile}
                                                                                                onChangeTipologiaCorso={onChangeTipologiaCorso}
                                                                                                dataRilascio={corso.dataRilascio ? new Date(corso.dataRilascio) : new Date()}
                                                                                                onChangeSetDataRilascioCorso={onChangeSetDataRilascioCorso}
                                                                                                onChangeSetScadenzaCorso={onChangeSetScadenzaCorso}
                                                                                                eliminaCorso={eliminaCorso}
                                                                                                scadenza={corso.scadenza}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </details>
                                                                            ))}
                                                                        </div>
                                                                    </section>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </>
                                        : tabSelected == "tiro-a-segno" ?
                                        <div className="row tab_modifica_dip">
                                            <div className="item_2">
                                                <h4>Modifica tiro a segno</h4>
                                                <div>
                                                    <div className="w-form">
                                                        <form id="documenti" name="email-form-documenti" data-name="Email Form documenti" method="get" aria-label="Email Form documenti">
                                                            <section class="p-section-faq">
                                                                <div class="l-inner">
                                                                    {documentiTiroASegnoDipendente.map(documento => (
                                                                        <details class="p-section-faq__item p-faq js-faq-details">
                                                                            <summary class="p-faq__question js-faq-question">{documento.nome}<span class="p-faq__icon"></span></summary>
                                                                            <div class="p-faq__answer js-faq-answer">
                                                                                <div class="p-faq__answer-inner">
                                                                                    <DocumentoTiroASegno
                                                                                        id={documento.id}
                                                                                        nome={documento.nome}
                                                                                        dataScadenza1={documento.dataScadenza1 ? new Date(documento.dataScadenza1) : null}
                                                                                        eseguito1={documento.eseguito1}
                                                                                        dataScadenza2={documento.dataScadenza2 ? new Date(documento.dataScadenza2) : null}
                                                                                        eseguito2={documento.eseguito2}
                                                                                        dataScadenza3={documento.dataScadenza3 ? new Date(documento.dataScadenza3) : null}
                                                                                        eseguito3={documento.eseguito3}
                                                                                        eliminaDocumentoTiroASegno={eliminaDocumentoTiroASegno}
                                                                                        onChangeSetTiroASegno={onChangeSetTiroASegno}
                                                                                        onChangeSetCheckboxTiroASegno={onChangeSetCheckboxTiroASegno}
                                                                                        bloccaAvviso={documento.bloccaAvviso}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </details>
                                                                    ))}
                                                                </div>
                                                            </section>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        ''
                }
                <div className="row">
                    <div className="item_4 m_top_5">
                        <div className="item_5_function">
                            <div id="w-node-d27a7e1c-7034-f43d-edeb-5fcab2bbc790-b8dac9c0" className="div_item_empty"></div>
                            <div id="w-node-d27a7e1c-7034-f43d-edeb-5fcab2bbc790-b8dac9c0" className="div_item_empty"></div>
                            <div id="w-node-d27a7e1c-7034-f43d-edeb-5fcab2bbc790-b8dac9c0" className="div_item_empty"></div>
                            <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc40-b8dac9c0" className="div_item_empty"></div>
                            {popUpAggiungiTipologiaCorso ?
                                <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc40-b8dac9c0" className="div_item_empty"></div>
                                :
                                tabSelected === 'documenti' || tabSelected === 'documenti-dipendente' || tabSelected === 'corsi' || tabSelected === 'visita-medica' || tabSelected === 'tiro-a-segno' ?
                                    <div id="w-node-d409c487-9a0c-6b1f-803c-c7c11a8afa3e-b8dac9c0" className="div_item_button">
                                        <a onClick={(e) => onClickAggiungiRiga(tabSelected)} className="button w-button">Aggiungi riga</a>
                                    </div>
                                    :
                                    ''
                            }
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="item_4 m_top_5">
                        <div className="item_5_function">
                            <div id="w-node-d27a7e1c-7034-f43d-edeb-5fcab2bbc790-b8dac9c0" className="div_item_empty"></div>
                            <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc40-b8dac9c0" className="div_item_empty"></div>
                            <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc40-b8dac9c0" className="div_item_empty"></div>
                            {popUpAggiungiTipologiaCorso ?
                                <>
                                    <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc3d-b8dac9c0" className="div_item_empty"></div>
                                    <div id="w-node-d409c487-9a0c-6b1f-803c-c7c11a8afa3e-b8dac9c0" className="div_item_button">
                                        <a onClick={(e) => onClickCreaNuovaTipologiaCorso(e)} className="button w-button">Crea tipologia</a>
                                    </div>
                                    <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc43-b8dac9c0" className="div_item_button">
                                        <a id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc44-b8dac9c0" onClick={() => setPopUpAggiungiTipologiaCorso(false)} className="button w-button">Chiudi</a>
                                    </div>
                                </>
                                :
                                <>
                                    <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc43-b8dac9c0" className="div_item_button">
                                        <a id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc44-b8dac9c0" onClick={() => setOpenModificaDipendente(false)} className="button w-button">Chiudi</a>
                                    </div>
                                    <div id="w-node-d409c487-9a0c-6b1f-803c-c7c11a8afa3e-b8dac9c0" className="div_item_button">
                                        <a onClick={(e) => onSubmitModificaDipendente(e)} className="button w-button">Salva</a>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

ModificaCliente.protoTypes = {
    getDipendente: PropTypes.func.isRequired,
    modificaDipendente: PropTypes.func.isRequired,
    creaTipologiaCorso: PropTypes.func.isRequired,
    getTipologieCorsi: PropTypes.func.isRequired,
    dipendente: PropTypes.object.isRequired,
    alert: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    documento: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    dipendente: state.dipendente,
    alert: state.alert,
    auth: state.auth,
    documento: state.documento
});

export default connect(mapStateToProps, { getDipendente, modificaDipendente, creaTipologiaCorso, getTipologieCorsi })(ModificaCliente);