import axios from 'axios';
import { REGISTER_SUCCESS, REGISTER_FAIL, USER_LOADED, AUTH_ERROR, LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT, CLEAN_DATI_DASHBOARD } from './types';
import { setAlert } from './alert';
import setAuthToken from '../utils/setAuthToken';
import { setSidebarPosition } from '../actions/sidebar'
import history from '../History';

export const loadUser = () => async dispatch => {
    if(localStorage.token){
        setAuthToken(localStorage.token);
    }

    try{
        const res = await axios.get('/api/auth');
        dispatch({
            type: USER_LOADED,
            payload: res.data
        });
    }catch(err){
        dispatch({
            type: AUTH_ERROR
        });
    }
}

export const register = ({ username, password, nome, cognome }) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ username, password, nome, cognome });
    try{
        const res = await axios.post('/api/auth/register', body, config);
        dispatch({
            type: REGISTER_SUCCESS,
            payload: res.data
        });

        dispatch(loadUser());


    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        
        dispatch({
            type: REGISTER_FAIL
        });
    }
};



export const login = ({ username, password}) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ username, password });
    try{
        const res = await axios.post('/api/auth', body, config);
        dispatch({
            type: LOGIN_SUCCESS,
            payload: res.data
        });
        dispatch(loadUser());        
    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        
        dispatch({
            type: LOGIN_FAIL
        });
    }
};


export const logout = () => dispatch => {
    dispatch({ type: LOGOUT });  
    dispatch({ type: CLEAN_DATI_DASHBOARD });  
    setAuthToken(null);
    dispatch(setSidebarPosition('admin', 'ordini'));
    history.push("/login");
};