import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import DatePicker from 'react-date-picker';
import Moment from 'react-moment'
import Spinner from '../../layout/Spinner';
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import {Helmet} from "react-helmet";
import disponibilitaJSON from "../../../utils/disponibilita.json"
import { getFiliali } from '../../../actions/filiale';
import { getGiorniEccezione, getDipendenti, aggiungiGiornoEccezioneLungo, eliminaGiornoEccezioneLungo, confermaGiornoEccezione, confermaGiorniEccezione, cleanGiorniEccezione, modificaGiornoEccezione } from '../../../actions/dipendente';
const GiorniEccezione = ({ auth: {user}, dipendente: { dipendenti, giorniEccezione }, filiale: { filiali }, cleanDipendenti, archiviaRichieste, aggiungiGiornoEccezioneLungo, alert, getGiorniEccezione, getDipendenti, eliminaGiornoEccezioneLungo, confermaGiornoEccezione, confermaGiorniEccezione, cleanGiorniEccezione, modificaGiornoEccezione, getFiliali }) => {

    const [dataInizioInput, setDataInizioInput] = useState((setHours(setMinutes(startOfMonth(new Date()), 0), 0)));
    const [dataFineInput, setDataFineInput] = useState((setHours(setMinutes(endOfMonth(new Date()), 59), 23)));
    const [nuovaDataInizioInput, setNuovaDataInizioInput] = useState((setHours(setMinutes(new Date(), 0), 0)));
    const [nuovaDataFineInput, setNuovaDataFineInput] = useState((setHours(setMinutes(new Date(), 59), 23)));
    const [giorniEccezioneData, setGiorniEccezioneData] = useState([]);
    const [openNuovaAssenza, setOpenNuovaAssenza] = useState(false);
    const [dipendenteSelected, setDipendenteSelected] = useState(null);
    const [tipologiaSelected, setTipologiaSelected] = useState('');
    const [nuovaTipologiaSelected, setNuovaTipologiaSelected] = useState(null);
    const [nProtocollo, setNProtocollo] = useState('');
    const [tipologiaFilterSelected, setTipologiaFilterSelected] = useState('');
    const [openModificaGiornoEccezione, setOpenModificaGiornoEccezione] = useState(false);
    const [checkboxSelezionaAssenze, setCheckboxSelezionaAssenze] = useState(false);
    const [dipendentiList, setDipendentiList] = useState([]);
    const [checkVediTuttiDipendenti, setCheckVediTuttiDipendenti] = useState(true);
    const [filialiList, setFilialiList] = useState([]);
    const [filtraFiliale, setFiltraFiliale] = useState(null);


    const [rowSelected, setRowSelected] = useState({ id: "", dipendente: "", dataInzio: new Date(), dataFine: new Date(), disponibilita: "", nProtocollo: "" });
    const [loadingContrattiRichieste, setLoadingContrattiRichieste] = useState(false);
    const [checkBoxAssenze, setCheckBoxAssenze] = useState([]);
    const [cambiaDisponibilitaOpen, setCambiaDisponibilitaOpen] = useState(false);



    //*
    useEffect(() => {
        const size = alert.length;
        if (size > 0) {
            if (alert[size - 1].alertType === "success" && alert[size - 1].field == 'add_giorno_eccezione') {
                setCambiaDisponibilitaOpen(false);
                setDipendenteSelected(null)
                setNuovaTipologiaSelected(null)
                setNProtocollo('')
            }
            if (alert[size - 1].alertType === "success" && alert[size - 1].field == 'richieste_archiviate') {
                cleanDipendenti()
            }
            if (alert[size - 1].alertType === "success" && alert[size - 1].field == 'ripristina_richieste_archiviate') {
                cleanDipendenti()
            }
        }
    }, [alert]);


    //*
    useEffect(() => {
        getDipendenti(0, 10, false);
        getGiorniEccezione({ dataInizio: dataInizioInput, dataFine: dataFineInput, idFiliale: filtraFiliale })
        return () => {
            cleanGiorniEccezione()
        };
    }, []);

    useEffect(() => {
        if (user && user.role == "organizzazione") {
            getFiliali();
        }
    }, [user]);

    //*
    useEffect(() => {
        getGiorniEccezione({ dataInizio: dataInizioInput, dataFine: dataFineInput, idFiliale: filtraFiliale })
    }, [dataInizioInput, dataFineInput]);

    //*
    useEffect(() => {
        if (tipologiaFilterSelected) {
            let arrayGiorniEccezione = [];
            if (tipologiaFilterSelected == 'tutti') {
                arrayGiorniEccezione = giorniEccezione
            } else {
                arrayGiorniEccezione = giorniEccezione.filter(function (giorno) {
                    return giorno.disponibilita == tipologiaFilterSelected;
                })
            }
            setGiorniEccezioneData(arrayGiorniEccezione)
        } else {
            setGiorniEccezioneData(giorniEccezione)
        }
    }, [giorniEccezione]);


    //filtra dipendeenti con contratto valido
    useEffect(() => {
        if(checkVediTuttiDipendenti){
            let dataOggi = new Date()
            let arrayDipendenti = []
            arrayDipendenti = dipendenti.filter(el => {
                let dataFineAssunzione = new Date(el.dataFineAss)
                return !el.dataFineAss || (dataOggi <= dataFineAssunzione)
            })
            setDipendentiList(arrayDipendenti)
        }else{
            setDipendentiList(dipendenti)
        }
    }, [dipendenti]);

    useEffect(() => {
        if (filiali.length > 0 && user && user.role == 'organizzazione') {
            let array = [];
            filiali.map(filiale => {
                const index = user.vistaFiliali.findIndex(el => el._id == filiale._id);
                if(index >= 0){
                    array.push(filiale)
                }
            })
            setFilialiList(array)
            setFiltraFiliale(array[0]._id)
            getGiorniEccezione({ dataInizio: dataInizioInput, dataFine: dataFineInput, idFiliale: array[0]._id })
        }
    }, [filiali]);

    //FUNZIONI UTILITY





    //FUNZIONI ONCLICK


    //*
    const onClickOpenNuovaAssenza = () => {
        setOpenNuovaAssenza(true)
    }



    //*
    const onClickSetRowSelected = async ({ id, dipendente, dataInizio, dataFine, disponibilita, nProtocollo }) => {
        setRowSelected({ id, dipendente, dataInizio: new Date(dataInizio), dataFine: new Date(dataFine), disponibilita, nProtocollo })
    }



    //*
    const onClickEliminaGiornoEccezione = (() => {
        eliminaGiornoEccezioneLungo(rowSelected.id)
        setRowSelected({ id: "", dipendente: "", dataInzio: new Date(), dataFine: new Date(), disponibilita: "", nProtocollo: "" })
    })

    //*
    const onClickConfermaGiornoEccezione = (() => {
        confermaGiorniEccezione({giorniEccezione: checkBoxAssenze, check: true})
    })

    //*
    const onClickSconfermaGiornoEccezione = (() => {
        confermaGiorniEccezione({giorniEccezione: checkBoxAssenze, check: false})
    })

    //*
    const onClickOpenModificaGiornoEccezione = (() => {
        setOpenModificaGiornoEccezione(!openModificaGiornoEccezione)
    })

    //*
    const onClickSalvaModificaGiornoEccezione = (() => {
        modificaGiornoEccezione(rowSelected)
    })

    //*
    const onClickAddGiornoEccezione = (() => {
        aggiungiGiornoEccezioneLungo({ idDipendente: dipendenteSelected, disponibilita: nuovaTipologiaSelected, dataInizio: nuovaDataInizioInput, dataFine: nuovaDataFineInput, nProtocollo: nProtocollo })
    })

    const onClickChiudiModificaGiornoEccezione = () => {
        setRowSelected({ id: "", dipendente: "", dataInzio: new Date(), dataFine: new Date(), disponibilita: "", nProtocollo: "" })
        setOpenModificaGiornoEccezione(false)
    }

    //FUNZIONI ONCHANGE

    //*
    const onChangeRangeDateInizio = (data) => {
        setRowSelected({ id: "", dipendente: "", dataInzio: new Date(), dataFine: new Date(), disponibilita: "", nProtocollo: "" })
        setDataInizioInput(data)
    }

    //*
    const onChangeRangeDateFine = (data) => {
        setRowSelected({ id: "", dipendente: "", dataInzio: new Date(), dataFine: new Date(), disponibilita: "", nProtocollo: "" })
        setDataFineInput(data)
    }

    //*
    const onChangeSetDipendente = (e) => {
        setDipendenteSelected(e.target.value)
    }

    //*
    const onChangeSetNuovaDataInput = (e) => {
        if (e.target.name == 'nuovaDataInizio') {
            setNuovaDataInizioInput(e.target.value)
        } else {
            setNuovaDataFineInput(e.target.value)
        }
    }

    //*
    const onChangeSetTipologia = (e) => {
        setTipologiaSelected(e.target.value)
    }

    const onChangeSetTipologiaNuovaAssenza = (e) => {
        setNuovaTipologiaSelected(e.target.value)
    }

    const onChangeFilterResults = (e) => {
        setTipologiaFilterSelected(e.target.value)
        let arrayGiorniEccezione = [];
        if (e.target.value == 'tutti') {
            arrayGiorniEccezione = giorniEccezione
        } else {
            arrayGiorniEccezione = giorniEccezione.filter(function (giorno) {
                return giorno.disponibilita == e.target.value;
            })
        }
        setGiorniEccezioneData(arrayGiorniEccezione)
    }


    //*
    const onChangesetCheckBoxAssenze = ({ name, id, value }) => {
        let arrayCheckBoxAssenze = [];
        if (id) {
            arrayCheckBoxAssenze = [...checkBoxAssenze];
            const index = checkBoxAssenze.findIndex(el => el == id);
            if (index >= 0) {
                arrayCheckBoxAssenze.splice(index, 1);
            } else {
                arrayCheckBoxAssenze.push(id);
            }
        } else if (name === "tuttiContratti") {
            setCheckboxSelezionaAssenze(!checkboxSelezionaAssenze)
            if(value == "true"){
                arrayCheckBoxAssenze = []
            }else{
                giorniEccezioneData.map((el) => {
                    arrayCheckBoxAssenze.push(el._id)
                })
            }
        }
        setCheckBoxAssenze(arrayCheckBoxAssenze)
    };

    const onChangeSetCheckBoxCessati = () => {
        if(!checkVediTuttiDipendenti){
            let dataOggi = new Date()
            let arrayDipendenti = []
            arrayDipendenti = dipendenti.filter(el => {
                let dataFineAssunzione = new Date(el.dataFineAss)
                return !el.dataFineAss || (dataOggi <= dataFineAssunzione)
            })
            setDipendentiList(arrayDipendenti)
        }else{
            setDipendentiList(dipendenti)
        }
        setCheckVediTuttiDipendenti(!checkVediTuttiDipendenti)
    }

    const onChangeFiltraDipendentiPerFiliale = e => {
        setFiltraFiliale(e.target.value)
        getGiorniEccezione({ dataInizio: dataInizioInput, dataFine: dataFineInput, idFiliale: e.target.value })
    };

    return (
        <div className="body wf-section">
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`Gestione assenze - Gestionale Vigili dell'ordine`}</title>
            </Helmet>
            <div className="div_wrap">
                <div className="div_wrap_100">
                    <div className="div_wrap_50">
                        <div className="div_container">
                            <div className="title_window">
                                <div className="title_window_p">Gestione assenze</div>
                            </div>
                            <div className="dati_container">
                                <div className="fun_assegnazione_incarichi">
                                    <div className="row row_datapicker">
                                        <div className="item_1 filtro_wrap_incarichi">
                                            <div id="w-node-_284812ee-7924-9f38-86bb-8d2cc43dff3c-867f153d" className="div_item_1">
                                                <div id="w-node-_80bb2acb-3043-3649-0498-379a652f3b04-867f153d" className="label_text">Data inizio</div>
                                                <div data-hover="false" data-delay="0" className="select w-dropdown">
                                                    <DatePicker
                                                        value={dataInizioInput}
                                                        format="dd/MM/y"
                                                        weekPicker
                                                        onChange={onChangeRangeDateInizio}
                                                        //showTodayButton={false}
                                                        //weekDays={weekDays}
                                                        //months={months}
                                                        locale="it-IT"
                                                        clearIcon={null}
                                                        weekStartDayIndex={1}
                                                    />
                                                </div>
                                            </div>
                                            <div id="w-node-_284812ee-7924-9f38-86bb-8d2cc43dff3c-867f153d" className="div_item_1">
                                                <div id="w-node-_80bb2acb-3043-3649-0498-379a652f3b04-867f153d" className="label_text">Data fine</div>
                                                <div data-hover="false" data-delay="0" className="select w-dropdown">
                                                    <DatePicker
                                                        value={dataFineInput}
                                                        format="dd/MM/y"
                                                        weekPicker
                                                        onChange={onChangeRangeDateFine}
                                                        //showTodayButton={false}
                                                        //weekDays={weekDays}
                                                        //months={months}
                                                        locale="it-IT"
                                                        clearIcon={null}
                                                        weekStartDayIndex={1}
                                                    />
                                                </div>
                                            </div>
                                            <div id="w-node-_284812ee-7924-9f38-86bb-8d2cc43dff3c-867f153d" className="div_item_1">
                                                <div id="w-node-_80bb2acb-3043-3649-0498-379a652f3b04-867f153d" className="label_text">Tipologia</div>
                                                <div className="col_input col_select">
                                                    <div data-hover="false" data-delay="0" className="select w-dropdown">
                                                        <select className="form-control select" name="tipologia" onChange={e => onChangeFilterResults(e)} required>
                                                            <option selected value={'tutti'}>Tutti</option>
                                                            {disponibilitaJSON.disponibilita.map((el) => (
                                                                <option selected={el.codice == tipologiaSelected} value={el.codice}>{el.codice} - {el.nome}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            {user && user.role == 'organizzazione' ? 
                                            <div id="w-node-_284812ee-7924-9f38-86bb-8d2cc43dff3c-867f153d" className="div_item_1">
                                                <div id="w-node-_80bb2acb-3043-3649-0498-379a652f3b04-867f153d" className="label_text">Filiale</div>
                                                <div data-hover="false" data-delay="0" className="select w-dropdown">
                                                        <select className="form-control select" name="idCliente" onChange={e => onChangeFiltraDipendentiPerFiliale(e)} required>
                                                        <option selected={true} disabled >Seleziona filiale</option>
                                                            {filialiList.map((elementoFiliale, i) => (
                                                                <option key={i} value={elementoFiliale._id} selected={elementoFiliale._id == filtraFiliale} >{elementoFiliale.nome}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                            </div>
                                            :
                                            ''
                                            }
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="item_2">
                                            <div className="intestazione_table">
                                                <div className="w-layout-grid div_column_gestione_assenze intestazione">
                                                    <div className="div_item_column">
                                                        <div className="item_table">
                                                            <input type="checkbox" id="checkboxSelezionaTutto" name="checkboxSelezionaTutto" data-name="checkboxSelezionaTutto" className="w-checkbox-input checkbox_selection" value={checkboxSelezionaAssenze} onChange={e => onChangesetCheckBoxAssenze({ name: "tuttiContratti", id: null, value: e.target.value })} />
                                                        </div>
                                                    </div>
                                                    <div className="div_item_column">
                                                        <div className="item_table">Data inizio</div>
                                                    </div>
                                                    <div className="div_item_column">
                                                        <div className="item_table">Data fine</div>
                                                    </div>
                                                    <div className="div_item_column">
                                                        <div className="item_table">Dipendente</div>
                                                    </div>
                                                    <div className="div_item_column">
                                                        <div className="item_table">Tipologia</div>
                                                    </div>
                                                    <div className="div_item_column">
                                                        <div className="item_table">Confermata</div>
                                                    </div>
                                                    <div className="div_item_column">
                                                        <div className="item_table">N. protocollo</div>
                                                    </div>
                                                </div>
                                            </div>
                                            {loadingContrattiRichieste ? <Spinner /> :
                                                <div className="body_table">
                                                    {giorniEccezioneData.map((giorno, i) => (
                                                        <div key={"contratto" + i} className={`w-layout-grid div_column_gestione_assenze cursor ${rowSelected.id == giorno._id ? 'giorno_selected' : ''}`}>
                                                            <div className="div_item_column">
                                                                        <div className="item_table"><input type="checkbox" id={`checkboxSeleziona${i}`} name={`checkboxSeleziona${i}`} data-name={`checkboxSeleziona${i}`} value={checkboxSelezionaAssenze} className="w-checkbox-input checkbox_selection" checked={checkBoxAssenze.findIndex(el => el == giorno._id) >= 0 ? true : false} onChange={(e) => onChangesetCheckBoxAssenze({ name: "contrattoSingolo", id: giorno._id, value: e.target.value })} /></div>
                                                                    </div>
                                                            <div className="div_item_column" onClick={() => onClickSetRowSelected({ id: giorno._id, dipendente: "" + giorno.dipendente.cognome + " " + giorno.dipendente.nome, dataInizio: giorno.dataInizio, dataFine: giorno.dataFine, disponibilita: giorno.disponibilita, nProtocollo: giorno.nProtocollo })}>
                                                                <div className="item_table"><Moment format='DD/MM/YYYY'>{giorno.dataInizio}</Moment></div>
                                                            </div>
                                                            <div className="div_item_column" onClick={() => onClickSetRowSelected({ id: giorno._id, dipendente: "" + giorno.dipendente.cognome + " " + giorno.dipendente.nome, dataInizio: giorno.dataInizio, dataFine: giorno.dataFine, disponibilita: giorno.disponibilita, nProtocollo: giorno.nProtocollo })}>
                                                                <div className="item_table"><Moment format='DD/MM/YYYY'>{giorno.dataFine}</Moment></div>
                                                            </div>
                                                            <div className="div_item_column" onClick={() => onClickSetRowSelected({ id: giorno._id, dipendente: "" + giorno.dipendente.cognome + " " + giorno.dipendente.nome, dataInizio: giorno.dataInizio, dataFine: giorno.dataFine, disponibilita: giorno.disponibilita, nProtocollo: giorno.nProtocollo })}>
                                                                <div className="item_table">{giorno.dipendente.cognome} {giorno.dipendente.nome}</div>
                                                            </div>
                                                            <div className="div_item_column" onClick={() => onClickSetRowSelected({ id: giorno._id, dipendente: "" + giorno.dipendente.cognome + " " + giorno.dipendente.nome, dataInizio: giorno.dataInizio, dataFine: giorno.dataFine, disponibilita: giorno.disponibilita, nProtocollo: giorno.nProtocollo })}>
                                                                <div className="item_table">{giorno.disponibilita}</div>
                                                            </div>
                                                            <div className={`div_item_column ${giorno.confermata ? "green" : "red"}`} onClick={() => onClickSetRowSelected({ id: giorno._id, dipendente: "" + giorno.dipendente.cognome + " " + giorno.dipendente.nome, dataInizio: giorno.dataInizio, dataFine: giorno.dataFine, disponibilita: giorno.disponibilita, nProtocollo: giorno.nProtocollo })}>
                                                                <div className="item_table">{giorno.confermata ? "Si" : "No"}</div>
                                                            </div>
                                                            <div className="div_item_column" onClick={() => onClickSetRowSelected({ id: giorno._id, dipendente: "" + giorno.dipendente.cognome + " " + giorno.dipendente.nome, dataInizio: giorno.dataInizio, dataFine: giorno.dataFine, disponibilita: giorno.disponibilita, nProtocollo: giorno.nProtocollo })}>
                                                                <div className="item_table">{giorno.nProtocollo}</div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="item_3 m_top_5">
                                            <div className="list_button list_button_2">
                                                <div id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98d-867f153d" className="div_item_button">
                                                    <a id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98e-867f153d" className={`button w-button red ${rowSelected.id.length == 0 ? "button_disabled" : ""}`} onClick={(e) => rowSelected.id.length == 0 ? e.preventDefault() : onClickEliminaGiornoEccezione()}>Elimina</a>
                                                </div>                                                
                                                <div id="w-node-bf561da2-25a7-b3ef-c44c-a819295560e3-867f153d" className="div_item_empty"></div>
                                                <div id="w-node-bf561da2-25a7-b3ef-c44c-a819295560e3-867f153d" className="div_item_empty"></div>
                                                <div id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98d-867f153d" className="div_item_button">
                                                    <a id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98e-867f153d" className={`button w-button green ${checkBoxAssenze.length == 0 ? "button_disabled" : ""}`} onClick={(e) => checkBoxAssenze.length == 0 ? e.preventDefault() : onClickConfermaGiornoEccezione()}>Conferma</a>
                                                </div>
                                                <div id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98d-867f153d" className="div_item_button">
                                                    <a id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98e-867f153d" className={`button w-button red ${checkBoxAssenze.length == 0 ? "button_disabled" : ""}`} onClick={(e) => checkBoxAssenze.length == 0 ? e.preventDefault() : onClickSconfermaGiornoEccezione()}>Sconferma</a>
                                                </div>
                                                <div id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98d-867f153d" className="div_item_button">
                                                    <a id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98e-867f153d" className={`button w-button ${rowSelected.id.length == 0 ? "button_disabled" : ""}`} onClick={(e) => rowSelected.id.length == 0 ? e.preventDefault() : onClickOpenModificaGiornoEccezione()}>Modifica</a>
                                                </div>
                                                <div id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98d-867f153d" className="div_item_button">
                                                    <a id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98e-867f153d" className="button w-button" onClick={(e) => onClickOpenNuovaAssenza()}>Aggiungi</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {openModificaGiornoEccezione ?
                    <div className="div_wrap_50">
                    <div className="div_container">
                        <div className="title_window">
                            <div className="title_window_p">Modifica assenza</div>
                        </div>
                        <div className="dati_container assenze">
                            <div className="fun_assegnazione_incarichi">
                                <div className="row">
                                    <div className="item_2">
                                        <h4>Modifica assenza</h4>
                                        <div>
                                            <div className="w-form">
                                                <form id="email-form-2" name="email-form-2" data-name="Email Form 2" method="get" aria-label="Email Form 2">
                                                    <div className="div_wrap_100">
                                                        <div className="div_wrap_25">
                                                            <div className="col_input"><label htmlFor="name-11">Dipendente</label>
                                                                <input className="input w-input" type="text"  name="dipendenteModifica" id="dipendenteModifica" value={rowSelected.dipendente} disabled/>
                                                            </div>
                                                        </div>
                                                        <div className="div_wrap_25">
                                                            <div className="col_input"><label htmlFor="name-11">Data inizio</label>
                                                                <div data-hover="false" data-delay="0" className="select w-dropdown">
                                                                    <DatePicker
                                                                        onChange={(value) => setRowSelected({...rowSelected, dataInizio: value})}
                                                                        value={rowSelected.dataInizio}
                                                                        //calendarIcon={null}
                                                                        clearIcon={null}
                                                                        className={'form-control'}
                                                                    //disableCalendar={true}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="div_wrap_25">
                                                            <div className="col_input"><label htmlFor="name-11">Data fine</label>
                                                                <div data-hover="false" data-delay="0" className="select w-dropdown">
                                                                    <DatePicker
                                                                        onChange={(value) => setRowSelected({...rowSelected, dataFine: value})}
                                                                        value={rowSelected.dataFine}
                                                                        //calendarIcon={null}
                                                                        clearIcon={null}
                                                                        className={'form-control'}
                                                                    //disableCalendar={true}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="div_wrap_25">
                                                            <div className="col_input col_select">
                                                                <label htmlFor="name">Tipologia</label>
                                                                <div data-hover="false" data-delay="0" className="select w-dropdown">
                                                                    <select className="form-control select" name="tipologia" onChange={e => setRowSelected({...rowSelected, disponibilita: e.target.value})} required>
                                                                        <option selected value={null} disabled >Seleziona tipologia...</option>
                                                                        {disponibilitaJSON.disponibilita.map((el) => (
                                                                            <>
                                                                            {el.codice != 'DI' ?
                                                                            <option selected={el.codice == rowSelected.disponibilita} value={el.codice}>{el.codice} - {el.nome}</option>
                                                                            :
                                                                            ''
                                                                            }
                                                                            </>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="div_wrap_100">
                                                        <div className="div_wrap_25">
                                                            <div className="col_input"><label htmlFor="name-11">N. protocollo</label>
                                                                <input className="input w-input" type="text" value={rowSelected.nProtocollo} name="nProtocollo" id="nProtocollo" onChange={(e) => setRowSelected({...rowSelected, nProtocollo: e.target.value})} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="item_3 m_top_5">
                                        <div className="list_button list_button_2">
                                            <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc3d-b8dac9c0" className="div_item_empty"></div>
                                            <div id="w-node-d27a7e1c-7034-f43d-edeb-5fcab2bbc790-b8dac9c0" className="div_item_empty"></div>
                                            <div id="w-node-d27a7e1c-7034-f43d-edeb-5fcab2bbc790-b8dac9c0" className="div_item_empty"></div>
                                            <div id="w-node-d27a7e1c-7034-f43d-edeb-5fcab2bbc790-b8dac9c0" className="div_item_empty"></div>
                                            <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc40-b8dac9c0" className="div_item_empty"></div>
                                            <div id="w-node-d409c487-9a0c-6b1f-803c-c7c11a8afa3e-b8dac9c0" className="div_item_button">
                                                <a onClick={() => onClickSalvaModificaGiornoEccezione()} className="button w-button">Salva</a>
                                            </div>
                                            <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc43-b8dac9c0" className="div_item_button">
                                                <a id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc44-b8dac9c0" onClick={() => onClickChiudiModificaGiornoEccezione(false)} className="button w-button">Annulla</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
                : openNuovaAssenza ?
                        <div className="div_wrap_50">
                            <div className="div_container">
                                <div className="title_window">
                                    <div className="title_window_p">Nuova assenza</div>
                                </div>
                                <div className="dati_container assenze">
                                    <div className="fun_assegnazione_incarichi">
                                        <div className="row">
                                            <div className="item_2">
                                                <h4>Crea nuova assenza</h4>
                                                <div>
                                                    <div className="w-form">
                                                    <div id="w-node-_98d32d21-f772-2386-6ce9-47c8e0ec064d-867f153d" className="div_item_1">
                                                <div className="w-form">
                                                    <div id="email-form" name="email-form" data-name="Email Form" method="get">
                                                        <label className="w-checkbox checkbox-field">
                                                            <input type="checkbox" id="resti" name="resti" data-name="resti" value={checkVediTuttiDipendenti} checked={!checkVediTuttiDipendenti} className="w-checkbox-input" onChange={e => onChangeSetCheckBoxCessati()} />
                                                            <span className="label_text w-form-label" htmlFor="checkbox">cessati</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                                        <form id="email-form-2" name="email-form-2" data-name="Email Form 2" method="get" aria-label="Email Form 2">
                                                            <div className="div_wrap_100">
                                                                <div className="div_wrap_25">
                                                                    <div className="col_input col_select">
                                                                        <label htmlFor="name">Dipendente</label>
                                                                        <div data-hover="false" data-delay="0" className="select w-dropdown">
                                                                            <select className="form-control select" name="dipendente" onChange={e => onChangeSetDipendente(e)} required>
                                                                                <option selected={dipendenteSelected == null} value={null} disabled >Seleziona dipendente...</option>
                                                                                {dipendentiList.map((dipendente) => (
                                                                                    <option value={dipendente._id} selected={dipendenteSelected == dipendente._id} >{dipendente.cognome} {dipendente.nome}</option>
                                                                                ))}
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="div_wrap_25">
                                                                    <div className="col_input"><label htmlFor="name-11">Data inizio</label>
                                                                        <div data-hover="false" data-delay="0" className="select w-dropdown">
                                                                            <DatePicker
                                                                                onChange={(value) => onChangeSetNuovaDataInput({ target: { value: value, name: 'nuovaDataInizio' } })}
                                                                                value={nuovaDataInizioInput}
                                                                                //calendarIcon={null}
                                                                                clearIcon={null}
                                                                                className={'form-control'}
                                                                            //disableCalendar={true}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="div_wrap_25">
                                                                    <div className="col_input"><label htmlFor="name-11">Data fine</label>
                                                                        <div data-hover="false" data-delay="0" className="select w-dropdown">
                                                                            <DatePicker
                                                                                onChange={(value) => onChangeSetNuovaDataInput({ target: { value: value, name: 'nuovaDataFine' } })}
                                                                                value={nuovaDataFineInput}
                                                                                //calendarIcon={null}
                                                                                clearIcon={null}
                                                                                className={'form-control'}
                                                                            //disableCalendar={true}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="div_wrap_25">
                                                                    <div className="col_input col_select">
                                                                        <label htmlFor="name">Tipologia</label>
                                                                        <div data-hover="false" data-delay="0" className="select w-dropdown">
                                                                            <select className="form-control select" name="tipologia" onChange={e => onChangeSetTipologiaNuovaAssenza(e)} required>
                                                                                <option selected={nuovaTipologiaSelected == null} value={null} disabled >Seleziona tipologia...</option>
                                                                                {disponibilitaJSON.disponibilita.map((el) => (
                                                                                    <option selected={el.codice == nuovaTipologiaSelected} value={el.codice}>{el.codice} - {el.nome}</option>
                                                                                ))}
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="div_wrap_100">
                                                                <div className="div_wrap_25">
                                                                    <div className="col_input"><label htmlFor="name-11">N. protocollo</label>
                                                                        <input className="input w-input" type="text" value={nProtocollo} name="nProtocollo" id="nProtocollo" onChange={(e) => setNProtocollo(e.target.value)} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="item_3 m_top_5">
                                                <div className="list_button list_button_2">
                                                    <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc3d-b8dac9c0" className="div_item_empty"></div>
                                                    <div id="w-node-d27a7e1c-7034-f43d-edeb-5fcab2bbc790-b8dac9c0" className="div_item_empty"></div>
                                                    <div id="w-node-d27a7e1c-7034-f43d-edeb-5fcab2bbc790-b8dac9c0" className="div_item_empty"></div>
                                                    <div id="w-node-d27a7e1c-7034-f43d-edeb-5fcab2bbc790-b8dac9c0" className="div_item_empty"></div>
                                                    <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc40-b8dac9c0" className="div_item_empty"></div>
                                                    <div id="w-node-d409c487-9a0c-6b1f-803c-c7c11a8afa3e-b8dac9c0" className="div_item_button">
                                                        <a onClick={() => onClickAddGiornoEccezione()} className="button w-button">Salva</a>
                                                    </div>
                                                    <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc43-b8dac9c0" className="div_item_button">
                                                        <a id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc44-b8dac9c0" onClick={() => setOpenNuovaAssenza(false)} className="button w-button">Chiudi</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        ''
                    }
                </div>
            </div>
        </div>
    )
}

GiorniEccezione.protoTypes = {
    getGiorniEccezione: PropTypes.func.isRequired,
    getDipendenti: PropTypes.func.isRequired,
    aggiungiGiornoEccezioneLungo: PropTypes.func.isRequired,
    eliminaGiornoEccezioneLungo: PropTypes.func.isRequired,
    confermaGiornoEccezione: PropTypes.func.isRequired,
    confermaGiorniEccezione: PropTypes.func.isRequired,
    cleanGiorniEccezione: PropTypes.func.isRequired,
    modificaGiornoEccezione: PropTypes.func.isRequired,
    getFiliali: PropTypes.func.isRequired,
    dipendente: PropTypes.object.isRequired,
    alert: PropTypes.object.isRequired,
    filiale: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    dipendente: state.dipendente,
    alert: state.alert,
    filiale: state.filiale,
    auth: state.auth
});

export default connect(mapStateToProps, { getGiorniEccezione, getDipendenti, aggiungiGiornoEccezioneLungo, eliminaGiornoEccezioneLungo, confermaGiornoEccezione, confermaGiorniEccezione, cleanGiorniEccezione, modificaGiornoEccezione, getFiliali })(GiorniEccezione);