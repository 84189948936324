import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import DatePicker from 'react-date-picker';
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import addDays from "date-fns/addDays";
import Spinner from '../../layout/Spinner';
import {Helmet} from "react-helmet";
import XlsxPopulate from "xlsx-populate";
import { saveAs } from "file-saver";
import { useReactToPrint } from 'react-to-print';
import { getTuttiPuntiVendita } from '../../../actions/puntoVendita';
import { getClienti } from '../../../actions/cliente';
import { getFilialiOrganizzazione } from '../../../actions/filiale';
import { cleanRichieste, getRichiesteCliente, getRichiestePuntoVendita, getRichiesteFiliale, getTutteRichieste } from '../../../actions/richiesta';

const ReportCliente = ({ auth: { user }, richiesta: { richieste }, cleanRichieste, getTuttiPuntiVendita, puntoVendita: { puntiVendita }, getClienti, cliente: { clienti }, getRichiesteCliente, getRichiesteFiliale, getTutteRichieste, getRichiestePuntoVendita, getFilialiOrganizzazione, filiale: {filiali} }) => {

    const [dataInizioInput, setDataInizioInput] = useState((setHours(setMinutes(startOfMonth(new Date()), 0), 0)));
    const [dataFineInput, setDataFineInput] = useState((setHours(setMinutes(endOfMonth(new Date()), 59), 23)));
    const [elementiCliente, setElementiCliente] = useState([]);
    const [clienteSelected, setClienteSelected] = useState('Tutti');
    const [filialeSelected, setFilialeSelected] = useState('Tutti');
    const [elementiPuntiVendita, setElementiPuntiVendita] = useState([]);
    const [elementiFiliali, setElementiFiliali] = useState([]);
    const [puntoVenditaSelected, setPuntoVenditaSelected] = useState(null);
    const [totaleMinuti, setTotaleMinuti] = useState(0);
    const [nascondiNominativi, setNascondiNominativi] = useState(false);
    const [soloConFatturazione, setSoloConFatturazione] = useState(true);
    const [arrayExcel, setArrayExcel] = useState([]);
    

    const [elementi, setElementi] = useState([]);
    const [loadingRichieste, setLoadingRichieste] = useState(false);
    const [nomeFiliale, setNomeFiliale] = useState("");
    const [idOrganizzazione, setIdOrganizzazione] = useState("");
    const giorniArray = ['Domenica', 'Lunedi', 'Martedi', 'Mercoledi', 'Giovedi', 'Venerdi', 'Sabato'];

    const headers = [
        { label: 'Data', key: 'data' },
        { label: 'Cliente', key: 'cliente' },
        { label: 'Punto di servizio.', key: 'puntoVendita' },
        { label: 'Dipendente', key: 'dipendente' },
        { label: 'Dalle', key: 'oraInizio' },
        { label: 'Alle', key: 'oraFine' },
        { label: 'Ore fatte', key: 'ore' },
    ];


    useEffect(() => {
        if (user && user.filiale && user.filiale.nome) {
            //setNomeFiliale(user.filiale.nome)
            setNomeFiliale('Tutte le filiali')
        }
        if (user && user.organizzazione) {
            setIdOrganizzazione(user.organizzazione._id)
        }
    }, [user]);

    useEffect(() => {
        getTuttiPuntiVendita()
        getClienti()
        getFilialiOrganizzazione()
        cleanRichieste()
        return () => {
            cleanRichieste()
        };
    }, []);

    useEffect(() => {
        setElementiCliente(clienti)
    }, [clienti]);

    useEffect(() => {
        setElementiPuntiVendita(puntiVendita)
    }, [puntiVendita]);

    useEffect(() => {
        setElementiFiliali(filiali)
    }, [filiali]);

    useEffect(() => {
        let array = []
        let arrayPuntiServizio = []
        let totaleMin = 0
        let arrayExcel = []
        if (richieste) {
            let differenzaInGiorni = getDaysBetweenDates(dataInizioInput, dataFineInput)
                for (let i = 0; i <= differenzaInGiorni; i++) {
                    let newData = addDays(new Date(dataInizioInput), i)
                    let giorno = toIsoString(newData);
                    let giornoSettimana = new Date(newData).getDay();
                    array.push({ data: giorno, giornoSettimana: giorniArray[giornoSettimana], richieste: [], giorniEccezione: [], richiestePrestati: [], dipendentiNonAssociati: [] })
                }
                richieste.map(richiesta => {
                    let oreString = getOreBetweenDates(richiesta.oraInizio, richiesta.oraFine)
                    totaleMin = totaleMin + fromStringToMinuti(oreString)
                    let index = arrayPuntiServizio.findIndex(el => {
                        return String(el.puntoVendita._id) == String(richiesta.puntoVendita._id)
                    })
                    let data = toIsoString(new Date(richiesta.dataInizio))
                    let indexGiorno = array.findIndex(a => { return a.data == data })
                    arrayExcel.push({data: data, cliente: richiesta.puntoVendita.cliente.codice + " " + richiesta.puntoVendita.cliente.ragioneSoc, puntoVendita: richiesta.puntoVendita.denominazione, dipendente: richiesta.dipendente.cognome + " " + richiesta.dipendente.nome, oraInizio: richiesta.oraInizio, oraFine: richiesta.oraFine, ore: getOreBetweenDates(richiesta.oraInizio, richiesta.oraFine)})
                    if(indexGiorno > -1) {
                        let giorno = { data: array[indexGiorno].data, giornoSettimana: array[indexGiorno].giornoSettimana, richieste: [], giorniEccezione: [], richiestePrestati: [], dipendentiNonAssociati: [] }
                        if(index >= 0){
                            let indexGiornoInterno = arrayPuntiServizio[index].giorni.findIndex(a => { return a.data == data })
                            if(indexGiornoInterno > -1) {
                                arrayPuntiServizio[index].giorni[indexGiornoInterno].richieste.push(richiesta)
                            }else{
                                giorno.richieste.push(richiesta)
                                arrayPuntiServizio[index].giorni.push(giorno)
                            }
                        }else{
                            let obj = {cliente: richiesta.puntoVendita.cliente, puntoVendita: richiesta.puntoVendita, giorni: []}
                            giorno.richieste.push(richiesta)
                            obj.giorni.push(giorno)
                            arrayPuntiServizio.push(obj)
                        }
                    }
                })     
                arrayExcel.push({data: "TOTALE", cliente: "", puntoVendita: "", dipendente: "", oraInizio: "", oraFine: "", ore: totaleMin})
        }
        setArrayExcel(arrayExcel)
        setElementi(arrayPuntiServizio)
        setTotaleMinuti(totaleMin)
        setLoadingRichieste(false)
    }, [richieste]);

    async function saveAsExcel(data, header) {

        XlsxPopulate.fromBlankAsync().then(async (workbook) => {
            const sheet1 = workbook.sheet(0);
            const sheetData = getSheetData(data, header);
            const totalColumns = sheetData[0].length;

            sheet1.cell("A1").value(sheetData);
            const range = sheet1.usedRange();
            const endColumn = String.fromCharCode(64 + totalColumns);
            sheet1.row(1).style("bold", true);
            sheet1.range("A1:" + endColumn + "1").style("fill", "BFBFBF");
            range.style("border", true);
            return workbook.outputAsync().then((res) => {
                saveAs(res, "file.xlsx");
            });
        });
    }

    function getSheetData(data, header) {
        var fields = Object.keys(data[0]);
        var sheetData = data.map(function (row) {
            return fields.map(function (fieldName) {
                return row[fieldName] ? row[fieldName] : "";
            });
        });
        const headerLabel = header.map(function (row) { return row.label })
        sheetData.unshift(headerLabel);
        return sheetData;
    }

    const convertMsToDays = ms => {
        const msInOneSecond = 1000
        const secondsInOneMinute = 60
        const minutesInOneHour = 60
        const hoursInOneDay = 24

        const minutesInOneDay = hoursInOneDay * minutesInOneHour
        const secondsInOneDay = secondsInOneMinute * minutesInOneDay
        const msInOneDay = msInOneSecond * secondsInOneDay

        return Math.ceil(ms / msInOneDay)
    }

    const getDaysBetweenDates = (dateOne, dateTwo) => {
        let differenceInMs = dateTwo.getTime() - dateOne.getTime()

        if (differenceInMs < 0) {
            differenceInMs = dateOne.getTime() - dateTwo.getTime()
        }

        return convertMsToDays(differenceInMs)
    }

    const toIsoString = (date) => {
        const pad = function (num) {
            return (num < 10 ? '0' : '') + num;
        };

        return pad(date.getDate()) +
            '-' + pad(date.getMonth() + 1) +
            '-' + date.getFullYear()
    }

    const fromStringToMinuti = (oreString) => {
        var minuti = getMinutiInt(oreString);
        var ore = getOreInt(oreString) * 60
        return ore + minuti
    }

    const getOreInt = (ore) => {
        if (ore && ore.length > 0) {
            const split = ore.split(':');
            return parseInt(split[0])
        } else {
            return 0
        }
    }

    const getMinutiInt = (ore) => {
        if (ore && ore.length > 0) {
            const split = ore.split(':');
            return parseFloat(split[1])
        } else {
            return 0
        }
    }

    //FUNZIONI ONCLICK

    const onClickGeneraReport = () => {
        if(clienteSelected !== 'Tutti'){
            if(puntoVenditaSelected !== 'Tutti'){
                getRichiestePuntoVendita({idPuntoVendita: puntoVenditaSelected, dataInizio: dataInizioInput, dataFine: dataFineInput})
            }else{
                getRichiesteCliente({idCliente: clienteSelected, dataInizio: dataInizioInput, dataFine: dataFineInput, fatturazione: soloConFatturazione})
            }
            setLoadingRichieste(true)
        }else{
            if(filialeSelected !== 'Tutti'){
                getRichiesteFiliale({idFiliale: filialeSelected, dataInizio: dataInizioInput, dataFine: dataFineInput, fatturazione: soloConFatturazione})
            }else{
                getTutteRichieste({dataInizio: dataInizioInput, dataFine: dataFineInput, fatturazione: soloConFatturazione})
            }
            setLoadingRichieste(true)
        }
    }
    
    //FUNZIONI ONCHANGE

    //*
    const onChangeRangeDateInizio = (data) => {
        setDataInizioInput(data)
        setDataFineInput(setHours(setMinutes(endOfMonth(data), 59), 23))
    }

    //*
    const onChangeRangeDateFine = (data) => {
        setDataFineInput(data)
    }

    const onChangeSetCliente = (id) => {
        setClienteSelected(id)
        let array = [...puntiVendita]
        if(filialeSelected !== 'Tutti'){
            array = puntiVendita.filter( el => {return el.filiale == filialeSelected})
        }
        if(id !== 'Tutti'){
            array = array.filter( el => {return el.cliente._id == id})
        }
        if(soloConFatturazione){
            array = array.filter( el => {return el.cliente.fatturazione == true})
        }
        setPuntoVenditaSelected('Tutti')
        setElementiPuntiVendita(array)
    }

    const onChangeSetPuntoVendita = (id) => {
        setPuntoVenditaSelected(id)
    }

    const onChangeSetFiliale = (id) => {
        setFilialeSelected(id)
        let array = [...puntiVendita]
        let arrayClienti = [...clienti]
        if(id !== 'Tutti'){
            array = puntiVendita.filter( el => {return el.filiale == id})
            arrayClienti = clienti.filter( el => {return el.filiale == id})
            let indexFiliale = filiali.findIndex(el => el._id == id)
            if(indexFiliale >= 0){
                setNomeFiliale(filiali[indexFiliale].nome)
            }
        }else{
            setNomeFiliale('Tutte le filiali')
        }
        if(soloConFatturazione){
            arrayClienti = arrayClienti.filter( el => {return el.fatturazione == true})
            array = array.filter( el => {return el.cliente.fatturazione == true})
        }
        setClienteSelected('Tutti')
        setPuntoVenditaSelected('Tutti')
        setElementiPuntiVendita(array)
        setElementiCliente(arrayClienti)
    }

    const onChangeSetCheckboxNascondiNominativi = (e) => {
        setNascondiNominativi(!nascondiNominativi)
    }

    const onChangeSetCheckboxSoloConFatturazione = (e) => {
        let array = [...puntiVendita]
        let arrayClienti = [...clienti]
        if(filialeSelected !== 'Tutti'){
            array = puntiVendita.filter( el => {return el.filiale == filialeSelected})
            arrayClienti = clienti.filter( el => {return el.filiale == filialeSelected})
        }
        if(!soloConFatturazione){
            arrayClienti = arrayClienti.filter( el => {return el.fatturazione == true})
            array = array.filter( el => {return el.cliente.fatturazione == true})
        }
        setClienteSelected('Tutti')
        setPuntoVenditaSelected('Tutti')
        setElementiPuntiVendita(array)
        setElementiCliente(arrayClienti)
        setSoloConFatturazione(!soloConFatturazione)

    }

    const getLogo = (idOrganizzazione) => {
        if (idOrganizzazione == '63e618049a5309e9f6f3ff9a') {
            return 'logo_emilpol.jpg'
        } else if (idOrganizzazione == '63e618279a5309e9f6f3ff9b') {
            return 'new_koop.png'
        } else {
            return 'logo_vigili_dell_ordine.png'
        }
    }


    const formattaOre = (ore) => {
        return (ore <= 9 ? "0" : "") + ore
    }

    const formattaMinuti = (minuti) => {
        return (minuti <= 9 ? "0" : "") + minuti;
    }

    const getOreBetweenDates = (start, end) => {
        start = start.split(":");
        end = end.split(":");
        var startDate = new Date(0, 0, 0, start[0], start[1], 0);
        var endDate = new Date(0, 0, 0, end[0], end[1], 0);
        var diff = endDate.getTime() - startDate.getTime();
        var hours = Math.floor(diff / 1000 / 60 / 60);
        diff -= hours * 1000 * 60 * 60;
        var minutes = Math.floor(diff / 1000 / 60);
        if (hours < 0)
            hours = hours + 24;
        if (isNaN(hours) || isNaN(minutes)) {
            return ""
        }
        return (hours <= 9 ? "0" : "") + hours + ":" + (minutes <= 9 ? "0" : "") + minutes;
    }

    const fromMinutiToOreString = (minutiTotali) => {
        if (minutiTotali > 0) {
            var minuti = minutiTotali % 60;
            var ore = (minutiTotali - minuti) / 60;
            if (ore == 0 && minuti == 0) {
                return "00:00"
            } else {
                return formattaOre(ore) + ":" + formattaMinuti(minuti)
            }
        } else {
            return "00:00"
        }
    }

    const tableStyle = {
        width: "100%",
        border: "1px solid"
    };



    const tableInt1 = {
        fontSize: "12px",
        lineHeight: "1.3",
        background: "#8ebde6"
    };

    const tableStyle2 = {
        textAlign: "left",
        border: "1px solid",
        width: "6%",
        padding: "2px 2px",
        wordBreak: "break-all"
    }

    const tableStyle1 = {
        textAlign: "left",
        border: "1px solid",
        width: "30%",
        padding: "2px 2px"
    }

    const tableStyle3 = {
        textAlign: "left",
        border: "1px solid",
        width: "10%",
        padding: "2px 2px"
    }

    const componentRef = useRef();

    const print = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: "giornate"
    });

    return (
        <div className="body wf-section">
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`Report clienti - Gestionale Vigili dell'ordine`}</title>
            </Helmet>
            <div className="div_wrap">
                <div className="div_wrap_100">
                    <div className="div_wrap_inner_100">
                        <div className="div_container">
                            <div className="title_window">
                                <div className="title_window_p">Genera report</div>
                            </div>
                            <div className="dati_container">
                                <div className="fun_quadrature_ore">
                                    <div className="row row_datapicker">
                                        <div className="item_1 filtro_wrap_incarichi">
                                            <div id="w-node-_284812ee-7924-9f38-86bb-8d2cc43dff3c-867f153d" className="div_item_1">
                                                <div id="w-node-_80bb2acb-3043-3649-0498-379a652f3b04-867f153d" className="label_text">Data inizio</div>
                                                <div data-hover="false" data-delay="0" className="select w-dropdown">
                                                    <DatePicker
                                                        value={dataInizioInput}
                                                        format="dd/MM/y"
                                                        weekPicker
                                                        onChange={onChangeRangeDateInizio}
                                                        locale="it-IT"
                                                        clearIcon={null}
                                                        weekStartDayIndex={1}
                                                    />
                                                </div>
                                            </div>
                                            <div id="w-node-_284812ee-7924-9f38-86bb-8d2cc43dff3c-867f153d" className="div_item_1">
                                                <div id="w-node-_80bb2acb-3043-3649-0498-379a652f3b04-867f153d" className="label_text">Data fine</div>
                                                <div data-hover="false" data-delay="0" className="select w-dropdown">
                                                    <DatePicker
                                                        value={dataFineInput}
                                                        format="dd/MM/y"
                                                        weekPicker
                                                        onChange={onChangeRangeDateFine}
                                                        locale="it-IT"
                                                        clearIcon={null}
                                                        weekStartDayIndex={1}
                                                    />
                                                </div>
                                            </div>
                                            <div id="w-node-_284812ee-7924-9f38-86bb-8d2cc43dff3c-867f153d" className="div_item_1">
                                                <div className="col_input col_select">
                                                <div id="w-node-_80bb2acb-3043-3649-0498-379a652f3b04-867f153d" className="label_text">Filiale</div>
                                                    <div data-hover="false" data-delay="0" className="select w-dropdown">
                                                        <select className="form-control select" name="idCliente" onChange={e => onChangeSetFiliale(e.target.value)} required>
                                                        <option selected={'Tutti' == filialeSelected} value={'Tutti'} >Tutti</option>
                                                            {elementiFiliali.map((elementoFiliale, i) => (
                                                                <option key={i} value={elementoFiliale._id} selected={elementoFiliale._id == filialeSelected} >{elementoFiliale.nome}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id="w-node-_284812ee-7924-9f38-86bb-8d2cc43dff3c-867f153d" className="div_item_1">
                                                <div className="col_input col_select">
                                                <div id="w-node-_80bb2acb-3043-3649-0498-379a652f3b04-867f153d" className="label_text">Cliente</div>
                                                    <div data-hover="false" data-delay="0" className="select w-dropdown">
                                                        <select className="form-control select" name="idCliente" onChange={e => onChangeSetCliente(e.target.value)} required>
                                                        <option selected value={null} disabled >Seleziona cliente...</option>
                                                        <option value={'Tutti'} selected={'Tutti' == clienteSelected}>Tutti</option>
                                                            {elementiCliente.map((cliente, i) => (
                                                                <option key={i} value={cliente._id} selected={cliente._id == clienteSelected} >{cliente.ragioneSoc}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id="w-node-_284812ee-7924-9f38-86bb-8d2cc43dff3c-867f153d" className="div_item_1">
                                                <div className="col_input col_select">
                                                <div id="w-node-_80bb2acb-3043-3649-0498-379a652f3b04-867f153d" className="label_text">Punto di servizio</div>
                                                    <div data-hover="false" data-delay="0" className="select w-dropdown">
                                                        <select className="form-control select" name="idCliente" onChange={e => onChangeSetPuntoVendita(e.target.value)} required>
                                                        <option selected={'Tutti' == clienteSelected} value={'Tutti'}>Tutti</option>
                                                            {elementiPuntiVendita.map((elementoPuntoVendita, i) => (
                                                                <option key={i} value={elementoPuntoVendita._id} selected={elementoPuntoVendita._id == clienteSelected} >{elementoPuntoVendita.denominazione}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row row_datapicker">
                                        <div className="item_1 filtro_wrap_incarichi">
                                            <div id="w-node-_714e71a9-0550-fe0e-26e9-8b45ac42ab89-867f153d" className="div_item_1">
                                                <div className="w-form">
                                                    <form id="email-form" name="email-form" data-name="Email Form" method="get">
                                                        <label className="w-checkbox checkbox-field"><span className="label_text w-form-label" htmlFor="checkbox-2"></span></label>
                                                        <a id="w-node-_714e71a9-0550-fe0e-26e9-8b45ac42ab90-867f153d" onClick={() => { return elementiPuntiVendita.length > 0 && clienteSelected ? onClickGeneraReport() : null }} className={`${elementiPuntiVendita.length > 0 && clienteSelected ? '' : 'button_disabled ' }button w-button`}>Genera report</a>
                                                    </form>
                                                </div>
                                            </div>
                                            <div id="w-node-_714e71a9-0550-fe0e-26e9-8b45ac42ab89-867f153d" className="div_item_1">
                                                <div className="w-form">
                                                    <form id="email-form" name="email-form" data-name="Email Form" method="get">
                                                        <label className="w-checkbox checkbox-field"><span className="label_text w-form-label" htmlFor="checkbox-2"></span></label>
                                                        <a id="w-node-_714e71a9-0550-fe0e-26e9-8b45ac42ab90-867f153d" onClick={print} className="button w-button">Stampa</a>
                                                    </form>
                                                </div>
                                            </div>
                                            <div id="w-node-_714e71a9-0550-fe0e-26e9-8b45ac42ab89-867f153d" className="div_item_1">
                                                <div className="w-form">
                                                    <form id="email-form" name="email-form" data-name="Email Form" method="get">
                                                        <label className="w-checkbox checkbox-field"><span className="label_text w-form-label" htmlFor="checkbox-2"></span></label>
                                                        <a id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98e-867f153d" className={`button w-button ${arrayExcel.length == 0 ? "button_disabled" : ""}`} onClick={(e) => arrayExcel.length == 0 ? null : saveAsExcel(arrayExcel, headers)}>Scarica XLS</a>
                                                    </form>
                                                </div>
                                            </div>
                                            <div id="w-node-_714e71a9-0550-fe0e-26e9-8b45ac42ab89-867f153d" className="div_item_1">
                                                <div className="w-form">
                                                    <form id="email-form" name="email-form" data-name="Email Form" method="get">
                                                        <label className="w-checkbox checkbox-field"><span className="label_text w-form-label" htmlFor="checkbox-2"></span></label>
                                                        <label className="w-radio">
                                                            <input type="checkbox" data-name="Radio 2" id="radio-2" name="radio" value={nascondiNominativi} className="w-form-formradioinput w-radio-input" onChange={e => onChangeSetCheckboxNascondiNominativi(e)} checked={nascondiNominativi} />
                                                            <span className="label_text w-form-label" htmlFor="radio-2">Nascondi nominativi</span>
                                                        </label>                                                    
                                                    </form>
                                                </div>
                                            </div>
                                            <div id="w-node-_714e71a9-0550-fe0e-26e9-8b45ac42ab89-867f153d" className="div_item_1">
                                                <div className="w-form">
                                                    <form id="email-form" name="email-form" data-name="Email Form" method="get">
                                                        <label className="w-checkbox checkbox-field"><span className="label_text w-form-label" htmlFor="checkbox-2"></span></label>
                                                        <label className="w-radio">
                                                            <input type="checkbox" data-name="Radio 2" id="radio-2" name="radio" value={soloConFatturazione} className="w-form-formradioinput w-radio-input" onChange={e => onChangeSetCheckboxSoloConFatturazione(e)} checked={soloConFatturazione} />
                                                            <span className="label_text w-form-label" htmlFor="radio-2">Solo con fatturazione</span>
                                                        </label>                                                    
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {loadingRichieste ? <Spinner /> :
                                    <div>
                                        <div className="div_container" ref={componentRef}>
                                            <div className="dati_container">
                                            <div className="page">
                                                    <div className="div_wrap_100">
                                                        <div className="div_wrap_50">
                                                            <img src={`assets/media/logos/${getLogo(idOrganizzazione)}`} loading="lazy" alt="" className="logo_header" />
                                                            <h2>{nomeFiliale}</h2>
                                                        </div>
                                                    </div>
                                                {elementi.map(puntoVendita => (
                                                    <>
                                                    <h3>{puntoVendita.cliente.codice} - {puntoVendita.cliente.ragioneSoc} - {puntoVendita.puntoVendita.denominazione}</h3>
                                                    {puntoVendita.giorni.map(elemento => (
                                                    <div className='not_break_inside'>
                                                    <h4>{elemento.giornoSettimana} - {elemento.data}</h4>
                                                    <table style={tableStyle}>
                                                        <thead>
                                                            <tr style={tableInt1}>
                                                                <th style={tableStyle1}>Servizio</th>
                                                                <th style={tableStyle1}>Dipendente</th>
                                                                <th style={tableStyle3}>Ora Inizio</th>
                                                                <th style={tableStyle3}>Ora Fine</th>
                                                                <th style={tableStyle3}>Ore</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {elemento.richieste.map(el => (
                                                                <tr style={tableStyle2}>
                                                                    <td style={tableStyle1}>{el.puntoVendita.denominazione} {el.puntoVendita.localita}</td>
                                                                    {nascondiNominativi ? 
                                                                        <td style={tableStyle1}></td>
                                                                        :
                                                                        <td style={tableStyle1}>{el.dipendente.cognome} {el.dipendente.nome}</td>
                                                                    }
                                                                    <td style={tableStyle3}>{el.oraInizio}</td>
                                                                    <td style={tableStyle3}>{el.oraFine}</td>
                                                                    <td style={tableStyle3}>{getOreBetweenDates(el.oraInizio, el.oraFine)}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                    </div>
                                                ))}
                                                    <div class="pagebreak mb-100"> </div>
                                                    </>
                                                ))}
                                                    <div className="div_wrap_100">
                                                        <div className="div_wrap_25">
                                                            <span></span>
                                                        </div>
                                                        <div className="div_wrap_25">
                                                            <span></span>
                                                        </div>
                                                        <div className="div_wrap_25">
                                                            <span></span>
                                                        </div>
                                                        <div className="div_wrap_25">
                                                            <h3>Totale ore: {fromMinutiToOreString(totaleMinuti)}</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

ReportCliente.protoTypes = {
    cleanRichieste: PropTypes.func.isRequired,
    getTuttiPuntiVendita: PropTypes.func.isRequired,
    getClientigetClienti: PropTypes.func.isRequired,
    getRichiesteCliente: PropTypes.func.isRequired, 
    getRichiesteFiliale: PropTypes.func.isRequired,
    getTutteRichieste: PropTypes.func.isRequired,
    getRichiestePuntoVendita: PropTypes.func.isRequired,
    getFilialiOrganizzazione: PropTypes.func.isRequired,
    richiesta: PropTypes.object.isRequired,
    filiale: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    puntoVendita: PropTypes.object.isRequired,
    cliente: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    richiesta: state.richiesta,
    filiale: state.filiale,
    puntoVendita: state.puntoVendita,
    auth: state.auth,
    cliente: state.cliente
});

export default connect(mapStateToProps, { cleanRichieste, getTuttiPuntiVendita, getClienti, getRichiesteCliente, getRichiesteFiliale, getTutteRichieste, getRichiestePuntoVendita, getFilialiOrganizzazione })(ReportCliente);