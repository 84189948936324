import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import documentoIMG from "../../../img/documento.png"
import cestinoIMG from "../../../img/trash.png"
import DatePicker from 'react-date-picker';



const DocumentoVisitaMedica = ({ id, url, nomeDocumento, getIndexDocumento, pulisciFileVisitaMedica, onChangeUploadFile, dataRilascio, onChangeSetDataRilascioVisitaMedica, onChangeSetScadenzaVisitaMedica, scadenza, eliminaVisitaMedica, archiviato, onChangeSetArchiviatoVisitaMedica }) => {

    return (
        <div className='mb-30'>
            <div className="div_wrap_100">
                {url ?
                    <div className="div_wrap_25">
                        <div className="col_input">
                            <label htmlFor="tagliaPantaloni">File</label>
                            <div className='item_doc_dip'>
                                <span><a target="_blank" href={url}><img className='doc_dip_img' src={documentoIMG}></img></a><img onClick={() => pulisciFileVisitaMedica(id)} className='doc_dip_img' src={cestinoIMG}></img></span>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="div_wrap_25">
                        <div className="col_input">
                            <label htmlFor="tagliaPantaloni">File</label>
                            <div className='item_doc_dip'>
                                <input type="file" className="input w-input" name={nomeDocumento} data-name={nomeDocumento} id={nomeDocumento} onChange={(e) => onChangeUploadFile(e, id, "documento-visita-medica")} />
                            </div>
                        </div>
                    </div>
                }
                <div className="div_wrap_25">
                    <div className="col_input"><label htmlFor="name-11">Data rilascio</label>
                        <div data-hover="false" data-delay="0" className="select w-dropdown select_data">
                            <DatePicker
                                onChange={(value) => onChangeSetDataRilascioVisitaMedica(value, id)}
                                value={dataRilascio}
                                clearIcon={null}
                                className={'form-control'}
                                disableCalendar={false}
                            />
                        </div>
                    </div>
                </div>
                <div className="div_wrap_25">
                        <div className="col_input">
                            <label htmlFor="tagliaPantaloni">Scadenza (mesi)</label>
                            <div className='item_doc_dip'>
                                <input type="number" className="input w-input" name={scadenza} data-name={scadenza} value={scadenza} onChange={e => onChangeSetScadenzaVisitaMedica(e.target.value, id)} required />
                            </div>
                        </div>
                    </div>
                <div className="div_wrap_25">
                    <div className="col_input">
                    <label htmlFor="partTime">Elimina</label>
                        <div id="w-node-d409c487-9a0c-6b1f-803c-c7c11a8afa3e-b8dac9c0" onClick={() => eliminaVisitaMedica(id)} class="div_item_button"><a class="button w-button">elimina</a></div>
                    </div>
                </div>
            </div>
            <div className='div_wrap_100'>
                <div className="div_wrap_25">
                    <div className="col_input">
                        <label htmlFor="partTime">Archiviato</label>
                        <label className="w-checkbox checkbox-field m_right_10">
                            <input type="checkbox" id={"archiviato"} className="w-checkbox-input" name="partTime" checked={archiviato} value={archiviato} onChange={e => onChangeSetArchiviatoVisitaMedica(e.target.value, id)} />
                            <span className="label_text w-form-label" htmlFor="checkbox-3">Si</span>
                        </label>
                    </div>
                </div>
            </div>
        </div>
    )
}
DocumentoVisitaMedica.protoTypes = {
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, {})(DocumentoVisitaMedica);

