import axios from 'axios';
import { GET_FILIALE, GET_FILIALI, CREA_FILIALE, MODIFICA_FILIALE, GET_INCARICHI_FILIALE, GET_FILIALE_ORGANIZZAZIONE, FILIALE_ERROR } from './types';
import { setAlert } from './alert';


export const getFiliali = () => async dispatch => {
    try{
        const res = await axios.get('/api/filiale/filiali');
        dispatch({
            type: GET_FILIALI,
            payload: res.data
        });
    }catch(err){
        dispatch({
            type: FILIALE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const getFiliale = (idFiliale) => async dispatch => {
    try{
        const res = await axios.get(`/api/filiale/filiale/${idFiliale}`);
        dispatch({
            type: GET_FILIALE,
            payload: res.data
        });
    }catch(err){
        dispatch({
            type: FILIALE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};


export const creaNuovaFiliale = ({ idOrganizzazione, nome, indirizzo }) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ idOrganizzazione, nome, indirizzo });
    try{
        const res = await axios.post('/api/filiale/nuovaFiliale', body, config);
        dispatch({
            type: CREA_FILIALE,
            payload: res.data
        });
        dispatch(setAlert('Nuova Filiale creata!', 'success'));

    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        
        dispatch({
            type: FILIALE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};


export const modificaFiliale = (idFiliale, nome, indirizzo) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ 
        idFiliale: idFiliale,
        nome: nome,
        indirizzo: indirizzo
        }); 
    try{
        const res = await axios.post('/api/filiale/modificaFiliale', body, config);
        dispatch(setAlert('FIliale modificata con successo!', 'success'));
        dispatch({
            type: MODIFICA_FILIALE,
            payload: res.data
        });
    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        dispatch({
            type: FILIALE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const getIncarichiFiliale = ({dataInizio, dataFine}) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({
        dataInizio,
        dataFine
        }); 
    try{
        const res = await axios.post('/api/filiale/incarichiFiliale', body, config);
        dispatch({
            type: GET_INCARICHI_FILIALE,
            payload: res.data
        });
    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        dispatch({
            type: FILIALE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const getFilialiOrganizzazione = () => async dispatch => {
    try{
        const res = await axios.get(`/api/filiale/getFilialiOrganizzazione`);
        dispatch({
            type: GET_FILIALE_ORGANIZZAZIONE,
            payload: res.data
        });
    }catch(err){
        dispatch({
            type: FILIALE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};