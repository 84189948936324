import React from 'react';
import Spinner from '../layout/Spinner';

const DashboardVuota = () => {

    return (
        <div className="body wf-section">
            <Spinner />
        </div>
    )
};


export default DashboardVuota;