import axios from 'axios';
import { GET_RICHIESTA, GET_RICHIESTE, GET_RICHIESTE_CLIENTE, GET_RICHIESTE_FILIALE, GET_TUTTE_RICHIESTE, GET_RICHIESTE_PUNTO_VENDITA, GET_RICHIESTE_ARCHIVIATE, GET_RICHIESTE_CALENDARIO, GET_RICHIESTE_STAMPA_GIORNATE, FILTRA_RICHIESTE, FILTRA_RICHIESTE_ARCHIVIATE, FILTRA_RICHIESTE_DIPENDENTE, FILTRA_RICHIESTE_PUNTOVENDITA, FILTRA_RICHIESTE_CLIENTE, ARCHIVIA_RICHIESTE, ANNULLA_ARCHIVIA_RICHIESTE, CREA_RICHIESTA_SUCCESS, MODIFICA_RICHIESTA, MODIFICA_ORARIO_RICHIESTA, ARCHIVIA_RICHIESTA, PROGRAMMA_TUTTI_TURNI, PROGRAMMA_PROSSIMO_TURNO, ASSOCIA_DIPENDENTE_RICHIESTA, DUPLICA_SERVIZIO, DISSOCIA_DIPENDENTE_RICHIESTA, NASCONDI_CONTRATTO, RIPRISTINA_CONTRATTO_NASCOSTO, RIPRISTINA_TUTTI_TURNI, ANNULLA_RICHIESTA_ARCHIVIATA, LOADING_RICHIESTE, GET_CAMPI_RICHIESTA_MASSIVA, PROGRAMMA_TUTTI_TURNI_JOB, ADD_DIPENDENTE_RICHIESTE, DELETE_DIPENDENTE_RICHIESTE, CLEAN_RICHIESTA, CLEAN_CALENDARIO, CLEAN_RICHIESTE, CLEAN_RICHIESTE_ARCHIVIATE, RICHIESTA_ERROR, GET_CALENDARIO_CONTRATTI, GET_DIPENDENTI_PRESTABILI_OCCUPATI, GET_QUADRATURA_ORE, AGGIUNGI_GIORNO_ECCEZIONE_LUNGO, ELIMINA_RICHIESTA_ANOMALA } from './types';
import { setAlert } from './alert';


export const getRichieste = () => async dispatch => {
    try{
        const res = await axios.get('/api/richiesta/richieste');
        dispatch({
            type: GET_RICHIESTE,
            payload: res.data
        });
    }catch(err){
        dispatch({
            type: RICHIESTA_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const getRichiesteArchiviate = ({ idCliente: idCliente, dataInizio: dataInput }) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ idCliente: idCliente, dataInizio: dataInput });
    try{
        const res = await axios.post('/api/richiesta/richiesteArchiviate', body, config);
        dispatch({
            type: GET_RICHIESTE_ARCHIVIATE,
            payload: res.data
        });
    }catch(err){
        dispatch({
            type: RICHIESTA_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const getRichiesteCliente = ({idCliente, dataInizio, dataFine, fatturazione}) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ 
        idCliente: idCliente,
        dataInizio: dataInizio,
        dataFine: dataFine,
        fatturazione: fatturazione
    });
    try{
        const res = await axios.post('/api/richiesta/getRichiesteCliente', body, config);
            dispatch({
                type: GET_RICHIESTE_CLIENTE,
                payload: res.data
            });
    }catch(err){
        dispatch({
            type: RICHIESTA_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const getRichiesteFiliale = ({idFiliale, dataInizio, dataFine, fatturazione}) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ 
        idFiliale: idFiliale,
        dataInizio: dataInizio,
        dataFine: dataFine,
        fatturazione: fatturazione
    });
    try{
        const res = await axios.post('/api/richiesta/getRichiesteFiliale', body, config);
            dispatch({
                type: GET_RICHIESTE_FILIALE,
                payload: res.data
            });
    }catch(err){
        dispatch({
            type: RICHIESTA_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const getTutteRichieste = ({dataInizio, dataFine, fatturazione}) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ 
        dataInizio: dataInizio,
        dataFine: dataFine,
        fatturazione: fatturazione
    });
    try{
        const res = await axios.post('/api/richiesta/getTutteRichieste', body, config);
            dispatch({
                type: GET_TUTTE_RICHIESTE,
                payload: res.data
            });
    }catch(err){
        dispatch({
            type: RICHIESTA_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};


export const getRichiestePuntoVendita = ({idPuntoVendita, dataInizio, dataFine}) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ 
        idPuntoVendita: idPuntoVendita,
        dataInizio: dataInizio,
        dataFine: dataFine
    });
    try{
        const res = await axios.post('/api/richiesta/getRichiestePuntoVendita', body, config);
            dispatch({
                type: GET_RICHIESTE_PUNTO_VENDITA,
                payload: res.data
            });
    }catch(err){
        dispatch({
            type: RICHIESTA_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const getRichiesteCalendario = (mese) => async dispatch => {
    try{
        const res = await axios.get(`/api/richiesta/richiesteCalendario/${mese}`);
        dispatch({
            type: GET_RICHIESTE_CALENDARIO,
            payload: res.data
        });
    }catch(err){
        dispatch({
            type: RICHIESTA_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const filtraRichieste = (idPuntoVendita, parola, page, pagination, stato) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ 
        idPuntoVendita: idPuntoVendita,
        parola: parola,
        page: page,
        pagination: pagination,
        stato: stato
    });
    try{
        const res = await axios.post('/api/richiesta/filtraRichieste', body, config);
            dispatch({
                type: FILTRA_RICHIESTE,
                payload: res.data
            });
    }catch(err){
        dispatch({
            type: RICHIESTA_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const filtraRichiesteArchiviate = (idPuntoVendita, parola, page, pagination) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ 
        idPuntoVendita: idPuntoVendita,
        parola: parola,
        page: page,
        pagination: pagination
    });
    try{
        const res = await axios.post('/api/richiesta/filtraRichiesteArchiviate', body, config);
            dispatch({
                type: FILTRA_RICHIESTE_ARCHIVIATE,
                payload: res.data
            });
    }catch(err){
        dispatch({
            type: RICHIESTA_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const getRichiesta = (idRichiesta) => async dispatch => {
    try{
        const res = await axios.get(`/api/richiesta/richiesta/${idRichiesta}`);
        dispatch({
            type: GET_RICHIESTA,
            payload: res.data
        });
    }catch(err){
        dispatch({
            type: RICHIESTA_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};


export const annullaRichiestaArchiviata = (idRichiesta) => async dispatch => {
    try{
        const res = await axios.get(`/api/richiesta/annullaRichiestaArchiviata/${idRichiesta}`);
        dispatch({
            type: ANNULLA_RICHIESTA_ARCHIVIATA,
            payload: res.data
        });
        dispatch(setAlert('Richiesta archiviata annullata!', 'success'));
    }catch(err){
        dispatch({
            type: RICHIESTA_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const creaNuovaRichiesta = ({ idPuntoVendita, dataInizio, dataFine, page, pagination, parola, idPuntoVenditaFilter, tipologiaRichiesta, gpg }) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ idPuntoVendita, dataInizio, dataFine, page, pagination, parola, idPuntoVenditaFilter, tipologiaRichiesta, gpg });
    try{
        const res = await axios.post('/api/richiesta/NuovaRichiesta', body, config);
        dispatch({
            type: CREA_RICHIESTA_SUCCESS,
            payload: res.data
        });
        dispatch(setAlert('Nuovo Richiesta creata!', 'success'));

    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        dispatch({
            type: RICHIESTA_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const creaNuovaRichiestaMassiva = ({ campiRichieste, page, pagination, parola, idPuntoVenditaFilter }) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ campiRichieste, page, pagination, parola, idPuntoVenditaFilter });
    try{
        const res = await axios.post('/api/richiesta/NuovaRichiestaMassiva', body, config);
        dispatch({
            type: CREA_RICHIESTA_SUCCESS,
            payload: res.data
        });
        dispatch(setAlert('Nuove Richieste create!', 'success'));

    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        dispatch({
            type: RICHIESTA_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const modificaRichiesta = (idRichiesta, dataInizio, dataFine, idDipendente, tipologiaRichiesta, gpg, distanzaDipendente ) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ 
        idRichiesta: idRichiesta,
        dataInizio: dataInizio,
        dataFine: dataFine,
        idDipendente: idDipendente,
        tipologiaRichiesta: tipologiaRichiesta,
        gpg: gpg,
        distanzaDipendente: distanzaDipendente
        }); 
    try{
        const res = await axios.post('/api/richiesta/modificaRichiesta', body, config);
        dispatch(setAlert('Richiesta modificata con successo!', 'success'));
        dispatch({
            type: MODIFICA_RICHIESTA,
            payload: res.data
        });
    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        dispatch({
            type: RICHIESTA_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};


export const filtraRichiesteDipendente = (idDipendente, statoRichiesta, idPuntoVendita, page, pagination, dataInizio, dataFine) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ 
        idPuntoVendita: idPuntoVendita,
        statoRichiesta: statoRichiesta,
        idDipendente: idDipendente,
        page: page,
        pagination: pagination,
        dataInizio: dataInizio,
        dataFine: dataFine
    });
    try{
        const res = await axios.post('/api/richiesta/filtraRichiesteDipendente', body, config);
            dispatch({
                type: FILTRA_RICHIESTE_DIPENDENTE,
                payload: res.data
            });
    }catch(err){
        dispatch({
            type: RICHIESTA_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const filtraRichiestePuntoVendita = (idPuntoVendita, statoRichiesta, page, pagination, dataInizio, dataFine) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ 
        idPuntoVendita: idPuntoVendita,
        statoRichiesta: statoRichiesta,
        page: page,
        pagination: pagination,
        dataInizio: dataInizio,
        dataFine: dataFine
    });
    try{
        const res = await axios.post('/api/richiesta/filtraRichiestePuntoVendita', body, config);
            dispatch({
                type: FILTRA_RICHIESTE_PUNTOVENDITA,
                payload: res.data
            });
    }catch(err){
        dispatch({
            type: RICHIESTA_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const filtraRichiesteCliente = (idCliente, statoRichiesta, page, pagination, dataInizio, dataFine) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ 
        idCliente: idCliente,
        statoRichiesta: statoRichiesta,
        page: page,
        pagination: pagination,
        dataInizio: dataInizio,
        dataFine: dataFine
    });
    try{
        const res = await axios.post('/api/richiesta/filtraRichiesteCliente', body, config);
            dispatch({
                type: FILTRA_RICHIESTE_CLIENTE,
                payload: res.data
            });
    }catch(err){
        dispatch({
            type: RICHIESTA_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const programmaTuttiTurni = (page, pagination) => async dispatch => {
    try{
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const body = JSON.stringify({
            page: page,
            pagination: pagination
        });
        dispatch({
            type: LOADING_RICHIESTE
        });
        const res = await axios.post('/api/richiesta/programmaTuttiTurni', body, config);
        dispatch({
            type: PROGRAMMA_TUTTI_TURNI_JOB,
            payload: res.data
        });
        return res.data
    }catch(err){
        dispatch({
            type: RICHIESTA_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const getStateProcessProgrammaTurni = (id) => async dispatch => {
    try{
        const res = await axios.get(`/api/richiesta/getStateProcessProgrammaTurni/${id}`);
        return res
    }catch(err){
        dispatch({
            type: RICHIESTA_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const eliminaRichiestaAnomala = ({idRichiesta, id}) => async dispatch => {
    try{
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const body = JSON.stringify({
            idRichiesta: idRichiesta,
            id: id
        });
        const res = await axios.post('/api/richiesta/eliminaRichiestaAnomala', body, config);
        dispatch({
            type: ELIMINA_RICHIESTA_ANOMALA,
            payload: res.data
        });
    }catch(err){
        dispatch({
            type: RICHIESTA_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};



export const programmaTuttiTurniSAVE = (page, pagination) => async dispatch => {
    try{
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const body = JSON.stringify({
            page: page,
            pagination: pagination
        });
        dispatch({
            type: LOADING_RICHIESTE
        });
        const res = await axios.post('/api/richiesta/programmaTuttiTurni', body, config);
        dispatch({
            type: PROGRAMMA_TUTTI_TURNI,
            payload: res.data
        });
    }catch(err){
        dispatch({
            type: RICHIESTA_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const programmaProssimoTurno = (page, pagination) => async dispatch => {
    try{
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const body = JSON.stringify({
            page: page,
            pagination: pagination
        });
        dispatch({
            type: LOADING_RICHIESTE
        });
        const res = await axios.post('/api/richiesta/programmaProssimoTurno', body, config);
        dispatch({
            type: PROGRAMMA_PROSSIMO_TURNO,
            payload: res.data
        });
    }catch(err){
        dispatch({
            type: RICHIESTA_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const ripristinaTuttiTurni = (page, pagination) => async dispatch => {
    try{
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const body = JSON.stringify({
            page: page,
            pagination: pagination
        });
        const res = await axios.post('/api/richiesta/ripristinaTuttiTurni', body, config);
        dispatch({
            type: RIPRISTINA_TUTTI_TURNI,
            payload: res.data
        });
    }catch(err){
        dispatch({
            type: RICHIESTA_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const archiviaRichiesta = (idRichiesta) => async dispatch => {
    try{
        const res = await axios.get(`/api/richiesta/archiviaRichiesta/${idRichiesta}`);
        dispatch({
            type: ARCHIVIA_RICHIESTA,
            payload: res.data
        });
    }catch(err){
        dispatch({
            type: RICHIESTA_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const getCampiRichiesteMassive = (dataInizio, dataFine ) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ dataInizio: dataInizio, dataFine: dataFine});
    try{
        const res = await axios.post('/api/richiesta/CampiRichiestaMassiva', body, config);
        dispatch({
            type: GET_CAMPI_RICHIESTA_MASSIVA,
            payload: res.data
        });

    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        dispatch({
            type: RICHIESTA_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const addDipendenteRichiesta = (idRichiesta, idDipendente, idPuntoVendita, parola, page, pagination, stato) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ 
        idRichiesta: idRichiesta,
        idDipendente: idDipendente,
        idPuntoVendita: idPuntoVendita,
        parola: parola,
        page: page,
        pagination: pagination,
        stato: stato
    });
    try{
        const res = await axios.post('/api/richiesta/addDipendenteRichiesta', body, config);
            dispatch({
                type: ADD_DIPENDENTE_RICHIESTE,
                payload: res.data
            });
    }catch(err){
        dispatch({
            type: RICHIESTA_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const deleteDipendenteRichiesta = (idRichiesta, idPuntoVendita, parola, page, pagination, stato) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ 
        idRichiesta: idRichiesta,
        idPuntoVendita: idPuntoVendita,
        parola: parola,
        page: page,
        pagination: pagination,
        stato: stato
    });
    try{
        const res = await axios.post('/api/richiesta/deleteDipendenteRichiesta', body, config);
            dispatch({
                type: DELETE_DIPENDENTE_RICHIESTE,
                payload: res.data
            });
    }catch(err){
        dispatch({
            type: RICHIESTA_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const associaDipendenteRichiesta = ({id, idDipendente, idRichiesta, idContratto, dataRichiesta, oraInizio, oraFine, resti, disponibilita, continuativo, numeroContinuativo, recuperoRiposo, dataRecuperoRiposo, prestato}) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({id, idDipendente, idRichiesta, idContratto, dataRichiesta, oraInizio, oraFine, resti, disponibilita, continuativo, numeroContinuativo, recuperoRiposo, dataRecuperoRiposo, prestato});
    try{
        const res = await axios.post('/api/richiesta/associaDipendenteRichiesta', body, config);
            dispatch({
                type: ASSOCIA_DIPENDENTE_RICHIESTA,
                payload: res.data
            });
            if(res.data.eccezione.length > 0){
                dispatch({
                    type: AGGIUNGI_GIORNO_ECCEZIONE_LUNGO,
                    payload: res.data.eccezione
                });
            }
            dispatch(setAlert('Dipendente associato!', 'success'));
    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        dispatch({
            type: RICHIESTA_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const associaDipendenteRichiestaCalendario = ({id, indice, idDipendente, idRichiesta, idContratto, dataRichiesta, oraInizio, oraFine, resti, disponibilita, continuativo, numeroContinuativo, recuperoRiposo, dataRecuperoRiposo, prestato, forzato}) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({id, indice, idDipendente, idRichiesta, idContratto, dataRichiesta, oraInizio, oraFine, resti, disponibilita, continuativo, numeroContinuativo, recuperoRiposo, dataRecuperoRiposo, prestato, forzato});
    try{
        const res = await axios.post('/api/richiesta/associaDipendenteRichiestaCalendario', body, config);
            dispatch({
                type: ASSOCIA_DIPENDENTE_RICHIESTA,
                payload: res.data
            });
            if(res.data.eccezione.length > 0){
                dispatch({
                    type: AGGIUNGI_GIORNO_ECCEZIONE_LUNGO,
                    payload: res.data.eccezione
                });
            }
            dispatch(setAlert('Dipendente associato!', 'success', 'richiesta_associata'));

    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error', error.field)));
        }
        dispatch({
            type: RICHIESTA_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const duplicaServizioCalendario = ({ idContratto, data, id, indice }) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ idContratto, data, id, indice  });
    try {
        const res = await axios.post('/api/richiesta/duplicaServizioCalendario', body, config);
        dispatch(setAlert('Servizio duplicato con successo!', 'success', 'contratto_duplicato'));
        dispatch({
            type: DUPLICA_SERVIZIO,
            payload: res.data
        });
    } catch (err) {
        const errors = err.response.data.errors;
        if (errors) {
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        dispatch({
            type: RICHIESTA_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        });
    }
};

export const modificaOrarioRichiesta = ({id, idRichiesta, dataRichiesta, oraInizio, oraFine, resti}) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({id, idRichiesta, dataRichiesta, oraInizio, oraFine, resti});
    try{
        const res = await axios.post('/api/richiesta/modificaOrarioRichiesta', body, config);
            dispatch({
                type: MODIFICA_ORARIO_RICHIESTA,
                payload: res.data
            });
            dispatch(setAlert('Servizio modificato!', 'success'));
    }catch(err){
        dispatch({
            type: RICHIESTA_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};


export const modificaOrarioRichiestaCalendario = ({id, indice, idRichiesta, dataRichiesta, oraInizio, oraFine, resti}) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({id, indice, idRichiesta, dataRichiesta, oraInizio, oraFine, resti});
    try{
        const res = await axios.post('/api/richiesta/modificaOrarioRichiestaCalendario', body, config);
            dispatch({
                type: MODIFICA_ORARIO_RICHIESTA,
                payload: res.data
            });
            dispatch(setAlert('Servizio modificato!', 'success'));
    }catch(err){
        dispatch({
            type: RICHIESTA_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const dissociaDipendenteRichiesta = ({id, idRichiesta, resti}) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({id, idRichiesta, resti});
    try{
        const res = await axios.post('/api/richiesta/dissociaDipendenteRichiesta', body, config);
            dispatch({
                type: DISSOCIA_DIPENDENTE_RICHIESTA,
                payload: res.data
            });
            dispatch(setAlert('Dipendente disassociato!', 'success'));
    }catch(err){
        dispatch({
            type: RICHIESTA_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const dissociaDipendenteRichiestaCalendario = ({id, indice, idRichiesta, resti}) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({id, indice, idRichiesta, resti});
    try{
        const res = await axios.post('/api/richiesta/dissociaDipendenteRichiestaCalendario', body, config);
            dispatch({
                type: DISSOCIA_DIPENDENTE_RICHIESTA,
                payload: res.data
            });
            dispatch(setAlert('Dipendente disassociato!', 'success'));
    }catch(err){
        dispatch({
            type: RICHIESTA_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const nascondiContratto = ({idContratto, dataRichiesta, id, continuativo, numeroContinuativo, oraInizio, oraFine}) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({idContratto, dataRichiesta, id, continuativo, numeroContinuativo, oraInizio, oraFine});
    try{
        const res = await axios.post('/api/richiesta/nascondiContratto', body, config);
            dispatch({
                type: NASCONDI_CONTRATTO,
                payload: res.data
            });
            dispatch(setAlert('Contratto nascosto!', 'success'));
    }catch(err){
        dispatch({
            type: RICHIESTA_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const nascondiContrattoCalendario = ({idContratto, dataRichiesta, id, indice, continuativo, numeroContinuativo, oraInizio, oraFine}) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({idContratto, dataRichiesta, id, indice, continuativo, numeroContinuativo, oraInizio, oraFine});
    try{
        const res = await axios.post('/api/richiesta/nascondiContrattoCalendario', body, config);
            dispatch({
                type: NASCONDI_CONTRATTO,
                payload: res.data
            });
            dispatch(setAlert('Contratto nascosto!', 'success'));
    }catch(err){
        dispatch({
            type: RICHIESTA_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const ripristinaContrattoNascosto = ({id, idRichiesta}) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({id, idRichiesta});
    try{
        const res = await axios.post('/api/richiesta/ripristinaContrattoNascosto', body, config);
            dispatch({
                type: RIPRISTINA_CONTRATTO_NASCOSTO,
                payload: res.data
            });
            dispatch(setAlert('Contratto nascosto ripristinato!', 'success'));
    }catch(err){
        dispatch({
            type: RICHIESTA_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const ripristinaContrattoNascostoCalendario = ({id, indice, idRichiesta}) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({id, indice, idRichiesta});
    try{
        const res = await axios.post('/api/richiesta/ripristinaContrattoNascostoCalendario', body, config);
            dispatch({
                type: RIPRISTINA_CONTRATTO_NASCOSTO,
                payload: res.data
            });
            dispatch(setAlert('Contratto nascosto ripristinato!', 'success'));
    }catch(err){
        dispatch({
            type: RICHIESTA_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};


export const getCalendarioContratti = ({dataRichiesta, giornoSettimana}) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({dataRichiesta, giornoSettimana});
    try{
        const res = await axios.post('/api/richiesta/getCalendarioContratti', body, config);
            dispatch({
                type: GET_CALENDARIO_CONTRATTI,
                payload: res.data
            });
    }catch(err){
        dispatch({
            type: RICHIESTA_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const archiviaRichieste = ({richieste}) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({richieste});
    try{
        const res = await axios.post('/api/richiesta/archiviaRichieste', body, config);
            dispatch({
                type: ARCHIVIA_RICHIESTE,
                payload: res.data
            });
            dispatch(setAlert('Richiesta archiviata!', 'success', 'richieste_archiviate'));
    }catch(err){
        dispatch({
            type: RICHIESTA_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const getDipendentiPrestabiliOccupati = ({dipendenti, dataInizio}) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({dipendenti, dataInizio});
    try{
        const res = await axios.post('/api/richiesta/getDipendentiPrestabiliOccupati', body, config);
            dispatch({
                type: GET_DIPENDENTI_PRESTABILI_OCCUPATI,
                payload: res.data
            });
    }catch(err){
        dispatch({
            type: RICHIESTA_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};



export const annullaArchiviaRichieste = ({richieste}) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({richieste});
    try{
        const res = await axios.post('/api/richiesta/annullaArchiviaRichieste', body, config);
            dispatch({
                type: ANNULLA_ARCHIVIA_RICHIESTE,
                payload: res.data
            });
            dispatch(setAlert('Annullamento richieste archiviate!', 'success', 'ripristina_richieste_archiviate'));
    }catch(err){
        dispatch({
            type: RICHIESTA_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const getQuadraturaOre = ({dataInizio, dataFine, idFiliale}) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({dataInizio, dataFine, idFiliale});
    try{
        const res = await axios.post('/api/richiesta/getQuadraturaOre', body, config);
            dispatch({
                type: GET_QUADRATURA_ORE,
                payload: res.data
            });
    }catch(err){
        dispatch({
            type: RICHIESTA_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const getRichiesteStampaGiornate = ({dataInizio, dataFine}) => async dispatch => {
    try{
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const body = JSON.stringify({dataInizio, dataFine});
        const res = await axios.post('/api/richiesta/getRichiesteStampaGiornate', body, config);
        dispatch({
            type: GET_RICHIESTE_STAMPA_GIORNATE,
            payload: res.data
        });
        return res.data
    }catch(err){
        const errors = err.response.data.errors;
        if (errors) {
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        dispatch({
            type: RICHIESTA_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const cleanRichiesta = () => async dispatch => {
    dispatch({
        type: CLEAN_RICHIESTA
    });
};

export const cleanCalendario = () => async dispatch => {
    dispatch({
        type: CLEAN_CALENDARIO
    });
};

export const cleanRichieste = () => async dispatch => {
    dispatch({
        type: CLEAN_RICHIESTE
    });
};

export const cleanRichiesteArchiviate = () => async dispatch => {
    dispatch({
        type: CLEAN_RICHIESTE_ARCHIVIATE
    });
};