import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import documentoIMG from "../../../img/documento.png"
import cestinoIMG from "../../../img/trash.png"



const DocumentoUtenteEsterno = ({ id, url, nome, onChangeUploadFile, tipologieDocumenti, onChangeTipologiaDocumento, eliminaDocumento, onChangeNomeDocumento, cartella, categorieDocumentiUtenteEsterno, onChangeCartellaDocumento }) => {

    return (
        <div className='mb-30'>
            <div className="div_wrap_100">
                {url ?
                    <div className="div_wrap_25">
                        <div className="col_input">
                            <label htmlFor="tagliaPantaloni">File</label>
                            <div className='item_doc_dip'>
                                <span><a target="_blank" href={url}><img className='doc_dip_img' src={documentoIMG}></img></a></span>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="div_wrap_25">
                        <div className="col_input">
                            <label htmlFor="tagliaPantaloni">File</label>
                            <div className='item_doc_dip'>
                                <input type="file" className="input w-input" name={nome} data-name={id} id={id} onChange={(e) => onChangeUploadFile(e, id, "documento-esterno")} />
                            </div>
                        </div>
                    </div>
                }
                <div className="div_wrap_25">
                    <div className="col_input">
                        <label htmlFor="name">Nome</label>
                        <input type="text" className="input w-input" maxLength="256" name="nome" value={nome} data-name="nome" placeholder="Inserisci nome" id="nome" onChange={e => onChangeNomeDocumento(e, id)} />
                    </div>
                </div>
                <div className="div_wrap_25">
                    <div className="col_input col_select">
                        <label htmlFor="name">Tipologia documento</label>
                        <div data-hover="false" data-delay="0" className="select w-dropdown">
                            <select className="form-control select" name="scadenza" onChange={e => onChangeTipologiaDocumento(e.target.value, id)} required>
                                {tipologieDocumenti.map((el, i) => (
                                    <option name={el.nome} selected={nome == el.nome} value={el.nome}>{el.nome}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="div_wrap_25">
                    <div className="col_input col_select">
                        <label htmlFor="name">Cartella</label>
                        <div data-hover="false" data-delay="0" className="select w-dropdown">
                            <select className="form-control select" name="cartella" onChange={e => onChangeCartellaDocumento(e.target.value, id)} required>
                            <option selected={true} value={null} disabled >seleziona cartella</option>
                                {categorieDocumentiUtenteEsterno.map(cartellaD => (
                                    <option selected={cartellaD == cartella} value={cartellaD} >{cartellaD}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div className='div_wrap_100'>
                <div className="div_wrap_25">
                    <span></span>
                </div>
                <div className="div_wrap_25">
                    <span></span>
                </div>
                <div className="div_wrap_25">
                    <span></span>
                </div>
                <div className="div_wrap_25">
                    <div className="col_input">
                        <div id="w-node-d409c487-9a0c-6b1f-803c-c7c11a8afa3e-b8dac9c0" onClick={() => eliminaDocumento(id)} class="div_item_button"><a class="button w-button">elimina</a></div>
                    </div>
                </div>
            </div>
        </div>
    )
}
DocumentoUtenteEsterno.protoTypes = {
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, {})(DocumentoUtenteEsterno);

