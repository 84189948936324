import axios from 'axios';
import { GET_TIPOLOGIE_CORSI, CREA_TIPOLOGIA_CORSO, DOCUMENTO_ERROR } from './types';
import { setAlert } from './alert';


export const getTipologieCorsi = () => async dispatch => {
    try{
        const res = await axios.get('/api/documento/getTipologieCorsi');
        dispatch({
            type: GET_TIPOLOGIE_CORSI,
            payload: res.data
        });
    }catch(err){
        dispatch({
            type: DOCUMENTO_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const creaTipologiaCorso = ({ nome, scadenza }) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ nome, scadenza });
    try{
        const res = await axios.post('/api/documento/creaTipologiaCorso', body, config);
        dispatch({
            type: CREA_TIPOLOGIA_CORSO,
            payload: res.data
        });
        dispatch(setAlert('Nuova tipologia corso creata!', 'success', 'crea_nuova_tipologia_documento'));

    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        dispatch({
            type: DOCUMENTO_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};
