import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import DatePicker from 'react-date-picker';
import Spinner from '../../layout/Spinner';
import { creaNuovoDipendente } from '../../../actions/dipendente';
import { getFiliali } from '../../../actions/filiale';
import mansioniJSON from "../../../utils/mansioni.json";
const NuovoDipendente = ({ setOpenNuovoDipendente, creaNuovoDipendente, alert, getFiliali, auth: { user } }) => {


    useEffect(() => {
        if (user && user.role === 'admin') {
            getFiliali();
        }
    }, [getFiliali]);

    useEffect(() => {
        const size = alert.length;
        if (size > 0) {
            if (alert[size - 1].alertType === "success") {
                setOpenNuovoDipendente(false);
                setDatiDipendente(dipendenteInitialState);
            }
        }
    }, [alert]);


    const dipendenteInitialState = {
        idZoneRes: "",
        idZoneAss: "",
        idStato: "",
        idTitoliStu: "",
        idInvalidita: "",
        idAziendeLav: "",
        idQualifiche: "",
        idMansioni: "",
        idTipiDisponib: "",
        matricola: "",
        codice: "",
        nome: "",
        cognome: "",
        luogoRes: "",
        provRes: "",
        cap: "",
        indirizzo: "",
        telefono1: "",
        telefono2: "",
        sesso: "",
        luogoNasc: "",
        provNasc: "",
        dataNasc: null,
        codicefiscale: "",
        nPortoArmi: "",
        rinPortArmi: "",
        scadPortArmi: "",
        nLicenza: "",
        rinLicenza: "",
        scadLicenza: "",
        nDecreto: "",
        rinDecreto: "",
        scadDecreto: "",
        nLibretto: "",
        rinLibretto: "",
        scadLibretto: "",
        dataInizioAss: null,
        dataFineAss: "",
        tipoPatente: "",
        email: "",
        dispStraord: "",
        cadenza: "",
        dataInizioCadenza: null,
        ferOrd: "",
        ferSup: "",
        ferUsu: "",
        ferStr: "",
        fesOrd: "",
        fesSup: "",
        fesUsu: "",
        fesStr: "",
        ripOrd: "",
        ripSup: "",
        ripUsu: "",
        ripStr: "",
        percBancaOre: "",
        dipendenza: "",
        bancaOreIniziale: 0,
        bancaOreProgressivo: 0,
        ferieIniziale: 0,
        permessiIniziale: 0,
        qualifica: "",
        note: "",
        prestabile: false,
        eccedenza: false,
        sussidiari: false,
        nomeSussidiari: "",
        partTime: false,
        somministrato: false
    }

    const [datiDipendente, setDatiDipendente] = useState(dipendenteInitialState);
    const {
        idZoneRes,
        idZoneAss,
        idStato,
        idTitoliStu,
        idInvalidita,
        idAziendeLav,
        idQualifiche,
        idMansioni,
        idTipiDisponib,
        matricola,
        codice,
        nome,
        cognome,
        luogoRes,
        provRes,
        cap,
        indirizzo,
        telefono1,
        telefono2,
        sesso,
        luogoNasc,
        provNasc,
        dataNasc,
        codicefiscale,
        nPortoArmi,
        rinPortArmi,
        scadPortArmi,
        nLicenza,
        rinLicenza,
        scadLicenza,
        nDecreto,
        rinDecreto,
        scadDecreto,
        nLibretto,
        rinLibretto,
        scadLibretto,
        dataInizioAss,
        dataFineAss,
        tipoPatente,
        email,
        dispStraord,
        cadenza,
        dataInizioCadenza,
        filiale,
        organizzazione,
        ferOrd,
        ferSup,
        ferUsu,
        ferStr,
        fesOrd,
        fesSup,
        fesUsu,
        fesStr,
        ripOrd,
        ripSup,
        ripUsu,
        ripStr,
        percBancaOre,
        dipendenza,
        bancaOreIniziale,
        bancaOreProgressivo,
        ferieIniziale,
        permessiIniziale,
        qualifica,
        note,
        prestabile,
        eccedenza,
        sussidiari,
        nomeSussidiari,
        partTime,
        somministrato
    } = datiDipendente;


    function capitalize(str) {
        const lowerCaseString = str.toLowerCase(), // convert string to lower case  
              firstLetter = str.charAt(0).toUpperCase(), // upper case the first character
              strWithoutFirstChar = lowerCaseString.slice(1); // remove first character from lower case string 
      
        return firstLetter + strWithoutFirstChar; 
      
      }

      const onChangeSetDatiDipendente = e => {
        if(e.target.name == "nome" || e.target.name == "cognome"){
            setDatiDipendente({ ...datiDipendente, [e.target.name]: capitalize(e.target.value) });
        }else{
            setDatiDipendente({ ...datiDipendente, [e.target.name]: e.target.value });
        }
    }


    const onChangeCadenza = (i, value) => {
        let strCadenza = cadenza;
        if (value.length == 0) {
            strCadenza = strCadenza.slice(0, -1)
        } else {
            if (value.length > 1) {
                let char = value.charAt(value.length - 1);
                strCadenza = strCadenza.concat(char);
            } else {
                strCadenza = strCadenza.concat(value);
            }
        }
        setDatiDipendente({ ...datiDipendente, 'cadenza': strCadenza.toUpperCase() });
    }


    const onSubmitCreaDipendente = async e => {
        e.preventDefault();
        creaNuovoDipendente(
            {
                nome,
                cognome,
                codicefiscale,
                matricola,
                luogoRes,
                provRes,
                cap,
                indirizzo,
                telefono1,
                telefono2,
                email,
                luogoNasc,
                provNasc,
                dataNasc,
                cadenza: cadenza.replace(/ /g, '').slice(0, 14),
                dataInizioCadenza,
                dataInizioAss,
                dataFineAss,
                dipendenza,
                percBancaOre,
                bancaOreIniziale,
                ferieIniziale,
                permessiIniziale,
                qualifica,
                note,
                ferOrd,
                ferStr,
                prestabile,
                eccedenza,
                idMansioni,
                sussidiari,
                nomeSussidiari,
                partTime,
                somministrato
            }
        );
    };

    const onChangeSetCheckbox = e => {
        setDatiDipendente({ ...datiDipendente, [e.target.name]: e.target.value == 'true' ? false : true });
    }

    return (!user ? <Spinner /> :
        <div className="dati_container">
            <div className="fun_assegnazione_incarichi">
                <div className="row row_datapicker">
                    <div className="item_2">
                        <h4>Crea nuovo dipendente</h4>
                        <div>
                            <div className="w-form">
                                <form id="nuovo_dipendente" name="email-form-2" data-name="Email Form 2" method="get" aria-label="Email Form 2">
                                    <div className="div_wrap_100">
                                        <div className="div_wrap_25">
                                            <div className="col_input">
                                                <label htmlFor="name">Nome</label>
                                                <input type="text" className="input w-input" maxLength="256" name="nome" value={nome} data-name="nome" placeholder="Inserisci nome" id="nome" onChange={e => onChangeSetDatiDipendente(e)} />
                                            </div>
                                        </div>
                                        <div className="div_wrap_25">
                                            <div className="col_input">
                                                <label htmlFor="name">Cognome</label>
                                                <input type="text" className="input w-input" maxLength="256" name="cognome" data-name="cognome" value={cognome} placeholder="Inserisci cognome" id="cognome" onChange={e => onChangeSetDatiDipendente(e)} />
                                            </div>
                                        </div>
                                        <div className="div_wrap_25">
                                            <div className="col_input">
                                                <label htmlFor="name">Codice fiscale</label>
                                                <input type="text" className="input w-input" maxLength="256" name="codicefiscale" data-name="codicefiscale" value={codicefiscale} placeholder="Inserisci codicefiscale" id="codicefiscale" onChange={e => onChangeSetDatiDipendente(e)} />
                                            </div>
                                        </div>
                                        <div className="div_wrap_25">
                                            <div className="col_input">
                                                <label htmlFor="name">Matricola</label>
                                                <input type="text" className="input w-input" maxLength="256" name="matricola" data-name="matricola" value={matricola} placeholder="Inserisci matricola" id="matricola" onChange={e => onChangeSetDatiDipendente(e)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="div_wrap_100">
                                        <div className="div_wrap_25">
                                            <div className="col_input">
                                                <label htmlFor="name">Indirizzo</label>
                                                <input type="text" className="input w-input" maxLength="256" name="indirizzo" data-name="indirizzo" value={indirizzo} placeholder="Inserisci indirizzo" id="indirizzo" onChange={e => onChangeSetDatiDipendente(e)} />
                                            </div>
                                        </div>
                                        <div className="div_wrap_25">
                                            <div className="col_input">
                                                <label htmlFor="name">Luogo residenza</label>
                                                <input type="text" className="input w-input" maxLength="256" name="luogoRes" data-name="luogoRes" value={luogoRes} placeholder="Inserisci luogo residenza" id="luogoRes" onChange={e => onChangeSetDatiDipendente(e)} />
                                            </div>
                                        </div>
                                        <div className="div_wrap_25">
                                            <div className="col_input">
                                                <label htmlFor="name">Provincia residenza</label>
                                                <input type="text" className="input w-input" maxLength="256" name="provRes" value={provRes} data-name="provRes" placeholder="Inserisci provincia residenza" id="provRes" onChange={e => onChangeSetDatiDipendente(e)} />
                                            </div>
                                        </div>
                                        <div className="div_wrap_25">
                                            <div className="col_input">
                                                <label htmlFor="name">CAP</label>
                                                <input type="text" className="input w-input" maxLength="256" name="cap" data-name="cap" value={cap} placeholder="Inserisci cap" id="cap" onChange={e => onChangeSetDatiDipendente(e)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="div_wrap_100">
                                        <div className="div_wrap_25">
                                            <div className="col_input">
                                                <label htmlFor="name">Telefono 1</label>
                                                <input type="text" className="input w-input" maxLength="256" name="telefono1" data-name="telefono1" value={telefono1} placeholder="Inserisci telefono" id="telefono1" onChange={e => onChangeSetDatiDipendente(e)} />
                                            </div>
                                        </div>
                                        <div className="div_wrap_25">
                                            <div className="col_input">
                                                <label htmlFor="name">Telefono 2</label>
                                                <input type="text" className="input w-input" maxLength="256" name="telefono2" data-name="telefono2" value={telefono2} placeholder="Inserisci telefono" id="telefono2" onChange={e => onChangeSetDatiDipendente(e)} />
                                            </div>
                                        </div>
                                        <div className="div_wrap_25">
                                            <div className="col_input">
                                                <label htmlFor="name">Email</label>
                                                <input type="text" className="input w-input" maxLength="256" name="email" data-name="email" value={email} placeholder="Inserisci email" id="email" onChange={e => onChangeSetDatiDipendente(e)} />
                                            </div>
                                        </div>
                                        <div className="div_wrap_25">
                                        </div>
                                    </div>
                                    <div className="div_wrap_100">
                                        <div className="div_wrap_25">
                                            <div className="col_input">
                                                <label htmlFor="name">Luogo nascita</label>
                                                <input type="text" className="input w-input" maxLength="256" name="luogoNasc" data-name="luogoNasc" value={luogoNasc} placeholder="Inserisci luogo nascita" id="luogoNasc" onChange={e => onChangeSetDatiDipendente(e)} />
                                            </div>
                                        </div>
                                        <div className="div_wrap_25">
                                            <div className="col_input">
                                                <label htmlFor="name">Provincia nascita</label>
                                                <input type="text" className="input w-input" maxLength="256" name="provNasc" data-name="provNasc" value={provNasc} placeholder="Inserisci provincia nascita" id="provNasc" onChange={e => onChangeSetDatiDipendente(e)} />
                                            </div>
                                        </div>
                                        <div className="div_wrap_25">
                                            <div className="col_input"><label htmlFor="name-11">Data nascita</label>
                                                <div data-hover="false" data-delay="0" className="select w-dropdown select_data">
                                                    <DatePicker
                                                        onChange={(value) => onChangeSetDatiDipendente({ target: { value: value, name: 'dataNasc' } })}
                                                        value={dataNasc}
                                                        clearIcon={null}
                                                        className={'form-control'}
                                                        disableCalendar={false}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="div_wrap_25">

                                        </div>
                                    </div>
                                    <div className="div_wrap_100">
                                        <div className="div_wrap_50">
                                            <div className="col_input">
                                                <label htmlFor="name-11">Cadenza</label>
                                                <div class="div_wrap_more_input">
                                                    <input type="text" class="input cadenza w-input" name="cadenza-0" data-name="Cadenza-0" placeholder="" id="cadenza-0" value={cadenza.charAt(0)} onChange={(e) => onChangeCadenza(0, e.target.value)} />
                                                    <input type="text" class="input cadenza w-input" name="cadenza-1" data-name="Cadenza-1" placeholder="" id="cadenza-1" value={cadenza.charAt(1)} onChange={(e) => onChangeCadenza(1, e.target.value)} disabled />
                                                    <input type="text" class="input cadenza w-input" name="cadenza-2" data-name="Cadenza-2" placeholder="" id="cadenza-2" value={cadenza.charAt(2)} onChange={(e) => onChangeCadenza(2, e.target.value)} disabled />
                                                    <input type="text" class="input cadenza w-input" name="cadenza-3" data-name="Cadenza-3" placeholder="" id="cadenza-3" value={cadenza.charAt(3)} onChange={(e) => onChangeCadenza(3, e.target.value)} disabled />
                                                    <input type="text" class="input cadenza w-input" name="cadenza-4" data-name="Cadenza-4" placeholder="" id="cadenza-4" value={cadenza.charAt(4)} onChange={(e) => onChangeCadenza(4, e.target.value)} disabled />
                                                    <input type="text" class="input cadenza w-input" name="cadenza-5" data-name="Cadenza-5" placeholder="" id="cadenza-5" value={cadenza.charAt(5)} onChange={(e) => onChangeCadenza(5, e.target.value)} disabled />
                                                    <input type="text" class="input cadenza w-input" name="cadenza-6" data-name="Cadenza-6" placeholder="" id="cadenza-6" value={cadenza.charAt(6)} onChange={(e) => onChangeCadenza(6, e.target.value)} disabled />
                                                    <input type="text" class="input cadenza w-input" name="cadenza-7" data-name="Cadenza-7" placeholder="" id="cadenza-7" value={cadenza.charAt(7)} onChange={(e) => onChangeCadenza(7, e.target.value)} disabled />
                                                    <input type="text" class="input cadenza w-input" name="cadenza-8" data-name="Cadenza-8" placeholder="" id="cadenza-8" value={cadenza.charAt(8)} onChange={(e) => onChangeCadenza(8, e.target.value)} disabled />
                                                    <input type="text" class="input cadenza w-input" name="cadenza-9" data-name="Cadenza-9" placeholder="" id="cadenza-9" value={cadenza.charAt(9)} onChange={(e) => onChangeCadenza(9, e.target.value)} disabled />
                                                    <input type="text" class="input cadenza w-input" name="cadenza-10" data-name="Cadenza-10" placeholder="" id="cadenza-10" value={cadenza.charAt(10)} onChange={(e) => onChangeCadenza(10, e.target.value)} disabled />
                                                    <input type="text" class="input cadenza w-input" name="cadenza-11" data-name="Cadenza-11" placeholder="" id="cadenza-11" value={cadenza.charAt(11)} onChange={(e) => onChangeCadenza(11, e.target.value)} disabled />
                                                    <input type="text" class="input cadenza w-input" name="cadenza-12" data-name="Cadenza-12" placeholder="" id="cadenza-12" value={cadenza.charAt(12)} onChange={(e) => onChangeCadenza(12, e.target.value)} disabled />
                                                    <input type="text" class="input cadenza w-input" name="cadenza-13" data-name="Cadenza-13" placeholder="" id="cadenza-13" value={cadenza.charAt(13)} onChange={(e) => onChangeCadenza(13, e.target.value)} disabled />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="div_wrap_25">
                                            <div className="col_input">
                                                <label htmlFor="name-11">Data inizio cadenza</label>
                                                <div data-hover="false" data-delay="0" className="select w-dropdown select_data">
                                                    <DatePicker
                                                        onChange={(value) => onChangeSetDatiDipendente({ target: { value: value, name: 'dataInizioCadenza' } })}
                                                        value={dataInizioCadenza}
                                                        clearIcon={null}
                                                        className={'form-control'}
                                                        disableCalendar={false}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="div_wrap_25">
                                        </div>
                                    </div>
                                    <div className="div_wrap_100">
                                        <div className="div_wrap_25">
                                            <div className="col_input">
                                                <label htmlFor="name">Dipendenza</label>
                                                <input type="text" className="input w-input" maxLength="256" name="dipendenza" data-name="dipendenza" value={dipendenza} placeholder="Inserisci dipendenza" id="dipendenza" onChange={e => onChangeSetDatiDipendente(e)} />
                                            </div>
                                        </div>
                                        <div className="div_wrap_25">
                                            <div className="col_input col_select">
                                                <label htmlFor="name">Qualifica</label>
                                                <div data-hover="false" data-delay="0" className="select w-dropdown">
                                                    <select className="form-control select" name="qualifica" onChange={e => onChangeSetDatiDipendente(e)} required>
                                                        <option selected value={null} disabled >Seleziona qualifica...</option>
                                                        <option selected={'ots' == qualifica} value={'ots'}>OTS</option>
                                                        <option selected={'gpg' == qualifica} value={'gpg'}>GPG</option>
                                                        <option selected={'impiegato' == qualifica} value={'impiegato'}>IMPIEGATO</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="div_wrap_25">
                                            <div className="col_input"><label htmlFor="name-11">Data inizio assunzione</label>
                                                <div data-hover="false" data-delay="0" className="select w-dropdown select_data">
                                                    <DatePicker
                                                        onChange={(value) => onChangeSetDatiDipendente({ target: { value: value, name: 'dataInizioAss' } })}
                                                        value={dataInizioAss}
                                                        clearIcon={null}
                                                        className={'form-control'}
                                                        disableCalendar={false}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="div_wrap_25">
                                            <div className="col_input"><label htmlFor="name-11">Data fine assunzione</label>
                                                <div data-hover="false" data-delay="0" className="select w-dropdown select_data">
                                                    <DatePicker
                                                        onChange={(value) => onChangeSetDatiDipendente({ target: { value: value, name: 'dataFineAss' } })}
                                                        value={dataFineAss}
                                                        clearIcon={null}
                                                        className={'form-control'}
                                                        disableCalendar={false}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="div_wrap_100">
                                        <div className="div_wrap_25">
                                            <div className="col_input">
                                                <label htmlFor="name">Banca ore iniziali</label>
                                                <input type="text" className="input w-input" maxLength="256" name="bancaOreIniziale" data-name="bancaOreIniziale" value={bancaOreIniziale} placeholder="Inserisci ore" id="bancaOreIniziale" onChange={e => onChangeSetDatiDipendente(e)} />
                                            </div>
                                        </div>
                                        <div className="div_wrap_25">
                                            <div className="col_input">
                                                <label htmlFor="name">Percentuale banca ore</label>
                                                <input type="text" className="input w-input" maxLength="256" name="percBancaOre" data-name="percBancaOre" value={percBancaOre} placeholder="Inserisci percentuale banca ore" id="percBancaOre" onChange={e => onChangeSetDatiDipendente(e)} />
                                            </div>
                                        </div>
                                        <div className="div_wrap_25">
                                            <div className="col_input">
                                                <label htmlFor="name">Ferie iniziali</label>
                                                <input type="text" className="input w-input" maxLength="256" name="ferieIniziale" data-name="ferieIniziale" value={ferieIniziale} placeholder="Inserisci giorni" id="ferieIniziale" onChange={e => onChangeSetDatiDipendente(e)} />
                                            </div>
                                        </div>
                                        <div className="div_wrap_25">
                                            <div className="col_input">
                                                <label htmlFor="name">Permessi iniziali</label>
                                                <input type="text" className="input w-input" maxLength="256" name="permessiIniziale" data-name="permessiIniziale" value={permessiIniziale} placeholder="Inserisci giorni" id="permessiIniziale" onChange={e => onChangeSetDatiDipendente(e)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="div_wrap_100">
                                        <div className="div_wrap_25">
                                            <div className="col_input">
                                                <label htmlFor="name">Ore ordinari</label>
                                                <input type="time" className="input w-input" maxLength="256" name="ferOrd" data-name="ferOrd" value={ferOrd} placeholder="Inserisci ore ordinarie" id="ferOrd" onChange={e => onChangeSetDatiDipendente(e)} />
                                            </div>
                                        </div>
                                        <div className="div_wrap_25">
                                            <div className="col_input">
                                                <label htmlFor="name">Ore straordinarie</label>
                                                <input type="time" className="input w-input" maxLength="256" name="ferStr" data-name="ferStr" value={ferStr} placeholder="Inserisci ore straordinarie" id="ferStr" onChange={e => onChangeSetDatiDipendente(e)} />
                                            </div>
                                        </div>
                                        <div className="div_wrap_25">
                                            <div className="col_input">
                                                <label htmlFor="partTime">Part-time/chiamata</label>
                                                <label className="w-checkbox checkbox-field m_right_10">
                                                    <input type="checkbox" id={"partTime"} className="w-checkbox-input" name="partTime" checked={partTime} value={partTime} onChange={e => onChangeSetCheckbox(e)} />
                                                    <span className="label_text w-form-label" htmlFor="checkbox-3">Si</span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="div_wrap_25">
                                            <div className="col_input">
                                                <label htmlFor="partTime">Somministrato</label>
                                                <label className="w-checkbox checkbox-field m_right_10">
                                                    <input type="checkbox" id={"somministrato"} className="w-checkbox-input" name="somministrato" checked={somministrato} value={somministrato} onChange={e => onChangeSetCheckbox(e)} />
                                                    <span className="label_text w-form-label" htmlFor="checkbox-3">Si</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="div_wrap_100">
                                        <div className="div_wrap_25">
                                            <div className="col_input col_select">
                                                <label htmlFor="name">Mansione</label>
                                                <div data-hover="false" data-delay="0" className="select w-dropdown">
                                                    <select className="form-control select" name="idMansioni" onChange={e => onChangeSetDatiDipendente(e)} required>
                                                        <option selected value={null} disabled >Seleziona mansione</option>
                                                        {mansioniJSON.mansioni.map((el) => (
                                                            <option selected={el.id == idMansioni} value={el.id}>{el.nota}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="div_wrap_25">
                                            <div className="col_input">
                                                <label htmlFor="name">Eccedenza</label>
                                                <label className="w-checkbox checkbox-field m_right_10">
                                                    <input type="checkbox" id={"eccedenza"} className="w-checkbox-input" name="eccedenza" checked={eccedenza} value={eccedenza} onChange={e => onChangeSetCheckbox(e)} />
                                                    <span className="label_text w-form-label" htmlFor="checkbox-3">Si</span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="div_wrap_25">
                                            <div className="col_input">
                                                <label htmlFor="name">Prestabile</label>
                                                <label className="w-checkbox checkbox-field m_right_10">
                                                    <input type="checkbox" id={"prestabile"} className="w-checkbox-input" name="prestabile" checked={prestabile} value={prestabile} onChange={e => onChangeSetCheckbox(e)} />
                                                    <span className="label_text w-form-label" htmlFor="checkbox-3">Si</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="div_wrap_100 mb-5">
                                        <div className="div_wrap_50">
                                        <div className="col_input">
                                                <label htmlFor="name">Sussidiari</label>
                                                <label className="w-checkbox checkbox-field m_right_10">
                                                    <input type="checkbox" id={"sussidiari"} className="w-checkbox-input" name="sussidiari" checked={sussidiari} value={sussidiari} onChange={e => onChangeSetCheckbox(e)} />
                                                    <span className="label_text w-form-label" htmlFor="checkbox-3">Si</span>
                                                </label>
                                            </div>
                                        </div>
                                        {sussidiari ? 
                                        <div className="div_wrap_50">
                                        <div className="col_input">
                                            <label htmlFor="name">Nome sussidiari</label>
                                            <input type="text" className="input w-input" maxLength="256" name="nomeSussidiari" data-name="nomeSussidiari" value={nomeSussidiari} placeholder="Inserisci nome sussidiari" id="nomeSussidiari" onChange={e => onChangeSetDatiDipendente(e)} />
                                        </div>
                                        </div>
                                        :
                                        ''
                                        }
                                    </div>
                                    <div className="div_wrap_100">
                                        <div className="div_wrap_50">
                                            <div className="col_input">
                                                <label htmlFor="name">Note</label>
                                                <textarea type="text" className="input w-input altezza_text" maxLength="500" name="note" value={note} data-name="note" placeholder="Inserisci note" id="note" onChange={e => onChangeSetDatiDipendente(e)} />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="item_4 m_top_5">
                        <div className="item_5_function">
                            <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc3d-b8dac9c0" className="div_item_empty"></div>
                            <div id="w-node-d27a7e1c-7034-f43d-edeb-5fcab2bbc790-b8dac9c0" className="div_item_empty"></div>
                            <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc40-b8dac9c0" className="div_item_empty"></div>
                            <div id="w-node-d409c487-9a0c-6b1f-803c-c7c11a8afa3e-b8dac9c0" className="div_item_button">
                                <a onClick={(e) => onSubmitCreaDipendente(e)} className="button w-button">Salva</a>
                            </div>
                            <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc43-b8dac9c0" className="div_item_button">
                                <a id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc44-b8dac9c0" onClick={() => setOpenNuovoDipendente(false)} className="button w-button">Chiudi</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


NuovoDipendente.protoTypes = {
    creaNuovoDipendente: PropTypes.func.isRequired,
    getFiliali: PropTypes.func.isRequired,
    alert: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    alert: state.alert,
    auth: state.auth
});

export default connect(mapStateToProps, { creaNuovoDipendente, getFiliali })(NuovoDipendente);