import React from 'react';
const QuadratureElementVuoto = () => {

    
    
    return (
        <div key={"quadraturaOreTotaleVuoto"} className={`w-layout-grid div_column_quadrature_ore riga-quadratura-vuota`}>
                    <div className="div_item_column div_item_column_quadratura_ore">
                    <div className="item_table_quadratura"><span></span></div>
                    </div>
                    <div className="div_item_column div_item_column_quadratura_ore">
                    <div className="item_table_quadratura"><span></span></div>
                    </div>
                    <div className="div_item_column div_item_column_quadratura_ore">
                    <div className="item_table_quadratura"><span></span></div>
                    </div>
                    <div className="div_item_column div_item_column_quadratura_ore">
                    <div className="item_table_quadratura"><span></span></div>
                    </div>
                    <div className="div_item_column div_item_column_quadratura_ore">
                    <div className="item_table_quadratura"><span></span></div>
                    </div>
                    <div className="div_item_column div_item_column_quadratura_ore">
                    <div className="item_table_quadratura"><span></span></div>
                    </div>
                    <div className="div_item_column div_item_column_quadratura_ore">
                    <div className="item_table_quadratura"><span></span></div>
                    </div>
                    <div className="div_item_column div_item_column_quadratura_ore">
                    <div className="item_table_quadratura"><span></span></div>
                    </div>
                    <div className="div_item_column div_item_column_quadratura_ore">
                    <div className="item_table_quadratura"><span></span></div>
                    </div>
                    <div className="div_item_column div_item_column_quadratura_ore">
                    <div className="item_table_quadratura"><span></span></div>
                    </div>
                    <div className="div_item_column div_item_column_quadratura_ore">
                    <div className="item_table_quadratura"><span></span></div>
                    </div>
                    <div className="div_item_column div_item_column_quadratura_ore">
                    <div className="item_table_quadratura"><span></span></div>
                    </div>
                    <div className="div_item_column div_item_column_quadratura_ore">
                    <div className="item_table_quadratura"><span></span></div>
                    </div>
                    <div className="div_item_column div_item_column_quadratura_ore">
                    <div className="item_table_quadratura"><span></span></div>
                    </div>
                    <div className="div_item_column div_item_column_quadratura_ore">
                    <div className="item_table_quadratura"><span></span></div>
                    </div>
        </div>
    )
}

export default QuadratureElementVuoto;