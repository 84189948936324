import { GET_DIPENDENTI, GET_DIPENDENTI_CONTRATTO_JOB, GET_DIPENDENTI_CONTRATTO, GET_DIPENDENTI_JOB, FILTRA_DIPENDENTI, GET_DIPENDENTE, CREA_DIPENDENTE, MODIFICA_DIPENDENTE, GET_GIORNI_ECCEZIONE, GET_GIORNI_ECCEZIONE_ASSENTEISMO, GET_GIORNI_ECCEZIONE_DIPENDENTE, GET_INCARICHI_DIPENDENTE, GET_INCARICHI_TUTTI_DIPENDENTI_SALVATI, AGGIUNGI_GIORNO_ECCEZIONE, MODIFICA_GIORNO_ECCEZIONE, AGGIUNGI_GIORNO_ECCEZIONE_LUNGO, ELIMINA_GIORNO_ECCEZIONE, ELIMINA_GIORNO_ECCEZIONE_LUNGO, ELIMINA_GIORNO_ECCEZIONE_LUNGO_RICHIESTA, CONFERMA_GIORNO_ECCEZIONE, CONFERMA_GIORNI_ECCEZIONE, UPLOAD_DOCUMENTO, ELIMINA_DOCUMENTO, UPLOAD_ERROR, LOADING_DIPENDENTI, SALVA_INCARICHI_DIPENDENTE, GET_INCARICHI_DIPENDENTE_SALVATI, CLEAN_DIPENDENTI, CLEAN_GIORNI_ECCEZIONE, CLEAN_INCARICHI_PDF_DIPENDENTI, CLEAN_INCARICHI_DIPENDENTE, ELIMINA_PDF, DIPENDENTE_ERROR } from "../actions/types";

const initialState = {
    dipendente: null,
    dipendenti: [],
    giorniEccezione: [],
    numberDipendenti: 0,
    incarichiDipendente: [],
    incarichiDipendenteSalvati: [],
    datiAssenteismo: {giorniEccezione: [], dipendenti: []},
    jobId: null,
    error: {},
    loading: true
}

export default function(state = initialState, action){
    const { type, payload } = action;

    switch(type){
        case GET_DIPENDENTI:
        case FILTRA_DIPENDENTI:
        case GET_DIPENDENTI_CONTRATTO:
            return {
                ...state,
                dipendenti: payload.dipendenti,
                //numberDipendenti: payload.dipendenti.length,
                loading: false
            };
        case GET_DIPENDENTI_CONTRATTO_JOB:
            return {
                ...state,
                jobId: payload,
                loading: false
            };
        case GET_DIPENDENTE:
        case GET_GIORNI_ECCEZIONE_DIPENDENTE:
        case ELIMINA_GIORNO_ECCEZIONE:
        case MODIFICA_DIPENDENTE:
            return {
                ...state,
                dipendente: payload,
                loading: false
            };
        case GET_GIORNI_ECCEZIONE_ASSENTEISMO:
            return {
                ...state,
                datiAssenteismo: payload,
                loading: false
            };
        case MODIFICA_GIORNO_ECCEZIONE:
            let arrayModificaGiornoEccezione = [...state.giorniEccezione];
            const indexModificaGiornoEccezione = state.giorniEccezione.findIndex(x => x._id == payload._id );
            if(indexModificaGiornoEccezione>=0){
                arrayModificaGiornoEccezione[indexModificaGiornoEccezione] = payload
            }
            return {
                ...state,
                giorniEccezione: arrayModificaGiornoEccezione,
                loading: false
            };
        case AGGIUNGI_GIORNO_ECCEZIONE:
        case ELIMINA_GIORNO_ECCEZIONE_LUNGO_RICHIESTA:
            let arrayDipendenti = [...state.dipendenti];
            const index = state.dipendenti.findIndex(x => x._id == payload._id );
            if(index>=0){
                arrayDipendenti[index] = payload
            }
            return {
                ...state,
                dipendenti: arrayDipendenti,
                //numberDipendenti: arrayDipendenti.length,
                loading: false
            };
        case AGGIUNGI_GIORNO_ECCEZIONE_LUNGO:
            let arrayGiorniEccezione = [...state.giorniEccezione];
            const arrayGiorniEccezioneConcatenated = arrayGiorniEccezione.concat(payload);

            return {
                ...state,
                giorniEccezione: arrayGiorniEccezioneConcatenated,
                loading: false
            };
        case CONFERMA_GIORNO_ECCEZIONE:
            let arrayGiorniEcceioneAggiungi = [...state.giorniEccezione];
            const indexAggiungiGiornoEccezione = state.giorniEccezione.findIndex(x => x._id == payload._id );
            if(indexAggiungiGiornoEccezione>=0){
                arrayGiorniEcceioneAggiungi[indexAggiungiGiornoEccezione] = payload
            }
            return {
                ...state,
                giorniEccezione: arrayGiorniEcceioneAggiungi,
                loading: false
            };
        case CONFERMA_GIORNI_ECCEZIONE:
            let arrayGiorniEccezioneAggiungi = [...state.giorniEccezione];
            payload.map(giorno => {
                let indexConfermaGiornoEccezione = state.giorniEccezione.findIndex(x => x._id == giorno._id );
                if(indexConfermaGiornoEccezione>=0){
                    arrayGiorniEccezioneAggiungi[indexConfermaGiornoEccezione] = giorno
                }
            })
            return {
                ...state,
                giorniEccezione: arrayGiorniEccezioneAggiungi,
                loading: false
            };
        case ELIMINA_GIORNO_ECCEZIONE_LUNGO:
            let arrayGiorniEccezioneElimina = [...state.giorniEccezione];
            arrayGiorniEccezioneElimina = arrayGiorniEccezioneElimina.filter(function (el) { return el._id != payload});
            return {
                ...state,
                giorniEccezione: arrayGiorniEccezioneElimina,
                loading: false
            };
        case ELIMINA_PDF:
            let arrayPdfElimina = [...state.incarichiDipendenteSalvati];
            arrayPdfElimina = arrayPdfElimina.filter(function (el) { return el._id != payload});
            return {
                ...state,
                incarichiDipendenteSalvati: arrayPdfElimina,
                loading: false
            };
        case CREA_DIPENDENTE:
            let arrayCreaDipendente = [...state.dipendenti];
            arrayCreaDipendente.push(payload);
            arrayCreaDipendente.sort((a,b) => (a.cognome > b.cognome) ? 1 : ((b.cognome > a.cognome) ? -1 : 0)); 
            return {
                ...state,
                dipendenti: arrayCreaDipendente,
                //numberDipendenti: arrayCreaDipendente.length,
                loading: false
            };
        case LOADING_DIPENDENTI:
            return {
                ...state,
                loading: true
            }
        case UPLOAD_DOCUMENTO:
            let dipendente = state.dipendente;
            dipendente.documenti.push(payload)
            return {
                ...state,
                dipendente: dipendente,
                loading: false
            };
        case ELIMINA_DOCUMENTO:
            let dipendenteElimina = state.dipendente;
            let documenti = dipendenteElimina.documenti.filter(function (el) { return el.url != payload});
            dipendenteElimina.documenti = documenti;
            return {
                ...state,
                dipendente: dipendenteElimina,
                loading: false
            };
        case CLEAN_DIPENDENTI:
            return {
                ...state,
                dipendenti: [],
                loading: true
            };
        case CLEAN_GIORNI_ECCEZIONE:
            return {
                ...state,
                giorniEccezione: [],
                loading: true
            };
        case CLEAN_INCARICHI_PDF_DIPENDENTI:
            return {
                ...state,
                incarichiDipendenteSalvati: [],
                loading: false
            };
        case CLEAN_INCARICHI_DIPENDENTE:
            return {
                ...state,
                incarichiDipendente: [],
                loading: false
            }
        case GET_DIPENDENTI_JOB:
            return {
                ...state,
                dipendenti: payload.dipendenti,
                //numberDipendenti: payload.dipendenti.length,
                loading: false,
                jobId: null
            };
        case GET_GIORNI_ECCEZIONE:
            return {
                ...state,
                giorniEccezione: payload,
                loading: false
            };
        case GET_INCARICHI_DIPENDENTE:
            return {
                ...state,
                incarichiDipendente: payload,
                loading: false
            };
        case SALVA_INCARICHI_DIPENDENTE:
            let arrayIncarichiSalvati = state.incarichiDipendenteSalvati;
            arrayIncarichiSalvati.push(payload)
            return {
                ...state,
                incarichiDipendenteSalvati: arrayIncarichiSalvati,
                loading: false
            };
        case GET_INCARICHI_DIPENDENTE_SALVATI:
        case GET_INCARICHI_TUTTI_DIPENDENTI_SALVATI:
            return {
                ...state,
                incarichiDipendenteSalvati: payload,
                loading: false
            };
        case DIPENDENTE_ERROR:
        case UPLOAD_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            };
        default:
            return state;
    }
}