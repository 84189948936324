import React from 'react';
import { connect } from 'react-redux';
import Spinner from '../../layout/Spinner';

const ColonneFatturazioneOTS = ({ auth: { user }, setColonnaPersonalizzata1, colonnaPersonalizzata1, setColonnaPersonalizzata2, colonnaPersonalizzata2, incarichiLoading, incarichiDipendenteArray, onChangeSetDisponibilitaDipendenteSalvati, onChangeOrariServiziDipendenteSalvatiOTS, onChangeOreStraordinarie, onChangeOreFlessibilitaOTS, onChangeOreColonnaPersonalizzata, calcoloGiorniLavorati, calcoloTotaliOre, calcoloTotaliOreStrOTS, calcoloTotaliFlessibilita, fromMinutiToOreString, indennita, getFirstLetterOfDay, disponibilitaJSON, onChangeSetIndennita, setIndennitaPersonalizzata1, setIndennitaPersonalizzata2, setIndennitaPersonalizzata3, indennitaPersonalizzata1, indennitaPersonalizzata2, indennitaPersonalizzata3, calcolaStraordinari48, onChangeOreSenzaCalcoli, onChangeOreStraordinariOTSSenzaCalcoli }) => {

    const onChangeSetIndennitaPersonalizzata1 = (e) => {
        setIndennitaPersonalizzata1({...indennitaPersonalizzata1, [e.target.name]: e.target.value})
    }

    const onChangeSetIndennitaPersonalizzata2 = (e) => {
        setIndennitaPersonalizzata2({...indennitaPersonalizzata2, [e.target.name]: e.target.value})
    }

    const onChangeSetIndennitaPersonalizzata3 = (e) => {
        setIndennitaPersonalizzata3({...indennitaPersonalizzata3, [e.target.name]: e.target.value})
    }

    const valoreIndennitaInOre = (indennitaElement) => {
        if(indennitaElement.modalita == 'ore'){
            let ore = Number(indennitaElement.oreGiorni) / 60
            ore = ore.length > 2 ? ore.toFixed(2) : ore
            return ore > 0 ? ore : "" 

        }else{
            return indennitaElement.oreGiorni > 0 ? indennitaElement.oreGiorni : ""
        }
    
    }

    return (
        <div className="row">
            <div className="item_2">
                <div className="intestazione_table">
                    <div className="w-layout-grid div_column_incarichi_dipendente_modifica intestazione">
                        <div className="div_item_column">
                            <div className="item_table"></div>
                        </div>
                        <div className="div_item_column">
                            <div className="item_table"></div>
                        </div>
                        <div className="div_item_column">
                            <div className="item_table">Dis.</div>
                        </div>
                        <div className="div_item_column">
                            <div className="item_table">Eff.</div>
                        </div>
                        <div className="div_item_column">
                            <div className="item_table">Dalle</div>
                        </div>
                        <div className="div_item_column">
                            <div className="item_table">Alle</div>
                        </div>
                        <div className="div_item_column">
                            <div className="item_table">Ore ord.</div>
                        </div>
                        <div className="div_item_column">
                            <div className="item_table">Ore str. 1</div>
                        </div>
                        <div className="div_item_column">
                            <div className="item_table">Ore str. 2</div>
                        </div>
                        <div className="div_item_column">
                            <div className="item_table">Ore str. notte</div>
                        </div>
                        <div className="div_item_column">
                            <div className="item_table">Ore str. fest. giorno</div>
                        </div>
                        <div className="div_item_column">
                        <div className="item_table">Ore str. fest. notte</div>
                        </div>
                        <div className="div_item_column">
                            <div className="item_table">Dom. giorno</div>
                        </div>
                        <div className="div_item_column">
                        <div className="item_table">Dom. notte</div>
                        </div>
                        <div className="div_item_column">
                            <div className="item_table">Ore Sup. part-time</div>
                        </div>
                        <div className="div_item_column">
                            <div className="item_table">Fless.</div>
                        </div>
                        <div className="div_item_column">
                            <div className="item_table">Ore Non Lav.</div>
                        </div>
                        <div className="div_item_column">
                            <div className="item_table">
                                <select className="form-control select" name="colonnaPersonalizzata1" onChange={e => setColonnaPersonalizzata1(e.target.value)}>
                                    <option selected value={null}></option>
                                    <option value={"oreSciopero"} >Ore sciopero</option>
                                    <option value={"oreAllattamento"} >Ore allattamento</option>
                                    <option value={"permessiL104"} >Permessi L 104</option>
                                    <option value={"oreROLGoduti"} >Ore ROL goduti</option>
                                    <option value={"bancaOreGoduta"} >Banca ore goduta</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                {incarichiLoading ? <Spinner /> :
                    <div className="body_table full">
                        {incarichiDipendenteArray.map((el, i) => (
                            <div key={"contratto" + i} className={`w-layout-grid div_column_incarichi_dipendente_modifica cursor ${el.stato == "archiviata" ? "green_label" : ''}`}>
                                <div className="div_item_column">
                                    <div className="item_table">{el.data.split('-')[0]}</div>
                                </div>
                                <div className={`${el.giornoSettimana == 0 ? "red_soft " : ""}div_item_column`}>
                                    <div className="item_table">{getFirstLetterOfDay(el.giornoSettimana)}</div>
                                </div>
                                <div className="div_item_column" >
                                    <div className="item_table">{el.disponibilita}</div>
                                </div>
                                <div className="div_item_column">
                                    <select className="form-control select" name="effettivo" onChange={e => onChangeSetDisponibilitaDipendenteSalvati(e, i)} required>
                                        {disponibilitaJSON.disponibilita.map((disponibilita) => (
                                            <option selected={disponibilita.codice == el.effettivo} value={disponibilita.codice}>{disponibilita.codice} - {disponibilita.nome}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="div_item_column">
                                    <div className="item_table">
                                        <input type="time" className="input w-input" maxLength="256" name="oraInizio" data-name="oraInizio" value={el.oraInizio} id={`oraInizio-${i}`} onChange={e => onChangeOrariServiziDipendenteSalvatiOTS(e, i)} />
                                    </div>
                                </div>
                                <div className="div_item_column">
                                    <div className="item_table">
                                        <input type="time" className="input w-input" maxLength="256" name="oraFine" data-name="oraFine" value={el.oraFine} id={`oraFine-${i}`} onChange={e => onChangeOrariServiziDipendenteSalvatiOTS(e, i)} />
                                    </div>
                                </div>
                                <div className="div_item_column">
                                    <div className="item_table" >
                                        <input type="time" className="input w-input" maxLength="256" name="oreOrdinarie" data-name="oreOrdinarie" value={el.oreOrdinarie} id={`oreOrdinarie-${i}`} onChange={e => onChangeOreSenzaCalcoli(e, i)} />
                                    </div>
                                </div>
                                <div className="div_item_column">
                                    <div className="item_table" >
                                        {false ?
                                            <input type="time" className="input w-input" maxLength="256" name="oreStraordinarie" data-name="oreStraordinarie" value={calcolaStraordinari48(el).primaColonna} id={`oreStraordinarie-${i}`} onChange={e => onChangeOreStraordinariOTSSenzaCalcoli(e, i)} />
                                            :
                                            <input type="time" className="input w-input" maxLength="256" name="oreStraordinarieOTS1" data-name="oreStraordinarieOTS1" value={el.oreStraordinarieOTS1} id={`oreStraordinarieOTS1-${i}`} onChange={e => onChangeOreSenzaCalcoli(e, i)} />
                                        }
                                    </div>
                                </div>
                                <div className="div_item_column">
                                    <div className="item_table">

                                    {false ?
                                            <input type="time" className="input w-input" maxLength="256" name="oreStraordinarie" data-name="oreStraordinarie" value={calcolaStraordinari48(el).secondaColonna} id={`oreStraordinarie-${i}`} onChange={e => onChangeOreStraordinariOTSSenzaCalcoli(e, i)} />
                                            :
                                            <input type="time" className="input w-input" maxLength="256" name="oreStraordinarieOTS2" data-name="oreStraordinarieOTS2" value={el.oreStraordinarieOTS2} id={`oreStraordinarieOTS2-${i}`} onChange={e => onChangeOreSenzaCalcoli(e, i)} />
                                        }
                                    </div>
                                </div>
                                <div className="div_item_column">
                                    <div className="item_table">
                                        <input type="time" className="input w-input" maxLength="256" name="oreStraordinarieNotteOTS" data-name="oreStraordinarieNotteOTS" value={el.oreStraordinarieNotteOTS} id={`oreStraordinarieNotteOTS-${i}`} onChange={e => onChangeOreSenzaCalcoli(e, i)} />
                                    </div>
                                </div>
                                <div className="div_item_column">
                                    <div className="item_table" >
                                        <input type="time" className="input w-input" maxLength="256" name="oreStraordinarieFestiveOTSGiorno" data-name="oreStraordinarieFestiveOTSGiorno" value={el.oreStraordinarieFestiveOTSGiorno} id={`oreStraordinarieFestiveOTSGiorno-${i}`} onChange={e => onChangeOreSenzaCalcoli(e, i)} />
                                    </div>
                                </div>
                                <div className="div_item_column">
                                    <div className="item_table" >
                                        <input type="time" className="input w-input" maxLength="256" name="oreStraordinarieFestiveOTSNotte" data-name="oreStraordinarieFestiveOTSNotte" value={el.oreStraordinarieFestiveOTSNotte} id={`oreStraordinarieFestiveOTSNotte-${i}`} onChange={e => onChangeOreSenzaCalcoli(e, i)} />
                                    </div>
                                </div>
                                <div className="div_item_column">
                                    <div className="item_table" >
                                        <input type="time" className="input w-input" maxLength="256" name="indennitaDomenicaleOTSGiorno" data-name="indennitaDomenicaleOTSGiorno" value={el.indennitaDomenicaleOTSGiorno} id={`indennitaDomenicaleOTSGiorno-${i}`} onChange={e => onChangeOreSenzaCalcoli(e, i)} />
                                    </div>
                                </div>
                                <div className="div_item_column">
                                    <div className="item_table" >
                                        <input type="time" className="input w-input" maxLength="256" name="indennitaDomenicaleOTSNotte" data-name="indennitaDomenicaleOTSNotte" value={el.indennitaDomenicaleOTSNotte} id={`indennitaDomenicaleOTSNotte-${i}`} onChange={e => onChangeOreSenzaCalcoli(e, i)} />
                                    </div>
                                </div>
                                <div className="div_item_column">
                                    <div className="item_table" >
                                        <input type="time" className="input w-input" maxLength="256" name="oreSupPartTime" data-name="oreSupPartTime" value={el.oreSupPartTime} id={`oreSupPartTime-${i}`} onChange={e => onChangeOreSenzaCalcoli(e, i)} />
                                    </div>
                                </div>
                                <div className="div_item_column">
                                    <div className="item_table">
                                        <input type="number" className="input w-input" maxLength="256" name="flessibilita" data-name="flessibilita" value={el.flessibilita} id={`flessibilita-${i}`} onChange={e => onChangeOreFlessibilitaOTS(e, i)}/>
                                    </div>
                                </div>
                                <div className="div_item_column">
                                    <div className="item_table" >
                                        <input type="time" className="input w-input" maxLength="256" name="oreNonLavorate" data-name="oreNonLavorate" value={el.oreNonLavorate} id={`oreNonLavorate-${i}`} onChange={e => onChangeOreSenzaCalcoli(e, i)} />
                                    </div>
                                </div>
                                <div className="div_item_column">
                                    <div className="item_table">
                                        {colonnaPersonalizzata1 ?
                                            <input type="time" className="input w-input" maxLength="256" name={colonnaPersonalizzata1} data-name={colonnaPersonalizzata1} value={el[colonnaPersonalizzata1]} id={`${colonnaPersonalizzata1}-${i}`} />
                                            :
                                            ""
                                        }
                                    </div>
                                </div>
                            </div>
                        ))}
                        <div key="totali" className="w-layout-grid div_column_incarichi_dipendente_modifica">
                            <div className="div_item_column">
                                <div className="item_table">{calcoloGiorniLavorati(incarichiDipendenteArray)}</div>
                            </div>
                            <div className="div_item_column">
                                <div className="item_table"></div>
                            </div>
                            <div className="div_item_column">
                                <div className="item_table"></div>
                            </div>
                            <div className="div_item_column">
                                <div className="item_table"></div>
                            </div>
                            <div className="div_item_column">
                                <div className="item_table"></div>
                            </div>
                            <div className="div_item_column">
                                <div className="item_table"></div>
                            </div>
                            <div className="div_item_column">
                                <div className="item_table" >{calcoloTotaliOre(incarichiDipendenteArray, "oreOrdinarie")}</div>
                            </div>
                            {false ?
                            <>
                            <div className="div_item_column">
                                <div className="item_table" >{calcoloTotaliOreStrOTS(incarichiDipendenteArray, "oreStraordinarieMin48")}</div>
                            </div>
                            <div className="div_item_column">
                                <div className="item_table" >{calcoloTotaliOreStrOTS(incarichiDipendenteArray, "oreStraordinarieMax48")}</div>
                            </div>
                            </>
                            :
                            <>
                            <div className="div_item_column">
                                <div className="item_table" >{calcoloTotaliOre(incarichiDipendenteArray, "oreStraordinarieOTS1")}</div>
                            </div>
                            <div className="div_item_column">
                                <div className="item_table" >{calcoloTotaliOre(incarichiDipendenteArray, "oreStraordinarieOTS2")}</div>
                            </div>
                            </>
                            }
                            <div className="div_item_column">
                                <div className="item_table" >{calcoloTotaliOreStrOTS(incarichiDipendenteArray, "oreStraordinarieNotte")}</div>
                            </div>
                            <div className="div_item_column">
                                <div className="item_table" >{calcoloTotaliOre(incarichiDipendenteArray, "oreStraordinarieFestiveOTSGiorno")}</div>
                            </div>
                            <div className="div_item_column">
                                <div className="item_table" >{calcoloTotaliOre(incarichiDipendenteArray, "oreStraordinarieFestiveOTSNotte")}</div>
                            </div>
                            <div className="div_item_column">
                                <div className="item_table" >{calcoloTotaliOre(incarichiDipendenteArray, "indennitaDomenicaleOTSGiorno")}</div>
                            </div>
                            <div className="div_item_column">
                                <div className="item_table" >{calcoloTotaliOre(incarichiDipendenteArray, "indennitaDomenicaleOTSNotte")}</div>
                            </div>
                            <div className="div_item_column">
                                <div className="item_table" >{calcoloTotaliOre(incarichiDipendenteArray, "oreSupPartTime")}</div>
                            </div>
                            <div className={`div_item_column${calcoloTotaliFlessibilita(incarichiDipendenteArray) == 0 ? "" : " green_soft"}`}>
                                <div className="item_table">{fromMinutiToOreString(calcoloTotaliFlessibilita(incarichiDipendenteArray))}</div>
                            </div>
                            <div className={`div_item_column${calcoloTotaliOre(incarichiDipendenteArray, "oreNonLavorate") == "00:00" ? " green_soft" : " red_soft"}`}>
                                <div className="item_table">{calcoloTotaliOre(incarichiDipendenteArray, "oreNonLavorate")}</div>
                            </div>
                            <div className="div_item_column">
                                <div className="item_table">
                                    {colonnaPersonalizzata1 ?
                                        calcoloTotaliOre(incarichiDipendenteArray, colonnaPersonalizzata1)
                                        :
                                        ""
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="row" style={{ marginTop: "50px" }}>
                            <div className="item_2">
                                <div className="w-layout-grid div_column_incarichi_dipendente_indennita intestazione">
                                    <div className="div_item_column">
                                        <div className="item_table">Voce</div>
                                    </div>
                                    <div className="div_item_column" >
                                        <div className="item_table">Ore/Giorni</div>
                                    </div>
                                    <div className="div_item_column" >
                                        <div className="item_table"></div>
                                    </div>
                                    <div className="div_item_column" >
                                        <div className="item_table">Importo</div>
                                    </div>
                                    <div className="div_item_column" >
                                        <div className="item_table">Annotazioni</div>
                                    </div>
                                </div>
                                {indennita.map((indennitaElement, i) => (
                                    <div className="w-layout-grid div_column_incarichi_dipendente_indennita">
                                        <div className="div_item_column">
                                            <div className="item_table">{indennitaElement.codice}</div>
                                        </div>
                                        <div className="div_item_column">
                                        <div className="item_table"><input type="number" step="0.01" className="input w-input" name={`input-oreGiorni${indennitaElement.codice}`} value={valoreIndennitaInOre(indennitaElement)} data-name={`input-oreGiorni-${indennitaElement.codice}`} id={`input-oreGiorni-${indennitaElement.codice}`} onChange={e => onChangeSetIndennita(i, "oreGiorni", e.target.value)} /></div>
                                        </div>
                                        <div className="div_item_column">
                                            <div className="item_table">
                                                {indennitaElement.modalita == 'giorni' ? 'giorni' : indennitaElement.modalita == 'ore' ? 'ore' : indennitaElement.modalita == 'importo' ? 'importo' : ''}
                                            </div>
                                        </div>
                                        <div className="div_item_column">
                                            <div className="item_table"><input type="text" className="input w-input" name={`input-importo${indennitaElement.codice}`} value={indennitaElement.importo > 0 ? indennitaElement.importo : ""} data-name={`input-importo-${indennitaElement.codice}`} id={`input-importo-${indennitaElement.codice}`} onChange={e => onChangeSetIndennita(i, "importo", e.target.value)} /></div>
                                        </div>
                                        <div className="div_item_column">
                                            <div className="item_table"><input type="text" className="input w-input" name={`input-${indennitaElement.codice}`} value={indennitaElement.annotazioni} data-name={`input-${indennitaElement.codice}`} id={`input-${indennitaElement.codice}`} onChange={e => onChangeSetIndennita(i, "annotazioni", e.target.value)} /></div>
                                        </div>
                                    </div>
                                ))}
                                 <div className="w-layout-grid div_column_incarichi_dipendente_indennita_personalizzata">
                                        <div className="div_item_column">
                                            <div className="item_table"><input type="number" className="input w-input"  min="0" name="codice" value={indennitaPersonalizzata1.codice} data-name="input-codice-indennita-personalizzata" id="input-codice-indennita-personalizzata" onChange={e => onChangeSetIndennitaPersonalizzata1(e)} /></div>
                                        </div>
                                        <div className="div_item_column">
                                            <div className="item_table"><input type="number" className="input w-input"  min="0" name="oreGiorni"  value={indennitaPersonalizzata1.oreGiorni} data-name="input-oreGiorni-indennita-personalizzata" id="input-oreGiorni-indennita-personalizzata" onChange={e => onChangeSetIndennitaPersonalizzata1(e)} /></div>
                                        </div>
                                        <select className="form-control" name="modalita" onChange={e => onChangeSetIndennitaPersonalizzata1(e)} required>
                                            <option selected={indennitaPersonalizzata1.modalita == 'ore'} value={'ore'}>ore</option>
                                            <option selected={indennitaPersonalizzata1.modalita == 'giorni'} value={'giorni'}>giorni</option>
                                            <option selected={indennitaPersonalizzata1.modalita == 'importo'} value={'importo'}>importo</option>
                                            </select>
                                        <div className="div_item_column">
                                            <div className="item_table"><input type="text" className="input w-input"  min="0" name="importo"  value={indennitaPersonalizzata1.importo} data-name="input-importo-indennita-personalizzata" id="input-importo-indennita-personalizzata" onChange={e => onChangeSetIndennitaPersonalizzata1(e)} /></div>
                                        </div>
                                        <div className="div_item_column">
                                            <div className="item_table"><input type="text" className="input w-input"  name="annotazioni"  value={indennitaPersonalizzata1.annotazioni} data-name="input-indennita-personalizzata" id="input-indennita-personalizzata" onChange={e => onChangeSetIndennitaPersonalizzata1(e)} /></div>
                                        </div>
                                    </div>
                                    <div className="w-layout-grid div_column_incarichi_dipendente_indennita_personalizzata">
                                        <div className="div_item_column">
                                            <div className="item_table"><input type="number" className="input w-input"  min="0" name="codice" value={indennitaPersonalizzata2.codice} data-name="input-codice-indennita-personalizzata" id="input-codice-indennita-personalizzata" onChange={e => onChangeSetIndennitaPersonalizzata2(e)} /></div>
                                        </div>
                                        <div className="div_item_column">
                                            <div className="item_table"><input type="number" className="input w-input"  min="0" name="oreGiorni"  value={indennitaPersonalizzata2.oreGiorni} data-name="input-oreGiorni-indennita-personalizzata" id="input-oreGiorni-indennita-personalizzata" onChange={e => onChangeSetIndennitaPersonalizzata2(e)} /></div>
                                        </div>
                                        <select className="form-control" name="modalita" onChange={e => onChangeSetIndennitaPersonalizzata2(e)} required>
                                            <option selected={indennitaPersonalizzata2.modalita == 'ore'} value={'ore'}>ore</option>
                                            <option selected={indennitaPersonalizzata2.modalita == 'giorni'} value={'giorni'}>giorni</option>
                                            <option selected={indennitaPersonalizzata2.modalita == 'importo'} value={'importo'}>importo</option>
                                            </select>
                                        <div className="div_item_column">
                                            <div className="item_table"><input type="text" className="input w-input"  min="0" name="importo"  value={indennitaPersonalizzata2.importo} data-name="input-importo-indennita-personalizzata" id="input-importo-indennita-personalizzata" onChange={e => onChangeSetIndennitaPersonalizzata2(e)} /></div>
                                        </div>
                                        <div className="div_item_column">
                                            <div className="item_table"><input type="text" className="input w-input"  name="annotazioni"  value={indennitaPersonalizzata2.annotazioni} data-name="input-indennita-personalizzata" id="input-indennita-personalizzata" onChange={e => onChangeSetIndennitaPersonalizzata2(e)} /></div>
                                        </div>
                                    </div>
                                    <div className="w-layout-grid div_column_incarichi_dipendente_indennita_personalizzata">
                                        <div className="div_item_column">
                                            <div className="item_table"><input type="number" className="input w-input"  min="0" name="codice" value={indennitaPersonalizzata3.codice} data-name="input-codice-indennita-personalizzata" id="input-codice-indennita-personalizzata" onChange={e => onChangeSetIndennitaPersonalizzata3(e)} /></div>
                                        </div>
                                        <div className="div_item_column">
                                            <div className="item_table"><input type="number" className="input w-input"  min="0" name="oreGiorni"  value={indennitaPersonalizzata3.oreGiorni} data-name="input-oreGiorni-indennita-personalizzata" id="input-oreGiorni-indennita-personalizzata" onChange={e => onChangeSetIndennitaPersonalizzata3(e)} /></div>
                                        </div>
                                        <select className="form-control" name="modalita" onChange={e => onChangeSetIndennitaPersonalizzata3(e)} required>
                                            <option selected={indennitaPersonalizzata3.modalita == 'ore'} value={'ore'}>ore</option>
                                            <option selected={indennitaPersonalizzata3.modalita == 'giorni'} value={'giorni'}>giorni</option>
                                            <option selected={indennitaPersonalizzata3.modalita == 'importo'} value={'importo'}>importo</option>
                                            </select>
                                        <div className="div_item_column">
                                            <div className="item_table"><input type="text" className="input w-input"  min="0" name="importo"  value={indennitaPersonalizzata3.importo} data-name="input-importo-indennita-personalizzata" id="input-importo-indennita-personalizzata" onChange={e => onChangeSetIndennitaPersonalizzata3(e)} /></div>
                                        </div>
                                        <div className="div_item_column">
                                            <div className="item_table"><input type="text" className="input w-input"  name="annotazioni"  value={indennitaPersonalizzata3.annotazioni} data-name="input-indennita-personalizzata" id="input-indennita-personalizzata" onChange={e => onChangeSetIndennitaPersonalizzata3(e)} /></div>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, {})(ColonneFatturazioneOTS);