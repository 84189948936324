import { combineReducers } from 'redux';

import sidebar from './sidebar';
import alert from './alert';
import auth from './auth';
import cliente from './cliente';
import organizzazione from './organizzazione';
import filiale from './filiale';
import puntoVendita from './puntoVendita';
import dipendente from './dipendente';
import richiesta from './richiesta';
import dati from './dati';
import invioTurni from './invioTurni';
import documento from './documento';
import impostazione from './impostazione';
import utenteEsterno from './utenteEsterno';



export default combineReducers ({
    sidebar,
    alert,
    auth,
    cliente,
    organizzazione,
    filiale,
    puntoVendita,
    dipendente,
    richiesta,
    dati,
    invioTurni,
    documento,
    impostazione,
    utenteEsterno
});