import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import DatePicker from 'react-date-picker';
import Moment from 'react-moment'
import { saveAs } from "file-saver";
import {Helmet} from "react-helmet";
import XlsxPopulate from "xlsx-populate";
import Spinner from '../../layout/Spinner';
import raggruppamentiJSON from "../../../utils/raggruppamenti.json";
const IncarichiFiliale = ({ alert, incarichiFiliale, onChangeRangeDateInizio, onChangeRangeDateFine, incarichiLoading, dataInizioInput, dataFineInput, setIncarichiLoading, totaleOre, setTotaleOre, filialeSelected, setFilialeSelected, filialiList }) => {


    const [csvData, setCsvData] = useState([]);
    const [arrayIncarichiFiliale, setArrayIncarichiFiliale] = useState([]);
    const [raggruppamentoSelected, setRaggruppamentoSelected] = useState(-1);
    const [soloPresatiSelected, setSoloPresatiSelected] = useState(false);


    const headers = [
        { label: 'Data', key: 'dataCSV' },
        { label: 'Servizio', key: 'servizio' },
        { label: 'Localita', key: 'localita' },
        { label: 'Raggruppamento', key: 'raggruppamento' },
        { label: 'Dalle', key: 'oraInizio' },
        { label: 'Alle', key: 'oraFine' },
        { label: 'Ore', key: 'ore' },
        { label: 'Ore', key: 'oreFloat' },
        { label: 'Nome', key: 'nome' },
        { label: 'Cognome', key: 'cognome' },
        { label: 'Prestato', key: 'prestato' },
        { label: 'Filiale', key: 'filiale' }
    ];





    const getOreBetweenDates = (start, end) => {
        start = start.split(":");
        end = end.split(":");
        var startDate = new Date(0, 0, 0, start[0], start[1], 0);
        var endDate = new Date(0, 0, 0, end[0], end[1], 0);
        var diff = endDate.getTime() - startDate.getTime();
        var hours = Math.floor(diff / 1000 / 60 / 60);
        diff -= hours * 1000 * 60 * 60;
        var minutes = Math.floor(diff / 1000 / 60);
        if (hours < 0)
            hours = hours + 24;
        if (isNaN(hours) || isNaN(minutes)) {
            return ""
        }
        return (hours <= 9 ? "0" : "") + hours + ":" + (minutes <= 9 ? "0" : "") + minutes;
    }




    const fromStringToMinuti = (oreString) => {
        var minuti = getMinutiInt(oreString);
        var ore = getOreInt(oreString) * 60
        return ore + minuti
    }

    const getOreInt = (ore) => {
        if (ore && ore.length > 0) {
            const split = ore.split(':');
            return parseInt(split[0])
        } else {
            return 0
        }
    }

    const getMinutiInt = (ore) => {
        if (ore && ore.length > 0) {
            const split = ore.split(':');
            return parseFloat(split[1])
        } else {
            return 0
        }
    }

    useEffect(() => {
        let array = [];
        if (incarichiFiliale.length > 0) {
            incarichiFiliale.map(el => {
                let oreLavorateString = getOreBetweenDates(el.oraInizio, el.oraFine)
                let raggIndex = raggruppamentiJSON.raggruppamenti.findIndex((a) => { return a.id == el.puntoVendita.idRaggruppa });
                array.push({ dataCSV: toIsoString(new Date(el.dataInizio)), servizio: el.puntoVendita.denominazione, localita: el.puntoVendita.localita, raggruppamento: raggIndex > - 1 ? raggruppamentiJSON.raggruppamenti[raggIndex].nota : '', oraInizio: el.oraInizio, oraFine: el.oraFine, ore: oreLavorateString, oreFloat: convertTimeToDecimal(oreLavorateString), nome: el.dipendente.nome, cognome: el.dipendente.cognome, prestato: el.prestato ? 'Si' : 'No', filiale: el.dipendente.filiale.nome, data: el.dataInizio, idRaggruppa: el.puntoVendita.idRaggruppa, minutiInt:  fromStringToMinuti(oreLavorateString)})
            })
        }
        array.sort((a, b) => (a.dataCSV > b.dataCSV) ? 1 : -1)

        if(raggruppamentoSelected >= 0){
            array = array.filter( el => { return el.idRaggruppa == raggruppamentoSelected })
        }
        if(soloPresatiSelected == 'solo-prestati'){
            array = array.filter( el => { return  el.prestato == 'Si'; })
        }
        if(filialeSelected){
            array = array.filter( el => { return  el.filiale == filialeSelected.nome; })
        }

        let sommaOreTurni = sommaOreturni(array)
        let sommaOreTurniString = sommaOreTurni ? fromMinutiToOreString(sommaOreTurni) : "00:00"
        setArrayIncarichiFiliale(array)
        setCsvData(array)
        setTotaleOre(sommaOreTurniString)
        setIncarichiLoading(false)
    }, [incarichiFiliale]);

    function convertTimeToDecimal(timeStr) {
        // Dividi la stringa di tempo in ore e minuti
        const [hours, minutes] = timeStr.split(':').map(Number);
        
        // Converti i minuti in ore e somma alle ore
        const decimalTime = hours + (minutes / 60);
        
        return decimalTime;
    }

    const toIsoString = (date) => {
        const pad = function (num) {
            return (num < 10 ? '0' : '') + num;
        };

        return pad(date.getDate()) +
            '-' + pad(date.getMonth() + 1) +
            '-' + date.getFullYear()
    }

    async function saveAsExcel(data, header) {

        XlsxPopulate.fromBlankAsync().then(async (workbook) => {
            const sheet1 = workbook.sheet(0);
            const sheetData = getSheetData(data, header);
            const totalColumns = sheetData[0].length;

            sheet1.cell("A1").value(sheetData);
            const range = sheet1.usedRange();
            const endColumn = String.fromCharCode(64 + totalColumns);
            sheet1.cell(`H${data.length+2}`).value(sommaValoriColonna(workbook, 0, "H", 2, data.length+2));
            sheet1.cell(`H${data.length+2}`).style("bold", true);
            sheet1.cell(`H${data.length+2}`).style("border", true);
            sheet1.row(1).style("bold", true);
            sheet1.range("A1:" + endColumn + "1").style("fill", "BFBFBF");
            range.style("border", true);
            return workbook.outputAsync().then((res) => {
                saveAs(res, "incarichi-filiale.xlsx");
            });
        });
    }

    const sommaValoriColonna = (workbook, sheet, colonna, inizio, fine) => {
        let somma = 0;
        for(var i=inizio; i<fine; i++){
            somma = somma + Number(workbook.sheet(sheet).cell(colonna+i).value())
        }
        return somma
    }

    function getSheetData(data, header) {
        var fields = Object.keys(data[0]);
        var sheetData = data.map(function (row) {
            return fields.map(function (fieldName) {
                return row[fieldName] ? row[fieldName] : "";
            });
        });
        const headerLabel = header.map(function (row) { return row.label })
        sheetData.unshift(headerLabel);
        return sheetData;
    }

    const sommaOreturni = (turni) => {
        let totaleOreLavorate = 0;
        turni.map(turno => {
            totaleOreLavorate = totaleOreLavorate + turno.minutiInt
        })
        return totaleOreLavorate
    }

    const fromMinutiToOreString = (minutiTotali) => {
        if (minutiTotali > 0) {
            var minuti = minutiTotali % 60;
            var ore = (minutiTotali - minuti) / 60;
            if (ore == 0 && minuti == 0) {
                return ""
            } else {
                return formattaOre(ore) + ":" + formattaMinuti(minuti)
            }
        } else {
            return ""
        }
    }

    const formattaOre = (ore) => {
        return (ore <= 9 ? "0" : "") + ore
    }

    const formattaMinuti = (minuti) => {
        return (minuti <= 9 ? "0" : "") + minuti;
    }

    const onChangeSetRaggruppamento = e => {
        let array = [];
        setRaggruppamentoSelected(e.target.value);
        if (incarichiFiliale.length > 0) {
            incarichiFiliale.map(el => {
                let oreLavorateString = getOreBetweenDates(el.oraInizio, el.oraFine)
                let raggIndex = raggruppamentiJSON.raggruppamenti.findIndex((a) => { return a.id == el.puntoVendita.idRaggruppa });
                array.push({ dataCSV: toIsoString(new Date(el.dataInizio)), servizio: el.puntoVendita.denominazione, localita: el.puntoVendita.localita, raggruppamento: raggIndex > - 1 ? raggruppamentiJSON.raggruppamenti[raggIndex].nota : '', oraInizio: el.oraInizio, oraFine: el.oraFine, ore: oreLavorateString, oreFloat: convertTimeToDecimal(oreLavorateString), nome: el.dipendente.nome, cognome: el.dipendente.cognome, prestato: el.prestato ? 'Si' : 'No', filiale: el.dipendente.filiale.nome, data: el.dataInizio, idRaggruppa: el.puntoVendita.idRaggruppa, minutiInt:  fromStringToMinuti(oreLavorateString)})
            })
        }
        array.sort((a, b) => (a.dataCSV > b.dataCSV) ? 1 : -1)
        if(e.target.value >= 0){
            array = array.filter( el => { return el.idRaggruppa == e.target.value })
        }
        if(soloPresatiSelected == 'solo-prestati'){
            array = array.filter( el => { return  el.prestato == 'Si'; })
        }
        if(filialeSelected){
            array = array.filter( el => { return  el.filiale == filialeSelected.nome; })
        }
        let sommaOreTurni = sommaOreturni(array)
        let sommaOreTurniString = sommaOreTurni ? fromMinutiToOreString(sommaOreTurni) : "00:00"
        setArrayIncarichiFiliale(array)
        setCsvData(array)
        setTotaleOre(sommaOreTurniString)
    }

    const onChangeSetSoloPrestati = e => {
        let array = [];
        setSoloPresatiSelected(e.target.value);
        if (incarichiFiliale.length > 0) {
            incarichiFiliale.map(el => {
                let oreLavorateString = getOreBetweenDates(el.oraInizio, el.oraFine)
                let raggIndex = raggruppamentiJSON.raggruppamenti.findIndex((a) => { return a.id == el.puntoVendita.idRaggruppa });
                array.push({ dataCSV: toIsoString(new Date(el.dataInizio)), servizio: el.puntoVendita.denominazione, localita: el.puntoVendita.localita, raggruppamento: raggIndex > - 1 ? raggruppamentiJSON.raggruppamenti[raggIndex].nota : '', oraInizio: el.oraInizio, oraFine: el.oraFine, ore: oreLavorateString, oreFloat: convertTimeToDecimal(oreLavorateString), nome: el.dipendente.nome, cognome: el.dipendente.cognome, prestato: el.prestato ? 'Si' : 'No', filiale: el.dipendente.filiale.nome, data: el.dataInizio, idRaggruppa: el.puntoVendita.idRaggruppa, minutiInt:  fromStringToMinuti(oreLavorateString)})
            })
        }
        array.sort((a, b) => (a.dataCSV > b.dataCSV) ? 1 : -1)

        if(e.target.value == 'solo-prestati'){
            array = array.filter( el => { return  el.prestato == 'Si'; })
        }
        if(raggruppamentoSelected >= 0){
            array = array.filter( el => { return el.idRaggruppa == raggruppamentoSelected })
        }
        if(filialeSelected){
            array = array.filter( el => { return  el.filiale == filialeSelected.nome; })
        }
        let sommaOreTurni = sommaOreturni(array)
        let sommaOreTurniString = sommaOreTurni ? fromMinutiToOreString(sommaOreTurni) : "00:00"
        setArrayIncarichiFiliale(array)
        setCsvData(array)
        setTotaleOre(sommaOreTurniString)
    }

    const onChangeFiltraFiliale = e => {
        let array = [];
        let filialeIndex = filialiList.findIndex((a) => { return a._id == e.target.value });
        if(filialeIndex >= 0){
            setFilialeSelected(filialiList[filialeIndex]);
        }else{
            setFilialeSelected(null);
        }


        if (incarichiFiliale.length > 0) {
            incarichiFiliale.map(el => {
                let oreLavorateString = getOreBetweenDates(el.oraInizio, el.oraFine)
                let raggIndex = raggruppamentiJSON.raggruppamenti.findIndex((a) => { return a.id == el.puntoVendita.idRaggruppa });
                array.push({ dataCSV: toIsoString(new Date(el.dataInizio)), servizio: el.puntoVendita.denominazione, localita: el.puntoVendita.localita, raggruppamento: raggIndex > - 1 ? raggruppamentiJSON.raggruppamenti[raggIndex].nota : '', oraInizio: el.oraInizio, oraFine: el.oraFine, ore: oreLavorateString, oreFloat: convertTimeToDecimal(oreLavorateString), nome: el.dipendente.nome, cognome: el.dipendente.cognome, prestato: el.prestato ? 'Si' : 'No', filiale: el.dipendente.filiale.nome, data: el.dataInizio, idRaggruppa: el.puntoVendita.idRaggruppa, minutiInt:  fromStringToMinuti(oreLavorateString)})
            })
        }
        array.sort((a, b) => (a.dataCSV > b.dataCSV) ? 1 : -1)

        if(soloPresatiSelected == 'solo-prestati'){
            array = array.filter( el => { return  el.prestato == 'Si'; })
        }
        if(raggruppamentoSelected >= 0){
            array = array.filter( el => { return el.idRaggruppa == raggruppamentoSelected })
        }
        if(filialeIndex >= 0){
            array = array.filter( el => { return  el.filiale == filialiList[filialeIndex]['nome']; })
        }
        let sommaOreTurni = sommaOreturni(array)
        let sommaOreTurniString = sommaOreTurni ? fromMinutiToOreString(sommaOreTurni) : "00:00"
        setArrayIncarichiFiliale(array)
        setCsvData(array)
        setTotaleOre(sommaOreTurniString)
    }


    return (
        <div className="dati_container">
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`Incarichi filiale - Gestionale Vigili dell'ordine`}</title>
            </Helmet>
            <div className="fun_assegnazione_incarichi">
                <div className="row row_datapicker">
                    <div className="item_1 filtro_wrap_incarichi">
                        <div id="w-node-_284812ee-7924-9f38-86bb-8d2cc43dff3c-867f153d" className="div_item_1">
                            <div id="w-node-_80bb2acb-3043-3649-0498-379a652f3b04-867f153d" className="label_text">Data inizio</div>
                            <div data-hover="false" data-delay="0" className="select w-dropdown">
                                <DatePicker
                                    value={dataInizioInput}
                                    format="dd/MM/y"
                                    weekPicker
                                    onChange={onChangeRangeDateInizio}
                                    locale="it-IT"
                                    clearIcon={null}
                                    weekStartDayIndex={1}
                                />
                            </div>
                        </div>
                        <div id="w-node-_284812ee-7924-9f38-86bb-8d2cc43dff3c-867f153d" className="div_item_1">
                            <div id="w-node-_80bb2acb-3043-3649-0498-379a652f3b04-867f153d" className="label_text">Data fine</div>
                            <div data-hover="false" data-delay="0" className="select w-dropdown">
                                <DatePicker
                                    value={dataFineInput}
                                    format="dd/MM/y"
                                    weekPicker
                                    onChange={onChangeRangeDateFine}
                                    locale="it-IT"
                                    clearIcon={null}
                                    weekStartDayIndex={1}
                                />
                            </div>
                        </div>
                            <div id="w-node-_284812ee-7924-9f38-86bb-8d2cc43dff3c-867f153d" className="div_item_1">
                            <div id="w-node-_80bb2acb-3043-3649-0498-379a652f3b04-867f153d" className="label_text">Raggruppamento</div>
                                <div data-hover="false" data-delay="0" className="select w-dropdown">
                                    <select className="form-control select" name="idRaggruppa" onChange={e => onChangeSetRaggruppamento(e)}>
                                        <option selected={raggruppamentoSelected == -1} value={-1} >Tutti</option>
                                        {raggruppamentiJSON.raggruppamenti.map((el) => (
                                            <option selected={el.id == raggruppamentoSelected} value={el.id}>{el.codice} - {el.nota}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div id="w-node-_284812ee-7924-9f38-86bb-8d2cc43dff3c-867f153d" className="div_item_1">
                            <div id="w-node-_80bb2acb-3043-3649-0498-379a652f3b04-867f153d" className="label_text">Filtra prestati</div>
                                <div data-hover="false" data-delay="0" className="select w-dropdown">
                                    <select className="form-control select" name="soloPrestati" onChange={e => onChangeSetSoloPrestati(e)}>
                                    <option value={null} >Tutti</option>
                                    <option value={'solo-prestati'} >Solo prestati</option>
                                    </select>
                                </div>
                            </div>
                            <div id="w-node-_284812ee-7924-9f38-86bb-8d2cc43dff3c-867f153d" className="div_item_1">
                            <div id="w-node-_80bb2acb-3043-3649-0498-379a652f3b04-867f153d" className="label_text">Filtra filiale</div>
                                <div data-hover="false" data-delay="0" className="select w-dropdown">
                                    <select className="form-control select" name="filiale" onChange={e => onChangeFiltraFiliale(e)}>
                                    <option value={null} >Tutti</option>
                                    {filialiList.length > 0 ?
                                    <>
                                    {filialiList.map((el) => (
                                            <option selected={el._id == filialeSelected && filialeSelected._id} value={el._id}>{el.nome}</option>
                                        ))}
                                    </>
                                    : ''}
                                    </select>
                                </div>
                            </div>
                    </div>
                </div>
                <div className="row">
                    <div className="item_2">
                        <div className="intestazione_table">
                            <div className="w-layout-grid div_column_incarichi_filiale intestazione">
                                <div className="div_item_column">
                                    <div className="item_table">Data</div>
                                </div>
                                <div className="div_item_column">
                                    <div className="item_table">Servizio</div>
                                </div>
                                <div className="div_item_column">
                                    <div className="item_table">Località</div>
                                </div>
                                <div className="div_item_column">
                                    <div className="item_table">Raggruppamento</div>
                                </div>
                                <div className="div_item_column">
                                    <div className="item_table">Dalle</div>
                                </div>
                                <div className="div_item_column">
                                    <div className="item_table">Alle</div>
                                </div>
                                <div className="div_item_column">
                                    <div className="item_table">Ore</div>
                                </div>
                                <div className="div_item_column">
                                    <div className="item_table">Nome</div>
                                </div>
                                <div className="div_item_column">
                                    <div className="item_table">Cognome</div>
                                </div>
                                <div className="div_item_column">
                                    <div className="item_table">Prestato</div>
                                </div>
                                <div className="div_item_column">
                                    <div className="item_table">Filiale</div>
                                </div>
                            </div>
                        </div>
                        {incarichiLoading ? <Spinner /> :
                            <div className="body_table filiale">
                                {arrayIncarichiFiliale.map((el, i) => (
                                    <div key={"contratto" + i} className={`w-layout-grid div_column_incarichi_filiale cursor`}>
                                        <div className="div_item_column">
                                            <div className="item_table"><Moment format='DD/MM/YYYY'>{el.data}</Moment></div>
                                        </div>
                                        <div className="div_item_column" >
                                            <div className="item_table">{el.servizio}</div>
                                        </div>
                                        <div className="div_item_column">
                                            <div className="item_table">{el.localita}</div>
                                        </div>
                                        <div className="div_item_column">
                                            <div className="item_table">{el.raggruppamento}</div>
                                        </div>
                                        <div className="div_item_column">
                                            <div className="item_table">{el.oraInizio}</div>
                                        </div>
                                        <div className="div_item_column">
                                            <div className="item_table">{el.oraFine}</div>
                                        </div>
                                        <div className="div_item_column">
                                            <div className="item_table">{el.ore}</div>
                                        </div>
                                        <div className="div_item_column">
                                            <div className="item_table" >{el.nome}</div>
                                        </div>
                                        <div className="div_item_column">
                                            <div className="item_table" >{el.cognome}</div>
                                        </div>
                                        <div className="div_item_column">
                                            <div className="item_table" >{el.prestato}</div>
                                        </div>
                                        <div className="div_item_column">
                                            <div className="item_table" >{el.filiale}</div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        }
                    </div>
                </div>
                <div className="row">
                        <h4>Totale ore: {totaleOre}</h4>
                </div>
                <div className="row">
                    <div className="item_3 m_top_5">
                        <div className="list_button penultimo_largo">
                            <div id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98d-867f153d" className="div_item_button">
                                <a id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98e-867f153d" className={`button w-button ${csvData.length == 0 ? "button_disabled" : ""}`} onClick={(e) => csvData.length == 0 ? null : saveAsExcel(csvData, headers)}>Scarica XLS</a>
                            </div>
                            <div id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98d-867f153d" className="div_item_button"></div>
                            <div id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98d-867f153d" className="div_item_button"></div>
                            <div id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98d-867f153d" className="div_item_button"></div>
                            <div id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98d-867f153d" className="div_item_button"></div>
                            <div id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98d-867f153d" className="div_item_button"></div>
                            <div id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98d-867f153d" className="div_item_button"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

IncarichiFiliale.protoTypes = {
    filiale: PropTypes.object.isRequired,
    alert: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    filiale: state.filiale,
    alert: state.alert
});

export default connect(mapStateToProps, {})(IncarichiFiliale);