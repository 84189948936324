import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Spinner from '../../layout/Spinner';
import ModificaPuntoVendita from './ModificaPuntoVendita';
import NuovoPuntoVendita from './NuovoPuntoVendita';
import zoneResidenzaJSON from "../../../utils/zone_residenza.json";
import raggruppamentiJSON from "../../../utils/raggruppamenti.json";
import { saveAs } from "file-saver";
import XlsxPopulate from "xlsx-populate";
import {Helmet} from "react-helmet";
import serviziEffJSON from "../../../utils/serviziEff.json";
import DatePicker from 'react-date-picker';
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import { eliminaPuntoVendita, getPuntiVendita } from '../../../actions/puntoVendita';
import { getClienti } from '../../../actions/cliente'; 
import { getFiliali } from '../../../actions/filiale';
const PuntiVendita = ({ puntoVendita: { puntiVendita, loading }, getPuntiVendita, getClienti, cliente: { clienti }, eliminaPuntoVendita, auth: {user}, filiale: { filiali }, getFiliali }) => {

    const [openModificaPuntoServizio, setOpenModificaPuntoServizio] = useState(false);
    const [openNuovoPuntoServizio, setOpenNuovoPuntoServizio] = useState(false);
    const [puntoServizioSelected, setPuntoServizioSelected] = useState({ _id: null });
    const [testoFiltraPuntiServizio, setTestoFiltraPuntiServizio] = useState("");
    const [puntiServizioList, setPuntiServizioList] = useState([]);
    const [csvData, setCsvData] = useState([]);
    const [filialiList, setFilialiList] = useState([]);
    const [filtraFiliale, setFiltraFiliale] = useState("tutti");
    const [dataInizioInput, setDataInizioInput] = useState((setHours(setMinutes(startOfMonth(new Date()), 0), 0)));
    const [dataFineInput, setDataFineInput] = useState((setHours(setMinutes(endOfMonth(new Date()), 59), 23)));
    const [mostraTutti, setMostraTutti] = useState(false);

    const headers = [
        { label: 'Denominazione', key: 'denominazione' },
        { label: 'Codice', key: 'codice' },
        { label: 'Cliente', key: 'cliente' },
        { label: 'Indirizzo', key: 'indirizzo' },
        { label: 'Provincia', key: 'provincia' },
        { label: 'CAP', key: 'cap' },
        { label: 'Localita', key: 'localita' },
        { label: 'Zona residenza', key: 'idZonaRes' },
        { label: 'Raggruppamento', key: 'idRaggruppa' },
        { label: 'Servizio eff', key: 'idServiziEff' },
        { label: 'Qualifica', key: 'qualifica' },
        { label: 'Ora Inizio', key: 'oraInizio' },
        { label: 'Ora Fine', key: 'oraFine' },
        { label: 'Data Inizio', key: 'dataInizio' },
        { label: 'Data Fine', key: 'dataFine' }
    ];

    const [utente, setUtente] = useState({
		username: '',
		nome: '',
		cognome: '',
		ruolo: '',
		vista: '',
		nomeFiliale: '',
		nomeOrganizzazione: '',
		idFiliale: ''
	});

    useEffect(() => {
		setUtente({
			username: loading || !user ? '' : user.username,
			nome: loading || !user ? '' : user.nome,
			cognome: loading || !user ? '' : user.cognome,
			ruolo: loading || !user ? '' : user.role,
			vista: loading || !user ? '' : user.vista,
			nomeFiliale: loading || !user || !user.filiale ? '' : user.filiale.nome,
			nomeOrganizzazione: loading || !user || !user.organizzazione ? '' : user.organizzazione.nome,
			idOrganizzazione: loading || !user || !user.organizzazione ? '' : user.organizzazione._id
		})
	}, [user, loading]);

    const { vista, ruolo } = utente;

    useEffect(() => {
        getClienti();
    }, [getClienti]);

    useEffect(() => {
        getPuntiVendita({ idPuntoServizio: puntoServizioSelected._id });
    }, [getPuntiVendita]);

    useEffect(() => {
        if (user && user.role == "organizzazione") {
            getFiliali();
        }
    }, [user]);

    useEffect(() => {
        if (filiali.length > 0) {
            let array = [];
            filiali.map(filiale => {
                const index = user.vistaFiliali.findIndex(el => el._id == filiale._id);
                if(index >= 0){
                    array.push(filiale)
                }
            })
            setFilialiList(array)
        }
    }, [filiali]);

    useEffect(() => {
        let array = [];
        let arrayPuntiDiServizio = [...puntiVendita];
        if(!mostraTutti){
            arrayPuntiDiServizio = filtraContrattiValidi(arrayPuntiDiServizio, dataInizioInput, dataFineInput)
        }

        if (testoFiltraPuntiServizio.length > 0) {
            arrayPuntiDiServizio = puntiVendita.filter(function (puntoVendita) {
                return puntoVendita.denominazione.toLowerCase().includes(testoFiltraPuntiServizio.toLowerCase())
            });
        } 
        setPuntiServizioList(arrayPuntiDiServizio)

        if (puntiVendita.length > 0) {
            puntiVendita.map(el => {
                let raggIndex = raggruppamentiJSON.raggruppamenti.findIndex((a) => { return a.id == el.idRaggruppa });
                let zonaIndex = zoneResidenzaJSON.zoneResidenza.findIndex((a) => { return a.id == el.idZonaRes });
                let servIndex = serviziEffJSON.serviziEff.findIndex((a) => { return a.id == el.idServiziEff });
                array.push({ denominazione: el.denominazione, codice: el.codice, cliente: el.cliente.ragioneSoc, indirizzo: el.indirizzo, provincia: el.provincia, cap: el.cap, localita: el.localita, idZonaRes: zonaIndex > - 1 ? zoneResidenzaJSON.zoneResidenza[zonaIndex].nome : '', idRaggruppa: raggIndex > - 1 ? raggruppamentiJSON.raggruppamenti[raggIndex].nota : '', idServiziEff: servIndex > - 1 ? serviziEffJSON.serviziEff[servIndex].nome : '', qualifica: el.qualifica, oraInizio: "", oraFine: "", dataInizio: "", dataFine: ""})
                if(el.contratti.length > 0){
                    el.contratti.map(contratto => {
                        array.push({ denominazione: el.denominazione + " (contratto)", codice: "", cliente: "", indirizzo: "", provincia: "", cap: "", localita: "", idZonaRes: "", idRaggruppa: "", idServiziEff: "", qualifica: "", oraInizio: contratto.oraInizio, oraFine: contratto.oraFine, dataInizio: toIsoString(new Date(contratto.dataInizio)), dataFine: toIsoString(new Date(contratto.dataFine))})
                    })
                }
            })
            array.sort((a, b) => {
                if(a.denominazione > b.denominazione){
                    return 1
                }else if(a.denominazione < b.denominazione){
                    return -1
                }else{
                    if(a.dataInizio > b.dataInizio){
                        return 1
                    }else{
                        return -1
                    }
                }
            })
        }
        setCsvData(array)
    }, [puntiVendita]);


    const onClickOpenNuovoPuntoServizio = () => {
        setPuntoServizioSelected({ _id: null })
        setOpenModificaPuntoServizio(false)
        setOpenNuovoPuntoServizio(true)
    }

    const onClickOpenModificaPuntoServizio = (idPuntoServizio) => {
        if (idPuntoServizio) {
            setOpenModificaPuntoServizio(true)
            setOpenNuovoPuntoServizio(false)
        }
    }

    const onChangeFiltraPuntiServizio = e => {
        setTestoFiltraPuntiServizio(e.target.value)
        let arrayPuntiServizio = puntiVendita.filter(function (puntoVendita) {
            return puntoVendita.denominazione.toLowerCase().includes(e.target.value.toLowerCase())
        });
        if(!mostraTutti){
            arrayPuntiServizio = filtraContrattiValidi(arrayPuntiServizio, dataInizioInput, dataFineInput)
        }
        if(filtraFiliale != "tutti"){
            arrayPuntiServizio = filtraPerFiliale(arrayPuntiServizio, filtraFiliale)
        }else{
            arrayPuntiServizio = filtraPerFilialeTutti(arrayPuntiServizio)

        } 
            setPuntiServizioList(arrayPuntiServizio)
    };

    const onClickSelectPuntoServizio = ((e, idPuntoServizio) => {
        switch (e.detail) {
            case 1:
                setPuntoServizioSelected({ _id: idPuntoServizio });
                break;
            case 2:
                onClickOpenModificaPuntoServizio(idPuntoServizio)
                break;
            case 3:
                onClickOpenModificaPuntoServizio(idPuntoServizio)
                break;
            default:
                return;
        }
    })

    const filtraContrattiValidi = (data, dataInizioInput, dataFineInput) => {
        // Convert input dates to Date objects for comparison
        const dataInizio = new Date(dataInizioInput);
        const dataFine = new Date(dataFineInput);
    
        // Filter the objects
        return data.filter(obj => {
            // Filter the contratti array within each object
            const filteredContratti = obj.contratti.filter(contratto => {
                const contrattoDataInizio = new Date(contratto.dataInizio);
                const contrattoDataFine = new Date(contratto.dataFine);
                if(!contratto.sporadico){
                    return (contrattoDataInizio <= dataFine && contrattoDataFine >= dataInizio);
                }else{
                    return false
                }
            });
    
            // If the filteredContratti array is not empty, include this object in the result
            if (filteredContratti.length > 0) {
                // Return a new object with the filtered contratti
                return {
                    ...obj,
                    contratti: filteredContratti
                };
            }
            return false;
        });
    }

    const onClickEliminaPuntoVendita = (idPuntoVendita) => {
        if(idPuntoVendita == puntoServizioSelected._id){
            setPuntoServizioSelected({_id: ""})
            setOpenModificaPuntoServizio(false)
        }
        eliminaPuntoVendita(idPuntoVendita)
    }

    const onChangeRangeDateInizio = (data) => {
        setDataInizioInput(data)
        let arrayPuntiDiServizio = [...puntiVendita];
        if(!mostraTutti){
            arrayPuntiDiServizio = filtraContrattiValidi(arrayPuntiDiServizio, data, dataFineInput)
        }
        if (testoFiltraPuntiServizio.length > 0) {
            arrayPuntiDiServizio = arrayPuntiDiServizio.filter(function (puntoVendita) {
                return puntoVendita.denominazione.toLowerCase().includes(testoFiltraPuntiServizio.toLowerCase())
            });
        } 
        
        if(filtraFiliale != "tutti"){
            arrayPuntiDiServizio = filtraPerFiliale(arrayPuntiDiServizio, filtraFiliale)
        }else{
            arrayPuntiDiServizio = filtraPerFilialeTutti(arrayPuntiDiServizio)

        } 
            setPuntiServizioList(arrayPuntiDiServizio)
    }

     //*
     const onChangeRangeDateFine = (data) => {
        setDataFineInput(data)
        let arrayPuntiDiServizio = [...puntiVendita];
        if(!mostraTutti){
            arrayPuntiDiServizio = filtraContrattiValidi(arrayPuntiDiServizio, data, dataFineInput)
        }
        if (testoFiltraPuntiServizio.length > 0) {
            arrayPuntiDiServizio = arrayPuntiDiServizio.filter(function (puntoVendita) {
                return puntoVendita.denominazione.toLowerCase().includes(testoFiltraPuntiServizio.toLowerCase())
            });
        } 
        
        if(filtraFiliale != "tutti"){
            arrayPuntiDiServizio = filtraPerFiliale(arrayPuntiDiServizio, filtraFiliale)
        }else{
            arrayPuntiDiServizio = filtraPerFilialeTutti(arrayPuntiDiServizio)

        } 
            setPuntiServizioList(arrayPuntiDiServizio)
    }

    async function saveAsExcel(data, header) {

        XlsxPopulate.fromBlankAsync().then(async (workbook) => {
            const sheet1 = workbook.sheet(0);
            const sheetData = getSheetData(data, header);
            const totalColumns = sheetData[0].length;

            sheet1.cell("A1").value(sheetData);
            const range = sheet1.usedRange();
            const endColumn = String.fromCharCode(64 + totalColumns);
            sheet1.row(1).style("bold", true);
            sheet1.range("A1:" + endColumn + "1").style("fill", "BFBFBF");
            range.style("border", true);
            return workbook.outputAsync().then((res) => {
                saveAs(res, "incarichi-filiale.xlsx");
            });
        });
    }

    function getSheetData(data, header) {
        var fields = Object.keys(data[0]);
        var sheetData = data.map(function (row) {
            return fields.map(function (fieldName) {
                return row[fieldName] ? row[fieldName] : "";
            });
        });
        const headerLabel = header.map(function (row) { return row.label })
        sheetData.unshift(headerLabel);
        return sheetData;
    }

    const toIsoString = (date) => {
        const pad = function (num) {
            return (num < 10 ? '0' : '') + num;
        };

        return pad(date.getDate()) +
            '-' + pad(date.getMonth() + 1) +
            '-' + date.getFullYear()
    }

    const filtraPerFiliale = (puntiVendita, filialeId) => {
        let array = puntiVendita.filter(function (puntoVendita) {
            return puntoVendita.filiale._id == filialeId;
        })
        return array
    }

    const filtraPerFilialeTutti = (puntiVendita) => {
        let array;
        if (user && user.role == "organizzazione") {
            array = puntiVendita.filter(function (puntoVendita) {
                const index = user.vistaFiliali.findIndex(el => el._id == puntoVendita.filiale._id);
                if(index >= 0){
                    return true
                }else {
                    return false
                }           
            })
        }else{
            array = puntiVendita.filter(function (puntoVendita) {
                return  user.filiale._id == puntoVendita.filiale._id
            });
        }
        return array
    }

    const onChangeFiltraPuntiVenditaPerFiliale = e => {
        let arrayPuntiDiServizio = [...puntiVendita];
        if(!mostraTutti){
            arrayPuntiDiServizio = filtraContrattiValidi(arrayPuntiDiServizio, dataInizioInput, dataFineInput)
        }
        setFiltraFiliale(e.target.value)
        if (testoFiltraPuntiServizio.length > 0) {
            arrayPuntiDiServizio = arrayPuntiDiServizio.filter(function (puntoVendita) {
                return puntoVendita.denominazione.toLowerCase().includes(testoFiltraPuntiServizio.toLowerCase())
            });
        } 
        
        if(e.target.value != "tutti"){
            arrayPuntiDiServizio = filtraPerFiliale(arrayPuntiDiServizio, e.target.value)
        }else{
            arrayPuntiDiServizio = filtraPerFilialeTutti(arrayPuntiDiServizio)

        } 
            setPuntiServizioList(arrayPuntiDiServizio)
    };
    
    const onChangeMostraTutti = () => {
        let arrayPuntiDiServizio = [...puntiVendita];
        if(mostraTutti){
            arrayPuntiDiServizio = filtraContrattiValidi(arrayPuntiDiServizio, dataInizioInput, dataFineInput)
        }
        setMostraTutti(!mostraTutti)
        if (testoFiltraPuntiServizio.length > 0) {
            arrayPuntiDiServizio = arrayPuntiDiServizio.filter(function (puntoVendita) {
                return puntoVendita.denominazione.toLowerCase().includes(testoFiltraPuntiServizio.toLowerCase())
            });
        } 
        
        if(filtraFiliale != "tutti"){
            arrayPuntiDiServizio = filtraPerFiliale(arrayPuntiDiServizio, filtraFiliale)
        }else{
            arrayPuntiDiServizio = filtraPerFilialeTutti(arrayPuntiDiServizio)

        } 
            setPuntiServizioList(arrayPuntiDiServizio)
    };

    return (loading ? <Spinner /> :
        <div className="body wf-section">
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`Punti di servizio - Gestionale Vigili dell'ordine`}</title>
            </Helmet>
            <div className="div_wrap">
                <div className="div_wrap_100">
                    <div className="div_wrap_50">
                        <div className="div_container">
                            <div className="title_window">
                                <div className="title_window_p">Punti di servizio</div>
                            </div>
                            <div className="dati_container">
                                <div className="fun_assegnazione_incarichi">
                                    <div className="row punti_servizio_date">
                                        <div className="item_1 filtro_wrap_2">
                                            <div id="w-node-_714e71a9-0550-fe0e-26e9-8b45ac42ab89-867f153d" className="div_item_1">
                                                <div className="w-form">
                                                    <div id="email-form" name="email-form" data-name="Email Form" method="get">
                                                        <a id="w-node-_714e71a9-0550-fe0e-26e9-8b45ac42ab90-867f153d" onClick={() => { onClickOpenNuovoPuntoServizio() }} className="button w-button button_filtro_senza_label">Nuovo Punto di servizio</a>
                                                    </div>
                                                </div>
                                            </div>
                                            {ruolo == "organizzazione" || ruolo == "admin" ? 
                                            <div id="w-node-_284812ee-7924-9f38-86bb-8d2cc43dff3c-867f153d" className="div_item_1">
                                            <div id="w-node-_7b40e076-1929-a489-3ae4-e7e497d010fa-867f153d" className="label_text">Filiale</div>
                                            <div data-hover="false" data-delay="0" className="select w-dropdown">
                                                        <select className="form-control select" name="idCliente" onChange={e => onChangeFiltraPuntiVenditaPerFiliale(e)} required>
                                                        <option selected={'tutti' == filtraFiliale} value={'tutti'} >Tutti</option>
                                                            {filialiList.map((elementoFiliale, i) => (
                                                                <option key={i} value={elementoFiliale._id} selected={elementoFiliale._id == filtraFiliale} >{elementoFiliale.nome}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                            </div>
                                            :
                                                         ''   }
                                            <div id="w-node-_7b40e076-1929-a489-3ae4-e7e497d010f9-867f153d" className="div_item_1">
                                                <div id="w-node-_7b40e076-1929-a489-3ae4-e7e497d010fa-867f153d" className="label_text">Filtra punti servizio</div>
                                                <div data-hover="false" data-delay="0" className="w-dropdown">
                                                    <input type="text" className="w-input select" maxLength="256" name="filtraPuntiServizio" data-name="filtraPuntiServizio" value={testoFiltraPuntiServizio} placeholder="Inserisci punto servizio" id="filtraPuntiServizio" onChange={e => onChangeFiltraPuntiServizio(e)} />
                                                </div>
                                            </div>
                                            <div id="w-node-_284812ee-7924-9f38-86bb-8d2cc43dff3c-867f153d" className="div_item_1">
                                                <div id="w-node-_80bb2acb-3043-3649-0498-379a652f3b04-867f153d" className="label_text">Data inizio</div>
                                                <div data-hover="false" data-delay="0" className="select w-dropdown">
                                                    <DatePicker
                                                        value={dataInizioInput}
                                                        format="dd/MM/y"
                                                        weekPicker
                                                        onChange={onChangeRangeDateInizio}
                                                        //showTodayButton={false}
                                                        //weekDays={weekDays}
                                                        //months={months}
                                                        locale="it-IT"
                                                        clearIcon={null}
                                                        weekStartDayIndex={1}
                                                    />
                                                </div>
                                            </div>
                                            <div id="w-node-_284812ee-7924-9f38-86bb-8d2cc43dff3c-867f153d" className="div_item_1">
                                                <div id="w-node-_80bb2acb-3043-3649-0498-379a652f3b04-867f153d" className="label_text">Data fine</div>
                                                <div data-hover="false" data-delay="0" className="select w-dropdown">
                                                    <DatePicker
                                                        value={dataFineInput}
                                                        format="dd/MM/y"
                                                        weekPicker
                                                        onChange={onChangeRangeDateFine}
                                                        //showTodayButton={false}
                                                        //weekDays={weekDays}
                                                        //months={months}
                                                        locale="it-IT"
                                                        clearIcon={null}
                                                        weekStartDayIndex={1}
                                                    />
                                                </div>
                                            </div>
                                            <div id="w-node-_98d32d21-f772-2386-6ce9-47c8e0ec064d-867f153d" className="div_item_1">
                                                <div className="w-form">
                                                    <div id="email-form" name="email-form" data-name="Email Form" method="get">
                                                        <label className="w-checkbox checkbox-field">
                                                            <input type="checkbox" id="mostraTutti" name="mostraTutti" data-name="mostraTutti" value={mostraTutti} checked={mostraTutti} className="w-checkbox-input" onChange={e => onChangeMostraTutti()} />
                                                            <span className="label_text w-form-label" htmlFor="checkbox">Mostra tutti</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="item_2">
                                            <div className="intestazione_table">
                                                <div className="w-layout-grid div_column_punti_servizio intestazione">
                                                    <div className="div_item_column">
                                                        <div className="item_table"></div>
                                                    </div>
                                                    <div className="div_item_column">
                                                        <div className="item_table align_left">Denominazione</div>
                                                    </div>
                                                    <div className="div_item_column">
                                                        <div className="item_table align_left">Cliente</div>
                                                    </div>
                                                    <div className="div_item_column">
                                                        <div className="item_table align_left">Indirizzo</div>
                                                    </div>
                                                    <div className="div_item_column">
                                                        <div className="item_table align_left">Telefono</div>
                                                    </div>
                                                    <div className="div_item_column">
                                                        <div className="item_table align_left">Da controllare</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="body_table">
                                                {puntiServizioList.map((puntoVendita, i) => (
                                                    <div key={i} className={`w-layout-grid div_column_punti_servizio cursor ${puntoServizioSelected._id == puntoVendita._id ? 'punto_servizio_selected' : ''}`}>
                                                        <div className="div_item_column" onClick={(e) => onClickSelectPuntoServizio( e, puntoVendita._id )}>
                                                            <div className="item_table">{i + 1}</div>
                                                        </div>
                                                        <div className="div_item_column" onClick={(e) => onClickSelectPuntoServizio( e, puntoVendita._id )}>
                                                            <div className="item_table align_left">{puntoVendita.denominazione}</div>
                                                        </div>
                                                        <div className="div_item_column" onClick={(e) => onClickSelectPuntoServizio( e, puntoVendita._id )}>
                                                            <div className="item_table align_left">{puntoVendita.cliente.ragioneSoc}</div>
                                                        </div>
                                                        <div className="div_item_column" onClick={(e) => onClickSelectPuntoServizio( e, puntoVendita._id )}>
                                                            <div className="item_table align_left">{puntoVendita.indirizzo} {puntoVendita.cap} {puntoVendita.localita} {puntoVendita.provincia}</div>
                                                        </div>
                                                        <div className="div_item_column" onClick={(e) => onClickSelectPuntoServizio( e, puntoVendita._id )}>
                                                            <div className="item_table align_left">{puntoVendita.telefono}</div>
                                                        </div>
                                                        {false ?
                                                        <div className="div_item_column" onClick={() => {onClickEliminaPuntoVendita(puntoVendita._id)}}>
                                                            <div className="item_table align_left"><div className='cestino'><img src="assets/media/logos/trash-can.png"/></div></div>
                                                        </div>
                                                        :
                                                            ""
                                                        }
                                                        <div className="div_item_column" onClick={(e) => onClickSelectPuntoServizio( e, puntoVendita._id )}>
                                                            <div className="item_table">{puntoVendita.controllo && puntoVendita.controllo.esito ? '' : '🟠' }</div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="item_3 m_top_5">
                                            <div className="list_button">
                                                <div id="w-node-_47761b2d-160d-03bc-cd09-8911d6401437-867f153d" className="div_item_button">
                                                    <a id="w-node-_12c52e6d-210d-5526-7356-6c64cce43733-867f153d" onClick={() => (puntoServizioSelected._id ? onClickOpenModificaPuntoServizio(puntoServizioSelected._id) : null)} className={`button w-button ${puntoServizioSelected._id ? "" : "button_disabled"}`}>Modifica punto servizio</a>
                                                </div>
                                                <div id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98d-867f153d" className="div_item_button">
                                                    <a id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98e-867f153d" className={`button w-button ${csvData.length == 0 ? "button_disabled" : ""}`} onClick={(e) => csvData.length == 0 ? null : saveAsExcel(csvData, headers)}>Scarica XLS tutti i punti di servizio</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {openModificaPuntoServizio ?
                        <div className="div_wrap_50">
                            <div className="div_container">
                                <div className="title_window">
                                    <div className="title_window_p">Punto di servizio</div>
                                </div>
                                <ModificaPuntoVendita
                                    idPuntoVendita={puntoServizioSelected._id}
                                    setOpenModificaPuntoServizio={setOpenModificaPuntoServizio}
                                />
                            </div>
                        </div>
                        :
                        ''
                    }
                    {openNuovoPuntoServizio ?
                        <div className="div_wrap_50">
                            <div className="div_container">
                                <div className="title_window">
                                    <div className="title_window_p">Punto di servizio</div>
                                </div>
                                <NuovoPuntoVendita
                                    setOpenNuovoPuntoServizio={setOpenNuovoPuntoServizio}
                                />
                            </div>
                        </div>
                        :
                        ''
                    }
                </div>
            </div>
        </div>
    )
}

PuntiVendita.protoTypes = {
    getPuntiVendita: PropTypes.func.isRequired,
    eliminaPuntoVendita: PropTypes.func.isRequired,
    puntoVendita: PropTypes.object.isRequired,
    getClienti: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    filiale: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    puntoVendita: state.puntoVendita,
    cliente: state.cliente,
    auth: state.auth,
    filiale: state.filiale
});

export default connect(mapStateToProps, { getPuntiVendita, getClienti, eliminaPuntoVendita, getFiliali })(PuntiVendita);