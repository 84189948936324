import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { creaNuovoCliente } from '../../../actions/cliente';
const NuovoCliente = ({ setOpenNuovoCliente, creaNuovoCliente, alert }) => {

    useEffect(() => {
        const size = alert.length;
        if (size > 0) {
            if (alert[size - 1].alertType === "success") {
                setOpenNuovoCliente(false);
                setDatiCliente(clienteInitialState);
            }
        }
    }, [setOpenNuovoCliente, alert]);

    const clienteInitialState = {
        codice: '',
        ragioneSoc: '',
        indirizzo: '',
        cap: '',
        localita: '',
        provincia: '',
        telefono: '',
        fax: '',
        piva: '',
        idAttivita: '',
        fatturazione: true
    }
    const [datiCliente, setDatiCliente] = useState(clienteInitialState);
    const { codice, ragioneSoc, indirizzo, cap, localita, provincia, telefono, fax, piva, idAttivita, fatturazione } = datiCliente;


    const onChangeSetDatiCliente = e => setDatiCliente({ ...datiCliente, [e.target.name]: e.target.value });

    const onSubmitCreaCliente = async e => {
        e.preventDefault();
        creaNuovoCliente({ codice, ragioneSoc, indirizzo, cap, localita, provincia, telefono, fax, piva, idAttivita, fatturazione });
    };
    return (
        <Fragment>
            <div className="dati_container">
                <div className="fun_assegnazione_incarichi">
                    <div className="row">
                        <div className="item_2">
                            <h4>Crea nuovo cliente</h4>
                            <div>
                                <div className="w-form">
                                    <form id="email-form-2" name="email-form-2" data-name="Email Form 2" method="get" aria-label="Email Form 2">
                                        <div className="div_wrap_100">
                                            <div className="div_wrap_25">
                                                <div className="col_input">
                                                    <label htmlFor="name">Ragione sociale</label>
                                                    <input type="text" className="input w-input" maxLength="256" name="ragioneSoc" value={ragioneSoc} data-name="ragioneSoc" placeholder="Inserisci ragione sociale" id="ragioneSoc" onChange={e => onChangeSetDatiCliente(e)} />
                                                </div>
                                            </div>
                                            <div className="div_wrap_25">
                                                <div className="col_input">
                                                    <label htmlFor="name">P.IVA</label>
                                                    <input type="text" className="input w-input" maxLength="256" name="piva" data-name="piva" value={piva} placeholder="Inserisci p.Iva" id="piva" onChange={e => onChangeSetDatiCliente(e)} />
                                                </div>
                                            </div>
                                            <div className="div_wrap_25">
                                                <div className="col_input">
                                                    <label htmlFor="name">Codice</label>
                                                    <input type="text" className="input w-input" maxLength="256" name="codice" data-name="codice" value={codice} placeholder="Inserisci codice" id="codice" onChange={e => onChangeSetDatiCliente(e)} />
                                                </div>
                                            </div>
                                            <div className="div_wrap_25">
                                                <div className="col_input col_select">
                                                    <label htmlFor="name">Fatturazione</label>
                                                    <div data-hover="false" data-delay="0" className="select w-dropdown">
                                                        <select className="form-control select" name="fatturazione" onChange={e => onChangeSetDatiCliente(e)} required>
                                                            <option selected value={true} >Si</option>
                                                            <option value={false} >No</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="div_wrap_100">
                                            <div className="div_wrap_25">
                                            <div className="col_input">
                                                    <label htmlFor="name">Indirizzo</label>
                                                    <input type="text" className="input w-input" maxLength="256" name="indirizzo" data-name="indirizzo" value={indirizzo} placeholder="Inserisci indirizzo" id="indirizzo" onChange={e => onChangeSetDatiCliente(e)} />
                                                </div>
                                            </div>
                                            <div className="div_wrap_25">
                                            <div className="col_input">
                                                    <label htmlFor="name">Provincia</label>
                                                    <input type="text" className="input w-input" maxLength="256" name="provincia" value={provincia} data-name="provincia" placeholder="Inserisci provincia" id="provincia" onChange={e => onChangeSetDatiCliente(e)} />
                                                </div>
                                            </div>
                                            <div className="div_wrap_25">
                                            <div className="col_input">
                                                    <label htmlFor="name">CAP</label>
                                                    <input type="text" className="input w-input" maxLength="256" name="cap" data-name="cap" value={cap} placeholder="Inserisci cap" id="cap" onChange={e => onChangeSetDatiCliente(e)} />
                                                </div>
                                            </div>
                                            <div className="div_wrap_25">
                                                <div className="col_input">
                                                    <label htmlFor="name">Località</label>
                                                    <input type="text" className="input w-input" maxLength="256" name="localita" data-name="localita" value={localita} placeholder="Inserisci località" id="localita" onChange={e => onChangeSetDatiCliente(e)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="div_wrap_100">
                                            <div className="div_wrap_50">
                                                <div className="col_input">
                                                    <label htmlFor="name">Telefono</label>
                                                    <input type="text" className="input w-input" maxLength="256" name="telefono" data-name="telefono" value={telefono} placeholder="Inserisci telefono" id="telefono" onChange={e => onChangeSetDatiCliente(e)} />
                                                </div>
                                            </div>
                                            <div className="div_wrap_50">
                                                <div className="col_input">
                                                    <label htmlFor="name">Fax</label>
                                                    <input type="text" className="input w-input" maxLength="256" name="fax" data-name="fax" value={fax} placeholder="Inserisci fax" id="fax" onChange={e => onChangeSetDatiCliente(e)} />
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="item_4 m_top_5">
                            <div className="item_5_function">
                                <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc3d-b8dac9c0" className="div_item_empty"></div>
                                <div id="w-node-d27a7e1c-7034-f43d-edeb-5fcab2bbc790-b8dac9c0" className="div_item_empty"></div>
                                <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc40-b8dac9c0" className="div_item_empty"></div>
                                <div id="w-node-d409c487-9a0c-6b1f-803c-c7c11a8afa3e-b8dac9c0" className="div_item_button">
                                    <a onClick={(e) => onSubmitCreaCliente(e)} className="button w-button">Salva</a>
                                </div>
                                <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc43-b8dac9c0" className="div_item_button">
                                    <a id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc44-b8dac9c0" onClick={() => setOpenNuovoCliente(false)} className="button w-button">Chiudi</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}


NuovoCliente.protoTypes = {
    creaNuovoCliente: PropTypes.func.isRequired,
    alert: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    alert: state.alert
});

export default connect(mapStateToProps, { creaNuovoCliente })(NuovoCliente);



