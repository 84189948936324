import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { saveAs } from "file-saver";
import { Helmet } from "react-helmet";
import XlsxPopulate from "xlsx-populate";
import Spinner from '../../layout/Spinner';
import { getDipendenti } from '../../../actions/dipendente';
const StampaPoligono = ({ alert, getDipendenti, dipendente: { dipendenti, loading } }) => {


    const [csvData, setCsvData] = useState([]);
    const [dataLoading, setDataLoading] = useState(false);



    const headers = [
        { label: '', key: 'dipendente' },
        { label: 'Data prova', key: 'data1' },
        { label: 'Data prova', key: 'data2' },
        { label: 'Data prova', key: 'data3' },
    ];


    useEffect(() => {
        getDipendenti(0, 10, false);
    }, [getDipendenti]);



    useEffect(() => {
        setDataLoading(true)
        let arrayDipendenti = [...dipendenti]
        let dataOggi = new Date()
        arrayDipendenti = arrayDipendenti.filter(function (dipendente) {
            return dipendente.dataFineAss == null || new Date(dipendente.dataFineAss) > dataOggi
        })

        arrayDipendenti = arrayDipendenti.filter(function (dipendente) {
            return dipendente.qualifica == "gpg"
        })

        let arrayPoligono = [];
        arrayDipendenti.map(el => {
            if (el.documentiTiroASegno.length > 0) {
                el.documentiTiroASegno.map(doc => {
                    if (doc.dataScadenza1) {
                        arrayPoligono.push({ dipendente: el.cognome + " " + el.nome, data1: toIsoString(new Date(doc.dataScadenza1)), data2: toIsoString(new Date(doc.dataScadenza2)), data3: toIsoString(new Date(doc.dataScadenza3)), eseguito1: doc.eseguito1, eseguito2: doc.eseguito2, eseguito3: doc.eseguito3 })
                    }
                })
            }
            else{
                arrayPoligono.push({ dipendente: el.cognome + " " + el.nome, data1: null, data2: null, data3: null, eseguito1: false, eseguito2: false, eseguito3: false  })
            }
        })
        setCsvData(arrayPoligono)
        setDataLoading(false)
    }, [dipendenti]);



    const toIsoString = (date) => {
        const pad = function (num) {
            return (num < 10 ? '0' : '') + num;
        };

        return pad(date.getDate()) +
            '-' + pad(date.getMonth() + 1) +
            '-' + date.getFullYear()
    }

    async function saveAsExcel(data, header) {

        XlsxPopulate.fromBlankAsync().then(async (workbook) => {
            const sheet1 = workbook.sheet(0);
            const sheetData = getSheetData(data, header);
            const totalColumns = sheetData[0].length;

            sheet1.cell("A1").value(sheetData);
            const range = sheet1.usedRange();
            const endColumn = String.fromCharCode(64 + totalColumns);
            sheet1.row(1).style("bold", true);

            for(let i=0; i<data.length; i++){
                let cella = Number(i+2)
                if(data[i].eseguito1){
                    sheet1.cell("B"+ cella).style("bold", true);
                }
                if(data[i].eseguito2){
                    sheet1.cell("C"+ cella).style("bold", true);
                }
                if(data[i].eseguito3){
                    sheet1.cell("D"+ cella).style("bold", true);
                }
            }

            sheet1.range("A1:" + endColumn + "1").style("fill", "BFBFBF");
            range.style("border", true);
            return workbook.outputAsync().then((res) => {
                saveAs(res, "stampa-poligono.xlsx");
            });
        });
    }

    function getSheetData(data, header) {
        var fields = Object.keys(data[0]);
        var sheetData = data.map(function (row) {
            return fields.map(function (fieldName) {
                return row[fieldName] ? row[fieldName] : "";
            });
        });
        const headerLabel = header.map(function (row) { return row.label })
        sheetData.unshift(headerLabel);
        return sheetData;
    }




    return (


        <div className="body wf-section">
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`Stampa poligono - Gestionale Vigili dell'ordine`}</title>
            </Helmet>
            <div className="div_wrap">
                <div className="div_wrap_100">
                    <div className="div_container">
                        <div className="title_window">
                            <div className="title_window_p">Stampa poligono</div>
                        </div>
                        <div className="dati_container">
                            <div className="fun_assegnazione_incarichi">
                                <div className="row">
                                    <div className="item_2">
                                        <div className="intestazione_table">
                                            <div className="w-layout-grid div_column_stampa_poligono intestazione">
                                                <div className="div_item_column">
                                                    <div className="item_table">Dipendente</div>
                                                </div>
                                                <div className="div_item_column">
                                                    <div className="item_table">Data prova</div>
                                                </div>
                                                <div className="div_item_column">
                                                    <div className="item_table">Data prova</div>
                                                </div>
                                                <div className="div_item_column">
                                                    <div className="item_table">Data prova</div>
                                                </div>
                                            </div>
                                        </div>
                                        {dataLoading ? <Spinner /> :
                                            <div className="body_table filiale">
                                                {csvData.map((el, i) => (
                                                    <div key={"contratto" + i} className={`w-layout-grid div_column_stampa_poligono cursor`}>
                                                        <div className="div_item_column" >
                                                            <div className="item_table">{el.dipendente}</div>
                                                        </div>
                                                        <div className="div_item_column">
                                                            <div className={`item_table${el.eseguito1 ? " font-bold" : ""}`}>{el.data1}</div>
                                                        </div>
                                                        <div className="div_item_column">
                                                        <div className={`item_table${el.eseguito2 ? " font-bold" : ""}`}>{el.data2}</div>
                                                        </div>
                                                        <div className="div_item_column">
                                                        <div className={`item_table${el.eseguito3 ? " font-bold" : ""}`}>{el.data3}</div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="item_3 m_top_5">
                                        <div className="list_button penultimo_largo">
                                            <div id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98d-867f153d" className="div_item_button">
                                                <a id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98e-867f153d" className={`button w-button ${csvData.length == 0 ? "button_disabled" : ""}`} onClick={(e) => csvData.length == 0 ? null : saveAsExcel(csvData, headers)}>Scarica XLS</a>
                                            </div>
                                            <div id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98d-867f153d" className="div_item_button"></div>
                                            <div id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98d-867f153d" className="div_item_button"></div>
                                            <div id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98d-867f153d" className="div_item_button"></div>
                                            <div id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98d-867f153d" className="div_item_button"></div>
                                            <div id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98d-867f153d" className="div_item_button"></div>
                                            <div id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98d-867f153d" className="div_item_button"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

StampaPoligono.protoTypes = {
    getDipendenti: PropTypes.func.isRequired,
    dipendente: PropTypes.object.isRequired,
    alert: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    dipendente: state.dipendente,
    alert: state.alert
});

export default connect(mapStateToProps, { getDipendenti })(StampaPoligono);