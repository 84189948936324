import { GET_PUNTI_VENDITA, GET_PUNTO_VENDITA, CREA_PUNTO_VENDITA, MODIFICA_PUNTO_VENDITA, ELIMINA_PUNTO_VENDITA, CLEAR_PUNTO_VENDITA, PUNTO_VENDITA_ERROR, GET_CONTRATTI_PUNTI_VENDITA, GET_CONTRATTI_PUNTI_VENDITA_CALENDARIO, GET_CONTRATTI_PUNTO_VENDITA, GET_CALENDARIO_JOB, CLEAN_CALENDARIO_RICHIESTE } from "../actions/types";

const initialState = {
    puntoVendita: null,
    puntiVendita: [],
    contratti: [],
    contrattiPuntoVendita: [],
    elementiCalendario: [],
    error: {},
    loading: true,
    loadingPuntoVendita: true,
    loadingContratti: true,
    loadingElementiCalendario: true,
    jobCalendarioId: null
}

export default function(state = initialState, action){
    const { type, payload } = action;

    switch(type){
        case GET_PUNTI_VENDITA:
            return {
                ...state,
                puntiVendita: payload,
                loading: false
            };
        case GET_PUNTO_VENDITA:
            return {
                ...state,
                puntoVendita: payload,
                loadingPuntoVendita: false
            };
        case CREA_PUNTO_VENDITA:
            let arrayCreaPuntoVendita = [...state.puntiVendita];
            arrayCreaPuntoVendita.push(payload);
            arrayCreaPuntoVendita.sort((a,b) => (a.denominazione.toLowerCase() > b.denominazione.toLowerCase()) ? 1 : ((b.denominazione.toLowerCase() > a.denominazione.toLowerCase()) ? -1 : 0)); 
            return {
                ...state,
                puntiVendita: arrayCreaPuntoVendita,
                loading: false
            };
        case MODIFICA_PUNTO_VENDITA:
            const index = state.puntiVendita.findIndex(x => x._id == payload._id );
            let arrayModificaPuntoVendita = [...state.puntiVendita];
            arrayModificaPuntoVendita[index] = payload
            arrayModificaPuntoVendita.sort((a,b) => (a.denominazione.toLowerCase() > b.denominazione.toLowerCase()) ? 1 : ((b.denominazione.toLowerCase() > a.denominazione.toLowerCase()) ? -1 : 0)); 
            return {
                ...state,
                puntiVendita: arrayModificaPuntoVendita,
                loadingPuntoVendita: false
            };
        case ELIMINA_PUNTO_VENDITA:
            let puntoVenditaElimina = [...state.puntiVendita];
            let newArray = puntoVenditaElimina.filter(function (el) { return el._id != payload});
            return {
                ...state,
                puntiVendita: newArray,
                loading: false
            };
        case GET_CONTRATTI_PUNTI_VENDITA:
            return {
                ...state,
                contratti: payload,
                loadingContratti: false
            }
        case GET_CONTRATTI_PUNTI_VENDITA_CALENDARIO:
            return {
                ...state,
                jobCalendarioId: payload,
                loadingElementiCalendario: false
            }
        case GET_CONTRATTI_PUNTO_VENDITA:
            return {
                ...state,
                contrattiPuntoVendita: payload,
                loadingContratti: false
            }
        case CLEAR_PUNTO_VENDITA:
            return {
                ...state,
                puntoVendita: null,
                loadingPuntoVendita: true
            }
        case GET_CALENDARIO_JOB:
            return {
                ...state,
                elementiCalendario: payload.elementiCalendario,
                loading: false,
                jobCalendarioId: null
            };
        case CLEAN_CALENDARIO_RICHIESTE:
            return {
                ...state,
                elementiCalendario: [],
                loading: false,
                jobCalendarioId: null
            };
        case PUNTO_VENDITA_ERROR:
            return { 
                ...state,
                error: payload,
                loading: false
            };
        default:
            return state;
    }
}