import React, { useEffect, useState } from 'react';
const QuadratureElementTotali = ({labelA, labelB, totaleOreQuadrature}) => {

    const [quadraturaOre, setQuadraturaOre] = useState({gennaio: "00:00", febbraio: "00:00", marzo: "00:00", aprile: "00:00", maggio: "00:00", giugno: "00:00", luglio: "00:00", agosto: "00:00", settembre: "00:00", oottobre: "00:00", novembre: "00:00", dicembre: "00:00", totale: "00:00"});

    useEffect(() => {
        setQuadraturaOre({ ...quadraturaOre, 
            gennaio: fromMinutiToOreString(totaleOreQuadrature.gennaio), 
            febbraio: fromMinutiToOreString(totaleOreQuadrature.febbraio), 
            marzo: fromMinutiToOreString(totaleOreQuadrature.marzo), 
            aprile: fromMinutiToOreString(totaleOreQuadrature.aprile), 
            maggio: fromMinutiToOreString(totaleOreQuadrature.maggio), 
            giugno: fromMinutiToOreString(totaleOreQuadrature.giugno), 
            luglio: fromMinutiToOreString(totaleOreQuadrature.luglio), 
            agosto: fromMinutiToOreString(totaleOreQuadrature.agosto), 
            settembre: fromMinutiToOreString(totaleOreQuadrature.settembre), 
            ottobre: fromMinutiToOreString(totaleOreQuadrature.ottobre), 
            novembre: fromMinutiToOreString(totaleOreQuadrature.novembre), 
            dicembre: fromMinutiToOreString(totaleOreQuadrature.dicembre),
            totale: fromMinutiToOreString(totaleOreQuadrature.totale)
        });      
    }, [totaleOreQuadrature]);

    const fromMinutiToOreString = (minutiTotali) => {
        let minutiTotaliAbs = Math.abs(minutiTotali);
        var minuti = minutiTotaliAbs % 60;
        var ore = (minutiTotaliAbs - minuti) / 60;
        if (minutiTotali >= 0) {
            if (ore == 0 && minuti == 0) {
                return "00:00"
            } else {
                return formattaOre(ore) + ":" + formattaMinuti(minuti)
            }
        } else {
            return "-" + formattaOre(ore) + ":" + formattaMinuti(minuti)

        }
    }

    const formattaOre = (ore) => {
        return (ore <= 9 ? "0" : "") + ore
    }

    const formattaMinuti = (minuti) => {
        return (minuti <= 9 ? "0" : "") + minuti;
    }

    const onChangeData = (field, value) => {
        setQuadraturaOre({ ...quadraturaOre, [field]: value });      
    }
    
    return (
        <div key={"quadraturaOreTotaleElementTot"+labelB} className={`w-layout-grid div_column_quadrature_ore testi-grassetto`}>
                    <div className="div_item_column div_item_column_quadratura_ore">
                        <div className="item_table_quadratura">{labelA}</div>
                    </div>
                    <div className="div_item_column div_item_column_quadratura_ore">
                    <div className="item_table_quadratura">{labelB}</div>
                    </div>
                    <div className="div_item_column div_item_column_quadratura_ore">
                    <input type="text" name="quadraturaOre.gennaio" value={quadraturaOre.gennaio} onChange={(e) => {onChangeData('gennaio', e.target.value)}} className="input w-input edit_quadratura item_table_quadratura"/>
                    </div>
                    <div className="div_item_column div_item_column_quadratura_ore">
                    <input type="text" name="quadraturaOre.febbraio" value={quadraturaOre.febbraio} onChange={(e) => {onChangeData('febbraio', e.target.value)}} className="input w-input edit_quadratura item_table_quadratura"/>
                    </div>
                    <div className="div_item_column div_item_column_quadratura_ore">
                    <input type="text" name="quadraturaOre.marzo" value={quadraturaOre.marzo} onChange={(e) => {onChangeData('marzo', e.target.value)}} className="input w-input edit_quadratura item_table_quadratura"/>
                    </div>
                    <div className="div_item_column div_item_column_quadratura_ore">
                    <input type="text" name="quadraturaOre.aprile" value={quadraturaOre.aprile} onChange={(e) => {onChangeData('aprile', e.target.value)}} className="input w-input edit_quadratura item_table_quadratura"/>
                    </div>
                    <div className="div_item_column div_item_column_quadratura_ore">
                    <input type="text" name="quadraturaOre.maggio" value={quadraturaOre.maggio} onChange={(e) => {onChangeData('maggio', e.target.value)}} className="input w-input edit_quadratura item_table_quadratura"/>
                    </div>
                    <div className="div_item_column div_item_column_quadratura_ore">
                    <input type="text" name="quadraturaOre.giugno" value={quadraturaOre.giugno} onChange={(e) => {onChangeData('giugno', e.target.value)}} className="input w-input edit_quadratura item_table_quadratura"/>
                    </div>
                    <div className="div_item_column div_item_column_quadratura_ore">
                    <input type="text" name="quadraturaOre.luglio" value={quadraturaOre.luglio} onChange={(e) => {onChangeData('luglio', e.target.value)}} className="input w-input edit_quadratura item_table_quadratura"/>
                    </div>
                    <div className="div_item_column div_item_column_quadratura_ore">
                    <input type="text" name="quadraturaOre.agosto" value={quadraturaOre.agosto} onChange={(e) => {onChangeData('agosto', e.target.value)}} className="input w-input edit_quadratura item_table_quadratura"/>
                    </div>
                    <div className="div_item_column div_item_column_quadratura_ore">
                    <input type="text" name="quadraturaOre.settembre" value={quadraturaOre.settembre} onChange={(e) => {onChangeData('settembre', e.target.value)}} className="input w-input edit_quadratura item_table_quadratura"/>
                    </div>
                    <div className="div_item_column div_item_column_quadratura_ore">
                    <input type="text" name="quadraturaOre.ottobre" value={quadraturaOre.ottobre} onChange={(e) => {onChangeData('ottobre', e.target.value)}} className="input w-input edit_quadratura item_table_quadratura"/>
                    </div>
                    <div className="div_item_column div_item_column_quadratura_ore">
                    <input type="text" name="quadraturaOre.novembre" value={quadraturaOre.novembre} onChange={(e) => {onChangeData('novembre', e.target.value)}} className="input w-input edit_quadratura item_table_quadratura"/>
                    </div>
                    <div className="div_item_column div_item_column_quadratura_ore">
                    <input type="text" name="quadraturaOre.dicembre" value={quadraturaOre.dicembre} onChange={(e) => {onChangeData('dicembre', e.target.value)}} className="input w-input edit_quadratura item_table_quadratura"/>
                    </div>
                    <div className="div_item_column div_item_column_quadratura_ore">
                    <input type="text" name="quadraturaOre.totale" value={quadraturaOre.totale} onChange={(e) => {onChangeData('totale', e.target.value)}} className="input w-input edit_quadratura item_table_quadratura"/>
                    </div>
        </div>
    )
}

export default QuadratureElementTotali;