import { GET_CLIENTI, GET_CLIENTE, MODIFICA_CLIENTE, CREA_CLIENTE, CLIENTE_ERROR, CLEAR_CLIENTE } from "../actions/types";

const initialState = {
    cliente: null,
    clienti: [],
    error: {},
    loading: true,
    loadingCliente: true
}

export default function(state = initialState, action){
    const { type, payload } = action;

    switch(type){
        case GET_CLIENTI: 
            return {
                ...state,
                clienti: payload,
                loading: false
            };
        case GET_CLIENTE:
            return {
                ...state,
                cliente: payload,
                loadingCliente: false
            };
        case CREA_CLIENTE:
            let arrayCreaClienti = [...state.clienti];
            arrayCreaClienti.push(payload);
            arrayCreaClienti.sort((a,b) => (a.ragioneSoc.toLowerCase() > b.ragioneSoc.toLowerCase()) ? 1 : ((b.ragioneSoc.toLowerCase() > a.ragioneSoc.toLowerCase()) ? -1 : 0)); 
            return {
                ...state,
                clienti: arrayCreaClienti,
                loading: false
            };
        case MODIFICA_CLIENTE:
            const index = state.clienti.findIndex(x => x._id == payload._id );
            let arrayModificaCliente = [...state.clienti];
            arrayModificaCliente[index] = payload
            arrayModificaCliente.sort((a,b) => (a.ragioneSoc.toLowerCase() > b.ragioneSoc.toLowerCase()) ? 1 : ((b.ragioneSoc.toLowerCase() > a.ragioneSoc.toLowerCase()) ? -1 : 0)); 
            return {
                ...state,
                clienti: arrayModificaCliente,
                loading: false
            };
        case CLEAR_CLIENTE:
            return {
                ...state,
                cliente: null,
                loadingCliente: true
            }
        case CLIENTE_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            };
        default:
            return state;
    }
}