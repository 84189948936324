import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Moment from 'react-moment'
import {Helmet} from "react-helmet";
import { getFestivita, creaFestivita, eliminaFestivita } from '../../../actions/impostazione';
const Impostazioni = ({ impostazione: { festivita }, getFestivita, creaFestivita, alert, eliminaFestivita }) => {

    const [openNuovaFestivita, setOpenNuovaFestivita] = useState(false);
    const [festivitaData, setFestivitaData] = useState([]);
    const [nuovaFestivita, setNuovaFestivita] = useState({giorno: "", mese: "", anno: ""});


    const [rowSelected, setRowSelected] = useState(null);

    //*
    useEffect(() => {
        getFestivita();
    }, []);

    //*
    useEffect(() => {
        let array = [...festivita]
        array.sort((a,b) => {
            if(a.split("-")[2] > b.split("-")[2]){
                return -1
            }else if(a.split("-")[2] < b.split("-")[2]){
                return 1
            }else{
                if(a.split("-")[1] < b.split("-")[1]){
                    return -1
                }else if(a.split("-")[1] > b.split("-")[1]){
                    return 1
                }else{
                    if(a.split("-")[0] < b.split("-")[0]){
                        return -1
                    }else if(a.split("-")[0] > b.split("-")[0]){
                        return 1
                    }else{
                        return 0
                    }
                }
            }
        })
        
        
        setFestivitaData(array);
    }, [festivita]);


    //*
    useEffect(() => {
        const size = alert.length;
        if (size > 0) {
            if (alert[size - 1].alertType === "success") {
                //setNuovaFestivita({giorno: "", mese: "", anno: ""});
            }
        }
    }, [alert]);

    //FUNZIONI ONCLICK

    const generaMese = (giorno) => {
        switch(giorno) {
            case "01":
            return 'Gennaio'
            case "02":
            return 'Febbraio'
            case "03":
            return 'Marzo'
            case "04":
            return 'Aprile'
            case "05":
            return 'Maggio'
            case "06":
            return 'Giugno'
            case "07":
            return 'Luglio'
            case "08":
            return 'Agosto'
            case "09":
            return 'Settembre'
            case "10":
            return 'Ottobre'
            case "11":
            return 'Novembre'
            case "12":
            return 'Dicembre'
            default: return 'Gennaio'
        }
    }
    //*
    const onClickopenNuovaFestivita = () => {
        setOpenNuovaFestivita(true)
    }



    //*
    const onClickSetRowSelected = (festivita) => {
        setRowSelected(festivita)
    }



    //*
    const onClickEliminaFestivita = (() => {
        let array = [...festivita];
        array = array.filter(el => el != rowSelected);
        eliminaFestivita({arrayFestivita: array})
        setRowSelected(null)
    })

    //*
    const onClickAddFestivita = (() => {
        let array = [...festivita];
        let string = nuovaFestivita.giorno+"-"+nuovaFestivita.mese+"-"+nuovaFestivita.anno;
        array.push(string)
        creaFestivita({ arrayFestivita: array })
    })


    const onChangeSetMese = (mese) => {
        setNuovaFestivita({...nuovaFestivita, mese: mese})
    }

    const onChangeSetGiorno = (giorno) => {
        setNuovaFestivita({...nuovaFestivita, giorno: giorno})
    }

    const onChangeSetAnno = (anno) => {
        setNuovaFestivita({...nuovaFestivita, anno: anno})
    }

    return (
        <div className="body wf-section">
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`Impostazioni - Gestionale Vigili dell'ordine`}</title>
            </Helmet>
            <div className="div_wrap">
                <div className="div_wrap_100">
                    <div className="div_wrap_50">
                        <div className="div_container">
                            <div className="title_window">
                                <div className="title_window_p">Gestione festività</div>
                            </div>
                            <div className="dati_container">
                                <div className="fun_assegnazione_incarichi">
                                    <div className="row">
                                        <div className="item_2">
                                            <div className="intestazione_table">
                                                <div className="w-layout-grid div_column_festivita intestazione">
                                                    <div className="div_item_column">
                                                        <div className="item_table">giorno</div>
                                                    </div>
                                                    <div className="div_item_column">
                                                        <div className="item_table">mese</div>
                                                    </div>
                                                    <div className="div_item_column">
                                                        <div className="item_table">anno</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="body_table">
                                                {festivitaData.map((festivita, i) => (
                                                    <>
                                                    <div key={"festivita" + i} className={`w-layout-grid div_column_festivita cursor ${rowSelected == festivita ? 'giorno_selected' : ''}`}>
                                                        <div className="div_item_column" onClick={() => onClickSetRowSelected(festivita)}>
                                                            <div className="item_table">{festivita.split("-")[0]}</div>
                                                        </div>
                                                        <div className="div_item_column" onClick={() => onClickSetRowSelected(festivita)}>
                                                            <div className="item_table">{generaMese(festivita.split("-")[1])}</div>
                                                        </div>
                                                        <div className="div_item_column" onClick={() => onClickSetRowSelected(festivita)}>
                                                            <div className="item_table">{festivita.split("-")[2]}</div>
                                                        </div>
                                                    </div>
                                                </>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="item_3 m_top_5">
                                            <div className="list_button list_button_2">
                                                {false ?
                                                <div id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98d-867f153d" className="div_item_button">
                                                    <a id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98e-867f153d" className={`button w-button red ${rowSelected ? "" : "button_disabled"}`} onClick={(e) => !rowSelected ? e.preventDefault() : onClickEliminaFestivita()}>Elimina</a>
                                                </div>
                                                :
                                                <div id="w-node-bf561da2-25a7-b3ef-c44c-a819295560e3-867f153d" className="div_item_empty"></div>
                                                }
                                                <div id="w-node-bf561da2-25a7-b3ef-c44c-a819295560e3-867f153d" className="div_item_empty"></div>
                                                <div id="w-node-bf561da2-25a7-b3ef-c44c-a819295560e3-867f153d" className="div_item_empty"></div>
                                                <div id="w-node-bf561da2-25a7-b3ef-c44c-a819295560e3-867f153d" className="div_item_empty"></div>
                                                <div id="w-node-bf561da2-25a7-b3ef-c44c-a819295560e3-867f153d" className="div_item_empty"></div>
                                                <div id="w-node-bf561da2-25a7-b3ef-c44c-a819295560e3-867f153d" className="div_item_empty"></div>
                                                <div id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98d-867f153d" className="div_item_button">
                                                    <a id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98e-867f153d" className="button w-button" onClick={(e) => onClickopenNuovaFestivita()}>Aggiungi</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {openNuovaFestivita ?
                        <div className="div_wrap_50">
                            <div className="div_container">
                                <div className="title_window">
                                    <div className="title_window_p">Nuova festivita</div>
                                </div>
                                <div className="dati_container assenze">
                                    <div className="fun_assegnazione_incarichi">
                                        <div className="row">
                                            <div className="item_2">
                                                <h4>Crea nuova festivita</h4>
                                                <div>
                                                    <div className="w-form">
                                                        <form id="email-form-2" name="email-form-2" data-name="Email Form 2" method="get" aria-label="Email Form 2">
                                                            <div className="div_wrap_100">
                                                                <div className="div_wrap_25">
                                                                    <div className="col_input col_select">
                                                                        <label htmlFor="name">Giorno</label>
                                                                        <div data-hover="false" data-delay="0" className="select w-dropdown">
                                                                            <select className="form-control select" name="tipologia" onChange={e => onChangeSetGiorno(e.target.value)} required>
                                                                            <option selected value={null} disabled >Seleziona giorno...</option>
                                                                            <option value={"01"} >01</option>
                                                                            <option value={"02"} >02</option>
                                                                            <option value={"03"} >03</option>
                                                                            <option value={"04"} >04</option>
                                                                            <option value={"05"} >05</option>
                                                                            <option value={"06"} >06</option>
                                                                            <option value={"07"} >07</option>
                                                                            <option value={"08"} >08</option>
                                                                            <option value={"09"} >09</option>
                                                                            <option value={"10"} >10</option>
                                                                            <option value={"11"} >11</option>
                                                                            <option value={"12"} >12</option>
                                                                            <option value={"13"} >13</option>
                                                                            <option value={"14"} >14</option>
                                                                            <option value={"15"} >15</option>
                                                                            <option value={"16"} >16</option>
                                                                            <option value={"17"} >17</option>
                                                                            <option value={"18"} >18</option>
                                                                            <option value={"19"} >19</option>
                                                                            <option value={"20"} >20</option>
                                                                            <option value={"21"} >21</option>
                                                                            <option value={"22"} >22</option>
                                                                            <option value={"23"} >23</option>
                                                                            <option value={"24"} >24</option>
                                                                            <option value={"25"} >25</option>
                                                                            <option value={"26"} >26</option>
                                                                            <option value={"27"} >27</option>
                                                                            <option value={"28"} >28</option>
                                                                            <option value={"29"} >29</option>
                                                                            <option value={"30"} >30</option>
                                                                            <option value={"31"} >31</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="div_wrap_25">
                                                                    <div className="col_input col_select">
                                                                        <label htmlFor="name">Mese</label>
                                                                        <div data-hover="false" data-delay="0" className="select w-dropdown">
                                                                            <select className="form-control select" name="tipologia" onChange={e => onChangeSetMese(e.target.value)} required>
                                                                                <option selected value={null} disabled >Seleziona mese...</option>
                                                                                <option value={"01"} >Gennaio</option>
                                                                                <option value={"02"} >Febbraio</option>
                                                                                <option value={"03"} >Marzo</option>
                                                                                <option value={"04"} >Aprile</option>
                                                                                <option value={"05"} >Maggio</option>
                                                                                <option value={"06"} >Giugno</option>
                                                                                <option value={"07"} >Luglio</option>
                                                                                <option value={"08"} >Agosto</option>
                                                                                <option value={"09"} >Settembre</option>
                                                                                <option value={"10"} >Ottobre</option>
                                                                                <option value={"11"} >Novembre</option>
                                                                                <option value={"12"} >Dicembre</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="div_wrap_25">
                                                                    <div className="col_input col_select">
                                                                        <label htmlFor="name">Anno</label>
                                                                        <div data-hover="false" data-delay="0" className="select w-dropdown">
                                                                            <select className="form-control select" name="tipologia" onChange={e => onChangeSetAnno(e.target.value)} required>
                                                                                <option selected value={null} disabled >Seleziona anno...</option>
                                                                                <option value={"2023"} >2023</option>
                                                                                <option value={"2024"} >2024</option>
                                                                                <option value={"2025"} >2025</option>
                                                                                <option value={"2026"} >2026</option>
                                                                                <option value={"2027"} >2027</option>
                                                                                <option value={"2028"} >2028</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="div_wrap_25">
                                                                </div>
                                                                <div className="div_wrap_25">
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="item_3 m_top_5">
                                                <div className="list_button list_button_2">
                                                    <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc3d-b8dac9c0" className="div_item_empty"></div>
                                                    <div id="w-node-d27a7e1c-7034-f43d-edeb-5fcab2bbc790-b8dac9c0" className="div_item_empty"></div>
                                                    <div id="w-node-d27a7e1c-7034-f43d-edeb-5fcab2bbc790-b8dac9c0" className="div_item_empty"></div>
                                                    <div id="w-node-d27a7e1c-7034-f43d-edeb-5fcab2bbc790-b8dac9c0" className="div_item_empty"></div>
                                                    <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc40-b8dac9c0" className="div_item_empty"></div>
                                                    <div id="w-node-d409c487-9a0c-6b1f-803c-c7c11a8afa3e-b8dac9c0" className="div_item_button">
                                                        <a onClick={() => onClickAddFestivita()} className="button w-button">Salva</a>
                                                    </div>
                                                    <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc43-b8dac9c0" className="div_item_button">
                                                        <a id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc44-b8dac9c0" onClick={() => setOpenNuovaFestivita(false)} className="button w-button">Chiudi</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        ''
                    }
                </div>
            </div>
        </div>
    )
}

Impostazioni.protoTypes = {
    getFestivita: PropTypes.func.isRequired,
    creaFestivita: PropTypes.func.isRequired,
    eliminaFestivita: PropTypes.func.isRequired,
    impostazione: PropTypes.object.isRequired,
    alert: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    impostazione: state.impostazione,
    alert: state.alert
});

export default connect(mapStateToProps, { getFestivita, creaFestivita, eliminaFestivita })(Impostazioni);