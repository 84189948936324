import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Spinner from '../../layout/Spinner';
import Moment from 'react-moment'
import { cleanCalendario } from '../../../actions/richiesta';
import 'moment/locale/it';
import { v4 as uuid } from 'uuid';

const Calendario = ({ setOpenCalendario, openCalendario, calendarioRichieste, calendarioLoading, cleanCalendario, richiesta: {richiestaUpdate}, elementoDuplicato, dataInput, elementoEliminato, elementoModificato }) => {

    const [contrattiCalendario, setContrattiCalendario] = useState([]);


    const findSizeBiggerArray = (dipendente) =>{
        let maxLength = 0;
        dipendente.calendario.map((giorno)=>{
            if(giorno.richieste.length > maxLength){
                maxLength = giorno.richieste.length
            }
        })
        return maxLength
    }

    useEffect(() => {
        let array = []
        let newArray = [];
        if(calendarioRichieste.contrattiRichieste && calendarioRichieste.contrattiRichieste.length > 0 && calendarioRichieste.dipendenti.length > 0){
            let giorni = calendarioRichieste.contrattiRichieste;
            let arrayDipendenti = calendarioRichieste.dipendenti;
            let arrayDisponibilita = calendarioRichieste.disponibilita;
            arrayDipendenti.map((dipendente)=>{ 
                let objDipendente = {id: uuid(), dipendente: { nome: dipendente.nome, cognome: dipendente.cognome, _id: dipendente._id }, giorni: [], index: 0, sizeIndex: 0}
                giorni.map((giorno, i)=>{
                    const richieste = giorno.richieste.filter(richiesta => richiesta.dipendente._id === dipendente._id );
                    if(objDipendente.sizeIndex < richieste.length){
                        objDipendente.sizeIndex = richieste.length
                        objDipendente.index = i
                    }
                    objDipendente.giorni.push({giorno: giorno.giorno, richieste: richieste}) 
                })
                array.push(objDipendente)
            })
            array.map((dipendente) => {
                if(dipendente.sizeIndex == 0){
                    let obj = {id: dipendente.id, dipendente: { nome: dipendente.dipendente.nome, cognome: dipendente.dipendente.cognome, _id: dipendente.dipendente._id }, giorni: [], sdoppiato: false}
                    dipendente.giorni.map((giorno, i) =>{
                        let disponibilita = "";
                        if(arrayDisponibilita[i]){
                            const findDisponibilita = arrayDisponibilita[i].filter(disponibilita => disponibilita.idDipendente === dipendente.dipendente._id );
                            if(findDisponibilita.length > 0){
                                disponibilita = findDisponibilita[0].disponibilita
                            }
                        }
                        obj.giorni.push({giorno: giorno.giorno, richiesta: {stato: "vuota"}, disponibilita: disponibilita})
                    })
                    newArray.push(obj)
                }else{
                    for(let i = 0; i< dipendente.sizeIndex; i++){
                        let obj = {id: uuid(), dipendente: { nome: dipendente.dipendente.nome, cognome: dipendente.dipendente.cognome, _id: dipendente.dipendente._id }, giorni: [], sdoppiato: false}
                        dipendente.giorni.map(giorno =>{
                            if(i>0){
                                obj.sdoppiato = true
                            }
                            let disponibilita = "";
                            if(arrayDisponibilita[i]){
                                const findDisponibilita = arrayDisponibilita[i].filter(disponibilita => disponibilita.idDipendente === dipendente.dipendente._id );
                                if(findDisponibilita.length > 0){
                                    disponibilita = findDisponibilita[0].disponibilita
                                }
                            }
                            
                            if(typeof giorno.richieste[i] === 'undefined'){
                                obj.giorni.push({giorno: giorno.giorno, richiesta: {stato: "vuota"}, disponibilita: disponibilita})
                            }else{
                                obj.giorni.push({giorno: giorno.giorno, richiesta: giorno.richieste[i], disponibilita: disponibilita}) 
                            }
                        })
                        newArray.push(obj)
                    }
                }
            })
        }
        setContrattiCalendario(newArray)
    }, [calendarioRichieste]);
    



    useEffect(() => {
        let temp_state = [...contrattiCalendario];
        if (richiestaUpdate.tipologia == "associa"){
            if(richiestaUpdate.oldDipendente){
                temp_state = dissociaDipendenteRichiesta(temp_state, richiestaUpdate.richiesta._id)
            }
            const dipendenti = temp_state.filter(dipendente => dipendente.dipendente._id ==  richiestaUpdate.newDipendente._id)
            const indexGiorno = dipendenti[0].giorni.findIndex(giorno => datesAreOnSameDay(new Date(giorno.giorno), new Date(richiestaUpdate.richiesta.dataInizio)))
            if(dipendenti.length === 1 && indexGiorno > -1){
                const indexDipendente = temp_state.findIndex(dipendente => dipendente.id == dipendenti[0].id)
                if(indexDipendente > -1){
                    let temp_element = { ...temp_state[indexDipendente] };
                    if(temp_element.giorni[indexGiorno].richiesta.stato == "vuota"){
                        temp_element.giorni[indexGiorno].richiesta = richiestaUpdate.richiesta
                        temp_state[indexDipendente] = temp_element;
                    }else{
                        let obj = {id: uuid(), dipendente: { nome: temp_element.dipendente.nome, cognome: temp_element.dipendente.cognome, _id: temp_element.dipendente._id }, giorni: [], sdoppiato: true}
                        temp_element.giorni.map((giorno, i) =>{
                            if(i !== indexGiorno){
                                obj.giorni.push({giorno: giorno.giorno, richiesta: {stato: "vuota"}, disponibilita: giorno.disponibilita}) 
                            }else{
                                obj.giorni.push({giorno: giorno.giorno, richiesta: richiestaUpdate.richiesta, disponibilita: giorno.disponibilita}) 
                            }
                        })
                        temp_state.splice(indexDipendente+1, 0, obj);
                    }
                }
            }else if(dipendenti.length > 1 && indexGiorno > -1){
                const indexDipendente = dipendenti.findIndex(dipendente => dipendente.giorni[indexGiorno].richiesta.stato == 'vuota')
                if(indexDipendente > -1){
                    let temp_element = { ...temp_state[indexDipendente] };
                    temp_element.giorni[indexGiorno].richiesta = richiestaUpdate.richiesta
                    temp_state[indexDipendente] = temp_element;
                }else{
                    const indexUltimoDipendente = temp_state.findIndex(dipendente => dipendente.id == dipendenti[dipendenti.length-1].id)
                    if(indexUltimoDipendente > -1){
                        let obj = {id: uuid(), dipendente: { nome: temp_state[indexUltimoDipendente].dipendente.nome, cognome: temp_state[indexUltimoDipendente].dipendente.cognome, _id: temp_state[indexUltimoDipendente].dipendente._id }, giorni: [], sdoppiato: true}
                        temp_state[indexUltimoDipendente].giorni.map((giorno, i) =>{
                            if(i !== indexGiorno){
                                obj.giorni.push({giorno: giorno.giorno, richiesta: {stato: "vuota"}, disponibilita: giorno.disponibilita}) 
                            }else{
                                obj.giorni.push({giorno: giorno.giorno, richiesta: richiestaUpdate.richiesta, disponibilita: giorno.disponibilita}) 
                            }
                        })                        
                        temp_state.splice(indexUltimoDipendente+1, 0, obj);
                    }
                }
            }
            setContrattiCalendario(temp_state)
        }else if (richiestaUpdate.tipologia == "dissocia"){
            temp_state = dissociaDipendenteRichiesta(temp_state, richiestaUpdate.oldRichiesta._id)
            
        }else if (richiestaUpdate.tipologia == "modifica_orario"){
            const indexDipendente = temp_state.findIndex(dipendente => dipendente.giorni.some(giorno => giorno.richiesta._id === richiestaUpdate.richiesta._id));
            if(indexDipendente > -1){
                const indexGiorno = temp_state[indexDipendente].giorni.findIndex(giorno => giorno.richiesta._id == richiestaUpdate.richiesta._id)
                if(indexGiorno > -1){
                    let temp_element = { ...temp_state[indexDipendente] };
                    temp_element.giorni[indexGiorno].richiesta = richiestaUpdate.richiesta;
                    temp_state[indexDipendente] = temp_element;
                    setContrattiCalendario(temp_state)
                }
            }
        }
    }, [richiestaUpdate]);


    const dissociaDipendenteRichiesta = (array, idRichiesta) => {
        const indexDipendente = array.findIndex(dipendente => dipendente.giorni.some(giorno => giorno.richiesta._id === idRichiesta));
        if(indexDipendente > -1){
            const indexGiorno = array[indexDipendente].giorni.findIndex(giorno => giorno.richiesta._id == idRichiesta)
            if(indexGiorno > -1){
                let temp_element = { ...array[indexDipendente] };
                temp_element.giorni[indexGiorno].richiesta = {stato: "vuota"}
                array[indexDipendente] = temp_element;
            }
            const giorniAssociati = array[indexDipendente].giorni.find(giorno => giorno.richiesta.stato != "vuota")
            console.log(giorniAssociati)
            if(!giorniAssociati && array[indexDipendente].sdoppiato){
                array.splice(indexDipendente, 1)
            }
            setContrattiCalendario(array)

        }
        return array
    }
    const datesAreOnSameDay = (first, second) =>
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate();

    const onClickCloseCalendario = () => {
        cleanCalendario()
        setContrattiCalendario([])
        setOpenCalendario(false);
    }
    

    return (
        <div className="div_wrap_100">
            {// <div className="div_wrap_50">
            }
            <div className="div_wrap_inner_100">
                <div className="div_container">
                    <div className="title_window">
                        <div className="title_window_p">Calendario</div>
                    </div>
                    <div className="dati_container">
                        {calendarioLoading ? <Spinner /> :
                            <div className="fun_assegnazione_incarichi">
                            <div className="row">
                                <div className="item_8_calendario">
                                    <div className="intestazione_table">
                                        <div className="w-layout-grid div_column_assegnazioni_calendario intestazione">
                                            <div className="div_item_column">
                                                <div className="item_table">Dipendente</div>
                                            </div>
                                            {contrattiCalendario.length > 0 ?
                                            contrattiCalendario[0].giorni.map((giorno, i) => (
                                                <>
                                                <div className="div_item_column">
                                                    <div className="item_table">O.I</div>
                                                </div>
                                                <div className="div_item_column">
                                                    <div className="item_table">O.F</div>
                                                </div>
                                                <div className="div_item_column">
                                                    <div className="item_table"><Moment locale="it" format='DD dddd '>{giorno.giorno}</Moment></div>
                                                </div>
                                                <div className="div_item_column">
                                                    <div className="item_table">D</div>
                                                </div>
                                                <div className="div_item_column">
                                                    <div className="item_table_empty"></div>
                                                </div>
                                                </>
                                            ))
                                        :
                                        ''
                                        }
                                        </div>
                                    </div>
                                    <div className="body_table more_altezza overflow_x_hidden">
                                        {contrattiCalendario.length > 0 ?
                                            contrattiCalendario.map((dipendente) => (
                                                <div className="w-layout-grid div_column_assegnazioni_calendario">
                                                    {dipendente.sdoppiato ?
                                                    <div className="div_item_column">
                                                        <div className="item_table"></div>
                                                    </div>
                                                    :
                                                    <div className="div_item_column">
                                                        <div className="item_table">{dipendente.dipendente.cognome} {dipendente.dipendente.nome}</div>
                                                    </div>
                                                    }
                                                    {dipendente.giorni.map((giorno)=>(
                                                         <>
                                                          {giorno.richiesta.stato == 'vuota' ?
                                                          <>
                                                         <div className="div_item_column">
                                                             <div className="item_table"></div>
                                                         </div>
                                                         <div className="div_item_column">
                                                             <div className="item_table"></div>
                                                         </div>
                                                            <div className="div_item_column">
                                                                <div className="item_table"></div>
                                                            </div>
                                                         <div className="div_item_column">
                                                             <div className="item_table">{giorno.disponibilita}</div>
                                                         </div>
                                                         <div className="div_item_column">
                                                             <div className="item_table_empty"></div>
                                                         </div>
                                                         </>
                                                         :
                                                         <>
                                                         <div className={`div_item_column ${giorno.richiesta.stato === "archiviata" ? "green_label" : "orange_label"}`}>
                                                             <div className="item_table">{giorno.richiesta.oraInizio}</div>
                                                         </div>
                                                         <div className={`div_item_column ${giorno.richiesta.stato === "archiviata" ? "green_label" : "orange_label"}`}>
                                                             <div className="item_table">{giorno.richiesta.oraFine}</div>
                                                         </div>
                                                         <div className={`div_item_column ${giorno.richiesta.stato === "archiviata" ? "green_label" : "orange_label"}`}>
                                                                <div className="item_table">{giorno.richiesta.nome}</div>
                                                            </div>
                                                            <div className={`div_item_column ${giorno.richiesta.stato === "archiviata" ? "green_label" : "orange_label"}`}>
                                                             <div className="item_table">{giorno.disponibilita}</div>
                                                         </div>
                                                         <div className="div_item_column">
                                                             <div className="item_table_empty"></div>
                                                         </div>
                                                         </>
                                                        }
                                                         </>

                                                    ))}
                                                </div>
                                            ))
                                        : ''
                                        }  
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="item_4 m_top_5">
                                    <div className="item_5_function">
                                        <div id="w-node-_7b1ba97e-ee0c-e416-819b-cbde7d2c589a-b8dac9c0" className="div_item_empty"></div>
                                        <div id="w-node-_7b1ba97e-ee0c-e416-819b-cbde7d2c589b-b8dac9c0" className="div_item_empty"></div>
                                        <div id="w-node-_7b1ba97e-ee0c-e416-819b-cbde7d2c589c-b8dac9c0" className="div_item_empty"></div>
                                        <div id="w-node-_7b1ba97e-ee0c-e416-819b-cbde7d2c589d-b8dac9c0" className="div_item_empty"></div>
                                        <div id="w-node-_7b1ba97e-ee0c-e416-819b-cbde7d2c58a0-b8dac9c0" className="div_item_button">
                                            <a id="w-node-_7b1ba97e-ee0c-e416-819b-cbde7d2c58a1-b8dac9c0" onClick={() => onClickCloseCalendario()} className="button w-button">Chiudi</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}


Calendario.protoTypes = {
    cleanCalendario: PropTypes.func.isRequired,
    richiesta: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    richiesta: state.richiesta
});

export default connect(mapStateToProps, { cleanCalendario })(Calendario);



