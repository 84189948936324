import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Spinner from '../../layout/Spinner';
import ModificaCliente from './ModificaCliente';
import NuovoCliente from './NuovoCliente';
import {Helmet} from "react-helmet";
import { getClienti } from '../../../actions/cliente';
const Clienti = ({ cliente: { clienti, loading }, getClienti }) => {

    const [openModificaCliente, setOpenModificaCliente] = useState(false);
    const [openNuovoCliente, setOpenNuovoCliente] = useState(false);
    const [clienteSelected, setClienteSelected] = useState({ _id: null });
    const [clientiList, setClientiList] = useState([]);
    const [testoFiltraClienti, setTestoFiltraClienti] = useState("");

    useEffect(() => {
        getClienti();
    }, [getClienti]);


    useEffect(() => {
        if (testoFiltraClienti.length > 0) {
            const arrayClinti = clienti.filter(function (cliente) {
                return cliente.ragioneSoc.toLowerCase().startsWith(testoFiltraClienti.toLowerCase());
            })
            setClientiList(arrayClinti)
        } else {
            setClientiList(clienti)
        }
    }, [clienti]);


    const onClickOpenNuovoCliente = () => {
        setClienteSelected({ _id: null })
        setOpenModificaCliente(false)
        setOpenNuovoCliente(true)

    }

    const onClickOpenModificaCliente = (idCliente) => {
        if (idCliente) {
            setOpenModificaCliente(true)
            setOpenNuovoCliente(false)
        }
    }

    const onChangeFiltraClienti = e => {
        setTestoFiltraClienti(e.target.value)
        const arrayClienti = clienti.filter(function (cliente) {
            return cliente.ragioneSoc.toLowerCase().startsWith(e.target.value);
        })
        if (arrayClienti.length > 0) {
            setClientiList(arrayClienti)
        }
    };

    return (
        loading ? <Spinner /> :
            <div className="body wf-section">
                <Helmet>
                <meta charSet="utf-8" />
                <title>{`Clienti - Gestionale Vigili dell'ordine`}</title>
            </Helmet>
                <div className="div_wrap">
                    <div className="div_wrap_100">
                        <div className="div_wrap_50">
                            <div className="div_container">
                                <div className="title_window">
                                    <div className="title_window_p">Clienti</div>
                                </div>
                                <div className="dati_container">
                                    <div className="fun_assegnazione_incarichi">
                                        <div className="row">
                                            <div className="item_1 filtro_wrap">
                                                <div id="w-node-_714e71a9-0550-fe0e-26e9-8b45ac42ab89-867f153d" className="div_item_1">
                                                    <div className="w-form">
                                                        <div id="email-form" name="email-form" data-name="Email Form" method="get">
                                                            <a id="w-node-_714e71a9-0550-fe0e-26e9-8b45ac42ab90-867f153d" onClick={() => { onClickOpenNuovoCliente() }} className="button w-button button_filtro_senza_label">Nuovo Cliente</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="w-node-_7b40e076-1929-a489-3ae4-e7e497d010f9-867f153d" className="div_item_1">
                                                <div id="w-node-_7b40e076-1929-a489-3ae4-e7e497d010fa-867f153d" className="label_text">Filtra clienti</div>
                                                <div data-hover="false" data-delay="0" className="w-dropdown">
                                                    <input type="text" className="w-input select" maxLength="256" name="filtraClienti" data-name="filtraClienti" value={testoFiltraClienti} placeholder="Inserisci cliente..." id="filtraClienti" onChange={e => onChangeFiltraClienti(e)} />
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="item_2">
                                                <div className="intestazione_table">
                                                    <div className="w-layout-grid div_column_clienti intestazione">
                                                        <div className="div_item_column">
                                                            <div className="item_table"></div>
                                                        </div>
                                                        <div className="div_item_column">
                                                            <div className="item_table align_left">Codice</div>
                                                        </div>
                                                        <div className="div_item_column">
                                                            <div className="item_table align_left">Ragione sociale</div>
                                                        </div>
                                                        <div className="div_item_column">
                                                            <div className="item_table align_left">P.Iva</div>
                                                        </div>
                                                        <div className="div_item_column">
                                                            <div className="item_table align_left">Indirizzo</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="body_table">
                                                    {clientiList.map((cliente, i) => (
                                                        <div key={i} className={`w-layout-grid div_column_clienti cursor ${clienteSelected._id == cliente._id ? 'cliente_selected' : ''}`} onClick={() => setClienteSelected({ _id: cliente._id })}>
                                                            <div className="div_item_column">
                                                                <div className="item_table">{i + 1}</div>
                                                            </div>
                                                            <div className="div_item_column">
                                                                <div className="item_table align_left">{cliente.codice}</div>
                                                            </div>
                                                            <div className="div_item_column">
                                                                <div className="item_table align_left">{cliente.ragioneSoc}</div>
                                                            </div>
                                                            <div className="div_item_column">
                                                                <div className="item_table align_left">{cliente.piva}</div>
                                                            </div>
                                                            <div className="div_item_column">
                                                                <div className="item_table align_left">{cliente.indirizzo} {cliente.cap} {cliente.localita} {cliente.provincia}</div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="item_3 m_top_5">
                                                <div className="list_button">
                                                    <div id="w-node-_47761b2d-160d-03bc-cd09-8911d6401437-867f153d" className="div_item_button">
                                                        <a id="w-node-_12c52e6d-210d-5526-7356-6c64cce43733-867f153d" onClick={() => (clienteSelected._id ? onClickOpenModificaCliente(clienteSelected._id) : null)} className={`button w-button ${clienteSelected._id ? "" : "button_disabled"}`}>Modifica cliente</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {openModificaCliente ?
                            <div className="div_wrap_50">
                                <div className="div_container">
                                    <div className="title_window">
                                        <div className="title_window_p">Cliente</div>
                                    </div>
                                    <ModificaCliente
                                        idCliente={clienteSelected._id}
                                        setOpenModificaCliente={setOpenModificaCliente}
                                    />
                                </div>
                            </div>
                            :
                            ''
                        }
                        {openNuovoCliente ?
                            <div className="div_wrap_50">
                                <div className="div_container">
                                    <div className="title_window">
                                        <div className="title_window_p">Cliente</div>
                                    </div>
                                    <NuovoCliente
                                        setOpenNuovoCliente={setOpenNuovoCliente}
                                    />
                                </div>
                            </div>
                            :
                            ''
                        }
                    </div>
                </div>
            </div>
    )
}

Clienti.protoTypes = {
    getClienti: PropTypes.func.isRequired,
    cliente: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    cliente: state.cliente
});

export default connect(mapStateToProps, { getClienti })(Clienti);