import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Helmet } from "react-helmet";
import DatePicker from 'react-date-picker';
import { useReactToPrint } from 'react-to-print';
import Moment from 'react-moment'
import { v4 as uuid } from 'uuid';
import Spinner from '../../layout/Spinner';
import addDays from "date-fns/addDays";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import startOfWeek from "date-fns/startOfWeek";
import subDays from "date-fns/subDays";
import endOfWeek from "date-fns/endOfWeek";
import disponibilitaJSON from "../../../utils/disponibilita.json"
import { associaDipendenteRichiestaCalendario, dissociaDipendenteRichiestaCalendario, archiviaRichieste, modificaOrarioRichiestaCalendario, cleanRichieste, cleanRichiesteArchiviate, nascondiContrattoCalendario, ripristinaContrattoNascostoCalendario, annullaArchiviaRichieste, getDipendentiPrestabiliOccupati, duplicaServizioCalendario } from '../../../actions/richiesta';
import { getDipendentiCalendarioContratto, cleanDipendenti, getStateDipendentiContrattoJob, aggiungiGiornoEccezione, getGiorniEccezioneCalendario, cleanGiorniEccezione, getIncarichiDipendente, cleanIncarichiDipendente, confermaGiorniEccezione, eliminaGiornoEccezioneLungoRichieste, togliFerieAggiungiGiornoEccezione } from '../../../actions/dipendente';
import { getContrattiRichiestePuntiVenditaCalendario, aggiungiServizioSporadico, getContrattiPuntoVendita, getStateContrattiRichiestePuntiVenditaCalendario, cleanCalendarioRichieste } from '../../../actions/puntoVendita';
const RichiesteCalendario = ({ richiesta: {  richiesteArchiviate, richiestaUpdate, dipendentiPrestabiliOccupati }, puntoVendita: {  puntiVendita, elementiCalendario, jobCalendarioId }, dipendente: { dipendenti, jobId, giorniEccezione, incarichiDipendente }, getContrattiRichiestePuntiVenditaCalendario, getDipendentiCalendarioContratto, cleanDipendenti, associaDipendenteRichiestaCalendario, dissociaDipendenteRichiestaCalendario, getStateDipendentiContrattoJob, archiviaRichieste, aggiungiGiornoEccezione, alert, cleanCalendarioRichieste, modificaOrarioRichiestaCalendario, cleanRichieste, cleanRichiesteArchiviate, cleanGiorniEccezione, nascondiContrattoCalendario, ripristinaContrattoNascostoCalendario, annullaArchiviaRichieste, getGiorniEccezioneCalendario, aggiungiServizioSporadico, duplicaServizioCalendario, getIncarichiDipendente, cleanIncarichiDipendente, getContrattiPuntoVendita, confermaGiorniEccezione, getDipendentiPrestabiliOccupati, eliminaGiornoEccezioneLungoRichieste, togliFerieAggiungiGiornoEccezione, getStateContrattiRichiestePuntiVenditaCalendario, auth: { user } }) => {

    const [richiesteContratto, setRichiesteContratto] = useState([]);
    const [rowSelectedGiornoEccezione, setRowSelectedGiornoEccezione] = useState({ id: "", dipendente: "", dataInzio: new Date(), dataFine: new Date(), disponibilita: "", nProtocollo: "" });
    const [dipendentiFirstLoad, setDipendentiFirstLoad] = useState(false);
    const [dataDipendenti, setDataDipendenti] = useState(setHours(setMinutes(new Date(), 0), 12));


    
    const [checkBoxFiltraDisponibili, setCheckBoxFiltraDisponibili] = useState('tutti');
    const [waitingJob, setWaitingJob] = useState(false);
    const [waitingJobCalendario, setWaitingJobCalendario] = useState(false);
    const [testoFiltraDipendente, setTestoFiltraDipendente] = useState("");
    const [openPopUpAssociaDipendente, setOpenPopUpAssociaDipendente] = useState({ open: false, messaggi: [], tipologia: "", id: null, recuperoRiposo: false, dataRecuperoRiposo: new Date(), idRiga: null, elemento: null, indice: null });
    const [dipendenteSelected, setDipendenteSelected] = useState(null);
    const [dipendenteNonAssegnatoSelected, setDipendenteNonAssegnatoSelected] = useState(null);
    const [incarichiAlert, setIncarichiAlert] = useState({ incarichiTotali: 0, incarichiAssegnati: 0 });
    const [checkOpenNascosti, setCheckOpenNascosti] = useState(false);
    const [cambiaDisponibilitaOpen, setCambiaDisponibilitaOpen] = useState(false);
    const [cambiaDisponibilitaNonAssegnatoSelectedOpen, setCambiaDisponibilitaNonAssegnatoSelectedOpen] = useState(false);
    const [disponibilitaSelected, setDisponibilitaSelected] = useState(null);
    const [disponibilitaNonAssegnatoSelected, setDisponibilitaNonAssegnatoSelected] = useState(null);
    const [openPopUpAssociaDipendenteConRR, setOpenPopUpAssociaDipendenteConRR] = useState(false);
    const [dataInputRR, setDataInputRR] = useState((setHours(setMinutes(new Date(), 0), 12)));
    const [recuperiRiposiDipednenti, setRecuperiRiposiDipednenti] = useState([]);
    const [datiServizioSporadico, setDatiServizioSporadico] = useState({
        idPuntoVenditaSporadico: '',
        multiplo: 1,
        dataInizioServizioSporadico: new Date(),
        dataFineServizioSporadico: new Date(),
        lunedi: false,
        martedi: false,
        mercoledi: false,
        giovedi: false,
        venerdi: false,
        sabato: false,
        domenica: false,
        festivi: false,
        semifestivi: false,
        oraInizioServizioSporadico: "",
        oraFineServizioSporadico: ""
    });
    const [checkboxSelezionaAssenze, setCheckboxSelezionaAssenze] = useState(false);
    const [waitingTurniDipendente, setWaitingTurniDipendente] = useState(false);




    //NUOVO CALENDARIO
    const [intestazioneGiorniCalendario, setIntestazioneGiorniCalendario] = useState({intestazione : ["","","","","","","","","","","","","",""], contratti: []});
    const [quadranteSelected, setQuadranteSelected] = useState({idRiga: null, idContratto: "", indice: null, stato : null, contratto: null, dataSelezionata: setHours(setMinutes(new Date(), 0), 12)});

    const [loadingCalendario, setLoadingCalendario] = useState(false);
    const [loadingGiorniAssenze, setLoadingGiorniAssenze] = useState(false);
    const [loadingDipendenti, setLoadingDipendenti] = useState(false);
    const [loadingDipendentiLiberi, setLoadingDipendentiLiberi] = useState(false);
    const [loadingDipendentiPrestati, setLoadingDipendentiPrestati] = useState(false);

    
    
    const [dataStartInput, setDataStartInput] = useState((setHours(setMinutes(new Date(), 0), 12)));
    const [checkBoxAssenze, setCheckBoxAssenze] = useState([]);

    const [openModificaOrario, setOpenModificaOrario] = useState(false);
    const [servizioSporadicoOpen, setServizioSporadicoOpen] = useState(false);
  
    const [giorniSelected, setGiorniSelected] = useState(7);
    const [datiModificaOrario, setDatiModificaOrario] = useState({ oraInizioModificaOrario: "", oraFineModificaOrario: "" });

    const [giorniEccezioneData, setGiorniEccezioneData] = useState([]);
    const [dipendentiList, setDipendentiList] = useState([]);
    const [dipendentiLiberi, setDipendentiLiberi] = useState([]);
    const [dipendentiPrestati, setDipendentiPrestati] = useState([]);

    
    



    const { oraInizioModificaOrario, oraFineModificaOrario } = datiModificaOrario;

    const { idPuntoVenditaSporadico, dataInizioServizioSporadico, dataFineServizioSporadico, oraInizioServizioSporadico, oraFineServizioSporadico, lunedi, martedi, mercoledi, giovedi, venerdi, sabato, domenica, festivi, semifestivi, multiplo } = datiServizioSporadico;


    //1
    //Pulizia generale
    useEffect(() => {
        cleanIncarichiDipendente();
        return () => {
            setRichiesteContratto([])
            cleanDipendenti()
            cleanRichieste()
            cleanRichiesteArchiviate()
            cleanGiorniEccezione()
            cleanCalendarioRichieste()
        };
    }, []);

     //Genera intestazione giorni calendario
     useEffect(() => {
        let arrayIntestazione = []
        for(var i=0; i< giorniSelected; i++){
            arrayIntestazione.push(addDays(dataStartInput, i));
        }
        setIntestazioneGiorniCalendario({...intestazioneGiorniCalendario, intestazione: arrayIntestazione})
    }, [giorniSelected]);

    //*
    useEffect(() => {
        const size = alert.length;
        if (size > 0) {
            if (alert[size - 1].alertType === "success" && alert[size - 1].field == 'add_giorno_eccezione') {
                setCambiaDisponibilitaOpen(false);
                setCambiaDisponibilitaNonAssegnatoSelectedOpen(false)
                setCambiaDisponibilitaNonAssegnatoSelectedOpen(false)
                setDipendenteNonAssegnatoSelected(null)
            }
            if (alert[size - 1].alertType === "success" && alert[size - 1].field == 'richieste_archiviate') {
                cleanDipendenti()
            }
            if (alert[size - 1].alertType === "success" && alert[size - 1].field == 'ripristina_richieste_archiviate') {
                cleanDipendenti()
            }
            if (alert[size - 1].alertType === "success" && alert[size - 1].field == 'nuovo_servizio_sporadico') {
                setServizioSporadicoOpen(false)
            }
            if (alert[size - 1].alertType === "success" && alert[size - 1].field == 'delete_giorno_eccezione') {

            }
            if (alert[size - 1].alertType === "error" && alert[size - 1].field == 'riposo') {
                setOpenPopUpAssociaDipendente({ open: true, messaggi: [{messaggio: "Attenzione dipendente in giorno di riposo!"}], tipologia: "alert_dipendente", recuperoRiposo: true, dataRecuperoRiposo: new Date(), prestato: false, idRiga: quadranteSelected.idRiga, elemento: quadranteSelected.elemento, indice: quadranteSelected.indice })
            
            }
        }
    }, [alert]);








useEffect(() => {


    // Raccogli tutte le richieste assegnate in un unico passaggio
    const arrayRichieste = quadranteSelected.indice != null 
        ? intestazioneGiorniCalendario.contratti.flatMap(contratto => 
            contratto.turni
                .filter(turno => turno.assegnato)
                .map(turno => turno.richiesta)
        ) 
        : [];

    // Elimina duplicati e aggiungi dipendenti prestabili occupati
    let arrayDipendentiOccupati = [
        ...new Set([
            ...arrayRichieste.filter(el => el?.dipendente).map(el => el.dipendente._id),
            ...dipendentiPrestabiliOccupati.map(d => d._id)
        ])
    ];
    // Seleziona i dipendenti liberi e prestati in un'unica iterazione
    const { dipendentiLiberi, dipendentiPrestati } = dipendenti.reduce((acc, dipendente) => {
        const occupato = arrayDipendentiOccupati.includes(dipendente._id);
        const prestato = dipendente.calcoli?.prestato;

        if (!occupato) {
            const disponibile = dipendente.calcoli?.disponibilita === "DI";
            const qualificaValida = ["ots", "gpg"].includes(dipendente.qualifica);
            if (disponibile && qualificaValida && !prestato) {
                acc.dipendentiLiberi.push({
                    _id: dipendente._id,
                    nome: dipendente.nome,
                    cognome: dipendente.cognome,
                    qualifica: dipendente.qualifica,
                    disponibilita: dipendente.calcoli.disponibilita,
                    prestato: dipendente.calcoli.prestato
                });
            }
        }

        if (prestato) {
            acc.dipendentiPrestati.push(dipendente);
        }

        return acc;
    }, { dipendentiLiberi: [], dipendentiPrestati: [] });


    // Ordina i dipendenti liberi
    const dipendentiLiberiOrdinati = dipendentiLiberi.sort(ordinaDipendenti);

    // Imposta lo stato
    //setDipendentiLiberi(dipendentiLiberiOrdinati);
    setDipendentiPrestati(dipendentiPrestati);
    setLoadingDipendentiLiberi(false);
    setLoadingDipendentiPrestati(false);
}, [dipendenti, dipendentiPrestabiliOccupati]);



//Filtro dipendenti in base al testo ricerca e checkbox selezionati *
useEffect(() => {
    console.log(2)
    console.log(dipendenti)
    if(dipendenti && dipendenti.length > 0){
        let indexContratto = intestazioneGiorniCalendario.contratti.findIndex(el => el.id == quadranteSelected.idRiga);
        if (indexContratto >= 0) {
            let contrattoSelezionato = intestazioneGiorniCalendario.contratti[indexContratto].turni[quadranteSelected.indice]
            getGiorniEccezioneCalendario({ dataInizio: new Date(contrattoSelezionato.data) })
            getDipendentiPrestabiliOccupati({ dipendenti: dipendenti, dataInizio: contrattoSelezionato.data })
        }
        let arrayDipendenti = dipendenti;
        if (checkBoxFiltraDisponibili === 'disponibili') {
            arrayDipendenti = arrayDipendenti.filter(dipendente => dipendente.calcoli.disponibilita == "DI");
        }
        if (testoFiltraDipendente.length > 0) {
            arrayDipendenti = arrayDipendenti.filter(function (dipendente) {
                return dipendente.nome.toLowerCase().startsWith(testoFiltraDipendente.toLowerCase())
                    || dipendente.cognome.toLowerCase().startsWith(testoFiltraDipendente.toLowerCase());
            })
        }
        arrayDipendenti = arrayDipendenti.filter(dipendente => dipendente.qualifica != "impiegato");
        arrayDipendenti = arrayDipendenti.filter(dipendente => dipendente.calcoli && dipendente.calcoli.prestato == false);
        arrayDipendenti = arrayDipendenti.sort(ordinaDipendenti)
        setDipendentiList(arrayDipendenti)
        setWaitingJob(false)
        setLoadingDipendenti(false)
        setDipendentiFirstLoad(true)
    }
}, [dipendenti]);



//Popola il calendario con i servizi
useEffect(() => {
    let arrayCalendarioContratti = []
    var arrayDate = []

    if(elementiCalendario.length > 0){
        for(var i=0; i< elementiCalendario.length; i++){
            arrayDate.push({data: elementiCalendario[i].data, contratto: null, turno: null, esiste: false, assegnato: false, oraInizio: "", oraFine: "", stato: null, duplicato: false, richiesta: null})
        }
        for(var i=0; i< elementiCalendario.length; i++){
            elementiCalendario[i].contratti.map(el => {
                let dipendenteSuggerito = null
                if(el.dipendentiSuggeriti.length > 0){
                    let index = el.dipendentiSuggeriti.findIndex((dip) => {return dip.calcoli.disponibilita == "DI"})
                    if(index >= 0){
                        dipendenteSuggerito = el.dipendentiSuggeriti[index]
                    } 
                }
                let index = arrayCalendarioContratti.findIndex((contratto) => {return contratto._id == el._id})
                if(index < 0){
                    let obj = {id: uuid(), _id: el._id, denominazione: el.puntoVendita.denominazione, localita: el.puntoVendita.localita, oraInizio: el.oraInizio, oraFine: el.oraFine, turni: [], duplicato: false}
                    obj.turni = arrayDate.map(date => ({ ...date }));
                    arrayCalendarioContratti.push(obj)
                    arrayCalendarioContratti[arrayCalendarioContratti.length - 1].turni[i].esiste = true
                    arrayCalendarioContratti[arrayCalendarioContratti.length - 1].turni[i].contratto = el
                    arrayCalendarioContratti[arrayCalendarioContratti.length - 1].turni[i].oraInizio = el.oraInizio
                    arrayCalendarioContratti[arrayCalendarioContratti.length - 1].turni[i].oraFine = el.oraFine
                    arrayCalendarioContratti[arrayCalendarioContratti.length - 1].turni[i].stato = "creata"
                    arrayCalendarioContratti[arrayCalendarioContratti.length - 1].turni[i].dipendenteSuggerito = dipendenteSuggerito
                    arrayCalendarioContratti[arrayCalendarioContratti.length - 1].turni[i].qualifica = el.puntoVendita.qualifica
                    arrayCalendarioContratti[arrayCalendarioContratti.length - 1].turni[i].id = uuid()
                }else{
                    arrayCalendarioContratti[index].turni[i].esiste = true
                    arrayCalendarioContratti[index].turni[i].contratto = el
                    arrayCalendarioContratti[index].turni[i].oraInizio = el.oraInizio
                    arrayCalendarioContratti[index].turni[i].oraFine = el.oraFine
                    arrayCalendarioContratti[index].turni[i].stato = "creata"
                    arrayCalendarioContratti[index].turni[i].duplicato = false
                    arrayCalendarioContratti[index].turni[i].dipendenteSuggerito = dipendenteSuggerito
                    arrayCalendarioContratti[index].turni[i].qualifica = el.puntoVendita.qualifica
                    arrayCalendarioContratti[index].turni[i].id = uuid()
                }
            })
            elementiCalendario[i].richieste.map(el => {
                let index = arrayCalendarioContratti.findIndex((contratto) => {return contratto._id == el.contratto._id})
                if(index >= 0){
                    arrayCalendarioContratti[index].turni[i].richiesta = el
                    arrayCalendarioContratti[index].turni[i].richiestaId = el._id
                    arrayCalendarioContratti[index].turni[i].oraInizio = el.oraInizio
                    arrayCalendarioContratti[index].turni[i].oraFine = el.oraFine
                    arrayCalendarioContratti[index].turni[i].assegnato = true
                    arrayCalendarioContratti[index].turni[i].id = uuid()
                    arrayCalendarioContratti[index].turni[i].stato = el.stato
                    arrayCalendarioContratti[index].turni[i].duplicato = el.false
                }
            })
        }

        arrayCalendarioContratti.sort((a, b) => (a.denominazione > b.denominazione) ? 1 : -1)
        arrayCalendarioContratti.sort((a, b) => {
        if (a.denominazione == b.denominazione) {
            return (fromStringToIntHour(a.oraInizio) > fromStringToIntHour(b.oraInizio)) ? 1 : -1
        } else {
            return 0
        }
    })            
        setIntestazioneGiorniCalendario({...intestazioneGiorniCalendario, contratti: arrayCalendarioContratti})
        setLoadingCalendario(false)
        setWaitingJobCalendario(false)
    }
}, [elementiCalendario]);



    //cambia stato alla richiesta che viene archiviata
    useEffect(() => {   
        if(richiesteArchiviate && richiesteArchiviate.richieste){
            let tempContratti = [...intestazioneGiorniCalendario.contratti]
            richiesteArchiviate.richieste.map((richiesta) => {
                tempContratti.map(contratto => {
                    contratto.turni.map(turno => {
                        if(turno.richiesta){
                            if(turno.richiesta._id == richiesta){
                                if (richiesteArchiviate.tipologia == 'archivia_richieste') {
                                    turno.stato = "archiviata";
                                    turno.richiesta.stato = "archiviata";
                                } else if (richiesteArchiviate.tipologia == 'ripristina_richieste_archiviate') {
                                    turno.stato = "assegnata";
                                    turno.richiesta.stato = "assegnata";
                                }
                            }
                        }    
                    })
                })
            })
            let stato = ""
            if(quadranteSelected.stato == "assegnata"){
                stato = "archiviata"
            }
            if(quadranteSelected.stato == "archiviata"){
                stato = "assegnata"
            }
            setQuadranteSelected({...quadranteSelected, stato: stato})
            setIntestazioneGiorniCalendario({ ...intestazioneGiorniCalendario, contratti: tempContratti})
        }
    }, [richiesteArchiviate]);




     //Listener JOB calendario
     useEffect(() => {
        const interval = setInterval(() => {
            if (jobCalendarioId && jobCalendarioId.length > 0 && waitingJobCalendario) {
                getStateContrattiRichiestePuntiVenditaCalendario(jobCalendarioId)
            } else {
                clearInterval(interval)
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [jobCalendarioId]);




    //Scatta quando viene associato/disassociato un dipendente NON PRESTATO * oppure eliminata una richiesta anomala
    useEffect(() => {
        if (dipendenteSelected) {
            cleanIncarichiDipendente()
            setWaitingTurniDipendente(true)
            let dataStart = startOfWeek(setHours(setMinutes(quadranteSelected.dataSelezionata, 0), 0), { weekStartsOn: 1 })
            dataStart = subDays(dataStart, 1)
            let dataEnd = endOfWeek(setHours(setMinutes(quadranteSelected.dataSelezionata, 59), 23), { weekStartsOn: 1 })
            getIncarichiDipendente({ dataInizio: dataStart, dataFine: dataEnd, idDipendente: dipendenteSelected });
        }

        //trovo la richiesta/contratto con id
        if(richiestaUpdate && richiestaUpdate.id){
            const indexRiga = intestazioneGiorniCalendario.contratti.findIndex(el => el.id == richiestaUpdate.id);
            if(indexRiga >= 0){
                let tempContratti = [...intestazioneGiorniCalendario.contratti]
                let temp_state = [...tempContratti[indexRiga].turni];
                let temp_element = temp_state[richiestaUpdate.indice];
                let  newContratti = [];
                if (richiestaUpdate.tipologia == "associa") {
                    if (richiestaUpdate.richiesta && richiestaUpdate.richiesta.recuperoRiposo) {
                        let arrayRR = recuperiRiposiDipednenti
                        arrayRR.push(richiestaUpdate.richiesta.recuperoRiposo)
                        setRecuperiRiposiDipednenti(arrayRR)
                    }
                    temp_element.duplicato = false;
                    if (richiestaUpdate && !richiestaUpdate.oldDipendente) {
                        setIncarichiAlert({ ...incarichiAlert, incarichiAssegnati: incarichiAlert.incarichiAssegnati + 1 })
                    }
    
                    temp_element.dipendente = richiestaUpdate.richiesta.dipendente;
                    temp_element.stato = richiestaUpdate.richiesta.stato;
                    temp_element.richiestaId = richiestaUpdate.richiesta._id;
                    temp_element.codice = richiestaUpdate.richiesta.codice;
                    temp_element.assegnato = true;
                    temp_element.richiesta = richiestaUpdate.richiesta;
                    const indexNewDipendente = dipendentiList.findIndex(el => richiestaUpdate.newDipendente._id == el._id);
                    let arrayDipendenti = [...dipendentiList];
                    if (indexNewDipendente >= 0) {
                        arrayDipendenti[indexNewDipendente] = richiestaUpdate.newDipendente
                    }
                    if (richiestaUpdate.oldDipendente) {
                        const indexOldDipendente = dipendentiList.findIndex(el => richiestaUpdate.oldDipendente._id == el._id);
                        if (indexOldDipendente >= 0) {
                            arrayDipendenti[indexOldDipendente] = richiestaUpdate.oldDipendente
                        }
                    }
                    setDipendentiList(arrayDipendenti)
                }else if (richiestaUpdate.tipologia == "dissocia"){ 
                    if (richiestaUpdate.oldRichiesta && richiestaUpdate.oldRichiesta.recuperoRiposo){
                        let arrayRR = recuperiRiposiDipednenti
                        let index = arrayRR.findIndex(el => { return richiestaUpdate.oldRichiesta.recuperoRiposo._id == el._id })
                        if (index > -1) {
                            arrayRR.splice(index, 1);
                        }
                        setRecuperiRiposiDipednenti(arrayRR)
                    }
                    console.log(temp_element)
                    temp_element.duplicato = false;
                    temp_element.dipendente = null;
                    temp_element.assegnato = false;
                    temp_element.stato = "creata";
                    temp_element.richiesta = null;
                    const indexNewDipendente = dipendentiList.findIndex(el => richiestaUpdate.newDipendente._id == el._id);
                    let arrayDipendenti = [...dipendentiList];
                    if (indexNewDipendente >= 0) {
                        arrayDipendenti[indexNewDipendente] = richiestaUpdate.newDipendente
                        setDipendentiList(arrayDipendenti)
                    }
                } else if (richiestaUpdate.tipologia == "contratto_nascosto") {
                    temp_element.stato = richiestaUpdate.richiesta.stato;
                    temp_element.richiestaId = richiestaUpdate.richiesta._id;
                    temp_element.richiesta = richiestaUpdate.richiesta;
                    console.log(temp_element)
                }else if (richiestaUpdate.tipologia == "ripristina_contratto_nascosto") {
                    temp_element.stato = "creata"
                    temp_element.richiestaId = null;
                    temp_element.richiesta = null;

                }else if (richiestaUpdate.tipologia == "contratto_duplicato") {
                    let indexContratto = intestazioneGiorniCalendario.contratti.findIndex(el => el.id == quadranteSelected.idRiga);
                        if (indexContratto >= 0) {
                            const originalArray = intestazioneGiorniCalendario.contratti.map(step => ({...step}));
                            let temp_state_clone = originalArray[indexContratto]
                            let arrayTurni = []
                            temp_state_clone.turni.map((el, i) => {
                                let obj;
                                if(i == quadranteSelected.indice){
                                    obj = {id: uuid(), data: el.data, contratto: richiestaUpdate.contratto, turno: el.turno, esiste: true, assegnato: false, oraInizio: richiestaUpdate.contratto.oraInizio, oraFine: richiestaUpdate.contratto.oraFine, stato: "creata", duplicato: false, richiesta: null, dipendente: null}
                                }else{
                                    obj = {id: uuid(), data: el.data, contratto: richiestaUpdate.contratto, turno: el.turno, esiste: false, assegnato: false, oraInizio: "", oraFine: "", stato: null, duplicato: false, richiesta: null, dipendente: null}
                                }
                                arrayTurni.push(obj)
                            })
                            temp_state_clone.turni = arrayTurni
                            temp_state_clone.contratto = richiestaUpdate.contratto
                            temp_state_clone.id = uuid()
                            newContratti = [
                                ...tempContratti.slice(0, indexContratto),
                                temp_state_clone,
                                ...tempContratti.slice(indexContratto)
                            ];
                        }
                }else if (richiestaUpdate.tipologia == "modifica_orario") {
                    temp_element.oraInizio = richiestaUpdate.richiesta.oraInizio;
                    temp_element.oraFine = richiestaUpdate.richiesta.oraFine;
                    let indexDipendente = dipendentiList.findIndex(el => richiestaUpdate.dipendente._id == el._id);
                    let arrayDipendenti = [...dipendentiList];
                    if (indexDipendente >= 0) {
                        arrayDipendenti[indexDipendente] = richiestaUpdate.dipendente
                        setDipendentiList(arrayDipendenti)
                    }
                }
            if (richiestaUpdate.tipologia == "contratto_duplicato") {
                setIntestazioneGiorniCalendario({...intestazioneGiorniCalendario, contratti: newContratti})
            }else{
                setIntestazioneGiorniCalendario({ ...intestazioneGiorniCalendario, contratti: tempContratti})
            }
            if(quadranteSelected.idRiga && quadranteSelected.indice >= 0){
                setQuadranteSelected({...quadranteSelected, stato: temp_element.stato})
            }
            }
        }
    }, [richiestaUpdate]);









  //Aggiorna dipendenti liberi dopo associazioni o disassociazioni
  useEffect(() => {
    let arrayDipendentiLiberi = dipendentiLiberi
    if (richiestaUpdate.tipologia == 'associa') {
        if (!richiestaUpdate.oldDipendente) {
            arrayDipendentiLiberi = arrayDipendentiLiberi.filter(el => el._id != richiestaUpdate.newDipendente._id)
        } else {
            arrayDipendentiLiberi = arrayDipendentiLiberi.filter(el => el._id != richiestaUpdate.newDipendente._id)
            if (!richiestaUpdate.oldDipendente.calcoli.occupato) {
                arrayDipendentiLiberi.push({ _id: richiestaUpdate.oldDipendente._id, nome: richiestaUpdate.oldDipendente.nome, cognome: richiestaUpdate.oldDipendente.cognome, qualifica: richiestaUpdate.oldDipendente.qualifica, disponibilita: richiestaUpdate.oldDipendente.calcoli && richiestaUpdate.oldDipendente.calcoli.disponibilita, prestato: richiestaUpdate.oldDipendente.calcoli && richiestaUpdate.oldDipendente.calcoli.prestato })
            }
        }
    }
    if (richiestaUpdate.tipologia == 'dissocia') {
        if (!richiestaUpdate.newDipendente.calcoli.occupato) {
            arrayDipendentiLiberi.push({ _id: richiestaUpdate.newDipendente._id, nome: richiestaUpdate.newDipendente.nome, cognome: richiestaUpdate.newDipendente.cognome, qualifica: richiestaUpdate.newDipendente.qualifica, disponibilita: richiestaUpdate.newDipendente.calcoli && richiestaUpdate.newDipendente.calcoli.disponibilita, prestato: richiestaUpdate.newDipendente.calcoli && richiestaUpdate.newDipendente.calcoli.prestato })
        }
    }
    arrayDipendentiLiberi.sort(function (a, b) {
        if (a.cognome < b.cognome) { return -1; }
        if (a.cognome > b.cognome) { return 1; }
        return 0;
    })
    arrayDipendentiLiberi = arrayDipendentiLiberi.sort(ordinaDipendenti)
    //setDipendentiLiberi(arrayDipendentiLiberi)

}, [richiestaUpdate]);



//Carica i turni del dipendente selezionato
useEffect(() => {
    if (incarichiDipendente && incarichiDipendente.incarichi && incarichiDipendente.incarichi.length > 0) {
        console.log(incarichiDipendente)
        setWaitingTurniDipendente(false)
    }
}, [incarichiDipendente]);


useEffect(() => {
    // Mappa direttamente in arrayFinale usando cleanDipendentiGiorniEccezioneRR
    const arrayFinale = cleanDipendentiGiorniEccezioneRR(
        giorniEccezione.map(el => ({
            _id: el._id,
            dataInizio: el.dataInizio,
            dataFine: el.dataFine,
            dipendente: el.dipendente,
            confermata: el.confermata,
            nProtocollo: el.nProtocollo,
            disponibilita: el.disponibilita,
            autocreate: !!el.autocreate  // Usa !! per una conversione booleana
        }))
    );

    // Imposta i risultati e aggiorna lo stato
    setGiorniEccezioneData(arrayFinale);
    setLoadingGiorniAssenze(false);
}, [giorniEccezione]);






    

//FUNZIONI


const onClickGetContrattiRichiestePuntiVendita = () => {
    setQuadranteSelected({idRiga: null, idContratto: "", indice: null, stato : null, contratto: null, dataSelezionata: dataStartInput});
    cleanIncarichiDipendente();
    setRichiesteContratto([])
    cleanDipendenti()
    cleanRichieste()
    cleanRichiesteArchiviate()
    cleanGiorniEccezione()
    cleanCalendarioRichieste()
    setDipendenteSelected(null)
    setDipendentiList([])
    setDipendentiLiberi([])
    setLoadingDipendenti(false)
    setGiorniEccezioneData([])
    setLoadingDipendentiPrestati(false)
    setLoadingDipendentiLiberi(false)
    setLoadingGiorniAssenze(false)
    setCheckBoxAssenze([])
    setLoadingCalendario(true)
    setWaitingJobCalendario(true)
    getContrattiRichiestePuntiVenditaCalendario({ dataInizio: dataStartInput, giorni: giorniSelected })
    let arrayIntestazione = []
    for(var i=0; i<giorniSelected; i++){
        arrayIntestazione.push(addDays(dataStartInput, i));
    }
    setIntestazioneGiorniCalendario({...intestazioneGiorniCalendario, intestazione: arrayIntestazione})
}


const selezionaQuadrante = ({idRiga, elemento, indice}) => {
        if(elemento.esiste){
            setQuadranteSelected({idRiga: idRiga, idContratto: elemento.contratto._id, indice: indice, stato: elemento.stato, contratto: elemento.contratto, dataSelezionata: new Date(elemento.data)})
            if(indice != quadranteSelected.indice && !dipendentiFirstLoad){
                setWaitingJob(true)
                setLoadingDipendenti(true)
                setLoadingDipendentiPrestati(true)
                setLoadingDipendentiLiberi(true)
                setLoadingGiorniAssenze(true)    
                setDataDipendenti(elemento.data)                
                getDipendentiCalendarioContratto({ idContratto: elemento.contratto._id, dataRichiesta: elemento.data, resti: false, oraInizio: elemento.contratto.oraInizio, oraFine: elemento.contratto.oraFine });
            }
        }
}

const onClickAggiornaDipendenti = () => {
    if(quadranteSelected && quadranteSelected.contratto && quadranteSelected.contratto._id && quadranteSelected.dataSelezionata && quadranteSelected.contratto.oraInizio && quadranteSelected.contratto.oraFine){
        setWaitingJob(true)
        setLoadingDipendenti(true)
        setLoadingDipendentiPrestati(true)
        setLoadingDipendentiLiberi(true)
        setLoadingGiorniAssenze(true)    
        setDataDipendenti(quadranteSelected.dataSelezionata)                                
        getDipendentiCalendarioContratto({ idContratto: quadranteSelected.contratto._id, dataRichiesta: quadranteSelected.dataSelezionata, resti: false, oraInizio: quadranteSelected.contratto.oraInizio, oraFine: quadranteSelected.contratto.oraFine });
    }
}
   

    




    


    

    

    

 








    //FUNZIONI UTILITY

    const cleanDipendentiGiorniEccezioneRR = (dipendenti) => {
        let array = []
        dipendenti.map(el => {
            if (el.disponibilita == "FE") {
                let index = array.findIndex(a => { return (a.dipendente._id == el.dipendente._id) })
                if (index === -1) {
                    array.push(el)
                }
            } else {
                let index = array.findIndex(a => { return (a.dipendente._id == el.dipendente._id) && (a.disponibilita == 'FE') })
                if (index === -1) {
                    array.push(el)
                } else {
                    array[index] = el
                }
            }
        })
        return array
    }

    const ordinaDipendenti = (a, b) => {
        if (a.qualifica == 'ots' && b.qualifica != 'ots') {
            return -1
        } else if (a.qualifica != 'ots' && b.qualifica == 'ots') {
            return 1
        } else {
            return 0
        }
    }
  
    const checkOrarioAccavvallati = (oraInizioRichiesta, oraFineRichiesta, oraInizioOccupato, oraFineOccupato) => {
        let oraInizioRichiestaParse = Date.parse('01/01/2011 ' + oraInizioRichiesta + ':00');
        let oraFineRichiestaParse = Date.parse('01/01/2011 ' + oraFineRichiesta + ':00');
        let oraInizioOccupatoParse = Date.parse('01/01/2011 ' + oraInizioOccupato + ':00');
        let oraFineOccupatoParse = Date.parse('01/01/2011 ' + oraFineOccupato + ':00');
        if (isOrarioOltreMezzanotte(oraInizioRichiesta, oraFineRichiesta)) {
            oraFineRichiestaParse = Date.parse('01/02/2011 ' + oraFineRichiesta + ':00');
        }
        if (isOrarioOltreMezzanotte(oraInizioOccupato, oraFineOccupato)) {
            oraFineOccupatoParse = Date.parse('01/02/2011 ' + oraFineOccupato + ':00');
        }
        if (oraInizioRichiestaParse > oraInizioOccupatoParse && oraInizioRichiestaParse < oraFineOccupatoParse) {
            return true
        } else if (oraFineRichiestaParse > oraInizioOccupatoParse && oraFineRichiestaParse < oraFineOccupatoParse) {
            return true
        } else if (oraInizioRichiestaParse < oraInizioOccupatoParse && oraFineRichiestaParse > oraFineOccupatoParse) {
            return true
        } else {
            return false
        }
    }

    const checkIfOrarioTurnoPrecedenteAccavvallati = (oraInizioTurnoPrecedente, oraFineTurnoPrecedente, oraInizioOccupato, oraFineOccupato) => {
        let oraInizioTurnoPrecedenteParse = Date.parse('01/01/2011 ' + oraInizioTurnoPrecedente + ':00');
        let oraFineTurnoPrecedenteParse = Date.parse('01/01/2011 ' + oraFineTurnoPrecedente + ':00');
        let oraInizioOccupatoParse = Date.parse('01/02/2011 ' + oraInizioOccupato + ':00');
        let oraFineOccupatoParse = Date.parse('01/02/2011 ' + oraFineOccupato + ':00');
        if (isOrarioOltreMezzanotte(oraInizioTurnoPrecedente, oraFineTurnoPrecedente)) {
            oraFineTurnoPrecedenteParse = Date.parse('01/02/2011 ' + oraFineTurnoPrecedente + ':00');
        }
        if (isOrarioOltreMezzanotte(oraInizioOccupato, oraFineOccupato)) {
            oraFineOccupatoParse = Date.parse('01/03/2011 ' + oraFineOccupato + ':00');
        }
        if (oraInizioTurnoPrecedenteParse > oraInizioOccupatoParse && oraInizioTurnoPrecedenteParse < oraFineOccupatoParse) {
            return true
        } else if (oraFineTurnoPrecedenteParse > oraInizioOccupatoParse && oraFineTurnoPrecedenteParse < oraFineOccupatoParse) {
            return true
        } else if (oraInizioTurnoPrecedenteParse < oraInizioOccupatoParse && oraFineTurnoPrecedenteParse > oraFineOccupatoParse) {
            return true
        } else {
            return false
        }
    }

    const isOrarioOltreMezzanotte = (oraInizio, oraFine) => {
        const oraInizioOre = parseInt(oraInizio.substring(0, oraInizio.indexOf(":")));
        const oraFineOre = parseInt(oraFine.substring(0, oraFine.indexOf(":")));
        const oraInizioMin = parseInt(oraInizio.substring(oraInizio.indexOf(":") + 1, oraInizio.length))
        const oraFineMin = parseInt(oraFine.substring(oraFine.indexOf(":") + 1, oraFine.length))
        if (oraInizioOre > oraFineOre) {
            return true
        } else if (oraInizioOre == oraFineOre) {
            if (oraInizioMin > oraFineMin) {
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    }

    const checkIsFirstAssociazione = (idDipendente) => {
        let index = richiesteContratto.findIndex(el =>
            el.dipendente && el.dipendente._id == idDipendente
        );
        if (index >= 0) {
            return false
        } else {
            return true
        }
    }

    const fromStringToIntHour = (ora) => {
        const hour1 = parseInt(ora.substring(0, ora.indexOf(":")))
        const min1 = parseInt(ora.substring(ora.indexOf(":") + 1, ora.length))
        return hour1 + min1 / 60;
    }

    const differenzaInOreDaStringheOre = (ora1, ora2) => {
        const hour1 = parseInt(ora1.substring(0, ora1.indexOf(":")))
        const hour2 = parseInt(ora2.substring(0, ora2.indexOf(":")))
        const min1 = parseInt(ora1.substring(ora1.indexOf(":") + 1, ora1.length))
        const min2 = parseInt(ora2.substring(ora2.indexOf(":") + 1, ora2.length))
        let fromMinToHour;
        let diffHours;
        if (hour1 < hour2) {
            fromMinToHour = min2 / 60 - min1 / 60
            diffHours = hour2 - hour1 + fromMinToHour;
        } else if (hour1 == hour2) {
            if (min1 < min2) {
                fromMinToHour = min2 / 60 - min1 / 60
                diffHours = fromMinToHour;
            } else {
                fromMinToHour = min1 / 60 - min2 / 60
                diffHours = 24 - fromMinToHour;
            }
        } else {
            fromMinToHour = min1 / 60 - min2 / 60
            diffHours = 24 - (hour1 - hour2) - fromMinToHour;
        }
        return diffHours;
    }

    const checkRiposo11Ore = (oraInizioPrecedente, oraFinePrecedente, oraInizioNuovo) => {
        let oraFineTurnoPrecedenteParse = Date.parse('01/01/2011 ' + oraFinePrecedente + ':00');
        let oraInizioNuovoParse = Date.parse('01/02/2011 ' + oraInizioNuovo + ':00');
        if (isOrarioOltreMezzanotte(oraInizioPrecedente, oraFinePrecedente)) {
            oraFineTurnoPrecedenteParse = Date.parse('01/02/2011 ' + oraFinePrecedente + ':00');
        }
        const diff = (oraInizioNuovoParse - oraFineTurnoPrecedenteParse) / (1000 * 60 * 60)
        if (diff < 11) {
            return false
        } else {
            return true
        }
    }

    const findRRGiaEsistente = (dipendente, recuperiRiposiDipednenti) => {
        let arrayRR = [];
        arrayRR = recuperiRiposiDipednenti.filter(el => (el.dipendente == dipendente._id))
        if (arrayRR.length > 0) {
            return true
        } else {
            return false
        }
    }








    //FUNZIONI ONCLICK



    //*
    const onClickAssociaRichiestaPopUp = (idDipendente) => {
        let indexContratto = intestazioneGiorniCalendario.contratti.findIndex(el => el.id == quadranteSelected.idRiga);
        if (indexContratto >= 0) {
            let contrattoSelezionato = intestazioneGiorniCalendario.contratti[indexContratto].turni[quadranteSelected.indice]
            let indexDipendente = dipendentiList.findIndex(el => el._id == idDipendente);
            associaDipendenteRichiestaCalendario({ id: quadranteSelected.idRiga, indice: quadranteSelected.indice, idDipendente, idRichiesta: contrattoSelezionato.richiesta ? contrattoSelezionato.richiesta._id : null, idContratto: contrattoSelezionato.contratto._id, dataRichiesta: contrattoSelezionato.data, oraInizio: contrattoSelezionato.oraInizio, oraFine: contrattoSelezionato.oraFine, resti: false, disponibilita: dipendentiList[indexDipendente].calcoli.disponibilita, continuativo: contrattoSelezionato.contratto.continuativo, numeroContinuativo: contrattoSelezionato.contratto.numeroContinuativo, recuperoRiposo: false, dataRecuperoRiposo: dataInputRR, prestato: dipendentiList[indexDipendente].calcoli.prestato, forzato: false })
        }
        setOpenPopUpAssociaDipendente({ open: false, messaggio: [], tipologia: "", recuperoRiposo: false, dataRecuperoRiposo: new Date(), prestato: false, idRiga: null, elemento: null, indice: null })
    }

    const onClickAssociaRichiestaPrestatoPopUp = (idDipendente) => {
        let indexContratto = intestazioneGiorniCalendario.contratti.findIndex(el => el.id == quadranteSelected.idRiga);
        if (indexContratto >= 0) {
            let contrattoSelezionato = intestazioneGiorniCalendario.contratti[indexContratto].turni[quadranteSelected.indice]
            let indexDipendente = dipendentiPrestati.findIndex(el => el._id == idDipendente);
            associaDipendenteRichiestaCalendario({ id: quadranteSelected.idRiga, indice: quadranteSelected.indice, idDipendente, idRichiesta: contrattoSelezionato.richiesta ? contrattoSelezionato.richiesta._id : null, idContratto: contrattoSelezionato.contratto._id, dataRichiesta: contrattoSelezionato.data, oraInizio: contrattoSelezionato.oraInizio, oraFine: contrattoSelezionato.oraFine, resti: false, disponibilita: dipendentiPrestati[indexDipendente].calcoli.disponibilita, continuativo: contrattoSelezionato.contratto.continuativo, numeroContinuativo: contrattoSelezionato.contratto.numeroContinuativo, recuperoRiposo: false, dataRecuperoRiposo: dataInputRR, prestato: dipendentiPrestati[indexDipendente].calcoli.prestato, forzato: false })
        }
        setOpenPopUpAssociaDipendente({ open: false, messaggio: [], tipologia: "", recuperoRiposo: false, dataRecuperoRiposo: new Date(), prestato: false, idRiga: null, elemento: null, indice: null })
    }


    const setOpenPopUpAssociaDipendenteSenzaRR = (idDipendente) => {
        let indexContratto = intestazioneGiorniCalendario.contratti.findIndex(el => el.id == quadranteSelected.idRiga);
        if (indexContratto >= 0) {
            let contrattoSelezionato = intestazioneGiorniCalendario.contratti[indexContratto].turni[quadranteSelected.indice]
            let indexDipendente = dipendentiList.findIndex(el => el._id == idDipendente);
            if (indexDipendente < 0) {
                indexDipendente = dipendentiPrestati.findIndex(el => el._id == idDipendente);
                associaDipendenteRichiestaCalendario({ id: quadranteSelected.idRiga, indice: quadranteSelected.indice, idDipendente, idRichiesta: contrattoSelezionato.richiesta ? contrattoSelezionato.richiesta._id : null, idContratto: contrattoSelezionato.contratto._id, dataRichiesta: contrattoSelezionato.data, oraInizio: contrattoSelezionato.oraInizio, oraFine: contrattoSelezionato.oraFine, resti: false, disponibilita: dipendentiPrestati[indexDipendente].calcoli.disponibilita, continuativo: contrattoSelezionato.contratto.continuativo, numeroContinuativo: contrattoSelezionato.contratto.numeroContinuativo, recuperoRiposo: false, dataRecuperoRiposo: dataInputRR, prestato: dipendentiPrestati[indexDipendente].calcoli.prestato, forzato: true })
            } else {
                associaDipendenteRichiestaCalendario({ id: quadranteSelected.idRiga, indice: quadranteSelected.indice, idDipendente, idRichiesta: contrattoSelezionato.richiesta ? contrattoSelezionato.richiesta._id : null, idContratto: contrattoSelezionato.contratto._id, dataRichiesta: contrattoSelezionato.data, oraInizio: contrattoSelezionato.oraInizio, oraFine: contrattoSelezionato.oraFine, resti: false, disponibilita: dipendentiList[indexDipendente].calcoli.disponibilita, continuativo: contrattoSelezionato.contratto.continuativo, numeroContinuativo: contrattoSelezionato.contratto.numeroContinuativo, recuperoRiposo: false, dataRecuperoRiposo: dataInputRR, prestato: dipendentiList[indexDipendente].calcoli.prestato, forzato: true })
            }
        }
        setOpenPopUpAssociaDipendente({ open: false, messaggio: [], tipologia: "", recuperoRiposo: false, dataRecuperoRiposo: new Date(), prestato: false, idRiga: null, elemento: null, indice: null })
    }

    //*
    const onClickAssociaRichiestaConRRPopUp = (idDipendente) => {
        let indexContratto = intestazioneGiorniCalendario.contratti.findIndex(el => el.id == quadranteSelected.idRiga);
        if (indexContratto >= 0) {
            let contrattoSelezionato = intestazioneGiorniCalendario.contratti[indexContratto].turni[quadranteSelected.indice]
            let indexDipendente = dipendentiList.findIndex(el => el._id == idDipendente);
            let arrayDipendenti = dipendentiList
            if (indexDipendente == -1) {
                arrayDipendenti = dipendentiPrestati
                indexDipendente = dipendentiPrestati.findIndex(el => el._id == idDipendente);
            }
            if (indexDipendente >= 0) {
                associaDipendenteRichiestaCalendario({ id: quadranteSelected.idRiga, indice: quadranteSelected.indice, idDipendente, idRichiesta: contrattoSelezionato.richiesta ? contrattoSelezionato.richiesta._id : null, idContratto: contrattoSelezionato.contratto._id, dataRichiesta: contrattoSelezionato.data, oraInizio: contrattoSelezionato.oraInizio, oraFine: contrattoSelezionato.oraFine, resti: false, disponibilita: arrayDipendenti[indexDipendente].calcoli.disponibilita, continuativo: contrattoSelezionato.contratto.continuativo, numeroContinuativo: contrattoSelezionato.contratto.numeroContinuativo, recuperoRiposo: true, dataRecuperoRiposo: dataInputRR, prestato: arrayDipendenti[indexDipendente].calcoli.prestato,  forzato: false })
            }
        }
        setOpenPopUpAssociaDipendente({ open: false, messaggio: [], tipologia: "", recuperoRiposo: false, dataRecuperoRiposo: new Date(), prestato: false, idRiga: null, elemento: null, indice: null })
        setOpenPopUpAssociaDipendenteConRR(false)
    }

    //*
    const onClickAssociaRichiestaDipendenteSuggeritoPopUp = (idDipendente) => {
        let indexContratto = intestazioneGiorniCalendario.contratti.findIndex(el => el.id == quadranteSelected.idRiga);
        console.log(openPopUpAssociaDipendente)
        if (indexContratto >= 0) {
            let contrattoSelezionato = intestazioneGiorniCalendario.contratti[indexContratto].turni[quadranteSelected.indice]
            associaDipendenteRichiestaCalendario({ id: openPopUpAssociaDipendente.idRiga, indice: openPopUpAssociaDipendente.indice, idDipendente, idRichiesta: contrattoSelezionato.richiesta ? contrattoSelezionato.richiesta._id : null, idContratto: contrattoSelezionato.contratto._id, dataRichiesta: contrattoSelezionato.data, oraInizio: contrattoSelezionato.oraInizio, oraFine: contrattoSelezionato.oraFine, resti: false, disponibilita: contrattoSelezionato.dipendenteSuggerito.calcoli.disponibilita, continuativo: contrattoSelezionato.contratto.continuativo, numeroContinuativo: contrattoSelezionato.contratto.numeroContinuativo, recuperoRiposo: false, dataRecuperoRiposo: dataInputRR, prestato: false, forzato: false })
        }
        setOpenPopUpAssociaDipendente({ open: false, messaggio: [], tipologia: "", recuperoRiposo: false, dataRecuperoRiposo: new Date(), prestato: false, idRiga: null, elemento: null, indice: null })
    }

    const onClickAssociaDipendenteSuggerito = ({ idDipendente, id, idRiga, elemento, indice }) => {
        setQuadranteSelected({idRiga: null, idContratto: "", indice: null, stato : null, contratto: null, dataSelezionata: null});
        let indexContratto = intestazioneGiorniCalendario.contratti.findIndex(el => el.id == idRiga);
            if (indexContratto >= 0) {
                let contrattoSelezionato = intestazioneGiorniCalendario.contratti[indexContratto].turni[indice]
                    associaDipendenteRichiestaCalendario({ id: idRiga, indice: indice, idDipendente, idRichiesta: contrattoSelezionato.richiesta ? contrattoSelezionato.richiesta._id : null, idContratto: contrattoSelezionato.contratto._id, dataRichiesta: contrattoSelezionato.data, oraInizio: contrattoSelezionato.oraInizio, oraFine: contrattoSelezionato.oraFine, resti: false, disponibilita: contrattoSelezionato.dipendenteSuggerito.calcoli.disponibilita, continuativo: contrattoSelezionato.contratto.continuativo, numeroContinuativo: contrattoSelezionato.contratto.numeroContinuativo, recuperoRiposo: false, dataRecuperoRiposo: dataInputRR, prestato: false, forzato: false })
            }
    }


    const onClickOpenModificaOrario = async () => {
        setOpenModificaOrario(true)
    }


       //*
       const onClickDissociaDipendente = async () => {
        let indexContratto = intestazioneGiorniCalendario.contratti.findIndex(el => el.id == quadranteSelected.idRiga);
        if (indexContratto >= 0) {
            let contrattoSelezionato = intestazioneGiorniCalendario.contratti[indexContratto].turni[quadranteSelected.indice]
            if(contrattoSelezionato.assegnato){
                dissociaDipendenteRichiestaCalendario({ id: quadranteSelected.idRiga, indice: quadranteSelected.indice, idRichiesta: contrattoSelezionato.richiesta._id, resti: false })
                setIncarichiAlert({ ...incarichiAlert, incarichiAssegnati: incarichiAlert.incarichiAssegnati - 1 })
            }else{
                nascondiContrattoCalendario({ idContratto: contrattoSelezionato.contratto._id, dataRichiesta: contrattoSelezionato.data, id: quadranteSelected.idRiga, indice: quadranteSelected.indice, continuativo: contrattoSelezionato.contratto.continuativo, numeroContinuativo: contrattoSelezionato.contratto.numeroContinuativo, oraInizio: contrattoSelezionato.contratto.oraInizio, oraFine: contrattoSelezionato.contratto.oraFine })
            }
        }
    }


    //*
    const onClickAssociaRichiestaWithCheck = (idDipendente) => {
        let indexContratto = intestazioneGiorniCalendario.contratti.findIndex(el => el.id == quadranteSelected.idRiga);
            if (indexContratto >= 0) {
                let contrattoSelezionato = intestazioneGiorniCalendario.contratti[indexContratto].turni[quadranteSelected.indice]
                let indexDipendente = dipendentiList.findIndex(el => el._id == idDipendente);
                associaDipendenteRichiestaCalendario({ id: quadranteSelected.idRiga, indice: quadranteSelected.indice, idDipendente, idRichiesta: contrattoSelezionato.richiesta ? contrattoSelezionato.richiesta._id : null, idContratto: contrattoSelezionato.contratto._id, dataRichiesta: contrattoSelezionato.data, oraInizio: contrattoSelezionato.oraInizio, oraFine: contrattoSelezionato.oraFine, resti: false, disponibilita: dipendentiList[indexDipendente].calcoli.disponibilita, continuativo: contrattoSelezionato.contratto.continuativo, numeroContinuativo: contrattoSelezionato.contratto.numeroContinuativo, recuperoRiposo: false, dataRecuperoRiposo: dataInputRR, prestato: dipendentiList[indexDipendente].calcoli.prestato, forzato: false })
            }
    }

    //*
    const onClickAssociaRichiestaPrestatoWithCheck = () => {
        console.log("")
    }

    


    //*
    const onClickDuplicaRiga = () => { 
        let indexContratto = intestazioneGiorniCalendario.contratti.findIndex(el => el.id == quadranteSelected.idRiga);
        if (indexContratto >= 0) {
            let contrattoSelezionato = intestazioneGiorniCalendario.contratti[indexContratto].turni[quadranteSelected.indice]
            duplicaServizioCalendario({ idContratto: contrattoSelezionato.contratto._id, data: contrattoSelezionato.data, id: quadranteSelected.idRiga, indice: quadranteSelected.indice })
        }  
    }

    //*
    const onClickOpenServizioSporadico = () => {
        setServizioSporadicoOpen(!servizioSporadicoOpen)
    }







    //*
    const onClickArchiviaRichieste = (() => {
        let indexContratto = intestazioneGiorniCalendario.contratti.findIndex(el => el.id == quadranteSelected.idRiga);
        if (indexContratto >= 0) {
            let contrattoSelezionato = intestazioneGiorniCalendario.contratti[indexContratto].turni[quadranteSelected.indice]
            archiviaRichieste({ richieste: [contrattoSelezionato.richiesta._id]})
        }
    })

    const onClickRipristinaRichiesteArchiviate = (() => {
        let indexContratto = intestazioneGiorniCalendario.contratti.findIndex(el => el.id == quadranteSelected.idRiga);
        if (indexContratto >= 0) {
            let contrattoSelezionato = intestazioneGiorniCalendario.contratti[indexContratto].turni[quadranteSelected.indice]
            annullaArchiviaRichieste({ richieste: [contrattoSelezionato.richiesta._id]})
        }
    })



    //*
    const onClickEliminaGiornoEccezione = (() => {
        if (rowSelectedGiornoEccezione.id) {
            let indexContratto = intestazioneGiorniCalendario.contratti.findIndex(el => el.id == quadranteSelected.idRiga);
            if (indexContratto >= 0) {
                let contrattoSelezionato = intestazioneGiorniCalendario.contratti[indexContratto].turni[quadranteSelected.indice]
                eliminaGiornoEccezioneLungoRichieste({ idGiornoEccezione: rowSelectedGiornoEccezione.id, dataRichiesta: contrattoSelezionato.data, oraInizio: contrattoSelezionato.oraInizio, oraFine: contrattoSelezionato.oraFine, resti: false })
            }
        }
    })

    //*
    const onClickConfermaGiornoEccezione = (() => {
        confermaGiorniEccezione({ giorniEccezione: checkBoxAssenze, check: true })
    })

    //*
    const onClickSconfermaGiornoEccezione = (() => {
        confermaGiorniEccezione({ giorniEccezione: checkBoxAssenze, check: false })
    })

    //*
    const onClickripristinaContrattoNascostoCalendario = (() => {
        if (quadranteSelected.stato == "nascosta") {
            let indexContratto = intestazioneGiorniCalendario.contratti.findIndex(el => el.id == quadranteSelected.idRiga);
                if (indexContratto >= 0) {
                    let contrattoSelezionato = intestazioneGiorniCalendario.contratti[indexContratto].turni[quadranteSelected.indice]
                    ripristinaContrattoNascostoCalendario({ id: quadranteSelected.idRiga, indice: quadranteSelected.indice, idRichiesta: contrattoSelezionato.richiesta._id })
                }
        }
    })




    //*
    const onClickSelectDipendente = ((e, idDipendente) => {
        switch (e.detail) {
            case 1:
                setDipendenteSelected(idDipendente);
                break;
            case 2:
                onClickAssociaRichiestaWithCheck(idDipendente)
                break;
            case 3:
                onClickAssociaRichiestaWithCheck(idDipendente)
                break;
            default:
                return;
        }
        if (idDipendente) {
            //Carica turni dipendente della giornata
            setWaitingTurniDipendente(true)
            cleanIncarichiDipendente()
            let dataStart = startOfWeek(setHours(setMinutes(quadranteSelected.dataSelezionata, 0), 0), { weekStartsOn: 1 })
            dataStart = subDays(dataStart, 1)
            let dataEnd = endOfWeek(setHours(setMinutes(quadranteSelected.dataSelezionata, 59), 23), { weekStartsOn: 1 })
            getIncarichiDipendente({ dataInizio: dataStart, dataFine: dataEnd, idDipendente: idDipendente });
        }
    })

    //*
    const onClickSelectDipendenteNonAssegnato = ((idDipendente) => {
        setDipendenteNonAssegnatoSelected(idDipendente);
    })

    //*
    const onClickSalvaDisponibilita = (() => {
        console.log()
    })

    //*
    const onClickSalvaDisponibilitaNonAssegnati = (() => {
        console.log()
    })

   


    const onClickAggiungiServizioSporadico = () => {
        aggiungiServizioSporadico({ idPuntoVenditaSporadico, dataInizioServizioSporadico, dataFineServizioSporadico, oraInizioServizioSporadico, oraFineServizioSporadico, multiplo })
    }

    const onClickModificaOrarioServizio = () => {
        let tempContratti = [...intestazioneGiorniCalendario.contratti]
        let indexContratto = intestazioneGiorniCalendario.contratti.findIndex(el => el.id == quadranteSelected.idRiga);
        if (indexContratto >= 0) {
            let oraInizioSplitted = oraInizioModificaOrario.split(":");
            let oraFineSplitted = oraFineModificaOrario.split(":");
            if ((parseInt(oraInizioSplitted[0]) <= 23 && parseInt(oraInizioSplitted[0]) >= 0) && (parseInt(oraInizioSplitted[1]) <= 59 && parseInt(oraInizioSplitted[1]) >= 0)
                &&
                (parseInt(oraFineSplitted[0]) <= 23 && parseInt(oraFineSplitted[0]) >= 0) && (parseInt(oraFineSplitted[1]) <= 59 && parseInt(oraFineSplitted[1]) >= 0)
            ) {
                let temp_state = [...tempContratti[indexContratto].turni];
                let temp_element = temp_state[quadranteSelected.indice];
                if (quadranteSelected.stato == 'assegnata') {
                    modificaOrarioRichiestaCalendario({ id: quadranteSelected.idRiga, indice: quadranteSelected.indice, idRichiesta: temp_element.richiesta._id, dataRichiesta: temp_element.data, oraInizio: oraInizioModificaOrario, oraFine: oraFineModificaOrario, resti: false })
                } else {
                temp_element.oraInizio = oraInizioModificaOrario
                temp_element.oraFine = oraFineModificaOrario
                setIntestazioneGiorniCalendario({ ...intestazioneGiorniCalendario, contratti: tempContratti})
                }
    
            }
        }
        setOpenPopUpAssociaDipendente({ open: false, messaggio: [], tipologia: "", recuperoRiposo: false, dataRecuperoRiposo: new Date(), prestato: false, idRiga: null, elemento: null, indice: null })
        setOpenModificaOrario(false)
    }

    //FUNZIONI ONCHANGE

    //*
    const onChangeRangeDate = (data) => {
        setDataStartInput(data)
        setDatiServizioSporadico({ ...datiServizioSporadico, dataInizioServizioSporadico: data, dataFineServizioSporadico: data })
    }

    //*
    const onChangeRangeDateRR = (data) => {
        setDataInputRR(data)
    }

    

    // *
    const onChangeSetCheckboxFiltraDisponibili = (e) => {
        setCheckBoxFiltraDisponibili(e.target.value)
        let arrayDipendenti = dipendenti;
        if (e.target.value === 'disponibili') {
            arrayDipendenti = arrayDipendenti.filter(dipendente => dipendente.calcoli.disponibilita == "DI");
        }
        if (testoFiltraDipendente.length > 0) {
            arrayDipendenti = arrayDipendenti.filter(function (dipendente) {
                return dipendente.nome.toLowerCase().startsWith(testoFiltraDipendente.toLowerCase())
                    || dipendente.cognome.toLowerCase().startsWith(testoFiltraDipendente.toLowerCase());
            })
        }
        arrayDipendenti = arrayDipendenti.filter(dipendente => dipendente.qualifica != "impiegato");
        arrayDipendenti = arrayDipendenti.filter(dipendente => dipendente.calcoli.prestato == false);
        arrayDipendenti = arrayDipendenti.sort(ordinaDipendenti)
        setDipendentiList(arrayDipendenti)
    }




    //*
    const onChangeFiltraDipendenti = e => {
        setTestoFiltraDipendente(e.target.value)
        let arrayDipendenti = dipendenti.filter(function (dipendente) {
            return dipendente.nome.toLowerCase().startsWith(e.target.value.toLowerCase()) || dipendente.cognome.toLowerCase().startsWith(e.target.value.toLowerCase());
        })
        if (checkBoxFiltraDisponibili === 'disponibili') {
            arrayDipendenti = arrayDipendenti.filter(dipendente => dipendente.calcoli.disponibilita == "DI");
        }
        arrayDipendenti = arrayDipendenti.filter(dipendente => dipendente.qualifica != "impiegato");
        arrayDipendenti = arrayDipendenti.filter(dipendente => dipendente.calcoli.prestato == false);
        arrayDipendenti = arrayDipendenti.sort(ordinaDipendenti)
        setDipendentiList(arrayDipendenti)
    };






    //*
    const onChangeSetDisponibilita = (e) => {
        setDisponibilitaSelected(e.target.value)
    }

    //*
    const onChangeSetDisponibilitaNonAssegnati = (e) => {
        setDisponibilitaNonAssegnatoSelected(e.target.value)
    }


    const onChangeSetDatiServizioSporadico = e => setDatiServizioSporadico({ ...datiServizioSporadico, [e.target.name]: e.target.value });
    const onChangeSetModificaOrario = e => setDatiModificaOrario({ ...datiModificaOrario, [e.target.name]: e.target.value });

    const onChangeSetDatiServizioSporadicoPuntoVendita = e => {
        getContrattiPuntoVendita({ idPuntoVendita: e.target.value })
        setDatiServizioSporadico({ ...datiServizioSporadico, [e.target.name]: e.target.value })
    };


    const onChangesetCheckBoxAssenze = ({ name, id, value }) => {
        let arrayCheckBoxAssenze = [];
        if (id) {
            arrayCheckBoxAssenze = [...checkBoxAssenze];
            const index = checkBoxAssenze.findIndex(el => el == id);
            if (index >= 0) {
                arrayCheckBoxAssenze.splice(index, 1);
            } else {
                arrayCheckBoxAssenze.push(id);
            }
        } else if (name === "tuttiContratti") {
            setCheckboxSelezionaAssenze(!checkboxSelezionaAssenze)
            if (value == "true") {
                arrayCheckBoxAssenze = []
            } else {
                giorniEccezioneData.map((el) => {
                    arrayCheckBoxAssenze.push(el._id)
                })
            }
        }
        setCheckBoxAssenze(arrayCheckBoxAssenze)
    };

    //*
    const onClickSetRowSelectedGiornoEccezione = async ({ id, dipendente, dataInizio, dataFine, disponibilita, nProtocollo }) => {
        setRowSelectedGiornoEccezione({ id, dipendente, dataInizio: new Date(dataInizio), dataFine: new Date(dataFine), disponibilita, nProtocollo })
    }

    const checkResOrd = (ore) => {
        let number = Number(ore)
        if (number > 0) {
            return true
        } else {
            return false
        }
    }


    const componentRef = useRef();

    const print = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: "dipendenti non assegnati"
    });


    const tableStyle2 = {
        textAlign: "left",
        border: "1px solid",
        width: "6%",
        padding: "2px 2px",
        wordBreak: "break-all"
    }

    const tableStyle1 = {
        textAlign: "left",
        border: "1px solid",
        width: "30%",
        padding: "2px 2px"
    }

    const tableStyle3 = {
        textAlign: "left",
        border: "1px solid",
        width: "15%",
        padding: "2px 2px"
    }

    const tableStyle = {
        width: "100%",
        border: "1px solid"
    };

    const tableInt1 = {
        fontSize: "12px",
        lineHeight: "1.3",
        background: "#8ebde6"
    };

   

    return (
        <div className="body wf-section">
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`Incarichi ${dataStartInput.toLocaleString().split(',')[0]} - Gestionale Vigili dell'ordine`}</title>
            </Helmet>
            <div className="div_wrap">
                <div className="div_wrap_100">
                <div className="div_wrap_inner_100">
                    <div className="div_container">
                        <div className="title_window">
                            <div className="title_window_p">Assegnazione incarichi</div>
                            {false ? <span className={`${incarichiAlert.incarichiTotali === 0 ? "" : incarichiAlert.incarichiAssegnati < incarichiAlert.incarichiTotali ? "red_label" : "green_label"} label_incarichi`}>{`${incarichiAlert.incarichiAssegnati}/${incarichiAlert.incarichiTotali}`}</span> : ''}
                        </div>
                        <div className="dati_container">
                            <div className="fun_assegnazione_incarichi">
                                <div className="row row_datapicker">
                                    <div className="item_1 filtro_wrap_incarichi">
                                        <div id="w-node-_284812ee-7924-9f38-86bb-8d2cc43dff3c-867f153d" className="div_item_1">
                                            <div id="w-node-_80bb2acb-3043-3649-0498-379a652f3b04-867f153d" className="label_text">Data</div>
                                            <div data-hover="false" data-delay="0" className="select w-dropdown">
                                                <DatePicker
                                                    value={dataStartInput}
                                                    format="dd/MM/y"
                                                    weekPicker
                                                    onChange={onChangeRangeDate}
                                                    //showTodayButton={false}
                                                    //weekDays={weekDays}
                                                    //months={months}
                                                    locale="it-IT"
                                                    clearIcon={null}
                                                    weekStartDayIndex={1}
                                                />
                                            </div>
                                        </div>
                                        <div id="w-node-_98d32d21-f772-2386-6ce9-47c8e0ec064d-867f153d" className="div_item_1">
                                        <div id="w-node-_80bb2acb-3043-3649-0498-379a652f3b04-867f153d" className="label_text"><span> Carica servizi</span></div>
                                            <div className="w-form">
                                                <div id="email-form" name="email-form" data-name="Email Form" method="get">
                                                    <button id="w-node-a7f8735f-5a06-66e1-52ab-69f728a73208-867f153d" onClick={(e) => { loadingCalendario ? e.preventDefault() : onClickGetContrattiRichiestePuntiVendita() }} className={`button w-button${loadingCalendario ? " button_disabled" : ''}`} disabled={loadingCalendario ? true : false}>Esplodi servizi</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="w-node-_32403d1b-d20e-1341-c5d5-395ced6b5d87-867f153d" className="div_item_1">
                                                <div className="w-form">
                                                    <form id="email-form" name="email-form" data-name="Email Form" method="get">
                                                        <div id="w-node-_80bb2acb-3043-3649-0498-379a652f3b04-867f153d" className="label_text">Numero giorni</div>
                                                        <div data-hover="false" data-delay="0" className="select w-dropdown">
                                                            <select className="form-control select" name="idPuntoVendita" onChange={e => setGiorniSelected(e.target.value)} required>
                                                            <option selected={giorniSelected == 2} value={2}>2 giorni</option>
                                                            <option selected={giorniSelected == 7} value={7}>7 giorni</option>
                                                            <option selected={giorniSelected == 14} value={14}>14 giorni</option>
                                                            </select>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                    </div>
                                </div>
                                <div className="row row_datapicker">
                                    <div className="item_1 filtro_wrap_incarichi">
                                        <div id="w-node-_714e71a9-0550-fe0e-26e9-8b45ac42ab89-867f153d" className="div_item_1">
                                            <div className="w-form">
                                                <form id="email-form" name="email-form" data-name="Email Form" method="get">
                                                    <label className="w-checkbox checkbox-field"><span className="label_text w-form-label" htmlFor="checkbox-2"></span></label>
                                                    <a id="w-node-_714e71a9-0550-fe0e-26e9-8b45ac42ab90-867f153d" onClick={() => { setCheckOpenNascosti(!checkOpenNascosti) }} className={`button w-button${checkOpenNascosti ? " red" : ""}`}>{checkOpenNascosti ? "Chiudi nascosti" : "Mostra nascosti"}</a>
                                                </form>
                                            </div>
                                        </div>
                                        <div id="w-node-bf561da2-25a7-b3ef-c44c-a819295560e3-867f153d" className="div_item_empty"></div>
                                        <div id="w-node-bf561da2-25a7-b3ef-c44c-a819295560e3-867f153d" className="div_item_empty"></div>
                                        <div id="w-node-_714e71a9-0550-fe0e-26e9-8b45ac42ab89-867f153d" className="div_item_1">
                                            <div className="w-form">
                                                <form id="email-form" name="email-form" data-name="Email Form" method="get">
                                                    <label className="w-checkbox checkbox-field"><span className="label_text w-form-label" htmlFor="checkbox-2"></span></label>
                                                    {false ? <p>↪️ riferito al giorno dopo</p> : ''}
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row margin_top">
                                    <div className="item_2">
                                        <div className="intestazione_table intestazione_calendario">
                                            <div className="w-layout-grid div_column_assegnazioni_calendario_interattivo intestazione">
                                                <div className="div_item_column">
                                                    <div className="item_table"></div>
                                                </div>
                                                <div className="div_item_column">
                                                    <div className="item_table">Servizio</div>
                                                </div>
                                                <div className="div_item_column">
                                                    <div className="item_table">Località</div>
                                                </div>
                                                <div className="div_item_column">
                                                    <div className="item_table">Dalle</div>
                                                </div>
                                                <div className="div_item_column">
                                                    <div className="item_table">Alle</div>
                                                </div>
                                                {intestazioneGiorniCalendario.intestazione.map((el) => (
                                                    <div className="div_item_column">
                                                        <div className="item_table">
                                                            <><Moment format='dddd'>{el}</Moment> <Moment format='D'>{el}</Moment></>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        {loadingCalendario ? <Spinner /> :
                                            <div className="body_table body_table_calendario">
                                                {intestazioneGiorniCalendario.contratti.map((el, i) => (
                                                    <div key={"contratto" + i} className={"w-layout-grid div_column_assegnazioni_calendario_interattivo"}>
                                                            <div className="div_item_column">
                                                                <div className="item_table">{i+1}</div>
                                                            </div>
                                                            <div className="div_item_column">
                                                                <div className="item_table">{el.denominazione}</div>
                                                            </div>
                                                            <div className="div_item_column">
                                                                <div className="item_table">{el.localita}</div>
                                                            </div>
                                                            <div className="div_item_column">
                                                                <div className="item_table">{el.oraInizio}</div>
                                                            </div>
                                                            <div className="div_item_column">
                                                                <div className="item_table">{el.oraFine}</div>
                                                            </div>
                                                            {el.turni.map((ele, i) => (
                                                                <>
                                                                {ele.esiste ?
                                                                    <>
                                                                    {ele.richiesta ?
                                                                    <>
                                                                    {ele.richiesta.stato == 'nascosta' ?
                                                                        <div className={`div_item_column element_calendar_selectable white_assegna grey ${quadranteSelected.idRiga == el.id && quadranteSelected.indice == i ? 'contratto_selected' : ''}`} onClick={(e) => {selezionaQuadrante({idRiga: el.id, elemento: ele, indice: i})}}>
                                                                            <div className="item_table">{checkOpenNascosti ? 'nascosta'  : ''}</div>
                                                                        </div>
                                                                    : ele.richiesta.stato == 'assegnata' ?
                                                                        <div className={`div_item_column element_calendar_selectable white_assegna ${quadranteSelected.idRiga == el.id && quadranteSelected.indice == i ? 'contratto_selected' : ''}`} onClick={(e) => {selezionaQuadrante({idRiga: el.id, elemento: ele, indice: i})}}>
                                                                            <div className="item_table">{ele.richiesta.dipendente &&  ele.richiesta.dipendente.cognome}</div>
                                                                            <div class="orario_wrap-absolute_assegnazioni"><span>{ele.oraInizio} - {ele.oraFine}</span></div>
                                                                        </div>
                                                                    :
                                                                    <div className={`div_item_column element_calendar_selectable white_assegna green ${quadranteSelected.idRiga == el.id && quadranteSelected.indice == i ? 'contratto_selected' : ''}`} onClick={(e) => {selezionaQuadrante({idRiga: el.id, elemento: ele, indice: i})}}>
                                                                        <div className="item_table">{ele.richiesta.dipendente &&  ele.richiesta.dipendente.cognome}</div>
                                                                    </div>
                                                                    }
                                                                    </>
                                                                    : ele.dipendenteSuggerito ?
                                                                    <div className={`div_item_column element_calendar_selectable white_assegna ${quadranteSelected.idRiga == el.id && quadranteSelected.indice == i ? 'contratto_selected' : ''}`} onClick={(e) => {selezionaQuadrante({idRiga: el.id, elemento: ele, indice: i})}}>
                                                                        <div className="item_table" >
                                                                            <div id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98d-867f153d" className="div_item_button associa_preferito">
                                                                                <a id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98e-867f153d" className="button w-button" onClick={() => onClickAssociaDipendenteSuggerito({ idRiga: el.id, elemento: ele, indice: i, idDipendente: ele.dipendenteSuggerito._id, id: ele.contratto._id, idRichiesta: null })}>Associa {ele.dipendenteSuggerito.cognome + " " + ele.dipendenteSuggerito.nome }</a>
                                                                            </div>
                                                                            <div class="orario_wrap-absolute_assegnazioni"><span>{ele.oraInizio} - {ele.oraFine}</span></div>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <div className={`div_item_column element_calendar_selectable white_assegna ${quadranteSelected.idRiga == el.id && quadranteSelected.indice == i ? 'contratto_selected' : ''}`} onClick={(e) => {selezionaQuadrante({idRiga: el.id, elemento: ele, indice: i})}}>
                                                                        <div className="item_table"></div>
                                                                        <div class="orario_wrap-absolute_assegnazioni"><span>{ele.oraInizio} - {ele.oraFine}</span></div>
                                                                    </div> 
                                                                    }
                                                                    </>
                                                                :
                                                                <div className="div_item_column grey">
                                                                    <div className="item_table"></div>
                                                                </div>
                                                                }
                                                                </>
                                                            ))}
                                                    </div>
                                                ))}
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="item_3 m_top_5">
                                        <div className="list_button">
                                            <div id="w-node-_571e9ca7-8650-59ca-aaad-79ac7369ac51-867f153d" className="div_item_button">
                                                <a id="w-node-_8af3fff2-d158-bb07-f4c8-6e208fda3794-867f153d" onClick={(e) => {quadranteSelected.idRiga && quadranteSelected.indice >= 0 ?  onClickDuplicaRiga() : e.preventDefault() }} className={`button w-button${quadranteSelected.idRiga && quadranteSelected.indice >= 0 ? "" : " button_disabled"}`}>Sdoppia servizio</a>
                                            </div>
                                            <div id="w-node-_8af3fff2-d158-bb07-f4c8-6e208fda3793-867f153d" className="div_item_button">
                                                <a id="w-node-_8af3fff2-d158-bb07-f4c8-6e208fda3794-867f153d" onClick={(e) => !quadranteSelected.idRiga || quadranteSelected.stato == 'nascosta' || quadranteSelected.stato == 'archiviata' ? e.preventDefault() : onClickDissociaDipendente()} className={`button w-button ${ !quadranteSelected.stato || quadranteSelected.stato == 'nascosta' || quadranteSelected.stato == 'archiviata' ? "button_disabled" : ""}`}>{quadranteSelected.stato == 'creata' ? "Elimina servizio" : "Dissocia dipendente"}</a>
                                            </div>
                                            
                                            <div id="w-node-bf561da2-25a7-b3ef-c44c-a819295560e3-867f153d" className="div_item_empty"></div>
                                            <div id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98d-867f153d" className="div_item_button">
                                                <a id="w-node-_8af3fff2-d158-bb07-f4c8-6e208fda3794-867f153d" onClick={(e) =>{quadranteSelected.idRiga && quadranteSelected.indice >= 0 && quadranteSelected.stato != 'archiviata' && quadranteSelected.stato != 'nascosta' ?  onClickOpenModificaOrario() : e.preventDefault() }} className={`button w-button${quadranteSelected.idRiga && quadranteSelected.indice >= 0 && quadranteSelected.stato != 'archiviata' && quadranteSelected.stato != 'nascosta' ? "" : " button_disabled"}`}>Modifica orario</a>
                                            </div>
                                                <div id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98d-867f153d" className="div_item_button">
                                                    <a id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98e-867f153d" className={`button w-button ${quadranteSelected.stato == 'nascosta' ? "" : "button_disabled"}`} onClick={(e) => quadranteSelected.stato == 'nascosta' ? onClickripristinaContrattoNascostoCalendario() : e.preventDefault()}>Ripristina nascosto</a>
                                                </div>
                                                
                                                <div id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98d-867f153d" className="div_item_button">
                                                    <a id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98e-867f153d" className={`button w-button ${quadranteSelected.stato == 'archiviata' ? "" : "button_disabled"}`} onClick={(e) => quadranteSelected.stato == 'archiviata' ?  onClickRipristinaRichiesteArchiviate() : e.preventDefault()}>Ripristina servizio confermato</a>
                                                </div>
                                            <div id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98d-867f153d" className="div_item_button">
                                                <a id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98e-867f153d" className={`button w-button ${!quadranteSelected.idRiga || quadranteSelected.stato != 'assegnata' ? "button_disabled" : ""}`} onClick={(e) => !quadranteSelected.idRiga || quadranteSelected.stato != 'assegnata' ? e.preventDefault() : onClickArchiviaRichieste()}>Conferma servizio</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="item_3 m_top_5">
                                        <div className="list_button">
                                            <div id="w-node-_571e9ca7-8650-59ca-aaad-79ac7369ac51-867f153d" className="div_item_button">
                                                <a id="w-node-_571e9ca7-8650-59ca-aaad-79ac7369ac52-867f153d" onClick={(e) => { intestazioneGiorniCalendario.contratti.length > 0 ? onClickOpenServizioSporadico() : e.preventDefault()}} className={`button w-button${intestazioneGiorniCalendario.contratti.length > 0 ? "" : " button_disabled"}`}>Servizio sporadico</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {openPopUpAssociaDipendenteConRR ?
                        <div className="popup wf-section">
                            <div className="alert_popup">
                                <p>Scegli la data a cui associare un recupero riposo</p>
                                <div className="div-block">
                                    <>
                                        <div data-hover="false" data-delay="0" className="select w-dropdown">
                                            <DatePicker
                                                value={dataInputRR}
                                                format="dd/MM/y"
                                                weekPicker
                                                onChange={onChangeRangeDateRR}
                                                locale="it-IT"
                                                clearIcon={null}
                                                weekStartDayIndex={1}
                                            />
                                        </div>
                                        <a onClick={() => onClickAssociaRichiestaConRRPopUp(dipendenteSelected)} className="button m_left_5 w-button">Salva con RR</a>
                                        <a onClick={() => setOpenPopUpAssociaDipendenteConRR(false)} className="button red w-button">Annulla</a>
                                    </>
                                </div>
                            </div>
                        </div>
                        :
                        openPopUpAssociaDipendente.open && openPopUpAssociaDipendente.messaggi.length > 0 ?
                            <div className="popup wf-section">
                                <div className="alert_popup">
                                    <div>
                                        {openPopUpAssociaDipendente.messaggi.map((el) => (
                                            <p>{el.messaggio}</p>
                                        ))}
                                    </div>
                                    {openPopUpAssociaDipendente.tipologia == "alert_dipendente" ?
                                        <div className="div-block">
                                            {openPopUpAssociaDipendente.recuperoRiposo ?
                                                <>
                                                    <a onClick={() => setOpenPopUpAssociaDipendenteConRR(true)} className="button m_left_5 w-button">Associa con RR</a>
                                                    <a onClick={() => setOpenPopUpAssociaDipendenteSenzaRR(dipendenteSelected)} className="button m_left_5 w-button">Associa senza RR</a>
                                                    <a onClick={() => setOpenPopUpAssociaDipendente({ open: false, messaggi: [], tipologia: "", id: null, recuperoRiposo: false, dataRecuperoRiposo: new Date(), prestato: false, idRiga: null, elemento: null, indice: null })} className="button red w-button">No</a>
                                                </>
                                                : openPopUpAssociaDipendente.prestato ?
                                                    <>
                                                        <a onClick={() => onClickAssociaRichiestaPrestatoPopUp(dipendenteSelected)} className="button m_left_5 w-button">Si</a>
                                                        <a onClick={() => setOpenPopUpAssociaDipendente({ open: false, messaggi: [], tipologia: "", id: null, recuperoRiposo: false, dataRecuperoRiposo: new Date(), prestato: false, idRiga: null, elemento: null, indice: null })} className="button red w-button">No</a>
                                                    </>
                                                    :
                                                    <>
                                                        <a onClick={() => onClickAssociaRichiestaPopUp(dipendenteSelected)} className="button m_left_5 w-button">Si</a>
                                                        <a onClick={() => setOpenPopUpAssociaDipendente({ open: false, messaggi: [], tipologia: "", id: null, recuperoRiposo: false, dataRecuperoRiposo: new Date(), prestato: false, idRiga: null, elemento: null, indice: null })} className="button red w-button">No</a>
                                                    </>
                                            }
                                        </div>
                                        :
                                        ''
                                    }
                                    {openPopUpAssociaDipendente.tipologia == "alert_dipendente_suggerito" ?
                                        <div className="div-block">
                                            <a onClick={() => onClickAssociaRichiestaDipendenteSuggeritoPopUp(dipendenteSelected)} className="button m_left_5 w-button">Si</a>
                                            <a onClick={() => setOpenPopUpAssociaDipendente({ open: false, messaggi: [], tipologia: "", id: null, recuperoRiposo: false, dataRecuperoRiposo: new Date(), prestato: false, idRiga: null, elemento: null, indice: null })} className="button red w-button">No</a>
                                        </div>
                                        :
                                        ''
                                    }
                                    {openPopUpAssociaDipendente.tipologia == "alert_modifica_orario" ?
                                        <div className="div-block">
                                            <a onClick={() => setOpenPopUpAssociaDipendente({ open: false, messaggi: [], tipologia: "", id: null, recuperoRiposo: false, dataRecuperoRiposo: new Date(), prestato: false, idRiga: null, elemento: null, indice: null })} className="button red w-button">Chiudi</a>
                                        </div>
                                        :
                                        ''
                                    }
                                </div>
                            </div>
                            :
                            
                            ''
                }
                {servizioSporadicoOpen ?
                        <div className="popup wf-section">
                            <div className="alert_popup">
                                <div className="dati_container">
                                    <div className="fun_assegnazione_incarichi">
                                        <div className="row">
                                            <div className="item_2">
                                                <h4>Crea servizio sporadico</h4>
                                            </div>
                                            <div className="w-form">
                                                <form id="nuovo_punto_servizio" name="email-form-2" data-name="Email Form 2" method="get" aria-label="Email Form 2">
                                                    <div className="div_wrap_100">
                                                        <div className="div_wrap_100">
                                                            <div className="col_input col_select">
                                                                <label htmlFor="name">Punto di servizio</label>
                                                                <div data-hover="false" data-delay="0" className="select w-dropdown">
                                                                    <select className="form-control select" name="idPuntoVenditaSporadico" onChange={e => onChangeSetDatiServizioSporadicoPuntoVendita(e)} required>
                                                                        <option selected value={null} disabled >Seleziona punto di servizio...</option>
                                                                        {puntiVendita.map((puntoVendita, i) => (
                                                                            <option key={i} value={puntoVendita._id}>{puntoVendita.denominazione}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="div_wrap_100">
                                                        <div className="div_wrap_50">
                                                            <div className="col_input"><label htmlFor="name-11">Data inizio</label>
                                                                <div data-hover="false" data-delay="0" className="select w-dropdown select_data">
                                                                    <DatePicker
                                                                        onChange={(value) => setDatiServizioSporadico({ ...datiServizioSporadico, dataInizioServizioSporadico: value })}
                                                                        value={new Date(dataInizioServizioSporadico)}
                                                                        calendarIcon={null}
                                                                        clearIcon={null}
                                                                        className={'form-control'}
                                                                    //disableCalendar={true}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="div_wrap_50">
                                                            <div className="col_input"><label htmlFor="name-11">Data fine</label>
                                                                <div data-hover="false" data-delay="0" className="select w-dropdown select_data">
                                                                    <DatePicker
                                                                        onChange={(value) => setDatiServizioSporadico({ ...datiServizioSporadico, dataFineServizioSporadico: value })}
                                                                        value={new Date(dataFineServizioSporadico)}
                                                                        calendarIcon={null}
                                                                        clearIcon={null}
                                                                        className={'form-control'}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="div_wrap_100">
                                                        <div className="div_wrap_50">
                                                            <div className="col_input"><label htmlFor="name-11">Orario inizio</label>
                                                                <input className="input w-input" type="time" value={oraInizioServizioSporadico} name="oraInizioServizioSporadico" id="oraInizioServizioSporadico" onChange={e => onChangeSetDatiServizioSporadico(e)} />
                                                            </div>
                                                        </div>
                                                        <div className="div_wrap_50">
                                                            <div className="col_input"><label htmlFor="name-11">Orario fine</label>
                                                                <input className="input w-input" type="time" value={oraFineServizioSporadico} name="oraFineServizioSporadico" id="oraFineServizioSporadico" onChange={e => onChangeSetDatiServizioSporadico(e)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="div_wrap_100">
                                                        <div className="div_wrap_50">
                                                            <div className="col_input"><label htmlFor="name-11">Multiplo</label>
                                                                <input className="input w-input" type="number" value={multiplo} name="multiplo" id="multiplo" onChange={e => onChangeSetDatiServizioSporadico(e)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="item_3 m_top_5">
                                                <div className="list_button">
                                                    <div id="w-node-_571e9ca7-8650-59ca-aaad-79ac7369ac51-867f153d" className="div_item_button">
                                                        <a id="w-node-_571e9ca7-8650-59ca-aaad-79ac7369ac52-867f153d" onClick={(e) => { setServizioSporadicoOpen(false) }} className={`button w-button red`}>Annulla</a>
                                                    </div>
                                                    <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc40-867f153d" className="div_item_empty"></div>
                                                    <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc40-867f153d" className="div_item_empty"></div>
                                                    <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc40-867f153d" className="div_item_empty"></div>
                                                    <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc40-867f153d" className="div_item_empty"></div>
                                                    <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc40-867f153d" className="div_item_empty"></div>
                                                    <div id="w-node-_8af3fff2-d158-bb07-f4c8-6e208fda3793-867f153d" className="div_item_button">
                                                        <a id="w-node-_571e9ca7-8650-59ca-aaad-79ac7369ac52-867f153d" onClick={(e) => { onClickAggiungiServizioSporadico() }} className={`button w-button`}>Salva</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        ''
                }
                {openModificaOrario ?
                        <div className="popup wf-section">
                            <div className="alert_popup">
                                <div className="dati_container">
                                    <div className="fun_assegnazione_incarichi">
                                        <div className="row">
                                            <div className="item_2">
                                                <h4>Modifica orario</h4>
                                            </div>
                                            <div className="w-form">
                                                <form id="nuovo_punto_servizio" name="email-form-2" data-name="Email Form 2" method="get" aria-label="Email Form 2">
                                                    <div className="div_wrap_100">
                                                        <div className="div_wrap_50">
                                                            <div className="col_input"><label htmlFor="name-11">Orario inizio</label>
                                                                <input className="input w-input" type="time" value={oraInizioModificaOrario} name="oraInizioModificaOrario" id="oraInizioModificaOrario" onChange={e => onChangeSetModificaOrario(e)} />
                                                            </div>
                                                        </div>
                                                        <div className="div_wrap_50">
                                                            <div className="col_input"><label htmlFor="name-11">Orario fine</label>
                                                                <input className="input w-input" type="time" value={oraFineModificaOrario} name="oraFineModificaOrario" id="oraFineModificaOrario" onChange={e => onChangeSetModificaOrario(e)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="item_3 m_top_5">
                                                <div className="list_button">
                                                    <div id="w-node-_571e9ca7-8650-59ca-aaad-79ac7369ac51-867f153d" className="div_item_button">
                                                        <a id="w-node-_571e9ca7-8650-59ca-aaad-79ac7369ac52-867f153d" onClick={(e) => { setOpenModificaOrario(false) }} className={`button w-button red`}>Annulla</a>
                                                    </div>
                                                    <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc40-867f153d" className="div_item_empty"></div>
                                                    <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc40-867f153d" className="div_item_empty"></div>
                                                    <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc40-867f153d" className="div_item_empty"></div>
                                                    <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc40-867f153d" className="div_item_empty"></div>
                                                    <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc40-867f153d" className="div_item_empty"></div>
                                                    <div id="w-node-_8af3fff2-d158-bb07-f4c8-6e208fda3793-867f153d" className="div_item_button">
                                                        <a id="w-node-_571e9ca7-8650-59ca-aaad-79ac7369ac52-867f153d" onClick={(e) => { onClickModificaOrarioServizio() }} className={`button w-button`}>Salva</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        ''
                }
                </div>
                <div className="div_wrap_100">
                <div className="div_wrap_50">
                        <div className="div_container">
                            <div className="title_window">
                                <div className="title_window_p">Gestione assenze</div>
                            </div>
                            <div className="dati_container">
                                <div className="fun_assegnazione_incarichi">
                                    <div className="row">
                                        <div className="item_2">
                                            <div className="intestazione_table">
                                                <div className="w-layout-grid div_column_gestione_assenze intestazione">
                                                    <div className="div_item_column">
                                                        <div className="item_table">
                                                            <input type="checkbox" id="checkboxSelezionaTutto" name="checkboxSelezionaTutto" data-name="checkboxSelezionaTutto" className="w-checkbox-input checkbox_selection" value={checkboxSelezionaAssenze} onChange={e => onChangesetCheckBoxAssenze({ name: "tuttiContratti", id: null, value: e.target.value })} />
                                                        </div>
                                                    </div>
                                                    <div className="div_item_column">
                                                        <div className="item_table">Data inizio</div>
                                                    </div>
                                                    <div className="div_item_column">
                                                        <div className="item_table">Data fine</div>
                                                    </div>
                                                    <div className="div_item_column">
                                                        <div className="item_table">Dipendente</div>
                                                    </div>
                                                    <div className="div_item_column">
                                                        <div className="item_table">Tipologia</div>
                                                    </div>
                                                    <div className="div_item_column">
                                                        <div className="item_table">Confermata</div>
                                                    </div>
                                                    <div className="div_item_column">
                                                        <div className="item_table">N. protocollo</div>
                                                    </div>
                                                </div>
                                            </div>
                                            {loadingGiorniAssenze ? <Spinner /> :
                                                <div className="body_table">
                                                    {giorniEccezioneData.map((giorno, i) => (
                                                        <div key={"assenza-" + i} className={`w-layout-grid div_column_gestione_assenze cursor ${rowSelectedGiornoEccezione.id == giorno._id ? 'giorno_selected' : ''}`}>
                                                            {giorno.autocreate ?
                                                                <div className="div_item_column"></div>
                                                                :
                                                                <div className="div_item_column">
                                                                    <div className="item_table"><input type="checkbox" id={`checkboxSeleziona${i}`} name={`checkboxSeleziona${i}`} data-name={`checkboxSeleziona${i}`} value={checkboxSelezionaAssenze} className="w-checkbox-input checkbox_selection" checked={checkBoxAssenze.findIndex(el => el == giorno._id) >= 0 ? true : false} onChange={(e) => onChangesetCheckBoxAssenze({ name: "contrattoSingolo", id: giorno._id, value: e.target.value })} /></div>
                                                                </div>
                                                            }
                                                            <div className="div_item_column" onClick={(e) => giorno.autocreate ? e.preventDefault() : onClickSetRowSelectedGiornoEccezione({ id: giorno._id, dipendente: "" + giorno.dipendente.cognome + " " + giorno.dipendente.nome, dataInizio: giorno.dataInizio, dataFine: giorno.dataFine, disponibilita: giorno.disponibilita, nProtocollo: giorno.nProtocollo })}>
                                                                <div className="item_table"><Moment format='DD/MM/YYYY'>{giorno.dataInizio}</Moment></div>
                                                            </div>
                                                            <div className="div_item_column" onClick={(e) => giorno.autocreate ? e.preventDefault() : onClickSetRowSelectedGiornoEccezione({ id: giorno._id, dipendente: "" + giorno.dipendente.cognome + " " + giorno.dipendente.nome, dataInizio: giorno.dataInizio, dataFine: giorno.dataFine, disponibilita: giorno.disponibilita, nProtocollo: giorno.nProtocollo })}>
                                                                <div className="item_table"><Moment format='DD/MM/YYYY'>{giorno.dataFine}</Moment></div>
                                                            </div>
                                                            <div className="div_item_column" onClick={(e) => giorno.autocreate ? e.preventDefault() : onClickSetRowSelectedGiornoEccezione({ id: giorno._id, dipendente: "" + giorno.dipendente.cognome + " " + giorno.dipendente.nome, dataInizio: giorno.dataInizio, dataFine: giorno.dataFine, disponibilita: giorno.disponibilita, nProtocollo: giorno.nProtocollo })}>
                                                                <div className="item_table">{giorno.dipendente.cognome} {giorno.dipendente.nome}</div>
                                                            </div>
                                                            <div className="div_item_column" onClick={(e) => giorno.autocreate ? e.preventDefault() : onClickSetRowSelectedGiornoEccezione({ id: giorno._id, dipendente: "" + giorno.dipendente.cognome + " " + giorno.dipendente.nome, dataInizio: giorno.dataInizio, dataFine: giorno.dataFine, disponibilita: giorno.disponibilita, nProtocollo: giorno.nProtocollo })}>
                                                                <div className="item_table">{giorno.disponibilita}</div>
                                                            </div>
                                                            <div className={`div_item_column ${giorno.confermata ? "green" : "red"}`} onClick={(e) => giorno.autocreate ? e.preventDefault() : onClickSetRowSelectedGiornoEccezione({ id: giorno._id, dipendente: "" + giorno.dipendente.cognome + " " + giorno.dipendente.nome, dataInizio: giorno.dataInizio, dataFine: giorno.dataFine, disponibilita: giorno.disponibilita, nProtocollo: giorno.nProtocollo })}>
                                                                <div className="item_table">{giorno.confermata ? "Si" : "No"}</div>
                                                            </div>
                                                            <div className="div_item_column" onClick={(e) => giorno.autocreate ? e.preventDefault() : onClickSetRowSelectedGiornoEccezione({ id: giorno._id, dipendente: "" + giorno.dipendente.cognome + " " + giorno.dipendente.nome, dataInizio: giorno.dataInizio, dataFine: giorno.dataFine, disponibilita: giorno.disponibilita, nProtocollo: giorno.nProtocollo })}>
                                                                <div className="item_table">{giorno.nProtocollo}</div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="item_3 m_top_5">
                                            <div className="list_button list_button_2">
                                                <div id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98d-867f153d" className="div_item_button">
                                                    <a id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98e-867f153d" className={`button w-button red ${!rowSelectedGiornoEccezione.id ? "button_disabled" : ""}`} onClick={(e) => !rowSelectedGiornoEccezione.id ? e.preventDefault() : onClickEliminaGiornoEccezione()}>Elimina</a>
                                                </div>
                                                <div id="w-node-bf561da2-25a7-b3ef-c44c-a819295560e3-867f153d" className="div_item_empty"></div>
                                                <div id="w-node-bf561da2-25a7-b3ef-c44c-a819295560e3-867f153d" className="div_item_empty"></div>
                                                <div id="w-node-bf561da2-25a7-b3ef-c44c-a819295560e3-867f153d" className="div_item_empty"></div>
                                                <div id="w-node-bf561da2-25a7-b3ef-c44c-a819295560e3-867f153d" className="div_item_empty"></div>
                                                <div id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98d-867f153d" className="div_item_button">
                                                    <a id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98e-867f153d" className={`button w-button green ${checkBoxAssenze.length == 0 ? "button_disabled" : ""}`} onClick={(e) => checkBoxAssenze.length == 0 ? e.preventDefault() : onClickConfermaGiornoEccezione()}>Conferma</a>
                                                </div>
                                                <div id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98d-867f153d" className="div_item_button">
                                                    <a id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98e-867f153d" className={`button w-button red ${checkBoxAssenze.length == 0 ? "button_disabled" : ""}`} onClick={(e) => checkBoxAssenze.length == 0 ? e.preventDefault() : onClickSconfermaGiornoEccezione()}>Sconferma</a>
                                                </div>
                                                <div id="w-node-bf561da2-25a7-b3ef-c44c-a819295560e3-867f153d" className="div_item_empty"></div>
                                                <div id="w-node-bf561da2-25a7-b3ef-c44c-a819295560e3-867f153d" className="div_item_empty"></div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
                <div className="div_wrap_50">
                <div className="div_container">
                                <div className="title_window">
                                    <div className="title_window_p"><Moment format='DD/MM/YYYY'>{dataDipendenti}</Moment></div>
                                </div>
                                <div className="dati_container">
                                    <div className="fun_assegnazione_incarichi">
                                        <div className="row row_datapicker">
                                            <div className="item_1">
                                                <div id="w-node-_7b40e076-1929-a489-3ae4-e7e497d010f9-867f153d" className="div_item_1">
                                                    <div id="w-node-_7b40e076-1929-a489-3ae4-e7e497d010fa-867f153d" className="label_text">Filtra dipendenti</div>
                                                    <div data-hover="false" data-delay="0" className="w-dropdown">
                                                        <input type="text" className="w-input select" maxLength="256" name="filtraDipendente" data-name="filtraDipendente" value={testoFiltraDipendente} placeholder="Inserisci dipendenti..." id="filtraDipendente" onChange={e => onChangeFiltraDipendenti(e)} />
                                                    </div>
                                                </div>
                                                <div id="w-node-bf561da2-25a7-b3ef-c44c-a819295560e3-867f153d" className="div_item_empty"></div>
                                                <div id="w-node-bf561da2-25a7-b3ef-c44c-a819295560e3-867f153d" className="div_item_empty"></div>
                                                <div id="w-node-bf561da2-25a7-b3ef-c44c-a819295560e3-867f153d" className="div_item_empty"></div>
                                                <div id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98d-867f153d" className="div_item_1">
                                                <div id="w-node-_7b40e076-1929-a489-3ae4-e7e497d010fa-867f153d" className="label_text"><span></span></div>
                                                <a id="w-node-_8af3fff2-d158-bb07-f4c8-6e208fda3794-867f153d" onClick={(e) =>{!loadingDipendenti ?  onClickAggiornaDipendenti() : e.preventDefault() }} className={`button w-button${ !loadingDipendenti ? "" : " button_disabled"}`}>Aggiorna</a>
                                            </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="item_2">
                                                <div className="intestazione_table">
                                                    <div className="w-layout-grid div_column_assegnazioni_dipendenti intestazione">
                                                        <div className="div_item_column">
                                                            <div className="item_table">Qual.</div>
                                                        </div>
                                                        <div className="div_item_column">
                                                            <div className="item_table">Sus.</div>
                                                        </div>
                                                        <div className="div_item_column">
                                                            <div className="item_table">Dipendente</div>
                                                        </div>
                                                        <div className="div_item_column">
                                                            <div className="item_table">Res.Ord</div>
                                                        </div>
                                                        <div className="div_item_column">
                                                            <div className="item_table">Res.Str</div>
                                                        </div>
                                                        <div className="div_item_column">
                                                            <div className="item_table">Ass.Ord</div>
                                                        </div>
                                                        <div className="div_item_column">
                                                            <div className="item_table">Ass.Str</div>
                                                        </div>
                                                        <div className="div_item_column">
                                                            <div className="item_table">Dis</div>
                                                        </div>
                                                        <div className="div_item_column">
                                                            <div className="item_table">B.Ore</div>
                                                        </div>
                                                        <div className="div_item_column">
                                                            <div className="item_table">Ferie</div>
                                                        </div>
                                                        <div className="div_item_column">
                                                            <div className="item_table">Perm</div>
                                                        </div>
                                                        <div className="div_item_column">
                                                            <div className="item_table">Ore mese</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {loadingDipendenti ? <Spinner /> : 
                                                    <div className="body_table_2">
                                                        {dipendentiList.map((dipendente, i) => (
                                                            <div key={"dipendente" + i} className={`w-layout-grid div_column_assegnazioni_dipendenti cursor ${dipendenteSelected == dipendente._id ? 'dipendente_selected' : dipendente.calcoli.occupato ? "red_label" : ""}`}>
                                                                <div className="div_item_column" onClick={(e) => onClickSelectDipendente(e, dipendente._id)}>
                                                                    <div className="item_table">{dipendente.qualifica}</div>
                                                                </div>
                                                                <div className="div_item_column" onClick={(e) => onClickSelectDipendente(e, dipendente._id)}>
                                                                    <div className="item_table">{dipendente.sussidiari ? dipendente.nomeSussidiari ? dipendente.nomeSussidiari.slice(0, 3) : '' : ''}</div>
                                                                </div>
                                                                <div className="div_item_column" onClick={(e) => onClickSelectDipendente(e, dipendente._id)}>
                                                                    <div className="item_table">{dipendente && dipendente.cognome} {dipendente && dipendente.nome}</div>
                                                                </div>
                                                                <div className={`div_item_column${checkResOrd(dipendente.calcoli.resOrd) ? " orange" : ""}`} onClick={(e) => onClickSelectDipendente(e, dipendente._id)}>
                                                                    <div className="item_table">{dipendente.calcoli.resOrd}</div>
                                                                </div>
                                                                <div className="div_item_column" onClick={(e) => onClickSelectDipendente(e, dipendente._id)}>
                                                                    <div className="item_table">{dipendente.calcoli.resStr}</div>
                                                                </div>
                                                                <div className="div_item_column" onClick={(e) => onClickSelectDipendente(e, dipendente._id)}>
                                                                    <div className="item_table">{dipendente.calcoli.assOrd}</div>
                                                                </div>
                                                                <div className="div_item_column" onClick={(e) => onClickSelectDipendente(e, dipendente._id)}>
                                                                    <div className="item_table">{dipendente.calcoli.assStr}</div>
                                                                </div>
                                                                {cambiaDisponibilitaOpen && dipendenteSelected == dipendente._id ?
                                                                    <div className="div_item_column">
                                                                        <select className="form-control select" name="idPuntoVendita" onChange={e => onChangeSetDisponibilita(e)} required>
                                                                            {disponibilitaJSON.disponibilita.map((el) => (
                                                                                <option selected={el.codice == disponibilitaSelected} value={el.codice}>{el.codice} - {el.nome}</option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                    :
                                                                    <div className="div_item_column" onClick={(e) => onClickSelectDipendente(e, dipendente._id)}>
                                                                        <div className="item_table">{dipendente.calcoli.disponibilita}</div>
                                                                    </div>
                                                                }
                                                                <div className="div_item_column" onClick={(e) => onClickSelectDipendente(e, dipendente._id)}>
                                                                    <div className="item_table">{dipendente.bancaOreProgressivo && dipendente.bancaOreProgressivo}</div>
                                                                </div>
                                                                <div className="div_item_column" onClick={(e) => onClickSelectDipendente(e, dipendente._id)}>
                                                                    <div className="item_table">{dipendente.calcoli.ferie}</div>
                                                                </div>
                                                                <div className="div_item_column" onClick={(e) => onClickSelectDipendente(e, dipendente._id)}>
                                                                    <div className="item_table">{dipendente.calcoli.permessi}</div>
                                                                </div>
                                                                <div className="div_item_column" onClick={(e) => onClickSelectDipendente(e, dipendente._id)}>
                                                                    <div className="item_table">{dipendente.calcoli.oreMensili}</div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="item_4 m_top_5">
                                                <div className="item_4_function">
                                                    <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc3d-867f153d" className="div_item_button">
                                                        <div id="w-node-_7ae3fa04-88bc-8c3b-7fdb-28ba347410f1-867f153d" className="form-block w-form">
                                                            <form id="email-form-2" name="email-form-2" data-name="Email Form 2" method="get">
                                                                <label className="w-radio">
                                                                    <input type="radio" data-name="Radio" id="radio" name="radio" value="disponibili" className="w-form-formradioinput w-radio-input" onChange={e => onChangeSetCheckboxFiltraDisponibili(e)} checked={checkBoxFiltraDisponibili === 'disponibili' ? true : false} />
                                                                    <span className="label_text w-form-label" htmlFor="radio">Disponibili</span>
                                                                </label>
                                                                <label className="w-radio">
                                                                    <input type="radio" data-name="Radio 2" id="radio-2" name="radio" value="tutti" className="w-form-formradioinput w-radio-input" onChange={e => onChangeSetCheckboxFiltraDisponibili(e)} checked={checkBoxFiltraDisponibili === 'tutti' ? true : false} />
                                                                    <span className="label_text w-form-label" htmlFor="radio-2">Tutti</span>
                                                                </label>
                                                            </form>
                                                        </div>
                                                    </div>

                                                    <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc40-867f153d" className="div_item_empty"></div>
                                                    {dipendenteSelected && !cambiaDisponibilitaOpen && quadranteSelected.stato != 'nascosta' && quadranteSelected.stato != 'archiviata' ?
                                                        <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc43-867f153d" className="div_item_button">
                                                            <a id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc44-867f153d" onClick={() => onClickAssociaRichiestaWithCheck(dipendenteSelected)} className="button w-button">Associa</a>
                                                        </div>
                                                        : dipendenteSelected && cambiaDisponibilitaOpen ?
                                                            <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc43-867f153d" className="div_item_button">
                                                                <a id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc44-867f153d" onClick={() => setCambiaDisponibilitaOpen(false)} className="button w-button">Annulla modifica</a>
                                                            </div>
                                                            :
                                                            <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc40-867f153d" className="div_item_empty"></div>
                                                    }
                                                    {dipendenteSelected && !cambiaDisponibilitaOpen ?
                                                        <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc43-867f153d" className="div_item_button">
                                                            <a id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc44-867f153d" onClick={() => setCambiaDisponibilitaOpen(true)} className="button w-button">Modifica disponibilità</a>
                                                        </div>
                                                        : dipendenteSelected && cambiaDisponibilitaOpen ?
                                                            <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc43-867f153d" className="div_item_button">
                                                                <a id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc44-867f153d" onClick={() => onClickSalvaDisponibilita()} className="button w-button">Salva</a>
                                                            </div>
                                                            :
                                                            <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc40-867f153d" className="div_item_empty"></div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                </div>
                </div>
                <div className="div_wrap_100">
                <div className="div_wrap_50">
                            <div className="div_container">
                                <div className="title_window">
                                    <div className="title_window_p">Incarichi dipendente selezionato</div>
                                </div>
                                <div className="dati_container">
                                    <div className="fun_assegnazione_incarichi">
                                        <div className="row">
                                            <div className="item_2">
                                                <div className="intestazione_table">
                                                    <div className="w-layout-grid div_column_incarichi_dipendente intestazione">
                                                        <div className="div_item_column">
                                                            <div className="item_table">Data</div>
                                                        </div>
                                                        <div className="div_item_column">
                                                            <div className="item_table">Disp.</div>
                                                        </div>
                                                        <div className="div_item_column">
                                                            <div className="item_table">Eff.</div>
                                                        </div>
                                                        <div className="div_item_column">
                                                            <div className="item_table">Servizio</div>
                                                        </div>
                                                        <div className="div_item_column">
                                                            <div className="item_table">Dalle</div>
                                                        </div>
                                                        <div className="div_item_column">
                                                            <div className="item_table">Alle</div>
                                                        </div>
                                                        <div className="div_item_column">
                                                            <div className="item_table">Ore fatte</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {waitingTurniDipendente ? <Spinner /> : dipendenteSelected && incarichiDipendente && incarichiDipendente.incarichi && incarichiDipendente.incarichi.length >= 0 &&  dipendenteSelected.length > 0 ?
                                                    <div className="body_table">
                                                        {incarichiDipendente.incarichi.map((el, i) => (
                                                            <div key={"contratto" + i} className={`w-layout-grid div_column_incarichi_dipendente cursor ${el.stato == "archiviata" ? "green_label" : ''}`}>
                                                                <div className="div_item_column">
                                                                    <div className="item_table"><Moment format='DD/MM/YYYY'>{el.data}</Moment></div>
                                                                </div>
                                                                <div className="div_item_column" >
                                                                    <div className="item_table">{el.disponibilita}</div>
                                                                </div>
                                                                <div className="div_item_column">
                                                                    <div className="item_table">{el.effettivo}</div>
                                                                </div>
                                                                <div className="div_item_column">
                                                                    <div className="item_table">{el.servizio}{el.localita ? " - " + el.localita : ""}</div>
                                                                </div>
                                                                <div className="div_item_column">
                                                                    <div className="item_table">{el.oraInizio}</div>
                                                                </div>
                                                                <div className="div_item_column">
                                                                    <div className="item_table">{el.oraFine}</div>
                                                                </div>
                                                                <div className="div_item_column">
                                                                    <div className="item_table" >{el.ore}</div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    :
                                                    ''
                                                }
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
                <div className="div_wrap_100">
                    <div className="div_wrap_50">
                        <div className="page">
                            <div className="div_container">
                                <div className="title_window">
                                    <div className="title_window_p">Dipendenti non assegnati</div>
                                </div>
                                <div className="dati_container">
                                    <div className="fun_assegnazione_incarichi">
                                        <div className="row">
                                            <div className="item_2">
                                                <div className="intestazione_table">
                                                    <div className="w-layout-grid div_column_dipendenti_liberi intestazione">
                                                        <div className="div_item_column">
                                                            <div className="item_table">Dis.</div>
                                                        </div>
                                                        <div className="div_item_column">
                                                            <div className="item_table">Qualifica</div>
                                                        </div>
                                                        <div className="div_item_column">
                                                            <div className="item_table">Cognome</div>
                                                        </div>
                                                        <div className="div_item_column">
                                                            <div className="item_table">Nome</div>
                                                        </div>
                                                        <div className="div_item_column">
                                                            <div className="item_table"></div>
                                                        </div>
                                                        <div className="div_item_column">
                                                            <div className="item_table"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {loadingDipendentiLiberi ? <Spinner /> :
                                                    <div className="body_table">
                                                        {dipendentiLiberi.map((dip, i) => (
                                                            <div key={"dipendente-libero" + i} className={`w-layout-grid div_column_dipendenti_liberi cursor ${dipendenteNonAssegnatoSelected == dip._id ? 'dipendente_selected' : ""}`}>
                                                                {cambiaDisponibilitaNonAssegnatoSelectedOpen && dipendenteNonAssegnatoSelected == dip._id ?
                                                                    <div className="div_item_column" onClick={(e) => onClickSelectDipendenteNonAssegnato(dip._id)}>
                                                                        <select className="form-control select" name="idPuntoVendita" onChange={e => onChangeSetDisponibilitaNonAssegnati(e)} required>
                                                                            {disponibilitaJSON.disponibilita.map((el) => (
                                                                                <option selected={el.codice == dip.disponibilita} value={el.codice}>{el.codice} - {el.nome}</option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                    :
                                                                    <div className="div_item_column" onClick={(e) => onClickSelectDipendenteNonAssegnato(dip._id)}>
                                                                        <div className="item_table">{dip.disponibilita}</div>
                                                                    </div>
                                                                }
                                                                <div className="div_item_column" onClick={(e) => onClickSelectDipendenteNonAssegnato(dip._id)}>
                                                                    <div className="item_table">{dip.qualifica}</div>
                                                                </div>
                                                                <div className="div_item_column" onClick={(e) => onClickSelectDipendenteNonAssegnato(dip._id)}>
                                                                    <div className="item_table">{dip.cognome}</div>
                                                                </div>
                                                                <div className="div_item_column" onClick={(e) => onClickSelectDipendenteNonAssegnato(dip._id)}>
                                                                    <div className="item_table">{dip.nome}</div>
                                                                </div>
                                                                <div className="div_item_column" onClick={(e) => onClickSelectDipendenteNonAssegnato(dip._id)}>
                                                                    <div className="item_table"></div>
                                                                </div>
                                                                <div className="div_item_column" onClick={(e) => onClickSelectDipendenteNonAssegnato(dip._id)}>
                                                                    <div className="item_table"></div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                }
                                                <div className="row hide-element">
                                                    <div className="item_4 m_top_5">
                                                        <div className="item_4_function">
                                                            <div id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98d-867f153d" className="div_item_button">
                                                                <a id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98e-867f153d" className={`button w-button ${dipendentiLiberi.length == 0 ? "button_disabled" : ""}`} onClick={() => print()}>Stampa</a>
                                                            </div>
                                                            {dipendenteNonAssegnatoSelected && !cambiaDisponibilitaNonAssegnatoSelectedOpen ?
                                                                <>
                                                                    <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc43-867f153d" className="div_item_button">
                                                                        <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc40-867f153d" className="div_item_empty"></div>
                                                                    </div>
                                                                    <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc43-867f153d" className="div_item_button">
                                                                        <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc40-867f153d" className="div_item_empty"></div>
                                                                    </div>
                                                                    <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc43-867f153d" className="div_item_button">
                                                                        <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc40-867f153d" className="div_item_empty"></div>
                                                                    </div>
                                                                    <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc43-867f153d" className="div_item_button">
                                                                        <a id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc44-867f153d" onClick={() => setCambiaDisponibilitaNonAssegnatoSelectedOpen(true)} className="button w-button">Modifica disponibilità</a>
                                                                    </div>
                                                                </>
                                                                : dipendenteNonAssegnatoSelected && cambiaDisponibilitaNonAssegnatoSelectedOpen ?
                                                                    <>
                                                                        <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc43-867f153d" className="div_item_button">
                                                                            <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc40-867f153d" className="div_item_empty"></div>
                                                                        </div>
                                                                        <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc43-867f153d" className="div_item_button">
                                                                            <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc40-867f153d" className="div_item_empty"></div>
                                                                        </div>
                                                                        <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc43-867f153d" className="div_item_button">
                                                                            <a id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc44-867f153d" onClick={() => onClickSalvaDisponibilitaNonAssegnati()} className="button w-button">Salva</a>
                                                                        </div>
                                                                        <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc43-867f153d" className="div_item_button">
                                                                            <a id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc44-867f153d" onClick={() => setCambiaDisponibilitaNonAssegnatoSelectedOpen(false)} className="button w-button">Chiudi</a>
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc40-867f153d" className="div_item_empty"></div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="div_container font-bold hide" ref={componentRef} >
                                                        <div className="dati_container">
                                                            <div className="page">
                                                                <h5>Dipendenti non assegnati</h5>
                                                                <table style={tableStyle}>
                                                                    <thead>
                                                                        <tr style={tableInt1}>
                                                                            <th style={tableStyle1}>Qualifica</th>
                                                                            <th style={tableStyle1}>Dipendente</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {dipendentiLiberi.map((elemento, i) => (
                                                                            <>
                                                                                <tr style={tableStyle2}>
                                                                                    <td style={tableStyle3}>{elemento.qualifica}</td>
                                                                                    <td style={tableStyle3}>{elemento.cognome} {elemento.nome}</td>
                                                                                </tr>
                                                                            </>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="div_wrap_50">
                            <div className="div_container">
                                <div className="title_window">
                                    <div className="title_window_p">Dipendenti prestabili</div>
                                </div>
                                <div className="dati_container">
                                    <div className="fun_assegnazione_incarichi">
                                        <div className="row">
                                            <div className="item_2">
                                                <div className="intestazione_table">
                                                    <div className="w-layout-grid div_column_assegnazioni_dipendenti_prestati intestazione">
                                                        <div className="div_item_column">
                                                            <div className="item_table">Qual.</div>
                                                        </div>
                                                        <div className="div_item_column">
                                                            <div className="item_table">Sus.</div>
                                                        </div>
                                                        <div className="div_item_column">
                                                            <div className="item_table">Filiale</div>
                                                        </div>
                                                        <div className="div_item_column">
                                                            <div className="item_table">Dipendente</div>
                                                        </div>
                                                        <div className="div_item_column">
                                                            <div className="item_table">Res.Ord</div>
                                                        </div>
                                                        <div className="div_item_column">
                                                            <div className="item_table">Res.Str</div>
                                                        </div>
                                                        <div className="div_item_column">
                                                            <div className="item_table">Ass.Ord</div>
                                                        </div>
                                                        <div className="div_item_column">
                                                            <div className="item_table">Ass.Str</div>
                                                        </div>
                                                        <div className="div_item_column">
                                                            <div className="item_table">Dis</div>
                                                        </div>
                                                        <div className="div_item_column">
                                                            <div className="item_table">B.Ore</div>
                                                        </div>
                                                        <div className="div_item_column">
                                                            <div className="item_table">Ferie</div>
                                                        </div>
                                                        <div className="div_item_column">
                                                            <div className="item_table">Perm</div>
                                                        </div>
                                                        <div className="div_item_column">
                                                            <div className="item_table">Ore mese</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {loadingDipendentiPrestati ? <Spinner /> :
                                                    <div className="body_table_2">
                                                        {dipendentiPrestati.map((dipendente, i) => (
                                                            <div key={"dipendente-da-assegnare" + i} className={`w-layout-grid div_column_assegnazioni_dipendenti_prestati cursor ${dipendenteSelected == dipendente._id ? 'dipendente_selected' : dipendente.calcoli.occupato ? "red_label" : ""}`}>
                                                                <div className="div_item_column" onClick={(e) => onClickSelectDipendente(e, dipendente._id)}>
                                                                    <div className="item_table">{dipendente.qualifica}</div>
                                                                </div>
                                                                <div className="div_item_column" onClick={(e) => onClickSelectDipendente(e, dipendente._id)}>
                                                                    <div className="item_table">{dipendente.sussidiari ? dipendente.nomeSussidiari ? dipendente.nomeSussidiari.slice(0, 3) : '' : ''}</div>
                                                                </div>
                                                                <div className="div_item_column" onClick={(e) => onClickSelectDipendente(e, dipendente._id)}>
                                                                    <div className="item_table">{dipendente && dipendente.filiale && dipendente.filiale.nome}</div>
                                                                </div>
                                                                <div className="div_item_column" onClick={(e) => onClickSelectDipendente(e, dipendente._id)}>
                                                                    <div className="item_table">{dipendente && dipendente.cognome} {dipendente && dipendente.nome}</div>
                                                                </div>
                                                                <div className="div_item_column" onClick={(e) => onClickSelectDipendente(e, dipendente._id)}>
                                                                    <div className="item_table">{dipendente.calcoli.resOrd}</div>
                                                                </div>
                                                                <div className="div_item_column" onClick={(e) => onClickSelectDipendente(e, dipendente._id)}>
                                                                    <div className="item_table">{dipendente.calcoli.resStr}</div>
                                                                </div>
                                                                <div className="div_item_column" onClick={(e) => onClickSelectDipendente(e, dipendente._id)}>
                                                                    <div className="item_table">{dipendente.calcoli.assOrd}</div>
                                                                </div>
                                                                <div className="div_item_column" onClick={(e) => onClickSelectDipendente(e, dipendente._id)}>
                                                                    <div className="item_table">{dipendente.calcoli.assStr}</div>
                                                                </div>
                                                                {cambiaDisponibilitaOpen && dipendenteSelected == dipendente._id ?
                                                                    <div className="div_item_column">
                                                                        <select className="form-control select" name="idPuntoVendita" onChange={e => onChangeSetDisponibilita(e)} required>
                                                                            {disponibilitaJSON.disponibilita.map((el) => (
                                                                                <option selected={el.codice == disponibilitaSelected} value={el.codice}>{el.codice} - {el.nome}</option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                    :
                                                                    <div className="div_item_column" onClick={(e) => onClickSelectDipendente(e, dipendente._id)}>
                                                                        <div className="item_table">{dipendente.calcoli.disponibilita}</div>
                                                                    </div>
                                                                }
                                                                <div className="div_item_column" onClick={(e) => onClickSelectDipendente(e, dipendente._id)}>
                                                                    <div className="item_table">{dipendente.bancaOreProgressivo && dipendente.bancaOreProgressivo}</div>
                                                                </div>
                                                                <div className="div_item_column" onClick={(e) => onClickSelectDipendente(e, dipendente._id)}>
                                                                    <div className="item_table">{dipendente.calcoli.ferie}</div>
                                                                </div>
                                                                <div className="div_item_column" onClick={(e) => onClickSelectDipendente(e, dipendente._id)}>
                                                                    <div className="item_table">{dipendente.calcoli.permessi}</div>
                                                                </div>
                                                                <div className="div_item_column" onClick={(e) => onClickSelectDipendente(e, dipendente._id)}>
                                                                    <div className="item_table">{dipendente.calcoli.oreMensili}</div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="item_4 m_top_5">
                                                <div className="item_4_function">
                                                    <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc3d-867f153d" className="div_item_button">
                                                        <div id="w-node-_7ae3fa04-88bc-8c3b-7fdb-28ba347410f1-867f153d" className="form-block w-form">

                                                        </div>
                                                    </div>
                                                    <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc40-867f153d" className="div_item_empty"></div>
                                                    <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc40-867f153d" className="div_item_empty"></div>
                                                    <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc40-867f153d" className="div_item_empty"></div>
                                                    {dipendenteSelected && !cambiaDisponibilitaOpen && quadranteSelected.stato != 'nascosta' && quadranteSelected.stato != 'archiviata' ?
                                                        <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc43-867f153d" className="div_item_button">
                                                            <a id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc44-867f153d" onClick={() => onClickAssociaRichiestaPrestatoWithCheck(dipendenteSelected)} className="button w-button">Associa</a>
                                                        </div>
                                                        : dipendenteSelected && cambiaDisponibilitaOpen ?
                                                            <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc43-867f153d" className="div_item_button">
                                                                <a id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc44-867f153d" onClick={() => setCambiaDisponibilitaOpen(false)} className="button w-button">Annulla modifica</a>
                                                            </div>
                                                            :
                                                            <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc40-867f153d" className="div_item_empty"></div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
        </div>
    )
}

RichiesteCalendario.protoTypes = {
    getContrattiRichiestePuntiVenditaCalendario: PropTypes.func.isRequired,
    getDipendentiCalendarioContratto: PropTypes.func.isRequired,
    cleanDipendenti: PropTypes.func.isRequired,
    associaDipendenteRichiestaCalendario: PropTypes.func.isRequired,
    dissociaDipendenteRichiestaCalendario: PropTypes.func.isRequired,
    getStateDipendentiContrattoJob: PropTypes.func.isRequired,
    getStateContrattiRichiestePuntiVenditaCalendario: PropTypes.func.isRequired,
    archiviaRichieste: PropTypes.func.isRequired,
    aggiungiGiornoEccezione: PropTypes.func.isRequired,
    cleanCalendarioRichieste: PropTypes.func.isRequired,
    modificaOrarioRichiestaCalendario: PropTypes.func.isRequired,
    cleanRichieste: PropTypes.func.isRequired,
    cleanRichiesteArchiviate: PropTypes.func.isRequired,
    cleanGiorniEccezione: PropTypes.func.isRequired,
    nascondiContrattoCalendario: PropTypes.func.isRequired,
    ripristinaContrattoNascostoCalendario: PropTypes.func.isRequired,
    annullaArchiviaRichieste: PropTypes.func.isRequired,
    eliminaGiornoEccezioneLungoRichieste: PropTypes.func.isRequired,
    togliFerieAggiungiGiornoEccezione: PropTypes.func.isRequired,
    getGiorniEccezioneCalendario: PropTypes.func.isRequired,
    aggiungiServizioSporadico: PropTypes.func.isRequired,
    duplicaServizioCalendario: PropTypes.func.isRequired,
    getIncarichiDipendente: PropTypes.func.isRequired,
    cleanIncarichiDipendente: PropTypes.func.isRequired,
    getContrattiPuntoVendita: PropTypes.func.isRequired,
    confermaGiorniEccezione: PropTypes.func.isRequired,
    getDipendentiPrestabiliOccupati: PropTypes.func.isRequired,
    richiesta: PropTypes.object.isRequired,
    puntoVendita: PropTypes.object.isRequired,
    dipendente: PropTypes.object.isRequired,
    alert: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    richiesta: state.richiesta,
    puntoVendita: state.puntoVendita,
    cliente: state.cliente,
    dipendente: state.dipendente,
    alert: state.alert,
    auth: state.auth
});

export default connect(mapStateToProps, { getContrattiRichiestePuntiVenditaCalendario, getDipendentiCalendarioContratto, cleanDipendenti, associaDipendenteRichiestaCalendario, dissociaDipendenteRichiestaCalendario, getStateDipendentiContrattoJob, getStateContrattiRichiestePuntiVenditaCalendario, archiviaRichieste, aggiungiGiornoEccezione, cleanCalendarioRichieste, modificaOrarioRichiestaCalendario, cleanRichieste, cleanRichiesteArchiviate, cleanGiorniEccezione, nascondiContrattoCalendario, ripristinaContrattoNascostoCalendario, annullaArchiviaRichieste, getGiorniEccezioneCalendario, aggiungiServizioSporadico, duplicaServizioCalendario, getIncarichiDipendente, cleanIncarichiDipendente, getContrattiPuntoVendita, confermaGiorniEccezione, getDipendentiPrestabiliOccupati, eliminaGiornoEccezioneLungoRichieste, togliFerieAggiungiGiornoEccezione })(RichiesteCalendario);