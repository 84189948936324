import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import DatePicker from 'react-date-picker';
import { creaNuovoPuntoVendita } from '../../../actions/puntoVendita';
import { getClienti } from '../../../actions/cliente';
import { getDipendenti } from '../../../actions/dipendente';
import zoneResidenzaJSON from "../../../utils/zone_residenza.json";
import raggruppamentiJSON from "../../../utils/raggruppamenti.json";
import indennitaDiFunzioneJSON from "../../../utils/indennitaDiFunzione.json";
import serviziEffJSON from "../../../utils/serviziEff.json";
const NuovoPuntoVendita = ({ setOpenNuovoPuntoServizio, creaNuovoPuntoVendita, alert, getClienti, cliente: { clienti }, getDipendenti, dipendente: { dipendenti } }) => {

    useEffect(() => {
        getClienti();
    }, [getClienti]);


    useEffect(() => {
        const size = alert.length;
        if (size > 0) {
            if (alert[size - 1].alertType === "success") {
                setOpenNuovoPuntoServizio(false);
                setDatiPuntoServizio(puntoVenditaInitialState);
            }
        }
    }, [alert]);

    useEffect(() => {
        getDipendenti(0, 10, false);
    }, []);


    const contrattoObj = {
        codice: "",
        dataInizio: null,
        dataFine: null,
        oraInizio: '',
        oraFine: '',
        cadenza: {
            lunedi: false,
            martedi: false,
            mercoledi: false,
            giovedi: false,
            venerdi: false,
            sabato: false,
            domenica: false,
            festivi: false,
            semifestivi: false
        },
        puntoVendita: "",
        nuovo: true,
        continuativo: false,
        riferitoGiornoDopo: false,
        dipendentiSuggeriti: []
    }

    const puntoVenditaInitialState = {
        denominazione: '',
        idZonaRes: '',
        idServiziEff: '',
        idIndennitaDiFunz: '',
        qualifica: '',
        indirizzo: '',
        cap: '',
        localita: '',
        provincia: '',
        telefono: '',
        email: '',
        idCliente: null,
        contratti: [contrattoObj]
    }

    

    const [datiPuntoServizio, setDatiPuntoServizio] = useState(puntoVenditaInitialState);
    const [zonaResidenzaSelected, setZonaResidenzaSelected] = useState(null);
    const [raggruppamentoSelected, setRaggruppamentoSelected] = useState(null);
    const [serviziEffSelected, setServiziEffSelected] = useState(null);
    const [qualificaSelected, setQualificaSelected] = useState(null);


    const { denominazione, idZonaRes, idServiziEff, idIndennitaDiFunz, idRaggruppa, qualifica, indirizzo, cap, localita, provincia, telefono, email, idCliente, contratti } = datiPuntoServizio;


    const onChangeSetDatiPuntoServizio = e => setDatiPuntoServizio({ ...datiPuntoServizio, [e.target.name]: e.target.value });

    const onSubmitCreaPuntoServizio = async e => {
        e.preventDefault();
        creaNuovoPuntoVendita({ denominazione, idZonaRes, idServiziEff, idIndennitaDiFunz, idRaggruppa, qualifica, indirizzo, cap, localita, provincia, telefono, email, idCliente, contratti });
    };

    const onChangeSetZonaResidenza = (e) => {
        setDatiPuntoServizio({ ...datiPuntoServizio, idZonaRes: e.target.value })
    }

    const onChangeSetRaggruppamento = (e) => {
        setDatiPuntoServizio({ ...datiPuntoServizio, idRaggruppa: e.target.value })
    }

    const onChangeSetServiziEff = (e) => {
        setDatiPuntoServizio({ ...datiPuntoServizio, idServiziEff: e.target.value })
    }

    const onChangeSetQualifica = (e) => {
        setDatiPuntoServizio({ ...datiPuntoServizio, qualifica: e.target.value })
    }


    const onChangeSetContrattoPuntoVendita = (e, i) => {
        let arrayContratti = contratti;
        if (e.target.name === 'dataInizio' || e.target.name === 'dataFine') {
            arrayContratti[i][e.target.name] = new Date(e.target.value);
        } else if (e.target.type === 'checkbox') {
            if(e.target.name == "continuativo"){
                if(e.target.value === 'false'){
                    arrayContratti[i].cadenza = {
                        lunedi: true,
                        martedi: true,
                        mercoledi: true,
                        giovedi: true,
                        venerdi: true,
                        sabato: true,
                        domenica: true,
                        festivi: true,
                        semifestivi: true
                    }
                }
                arrayContratti[i].continuativo = e.target.value === 'false' ? true : false;
            }else{
                arrayContratti[i].cadenza[e.target.name] = e.target.value === 'false' ? true : false;
            }
        } else {
            arrayContratti[i][e.target.name] = e.target.value;
        }
        setDatiPuntoServizio({ ...datiPuntoServizio, contratti: arrayContratti })
    };

    const onChangeRiferitoGiornoDopo = (e, i) => {
        let arrayContratti = contratti;
        arrayContratti[i].riferitoGiornoDopo = e.target.value === 'false' ? true : false;
        setDatiPuntoServizio({ ...datiPuntoServizio, contratti: arrayContratti })
    }

    const addContrattoRow = () => {
        let arrayContratti = contratti.concat(contrattoObj);
        setDatiPuntoServizio({ ...datiPuntoServizio, contratti: arrayContratti })
    };

    const deleteContrattoRow = (i) => {
        let arrayContratti = contratti;
        arrayContratti.splice(i, 1);
        setDatiPuntoServizio({ ...datiPuntoServizio, contratti: arrayContratti })
    };

    const onChangeSetDipendente = (e, i) => {
        let arrayContratti = contratti;
        let arrayDipendentiSuggeriti = [...arrayContratti[i].dipendentiSuggeriti]
        let indexDipendenteSuggerito = dipendenti.findIndex(el => el._id == e.target.value);
        if(indexDipendenteSuggerito > -1){
            let indexDipendenteSuggeritoEsistente = arrayDipendentiSuggeriti.findIndex(el => el._id == dipendenti[indexDipendenteSuggerito]._id);
            if(indexDipendenteSuggeritoEsistente == -1){
                arrayDipendentiSuggeriti.push(dipendenti[indexDipendenteSuggerito]);
            }
        }
        arrayContratti[i].dipendentiSuggeriti = arrayDipendentiSuggeriti
        setDatiPuntoServizio({ ...datiPuntoServizio, contratti: arrayContratti })
    }

    const onClickDeleteDipendenteSuggerito = (e, i) => {
        let arrayContratti = contratti;
        let arrayDipendentiSuggeriti = [...arrayContratti[i].dipendentiSuggeriti]
        arrayDipendentiSuggeriti.splice(i, 1);
        arrayContratti[i].dipendentiSuggeriti = arrayDipendentiSuggeriti
        setDatiPuntoServizio({ ...datiPuntoServizio, contratti: arrayContratti })
    }

    return (
        <Fragment>
            <div className="dati_container">
                <div className="fun_assegnazione_incarichi">
                    <div className="row">
                        <div className="item_2">
                            <h4>Crea nuovo punto di servizio</h4>
                            <div>
                                <div className="w-form">
                                    <form id="nuovo_punto_servizio" name="email-form-2" data-name="Email Form 2" method="get" aria-label="Email Form 2">
                                        <div className="div_wrap_100">
                                            <div className="div_wrap_25">
                                                <div className="col_input">
                                                    <label htmlFor="name">Denominazione</label>
                                                    <input type="text" className="input w-input" maxLength="256" name="denominazione" value={denominazione} data-name="denominazione" placeholder="Inserisci denominazione" id="denominazione" onChange={e => onChangeSetDatiPuntoServizio(e)} />
                                                </div>
                                            </div>
                                            <div className="div_wrap_25">
                                                <div className="col_input col_select">
                                                    <label htmlFor="name">Cliente</label>
                                                    <div data-hover="false" data-delay="0" className="select w-dropdown">
                                                        <select className="form-control select" name="idCliente" onChange={e => onChangeSetDatiPuntoServizio(e)} required>
                                                            <option selected value={null} disabled >Seleziona cliente...</option>
                                                            {clienti.map((cliente, i) => (
                                                                <option key={i} value={cliente._id}>{cliente.ragioneSoc}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="div_wrap_25">
                                                <div className="col_input">
                                                </div>
                                            </div>
                                            <div className="div_wrap_25">
                                                <div className="col_input">
                                                </div>
                                            </div> 
                                        </div>
                                        <div className="div_wrap_100">
                                            <div className="div_wrap_25">
                                                <div className="col_input">
                                                    <label htmlFor="name">Indirizzo</label>
                                                    <input type="text" className="input w-input" maxLength="256" name="indirizzo" data-name="indirizzo" value={indirizzo} placeholder="Inserisci indirizzo" id="indirizzo" onChange={e => onChangeSetDatiPuntoServizio(e)} />
                                                </div>
                                            </div>
                                            <div className="div_wrap_25">
                                                <div className="col_input">
                                                    <label htmlFor="name">Provincia</label>
                                                    <input type="text" className="input w-input" maxLength="256" name="provincia" data-name="provincia" value={provincia} placeholder="Inserisci provincia" id="provincia" onChange={e => onChangeSetDatiPuntoServizio(e)} />
                                                </div>
                                            </div>
                                            <div className="div_wrap_25">
                                                <div className="col_input">
                                                    <label htmlFor="name">CAP</label>
                                                    <input type="text" className="input w-input" maxLength="256" name="cap" data-name="cap" value={cap} placeholder="Inserisci cap" id="cap" onChange={e => onChangeSetDatiPuntoServizio(e)} />
                                                </div>
                                            </div>
                                            <div className="div_wrap_25">
                                                <div className="col_input">
                                                    <label htmlFor="name">Località</label>
                                                    <input type="text" className="input w-input" maxLength="256" name="localita" data-name="localita" value={localita} placeholder="Inserisci località" id="localita" onChange={e => onChangeSetDatiPuntoServizio(e)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="div_wrap_100">
                                            <div className="div_wrap_25">
                                                <div className="col_input col_select">
                                                    <label htmlFor="name">Zona residenza</label>
                                                    <div data-hover="false" data-delay="0" className="select w-dropdown">
                                                        <select className="form-control select" name="idZonaRes" onChange={e => onChangeSetDatiPuntoServizio(e)} required>
                                                            <option selected value={null} disabled >Seleziona zona residenza...</option>
                                                            {zoneResidenzaJSON.zoneResidenza.map((el) => (
                                                                <option selected={el.id == idZonaRes} value={el.id}>{el.codice} - {el.nome}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="div_wrap_25">
                                                <div className="col_input col_select">
                                                    <label htmlFor="name">Raggruppamento</label>
                                                    <div data-hover="false" data-delay="0" className="select w-dropdown">
                                                        <select className="form-control select" name="idRaggruppa" onChange={e => onChangeSetDatiPuntoServizio(e)} required>
                                                            <option selected value={null} disabled >Seleziona raggruppamento...</option>
                                                            {raggruppamentiJSON.raggruppamenti.map((el) => (
                                                                <option selected={el.id == idRaggruppa} value={el.id}>{el.codice} - {el.nota}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="div_wrap_25">
                                                <div className="col_input col_select">
                                                    <label htmlFor="name">Servizio eff</label>
                                                    <div data-hover="false" data-delay="0" className="select w-dropdown">
                                                        <select className="form-control select" name="idServiziEff" onChange={e => onChangeSetDatiPuntoServizio(e)} required>
                                                            <option selected value={null} disabled >Seleziona servizi eff...</option>
                                                            {serviziEffJSON.serviziEff.map((el) => (
                                                                <option selected={el.id == idServiziEff} value={el.id}>{el.nome}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="div_wrap_25">
                                                <div className="col_input col_select">
                                                    <label htmlFor="name">Qualifica</label>
                                                    <div data-hover="false" data-delay="0" className="select w-dropdown">
                                                        <select className="form-control select" name="qualifica" onChange={e => onChangeSetDatiPuntoServizio(e)} required>
                                                            <option selected value={null} disabled >Seleziona qualifica...</option>
                                                                <option selected={'ots' == qualifica} value={'ots'}>OTS</option>
                                                                <option selected={'gpg' == qualifica} value={'gpg'}>GPG</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="div_wrap_100">
                                            <div className="div_wrap_25">
                                                <div className="col_input col_select">
                                                    <label htmlFor="name">Indennità di funzione</label>
                                                    <div data-hover="false" data-delay="0" className="select w-dropdown">
                                                        <select className="form-control select" name="idIndennitaDiFunz" onChange={e => onChangeSetDatiPuntoServizio(e)} required>
                                                            <option selected value={''} >Nessuna</option>
                                                            {indennitaDiFunzioneJSON.indennitaDiFunzione.map((el) => (
                                                                <option selected={el.id == idIndennitaDiFunz} value={el.id}>{el.nome}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="div_wrap_100">
                                            <div className="div_wrap_50">
                                                <div className="col_input">
                                                    <label htmlFor="name">Email</label>
                                                    <input type="text" className="input w-input" maxLength="256" name="email" data-name="email" value={email} placeholder="Inserisci email" id="email" onChange={e => onChangeSetDatiPuntoServizio(e)} />
                                                </div>
                                            </div>
                                            <div className="div_wrap_50">
                                                <div className="col_input">
                                                    <label htmlFor="name">Telefono</label>
                                                    <input type="text" className="input w-input" maxLength="256" name="telefono" data-name="telefono" value={telefono} placeholder="Inserisci telefono" id="telefono" onChange={e => onChangeSetDatiPuntoServizio(e)} />
                                                </div>
                                            </div>
                                        </div>
                                            {contratti.map((contratto, i) => (
                                                    <Fragment>
                                                        <div className="div_wrap_100 m_bottom_30">
                                                            <div className="div_wrap_25">
                                                                <div className="col_input"><label htmlFor="name-11">Data inizio</label>
                                                                    <div data-hover="false" data-delay="0" className="select w-dropdown select_data">
                                                                        <DatePicker
                                                                            onChange={(value) => onChangeSetContrattoPuntoVendita({ target: { value: value, name: 'dataInizio' } }, i)}
                                                                            value={contratto.dataInizio ? new Date(contratto.dataInizio) : null}
                                                                            calendarIcon={null}
                                                                            clearIcon={null}
                                                                            className={'form-control'}
                                                                        //disableCalendar={true}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="div_wrap_25">
                                                                <div className="col_input"><label htmlFor="name-11">Data fine</label>
                                                                    <div data-hover="false" data-delay="0" className="select w-dropdown select_data">
                                                                        <DatePicker
                                                                            onChange={(value) => onChangeSetContrattoPuntoVendita({ target: { value: value, name: 'dataFine' } }, i)}
                                                                            value={contratto.dataFine ? new Date(contratto.dataFine) : null}
                                                                            calendarIcon={null}
                                                                            clearIcon={null}
                                                                            className={'form-control'}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="div_wrap_25">
                                                                <div className="col_input"><label htmlFor="name-11">Orario inizio</label>
                                                                    <input className="input w-input" type="time" value={contratto.oraInizio} name="oraInizio" id={"oraInizio-" + { i }} onChange={e => onChangeSetContrattoPuntoVendita(e, i)} />
                                                                </div>
                                                            </div>
                                                            <div className="div_wrap_25">
                                                                <div className="col_input"><label htmlFor="name-11">Orario fine</label>
                                                                    <input className="input w-input" type="time" value={contratto.oraFine} name="oraFine" id={"oraFine-" + { i }} onChange={e => onChangeSetContrattoPuntoVendita(e, i)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="div_wrap_100 m_bottom_30">
                                                            <div className="div_wrap_25">
                                                                <div className="col_input col_select">
                                                                    <label htmlFor="name">Dipendenti suggeriti</label>
                                                                    <div data-hover="false" data-delay="0" className="select w-dropdown">
                                                                        <select className="form-control select" name="dipendente" onChange={e => onChangeSetDipendente(e, i)} required>
                                                                            <option selected value={null} disabled >Seleziona dipendente...</option>
                                                                            {dipendenti.map((dipendente) => (
                                                                                <option value={dipendente._id} >{dipendente.cognome} {dipendente.nome}</option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="div_wrap_100 m_bottom_30">
                                                            <div className="div_wrap_25">
                                                                <div className="col_input">
                                                                    <div data-hover="false" data-delay="0" className="w-dropdown">
                                                                        {contratto.dipendentiSuggeriti.map((dipendenteSuggerito) => (
                                                                            <span><p>{dipendenteSuggerito.cognome} {dipendenteSuggerito.nome}</p><a onClick={e => onClickDeleteDipendenteSuggerito(e, i)}>x</a></span>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="div_wrap_100">
                                                            <div className="col_input_row">
                                                                <label className="w-checkbox checkbox-field m_right_10">
                                                                    <input type="checkbox" id={"lunedi-" + { i }} className="w-checkbox-input" name="lunedi" checked={contratto.cadenza.lunedi} value={contratto.cadenza.lunedi} onChange={e => onChangeSetContrattoPuntoVendita(e, i)} />
                                                                    <span className="label_text w-form-label" htmlFor="checkbox-3">Lunedì</span>
                                                                </label>
                                                                <label className="w-checkbox checkbox-field m_right_10">
                                                                    <input type="checkbox" id={"martedi-" + { i }} className="w-checkbox-input" name="martedi" checked={contratto.cadenza.martedi} value={contratto.cadenza.martedi} onChange={e => onChangeSetContrattoPuntoVendita(e, i)} />
                                                                    <span className="label_text w-form-label" htmlFor="checkbox-3">Martedì</span>
                                                                </label>
                                                                <label className="w-checkbox checkbox-field m_right_10">
                                                                    <input type="checkbox" id={"mercoledi-" + { i }} className="w-checkbox-input" name="mercoledi" checked={contratto.cadenza.mercoledi} value={contratto.cadenza.mercoledi} onChange={e => onChangeSetContrattoPuntoVendita(e, i)} />
                                                                    <span className="label_text w-form-label" htmlFor="checkbox-3">Mercoledì</span>
                                                                </label>
                                                                <label className="w-checkbox checkbox-field m_right_10">
                                                                    <input type="checkbox" id={"giovedi-" + { i }} className="w-checkbox-input" name="giovedi" checked={contratto.cadenza.giovedi} value={contratto.cadenza.giovedi} onChange={e => onChangeSetContrattoPuntoVendita(e, i)} />
                                                                    <span className="label_text w-form-label" htmlFor="checkbox-3">Giovedì</span>
                                                                </label>
                                                                <label className="w-checkbox checkbox-field m_right_10">
                                                                    <input type="checkbox" id={"venerdi-" + { i }} className="w-checkbox-input" name="venerdi" checked={contratto.cadenza.venerdi} value={contratto.cadenza.venerdi} onChange={e => onChangeSetContrattoPuntoVendita(e, i)} />
                                                                    <span className="label_text w-form-label" htmlFor="checkbox-3">Venerdì</span>
                                                                </label>
                                                                <label className="w-checkbox checkbox-field m_right_10">
                                                                    <input type="checkbox" id={"sabato-" + { i }} className="w-checkbox-input" name="sabato" checked={contratto.cadenza.sabato} value={contratto.cadenza.sabato} onChange={e => onChangeSetContrattoPuntoVendita(e, i)} />
                                                                    <span className="label_text w-form-label" htmlFor="checkbox-3">Sabato</span>
                                                                </label>
                                                                <label className="w-checkbox checkbox-field m_right_10">
                                                                    <input type="checkbox" id={"domenica-" + { i }} className="w-checkbox-input" name="domenica" checked={contratto.cadenza.domenica} value={contratto.cadenza.domenica} onChange={e => onChangeSetContrattoPuntoVendita(e, i)} />
                                                                    <span className="label_text w-form-label" htmlFor="checkbox-3">Domenica</span>
                                                                </label>
                                                                <label className="w-checkbox checkbox-field m_right_10">
                                                                    <input type="checkbox" id={"festivi-" + { i }} className="w-checkbox-input" name="festivi" checked={contratto.cadenza.festivi} value={contratto.cadenza.festivi} onChange={e => onChangeSetContrattoPuntoVendita(e, i)} />
                                                                    <span className="label_text w-form-label" htmlFor="checkbox-3">Festivi</span>
                                                                </label>
                                                                <label className="w-checkbox checkbox-field m_right_10">
                                                                    <input type="checkbox" id={"semifestivi-" + { i }} className="w-checkbox-input" name="semifestivi" checked={contratto.cadenza.semifestivi} value={contratto.cadenza.semifestivi} onChange={e => onChangeSetContrattoPuntoVendita(e, i)} />
                                                                    <span className="label_text w-form-label" htmlFor="checkbox-3">Semifestivi</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="div_wrap_100">
                                                            <div className="col_input_row">
                                                                <label className="w-checkbox checkbox-field m_right_10">
                                                                    <input type="checkbox" id={"continuativo-" + { i }} className="w-checkbox-input" name="continuativo" checked={contratto.continuativo} value={contratto.continuativo} onChange={e => onChangeSetContrattoPuntoVendita(e, i)} />
                                                                    <span className="label_text w-form-label" htmlFor="checkbox-3">Continuativo</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="div_wrap_100">
                                                            <div className="col_input_row">
                                                                <label className="w-checkbox checkbox-field m_right_10">
                                                                    <input type="checkbox" id={"riferitoGiornoDopo-" + { i }} className="w-checkbox-input" name="riferitoGiornoDopo" checked={contratto.riferitoGiornoDopo} value={contratto.riferitoGiornoDopo} onChange={e => onChangeRiferitoGiornoDopo(e, i)} />
                                                                    <span className="label_text w-form-label" htmlFor="checkbox-3">Riferito al giorno dopo</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="row m_bottom_10">
                                                            <div className="item_5 m_top_5">
                                                                <div className="item_6_function">
                                                                    <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc3d-43174afa" className="div_item_empty"></div>
                                                                    <div id="w-node-d27a7e1c-7034-f43d-edeb-5fcab2bbc790-43174afa" className="div_item_empty"></div>
                                                                    <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc40-43174afa" className="div_item_empty"></div>
                                                                    <div id="w-node-d409c487-9a0c-6b1f-803c-c7c11a8afa3e-43174afa" className="div_item_empty"></div>
                                                                    {contratto.nuovo ?
                                                                    <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc43-43174afa" className="div_item_button">
                                                                        <a id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc44-43174afa" onClick={() => deleteContrattoRow(i)} className="button w-button">Elimina</a>
                                                                    </div>
                                                                    :
                                                                    <div id="w-node-d409c487-9a0c-6b1f-803c-c7c11a8afa3e-43174afa" className="div_item_empty"></div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Fragment>
                                                ))}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="item_5 m_top_5">
                            <div className="item_6_function">
                                <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc3d-43174afa" className="div_item_empty"></div>
                                <div id="w-node-d27a7e1c-7034-f43d-edeb-5fcab2bbc790-43174afa" className="div_item_empty"></div>
                                <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc40-43174afa" className="div_item_empty"></div>
                                <div id="w-node-d409c487-9a0c-6b1f-803c-c7c11a8afa3e-43174afa" className="div_item_empty"></div>
                                <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc43-43174afa" className="div_item_button">
                                    <a id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc44-43174afa" onClick={() => addContrattoRow()} className="button w-button">Aggiungi riga contratto</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="item_4 m_top_5">
                            <div className="item_5_function">
                                <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc3d-b8dac9c0" className="div_item_empty"></div>
                                <div id="w-node-d27a7e1c-7034-f43d-edeb-5fcab2bbc790-b8dac9c0" className="div_item_empty"></div>
                                <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc40-b8dac9c0" className="div_item_empty"></div>
                                <div id="w-node-d409c487-9a0c-6b1f-803c-c7c11a8afa3e-b8dac9c0" className="div_item_button">
                                    <a onClick={(e) => onSubmitCreaPuntoServizio(e)} className="button w-button">Salva</a>
                                </div>
                                <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc43-b8dac9c0" className="div_item_button">
                                    <a id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc44-b8dac9c0" onClick={() => setOpenNuovoPuntoServizio(false)} className="button w-button">Chiudi</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}


NuovoPuntoVendita.protoTypes = {
    creaNuovoPuntoVendita: PropTypes.func.isRequired,
    getClienti: PropTypes.func.isRequired,
    getDipendenti: PropTypes.func.isRequired,
    alert: PropTypes.object.isRequired,
    cliente: PropTypes.object.isRequired,
    dipendente: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    alert: state.alert,
    cliente: state.cliente,
    dipendente: state.dipendente
});

export default connect(mapStateToProps, { creaNuovoPuntoVendita, getClienti, getDipendenti })(NuovoPuntoVendita);
