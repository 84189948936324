import { GET_RIPOSI_NON_RECUPERATI, ASSOCIA_RECUPERO_RIPOSO, GET_DOCUMENTI_IN_SCADENZA, DATI_ERROR, CLEAN_DATI_DASHBOARD } from "../actions/types";

const initialState = {
    riposiNonRecuperati: [],
    documentiInScadenza: [],
    error: {},
    loading: true
}

export default function(state = initialState, action){
    const { type, payload } = action;

    switch(type){
        case GET_RIPOSI_NON_RECUPERATI:
            return {
                ...state,
                riposiNonRecuperati: payload,
                loading: false
            };
        case ASSOCIA_RECUPERO_RIPOSO:
            const arrayRiposiNonRecuperati = state.riposiNonRecuperati.filter(x => x._id != payload );
            return {
                ...state,
                riposiNonRecuperati: arrayRiposiNonRecuperati,
                loading: false
            };
        case GET_DOCUMENTI_IN_SCADENZA:
            return {
                ...state,
                documentiInScadenza: payload,
                loading: false
            };
        case CLEAN_DATI_DASHBOARD:
            return {
                ...state,
                riposiNonRecuperati: [],
                documentiInScadenza: [],
                loading: false
            };
        case DATI_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            };
        default:
            return state;
    }
}