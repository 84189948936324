import React from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import PrivateRoute from '../routing/PrivateRoute';
import Login from '../auth/Login';
import NotFound from '../layout/NotFound';
import Dashboard from '../dashboard/Dashboard';
import DashboardVuota from '../dashboard/DashboardVuota';
import DashboardUtenteEsterno from '../dashboard/DashboardUtenteEsterno';
import Organizzazioni from '../dashboard/organizzazioni/Organizzazioni';
import Filiali from '../dashboard/filiali/Filiali';
import Filiale from '../dashboard/filiali/Filiale';
import Clienti from '../dashboard/clienti/Clienti';
import PuntiVendita from '../dashboard/puntiVendita/PuntiVendita';
import Dipendenti from '../dashboard/dipendenti/Dipendenti';
import Richieste from '../dashboard/richieste/Richieste';
import RichiesteCalendario from '../dashboard/richieste/RichiesteCalendario';
import GiorniEccezione from '../dashboard/giorniEccezione/GiorniEccezione';
import InvioServizi from '../dashboard/richieste/InvioServizi';
import RichiesteArchiviate from '../dashboard/richieste/RichiesteArchiviate';
import GeneraTrripa from '../dashboard/trripa/GeneraTrripa'
import Amministrazione from '../dashboard/amministrazione/Amministrazione';
import QuadratureOre from '../dashboard/quadratureOre/QuadratureOre';
import StampaGiornate from '../dashboard/stampaGiornate/StampaGiornate';
import StampaPoligono from '../dashboard/dipendenti/StampaPoligono';
import ReportCliente from '../dashboard/reportCliente/ReportCliente';
import ReportClienteData from '../dashboard/reportCliente/ReportClienteData';
import Assenteismo from '../dashboard/assenteismo/Assenteismo';
import UtentiEsterni from '../dashboard/utentiEsterni/UtentiEsterni'
import Impostazioni from '../dashboard/impostazioni/Impostazioni';
import Topbar from '../dashboard/Topbar';
import TopbarVuota from '../dashboard/TopbarVuota';
import TopbarUtenteEsterno from '../dashboard/TopbarUtenteEsterno';
import Footer from '../dashboard/Footer';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';


const Routes = ({auth: { isAuthenticated, user }}) => {

  const generaSidebar = ({isAuthenticated, ruolo}) => {
    if(isAuthenticated){
      if(ruolo === 'admin'){
        return <Topbar />
      }else if(ruolo === 'organizzazione'){
        return <Topbar />
      }else if(ruolo === 'filiale'){
        return <Topbar />
      }else if(ruolo === 'esterno'){
        return <TopbarUtenteEsterno />
      } else{
        return <TopbarVuota />
      }
    }else{
      return <TopbarVuota />
    }
  }

  const generaDashboard = ({isAuthenticated, ruolo}) => {
    if(ruolo === 'admin'){
      return Dashboard
    }else if(ruolo === 'organizzazione'){
      return Dashboard
    }else if(ruolo === 'filiale'){
      return Dashboard
    }else if(ruolo === 'esterno'){
      return DashboardUtenteEsterno
    }else{
      return DashboardVuota
    }
  }

    return (
      <BrowserRouter>
        {generaSidebar({isAuthenticated, ruolo: user && user.role})}
        <Switch>
          <PrivateRoute exact path="/" component={generaDashboard({isAuthenticated, ruolo: user && user.role})} />
          <PrivateRoute exact path="/organizzazioni" component={Organizzazioni} />
          <PrivateRoute exact path="/filiali" component={Filiali} />
          <PrivateRoute exact path="/filiale" component={Filiale} />
          <PrivateRoute exact path="/clienti" component={Clienti} />
          <PrivateRoute exact path="/punti-servizio" component={PuntiVendita} />
          <PrivateRoute exact path="/dipendenti" component={Dipendenti} />
          <PrivateRoute exact path="/incarichi" component={Richieste} />
          <PrivateRoute exact path="/incarichi-calendario" component={RichiesteCalendario} />
          <PrivateRoute exact path="/gestione-assenze" component={GiorniEccezione} />
          <PrivateRoute exact path="/amministrazione" component={Amministrazione} />
          <PrivateRoute exact path="/invio-servizi" component={InvioServizi} />
          <PrivateRoute exact path="/genera-trripa" component={GeneraTrripa} />
          <PrivateRoute exact path="/quadrature" component={QuadratureOre} />
          <PrivateRoute exact path="/stampa-giornate" component={StampaGiornate} />
          <PrivateRoute exact path="/stampa-poligono" component={StampaPoligono} />
          <PrivateRoute exact path="/report-cliente" component={ReportCliente} />
          <PrivateRoute exact path="/report-cliente-data" component={ReportClienteData} />
          <PrivateRoute exact path="/assenteismo" component={Assenteismo} />
          <PrivateRoute exact path="/utenti-esterni" component={UtentiEsterni} />
          <PrivateRoute exact path="/impostazioni" component={Impostazioni} />
          <Route exact path="/login" component={Login} />
          <Route component={NotFound} />
        </Switch>
        <Footer />
      </BrowserRouter>
    )
}

Routes.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(Routes);

