import axios from 'axios';
import { GET_ORGANIZZAZIONE, GET_ORGANIZZAZIONI, CREA_ORGANIZZAZIONE, MODIFICA_ORGANIZZAZIONE, ORGANIZZAZIONE_ERROR } from './types';
import { setAlert } from './alert';


export const getOrganizzazioni = () => async dispatch => {
    try{
        const res = await axios.get('/api/organizzazione/organizzazioni');
        dispatch({
            type: GET_ORGANIZZAZIONI,
            payload: res.data
        });
    }catch(err){
        dispatch({
            type: ORGANIZZAZIONE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const getOrganizzazione = (idOrganizzazione) => async dispatch => {
    try{
        const res = await axios.get(`/api/organizzazione/organizzazione/${idOrganizzazione}`);
        dispatch({
            type: GET_ORGANIZZAZIONE,
            payload: res.data
        });
    }catch(err){
        dispatch({
            type: ORGANIZZAZIONE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const creaNuovaOrganizzazione = ({ nome, indirizzo }) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ nome, indirizzo });
    try{
        const res = await axios.post('/api/organizzazione/nuovaOrganizzazione', body, config);
        dispatch({
            type: CREA_ORGANIZZAZIONE,
            payload: res.data
        });
        dispatch(setAlert('Nuova Organizzazione creata!', 'success'));

    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        
        dispatch({
            type: ORGANIZZAZIONE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};


export const modificaOrganizzazione = (idOrganizzazione, nome, indirizzo) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ 
        idOrganizzazione: idOrganizzazione,
        nome: nome,
        indirizzo: indirizzo
        }); 
    try{
        const res = await axios.post('/api/organizzazione/modificaOrganizzazione', body, config);
        dispatch(setAlert('Organizzazione modificata con successo!', 'success'));
        dispatch({
            type: MODIFICA_ORGANIZZAZIONE,
            payload: res.data
        });
    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        dispatch({
            type: ORGANIZZAZIONE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};