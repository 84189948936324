import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Spinner from '../../layout/Spinner';
import ModificaOrganizzazione from './ModificaOrganizzazione';
import NuovaOrganizzazione from './NuovaOrganizzazione';
import { getOrganizzazioni } from '../../../actions/organizzazione';
const Organizzazioni = ({ organizzazione: { organizzazioni, numberOrganizzazioni, loading }, getOrganizzazioni }) => {

    const [totalOrganizzazioni, setTotalOrganizzazioni] = useState(null);
    const [idOrganizzazione, setIdOrganizzazione] = useState(null);
    const [modificaOrganizzazione, setModificaOrganizzazione] = useState(false);
    const [openNuovaOrganizzazione, setOpenNuovaOrganizzazione] = useState(false);

    useEffect(() => {
        getOrganizzazioni();
    }, [getOrganizzazioni]);

    useEffect(() => {
        setTotalOrganizzazioni(numberOrganizzazioni);
    }, [numberOrganizzazioni]);
   

    const onClickGetOrganizzazione = (idOrganizzazione) => {
        setModificaOrganizzazione(true)
        setIdOrganizzazione(idOrganizzazione)
    }


    return ( loading ? <Spinner /> :
        <div>
            <div id="kt_header_mobile" className="header-mobile noprint">
	            <p>
	            </p>
	            <div className="d-flex align-items-center">
				    <button className="btn p-0 burger-icon burger-icon-left" id="kt_aside_mobile_toggle">
				        <span></span>
			        </button>
		        </div>
            </div>
            <div className="d-flex flex-column flex-root">
                <div className="d-flex flex-row flex-column-fluid page">
                <div className="d-flex flex-column flex-row-fluid wrapper">
                <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
            <div className="subheader py-3 py-lg-8  subheader-transparent " id="kt_subheader">
                <div className=" container  d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                    <div className="d-flex align-items-center flex-wrap mr-1">
                        <div className="d-flex align-items-center flex-wrap mr-1">
                            <div className="d-flex align-items-baseline flex-wrap mr-5">
                                <h2 className="subheader-title text-dark font-weight-bold my-1 mr-3">Organizzazioni</h2>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center">
                        <button type="button" onClick={() => {setOpenNuovaOrganizzazione(true)}} className="btn btn-fixed-height btn-white btn-hover-primary font-weight-bold px-2 px-lg-5 mr-2">
                            <span className="svg-icon svg-icon-success svg-icon-lg">
                                <i className="fas fa-user-plus icon-lg"></i>
                            </span> Nuova organizzazione
			            </button>
                    </div>
                </div>
            </div>
            <div className="d-flex flex-column-fluid">
                <div className=" container ">
                    {modificaOrganizzazione ? 
                        <ModificaOrganizzazione 
                        idOrganizzazione = { idOrganizzazione }
                        setModificaOrganizzazione= { setModificaOrganizzazione }
                        />
                    :
                    <div className="card card-custom">
                        <div className="card-header flex-wrap border-0 pt-6 pb-0">
                            <div className="card-title">
                                <h3 className="card-label">
                                    Lista organizzazioni
				<span className="d-block  pt-2 font-size-sm">Tutti le organizzazioni</span>
                                </h3>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="datatable datatable-bordered datatable-head-custom datatable-default datatable-primary datatable-loaded" id="kt_datatable" >
                                    <table className="datatable-table" style={{display: "block"}}>
                                        <thead className="datatable-head">
                                            <tr className="datatable-row" style={{left: "0px"}}>
                                                <th data-field="ShipAddress" className="datatable-cell datatable-cell-sort">
                                                    <span style={{width: "150px"}}>Nome</span>
                                                </th>
                                                <th data-field="Country" className="datatable-cell datatable-cell-sort">
                                                    <span style={{width: "150px"}}>Indirizzo</span>
                                                </th>
                                                <th data-field="Actions" data-autohide-disabled="false" className="datatable-cell datatable-cell-sort">
                                                    <span style={{width: "80px"}}>Modifica</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="datatable-body" >
                                            {loading ? null : 
                                                organizzazioni.map((organizzazione, i) => (
                                                    <Fragment>

                                            <tr data-row="0" className="datatable-row" style={{left: "0px"}}>
                                                <td data-field="OrderID" aria-label="64616-103" className="datatable-cell">
                                                    <span style={{width: "150px"}}>{organizzazione.nome}</span>
                                                </td>
                                                <td data-field="OrderID" aria-label="64616-103" className="datatable-cell">
                                                    <span style={{width: "150px"}}>{organizzazione.indirizzo}</span>
                                                </td>
                                                <td data-field="Actions" data-autohide-disabled="false" aria-label="null" className="datatable-cell">
                                                    <span style={{overflow: "visible", position: "relative", width: "80px"}}>
                                                        <p onClick ={() => onClickGetOrganizzazione(organizzazione._id)} className="btn btn-sm btn-clean btn-icon mr-2" title="Modifica">
                                                            <span className="svg-icon svg-icon-md">
                                                                <i className="fas fa-edit icon-md"></i>
                                                            </span>
                                                        </p>
                                                    </span>
                                                </td>
                                            </tr>
                                            </Fragment>
                                                   
                                                )
                                                )}
                                        </tbody>
                                    </table>
                                    <div className="datatable-pager datatable-paging-loaded">
                                        <div className="datatable-pager-info">
                                            <span className="datatable-pager-detail">Risultati: {totalOrganizzazioni}</span>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                }
                </div>
            </div>
        </div>
                </div>
                
                </div>
            </div>
            <NuovaOrganizzazione 
                            openNuovaOrganizzazione={openNuovaOrganizzazione}
                            setOpenNuovaOrganizzazione={setOpenNuovaOrganizzazione}
                        />
        </div>
        
    )
}

Organizzazioni.protoTypes = {
    getOrganizzazioni: PropTypes.func.isRequired,
    organizzazione: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    organizzazione: state.organizzazione
});

export default connect(mapStateToProps, { getOrganizzazioni })(Organizzazioni);

