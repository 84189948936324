import { GET_FESTIVITA, CREA_FESTIVITA, GET_CATEGORIE_DOCUMENTI_UTENTE_ESTERNO, CREA_CATEGORIA_DOCUMENTO_UTENTE_ESTERNO, ELIMINA_FESTIVITA, IMPOSTAZIONE_ERROR } from "../actions/types";

const initialState = {
    festivita: [],
    categorieDocumentiUtenteEsterno: [],
    loading: true
}

export default function(state = initialState, action){
    const { type, payload } = action;

    switch(type){
        case GET_CATEGORIE_DOCUMENTI_UTENTE_ESTERNO:
        case CREA_CATEGORIA_DOCUMENTO_UTENTE_ESTERNO:
            return {
                ...state,
                categorieDocumentiUtenteEsterno: payload,
                loading: false
            };           
        case GET_FESTIVITA: 
        case CREA_FESTIVITA:
        case ELIMINA_FESTIVITA:
            return {
                ...state,
                festivita: payload,
                loading: false
            };
        case IMPOSTAZIONE_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            };
        default:
            return state;
    }
}