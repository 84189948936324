import { GET_UTENTI_ESTERNI, GET_UTENTE_ESTERNO, MODIFICA_UTENTE_ESTERNO, CREA_UTENTE_ESTERNO, UTENTE_ESTERNO_ERROR } from "../actions/types";

const initialState = {
    utentiEsterni: [],
    utenteEsterno: {},
    loading: true
}

export default function(state = initialState, action){
    const { type, payload } = action;

    switch(type){
        case GET_UTENTI_ESTERNI:
            return {
                ...state,
                utentiEsterni: payload,
                loading: false
            };
        case GET_UTENTE_ESTERNO:
        case MODIFICA_UTENTE_ESTERNO:
            return {
                ...state,
                utenteEsterno: payload,
                loading: false
            };
        case CREA_UTENTE_ESTERNO:
            let arrayCreaUtente = [...state.utentiEsterni];
            arrayCreaUtente.push(payload);
            arrayCreaUtente.sort((a,b) => (a.cognome > b.cognome) ? 1 : ((b.cognome > a.cognome) ? -1 : 0)); 
            return {
                ...state,
                utentiEsterni: arrayCreaUtente,
                loading: false
            }
        case UTENTE_ESTERNO_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            };
        default:
            return state;
    }
}