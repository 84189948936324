import { GET_RICHIESTE, GET_RICHIESTE_ARCHIVIATE, GET_RICHIESTE_CLIENTE, GET_RICHIESTE_FILIALE, GET_TUTTE_RICHIESTE, GET_RICHIESTE_PUNTO_VENDITA, GET_RICHIESTE_CALENDARIO, GET_RICHIESTE_STAMPA_GIORNATE, GET_RICHIESTE_SENZA_CONTRATTO, GET_RICHIESTA, FILTRA_RICHIESTE, FILTRA_RICHIESTE_ARCHIVIATE, FILTRA_RICHIESTE_DIPENDENTE, FILTRA_RICHIESTE_PUNTOVENDITA, FILTRA_RICHIESTE_CLIENTE, ARCHIVIA_RICHIESTE, ANNULLA_ARCHIVIA_RICHIESTE, CREA_RICHIESTA_SUCCESS, MODIFICA_RICHIESTA, MODIFICA_ORARIO_RICHIESTA, ARCHIVIA_RICHIESTA, PROGRAMMA_TUTTI_TURNI, PROGRAMMA_PROSSIMO_TURNO, RIPRISTINA_TUTTI_TURNI, ANNULLA_RICHIESTA_ARCHIVIATA, LOADING_RICHIESTE, GET_CAMPI_RICHIESTA_MASSIVA, PROGRAMMA_TUTTI_TURNI_JOB, ADD_DIPENDENTE_RICHIESTE, DELETE_DIPENDENTE_RICHIESTE, CLEAN_RICHIESTA, CLEAN_CALENDARIO, CLEAN_RICHIESTE, CLEAN_RICHIESTE_ARCHIVIATE, RICHIESTA_ERROR, ASSOCIA_DIPENDENTE_RICHIESTA, DUPLICA_SERVIZIO, DISSOCIA_DIPENDENTE_RICHIESTA, GET_CALENDARIO_CONTRATTI, NASCONDI_CONTRATTO, RIPRISTINA_CONTRATTO_NASCOSTO, GET_DIPENDENTI_PRESTABILI_OCCUPATI, GET_QUADRATURA_ORE, ELIMINA_RICHIESTA_ANOMALA } from "../actions/types";

const initialState = {
    richiesta: null,
    richieste: [],
    richiesteArchiviate: [],
    richiesteSenzaContratto: [],   
    dipendentiPrestabiliOccupati: [], 
    jobId: null,
    campiRichieste: [],
    richiestaUpdate: [],
    calendarioRichieste: [],
    calendarioLoading: true,
    numberRichieste: 0,
    quadraturaOre: {richieste: [], incarichiSalvati: []},
    error: {},
    loading: true
}

export default function(state = initialState, action){
    const { type, payload } = action;

    switch(type){
        case GET_RICHIESTE:
        case GET_RICHIESTE_CALENDARIO:
        case CREA_RICHIESTA_SUCCESS:
        case PROGRAMMA_TUTTI_TURNI:
        case PROGRAMMA_PROSSIMO_TURNO:
        case RIPRISTINA_TUTTI_TURNI:
        case FILTRA_RICHIESTE:
        case FILTRA_RICHIESTE_ARCHIVIATE:
        case FILTRA_RICHIESTE_DIPENDENTE:
        case FILTRA_RICHIESTE_PUNTOVENDITA:
        case FILTRA_RICHIESTE_CLIENTE:
        case ADD_DIPENDENTE_RICHIESTE:
        case DELETE_DIPENDENTE_RICHIESTE:
            return {
                ...state,
                richieste: payload.richieste,
                numberRichieste: payload.numberRichieste,
                jobId: null,
                loading: false
            };
        case GET_CAMPI_RICHIESTA_MASSIVA:
            return {
                ...state,
                campiRichieste: payload,
                loading: false
                };
        case GET_RICHIESTA:
            return {
                ...state,
                richiesta: payload,
                loading: false
            };
        case ARCHIVIA_RICHIESTA:
        case ANNULLA_RICHIESTA_ARCHIVIATA:
        case ARCHIVIA_RICHIESTE:
        case ANNULLA_ARCHIVIA_RICHIESTE:
            return {
                ...state,
                richiesteArchiviate: payload,
                loading: false
        };
        case MODIFICA_RICHIESTA:
            const index = state.richieste.findIndex(x => x._id == payload._id );
            let array = state.richieste;
            array[index] = payload
            return {
                ...state,
                richieste: array,
                loading: false
            };
        case ELIMINA_RICHIESTA_ANOMALA: 
            return {
                ...state,
                richiestaUpdate: payload,
                loading: false
            };
        case CLEAN_RICHIESTA:
            return {
                ...state,
                richiesta: [],
                loading: false
            };
        case CLEAN_CALENDARIO:
            return {
                ...state,
                calendarioRichieste: [],
                calendarioLoading: false
            };
        case CLEAN_RICHIESTE:
            return {
                ...state,
                richieste: [],
                dipendentiPrestabiliOccupati: [],
                loading: false
            };
        case CLEAN_RICHIESTE_ARCHIVIATE:
            return {
                ...state,
                richiesteArchiviate: [],
                loading: false
            };
        case PROGRAMMA_TUTTI_TURNI_JOB:
            return {
                ...state,
                jobId: payload,
                loading: false
            };
        case ASSOCIA_DIPENDENTE_RICHIESTA:
        case DISSOCIA_DIPENDENTE_RICHIESTA:
        case MODIFICA_ORARIO_RICHIESTA:
        case NASCONDI_CONTRATTO:
        case RIPRISTINA_CONTRATTO_NASCOSTO:
        case DUPLICA_SERVIZIO:
            return {
                ...state,
                richiestaUpdate: payload,
                loading: false
            }
        case GET_CALENDARIO_CONTRATTI:
            return {
                ...state,
                calendarioRichieste: payload,
                calendarioLoading: false
            };
        case GET_RICHIESTE_ARCHIVIATE:
            return {
                ...state,
                richiesteArchiviate: payload,
                loading: false
            };
        case GET_RICHIESTE_SENZA_CONTRATTO:
            return {
                ...state,
                richiesteSenzaContratto: payload,
                loading: false
            };
        case GET_DIPENDENTI_PRESTABILI_OCCUPATI:
            return {
                ...state,
                dipendentiPrestabiliOccupati: payload,
                loading: false
            };
        case GET_QUADRATURA_ORE:
            return {
                ...state,
                quadraturaOre: payload,
                loading: false
            };
        case GET_RICHIESTE_STAMPA_GIORNATE:
        case GET_RICHIESTE_CLIENTE:
        case GET_RICHIESTE_FILIALE:
        case GET_TUTTE_RICHIESTE:
        case GET_RICHIESTE_PUNTO_VENDITA:
            return {
                ...state,
                richieste: payload,
                loading: false
            };
        case RICHIESTA_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            };
        case LOADING_RICHIESTE:
            return {
                ...state,
                loading: true
            }
        default:
            return state;
    }
}