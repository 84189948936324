import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Spinner from '../../layout/Spinner';
import { getOrganizzazione, modificaOrganizzazione } from '../../../actions/organizzazione';
const ModificaOrganizzazione = ({ organizzazione: { organizzazione, loading }, alert, getOrganizzazione, modificaOrganizzazione, idOrganizzazione, setModificaOrganizzazione}) => {

    useEffect(() => {
        const size = alert.length;
        if(size>0){
            if(alert[size-1].alertType === "success"){
                setModificaOrganizzazione(false);
            }
        }
    }, [setModificaOrganizzazione, alert]);

    useEffect(() => {
        getOrganizzazione(idOrganizzazione);
    }, [getOrganizzazione, idOrganizzazione]);

    const organizzazioneInitialState = {
        nome: '',
        indirizzo: ''
       }
    const [datiOrganizzazione, setDatiOrganizzazione] = useState(organizzazioneInitialState);

    useEffect(() => {
        setDatiOrganizzazione({
            nome: loading || !organizzazione ? '' : organizzazione.nome,
            indirizzo: loading || !organizzazione ? '' : organizzazione.indirizzo
        });
    }, [organizzazione, loading]);
    const { nome, indirizzo } = datiOrganizzazione;


    const onChangesetDatiOrganizzazione = e => setDatiOrganizzazione({ ...datiOrganizzazione, [e.target.name]: e.target.value });

    const onSubmitModificaOrganizzazione = async e => {
        e.preventDefault();
        modificaOrganizzazione(idOrganizzazione, nome, indirizzo );
     };

    return ( loading ? <Spinner /> :
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card card-custom example example-compact">
                                <div className="card-header">
                                    <h3 className="card-title">
                                        Modifica Organizzazione
				                    </h3>
                                </div>
                                <form className="form" onSubmit= {e => onSubmitModificaOrganizzazione(e)} >
                                    <div className="card-body">
                                        <h3 className="font-size-lg text-dark font-weight-bold mb-6">Dati organizzazione:</h3>
                                        <div className="mb-15">
                                            <div className="form-group row">
                                                <div className="col-lg-6">
							                        <label>Nome:</label>
							                        <input type="text" className="form-control" name="nome" value={nome} onChange={e => onChangesetDatiOrganizzazione(e)} placeholder="Inserisci nome"/>
						                        </div>
                                                <div className="col-lg-6">
						                            <label>indirizzo:</label>
						                            <input type="text" className="form-control" name="indirizzo" value={indirizzo} onChange={e => onChangesetDatiOrganizzazione(e)} placeholder="Inserisci indirizzo"/>
					                            </div>
                                        </div>
                                    </div>
                                    <div className="card-footer">
                                        <div className="row">
                                        <div className="col-lg-6 text-left">
                                                <p onClick={() => setModificaOrganizzazione(false)} className="btn btn-secondary mr-2">Chiudi</p>
                                            </div>
                                            <div className="col-lg-6 text-right">
                                                <button type="submit" className="btn btn-success mr-2">Modifica organizzazione</button>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
    )
}

ModificaOrganizzazione.protoTypes = {
    getOrganizzazione: PropTypes.func.isRequired,
    modificaOrganizzazione: PropTypes.func.isRequired,
    organizzazione: PropTypes.object.isRequired,
    alert: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    organizzazione: state.organizzazione,
    alert: state.alert
});

export default connect(mapStateToProps, { getOrganizzazione, modificaOrganizzazione })(ModificaOrganizzazione);

