import axios from 'axios';
import { setAlert } from './alert';
import { GET_RIPOSI_NON_RECUPERATI, ASSOCIA_RECUPERO_RIPOSO, GET_DOCUMENTI_IN_SCADENZA, DATI_ERROR } from './types';



export const getRiposiNonRecuperati = () => async dispatch => {
    try{
        const res = await axios.get('/api/dati/getRiposiNonRecuperati');
        dispatch({
            type: GET_RIPOSI_NON_RECUPERATI,
            payload: res.data
        });
    }catch(err){
        dispatch({
            type: DATI_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const associaRecuperoRiposo = ({ idRichiesta, dataRecuperoRiposo }) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ idRichiesta, dataRecuperoRiposo }); 
    try{
        const res = await axios.post('/api/dipendente/associaRecuperoRiposo', body, config);
        dispatch(setAlert('Recupero riposo associato con successo!', 'success', 'add_recupero_riposo'));
        dispatch({
            type: ASSOCIA_RECUPERO_RIPOSO,
            payload: res.data
        });
    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        dispatch({
            type: DATI_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const getDocumentiInScadenza = ({ dataInizio, dataFine, tipologia, scaduti }) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ dataInizio, dataFine, tipologia, scaduti }); 
    try{
        const res = await axios.post('/api/dipendente/getDocumentiInScadenza', body, config);
        dispatch({
            type: GET_DOCUMENTI_IN_SCADENZA,
            payload: res.data
        });
    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        dispatch({
            type: DATI_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};
