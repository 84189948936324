import axios from 'axios';
import { setAlert } from './alert';
import { GET_UTENTI_ESTERNI, GET_UTENTE_ESTERNO, CREA_UTENTE_ESTERNO, MODIFICA_UTENTE_ESTERNO, UTENTE_ESTERNO_ERROR } from './types';


export const getUtentiEsterni = () => async dispatch => {
    try{
        const res = await axios.get('/api/utenteEsterno/getUtentiEsterni')
        dispatch({
            type: GET_UTENTI_ESTERNI,
            payload: res.data
        });
    }catch(err){
        dispatch({
            type: UTENTE_ESTERNO_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const getUtenteEsterno = (idUtenteEsterno) => async dispatch => {
    try{
        const res = await axios.get(`/api/utenteEsterno/getUtenteEsterno/${idUtenteEsterno}`);
        dispatch({
            type: GET_UTENTE_ESTERNO,
            payload: res.data
        });
    }catch(err){
        dispatch({
            type: UTENTE_ESTERNO_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const creaNuovoUtenteEsterno = ({
    nome,
    cognome,
    username,
    password
}) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ 
        nome,
        cognome,
        username,
        password
    }); 
    try{
        const res = await axios.post('/api/utenteEsterno/creaNuovoUtenteEsterno', body, config);
        dispatch(setAlert('Utente creato con successo!', 'success'));
        dispatch({
            type: CREA_UTENTE_ESTERNO,
            payload: res.data
        });
    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        dispatch({
            type: UTENTE_ESTERNO_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};


export const modificaUtenteEsterno = ({
    idUtenteEsterno,
    nome,
    cognome,
    username,
    passCl,
    documenti
}) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ 
        idUtenteEsterno,
        nome,
        cognome,
        username,
        passCl,
        documenti
    }); 
    try{
        const res = await axios.post('/api/utenteEsterno/modificaUtenteEsterno', body, config);
        dispatch(setAlert('Utente modificato con successo!', 'success'));
        dispatch({
            type: MODIFICA_UTENTE_ESTERNO,
            payload: res.data
        });
    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        dispatch({
            type: UTENTE_ESTERNO_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};