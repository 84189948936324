import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import documentoIMG from "../../../img/documento.png"
import cestinoIMG from "../../../img/trash.png"
import DatePicker from 'react-date-picker';



const DocumentoCorso = ({ id, nome, url, getIndexDocumento, pulisciFileCorso, onChangeUploadFile, dataRilascio, onChangeSetDataRilascioCorso, onChangeSetScadenzaCorso, scadenza, tipologieCorsi, onChangeTipologiaCorso, eliminaCorso }) => {

    return (
        <div className='mb-30'>
            <div className="div_wrap_100">
                    <>
                        {url.length > 0 ?
                            <div className="div_wrap_25">
                                <div className="col_input">
                                    <label htmlFor="tagliaPantaloni">File</label>
                                    <div className='item_doc_dip'>
                                        <span><a target="_blank" href={url}><img className='doc_dip_img' src={documentoIMG}></img></a><img onClick={() => pulisciFileCorso(id)} className='doc_dip_img' src={cestinoIMG}></img></span>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="div_wrap_25">
                                <div className="col_input">
                                    <label htmlFor="tagliaPantaloni">File</label>
                                    <div className='item_doc_dip'>
                                        <input type="file" className="input w-input" name={nome} data-name={nome} id={nome} onChange={(e) => onChangeUploadFile(e, id, "corso")} />
                                    </div>
                                </div>
                            </div>
                        }
                    </>
                <div className="div_wrap_25">
                    <div className="col_input col_select">
                        <label htmlFor="name">Tipologia corso</label>
                        <div data-hover="false" data-delay="0" className="select w-dropdown">
                            <select className="form-control select" name="scadenza" onChange={e => onChangeTipologiaCorso(e.target.value, id)} required>
                                <option selected={true} value={null} disabled>seleziona...</option>
                                {tipologieCorsi.map((el, i)=> (
                                <option name={el.nome} selected={nome == el.nome} value={i}>{el.nome}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="div_wrap_25">
                    <div className="col_input"><label htmlFor="name-11">Data rilascio</label>
                        <div data-hover="false" data-delay="0" className="select w-dropdown select_data">
                            <DatePicker
                                onChange={(value) => onChangeSetDataRilascioCorso(value, id)}
                                value={dataRilascio}
                                clearIcon={null}
                                className={'form-control'}
                                disableCalendar={false}
                            />
                        </div>
                    </div>
                </div>
                <div className="div_wrap_25">
                        <div className="col_input">
                            <label htmlFor="tagliaPantaloni">Scadenza (mesi)</label>
                            <div className='item_doc_dip'>
                                <input type="number" className="input w-input" name={scadenza} data-name={scadenza} value={scadenza} onChange={e => onChangeSetScadenzaCorso(e.target.value, id)} required />
                            </div>
                        </div>
                    </div>
            </div>
            <div className='div_wrap_100'>
                <div className="div_wrap_25">
                    <span></span>
                </div>
                <div className="div_wrap_25">
                    <span></span>
                </div>
                <div className="div_wrap_25">
                    <span></span>
                </div>
                <div className="div_wrap_25">
                    <div className="col_input">
                        <div id="w-node-d409c487-9a0c-6b1f-803c-c7c11a8afa3e-b8dac9c0" onClick={() => eliminaCorso(id)} class="div_item_button"><a class="button w-button">elimina</a></div>
                    </div>
                </div>
            </div>
        </div>
    )
}
DocumentoCorso.protoTypes = {
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, {})(DocumentoCorso);

