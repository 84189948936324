import React, { Fragment } from 'react';
import spinner from './spinner.gif';

export default () => (
    <Fragment>
        <div className="spinner_style" 
            style={{ width: '100%', margin: 'auto', display: 'flex',height: '100vh', alignItems: 'center', justifyContent: 'center'   }}
        >
        <img
            src={spinner}
            style={{ width: '200px', display: 'block' }}
            alt='Caricamento...'
        />
        </div>
    </Fragment>
)