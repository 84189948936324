import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Spinner from '../../layout/Spinner';
import DatePicker from 'react-date-picker';
import zoneResidenzaJSON from "../../../utils/zone_residenza.json";
import raggruppamentiJSON from "../../../utils/raggruppamenti.json";
import serviziEffJSON from "../../../utils/serviziEff.json";
import indennitaDiFunzioneJSON from "../../../utils/indennitaDiFunzione.json";
import { getPuntoVendita, modificaPuntoVendita, clearPuntoVendita } from '../../../actions/puntoVendita';
import { getDipendenti } from '../../../actions/dipendente';
import { getClienti } from '../../../actions/cliente';
const ModificaCliente = ({ auth: {user}, cliente: { clienti }, puntoVendita: { puntoVendita, loadingPuntoVendita }, alert, getPuntoVendita, modificaPuntoVendita, idPuntoVendita, setOpenModificaPuntoServizio, clearPuntoVendita, getDipendenti, dipendente: { dipendenti }, getClienti }) => {

    useEffect(() => {
        const size = alert.length;
        if (size > 0) {
            if (alert[size - 1].alertType === "success") {
                //setOpenModificaPuntoServizio(false);
            }
        }
    }, [setOpenModificaPuntoServizio, alert]);

    useEffect(() => {
        getPuntoVendita(idPuntoVendita);
        return () => {
            clearPuntoVendita();
        };
    }, [getPuntoVendita, idPuntoVendita]);

    useEffect(() => {
        getDipendenti(0, 10, false);
    }, []);

    useEffect(() => {
        getClienti();
    }, [getClienti]);

    

    const puntoVenditaInitialState = {
        idCliente: null,
        denominazione: '',
        codice: '',
        idZonaRes: '',
        idServiziEff: '',
        idIndennitaDiFunz: '',
        idRaggruppa: '',
        qualifica: '',
        indirizzo: '',
        cap: '',
        localita: '',
        provincia: '',
        telefono: '',
        email: '',
        ragioneSocCliente: '',
        contratti: [],
        controllo: null
    }
    const [datiPuntoServizio, setDatiPuntoServizio] = useState(puntoVenditaInitialState);

    const contrattoObj = {
        codice: loadingPuntoVendita || !puntoVendita ? '' : puntoVendita.codice,
        dataInizio: null,
        dataFine: null,
        oraInizio: '',
        oraFine: '',
        cadenza: {
            lunedi: false,
            martedi: false,
            mercoledi: false,
            giovedi: false,
            venerdi: false,
            sabato: false,
            domenica: false,
            festivi: false,
            semifestivi: false
        },
        puntoVendita: idPuntoVendita,
        nuovo: true,
        continuativo: false,
        riferitoGiornoDopo: false,
        dipendentiSuggeriti: []
    }

    useEffect(() => {
        console.log(puntoVendita)
        setDatiPuntoServizio({
            idCliente: loadingPuntoVendita || !puntoVendita ? '' : puntoVendita.cliente._id,
            denominazione: loadingPuntoVendita || !puntoVendita ? '' : puntoVendita.denominazione,
            codice: loadingPuntoVendita || !puntoVendita ? '' : puntoVendita.codice,
            idZonaRes: loadingPuntoVendita || !puntoVendita ? '' : puntoVendita.idZonaRes,
            idServiziEff: loadingPuntoVendita || !puntoVendita ? '' : puntoVendita.idServiziEff,
            idIndennitaDiFunz: loadingPuntoVendita || !puntoVendita ? '' : puntoVendita.idIndennitaDiFunz,
            idRaggruppa: loadingPuntoVendita || !puntoVendita ? '' : puntoVendita.idRaggruppa,
            qualifica: loadingPuntoVendita || !puntoVendita ? '' : puntoVendita.qualifica,
            indirizzo: loadingPuntoVendita || !puntoVendita ? '' : puntoVendita.indirizzo,
            cap: loadingPuntoVendita || !puntoVendita ? '' : puntoVendita.cap,
            localita: loadingPuntoVendita || !puntoVendita ? '' : puntoVendita.localita,
            provincia: loadingPuntoVendita || !puntoVendita ? '' : puntoVendita.provincia,
            telefono: loadingPuntoVendita || !puntoVendita ? '' : puntoVendita.telefono,
            email: loadingPuntoVendita || !puntoVendita ? '' : puntoVendita.email,
            ragioneSocCliente: loadingPuntoVendita || !puntoVendita || !puntoVendita.cliente || !puntoVendita.cliente.ragioneSoc ? '' : puntoVendita.cliente.ragioneSoc,
            contratti: loadingPuntoVendita || !puntoVendita ? [contrattoObj] : puntoVendita.contratti,
            controllo: loadingPuntoVendita || !puntoVendita || !puntoVendita.controllo ? null : puntoVendita.controllo,
        });
    }, [puntoVendita, loadingPuntoVendita]);
    const { idCliente, denominazione, codice, idZonaRes, idServiziEff, idIndennitaDiFunz, idRaggruppa, qualifica, indirizzo, cap, localita, provincia, telefono, email, ragioneSocCliente, contratti, controllo } = datiPuntoServizio;


    const onChangeSetDatiPuntoServizio = e => setDatiPuntoServizio({ ...datiPuntoServizio, [e.target.name]: e.target.value });
    const onChangeSetDatiPuntoServizioControllo = e => {
        let newControllo = {...controllo}
        if(e.target.name == 'esito'){
            newControllo.esito = e.target.value
            if(e.target.value == 'true'){
                newControllo.utente = user
            }else{
                newControllo.utente = null
            }
        }
        if(e.target.name == 'messaggio'){
            newControllo.messaggio = e.target.value
        }
        
        setDatiPuntoServizio({ ...datiPuntoServizio, controllo: newControllo });
    }


    const onSubmitModificaPuntoServizio = async e => {
        e.preventDefault();
        modificaPuntoVendita({ idPuntoVendita, denominazione, idZonaRes, idServiziEff, idIndennitaDiFunz, idRaggruppa, qualifica, indirizzo, cap, localita, provincia, telefono, email, contratti, controllo, idCliente });
    };

    const onChangeSetContrattoPuntoVendita = (e, i) => {
        let arrayContratti = contratti;
        if (e.target.name === 'dataInizio' || e.target.name === 'dataFine') {
            arrayContratti[i][e.target.name] = new Date(e.target.value);
        } else if (e.target.type === 'checkbox') {
            if(e.target.name == "continuativo"){
                if(e.target.value === 'false'){
                    arrayContratti[i].cadenza = {
                        lunedi: true,
                        martedi: true,
                        mercoledi: true,
                        giovedi: true,
                        venerdi: true,
                        sabato: true,
                        domenica: true,
                        festivi: true,
                        semifestivi: true
                    }
                }
                arrayContratti[i].continuativo = e.target.value === 'false' ? true : false;
            }else{
                arrayContratti[i].cadenza[e.target.name] = e.target.value === 'false' ? true : false;
            }
        } else {
            arrayContratti[i][e.target.name] = e.target.value;
        }
        setDatiPuntoServizio({ ...datiPuntoServizio, contratti: arrayContratti })
    };

    const onChangeRiferitoGiornoDopo = (e, i) => {
        let arrayContratti = contratti;
        arrayContratti[i].riferitoGiornoDopo = e.target.value === 'false' ? true : false;
        setDatiPuntoServizio({ ...datiPuntoServizio, contratti: arrayContratti })
    }

    const addContrattoRow = () => {
        let arrayContratti = contratti.concat(contrattoObj);
        setDatiPuntoServizio({ ...datiPuntoServizio, contratti: arrayContratti })
    };

    const deleteContrattoRow = (i) => {
        let arrayContratti = contratti;
        arrayContratti.splice(i, 1);
        setDatiPuntoServizio({ ...datiPuntoServizio, contratti: arrayContratti })
    };

    const onChangeSetDipendente = (e, i) => {
        let arrayContratti = contratti;
        let arrayDipendentiSuggeriti = [...arrayContratti[i].dipendentiSuggeriti]
        let indexDipendenteSuggerito = dipendenti.findIndex(el => el._id == e.target.value);
        if(indexDipendenteSuggerito > -1){
            let indexDipendenteSuggeritoEsistente = arrayDipendentiSuggeriti.findIndex(el => el._id == dipendenti[indexDipendenteSuggerito]._id);
            if(indexDipendenteSuggeritoEsistente == -1){
                arrayDipendentiSuggeriti.push(dipendenti[indexDipendenteSuggerito]);
            }
        }
        arrayContratti[i].dipendentiSuggeriti = arrayDipendentiSuggeriti
        setDatiPuntoServizio({ ...datiPuntoServizio, contratti: arrayContratti })
    }

    const onClickDeleteDipendenteSuggerito = (i, j) => {
        let arrayContratti = [...contratti];
        let arrayDipendentiSuggeriti = [...arrayContratti[i].dipendentiSuggeriti]
        arrayDipendentiSuggeriti.splice(j, 1);
        arrayContratti[i].dipendentiSuggeriti = arrayDipendentiSuggeriti
        setDatiPuntoServizio({ ...datiPuntoServizio, contratti: arrayContratti })
    }

    return (loadingPuntoVendita ? <Spinner /> :
        <Fragment>
            <div className="dati_container">
                <div className="fun_assegnazione_incarichi">
                    <div className="row row_datapicker">
                        <div className="item_2">
                            <h4>Modifica punto di servizio</h4>
                            <div>
                                <div className="w-form">
                                    <form id="email-form-2" name="email-form-2" data-name="Email Form 2" method="get" aria-label="Email Form 2">
                                        <div className="div_wrap_100">
                                            <div className="div_wrap_25">
                                                <div className="col_input">
                                                    <label htmlFor="name">Denominazione</label>
                                                    <input type="text" className="input w-input" maxLength="256" name="denominazione" value={denominazione} data-name="denominazione" placeholder="Inserisci denominazione" id="denominazione" onChange={e => onChangeSetDatiPuntoServizio(e)} />
                                                </div>
                                            </div>
                                            <div className="div_wrap_25">
                                                <div className="col_input">
                                                    <label htmlFor="name">Codice</label>
                                                    <input type="text" className="input w-input" maxLength="256" name="codice" data-name="codice" value={codice} placeholder="Inserisci codice" id="codice" disabled />
                                                </div>
                                            </div>
                                            <div className="div_wrap_25">
                                                <div className="col_input col_select">
                                                    <label htmlFor="name">Cliente</label>
                                                    <div data-hover="false" data-delay="0" className="select w-dropdown">
                                                        <select className="form-control select" name="idCliente" onChange={e => onChangeSetDatiPuntoServizio(e)} required>
                                                            <option selected value={null} disabled >Seleziona cliente...</option>
                                                            {clienti.map((cliente, i) => (
                                                                <option key={i} value={cliente._id} selected={cliente._id == idCliente} >{cliente.ragioneSoc}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="div_wrap_25">
                                                <div className="col_input">
                                                </div>
                                            </div>
                                        </div>
                                        <div className="div_wrap_100">
                                            <div className="div_wrap_25">
                                                <div className="col_input">
                                                    <label htmlFor="name">Indirizzo</label>
                                                    <input type="text" className="input w-input" maxLength="256" name="indirizzo" data-name="indirizzo" value={indirizzo} placeholder="Inserisci indirizzo" id="indirizzo" onChange={e => onChangeSetDatiPuntoServizio(e)} />
                                                </div>
                                            </div>
                                            <div className="div_wrap_25">
                                                <div className="col_input">
                                                    <label htmlFor="name">Provincia</label>
                                                    <input type="text" className="input w-input" maxLength="256" name="provincia" data-name="provincia" value={provincia} placeholder="Inserisci provincia" id="provincia" onChange={e => onChangeSetDatiPuntoServizio(e)} />
                                                </div>
                                            </div>
                                            <div className="div_wrap_25">
                                                <div className="col_input">
                                                    <label htmlFor="name">CAP</label>
                                                    <input type="text" className="input w-input" maxLength="256" name="cap" data-name="cap" value={cap} placeholder="Inserisci cap" id="cap" onChange={e => onChangeSetDatiPuntoServizio(e)} />
                                                </div>
                                            </div>
                                            <div className="div_wrap_25">
                                                <div className="col_input">
                                                    <label htmlFor="name">Località</label>
                                                    <input type="text" className="input w-input" maxLength="256" name="localita" data-name="localita" value={localita} placeholder="Inserisci località" id="localita" onChange={e => onChangeSetDatiPuntoServizio(e)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="div_wrap_100">
                                            <div className="div_wrap_25">
                                                <div className="col_input col_select">
                                                    <label htmlFor="name">Zona residenza</label>
                                                    <div data-hover="false" data-delay="0" className="select w-dropdown">
                                                        <select className="form-control select" name="idZonaRes" onChange={e => onChangeSetDatiPuntoServizio(e)} required>
                                                            <option selected value={null} disabled >Seleziona zona residenza...</option>
                                                            {zoneResidenzaJSON.zoneResidenza.map((el) => (
                                                                <option selected={el.id == idZonaRes} value={el.id}>{el.codice} - {el.nome}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="div_wrap_25">
                                                <div className="col_input col_select">
                                                    <label htmlFor="name">Raggruppamento</label>
                                                    <div data-hover="false" data-delay="0" className="select w-dropdown">
                                                        <select className="form-control select" name="idRaggruppa" onChange={e => onChangeSetDatiPuntoServizio(e)} required>
                                                            <option selected value={null} disabled >Seleziona raggruppamento...</option>
                                                            {raggruppamentiJSON.raggruppamenti.map((el) => (
                                                                <option selected={el.id == idRaggruppa} value={el.id}>{el.codice} - {el.nota}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="div_wrap_25">
                                                <div className="col_input col_select">
                                                    <label htmlFor="name">Servizio eff</label>
                                                    <div data-hover="false" data-delay="0" className="select w-dropdown">
                                                        <select className="form-control select" name="idServiziEff" onChange={e => onChangeSetDatiPuntoServizio(e)} required>
                                                            <option selected value={null} disabled >Seleziona servizi eff...</option>
                                                            {serviziEffJSON.serviziEff.map((el) => (
                                                                <option selected={el.id == idServiziEff} value={el.id}>{el.nome}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="div_wrap_25">
                                                <div className="col_input col_select">
                                                    <label htmlFor="name">Qualifica</label>
                                                    <div data-hover="false" data-delay="0" className="select w-dropdown">
                                                        <select className="form-control select" name="qualifica" onChange={e => onChangeSetDatiPuntoServizio(e)} required>
                                                            <option selected value={null} disabled >Seleziona qualifica...</option>
                                                                <option selected={'ots' == qualifica} value={'ots'}>OTS</option>
                                                                <option selected={'gpg' == qualifica} value={'gpg'}>GPG</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="div_wrap_100">
                                            <div className="div_wrap_25">
                                                <div className="col_input col_select">
                                                    <label htmlFor="name">Indennità di funzione</label>
                                                    <div data-hover="false" data-delay="0" className="select w-dropdown">
                                                        <select className="form-control select" name="idIndennitaDiFunz" onChange={e => onChangeSetDatiPuntoServizio(e)} required>
                                                            <option selected value={''} >Nessuna</option>
                                                            {indennitaDiFunzioneJSON.indennitaDiFunzione.map((el) => (
                                                                <option selected={el.id == idIndennitaDiFunz} value={el.id}>{el.nome}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="div_wrap_100">
                                            <div className="div_wrap_50">
                                                <div className="col_input">
                                                    <label htmlFor="name">Email</label>
                                                    <input type="text" className="input w-input" maxLength="256" name="email" data-name="email" value={email} placeholder="Inserisci email" id="email" onChange={e => onChangeSetDatiPuntoServizio(e)} />
                                                </div>
                                            </div>
                                            <div className="div_wrap_50">
                                                <div className="col_input">
                                                    <label htmlFor="name">Telefono</label>
                                                    <input type="text" className="input w-input" maxLength="256" name="telefono" data-name="telefono" value={telefono} placeholder="Inserisci telefono" id="telefono" onChange={e => onChangeSetDatiPuntoServizio(e)} />
                                                </div>
                                            </div>
                                        </div>
                                        {user && user.role == "organizzazione" ?
                                        <div className="modulo_controllo" >
                                            <h5>Modulo controllo servizio</h5>
                                            <div className="div_wrap_100">
                                            <div className="div_wrap_25">
                                                <div className="col_input col_select">
                                                    <label htmlFor="name">Controllato?</label>
                                                    <div data-hover="false" data-delay="0" className="select w-dropdown">
                                                        <select className="form-control select" name="esito" onChange={e => onChangeSetDatiPuntoServizioControllo(e)} required>
                                                        <option selected={controllo && controllo.esito == false} value={false} >No</option>
                                                        <option selected={controllo && controllo.esito == true} value={true} >Si</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="div_wrap_25">
                                                <div className="col_input">
                                                    <label htmlFor="name">Nome ultimo controllo</label>
                                                    <input type="text" className="input w-input" maxLength="256" name="telefono" data-name="telefono" value={controllo && controllo.utente ? controllo.utente.nome + " " + controllo.utente.cognome : 'Nessuno'} placeholder="Nessuno" id="telefono" disabled />
                                                </div>
                                            </div>
                                            <div className="div_wrap_50">
                                                <div className="col_input">
                                                    <label htmlFor="name">Messaggio</label>
                                                    <textarea type="text" className="input w-input altezza_text" maxLength="500" name="messaggio" data-name="messaggio" value={controllo && controllo.messaggio} placeholder="Inserisci messaggio" id="messaggio" onChange={e => onChangeSetDatiPuntoServizioControllo(e)} />
                                                </div>
                                            </div>
                                            
                                        </div>
                                        <br/>
                                        </div>
                                        :
                                        ''
                                        }
                                        <Fragment>
                                            {loadingPuntoVendita ? null :
                                                contratti.map((contratto, i) => (
                                                    <Fragment>
                                                        {contratto.sporadico ? '' : 
                                                         <Fragment>
                                                        <div className="div_wrap_100 m_bottom_30">
                                                            <div className="div_wrap_25">
                                                                <div className="col_input"><label htmlFor="name-11">Data inizio</label>
                                                                    <div data-hover="false" data-delay="0" className="select w-dropdown select_data">
                                                                        <DatePicker
                                                                            onChange={(value) => contratto.nuovo ? onChangeSetContrattoPuntoVendita({ target: { value: value, name: 'dataInizio' } }, i) : null}
                                                                            disabled={contratto.nuovo ? false : true}
                                                                            value={contratto.dataInizio ? new Date(contratto.dataInizio) : null}
                                                                            calendarIcon={null}
                                                                            clearIcon={null}
                                                                            className={'form-control'}
                                                                        //disableCalendar={true}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="div_wrap_25">
                                                                <div className="col_input"><label htmlFor="name-11">Data fine</label>
                                                                    <div data-hover="false" data-delay="0" className="select w-dropdown select_data">
                                                                        <DatePicker
                                                                            onChange={(value) => onChangeSetContrattoPuntoVendita({ target: { value: value, name: 'dataFine' } }, i)}
                                                                            value={contratto.dataFine ? new Date(contratto.dataFine) : null}
                                                                            calendarIcon={null}
                                                                            clearIcon={null}
                                                                            className={'form-control'}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="div_wrap_25">
                                                                <div className="col_input"><label htmlFor="name-11">Orario inizio</label>
                                                                    <input className="input w-input" type="time" value={contratto.oraInizio} name="oraInizio" id={"oraInizio-" + { i }} onChange={e => contratto.nuovo ? onChangeSetContrattoPuntoVendita(e, i) : null} />
                                                                </div>
                                                            </div>
                                                            <div className="div_wrap_25">
                                                                <div className="col_input"><label htmlFor="name-11">Orario fine</label>
                                                                    <input className="input w-input" type="time" value={contratto.oraFine} name="oraFine" id={"oraFine-" + { i }} onChange={e => contratto.nuovo ? onChangeSetContrattoPuntoVendita(e, i) : null} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="div_wrap_100 m_bottom_30">
                                                            <div className="div_wrap_25">
                                                                <div className="col_input col_select">
                                                                    <label htmlFor="name">Dipendenti suggeriti</label>
                                                                    <div data-hover="false" data-delay="0" className="select w-dropdown">
                                                                        <select className="form-control select" name="dipendente" onChange={e => onChangeSetDipendente(e, i)} required>
                                                                            <option selected value={null} disabled >Seleziona dipendente...</option>
                                                                            {dipendenti.map((dipendente) => (
                                                                                <option value={dipendente._id}>{dipendente.cognome} {dipendente.nome}</option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="div_wrap_75">
                                                                <div className="col_input">
                                                                    <div data-hover="false" data-delay="0" className="w-dropdown scelte_suggerite_wrap">
                                                                        {contratto.dipendentiSuggeriti.map((dipendenteSuggerito, j) => (
                                                                            <p className="dipendente_suggerito_element"><span>{dipendenteSuggerito.cognome} {dipendenteSuggerito.nome}</span><a onClick={e => onClickDeleteDipendenteSuggerito(i, j)}>x</a></p>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="div_wrap_100">
                                                            <div className="col_input_row">
                                                                <label className="w-checkbox checkbox-field m_right_10">
                                                                    <input type="checkbox" id={"lunedi-" + { i }} className="w-checkbox-input" name="lunedi" checked={contratto.cadenza.lunedi} value={contratto.cadenza.lunedi} onChange={e => contratto.nuovo ? onChangeSetContrattoPuntoVendita(e, i) : null} />
                                                                    <span className="label_text w-form-label" htmlFor="checkbox-3">Lunedì</span>
                                                                </label>
                                                                <label className="w-checkbox checkbox-field m_right_10">
                                                                    <input type="checkbox" id={"martedi-" + { i }} className="w-checkbox-input" name="martedi" checked={contratto.cadenza.martedi} value={contratto.cadenza.martedi} onChange={e => contratto.nuovo ? onChangeSetContrattoPuntoVendita(e, i) : null} />
                                                                    <span className="label_text w-form-label" htmlFor="checkbox-3">Martedì</span>
                                                                </label>
                                                                <label className="w-checkbox checkbox-field m_right_10">
                                                                    <input type="checkbox" id={"mercoledi-" + { i }} className="w-checkbox-input" name="mercoledi" checked={contratto.cadenza.mercoledi} value={contratto.cadenza.mercoledi} onChange={e => contratto.nuovo ? onChangeSetContrattoPuntoVendita(e, i) : null} />
                                                                    <span className="label_text w-form-label" htmlFor="checkbox-3">Mercoledì</span>
                                                                </label>
                                                                <label className="w-checkbox checkbox-field m_right_10">
                                                                    <input type="checkbox" id={"giovedi-" + { i }} className="w-checkbox-input" name="giovedi" checked={contratto.cadenza.giovedi} value={contratto.cadenza.giovedi} onChange={e => contratto.nuovo ? onChangeSetContrattoPuntoVendita(e, i) : null} />
                                                                    <span className="label_text w-form-label" htmlFor="checkbox-3">Giovedì</span>
                                                                </label>
                                                                <label className="w-checkbox checkbox-field m_right_10">
                                                                    <input type="checkbox" id={"venerdi-" + { i }} className="w-checkbox-input" name="venerdi" checked={contratto.cadenza.venerdi} value={contratto.cadenza.venerdi} onChange={e => contratto.nuovo ? onChangeSetContrattoPuntoVendita(e, i) : null} />
                                                                    <span className="label_text w-form-label" htmlFor="checkbox-3">Venerdì</span>
                                                                </label>
                                                                <label className="w-checkbox checkbox-field m_right_10">
                                                                    <input type="checkbox" id={"sabato-" + { i }} className="w-checkbox-input" name="sabato" checked={contratto.cadenza.sabato} value={contratto.cadenza.sabato} onChange={e => contratto.nuovo ? onChangeSetContrattoPuntoVendita(e, i) : null} />
                                                                    <span className="label_text w-form-label" htmlFor="checkbox-3">Sabato</span>
                                                                </label>
                                                                <label className="w-checkbox checkbox-field m_right_10">
                                                                    <input type="checkbox" id={"domenica-" + { i }} className="w-checkbox-input" name="domenica" checked={contratto.cadenza.domenica} value={contratto.cadenza.domenica} onChange={e => contratto.nuovo ? onChangeSetContrattoPuntoVendita(e, i) : null} />
                                                                    <span className="label_text w-form-label" htmlFor="checkbox-3">Domenica</span>
                                                                </label>
                                                                <label className="w-checkbox checkbox-field m_right_10">
                                                                    <input type="checkbox" id={"festivi-" + { i }} className="w-checkbox-input" name="festivi" checked={contratto.cadenza.festivi} value={contratto.cadenza.festivi} onChange={e => contratto.nuovo ? onChangeSetContrattoPuntoVendita(e, i) : null} />
                                                                    <span className="label_text w-form-label" htmlFor="checkbox-3">Festivi</span>
                                                                </label>
                                                                <label className="w-checkbox checkbox-field m_right_10">
                                                                    <input type="checkbox" id={"semifestivi-" + { i }} className="w-checkbox-input" name="semifestivi" checked={contratto.cadenza.semifestivi} value={contratto.cadenza.semifestivi} onChange={e => contratto.nuovo ? onChangeSetContrattoPuntoVendita(e, i) : null} />
                                                                    <span className="label_text w-form-label" htmlFor="checkbox-3">Semifestivi</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="div_wrap_100">
                                                            <div className="col_input_row">
                                                                <label className="w-checkbox checkbox-field m_right_10">
                                                                    <input type="checkbox" id={"continuativo-" + { i }} className="w-checkbox-input" name="continuativo" checked={contratto.continuativo} value={contratto.continuativo} onChange={e => contratto.nuovo ? onChangeSetContrattoPuntoVendita(e, i) : null} />
                                                                    <span className="label_text w-form-label" htmlFor="checkbox-3">Continuativo</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="div_wrap_100">
                                                            <div className="col_input_row">
                                                                <label className="w-checkbox checkbox-field m_right_10">
                                                                    <input type="checkbox" id={"riferitoGiornoDopo-" + { i }} className="w-checkbox-input" name="riferitoGiornoDopo" checked={contratto.riferitoGiornoDopo} value={contratto.riferitoGiornoDopo} onChange={e => onChangeRiferitoGiornoDopo(e, i)} />
                                                                    <span className="label_text w-form-label" htmlFor="checkbox-3">Riferito al giorno dopo</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="row m_bottom_10">
                                                            <div className="item_5 m_top_5">
                                                                <div className="item_6_function">
                                                                    <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc3d-43174afa" className="div_item_empty"></div>
                                                                    <div id="w-node-d27a7e1c-7034-f43d-edeb-5fcab2bbc790-43174afa" className="div_item_empty"></div>
                                                                    <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc40-43174afa" className="div_item_empty"></div>
                                                                    <div id="w-node-d409c487-9a0c-6b1f-803c-c7c11a8afa3e-43174afa" className="div_item_empty"></div>
                                                                    {contratto.nuovo ?
                                                                    <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc43-43174afa" className="div_item_button">
                                                                        <a id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc44-43174afa" onClick={() => deleteContrattoRow(i)} className="button w-button">Elimina</a>
                                                                    </div>
                                                                    :
                                                                    <div id="w-node-d409c487-9a0c-6b1f-803c-c7c11a8afa3e-43174afa" className="div_item_empty"></div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        </Fragment>
                                                    }
                                                    </Fragment>
                                                ))}
                                        </Fragment>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="item_5 m_top_5">
                            <div className="item_6_function">
                                <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc3d-43174afa" className="div_item_empty"></div>
                                <div id="w-node-d27a7e1c-7034-f43d-edeb-5fcab2bbc790-43174afa" className="div_item_empty"></div>
                                <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc40-43174afa" className="div_item_empty"></div>
                                <div id="w-node-d409c487-9a0c-6b1f-803c-c7c11a8afa3e-43174afa" className="div_item_empty"></div>
                                <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc43-43174afa" className="div_item_button">
                                    <a id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc44-43174afa" onClick={() => addContrattoRow()} className="button w-button">Aggiungi riga contratto</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="item_6 m_top_5">
                            <div className="item_5_function">
                                <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc3d-b8dac9c0" className="div_item_empty"></div>
                                <div id="w-node-d27a7e1c-7034-f43d-edeb-5fcab2bbc790-b8dac9c0" className="div_item_empty"></div>
                                <div id="w-node-d27a7e1c-7034-f43d-edeb-5fcab2bbc790-b8dac9c0" className="div_item_empty"></div>
                                <div id="w-node-d409c487-9a0c-6b1f-803c-c7c11a8afa3e-b8dac9c0" className="div_item_button">
                                    <a onClick={(e) => onSubmitModificaPuntoServizio(e)} className="button w-button">Salva</a>
                                </div>
                                <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc43-b8dac9c0" className="div_item_button">
                                    <a id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc44-b8dac9c0" onClick={() => setOpenModificaPuntoServizio(false)} className="button w-button">Chiudi</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

ModificaCliente.protoTypes = {
    getPuntoVendita: PropTypes.func.isRequired,
    modificaPuntoVendita: PropTypes.func.isRequired,
    puntoVendita: PropTypes.object.isRequired,
    clearPuntoVendita: PropTypes.func.isRequired,
    getDipendenti: PropTypes.func.isRequired,
    getClienti: PropTypes.func.isRequired,
    alert: PropTypes.object.isRequired,
    cliente: PropTypes.object.isRequired,
    dipendente: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    puntoVendita: state.puntoVendita,
    alert: state.alert,
    cliente: state.cliente,
    dipendente: state.dipendente,
    auth: state.auth
});

export default connect(mapStateToProps, { getPuntoVendita, modificaPuntoVendita, clearPuntoVendita, getDipendenti, getClienti })(ModificaCliente);

