import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import DatePicker from 'react-date-picker';
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import Moment from 'react-moment'
import {Helmet} from "react-helmet";
import PDFIncarichiTuttiDipendenti from './PDFIncarichiTuttiDipendenti'
import Spinner from '../../layout/Spinner';
import {generaFileTRRIPA} from './generaFileTRRIPA'
import { getFiliali } from '../../../actions/filiale';
import { getIncarichiTuttiDipendentiSalvati, cleanIncarichiPDFDipendenti } from '../../../actions/dipendente';

const GeneraTrripa = ({ auth: { user }, dipendente: { incarichiDipendenteSalvati }, alert, getIncarichiTuttiDipendentiSalvati, cleanIncarichiPDFDipendenti, filiale: { filiali }, getFiliali }) => {



    const [dataInizioInput, setDataInizioInput] = useState((setHours(setMinutes(startOfMonth(new Date()), 0), 0)));
    const [dataFineInput, setDataFineInput] = useState((setHours(setMinutes(endOfMonth(new Date()), 59), 23)));
    const [dataInizioInputInvio, setDataInizioInputInvio] = useState((setHours(setMinutes(startOfMonth(new Date()), 0), 0)));
    const [dataFineInputInvio, setDataFineInputInvio] = useState((setHours(setMinutes(endOfMonth(new Date()), 59), 23)));
    const [rowSelected, setRowSelected] = useState({ id: '', dipendenti: []});
    const [arrayPDFIncarichiDipendente, setArrayPDFIncarichiDipendente] = useState([]);
    const [loader, setLoader] = useState(false);
    const [filialiList, setFilialiList] = useState([]);
    const [filtraFiliale, setFiltraFiliale] = useState(null);


    useEffect(() => {
        cleanIncarichiPDFDipendenti()
    }, []);

    useEffect(() => {
        if (user && user.role == "organizzazione") {
            getFiliali();
        }
    }, [user]);

    useEffect(() => {
        if (filiali.length > 0) {
            let array = [];
            filiali.map(filiale => {
                const index = user.vistaFiliali.findIndex(el => el._id == filiale._id);
                if(index >= 0){
                    array.push(filiale)
                    setFiltraFiliale(filiale._id)
                }
            })
            setFilialiList(array)
        }
    }, [filiali]);


    useEffect(() => {
        if(incarichiDipendenteSalvati.length > 0){
            incarichiDipendenteSalvati.sort( function(a , b){
                if(a.dipendente.cognome == b.dipendente.denominazione){
                    if(a.dipendente.nome > b.dipendente.nome){
                        return 1;
                    }else{
                        return -1
                    }
                }else{
                    return a.dipendente.cognome > b.dipendente.cognome ? 1 : -1;
                }
            });
            setArrayPDFIncarichiDipendente(incarichiDipendenteSalvati)
        }
    }, [incarichiDipendenteSalvati]);

    useEffect(() => {
        const size = alert.length;
        if (size > 0) {
            setLoader(false)
        }
    }, [alert]);

    const onChangeRangeDateInizio = (data) => {
        setDataFineInput(setHours(setMinutes(endOfMonth(data), 59), 23))
        setDataInizioInput(data)
    }


    const onChangeRangeDateFine = (data) => {
        setDataFineInput(data)
    }


    const onClickCercaPDFIncarichiDipendenti = (idFiliale) => {
        setArrayPDFIncarichiDipendente([])
        setLoader(true)
        getIncarichiTuttiDipendentiSalvati({dataInizio: dataInizioInput, dataFine: dataFineInput, idFiliale: idFiliale})
    }

    

    const onClickGeneraTuttiTRRIPA = () => {
        generaFileTRRIPA(incarichiDipendenteSalvati, user)
    } 


    



    const onChangeFiltraDipendentiPerFiliale = e => {
        setFiltraFiliale(e.target.value)
    };

    return (
        <div className="body wf-section">
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`File TRRIPA - Gestionale Vigili dell'ordine`}</title>
            </Helmet>
            <div className="div_wrap">
                <div className="div_wrap_100">
                    <div className="div_wrap_50">
                        <div className="div_container">
                            <div className="title_window">
                                <div className="title_window_p">PDF incarichi dipendenti</div>
                            </div>
                            <div className="dati_container">
                                <div className="fun_assegnazione_incarichi">
                                    <div className="row row_datapicker">
                                        <div className="item_1 filtro_wrap_incarichi">
                                            <div id="w-node-_284812ee-7924-9f38-86bb-8d2cc43dff3c-867f153d" className="div_item_1">
                                                <div id="w-node-_80bb2acb-3043-3649-0498-379a652f3b04-867f153d" className="label_text">Data inizio</div>
                                                <div data-hover="false" data-delay="0" className="select w-dropdown">
                                                    <DatePicker
                                                        value={dataInizioInput}
                                                        format="dd/MM/y"
                                                        weekPicker
                                                        onChange={onChangeRangeDateInizio}
                                                        locale="it-IT"
                                                        clearIcon={null}
                                                        weekStartDayIndex={1}
                                                    />
                                                </div>
                                            </div>
                                            <div id="w-node-_284812ee-7924-9f38-86bb-8d2cc43dff3c-867f153d" className="div_item_1">
                                                <div id="w-node-_80bb2acb-3043-3649-0498-379a652f3b04-867f153d" className="label_text">Data fine</div>
                                                <div data-hover="false" data-delay="0" className="select w-dropdown">
                                                    <DatePicker
                                                        value={dataFineInput}
                                                        format="dd/MM/y"
                                                        weekPicker
                                                        onChange={onChangeRangeDateFine}
                                                        locale="it-IT"
                                                        clearIcon={null}
                                                        weekStartDayIndex={1}
                                                    />
                                                </div>
                                            </div>
                                            <div id="w-node-_284812ee-7924-9f38-86bb-8d2cc43dff3c-867f153d" className="div_item_1">
                                            <div id="w-node-_7b40e076-1929-a489-3ae4-e7e497d010fa-867f153d" className="label_text">Filiale</div>
                                            <div data-hover="false" data-delay="0" className="select w-dropdown">
                                                        <select className="form-control select" name="idCliente" onChange={e => onChangeFiltraDipendentiPerFiliale(e)} required>
                                                            {filialiList.map((elementoFiliale, i) => (
                                                                <option key={i} value={elementoFiliale._id} selected={elementoFiliale._id == filtraFiliale} >{elementoFiliale.nome}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                            </div>
                                            <div id="w-node-_98d32d21-f772-2386-6ce9-47c8e0ec064d-867f153d" className="div_item_1">
                                                <div className="w-form">
                                                    <div id="email-form" name="email-form" data-name="Email Form" method="get">
                                                        <label className="w-checkbox checkbox-field">
                                                            <span className="label_text w-form-label" htmlFor="checkbox"></span>
                                                        </label>
                                                        <button id="w-node-a7f8735f-5a06-66e1-52ab-69f728a73208-867f153d" onClick={() => { onClickCercaPDFIncarichiDipendenti(filtraFiliale) }} className="button w-button" >Aggiorna</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="item_2">
                                            <div className="intestazione_table">
                                                <div className="w-layout-grid div_column_pdf_dipendenti intestazione">
                                                    <div className="div_item_column">
                                                        <div className="item_table">
                                                            <div className="item_table"></div>
                                                        </div>
                                                    </div>
                                                    <div className="div_item_column">
                                                        <div className="item_table">Dipendente</div>
                                                    </div>
                                                    <div className="div_item_column">
                                                        <div className="item_table">Da</div>
                                                    </div>
                                                    <div className="div_item_column">
                                                        <div className="item_table">A</div>
                                                    </div>
                                                </div>
                                            </div>
                                                <div className="body_table">
                                                    {loader ? <Spinner /> :
                                                    arrayPDFIncarichiDipendente.map((el, i) => (
                                                            <div key={"esitoInvio" + i} className={`w-layout-grid div_column_pdf_dipendenti cursor ${rowSelected.id == el._id ? 'esito_selected' : ''}`}>
                                                                <div className="div_item_column">
                                                                    <div className="item_table"></div>
                                                                </div>
                                                                <div className="div_item_column">
                                                                    <div className="item_table">{el.dipendente.cognome} {el.dipendente.nome}</div>
                                                                </div>
                                                                <div className="div_item_column">
                                                                    <div className="item_table"><Moment format='DD/MM/YYYY'>{el.dataInizio}</Moment></div>
                                                                </div>
                                                                <div className="div_item_column">
                                                                    <div className="item_table"><Moment format='DD/MM/YYYY'>{el.dataFine}</Moment></div>
                                                                </div>
                                                            </div>
                                                    ))}
                                                </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="item_3 m_top_5">
                                            <div className="list_button">
                                                <div id="w-node-_571e9ca7-8650-59ca-aaad-79ac7369ac51-867f153d" className="div_item_button">
                                                    <a id="w-node-_571e9ca7-8650-59ca-aaad-79ac7369ac52-867f153d" onClick={(e) => { onClickGeneraTuttiTRRIPA() }} className="button w-button">Genera TRRIPA</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <PDFIncarichiTuttiDipendenti 
                            dataInizioInput={dataInizioInput}
                            dataFineInput={dataFineInput}
                            arrayPDFIncarichiDipendente={arrayPDFIncarichiDipendente}
                        />
                </div>
            </div>
        </div>
    )
}

GeneraTrripa.protoTypes = {
    getFiliali: PropTypes.func.isRequired,
    filiale: PropTypes.object.isRequired,
    dipendente: PropTypes.object.isRequired,
    alert: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    getIncarichiTuttiDipendentiSalvati: PropTypes.func.isRequired,
    cleanIncarichiPDFDipendenti: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    cliente: state.cliente,
    filiale: state.filiale,
    dipendente: state.dipendente,
    alert: state.alert,
    auth: state.auth
});

export default connect(mapStateToProps, {getIncarichiTuttiDipendentiSalvati, cleanIncarichiPDFDipendenti, getFiliali})(GeneraTrripa);