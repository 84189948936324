import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import DatePicker from 'react-date-picker';
import Spinner from '../../layout/Spinner';
import { getRichiesteArchiviate, annullaArchiviaRichieste } from '../../../actions/richiesta';
const RichiesteArchiviate = ({ richiesta: { richiesteArchiviate }, getRichiesteArchiviate, annullaArchiviaRichieste }) => {


    const [dataInput, setDataInput] = useState((setHours(setMinutes(new Date(), 0), 12)));
    const [rowSelected, setRowSelected] = useState({ id: '', idRichiesta: '' });
    const [idCliente, setIdCliente] = useState('');
    const [checkBoxServizi, setCheckBoxServizi] = useState([]);
    const [loadingContrattiRichieste, setLoadingContrattiRichieste] = useState(false);
    const [disableButton, setDisableButton] = useState(false);


    const onChangeRangeDate = (data) => {
        setRowSelected({ id: '', idRichiesta: '' })
        setDataInput(data)
    }

    const onClickSetRowSelected = async ({ id, idRichiesta }) => {
        setRowSelected({ id, idRichiesta })
    }

    const onClickGetServiziArchiviati = async () => {
        getRichiesteArchiviate({ idCliente: idCliente, dataInizio: dataInput })
    }

    const onChangeSetCheckboxServizi = ({ name, id }) => {
        if (id) {
            let arrayCheckboxServizi = [...checkBoxServizi];
            const index = checkBoxServizi.findIndex(el => el == id);
            if (index >= 0) {
                console.log(index)
                arrayCheckboxServizi.splice(index, 1);
            } else {
                arrayCheckboxServizi.push(id);
            }
            setCheckBoxServizi(arrayCheckboxServizi)
        } else if (name === "tuttiContratti") {
            let array = [];
            richiesteArchiviate.map((el) => {
                array.push(el._id)
                setCheckBoxServizi(array)
            })
        }
    };

    const onClickArchiviaRichieste = (() => {
        if (checkBoxServizi.length > 0) {
            annullaArchiviaRichieste({ richieste: checkBoxServizi })
        }
    })

    return (
        <div className="body wf-section">
            <div className="div_wrap">
                <div className="div_wrap_100">
                    <div className="div_wrap_50">
                        <div className="div_container">
                            <div className="title_window">
                                <div className="title_window_p">Incarichi archiviati</div>
                            </div>
                            <div className="dati_container">
                                <div className="fun_assegnazione_incarichi">
                                    <div className="row row_datapicker">
                                        <div className="item_1">
                                            <div id="w-node-_7b40e076-1929-a489-3ae4-e7e497d010f9-867f153d" className="div_item_1">
                                            </div>
                                            <div id="w-node-_284812ee-7924-9f38-86bb-8d2cc43dff3c-867f153d" className="div_item_1">
                                                <div id="w-node-_80bb2acb-3043-3649-0498-379a652f3b04-867f153d" className="label_text">Data</div>
                                                <div data-hover="false" data-delay="0" className="select w-dropdown">
                                                    <DatePicker
                                                        value={dataInput}
                                                        format="dd/MM/y"
                                                        weekPicker
                                                        onChange={onChangeRangeDate}
                                                        //showTodayButton={false}
                                                        //weekDays={weekDays}
                                                        //months={months}
                                                        locale="it-IT"
                                                        clearIcon={null}
                                                        weekStartDayIndex={1}
                                                    />
                                                </div>
                                            </div>
                                            <div id="w-node-_98d32d21-f772-2386-6ce9-47c8e0ec064d-867f153d" className="div_item_1">
                                                <div className="w-form">
                                                    <div id="email-form" name="email-form" data-name="Email Form" method="get">
                                                        <label className="w-checkbox checkbox-field">
                                                            <input type="checkbox" id="checkbox" name="checkbox" data-name="Checkbox" className="w-checkbox-input" />
                                                            <span className="label_text w-form-label" htmlFor="checkbox">Resti</span>
                                                        </label>
                                                        <button id="w-node-a7f8735f-5a06-66e1-52ab-69f728a73208-867f153d" onClick={() => { onClickGetServiziArchiviati() }} className="button w-button" disabled={disableButton}>Conferma</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id="w-node-_714e71a9-0550-fe0e-26e9-8b45ac42ab89-867f153d" className="div_item_1">
                                            </div>
                                            <div id="w-node-_32403d1b-d20e-1341-c5d5-395ced6b5d87-867f153d" className="div_item_1">
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="item_2">
                                            <div className="intestazione_table">
                                                <div className="w-layout-grid div_column_servizi_archiviati intestazione">
                                                    <div className="div_item_column">
                                                        <div className="item_table">
                                                            <input type="checkbox" id="checkboxSelezionaTutto" name="checkboxSelezionaTutto" data-name="checkboxSelezionaTutto" className="w-checkbox-input checkbox_selection" onChange={e => onChangeSetCheckboxServizi({ name: "tuttiContratti", id: null })} />
                                                        </div>
                                                    </div>
                                                    <div className="div_item_column">
                                                        <div className="item_table"></div>
                                                    </div>
                                                    <div className="div_item_column">
                                                        <div className="item_table">Codice</div>
                                                    </div>
                                                    <div className="div_item_column">
                                                        <div className="item_table">Servizio</div>
                                                    </div>
                                                    <div className="div_item_column">
                                                        <div className="item_table">Dalle</div>
                                                    </div>
                                                    <div className="div_item_column">
                                                        <div className="item_table">Alle</div>
                                                    </div>
                                                    <div className="div_item_column">
                                                        <div className="item_table">Dipendente</div>
                                                    </div>
                                                </div>
                                            </div>
                                            {loadingContrattiRichieste ? <Spinner /> :
                                                <div className="body_table">
                                                    {richiesteArchiviate.map((richiesta, i) => (
                                                        <div key={i} className={`w-layout-grid div_column_servizi_archiviati cursor ${rowSelected.id == richiesta.id ? 'servizio_selected' : ''}`}>
                                                            <div className="div_item_column">
                                                                <div className="item_table"><input type="checkbox" id={`checkboxSeleziona${i}`} name={`checkboxSeleziona${i}`} data-name={`checkboxSeleziona${i}`} className="w-checkbox-input checkbox_selection" checked={checkBoxServizi.findIndex(el => el == richiesta._id) >= 0 ? true : false} onChange={() => onChangeSetCheckboxServizi({ name: "contrattoSingolo", id: richiesta._id })} /></div>
                                                            </div>
                                                            <div className="div_item_column" onClick={() => onClickSetRowSelected({ id: richiesta._id })}>
                                                                <div className="item_table">{i + 1}</div>
                                                            </div>
                                                            <div className="div_item_column" onClick={() => onClickSetRowSelected({ id: richiesta._id })}>
                                                                <div className="item_table">{richiesta.codice}</div>
                                                            </div>
                                                            <div className="div_item_column" onClick={() => onClickSetRowSelected({ id: richiesta._id })}>
                                                                <div className="item_table">{richiesta.nome}</div>
                                                            </div>
                                                            <div className="div_item_column" onClick={() => onClickSetRowSelected({ id: richiesta._id })}>
                                                                <div className="item_table">{richiesta.oraInizio}</div>
                                                            </div>
                                                            <div className="div_item_column" onClick={() => onClickSetRowSelected({ id: richiesta._id })}>
                                                                <div className="item_table">{richiesta.oraFine}</div>
                                                            </div>
                                                            <div className="div_item_column" onClick={() => onClickSetRowSelected({ id: richiesta._id })}>
                                                                <div className="item_table" >{richiesta.dipendente ? richiesta.dipendente.nome + " " + richiesta.dipendente.cognome : ''}</div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="item_3 m_top_5">
                                            <div className="list_button">
                                                <div id="w-node-bf561da2-25a7-b3ef-c44c-a819295560e3-867f153d" className="div_item_empty"></div>
                                                <div id="w-node-bf561da2-25a7-b3ef-c44c-a819295560e3-867f153d" className="div_item_empty"></div>
                                                <div id="w-node-bf561da2-25a7-b3ef-c44c-a819295560e3-867f153d" className="div_item_empty"></div>
                                                <div id="w-node-bf561da2-25a7-b3ef-c44c-a819295560e3-867f153d" className="div_item_empty"></div>
                                                <div id="w-node-bf561da2-25a7-b3ef-c44c-a819295560e3-867f153d" className="div_item_empty"></div>
                                                <div id="w-node-bf561da2-25a7-b3ef-c44c-a819295560e3-867f153d" className="div_item_empty"></div>
                                                <div id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98d-867f153d" className="div_item_button">
                                                    <a id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98e-867f153d" className="button w-button" onClick={() => onClickArchiviaRichieste()}>Annulla Archiviazione</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

RichiesteArchiviate.protoTypes = {
    getRichiesteArchiviate: PropTypes.func.isRequired,
    annullaArchiviaRichieste: PropTypes.func.isRequired,
    richiesta: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    richiesta: state.richiesta
});

export default connect(mapStateToProps, { getRichiesteArchiviate, annullaArchiviaRichieste })(RichiesteArchiviate);
