import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Moment from 'react-moment'
import DatePicker from 'react-date-picker';
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import Spinner from '../layout/Spinner';
import {Helmet} from "react-helmet";
import XlsxPopulate from "xlsx-populate";
import { saveAs } from "file-saver";
import { useReactToPrint } from 'react-to-print';
import { getRiposiNonRecuperati, associaRecuperoRiposo, getDocumentiInScadenza } from '../../actions/dati';

const Dashboard = ({ auth, dati: { riposiNonRecuperati, documentiInScadenza, loading }, getRiposiNonRecuperati, associaRecuperoRiposo, alert, getDocumentiInScadenza }) => {

    const [riposiNonRecuperatirowSelected, setRiposiNonRecuperatiRowSelected] = useState({ id: '', idRichiesta: '' });
    const [openPopUp, setOpenPopUp] = useState(null);
    const [dataInputRR, setDataInputRR] = useState((setHours(setMinutes(new Date(), 0), 12)));
    const [dataInizioDocumentiInScadenza, setDataInizioDocumentiInScadenza] = useState((setHours(setMinutes(startOfMonth(new Date()), 0), 0)));
    const [dataFineDocumentiInScadenza, setDataFineDocumentiInScadenza] = useState((setHours(setMinutes(endOfMonth(new Date()), 59), 23)));
    const [documentiDipendentiInScadenza, setDocumentiDipendentiInScadenza] = useState([]);
    const [tipologiaSelected, setTipologiaSelected] = useState("");
    const [riposiNonRecuperatiData, setRiposiNonRecuperatiData] = useState([]);
    const [documentiInScadenzaCheckbox, setDocumentiInScadenzaCheckbox] = useState(true);
    const [csvData, setCsvData] = useState([]);

    const headers = [
        { label: 'Data', key: 'dataCSV' },
        { label: 'Dipendente', key: 'dipendente' },
        { label: 'Documento', key: 'documento' },
        { label: 'Filiale', key: 'filiale' }
    ]

    //*
    useEffect(() => {
        getRiposiNonRecuperati()
        getDocumentiInScadenza({ dataInizio: dataInizioDocumentiInScadenza, dataFine: dataFineDocumentiInScadenza, tipologia: tipologiaSelected, scaduti: documentiInScadenzaCheckbox })
    }, []);

    //*
    useEffect(() => {
        setCsvData([])
        setDocumentiDipendentiInScadenza([])
        getDocumentiInScadenza({ dataInizio: dataInizioDocumentiInScadenza, dataFine: dataFineDocumentiInScadenza, tipologia: tipologiaSelected, scaduti: documentiInScadenzaCheckbox })
    }, [dataInizioDocumentiInScadenza, dataFineDocumentiInScadenza]);

    //*
    useEffect(() => {
        const size = alert.length;
        if (size > 0) {
            if (alert[size - 1].alertType === "success" && alert[size - 1].field == 'add_recupero_riposo') {
                setRiposiNonRecuperatiRowSelected({ id: '', idRichiesta: '' });
                setOpenPopUp(null)
            }
        }
    }, [alert]);

    useEffect(() => {
        let array = []
        let arrayCSV = []
        documentiInScadenza.map(el => {
            if(el.documento.tipologia == "tiro-a-segno"){
                if(documentiInScadenzaCheckbox){
                    if(!el.documento.eseguito1){
                        array.push({documento: {dataScadenza: el.documento.dataScadenza1, nome: el.documento.nome}, dipendente: el.dipendente })
                    }else if(!el.documento.eseguito2){
                        array.push({documento: {dataScadenza: el.documento.dataScadenza2, nome: el.documento.nome}, dipendente: el.dipendente })
                    }else if(!el.documento.eseguito3){
                        array.push({documento: {dataScadenza: el.documento.dataScadenza3, nome: el.documento.nome}, dipendente: el.dipendente })
                    }
                }else{
                    if(el.documento.eseguito3){
                        array.push({documento: {dataScadenza: el.documento.dataScadenza3, nome: el.documento.nome}, dipendente: el.dipendente })
                    }else if(el.documento.eseguito2){
                        array.push({documento: {dataScadenza: el.documento.dataScadenza2, nome: el.documento.nome}, dipendente: el.dipendente })
                    }else if(el.documento.eseguito1){
                        array.push({documento: {dataScadenza: el.documento.dataScadenza1, nome: el.documento.nome}, dipendente: el.dipendente })
                    }
                }
                
            }else{
                array.push(el)
            }
        })
        //array.sort((a, b) => (a.documento.dataScadenza > b.documento.dataScadenza) ? 1 : -1)
        array.sort((a, b) => {
            if(a.dipendente.filiale.nome > b.dipendente.filiale.nome){
                return 1
            }else if(a.dipendente.filiale.nome < b.dipendente.filiale.nome){
                return -1
            }else{
                if(a.documento.dataScadenza > b.documento.dataScadenza){
                    return 1
                }else{
                    return -1
                }
            }
        })

        array.map(el => {
            arrayCSV.push({ dataCSV: toIsoString(new Date(el.documento.dataScadenza)), dipendente: el.dipendente.cognome + " - " + el.dipendente.nome, documento: el.documento.nome == 'portodarmi' ? "porto d'armi" : el.documento.nome, filiale: el.dipendente.filiale.nome })
        })

        setDocumentiDipendentiInScadenza(array)
        setCsvData(arrayCSV)
    }, [documentiInScadenza]);

    //*
    useEffect(() => {

        let array = []

        array = riposiNonRecuperati.filter(el => {
            let data = new Date(el.dataInizio)
            let anno = data.getFullYear()
            return anno > 2022
        })

        setRiposiNonRecuperatiData(array)
        
    }, [riposiNonRecuperati]);

    //*
    const onClickSetRowSelected = ({ id }) => {
        setRiposiNonRecuperatiRowSelected({ id })
    }

    //*
    const onClickOpenPopUp = (tipologia) => {
        setOpenPopUp(tipologia)
    }

    //*
    const onClickAssociaRecuperoRiposo = () => {
        const index = riposiNonRecuperatiData.findIndex(el => el._id == riposiNonRecuperatirowSelected.id);
        if (index > -1) {
            associaRecuperoRiposo({ idRichiesta: riposiNonRecuperatiData[index]._id, dataRecuperoRiposo: dataInputRR })
        }
    }

    //*
    const onChangeRangeDateRR = (data) => {
        setDataInputRR(data)
    }

    const onChangeTipologiaDocumento = (value) => {
        setTipologiaSelected(value)
        setCsvData([])
        setDocumentiDipendentiInScadenza([])
        getDocumentiInScadenza({ dataInizio: dataInizioDocumentiInScadenza, dataFine: dataFineDocumentiInScadenza, tipologia: value, scaduti: documentiInScadenzaCheckbox })
    }

    //*
    const onChangeSetCheckboxDocumentiInScadenzaCheckbox = (value) => {
        setDocumentiInScadenzaCheckbox(value == 'true' ? true : false )
        setCsvData([])
        setDocumentiDipendentiInScadenza([])
        getDocumentiInScadenza({ dataInizio: dataInizioDocumentiInScadenza, dataFine: dataFineDocumentiInScadenza, tipologia: tipologiaSelected, scaduti: value })
    }

    async function saveAsExcel(data, header) {

        XlsxPopulate.fromBlankAsync().then(async (workbook) => {
            const sheet1 = workbook.sheet(0);
            const sheetData = getSheetData(data, header);
            const totalColumns = sheetData[0].length;

            sheet1.cell("A1").value(sheetData);
            const range = sheet1.usedRange();
            const endColumn = String.fromCharCode(64 + totalColumns);
            sheet1.row(1).style("bold", true);
            sheet1.range("A1:" + endColumn + "1").style("fill", "BFBFBF");
            range.style("border", true);
            return workbook.outputAsync().then((res) => {
                saveAs(res, `Documenti ${documentiInScadenzaCheckbox ? 'in scadenza' : "effettuati"}.xlsx`);
            });
        });
    }

    function getSheetData(data, header) {
        var fields = Object.keys(data[0]);
        var sheetData = data.map(function (row) {
            return fields.map(function (fieldName) {
                return row[fieldName] ? row[fieldName] : "";
            });
        });
        const headerLabel = header.map(function (row) { return row.label })
        sheetData.unshift(headerLabel);
        return sheetData;
    }

    const toIsoString = (date) => {
        const pad = function (num) {
            return (num < 10 ? '0' : '') + num;
        };

        return pad(date.getDate()) +
            '-' + pad(date.getMonth() + 1) +
            '-' + date.getFullYear()
    }

    const componentRef = useRef();

    const print = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: `Documenti ${documentiInScadenzaCheckbox ? 'in scadenza' : "effettuati"}`
    });

    return (auth.loading ? <Spinner /> :
        <div className="body wf-section">
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`Home - Gestionale Vigili dell'ordine`}</title>
            </Helmet>
            <div className="div_wrap">
                <div className="div_wrap_100">
                    <div className="div_wrap_50">
                        <div className="div_container">
                            <div className="title_window">
                                <div className="title_window_p">Riposi non recuperati</div>
                                <span className={`${riposiNonRecuperatiData.length === 0 ? "green_label" : "red_label"} label_incarichi`}>{riposiNonRecuperatiData.length}</span>
                            </div>
                            <div className="dati_container">
                                <div className="fun_assegnazione_incarichi">
                                    <div className="row">
                                        <div className="item_2">
                                            <div className="intestazione_table">
                                                <div className="w-layout-grid div_column_alert_riposi_non_recuperati intestazione">
                                                    <div className="div_item_column">
                                                        <div className="item_table">Data</div>
                                                    </div>
                                                    <div className="div_item_column">
                                                        <div className="item_table">Servizio</div>
                                                    </div>
                                                    <div className="div_item_column">
                                                        <div className="item_table">Località</div>
                                                    </div>
                                                    <div className="div_item_column">
                                                        <div className="item_table">Dalle</div>
                                                    </div>
                                                    <div className="div_item_column">
                                                        <div className="item_table">Alle</div>
                                                    </div>
                                                    <div className="div_item_column">
                                                        <div className="item_table">Dipendente</div>
                                                    </div>
                                                    <div className="div_item_column">
                                                        <div className="item_table">Filiale</div>
                                                    </div>
                                                </div>
                                            </div>
                                            {riposiNonRecuperatiData.length > 0 ?
                                                <div className="body_table">
                                                    {riposiNonRecuperatiData.map((richiesta, i) => (
                                                        <div key={"riposoNonRecuperato" + i} className={`w-layout-grid div_column_alert_riposi_non_recuperati cursor ${riposiNonRecuperatirowSelected.id == richiesta._id ? 'riposo_selected' : ''}`}>
                                                            <div className="div_item_column" onClick={() => onClickSetRowSelected({ id: richiesta._id })}>
                                                                <div className="item_table"><Moment format='DD/MM/YYYY'>{richiesta.dataInizio}</Moment></div>
                                                            </div>
                                                            <div className="div_item_column" onClick={() => onClickSetRowSelected({ id: richiesta._id })}>
                                                                <div className="item_table">{richiesta.puntoVendita.denominazione}</div>
                                                            </div>
                                                            <div className="div_item_column" onClick={() => onClickSetRowSelected({ id: richiesta._id })}>
                                                                <div className="item_table">{richiesta.puntoVendita.localita} - {richiesta.puntoVendita.indirizzo}</div>
                                                            </div>
                                                            <div className="div_item_column" onClick={() => onClickSetRowSelected({ id: richiesta._id })}>
                                                                <div className="item_table">{richiesta.oraInizio}</div>
                                                            </div>
                                                            <div className="div_item_column" onClick={() => onClickSetRowSelected({ id: richiesta._id })}>
                                                                <div className="item_table">{richiesta.oraFine}</div>
                                                            </div>
                                                            <div className="div_item_column" onClick={() => onClickSetRowSelected({ id: richiesta._id })}>
                                                                <div className="item_table">{richiesta.dipendente && richiesta.dipendente.cognome} {richiesta.dipendente && richiesta.dipendente.nome}</div>
                                                            </div>
                                                            <div className="div_item_column" onClick={() => onClickSetRowSelected({ id: richiesta._id })}>
                                                                <div className="item_table">{richiesta.dipendente && richiesta.dipendente.filiale.nome}</div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                                :
                                                ""
                                            }
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="item_3 m_top_5">
                                            <div className="list_button list_button_2">
                                                <div id="w-node-bf561da2-25a7-b3ef-c44c-a819295560e3-867f153d" className="div_item_empty"></div>
                                                <div id="w-node-bf561da2-25a7-b3ef-c44c-a819295560e3-867f153d" className="div_item_empty"></div>
                                                <div id="w-node-bf561da2-25a7-b3ef-c44c-a819295560e3-867f153d" className="div_item_empty"></div>
                                                <div id="w-node-bf561da2-25a7-b3ef-c44c-a819295560e3-867f153d" className="div_item_empty"></div>
                                                <div id="w-node-bf561da2-25a7-b3ef-c44c-a819295560e3-867f153d" className="div_item_empty"></div>
                                                <div id="w-node-bf561da2-25a7-b3ef-c44c-a819295560e3-867f153d" className="div_item_empty"></div>
                                                <div id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98d-867f153d" className="div_item_button">
                                                    <a id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98e-867f153d" className={`button w-button ${riposiNonRecuperatirowSelected.id.length == 0 ? "button_disabled" : ""}`} onClick={(e) => (riposiNonRecuperatirowSelected.id.length == 0 ? null : onClickOpenPopUp("recuperoRiposo"))}>Associa</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {openPopUp == "recuperoRiposo" ?
                        <div className="popup wf-section">
                            <div className="alert_popup">
                                <p>Scegli la data a cui associare il recupero riposo</p>
                                <div className="div-block">
                                    <>
                                        <div data-hover="false" data-delay="0" className="select w-dropdown">
                                            <DatePicker
                                                value={dataInputRR}
                                                format="dd/MM/y"
                                                weekPicker
                                                onChange={onChangeRangeDateRR}
                                                locale="it-IT"
                                                clearIcon={null}
                                                weekStartDayIndex={1}
                                            />
                                        </div>
                                        <a onClick={() => onClickAssociaRecuperoRiposo()} className="button m_left_5 w-button">Associa recupero riposo</a>
                                        <a onClick={() => setOpenPopUp(null)} className="button red w-button">Annulla</a>
                                    </>
                                </div>
                            </div>
                        </div>
                        :
                        ""
                    }
                    <div className="div_wrap_50">
                        <div className="div_container">
                            <div className="title_window">
                                <div className="title_window_p">Documenti {documentiInScadenzaCheckbox ? 'in scadenza' : "effettuati"} nel range delle date</div>
                            </div>
                            <div className="div_container" ref={componentRef}>
                            <div className="dati_container">
                            <div className="page">
                                <div className="fun_assegnazione_incarichi">
                                    <div className="row row_datapicker hide-element">
                                        <div className="item_1 filtro_wrap_incarichi">
                                            <div id="w-node-_284812ee-7924-9f38-86bb-8d2cc43dff3c-867f153d" className="div_item_1">
                                            <div id="w-node-_80bb2acb-3043-3649-0498-379a652f3b04-867f153d" className="label_text">Data inizio</div>
                                                <div data-hover="false" data-delay="0" className="select w-dropdown">
                                                    <DatePicker
                                                        value={dataInizioDocumentiInScadenza}
                                                        format="dd/MM/y"
                                                        weekPicker
                                                        onChange={setDataInizioDocumentiInScadenza}
                                                        locale="it-IT"
                                                        clearIcon={null}
                                                        weekStartDayIndex={1}
                                                    />
                                                </div>
                                            </div>
                                            <div id="w-node-_284812ee-7924-9f38-86bb-8d2cc43dff3c-867f153d" className="div_item_1">
                                            <div id="w-node-_80bb2acb-3043-3649-0498-379a652f3b04-867f153d" className="label_text">Data fine</div>
                                                <div data-hover="false" data-delay="0" className="select w-dropdown">
                                                    <DatePicker
                                                        value={dataFineDocumentiInScadenza}
                                                        format="dd/MM/y"
                                                        weekPicker
                                                        onChange={setDataFineDocumentiInScadenza}
                                                        locale="it-IT"
                                                        clearIcon={null}
                                                        weekStartDayIndex={1}
                                                    />
                                                </div>
                                            </div>
                                            <div id="w-node-_32403d1b-d20e-1341-c5d5-395ced6b5d87-867f153d" className="div_item_1">
                                                <div className="w-form">
                                                    <form id="email-form" name="email-form" data-name="Email Form" method="get">
                                                        <div id="w-node-_80bb2acb-3043-3649-0498-379a652f3b04-867f153d" className="label_text">Tipologia</div>
                                                        <div data-hover="false" data-delay="0" className="select w-dropdown">
                                                            <select className="form-control select" name="tipologia" onChange={e => onChangeTipologiaDocumento(e.target.value)} required>
                                                                <option value="">Tutti</option>
                                                                <option value="corso">Corsi</option>
                                                                <option value="documento">Documenti</option>
                                                                <option value="documento-dipendente">Documenti dipendente</option>
                                                                <option value="visita-medica">Visite mediche</option>
                                                                <option value="tiro-a-segno">Tiro a segno</option>
                                                            </select>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                            <div id="w-node-_32403d1b-d20e-1341-c5d5-395ced6b5d87-867f153d" className="div_item_1">
                                                <div className="w-form">
                                                    <form id="email-form" name="email-form" data-name="Email Form" method="get">
                                                        <div id="w-node-_80bb2acb-3043-3649-0498-379a652f3b04-867f153d" className="label_text">Scaduti/Effettuati</div>
                                                        <div data-hover="false" data-delay="0" className="select w-dropdown">
                                                            <select className="form-control select" name="tipologia" onChange={e => onChangeSetCheckboxDocumentiInScadenzaCheckbox(e.target.value)} required>
                                                                <option value={true}>Scaduti</option>
                                                                <option value={false}>Effettuati</option>
                                                            </select>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="div_wrap_100">
                                <div className="div_wrap_50">
                                        <h4>Doc. {documentiInScadenzaCheckbox ? 'scaduti' : "effettuati"} da {toIsoString(dataInizioDocumentiInScadenza)} a {toIsoString(dataFineDocumentiInScadenza)}</h4>
                                    </div>
                                 </div>
                                    <div className="row">
                                        <div className="item_2">
                                            <div className="intestazione_table">
                                                <div className="w-layout-grid div_column_alert_documenti_scaduti intestazione">
                                                    <div className="div_item_column">
                                                        <div className="item_table">Data</div>
                                                    </div>
                                                    <div className="div_item_column">
                                                        <div className="item_table">Dipendente</div>
                                                    </div>
                                                    <div className="div_item_column">
                                                        <div className="item_table">Documento</div>
                                                    </div>
                                                    <div className="div_item_column">
                                                        <div className="item_table">Filiale</div>
                                                    </div>
                                                </div>
                                            </div>
                                            {documentiDipendentiInScadenza.length > 0 ?
                                                <div className="body_table">
                                                    {documentiDipendentiInScadenza.map((el, i) => (
                                                        <div key={"documento" + i} className="w-layout-grid div_column_alert_documenti_scaduti cursor">
                                                            <div className="div_item_column" >
                                                                <div className="item_table"><Moment format='DD/MM/YYYY'>{el.documento.dataScadenza}</Moment></div>
                                                            </div>
                                                            <div className="div_item_column" >
                                                                <div className="item_table">{el.dipendente.cognome} {el.dipendente.nome}</div>
                                                            </div>
                                                            <div className="div_item_column" >
                                                                <div className="item_table">{el.documento.nome == 'portodarmi' ? "porto d'armi" : el.documento.nome}</div>
                                                            </div>
                                                            <div className="div_item_column" >
                                                                <div className="item_table">{el.dipendente.filiale.nome}</div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                                :
                                                ""
                                            }
                                        </div>
                                    </div>
                                    <div className="row hide-element">
                    <div className="item_3 m_top_5">
                        <div className="list_button penultimo_largo">
                            <div id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98d-867f153d" className="div_item_button">
                                <a id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98e-867f153d" className={`button w-button ${csvData.length == 0 ? "button_disabled" : ""}`} onClick={(e) => csvData.length == 0 ? null : print()}>PDF</a>
                            </div>
                            <div id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98d-867f153d" className="div_item_button">
                                <a id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98e-867f153d" className={`button w-button ${csvData.length == 0 ? "button_disabled" : ""}`} onClick={(e) => csvData.length == 0 ? null : saveAsExcel(csvData, headers)}>XLS</a>
                            </div>
                            </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
        </div>

    )
};

Dashboard.propTypes = {
    auth: PropTypes.object.isRequired,
    dati: PropTypes.object.isRequired,
    alert: PropTypes.object.isRequired,
    getRiposiNonRecuperati: PropTypes.func.isRequired,
    associaRecuperoRiposo: PropTypes.func.isRequired,
    getDocumentiInScadenza: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    dati: state.dati,
    alert: state.alert
});

export default connect(mapStateToProps, { getRiposiNonRecuperati, associaRecuperoRiposo, getDocumentiInScadenza })(Dashboard);