import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Spinner from '../../layout/Spinner';
import { creaNuovaFiliale } from '../../../actions/filiale';
import { getOrganizzazioni } from '../../../actions/organizzazione';
const NuovaFiliale = ({ openNuovaFiliale, setOpenNuovaFiliale, creaNuovaFiliale, getOrganizzazioni, organizzazione: {organizzazioni, loading}, alert }) => {

    useEffect(() => {
        getOrganizzazioni();
    }, [getOrganizzazioni]);

    useEffect(() => {
        const size = alert.length;
        if(size>0){
            if(alert[size-1].alertType === "success"){
                setOpenNuovaFiliale(false);
                setDatiFiliale(filialeInitialState);
            }
        }
    }, [alert]);

    const filialeInitialState = {
        idOrganizzazione: '',
        nome: '',
        indirizzo: ''
    }
    const [datiFiliale, setDatiFiliale] = useState(filialeInitialState);
    const { idOrganizzazione, nome, indirizzo } = datiFiliale;


    const onChangeSetDatiOrganizzazione = e => setDatiFiliale({ ...datiFiliale, [e.target.name]: e.target.value });

    const onSubmitCreaOrganizzazione = async e => {
        e.preventDefault();
        creaNuovaFiliale({ idOrganizzazione, nome, indirizzo });
    };
    return ( loading ? <Spinner/> : 
        <Fragment>
        <div className={`modal fade ${openNuovaFiliale ? 'show' : null}`} style={{display: `${openNuovaFiliale ? 'block' : 'none'}`}}>
            <form className="kt-form"  onSubmit={e => onSubmitCreaOrganizzazione(e)}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="myModalLabel">Crea nuova filiale</h4>
                        </div>
                        <div className="modal-body">
                            <div className="form-group row">
                                <div className="col-lg-12 form-group">
                                    <label>Organizzazioni:</label>
                                    <select className="form-control" name="idOrganizzazione"  onChange={e => onChangeSetDatiOrganizzazione(e)} required>
                                        <option selected={true} value={null} disabled={true}>Seleziona organizzazione</option>
                                        {organizzazioni.map((organizzazione, i) => (
                                            <option selected={organizzazione._id === idOrganizzazione} value={organizzazione._id}>{organizzazione.nome}</option>
                                        ))}
						            </select>
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-lg-6 form-group">
                                    <label>Nome filiale:</label>
                                    <input type="text" name="nome" value={nome} className="form-control" placeholder="Inserisci nome" onChange={e => onChangeSetDatiOrganizzazione(e)} required />
                                </div>
                                <div className="col-lg-6 form-group">
                                    <label className="">Indirizzo filiale:</label>
                                    <input type="text" name="indirizzo" value={indirizzo} className="form-control" placeholder="Inserisci indirizzo" onChange={e => onChangeSetDatiOrganizzazione(e)} required />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" onClick={() => setOpenNuovaFiliale(false)} className="btn btn-default">Chiudi</button>
                            <button type="submit" className="btn btn-primary">Aggiungi organizzazione</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        </Fragment>
    )
}


NuovaFiliale.protoTypes = {
    creaNuovaFiliale: PropTypes.func.isRequired,
    getOrganizzazioni: PropTypes.func.isRequired,
    organizzazione: PropTypes.object.isRequired,
    alert: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    organizzazione: state.organizzazione,
    alert: state.alert
});
export default connect(mapStateToProps, { creaNuovaFiliale, getOrganizzazioni })(NuovaFiliale);



