import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import Spinner from '../../layout/Spinner';
import IncarichiFiliale from './IncarichiFiliale';
import { getIncarichiFiliale, getFilialiOrganizzazione } from '../../../actions/filiale';

const Filiale = ({ filiale: { filiali, incarichiFiliale, loading }, getIncarichiFiliale, getFilialiOrganizzazione }) => {

    const [dataInizioInput, setDataInizioInput] = useState((setHours(setMinutes(startOfMonth(new Date()), 0), 0)));
    const [dataFineInput, setDataFineInput] = useState((setHours(setMinutes(endOfMonth(new Date()), 59), 23)));
    const [incarichiLoading, setIncarichiLoading] = useState(true);
    const [totaleOre, setTotaleOre] = useState(0);
    const [filialiList, setFilialiList] = useState([]);
    const [filialeSelected, setFilialeSelected] = useState(null);

    useEffect(() => {
        getFilialiOrganizzazione();
    }, []);

    useEffect(() => {
        if (filiali.length > 0) {
            console.log(filiali)
            setFilialiList(filiali)
        }
    }, [filiali]);

    useEffect(() => {
        setIncarichiLoading(true)
        getIncarichiFiliale({ dataInizio: dataInizioInput, dataFine: dataFineInput });
        setTotaleOre(0)
    }, [getIncarichiFiliale, dataInizioInput, dataFineInput]);


    const onChangeRangeDateInizio = (data) => {
        setDataFineInput(setHours(setMinutes(endOfMonth(data), 59), 23))
        setDataInizioInput(data)
    }


    const onChangeRangeDateFine = (data) => {
        setDataFineInput(data)
    }

    return (
        <div className="body wf-section">
        <div className="div_wrap">
            <div className="div_wrap_100">
            <div className="div_container">
                        <div className="title_window">
                            <div className="title_window_p">Incarichi filiale</div>
                        </div>
                        <IncarichiFiliale 
                            incarichiFiliale = {incarichiFiliale}
                            onChangeRangeDateInizio={onChangeRangeDateInizio}
                            onChangeRangeDateFine={onChangeRangeDateFine}
                            incarichiLoading={incarichiLoading}
                            dataInizioInput={dataInizioInput} 
                            dataFineInput={dataFineInput} 
                            setIncarichiLoading={setIncarichiLoading}
                            setTotaleOre={setTotaleOre}
                            totaleOre={totaleOre}
                            filialeSelected={filialeSelected}
                            setFilialeSelected={setFilialeSelected}
                            filialiList={filialiList}
                        />
                    </div>
            </div>
            </div>
            </div>
    )
}

Filiale.protoTypes = {
    filiale: PropTypes.object.isRequired,
    getFilialiOrganizzazione: PropTypes.func.isRequired,
    getIncarichiFiliale: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    filiale: state.filiale
});

export default connect(mapStateToProps, { getIncarichiFiliale, getFilialiOrganizzazione })(Filiale);