import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { logout } from '../../actions/auth';
import { Link } from "react-router-dom";

const TopbarUtenteEsterno = ({ auth: { user, loading }, logout }) => {

	const [utente, setUtente] = useState({
		username: '',
		nome: '',
		cognome: '',
		ruolo: '',
		nomeFiliale: '',
		nomeOrganizzazione: ''

	});

	useEffect(() => {
		setUtente({
			username: loading || !user ? '' : user.username,
			nome: loading || !user ? '' : user.nome,
			cognome: loading || !user ? '' : user.cognome,
			ruolo: loading || !user ? '' : user.role,
			nomeFiliale: loading || !user || !user.filiale ? '' : user.filiale.nome,
			nomeOrganizzazione: loading || !user || !user.organizzazione ? '' : user.organizzazione.nome,
			idOrganizzazione: loading || !user || !user.organizzazione ? '' : user.organizzazione._id
		})
	}, [user, loading]);

	const { username, nome, cognome, ruolo, nomeFiliale, nomeOrganizzazione, idOrganizzazione } = utente;

	const getLogo = (idOrganizzazione) => {
		if(idOrganizzazione == '63e618049a5309e9f6f3ff9a'){
			return 'logo_emilpol.jpg'
		}else if(idOrganizzazione == '63e618279a5309e9f6f3ff9b'){
			return 'new_koop.png'
		}else{
			return 'logo_vigili_dell_ordine.png'
		}
	}

	return (
		<div className="header wf-section">
			<div data-animation="default" data-collapse="medium" data-duration="400" data-easing="ease" data-easing2="ease" role="banner" className="navbar w-nav">
				<div className="menu_header w-container">
					<a href="/" className="brand w-nav-brand">
					<img src={`assets/media/logos/${getLogo(idOrganizzazione)}`} loading="lazy" alt="" className="logo_header" /></a>
					<nav role="navigation" className="nav-menu w-nav-menu">
						<Link to="/" className="menu_item w-nav-link">Home</Link>
						<Link to="" onClick={() => logout()} className="menu_item w-nav-link">Logout</Link>
					</nav>
					<div className="menu-button w-nav-button">
						<div className="w-icon-nav-menu"></div>
					</div>
				</div>
			</div>
		</div>
	)
}

TopbarUtenteEsterno.protoTypes = {
	logout: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
	auth: state.auth
});

export default connect(mapStateToProps, { logout })(TopbarUtenteEsterno);

