export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

export const SET_SIDEBAR_POSITION = 'SET_SIDEBAR_POSITION';
export const SIDEBAR_ERROR = 'SIDEBAR_ERROR';


export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';


export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const CLEAN_DATI_DASHBOARD = 'CLEAN_DATI_DASHBOARD';



export const GET_CLIENTI = 'GET_CLIENTI';
export const GET_CLIENTE = 'GET_CLIENTE';
export const MODIFICA_CLIENTE = 'MODIFICA_CLIENTE';
export const CREA_CLIENTE = 'CREA_CLIENTE';
export const CLIENTE_ERROR = 'CLIENTE_ERROR';
export const CLEAR_CLIENTE = 'CLEAR_CLIENTE';

export const GET_ORGANIZZAZIONI = 'GET_ORGANIZZAZIONI';
export const GET_ORGANIZZAZIONE = 'GET_ORGANIZZAZIONE';
export const CREA_ORGANIZZAZIONE = 'CREA_ORGANIZZAZIONE';
export const MODIFICA_ORGANIZZAZIONE = 'MODIFICA_ORGANIZZAZIONE';
export const ORGANIZZAZIONE_ERROR = 'ORGANIZZAZIONE_ERROR';

export const GET_FILIALE = 'GET_FILIALE';
export const GET_FILIALI = 'GET_FILIALI';
export const CREA_FILIALE = 'CREA_FILIALE';
export const MODIFICA_FILIALE = 'MODIFICA_FILIALE';
export const GET_INCARICHI_FILIALE = 'GET_INCARICHI_FILIALE';
export const GET_FILIALE_ORGANIZZAZIONE = 'GET_FILIALE_ORGANIZZAZIONE';
export const FILIALE_ERROR = 'FILIALE_ERROR';

export const GET_PUNTO_VENDITA = 'GET_PUNTO_VENDITA';
export const GET_PUNTI_VENDITA = 'GET_PUNTI_VENDITA';
export const CREA_PUNTO_VENDITA = 'CREA_PUNTO_VENDITA';
export const MODIFICA_PUNTO_VENDITA = 'MODIFICA_PUNTO_VENDITA';
export const GET_CONTRATTI_PUNTI_VENDITA = 'GET_CONTRATTI_PUNTI_VENDITA';
export const GET_CONTRATTI_PUNTO_VENDITA = 'GET_CONTRATTI_PUNTO_VENDITA';
export const GET_CONTRATTI_PUNTI_VENDITA_CALENDARIO = 'GET_CONTRATTI_PUNTI_VENDITA_CALENDARIO';
export const GET_CALENDARIO_JOB = 'GET_CALENDARIO_JOB';
export const ELIMINA_PUNTO_VENDITA = 'ELIMINA_PUNTO_VENDITA';
export const CLEAR_PUNTO_VENDITA = 'CLEAR_PUNTO_VENDITA';
export const AGGIUNGI_SERVIZIO_SPORADICO = 'AGGIUNGI_SERVIZIO_SPORADICO';
export const PUNTO_VENDITA_ERROR = 'PUNTO_VENDITA_ERROR';
export const CLEAN_CALENDARIO_RICHIESTE = 'CLEAN_CALENDARIO_RICHIESTE';



export const GET_DIPENDENTE = 'GET_DIPENDENTE';
export const GET_DIPENDENTI = 'GET_DIPENDENTI';
export const GET_DIPENDENTI_CONTRATTO_JOB = 'GET_DIPENDENTI_CONTRATTO_JOB';
export const GET_DIPENDENTI_CONTRATTO = 'GET_DIPENDENTI_CONTRATTO';
export const CREA_DIPENDENTE = 'CREA_DIPENDENTE';
export const MODIFICA_DIPENDENTE = 'MODIFICA_DIPENDENTE';
export const FILTRA_DIPENDENTI = 'FILTRA_DIPENDENTI';
export const GET_INCARICHI_DIPENDENTE = 'GET_INCARICHI_DIPENDENTE';
export const GET_GIORNI_ECCEZIONE = 'GET_GIORNI_ECCEZIONE';
export const GET_GIORNI_ECCEZIONE_ASSENTEISMO = 'GET_GIORNI_ECCEZIONE_ASSENTEISMO';
export const GET_GIORNI_ECCEZIONE_DIPENDENTE = 'GET_GIORNI_ECCEZIONE_DIPENDENTE';
export const AGGIUNGI_GIORNO_ECCEZIONE = 'AGGIUNGI_GIORNO_ECCEZIONE';
export const MODIFICA_GIORNO_ECCEZIONE = 'MODIFICA_GIORNO_ECCEZIONE';
export const ELIMINA_GIORNO_ECCEZIONE = 'ELIMINA_GIORNO_ECCEZIONE';
export const AGGIUNGI_GIORNO_ECCEZIONE_LUNGO = 'AGGIUNGI_GIORNO_ECCEZIONE_LUNGO';
export const ELIMINA_GIORNO_ECCEZIONE_LUNGO = 'ELIMINA_GIORNO_ECCEZIONE_LUNGO';
export const ELIMINA_GIORNO_ECCEZIONE_LUNGO_RICHIESTA = 'ELIMINA_GIORNO_ECCEZIONE_LUNGO_RICHIESTA';
export const CONFERMA_GIORNO_ECCEZIONE = 'CONFERMA_GIORNO_ECCEZIONE';
export const CONFERMA_GIORNI_ECCEZIONE = 'CONFERMA_GIORNI_ECCEZIONE';
export const UPLOAD_DOCUMENTO = 'UPLOAD_DOCUMENTO';
export const ELIMINA_DOCUMENTO = 'ELIMINA_DOCUMENTO';
export const UPLOAD_ERROR = 'UPLOAD_ERROR';
export const SALVA_INCARICHI_DIPENDENTE = 'SALVA_INCARICHI_DIPENDENTE';
export const GET_INCARICHI_TUTTI_DIPENDENTI_SALVATI = 'GET_INCARICHI_TUTTI_DIPENDENTI_SALVATI';
export const GET_INCARICHI_DIPENDENTE_SALVATI = 'GET_INCARICHI_DIPENDENTE_SALVATI';
export const LOADING_DIPENDENTI = 'LOADING_DIPENDENTI';
export const CLEAN_DIPENDENTI = 'CLEAN_DIPENDENTI';
export const CLEAN_GIORNI_ECCEZIONE = 'CLEAN_GIORNI_ECCEZIONE';
export const CLEAN_INCARICHI_PDF_DIPENDENTI = 'CLEAN_INCARICHI_PDF_DIPENDENTI';
export const CLEAN_INCARICHI_DIPENDENTE = 'CLEAN_INCARICHI_DIPENDENTE';
export const DIPENDENTE_ERROR = 'DIPENDENTE_ERROR';
export const GET_DIPENDENTI_JOB = 'GET_DIPENDENTI_JOB';
export const ELIMINA_PDF = 'ELIMINA_PDF';
export const DUPLICA_SERVIZIO = 'DUPLICA_SERVIZIO';




export const GET_RICHIESTA = 'GET_RICHIESTA';
export const GET_RICHIESTE = 'GET_RICHIESTE';
export const GET_RICHIESTE_SENZA_CONTRATTO = 'GET_RICHIESTE_SENZA_CONTRATTO';
export const GET_RICHIESTE_CLIENTE = 'GET_RICHIESTE_CLIENTE';
export const GET_RICHIESTE_FILIALE = 'GET_RICHIESTE_FILIALE';
export const GET_TUTTE_RICHIESTE = 'GET_TUTTE_RICHIESTE';
export const GET_RICHIESTE_PUNTO_VENDITA = 'GET_RICHIESTE_PUNTO_VENDITA'; 
export const GET_RICHIESTE_ARCHIVIATE = 'GET_RICHIESTE_ARCHIVIATE';
export const GET_RICHIESTE_CALENDARIO = 'GET_RICHIESTE_CALENDARIO';
export const GET_RICHIESTE_STAMPA_GIORNATE = 'GET_RICHIESTE_STAMPA_GIORNATE';
export const FILTRA_RICHIESTE = 'FILTRA_RICHIESTE';
export const FILTRA_RICHIESTE_ARCHIVIATE = 'FILTRA_RICHIESTE_ARCHIVIATE';
export const FILTRA_RICHIESTE_DIPENDENTE = 'FILTRA_RICHIESTE_DIPENDENTE';
export const FILTRA_RICHIESTE_PUNTOVENDITA = 'FILTRA_RICHIESTE_PUNTOVENDITA';
export const FILTRA_RICHIESTE_CLIENTE = 'FILTRA_RICHIESTE_CLIENTE';
export const ARCHIVIA_RICHIESTE = 'ARCHIVIA_RICHIESTE';
export const ANNULLA_ARCHIVIA_RICHIESTE = 'ANNULLA_ARCHIVIA_RICHIESTE';
export const CREA_RICHIESTA_SUCCESS = 'CREA_RICHIESTA_SUCCESS';
export const MODIFICA_RICHIESTA = 'MODIFICA_RICHIESTA';
export const MODIFICA_ORARIO_RICHIESTA = 'MODIFICA_ORARIO_RICHIESTA';
export const ARCHIVIA_RICHIESTA = 'ARCHIVIA_RICHIESTA';
export const PROGRAMMA_TUTTI_TURNI = 'PROGRAMMA_TUTTI_TURNI';
export const PROGRAMMA_TUTTI_TURNI_JOB = 'PROGRAMMA_TUTTI_TURNI_JOB';
export const PROGRAMMA_PROSSIMO_TURNO = 'PROGRAMMA_PROSSIMO_TURNO';
export const RIPRISTINA_TUTTI_TURNI = 'RIPRISTINA_TUTTI_TURNI';
export const ASSOCIA_DIPENDENTE_RICHIESTA = 'ASSOCIA_DIPENDENTE_RICHIESTA';
export const DISSOCIA_DIPENDENTE_RICHIESTA = 'DISSOCIA_DIPENDENTE_RICHIESTA';
export const NASCONDI_CONTRATTO = 'NASCONDI_CONTRATTO';
export const RIPRISTINA_CONTRATTO_NASCOSTO = 'RIPRISTINA_CONTRATTO_NASCOSTO';
export const ANNULLA_RICHIESTA_ARCHIVIATA = 'ANNULLA_RICHIESTA_ARCHIVIATA';
export const LOADING_RICHIESTE = 'LOADING_RICHIESTE';
export const GET_CAMPI_RICHIESTA_MASSIVA = 'GET_CAMPI_RICHIESTA_MASSIVA';
export const ADD_DIPENDENTE_RICHIESTE = 'ADD_DIPENDENTE_RICHIESTE';
export const DELETE_DIPENDENTE_RICHIESTE = 'DELETE_DIPENDENTE_RICHIESTE';
export const CLEAN_RICHIESTA = 'CLEAN_RICHIESTA';
export const CLEAN_CALENDARIO = 'CLEAN_CALENDARIO';
export const CLEAN_RICHIESTE = 'CLEAN_RICHIESTE';
export const CLEAN_RICHIESTE_ARCHIVIATE = 'CLEAN_RICHIESTE_ARCHIVIATE';
export const RICHIESTA_ERROR = 'RICHIESTA_ERROR';
export const GET_CALENDARIO_CONTRATTI = 'GET_CALENDARIO_CONTRATTI';
export const ELIMINA_RICHIESTA_ANOMALA = 'ELIMINA_RICHIESTA_ANOMALA';
export const GET_DIPENDENTI_PRESTABILI_OCCUPATI = 'GET_DIPENDENTI_PRESTABILI_OCCUPATI';
export const GET_QUADRATURA_ORE = 'GET_QUADRATURA_ORE';




export const GET_RIPOSI_NON_RECUPERATI = 'GET_RIPOSI_NON_RECUPERATI';
export const ASSOCIA_RECUPERO_RIPOSO = 'ASSOCIA_RECUPERO_RIPOSO';
export const GET_DOCUMENTI_IN_SCADENZA = 'GET_DOCUMENTI_IN_SCADENZA';
export const DATI_ERROR = 'DATI_ERROR';


export const GET_ESITI_INVIO_TURNI = 'GET_ESITI_INVIO_TURNI';
export const INVIA_TURNI = 'INVIA_TURNI';
export const INVIA_TURNI_DIPENDENTE_VIA_EMAIL = 'INVIA_TURNI_DIPENDENTE_VIA_EMAIL';
export const INVIO_TURNI_ERROR = 'INVIO_TURNI_ERROR';


export const GET_TIPOLOGIE_CORSI = 'GET_TIPOLOGIE_CORSI';
export const CREA_TIPOLOGIA_CORSO = 'CREA_TIPOLOGIA_CORSO';
export const DOCUMENTO_ERROR = 'DOCUMENTO_ERROR';

export const GET_FESTIVITA = 'GET_FESTIVITA';
export const CREA_FESTIVITA = 'CREA_FESTIVITA';
export const GET_CATEGORIE_DOCUMENTI_UTENTE_ESTERNO = 'GET_CATEGORIE_DOCUMENTI_UTENTE_ESTERNO';
export const CREA_CATEGORIA_DOCUMENTO_UTENTE_ESTERNO = 'CREA_CATEGORIA_DOCUMENTO_UTENTE_ESTERNO';
export const ELIMINA_FESTIVITA = 'ELIMINA_FESTIVITA';
export const IMPOSTAZIONE_ERROR = 'IMPOSTAZIONE_ERROR';


export const GET_UTENTI_ESTERNI = 'GET_UTENTI_ESTERNI';
export const GET_UTENTE_ESTERNO = 'GET_UTENTE_ESTERNO';
export const MODIFICA_UTENTE_ESTERNO = 'MODIFICA_UTENTE_ESTERNO';
export const CREA_UTENTE_ESTERNO = 'CREA_UTENTE_ESTERNO';
export const UTENTE_ESTERNO_ERROR = 'UTENTE_ESTERNO_ERROR';

