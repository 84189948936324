import React, { Fragment, useEffect, useState, useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Helmet } from "react-helmet";
import DatePicker from 'react-date-picker';
import { useReactToPrint } from 'react-to-print'; 
import Moment from 'react-moment'
import { v4 as uuid } from 'uuid';
import Spinner from '../../layout/Spinner';
import Calendario from './Calendario';
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import startOfWeek from "date-fns/startOfWeek";
import subDays from "date-fns/subDays";
import endOfWeek from "date-fns/endOfWeek";
import disponibilitaJSON from "../../../utils/disponibilita.json"
import { getFiliali } from '../../../actions/filiale';
import { ripristinaTuttiTurni, associaDipendenteRichiesta, dissociaDipendenteRichiesta, archiviaRichieste, cleanCalendario, getCalendarioContratti, modificaOrarioRichiesta, cleanRichieste, cleanRichiesteArchiviate, nascondiContratto, ripristinaContrattoNascosto, annullaArchiviaRichieste, eliminaRichiestaAnomala, getDipendentiPrestabiliOccupati } from '../../../actions/richiesta';
import { getDipendentiContrattoJob, cleanDipendenti, getStateDipendentiContrattoJob, aggiungiGiornoEccezione, eliminaGiornoEccezioneLungo, aggiungiGiornoEccezioneLungo, getGiorniEccezione, cleanGiorniEccezione, getIncarichiDipendente, cleanIncarichiDipendente, confermaGiorniEccezione, eliminaGiornoEccezioneLungoRichieste, togliFerieAggiungiGiornoEccezione } from '../../../actions/dipendente';
import { getContrattiRichiestePuntiVendita, aggiungiServizioSporadico, getPuntiVendita, getContrattiPuntoVendita } from '../../../actions/puntoVendita';
const Richieste = ({ richiesta: { richieste, richiesteArchiviate, richiestaUpdate, calendarioRichieste, calendarioLoading, richiesteSenzaContratto, dipendentiPrestabiliOccupati }, puntoVendita: { contratti, puntiVendita, contrattiPuntoVendita }, dipendente: { dipendenti, jobId, giorniEccezione, incarichiDipendente }, getContrattiRichiestePuntiVendita, getDipendentiContrattoJob, cleanDipendenti, associaDipendenteRichiesta, dissociaDipendenteRichiesta, cliente: { clienti }, getStateDipendentiContrattoJob, archiviaRichieste, aggiungiGiornoEccezione, alert, cleanCalendario, getCalendarioContratti, modificaOrarioRichiesta, cleanRichieste, cleanRichiesteArchiviate, cleanGiorniEccezione, nascondiContratto, ripristinaContrattoNascosto, annullaArchiviaRichieste, eliminaGiornoEccezioneLungo, aggiungiGiornoEccezioneLungo, getGiorniEccezione, aggiungiServizioSporadico, getIncarichiDipendente, cleanIncarichiDipendente, getPuntiVendita, getContrattiPuntoVendita, eliminaRichiestaAnomala, confermaGiorniEccezione, getDipendentiPrestabiliOccupati, eliminaGiornoEccezioneLungoRichieste, togliFerieAggiungiGiornoEccezione, auth: {user}, getFiliali, filiale: {filiali} }) => {

    const [idCliente, setIdCliente] = useState('');
    const [dataInput, setDataInput] = useState((setHours(setMinutes(new Date(), 0), 12)));
    const [richiesteContratto, setRichiesteContratto] = useState([]);
    const [rowSelected, setRowSelected] = useState({ id: '', idRichiesta: '', stato: '', contrattoMancante: false });
    const [rowSelectedGiornoEccezione, setRowSelectedGiornoEccezione] = useState({ id: "", dipendente: "", dataInzio: new Date(), dataFine: new Date(), disponibilita: "", nProtocollo: "" });

    const [loadingContrattiRichieste, setLoadingContrattiRichieste] = useState(false);
    const [checkBoxFiltraDisponibili, setCheckBoxFiltraDisponibili] = useState('tutti');
    const [openCalendario, setOpenCalendario] = useState(false);
    const [waitingJob, setWaitingJob] = useState(false);
    const [testoFiltraDipendente, setTestoFiltraDipendente] = useState("");
    const [openPopUpAssociaDipendente, setOpenPopUpAssociaDipendente] = useState({ open: false, messaggi: [], tipologia: "", id: null, recuperoRiposo: false, dataRecuperoRiposo: new Date() });
    const [dipendenteSelected, setDipendenteSelected] = useState(null);
    const [dipendenteNonAssegnatoSelected, setDipendenteNonAssegnatoSelected] = useState(null);
    const [incarichiAlert, setIncarichiAlert] = useState({ incarichiTotali: 0, incarichiAssegnati: 0 });
    const [checkBoxServizi, setCheckBoxServizi] = useState([]);
    const [checkOpenNascosti, setCheckOpenNascosti] = useState(false);
    const [openListaDipendenti, setOpenListaDipendenti] = useState(false);
    const [openModificaServizio, setOpenModificaServizio] = useState(false);
    const [restiChecked, setRestiChecked] = useState(false);
    const [cambiaDisponibilitaOpen, setCambiaDisponibilitaOpen] = useState(false);
    const [cambiaDisponibilitaNonAssegnatoSelectedOpen, setCambiaDisponibilitaNonAssegnatoSelectedOpen] = useState(false);
    const [disponibilitaSelected, setDisponibilitaSelected] = useState(null);
    const [disponibilitaNonAssegnatoSelected, setDisponibilitaNonAssegnatoSelected] = useState(null);
    const [nProtocollo, setNProtocollo] = useState("");
    const [elementoDuplicato, setElementoDuplicato] = useState(null);
    const [elementoEliminato, setElementoEliminato] = useState(null);
    const [elementoModificato, setElementoModificato] = useState(null);
    const [modificaServizio, setModificaServizio] = useState({ oraInizio: "", oraFine: "" });
    const [disableButton, setDisableButton] = useState(false);
    const [giornoSettimanaSelected, setGiornoSettimanaSelected] = useState("0");
    const [dipendentiList, setDipendentiList] = useState([]);
    const [giorniEccezioneData, setGiorniEccezioneData] = useState([]);
    const [nuovaDataInizioInput, setNuovaDataInizioInput] = useState((setHours(setMinutes(new Date(), 0), 0)));
    const [nuovaDataFineInput, setNuovaDataFineInput] = useState((setHours(setMinutes(new Date(), 59), 23)));
    const [tipologiaSelected, setTipologiaSelected] = useState('');
    const [giornoEccezioneRowSelected, setGiornoEccezioneRowSelected] = useState({ id: "" });
    const [dipendenteGiornoEccezioneSelected, setDipendenteGiornoEccezioneSelected] = useState('');
    const [openPopUpAssociaDipendenteConRR, setOpenPopUpAssociaDipendenteConRR] = useState(false);
    const [dataInputRR, setDataInputRR] = useState((setHours(setMinutes(new Date(), 0), 12)));
    const [servizioSporadicoOpen, setServizioSporadicoOpen] = useState(false);
    const [checkboxSelezionaTurni, setCheckboxSelezionaTurni] = useState(false);
    const [dipendentiLiberi, setDipendentiLiberi] = useState([]);
    const [dipendentiPrestati, setDipendentiPrestati] = useState([]);
    const [recuperiRiposiDipednenti, setRecuperiRiposiDipednenti] = useState([]);
    const [datiServizioSporadico, setDatiServizioSporadico] = useState({
        idPuntoVenditaSporadico: '',
        multiplo: 1,
        dataInizioServizioSporadico: new Date(),
        dataFineServizioSporadico: new Date(),
        lunedi: false,
        martedi: false,
        mercoledi: false,
        giovedi: false,
        venerdi: false,
        sabato: false,
        domenica: false,
        festivi: false,
        semifestivi: false,
        oraInizioServizioSporadico: "",
        oraFineServizioSporadico: ""
    });
    const [checkboxSelezionaAssenze, setCheckboxSelezionaAssenze] = useState(false);
    const [checkBoxAssenze, setCheckBoxAssenze] = useState([]);
    const [waitingTurniDipendente, setWaitingTurniDipendente] = useState(false);
    const [idOrganizzazione, setIdOrganizzazione] = useState("");
    const [nomeFiliale, setNomeFiliale] = useState("");
    const [filialiList, setFilialiList] = useState([]);
    const [filtraFiliale, setFiltraFiliale] = useState(null);


    const { idPuntoVenditaSporadico, dataInizioServizioSporadico, dataFineServizioSporadico, oraInizioServizioSporadico, oraFineServizioSporadico, lunedi, martedi, mercoledi, giovedi, venerdi, sabato, domenica, festivi, semifestivi, multiplo } = datiServizioSporadico;


    useEffect(() => {
        if (user && user.filiale && user.filiale.nome) {
            setNomeFiliale(user.filiale.nome)
        }
        if (user && user.organizzazione) {
            setIdOrganizzazione(user.organizzazione._id)
        }
        if (user && user.role == "organizzazione") {
            getFiliali();
        }
    }, [user]);

    useEffect(() => {
        if (filiali.length > 0) {
            let array = [];
            filiali.map(filiale => {
                const index = user.vistaFiliali.findIndex(el => el._id == filiale._id);
                if(index >= 0){
                    array.push(filiale)
                    setFiltraFiliale(filiale._id)
                }
            })
            setFilialiList(array)
        }
    }, [filiali]);

    //*
    useEffect(() => {
        const size = alert.length;
        if (size > 0) {
            if (alert[size - 1].alertType === "success" && alert[size - 1].field == 'add_giorno_eccezione') {
                setCambiaDisponibilitaOpen(false);
                setCambiaDisponibilitaNonAssegnatoSelectedOpen(false)
                setCambiaDisponibilitaNonAssegnatoSelectedOpen(false)
                setDipendenteNonAssegnatoSelected(null)
            }
            if (alert[size - 1].alertType === "success" && alert[size - 1].field == 'richieste_archiviate') {
                cleanDipendenti()
            }
            if (alert[size - 1].alertType === "success" && alert[size - 1].field == 'ripristina_richieste_archiviate') {
                cleanDipendenti()
            }
            if (alert[size - 1].alertType === "success" && alert[size - 1].field == 'nuovo_servizio_sporadico') {
                getContrattiRichiestePuntiVendita({ idCliente: idCliente, dataInizio: dataInput, idFiliale: filtraFiliale })
                setServizioSporadicoOpen(false)
            }
            if (alert[size - 1].alertType === "success" && alert[size - 1].field == 'delete_giorno_eccezione') {

            }
            

        }
    }, [alert]);

    //*
    useEffect(() => {
        if(incarichiDipendente && incarichiDipendente.incarichi && incarichiDipendente.incarichi.length > 0){
            setWaitingTurniDipendente(false)
        }
    }, [incarichiDipendente]);



    //Carica giorni eccezione quando cambia la data
    useEffect(() => {
        let arrayGiorniEccezione = [];
        let arrayGiorniEccezioneDipendenti = []
        giorniEccezione.map(el => {
            arrayGiorniEccezione.push({ _id: el._id, dataInizio: el.dataInizio, dataFine: el.dataFine, dipendente: el.dipendente, confermata: el.confermata, nProtocollo: el.nProtocollo, disponibilita: el.disponibilita, autocreate: false })
        })
        if (dipendenti && dipendenti.length > 0) {
            let arrayDipendenti = dipendenti.filter(el => (el.calcoli && el.calcoli.disponibilita == 'RR') || (el.calcoli && el.calcoli.disponibilita == 'RP'))
            arrayDipendenti.map(el => {
                arrayGiorniEccezioneDipendenti.push({ _id: el._id, dataInizio: new Date(dataInput), dataFine: new Date(dataInput), dipendente: el, confermata: true, nProtocollo: "", disponibilita: el.calcoli && el.calcoli.disponibilita, autocreate: true })
            })
        }
        let arrayFinale = arrayGiorniEccezione.concat(arrayGiorniEccezioneDipendenti)
        arrayFinale = cleanDipendentiGiorniEccezione(arrayFinale)
        arrayFinale = cleanDipendentiGiorniEccezioneRR(arrayFinale)
        setGiorniEccezioneData(arrayFinale)
    }, [giorniEccezione]);

    const cleanDipendentiGiorniEccezione = (dipendenti) => {
        let array = []
        dipendenti.map(el => {
            let index = array.findIndex(a => { return (a.disponibilita === el.disponibilita) && (a.dipendente._id == el.dipendente._id) })
            if (index === -1) {
                array.push(el)
            }
        })
        return array
    }

    const cleanDipendentiGiorniEccezioneRR = (dipendenti) => {
        let array = []
        dipendenti.map(el => {
            if (el.disponibilita == "FE") {
                let index = array.findIndex(a => { return (a.dipendente._id == el.dipendente._id) })
                if (index === -1) {
                    array.push(el)
                }
            } else {
                let index = array.findIndex(a => { return (a.dipendente._id == el.dipendente._id) && (a.disponibilita == 'FE') })
                if (index === -1) {
                    array.push(el)
                } else {
                    array[index] = el
                }
            }
        })
        return array
    }



    //Carica giorni eccezione delle disponibilita dipendenti
    useEffect(() => {
        getGiorniEccezione({ dataInizio: dataInput, dataFine: dataInput, idFiliale: filtraFiliale })

    }, [dipendenti]);

    //Carica clienti nella select *
    useEffect(() => {
        getPuntiVendita({ idPuntoServizio: null });
        cleanIncarichiDipendente();
        return () => {
            cleanCalendario()
            setRowSelected({ id: '', idRichiesta: '', stato: '', contrattoMancante: false })
            setRichiesteContratto([])
            cleanDipendenti()
            cleanRichieste()
            cleanRichiesteArchiviate()
            cleanGiorniEccezione()
        };
    }, []);


    //carica i recuperi riposi
    useEffect(() => {
        let arrayRR = []
        if (richieste.length > 0) {
            richieste.map(el => {
                if (el.recuperoRiposo) {
                    arrayRR.push(el.recuperoRiposo)
                }
            })
        }
        setRecuperiRiposiDipednenti(arrayRR)
    }, [richieste]);

    const getOreBetweenDates = (start, end) => {
        start = start.split(":");
        end = end.split(":");
        var startDate = new Date(0, 0, 0, start[0], start[1], 0);
        var endDate = new Date(0, 0, 0, end[0], end[1], 0);
        var diff = endDate.getTime() - startDate.getTime();
        var hours = Math.floor(diff / 1000 / 60 / 60);
        diff -= hours * 1000 * 60 * 60;
        var minutes = Math.floor(diff / 1000 / 60);
        if (hours < 0)
            hours = hours + 24;
        if (isNaN(hours) || isNaN(minutes)) {
            return ""
        }
        return (hours <= 9 ? "0" : "") + hours + ":" + (minutes <= 9 ? "0" : "") + minutes;
    }

    const fromStringToMinuti = (oreString) => {
        var minuti = getMinutiInt(oreString);
        var ore = getOreInt(oreString) * 60
        return ore + minuti
    }

    const getOreInt = (ore) => {
        if (ore && ore.length > 0) {
            const split = ore.split(':');
            return parseInt(split[0])
        } else {
            return 0
        }
    }

    const getMinutiInt = (ore) => {
        if (ore && ore.length > 0) {
            const split = ore.split(':');
            return parseFloat(split[1])
        } else {
            return 0
        }
    }

    const toIsoString = (date) => {
        const pad = function (num) {
            return (num < 10 ? '0' : '') + num;
        };

        return pad(date.getDate()) +
            '-' + pad(date.getMonth() + 1) +
            '-' + date.getFullYear()
    }

    //Carica contratti e richieste e fa unione *
    useEffect(() => {
        let cont= 0
        richieste.map(el => {
            if(el && el.dipendente && el.dipendente.filiale == "634d6703aad1111a4c048797"){
                console.log(el)
            }
            if(el.prestato){
                if(el.dipendente.filiale == "634d6703aad1111a4c048797"){
                    console.log(el.nome)
                    console.log(el.dipendente.cognome)
                    console.log(el.oraInizio)
                    console.log(el.oraFine)
                    let durataString = getOreBetweenDates(el.oraInizio, el.oraFine)
                        let durataMinutiInt = fromStringToMinuti(durataString)
                        cont = cont + durataMinutiInt
                }
            }
        })
        console.log(cont)
        console.log("____________________________________")
        let arrayContrattiRichieste = [];
        let array = [];
        let arrayContratti = contratti;
        let arrayContrattiEliminati = [];
        let arrayContrattiContinuativiElaborati = elaboraContrattiContinuativi(arrayContratti.filter(contratto => contratto.continuativo == true))
        arrayContratti = arrayContratti.filter(contratto => contratto.continuativo != true);
        //tolgo dai contratti vuoti quelli che hanno una richiesta
        if (richieste.length > 0) {
            arrayContratti = arrayContratti.filter(contratto => !richieste.some(richiesta => contratto._id.toString() == richiesta.contratto._id.toString()));
            arrayContrattiContinuativiElaborati = arrayContrattiContinuativiElaborati.filter(contratto => !richieste.some(richiesta =>
                contratto.contrattoId.toString() == richiesta.contratto._id.toString() && contratto.numeroContinuativo == richiesta.numeroContinuativo)
            );
        }
        if (richieste.length > 0) {
            array = arrayContratti.concat(richieste)
        } else {
            array = arrayContratti
        }


        //PER CONTROLLO
        if (richieste.length > 0) {
            let richA = richieste.filter(el => el.stato == "archiviata")
            richA = richA.filter(el => el.puntoVendita.idRaggruppa == "32")
            let a = []
            let minutii = 0
            richA.map(el => {
                let to = getOreBetweenDates(el.oraInizio, el.oraFine)
                minutii = minutii + fromStringToMinuti(to)
                a.push({ inizio: el.oraInizio, fine: el.oraFine, totale: to })
            })
        }
        //PER CONTROLLO


        //creo array per layout
        array.map((el) => {
            if (el.stato == "nascosta") {
                arrayContrattiEliminati.push({ id: uuid(), contrattoId: el.contratto._id, denominazione: el.puntoVendita.denominazione, contrattoLocalita: el.puntoVendita.localita, indirizzo: el.puntoVendita.indirizzo, contrattoOraInizio: el.oraInizio, contrattoOraFine: el.oraFine, richiestaId: null, dipendente: null, stato: "nascosta", duplicato: false, codice: el.codice, qualifica: el.puntoVendita.qualifica, richiestaNascostaId: el._id, continuativo: el.continuativo, numeroContinuativo: el.continuativo ? el.numeroContinuativo : null, isDipendenteSuggerito: false, dipendenteSuggerito: null, contrattoMancante: false, riferitoGiornoDopo: el.contratto.riferitoGiornoDopo })
            } else {
                if (el.contratto && true) {
                    arrayContrattiRichieste.push({ id: uuid(), contrattoId: el.contratto._id, denominazione: el.puntoVendita.denominazione, contrattoLocalita: el.puntoVendita.localita, indirizzo: el.puntoVendita.indirizzo, contrattoOraInizio: el.oraInizio, contrattoOraFine: el.oraFine, richiestaId: el._id, dipendente: el.dipendente, stato: el.stato, duplicato: false, codice: el.codice, qualifica: el.puntoVendita.qualifica, continuativo: el.contratto.continuativo, numeroContinuativo: el.continuativo ? el.numeroContinuativo : null, isDipendenteSuggerito: false, dipendenteSuggerito: null, contrattoMancante: false, riferitoGiornoDopo: el.contratto.riferitoGiornoDopo })
                    //PER CONTROLLO arrayContrattiRichieste.push({ id: uuid(), contrattoId: el.contratto._id, denominazione: el.puntoVendita.denominazione + " " + el.puntoVendita.idRaggruppa, contrattoLocalita: el.puntoVendita.localita, indirizzo: el.puntoVendita.indirizzo, contrattoOraInizio: el.oraInizio, contrattoOraFine: el.oraFine, richiestaId: el._id, dipendente: el.dipendente, stato: el.stato, duplicato: false, codice: el.codice, qualifica: el.puntoVendita.qualifica, continuativo: el.contratto.continuativo, numeroContinuativo: el.continuativo ? el.numeroContinuativo : null, isDipendenteSuggerito: false, dipendenteSuggerito: null, contrattoMancante: false })
                } else {
                    if (el.dipendentiSuggeriti.length > 0) {
                        const indexDipendenteSuggerito = el.dipendentiSuggeriti.findIndex(el => el.calcoli.disponibilita == "DI");
                        if (indexDipendenteSuggerito >= 0) {
                            arrayContrattiRichieste.push({ id: uuid(), contrattoId: el._id, denominazione: el.puntoVendita.denominazione, contrattoLocalita: el.puntoVendita.localita, indirizzo: el.puntoVendita.indirizzo, contrattoOraInizio: el.oraInizio, contrattoOraFine: el.oraFine, richiestaId: null, dipendente: el.dipendentiSuggeriti[indexDipendenteSuggerito], stato: null, duplicato: false, codice: el.codice, qualifica: el.puntoVendita.qualifica, continuativo: false, numeroContinuativo: null, isDipendenteSuggerito: true, dipendenteSuggerito: el.dipendentiSuggeriti[indexDipendenteSuggerito], contrattoMancante: false, riferitoGiornoDopo: el.riferitoGiornoDopo })
                        } else {
                            arrayContrattiRichieste.push({ id: uuid(), contrattoId: el._id, denominazione: el.puntoVendita.denominazione, contrattoLocalita: el.puntoVendita.localita, indirizzo: el.puntoVendita.indirizzo, contrattoOraInizio: el.oraInizio, contrattoOraFine: el.oraFine, richiestaId: null, dipendente: null, stato: null, duplicato: false, codice: el.codice, qualifica: el.puntoVendita.qualifica, continuativo: false, numeroContinuativo: null, isDipendenteSuggerito: false, dipendenteSuggerito: null, contrattoMancante: false, riferitoGiornoDopo: el.riferitoGiornoDopo })
                        }
                    } else {
                        arrayContrattiRichieste.push({ id: uuid(), contrattoId: el._id, denominazione: el.puntoVendita.denominazione, contrattoLocalita: el.puntoVendita.localita, indirizzo: el.puntoVendita.indirizzo, contrattoOraInizio: el.oraInizio, contrattoOraFine: el.oraFine, richiestaId: null, dipendente: null, stato: null, duplicato: false, codice: el.codice, qualifica: el.puntoVendita.qualifica, continuativo: false, numeroContinuativo: null, isDipendenteSuggerito: false, dipendenteSuggerito: null, contrattoMancante: false, riferitoGiornoDopo: el.riferitoGiornoDopo })
                    }
                }
            }
        })
        //concateno anche i turni continuativi
        arrayContrattiRichieste = arrayContrattiRichieste.concat(arrayContrattiContinuativiElaborati)

        //aggiungo richieste con contratti mancanti
        richiesteSenzaContratto.map(el => {
            //if(el.contratto){
            //  console.log("CE")
            //}else{
            //  console.log(el)
            // }
            arrayContrattiRichieste.push({ id: uuid(), contrattoId: el.contratto && el.contratto._id, denominazione: el.puntoVendita.denominazione, contrattoLocalita: el.puntoVendita.localita, indirizzo: el.puntoVendita.indirizzo, contrattoOraInizio: el.oraInizio, contrattoOraFine: el.oraFine, richiestaId: el._id, dipendente: el.dipendente, stato: el.stato, duplicato: false, codice: el.codice, qualifica: el.puntoVendita.qualifica, continuativo: el.contratto && el.contratto.continuativo, numeroContinuativo: el.continuativo ? el.numeroContinuativo : null, isDipendenteSuggerito: false, dipendenteSuggerito: null, contrattoMancante: true, riferitoGiornoDopo: el.contratto && el.contratto.riferitoGiornoDopo })
        })


        //ordinamenti
        arrayContrattiRichieste.sort((a, b) => (a.denominazione > b.denominazione) ? 1 : -1)
        arrayContrattiRichieste.sort((a, b) => {
            if (a.denominazione == b.denominazione) {
                return (fromStringToIntHour(a.contrattoOraInizio) > fromStringToIntHour(b.contrattoOraInizio)) ? 1 : -1
            } else {
                return 0
            }
        })
        //tolgo i contratti nascosti continuativi
        arrayContrattiRichieste = arrayContrattiRichieste.filter(contratto => !arrayContrattiEliminati.some(richiesta => (
            contratto.contrattoId &&
            contratto.contrattoId.toString() == richiesta.contrattoId.toString() &&
            contratto.richiestaId == null &&
            contratto.continuativo == richiesta.continuativo &&
            contratto.numeroContinuativo == richiesta.numeroContinuativo
        )));


        setRichiesteContratto(arrayContrattiRichieste.concat(arrayContrattiEliminati))
        setIncarichiAlert({ incarichiTotali: arrayContrattiRichieste.length - richiesteSenzaContratto.length, incarichiAssegnati: richieste.length - arrayContrattiEliminati.length })
        setLoadingContrattiRichieste(false)
    }, [contratti, richieste, richiesteSenzaContratto]);



    //Scatta quando viene associato/disassociato un dipendente NON PRESTATO * oppure eliminata una richiesta anomala
    useEffect(() => {
        if (dipendenteSelected) {
            cleanIncarichiDipendente()
            setWaitingTurniDipendente(true)
            let dataStart = startOfWeek(setHours(setMinutes(dataInput, 0), 0), { weekStartsOn: 1 })
            dataStart = subDays(dataStart, 1)
            let dataEnd = endOfWeek(setHours(setMinutes(dataInput, 59), 23), { weekStartsOn: 1 })
            getIncarichiDipendente({ dataInizio: dataStart, dataFine: dataEnd, idDipendente: dipendenteSelected });
        }


        //trovo la richiesta/contratto con id
        const index = richiesteContratto.findIndex(el => el.id == richiestaUpdate.id);
        if (index >= 0) {
            let temp_state = [...richiesteContratto];
            let temp_element = { ...temp_state[index] };  // Creare una copia profonda per evitare modifiche non volute
            if (richiestaUpdate.tipologia == "modifica_orario") {
                temp_element.contrattoOraInizio = richiestaUpdate.richiesta.oraInizio;
                temp_element.contrattoOraFine = richiestaUpdate.richiesta.oraFine;
                let indexDipendente = dipendentiList.findIndex(el => richiestaUpdate.dipendente._id == el._id);
                let arrayDipendenti = dipendentiList;
                if (indexDipendente >= 0) {
                    arrayDipendenti[indexDipendente] = richiestaUpdate.dipendente
                }

                let oraInizioNuovaRichiesta = richiestaUpdate.richiesta.oraInizio.split(":");
                let minutiInizioNuovaRichiesta = 60 * parseInt(oraInizioNuovaRichiesta[0]) + parseInt(oraInizioNuovaRichiesta[1]);
                let oraFineNuovaRichiesta = richiestaUpdate.richiesta.oraFine.split(":");
                let minutiFineNuovaRichiesta = 60 * parseInt(oraFineNuovaRichiesta[0]) + parseInt(oraFineNuovaRichiesta[1]);
                let oraInizioRichiestaIntermedia = '23:59'.split(":");
                let minutiInizioRichiestaIntermedia = 60 * parseInt(oraInizioRichiestaIntermedia[0]) + parseInt(oraInizioRichiestaIntermedia[1]);

                let occupato = false
                temp_state.map((elemento) => {
                    if (elemento.richiestaId) {
                        indexDipendente = dipendentiList.findIndex(el => elemento && elemento.dipendente && elemento.dipendente._id == el._id && elemento.dipendente._id != richiestaUpdate.dipendente._id);
                        if (indexDipendente >= 0) {
                            //QUI
                            //arrayDipendenti[indexDipendente].calcoli.occupato = checkIfOrarioIntersecati(elemento.contrattoOraInizio, elemento.contrattoOraFine, minutiInizioNuovaRichiesta, minutiFineNuovaRichiesta, minutiInizioRichiestaIntermedia)
                            if (checkOrarioAccavvallati(temp_element.contrattoOraInizio, temp_element.contrattoOraFine, elemento.contrattoOraInizio, elemento.contrattoOraFine)) {
                                occupato = true
                            }
                            arrayDipendenti[indexDipendente].calcoli.occupato = occupato
                            //arrayDipendenti[indexDipendente].calcoli.occupato = !checkIsFirstAssociazione(arrayDipendenti[indexDipendente]._id);
                        }
                    }
                })
                setDipendentiList(arrayDipendenti)
            } else if (richiestaUpdate.tipologia == "ripristina_contratto_nascosto") {
                temp_element.stato = "creata"
                temp_element.richiestaId = null;
            } else if (richiestaUpdate.tipologia == "contratto_nascosto") {
                temp_element.stato = "nascosta"
                temp_element.richiestaNascostaId = richiestaUpdate.richiesta._id;
            } else if (richiestaUpdate.tipologia == "dissocia") {
                if (richiestaUpdate.oldRichiesta && richiestaUpdate.oldRichiesta.recuperoRiposo) {
                    let arrayRR = recuperiRiposiDipednenti
                    let indexR = arrayRR.findIndex(el => { return richiestaUpdate.oldRichiesta.recuperoRiposo._id == el._id })
                    if (indexR > -1) {
                        arrayRR.splice(indexR, 1);
                    }
                    setRecuperiRiposiDipednenti(arrayRR)
                }
                temp_element.duplicato = false;
                const contrattiGemelli = richiesteContratto.filter(el =>
                    el.contrattoId == richiesteContratto[index].contrattoId &&
                    el.continuativo == richiesteContratto[index].continuativo &&
                    el.numeroContinuativo == richiesteContratto[index].numeroContinuativo
                );
                if (contrattiGemelli.length > 1) {
                    //temp_state.splice(index, 1);
                    temp_state = temp_state.filter((_, idx) => idx !== index);
                    //temp_state = temp_state.splice(index, 1);
                    setIncarichiAlert({ ...incarichiAlert, incarichiTotali: incarichiAlert.incarichiTotali - 1 })
                } else {
                    temp_element.dipendente = null;
                    temp_element.stato = "creata";
                    temp_element.richiestaId = null;
                    const indexNewDipendente = dipendentiList.findIndex(el => richiestaUpdate.newDipendente._id == el._id);
                    let arrayDipendenti = dipendentiList;
                    if (indexNewDipendente >= 0) {
                        arrayDipendenti[indexNewDipendente] = richiestaUpdate.newDipendente
                        setDipendentiList(arrayDipendenti)
                    }
                }
            } else if (richiestaUpdate.tipologia == "associa") {
                if (richiestaUpdate.richiesta && richiestaUpdate.richiesta.recuperoRiposo) {
                    let arrayRR = recuperiRiposiDipednenti
                    arrayRR.push(richiestaUpdate.richiesta.recuperoRiposo)
                    setRecuperiRiposiDipednenti(arrayRR)
                }
                temp_element.duplicato = false;
                temp_element.isDipendenteSuggerito = false;
                temp_element.dipendenteSuggerito = null;
                if (richiestaUpdate && !richiestaUpdate.oldDipendente) {
                    setIncarichiAlert({ ...incarichiAlert, incarichiAssegnati: incarichiAlert.incarichiAssegnati + 1 })
                }

                setRowSelected({ id: richiestaUpdate.id, idRichiesta: richiestaUpdate.richiesta._id, stato: richiestaUpdate.stato, contrattoMancante: false })
                temp_element.dipendente = richiestaUpdate.richiesta.dipendente;
                temp_element.stato = richiestaUpdate.richiesta.stato;
                temp_element.richiestaId = richiestaUpdate.richiesta._id;
                temp_element.codice = richiestaUpdate.richiesta.codice;
                const indexNewDipendente = dipendentiList.findIndex(el => richiestaUpdate.newDipendente._id == el._id);
                let arrayDipendenti = dipendentiList;
                if (indexNewDipendente >= 0) {
                    arrayDipendenti[indexNewDipendente] = richiestaUpdate.newDipendente
                }
                if (richiestaUpdate.oldDipendente) {
                    const indexOldDipendente = dipendentiList.findIndex(el => richiestaUpdate.oldDipendente._id == el._id);
                    if (indexOldDipendente >= 0) {
                        arrayDipendenti[indexOldDipendente] = richiestaUpdate.oldDipendente
                    }
                }
                setDipendentiList(arrayDipendenti)
            } else if (richiestaUpdate.tipologia == "elimina_anomala") {
                temp_state = temp_state.filter(el => el.id != richiestaUpdate.id)
                setRowSelected({ id: '', idRichiesta: '', stato: '', contrattoMancante: false })
            }

            if (richiestaUpdate.tipologia != "elimina_anomala") {
                // Ricerca nuovamente l'indice dell'elemento nel caso sia stato modificato l'array
                const newIndex = temp_state.findIndex(el => el.id === temp_element.id);
            
                // Assicurati che l'indice sia valido prima di procedere
                if (newIndex >= 0) {
                    temp_state[newIndex] = temp_element;
                }
            }

            setRichiesteContratto(temp_state)
        }
    }, [richiestaUpdate]);

    //Scatta quando viene associato/disassociato un dipendente PRESTATO 
    useEffect(() => {
        if((richiestaUpdate && richiestaUpdate.richiesta && richiestaUpdate.richiesta.prestato) || (richiestaUpdate && richiestaUpdate.oldRichiesta && richiestaUpdate.oldRichiesta.prestato)){
//trovo la richiesta/contratto con id
const index = richiesteContratto.findIndex(el => el.id == richiestaUpdate.id);
if (index >= 0) {
    let temp_state = [...richiesteContratto];
    let temp_element = { ...richiesteContratto[index] };
    if (richiestaUpdate.tipologia == "dissocia") {
        if (richiestaUpdate.oldRichiesta && richiestaUpdate.oldRichiesta.recuperoRiposo) {
            let arrayRR = recuperiRiposiDipednenti
            let index = arrayRR.findIndex(el => { return richiestaUpdate.oldRichiesta.recuperoRiposo._id == el._id })
            if (index > -1) {
                arrayRR.splice(index, 1);
            }
            setRecuperiRiposiDipednenti(arrayRR)
        }
        temp_element.duplicato = false;
        const contrattiGemelli = richiesteContratto.filter(el =>
            el.contrattoId == richiesteContratto[index].contrattoId &&
            el.continuativo == richiesteContratto[index].continuativo &&
            el.numeroContinuativo == richiesteContratto[index].numeroContinuativo
        );
        if (contrattiGemelli.length > 1) {
            temp_state.splice(index, 1);
            setIncarichiAlert({ ...incarichiAlert, incarichiTotali: incarichiAlert.incarichiTotali - 1 })
        } else {
            temp_element.dipendente = null;
            temp_element.stato = "creata";
            temp_element.richiestaId = null;
            const indexNewDipendente = dipendentiPrestati.findIndex(el => richiestaUpdate.newDipendente._id == el._id);
            let arrayDipendenti = dipendentiPrestati;
            if (indexNewDipendente >= 0) {
                arrayDipendenti[indexNewDipendente] = richiestaUpdate.newDipendente
                setDipendentiPrestati(arrayDipendenti)
            }
        }
    } else if (richiestaUpdate.tipologia == "associa") {
        if (richiestaUpdate.richiesta && richiestaUpdate.richiesta.recuperoRiposo) {
            let arrayRR = recuperiRiposiDipednenti
            arrayRR.push(richiestaUpdate.richiesta.recuperoRiposo)
            setRecuperiRiposiDipednenti(arrayRR)
        }
        temp_element.duplicato = false;
        temp_element.isDipendenteSuggerito = false;
        temp_element.dipendenteSuggerito = null;
        if (!richiesteContratto[index].dipendente) {
            setIncarichiAlert({ ...incarichiAlert, incarichiAssegnati: incarichiAlert.incarichiAssegnati + 1 })
        } else if (!richiesteContratto[index].richiestaId && richiesteContratto[index].isDipendenteSuggerito) {
            setIncarichiAlert({ ...incarichiAlert, incarichiAssegnati: incarichiAlert.incarichiAssegnati + 1 })
        }
        setRowSelected({ id: richiestaUpdate.id, idRichiesta: richiestaUpdate.richiesta._id, stato: richiestaUpdate.stato, contrattoMancante: false })
        temp_element.dipendente = richiestaUpdate.richiesta.dipendente;
        temp_element.stato = richiestaUpdate.richiesta.stato;
        temp_element.richiestaId = richiestaUpdate.richiesta._id;
        temp_element.codice = richiestaUpdate.richiesta.codice;
        const indexNewDipendente = dipendentiPrestati.findIndex(el => richiestaUpdate.newDipendente._id == el._id);
        let arrayDipendenti = dipendentiPrestati;
        if (indexNewDipendente >= 0) {
            arrayDipendenti[indexNewDipendente] = richiestaUpdate.newDipendente
        }
        if (richiestaUpdate.oldDipendente) {
            const indexOldDipendente = dipendentiPrestati.findIndex(el => richiestaUpdate.oldDipendente._id == el._id);
            if (indexOldDipendente >= 0) {
                arrayDipendenti[indexOldDipendente] = richiestaUpdate.oldDipendente
            }
        }
        setDipendentiPrestati(arrayDipendenti)
    }
    temp_state[index] = temp_element
    setRichiesteContratto(temp_state)
}
        }
    }, [richiestaUpdate]);


    //cambia stato alle richieste che sono state archiviate backend *
    useEffect(() => {
        let temp_state = [...richiesteContratto];
        if (richiesteArchiviate.tipologia == 'archivia_richieste') {
            richiesteArchiviate.richieste.map((richiesta) => {
                const index = temp_state.findIndex(el => el.richiestaId == richiesta);
                if (index >= 0) {
                    let temp_element = { ...richiesteContratto[index] };
                    temp_element.stato = "archiviata";
                    temp_state[index] = temp_element;
                }
            })
        } else if (richiesteArchiviate.tipologia == 'ripristina_richieste_archiviate') {
            richiesteArchiviate.richieste.map((richiesta) => {
                const index = temp_state.findIndex(el => el.richiestaId == richiesta);
                if (index >= 0) {
                    let temp_element = { ...richiesteContratto[index] };
                    temp_element.stato = "assegnata";
                    temp_state[index] = temp_element;
                }
            })
        }

        setRichiesteContratto(temp_state)
        setCheckBoxServizi([])
    }, [richiesteArchiviate]);


    //Listener JOB *
    useEffect(() => {
        const interval = setInterval(() => {
            if (jobId && jobId.length > 0 && waitingJob) {
                getStateDipendentiContrattoJob(jobId)
            } else {
                clearInterval(interval)
            }
        }, 100);
        return () => clearInterval(interval);
    }, [jobId]);


    //Intercetto pressione tastiera *
    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, [rowSelected]);


    //Modifico alert dipendenti quando seleziono servizio *
    useEffect(() => {
        let index = richiesteContratto.findIndex(el => el.id == rowSelected.id);
        if (index >= 0) {
            if (dipendentiList && dipendentiList.length == 0) {
                getDipendentiContrattoJob({ idContratto: richiesteContratto[index].contrattoId, dataRichiesta: dataInput, resti: restiChecked, oraInizio: richiesteContratto[index].contrattoOraInizio, oraFine: richiesteContratto[index].contrattoOraFine, idFiliale: filtraFiliale });
            }
        }
    }, [rowSelected]);


    //Filtro dipendenti in base al testo ricerca e checkbox selezionati *
    useEffect(() => {
        if(dipendenti){

        getDipendentiPrestabiliOccupati({ dipendenti: dipendenti, dataInizio: dataInput })
        let arrayDipendenti = dipendenti;
        if (checkBoxFiltraDisponibili === 'disponibili') {
            arrayDipendenti = arrayDipendenti.filter(dipendente => dipendente.calcoli.disponibilita == "DI");
        }
        if (testoFiltraDipendente.length > 0) {
            arrayDipendenti = arrayDipendenti.filter(function (dipendente) {
                return dipendente.nome.toLowerCase().includes(testoFiltraDipendente.toLowerCase())
                    || dipendente.cognome.toLowerCase().includes(testoFiltraDipendente.toLowerCase());
            });
        }
        if(arrayDipendenti && arrayDipendenti.length > 0){
            arrayDipendenti = arrayDipendenti.filter(dipendente => dipendente.qualifica != "impiegato");
            arrayDipendenti = arrayDipendenti.filter(dipendente => dipendente.calcoli && dipendente.calcoli.prestato == false);
            arrayDipendenti = arrayDipendenti.sort(ordinaDipendenti)
        }
        setDipendentiList(arrayDipendenti)
        setWaitingJob(false)
    }
    }, [dipendenti]);

    const ordinaDipendenti = (a, b) => {
        if (a.qualifica == 'ots' && b.qualifica != 'ots') {
            return -1
        } else if (a.qualifica != 'ots' && b.qualifica == 'ots') {
            return 1
        } else {
            return 0
        }
    }




    //Aggiorna dipendenti liberi all'inizio
    useEffect(() => {
        let arrayRichieste = []
        let arrayRichiesteSenzaContratto = []
        if (richieste.length > 0) {
            arrayRichieste = richieste.filter(el => el.stato == 'assegnata' || el.stato == 'archiviata')
        }
        if (richiesteSenzaContratto.length > 0) {
            arrayRichiesteSenzaContratto = richiesteSenzaContratto.filter(el => el.stato == 'assegnata' || el.stato == 'archiviata')
        }
        arrayRichieste = arrayRichieste.concat(arrayRichiesteSenzaContratto)
        let arrayDipendentiOccupati = arrayRichieste.map(el => { return { _id: el.dipendente._id } })
        arrayDipendentiOccupati = arrayDipendentiOccupati.filter((value, index, self) =>
            index == self.findIndex((t) => (t._id == value._id
            ))
        )
        dipendentiPrestabiliOccupati.map(a => {
            let indexExist = arrayDipendentiOccupati.findIndex(b => (a._id == b._id))
            if (indexExist == -1) {
                arrayDipendentiOccupati.push({ _id: a._id })
            }
        })



        let arrayDipendentiLiberi = []
        if(dipendenti && dipendenti.length > 0){
            dipendenti.map(dipendente => {
                let index = arrayDipendentiOccupati.findIndex(el => el._id == dipendente._id)
                if (index == -1) {
                    arrayDipendentiLiberi.push({ _id: dipendente._id, nome: dipendente.nome, cognome: dipendente.cognome, qualifica: dipendente.qualifica, disponibilita: dipendente.calcoli && dipendente.calcoli.disponibilita, prestato: dipendente.calcoli && dipendente.calcoli.prestato })
                } 
            })
        }
        
        arrayDipendentiLiberi = arrayDipendentiLiberi.filter(el => el)
        //arrayDipendentiLiberi = arrayDipendentiLiberi.filter(el => el.disponibilita == "DI" || el.disponibilita == "PC")
        arrayDipendentiLiberi = arrayDipendentiLiberi.filter(el => el.disponibilita == "DI")
        arrayDipendentiLiberi = arrayDipendentiLiberi.filter(el => el.qualifica == "ots" || el.qualifica == "gpg")
        arrayDipendentiLiberi = arrayDipendentiLiberi.filter(el => !el.prestato)

        arrayDipendentiLiberi = arrayDipendentiLiberi.sort(ordinaDipendenti)
        setDipendentiLiberi(arrayDipendentiLiberi)
    }, [dipendenti, richieste, dipendentiPrestabiliOccupati, richiesteSenzaContratto]);

    //Aggiorna dipendenti liberi dopo associazioni o disassociazioni
    useEffect(() => {
        let arrayDipendentiLiberi = dipendentiLiberi
        if (richiestaUpdate.tipologia == 'associa') {
            if (!richiestaUpdate.oldDipendente) {
                arrayDipendentiLiberi = arrayDipendentiLiberi.filter(el => el._id != richiestaUpdate.newDipendente._id)
            } else {
                arrayDipendentiLiberi = arrayDipendentiLiberi.filter(el => el._id != richiestaUpdate.newDipendente._id)
                if (!richiestaUpdate.oldDipendente.calcoli.occupato) {
                    arrayDipendentiLiberi.push({ _id: richiestaUpdate.oldDipendente._id, nome: richiestaUpdate.oldDipendente.nome, cognome: richiestaUpdate.oldDipendente.cognome, qualifica: richiestaUpdate.oldDipendente.qualifica })
                }
            }
        }
        if (richiestaUpdate.tipologia == 'dissocia') {
            if (!richiestaUpdate.newDipendente.calcoli.occupato) {
                arrayDipendentiLiberi.push({ _id: richiestaUpdate.newDipendente._id, nome: richiestaUpdate.newDipendente.nome, cognome: richiestaUpdate.newDipendente.cognome, qualifica: richiestaUpdate.newDipendente.qualifica })
            }
        }
        arrayDipendentiLiberi.sort(function (a, b) {
            if (a.cognome < b.cognome) { return -1; }
            if (a.cognome > b.cognome) { return 1; }
            return 0;
        })
        arrayDipendentiLiberi = arrayDipendentiLiberi.sort(ordinaDipendenti)
        setDipendentiLiberi(arrayDipendentiLiberi)

    }, [richiestaUpdate]);

    //Aggiorna dipendenti prestati all'inizio
    useEffect(() => {
        let arrayDipendentiPrestati = []
        if(dipendenti && dipendenti.length > 0){
            dipendenti.map(dipendente => {
                if (dipendente.calcoli && dipendente.calcoli.prestato) {
                    arrayDipendentiPrestati.push(dipendente)
                }
            })
        }
        
        arrayDipendentiPrestati = arrayDipendentiPrestati.filter(el => el)
        setDipendentiPrestati(arrayDipendentiPrestati)
    }, [dipendenti, richieste]);


    useEffect(() => {
        if (richiesteContratto.length > 0) {
            let array = richiesteContratto.filter(el => el.stato != "archiviata" && el.stato != "nascosta" && el.id)
            if (array.length > 0) {
                //onClickSetRowSelected({ id: array[0].id, idRichiesta: array[0].richiestaId })
            }
        }
    }, [richiesteContratto]);




    //FUNZIONI UTILITY

    //* Controlla se dipendente occupato nel range orario
    const checkIfOrarioIntersecati = (contrattoOraInizio, contrattoOraFine, minutiInizioNuovaRichiesta, minutiFineNuovaRichiesta, minutiInizioRichiestaIntermedia) => {
        let oraInizioRichiesta = contrattoOraInizio.split(":");
        let minutiInizioRichiesta = 60 * parseInt(oraInizioRichiesta[0]) + parseInt(oraInizioRichiesta[1]);
        let oraFineRichiesta = contrattoOraFine.split(":");
        let minutiFineRichiesta = 60 * parseInt(oraFineRichiesta[0]) + parseInt(oraFineRichiesta[1]);
        if (minutiInizioNuovaRichiesta >= minutiFineNuovaRichiesta) {
            if (
                (minutiInizioRichiesta >= minutiInizioNuovaRichiesta && minutiInizioRichiesta <= minutiInizioRichiestaIntermedia) ||
                (minutiInizioRichiesta >= minutiInizioRichiestaIntermedia && minutiInizioRichiesta <= minutiFineNuovaRichiesta) ||
                (minutiFineRichiesta >= minutiInizioNuovaRichiesta && minutiFineRichiesta <= minutiInizioRichiestaIntermedia) ||
                (minutiFineRichiesta >= minutiInizioRichiestaIntermedia && minutiFineRichiesta <= minutiFineNuovaRichiesta) ||
                (minutiInizioRichiesta <= minutiInizioNuovaRichiesta && minutiFineRichiesta >= minutiFineNuovaRichiesta)
            ) {
                return true
            } else {
                return false
            }
        } else {
            if (
                (minutiInizioRichiesta >= minutiInizioNuovaRichiesta && minutiInizioRichiesta < minutiFineNuovaRichiesta) ||
                (minutiFineRichiesta > minutiInizioNuovaRichiesta && minutiFineRichiesta <= minutiFineNuovaRichiesta) ||
                (minutiInizioRichiesta <= minutiInizioNuovaRichiesta && minutiFineRichiesta >= minutiFineNuovaRichiesta) ||
                (minutiInizioRichiesta >= minutiInizioNuovaRichiesta && minutiFineRichiesta >= minutiFineNuovaRichiesta)
            ) {
                return true
            } else {
                return false
            }
        }
    }




    const checkIfOrarioAccavallato = (oraInizioTurnoPrecedente, oraFineTurnoPrecedente, oraInizioNuovoTurno) => {
        let oraInizioTurnoPrecedenteInt = oraInizioTurnoPrecedente.split(":");
        let minutiInizioTurnoPrecedente = 60 * parseInt(oraInizioTurnoPrecedenteInt[0]) + parseInt(oraInizioTurnoPrecedenteInt[1]);
        let oraFineTurnoPrecedenteInt = oraFineTurnoPrecedente.split(":");
        let minutiFineTurnoPrecedente = 60 * parseInt(oraFineTurnoPrecedenteInt[0]) + parseInt(oraFineTurnoPrecedenteInt[1]);
        let oraInizioNuovoTurnoInt = oraInizioNuovoTurno.split(":");
        let minutiInizioNuovoTurno = 60 * parseInt(oraInizioNuovoTurnoInt[0]) + parseInt(oraInizioNuovoTurnoInt[1]);

        if (minutiInizioTurnoPrecedente > minutiFineTurnoPrecedente) {
            if (minutiInizioNuovoTurno < minutiFineTurnoPrecedente) {
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    }

    const checkOrarioAccavvallati = (oraInizioRichiesta, oraFineRichiesta, oraInizioOccupato, oraFineOccupato) => {
        let oraInizioRichiestaParse = Date.parse('01/01/2011 ' + oraInizioRichiesta + ':00');
        let oraFineRichiestaParse = Date.parse('01/01/2011 ' + oraFineRichiesta + ':00');
        let oraInizioOccupatoParse = Date.parse('01/01/2011 ' + oraInizioOccupato + ':00');
        let oraFineOccupatoParse = Date.parse('01/01/2011 ' + oraFineOccupato + ':00');
        if (isOrarioOltreMezzanotte(oraInizioRichiesta, oraFineRichiesta)) {
            oraFineRichiestaParse = Date.parse('01/02/2011 ' + oraFineRichiesta + ':00');
        }
        if (isOrarioOltreMezzanotte(oraInizioOccupato, oraFineOccupato)) {
            oraFineOccupatoParse = Date.parse('01/02/2011 ' + oraFineOccupato + ':00');
        }
        if (oraInizioRichiestaParse > oraInizioOccupatoParse && oraInizioRichiestaParse < oraFineOccupatoParse) {
            return true
        } else if (oraFineRichiestaParse > oraInizioOccupatoParse && oraFineRichiestaParse < oraFineOccupatoParse) {
            return true
        } else if (oraInizioRichiestaParse < oraInizioOccupatoParse && oraFineRichiestaParse > oraFineOccupatoParse) {
            return true
        } else {
            return false
        }
    }

    const checkIfOrarioTurnoPrecedenteAccavvallati = (oraInizioTurnoPrecedente, oraFineTurnoPrecedente, oraInizioOccupato, oraFineOccupato) => {
        let oraInizioTurnoPrecedenteParse = Date.parse('01/01/2011 ' + oraInizioTurnoPrecedente + ':00');
        let oraFineTurnoPrecedenteParse = Date.parse('01/01/2011 ' + oraFineTurnoPrecedente + ':00');
        let oraInizioOccupatoParse = Date.parse('01/02/2011 ' + oraInizioOccupato + ':00');
        let oraFineOccupatoParse = Date.parse('01/02/2011 ' + oraFineOccupato + ':00');
        if (isOrarioOltreMezzanotte(oraInizioTurnoPrecedente, oraFineTurnoPrecedente)) {
            oraFineTurnoPrecedenteParse = Date.parse('01/02/2011 ' + oraFineTurnoPrecedente + ':00');
        }
        if (isOrarioOltreMezzanotte(oraInizioOccupato, oraFineOccupato)) {
            oraFineOccupatoParse = Date.parse('01/03/2011 ' + oraFineOccupato + ':00');
        }
        if (oraInizioTurnoPrecedenteParse > oraInizioOccupatoParse && oraInizioTurnoPrecedenteParse < oraFineOccupatoParse) {
            return true
        } else if (oraFineTurnoPrecedenteParse > oraInizioOccupatoParse && oraFineTurnoPrecedenteParse < oraFineOccupatoParse) {
            return true
        } else if (oraInizioTurnoPrecedenteParse < oraInizioOccupatoParse && oraFineTurnoPrecedenteParse > oraFineOccupatoParse) {
            return true
        } else {
            return false
        }
    }

    const isOrarioOltreMezzanotte = (oraInizio, oraFine) => {
        const oraInizioOre = parseInt(oraInizio.substring(0, oraInizio.indexOf(":")));
        const oraFineOre = parseInt(oraFine.substring(0, oraFine.indexOf(":")));
        const oraInizioMin = parseInt(oraInizio.substring(oraInizio.indexOf(":") + 1, oraInizio.length))
        const oraFineMin = parseInt(oraFine.substring(oraFine.indexOf(":") + 1, oraFine.length))
        if (oraInizioOre > oraFineOre) {
            return true
        } else if (oraInizioOre == oraFineOre) {
            if (oraInizioMin > oraFineMin) {
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    }


    //*
    const checkIsFirstAssociazione = (idDipendente) => {
        let index = richiesteContratto.findIndex(el =>
            el.dipendente && el.dipendente._id == idDipendente
        );
        if (index >= 0) {
            return false
        } else {
            return true
        }
    }


    //*
    const fromStringToIntHour = (ora) => {
        const hour1 = parseInt(ora.substring(0, ora.indexOf(":")))
        const min1 = parseInt(ora.substring(ora.indexOf(":") + 1, ora.length))
        return hour1 + min1 / 60;
    }


    //*
    const differenzaInOreDaStringheOre = (ora1, ora2) => {
        const hour1 = parseInt(ora1.substring(0, ora1.indexOf(":")))
        const hour2 = parseInt(ora2.substring(0, ora2.indexOf(":")))
        const min1 = parseInt(ora1.substring(ora1.indexOf(":") + 1, ora1.length))
        const min2 = parseInt(ora2.substring(ora2.indexOf(":") + 1, ora2.length))
        let fromMinToHour;
        let diffHours;
        if (hour1 < hour2) {
            fromMinToHour = min2 / 60 - min1 / 60
            diffHours = hour2 - hour1 + fromMinToHour;
        } else if (hour1 == hour2) {
            if (min1 < min2) {
                fromMinToHour = min2 / 60 - min1 / 60
                diffHours = fromMinToHour;
            } else {
                fromMinToHour = min1 / 60 - min2 / 60
                diffHours = 24 - fromMinToHour;
            }
        } else {
            fromMinToHour = min1 / 60 - min2 / 60
            diffHours = 24 - (hour1 - hour2) - fromMinToHour;
        }
        return diffHours;
    }

    const checkRiposo11OreOLD = (oraInizioPrecedente, oraFinePrecedente, oraInizioNuovo) => {
        const hourInizioPrecedente = parseInt(oraInizioPrecedente.substring(0, oraInizioPrecedente.indexOf(":")))
        const minInizioPrecedente = parseInt(oraInizioPrecedente.substring(oraInizioPrecedente.indexOf(":") + 1, oraInizioPrecedente.length))
        const hourFinePrecedente = parseInt(oraFinePrecedente.substring(0, oraFinePrecedente.indexOf(":")))
        const hourInizioNuovo = parseInt(oraInizioNuovo.substring(0, oraInizioNuovo.indexOf(":")))
        const minFinePrecedente = parseInt(oraFinePrecedente.substring(oraFinePrecedente.indexOf(":") + 1, oraFinePrecedente.length))
        const minInizioNuovo = parseInt(oraInizioNuovo.substring(oraInizioNuovo.indexOf(":") + 1, oraInizioNuovo.length))
        let offset;
        if (hourInizioPrecedente > hourFinePrecedente) {
            offset = 0
        } else if ((hourInizioPrecedente > hourFinePrecedente) && (minInizioPrecedente > minFinePrecedente)) {
            offset = 0
        } else {
            offset = 24
        }
        let fromMinToHour;
        let diffHours;
        if (hourFinePrecedente < hourInizioNuovo) {
            fromMinToHour = minInizioNuovo / 60 - minFinePrecedente / 60
            diffHours = hourInizioNuovo - hourFinePrecedente + fromMinToHour + offset;
        } else if (hourFinePrecedente == hourInizioNuovo) {
            if (minFinePrecedente < minInizioNuovo) {
                fromMinToHour = minInizioNuovo / 60 - minFinePrecedente / 60
                diffHours = fromMinToHour + offset;
            } else {
                fromMinToHour = minFinePrecedente / 60 - minInizioNuovo / 60
                diffHours = fromMinToHour + offset;
            }
        } else {
            fromMinToHour = minFinePrecedente / 60 - minInizioNuovo / 60
            diffHours = 24 - (hourFinePrecedente - hourInizioNuovo) - fromMinToHour - offset;
        }
        return diffHours > 11;
    }


    const checkRiposo11Ore = (oraInizioPrecedente, oraFinePrecedente, oraInizioNuovo) => {
        let oraFineTurnoPrecedenteParse = Date.parse('01/01/2011 ' + oraFinePrecedente + ':00');
        let oraInizioNuovoParse = Date.parse('01/02/2011 ' + oraInizioNuovo + ':00');
        if (isOrarioOltreMezzanotte(oraInizioPrecedente, oraFinePrecedente)) {
            oraFineTurnoPrecedenteParse = Date.parse('01/02/2011 ' + oraFinePrecedente + ':00');
        }
        const diff = (oraInizioNuovoParse - oraFineTurnoPrecedenteParse) / (1000 * 60 * 60)
        if (diff < 11) {
            return false
        } else {
            return true
        }
    }



    //*
    const escFunction = useCallback((event) => {
        if (event.key === "Delete") {
            onClickCancDissociaDipendente()
        }
    })


    //*
    const sameDay = (d1, d2) => {
        return d1.getFullYear() === d2.getFullYear() &&
            d1.getMonth() === d2.getMonth() &&
            d1.getDate() === d2.getDate();
    }

    //*
    const fromIntToStringHour = (ora) => {
        if (ora < 10) {
            return "0" + ora
        } else {
            return "" + ora
        }
    }


    const elaboraContrattiContinuativi = (contrattiContinuativi) => {
        let arrayContratti = [];
        contrattiContinuativi.map(el => {
            let dataInizio = new Date(el.dataInizio)
            let dataFine = new Date(el.dataFine)
            if (sameDay(dataInizio, new Date(dataInput))) {
                let hour1 = parseInt(el.oraInizio.substring(0, el.oraInizio.indexOf(":")))
                const minString = el.oraInizio.substring(el.oraInizio.indexOf(":") + 1, el.oraInizio.length)
                let min1 = parseInt(minString)
                let hour2;
                let i = 1;
                while (hour1 <= 23 && min1 <= 59) {
                    hour2 = hour1 + 8
                    if (hour2 > 23) {
                        hour2 = hour2 - 24
                    }
                    arrayContratti.push({ id: uuid(), contrattoId: el._id, denominazione: el.puntoVendita.denominazione, contrattoLocalita: el.puntoVendita.localita, contrattoOraInizio: fromIntToStringHour(hour1) + ":" + minString, contrattoOraFine: fromIntToStringHour(hour2) + ":" + minString, richiestaId: null, dipendente: null, stato: null, duplicato: false, codice: el.codice, qualifica: el.puntoVendita.qualifica, continuativo: el.continuativo, numeroContinuativo: i + 1 })
                    hour1 = hour1 + 8
                    i = i + 1
                }
            } else if (sameDay(dataFine, new Date(dataInput))) {
                let hour1 = 0;
                let hour2 = 8;
                let hour3 = parseInt(el.oraFine.substring(0, el.oraFine.indexOf(":")))
                const minString = el.oraFine.substring(el.oraFine.indexOf(":") + 1, el.oraFine.length)
                let min1 = parseInt(minString)
                let i = 1
                while (hour2 <= hour3 && min1 <= 59) {
                    if (hour2 > 23) {
                        hour2 = hour2 - 24
                    }
                    arrayContratti.push({ id: uuid(), contrattoId: el._id, denominazione: el.puntoVendita.denominazione, contrattoLocalita: el.puntoVendita.localita, contrattoOraInizio: fromIntToStringHour(hour1) + ":" + minString, contrattoOraFine: fromIntToStringHour(hour2) + ":" + minString, richiestaId: null, dipendente: null, stato: null, duplicato: false, codice: el.codice, qualifica: el.puntoVendita.qualifica, continuativo: el.continuativo, numeroContinuativo: i })
                    hour1 = hour1 + 8
                    hour2 = hour1 + 8
                    i = i + 1
                }
                arrayContratti.push({ id: uuid(), contrattoId: el._id, denominazione: el.puntoVendita.denominazione, contrattoLocalita: el.puntoVendita.localita, contrattoOraInizio: fromIntToStringHour(hour1) + ":" + minString, contrattoOraFine: fromIntToStringHour(hour3) + ":" + minString, richiestaId: null, dipendente: null, stato: null, duplicato: false, codice: el.codice, qualifica: el.puntoVendita.qualifica, continuativo: el.continuativo, numeroContinuativo: i })
            } else {
                arrayContratti.push({ id: uuid(), contrattoId: el._id, denominazione: el.puntoVendita.denominazione, contrattoLocalita: el.puntoVendita.localita, contrattoOraInizio: "00:00", contrattoOraFine: "08:00", richiestaId: null, dipendente: null, stato: null, duplicato: false, codice: el.codice, qualifica: el.puntoVendita.qualifica, continuativo: el.continuativo, numeroContinuativo: 1 })
                arrayContratti.push({ id: uuid(), contrattoId: el._id, denominazione: el.puntoVendita.denominazione, contrattoLocalita: el.puntoVendita.localita, contrattoOraInizio: "08:00", contrattoOraFine: "16:00", richiestaId: null, dipendente: null, stato: null, duplicato: false, codice: el.codice, qualifica: el.puntoVendita.qualifica, continuativo: el.continuativo, numeroContinuativo: 2 })
                arrayContratti.push({ id: uuid(), contrattoId: el._id, denominazione: el.puntoVendita.denominazione, contrattoLocalita: el.puntoVendita.localita, contrattoOraInizio: "16:00", contrattoOraFine: "00:00", richiestaId: null, dipendente: null, stato: null, duplicato: false, codice: el.codice, qualifica: el.puntoVendita.qualifica, continuativo: el.continuativo, numeroContinuativo: 3 })
            }
        })
        return arrayContratti
    }

    const findRRGiaEsistente = (dipendente, recuperiRiposiDipednenti) => {
        let arrayRR = [];
        arrayRR = recuperiRiposiDipednenti.filter(el => (el.dipendente == dipendente._id))
        if (arrayRR.length > 0) {
            return true
        } else {
            return false
        }
    }

    //FUNZIONI ONCLICK



    //*
    const onClickAssociaRichiestaPopUp = (idDipendente) => {
        let index = richiesteContratto.findIndex(el => el.id == rowSelected.id);
        if (index >= 0) {
            let indexDipendente = dipendentiList.findIndex(el => el._id == idDipendente);
            associaDipendenteRichiesta({ id: richiesteContratto[index].id, idDipendente, idRichiesta: richiesteContratto[index].richiestaId, idContratto: richiesteContratto[index].contrattoId, dataRichiesta: dataInput, oraInizio: richiesteContratto[index].contrattoOraInizio, oraFine: richiesteContratto[index].contrattoOraFine, resti: restiChecked, disponibilita: dipendentiList[indexDipendente].calcoli.disponibilita, continuativo: richiesteContratto[index].continuativo, numeroContinuativo: richiesteContratto[index].numeroContinuativo, recuperoRiposo: false, dataRecuperoRiposo: dataInputRR, prestato: false })
        }
        setOpenPopUpAssociaDipendente({ open: false, messaggio: [], tipologia: "", recuperoRiposo: false, dataRecuperoRiposo: new Date(), prestato: false })
    }

    const onClickAssociaRichiestaPrestatoPopUp = (idDipendente) => {
        let index = richiesteContratto.findIndex(el => el.id == rowSelected.id);
        if (index >= 0) {
            let indexDipendente = dipendentiPrestati.findIndex(el => el._id == idDipendente);
            associaDipendenteRichiesta({ id: richiesteContratto[index].id, idDipendente, idRichiesta: richiesteContratto[index].richiestaId, idContratto: richiesteContratto[index].contrattoId, dataRichiesta: dataInput, oraInizio: richiesteContratto[index].contrattoOraInizio, oraFine: richiesteContratto[index].contrattoOraFine, resti: restiChecked, disponibilita: dipendentiPrestati[indexDipendente].calcoli.disponibilita, continuativo: richiesteContratto[index].continuativo, numeroContinuativo: richiesteContratto[index].numeroContinuativo, recuperoRiposo: false, dataRecuperoRiposo: dataInputRR, prestato: true })
        }
        setOpenPopUpAssociaDipendente({ open: false, messaggio: [], tipologia: "", recuperoRiposo: false, dataRecuperoRiposo: new Date(), prestato: false })
    }


    const setOpenPopUpAssociaDipendenteSenzaRR = (idDipendente) => {
        let index = richiesteContratto.findIndex(el => el.id == rowSelected.id);
        if (index >= 0) {
            let indexDipendente = dipendentiList.findIndex(el => el._id == idDipendente);
            if (indexDipendente < 0) {
                indexDipendente = dipendentiPrestati.findIndex(el => el._id == idDipendente);
                associaDipendenteRichiesta({ id: richiesteContratto[index].id, idDipendente, idRichiesta: richiesteContratto[index].richiestaId, idContratto: richiesteContratto[index].contrattoId, dataRichiesta: dataInput, oraInizio: richiesteContratto[index].contrattoOraInizio, oraFine: richiesteContratto[index].contrattoOraFine, resti: restiChecked, disponibilita: dipendentiPrestati[indexDipendente].calcoli.disponibilita, continuativo: richiesteContratto[index].continuativo, numeroContinuativo: richiesteContratto[index].numeroContinuativo, recuperoRiposo: false, dataRecuperoRiposo: dataInputRR, prestato: false })
            }else{
                associaDipendenteRichiesta({ id: richiesteContratto[index].id, idDipendente, idRichiesta: richiesteContratto[index].richiestaId, idContratto: richiesteContratto[index].contrattoId, dataRichiesta: dataInput, oraInizio: richiesteContratto[index].contrattoOraInizio, oraFine: richiesteContratto[index].contrattoOraFine, resti: restiChecked, disponibilita: dipendentiList[indexDipendente].calcoli.disponibilita, continuativo: richiesteContratto[index].continuativo, numeroContinuativo: richiesteContratto[index].numeroContinuativo, recuperoRiposo: false, dataRecuperoRiposo: dataInputRR, prestato: false })
            }
        }
        setOpenPopUpAssociaDipendente({ open: false, messaggio: [], tipologia: "", recuperoRiposo: false, dataRecuperoRiposo: new Date(), prestato: false })
    }

    //*
    const onClickAssociaRichiestaConRRPopUp = (idDipendente) => {
        let index = richiesteContratto.findIndex(el => el.id == rowSelected.id);
        if (index >= 0) {
            let indexDipendente = dipendentiList.findIndex(el => el._id == idDipendente);
            let arrayDipendenti = dipendentiList
            if (indexDipendente == -1) {
                arrayDipendenti = dipendentiPrestati
                indexDipendente = dipendentiPrestati.findIndex(el => el._id == idDipendente);
            }
            if (indexDipendente >= 0) {
                associaDipendenteRichiesta({ id: richiesteContratto[index].id, idDipendente, idRichiesta: richiesteContratto[index].richiestaId, idContratto: richiesteContratto[index].contrattoId, dataRichiesta: dataInput, oraInizio: richiesteContratto[index].contrattoOraInizio, oraFine: richiesteContratto[index].contrattoOraFine, resti: restiChecked, disponibilita: dipendentiList[indexDipendente].calcoli.disponibilita, continuativo: richiesteContratto[index].continuativo, numeroContinuativo: richiesteContratto[index].numeroContinuativo, recuperoRiposo: true, dataRecuperoRiposo: dataInputRR, prestato: arrayDipendenti[indexDipendente].calcoli.prestato })
            }
        }
        setOpenPopUpAssociaDipendente({ open: false, messaggio: [], tipologia: "", recuperoRiposo: false, dataRecuperoRiposo: new Date(), prestato: false })
        setOpenPopUpAssociaDipendenteConRR(false)
    }

    //*
    const onClickAssociaRichiestaDipendenteSuggeritoPopUp = (idDipendente, id) => {
        let index = richiesteContratto.findIndex(el => el.id == id);
        if (index >= 0) {
            associaDipendenteRichiesta({ id: richiesteContratto[index].id, idDipendente, idRichiesta: richiesteContratto[index].richiestaId, idContratto: richiesteContratto[index].contrattoId, dataRichiesta: dataInput, oraInizio: richiesteContratto[index].contrattoOraInizio, oraFine: richiesteContratto[index].contrattoOraFine, resti: restiChecked, disponibilita: richiesteContratto[index].dipendenteSuggerito.calcoli.disponibilita, continuativo: richiesteContratto[index].continuativo, numeroContinuativo: richiesteContratto[index].numeroContinuativo, recuperoRiposo: false, dataRecuperoRiposo: dataInputRR, prestato: false })
        }
        setOpenPopUpAssociaDipendente({ open: false, messaggio: [], tipologia: "", recuperoRiposo: false, dataRecuperoRiposo: new Date(), prestato: false })
    }

    const onClickAssociaDipendenteSuggerito = ({ idDipendente, id, idRichiesta }) => {
        if (openModificaServizio) {
            setOpenPopUpAssociaDipendente({ open: true, messaggi: [{ messaggio: "Prima di associare il dipendente devi salvare la modifica orario." }], tipologia: "alert_modifica_orario", recuperoRiposo: false, dataRecuperoRiposo: new Date(), prestato: false })
        } else {
            let index = richiesteContratto.findIndex(el => el.id == id);
            if (index >= 0) {
                let erroreDisponibilita = false;
                let erroreRiposo = false;
                let erroreOccupato = false;
                let erroreOreResidue = false;
                let erroreQualifica = false;
                let errore11OreRiposo = false;
                let erroreAccavallamento = false;
                let arrayMessaggi = [];
                let turnoGiornoPrecedente = richiesteContratto[index].dipendenteSuggerito.calcoli.turnoGiornoPrecedente
                if (turnoGiornoPrecedente.oraInizio || turnoGiornoPrecedente.oraFine) {
                    //if (checkIfOrarioAccavallato(turnoGiornoPrecedente.oraInizio, turnoGiornoPrecedente.oraFine, richiesteContratto[index].contrattoOraInizio)) {
                    if (checkOrarioAccavvallati(turnoGiornoPrecedente.oraInizio, turnoGiornoPrecedente.oraFine, richiesteContratto[index].contrattoOraInizio, richiesteContratto[index].contrattoOraFine)) {
                        erroreAccavallamento = true;
                        arrayMessaggi.push({ messaggio: "Attenzione accavallamento orario con giorno precedente!" })
                    } else if (!checkRiposo11Ore(turnoGiornoPrecedente.oraInizio, turnoGiornoPrecedente.oraFine, richiesteContratto[index].contrattoOraInizio)) {
                        errore11OreRiposo = true;
                        arrayMessaggi.push({ messaggio: "Attenzione riposo inferiore a 11 ore!" })
                    }
                }
                if (richiesteContratto[index].dipendenteSuggerito.calcoli.disponibilita !== "DI" && richiesteContratto[index].dipendenteSuggerito.calcoli.disponibilita !== "RP") {
                    erroreDisponibilita = true;
                    arrayMessaggi.push({ messaggio: "Attenzione dipendente NON disponibile!" })
                }
                if (richiesteContratto[index].dipendenteSuggerito.calcoli.disponibilita == "RP") {
                    const RRExist = findRRGiaEsistente(richiesteContratto[index].dipendenteSuggerito, recuperiRiposiDipednenti)
                    if (!RRExist) {
                        erroreRiposo = true;
                        arrayMessaggi.push({ messaggio: "Attenzione dipendente in giorno di riposo!" })
                    }
                }
                if (richiesteContratto[index].dipendenteSuggerito.calcoli.occupato) {
                    erroreOccupato = true;
                    arrayMessaggi.push({ messaggio: "Attenzione dipendente già occupato!" })
                }
                if (!checkIsFirstAssociazione(richiesteContratto[index].dipendenteSuggerito._id) && richiesteContratto[index].dipendenteSuggerito.calcoli.resOrd < differenzaInOreDaStringheOre(richiesteContratto[index].contrattoOraInizio, richiesteContratto[index].contrattoOraFine)) {
                    erroreOreResidue = true;
                    arrayMessaggi.push({ messaggio: "Attenzione numero ore residue minore della durata del turno!" })
                }
                if (richiesteContratto[index].dipendenteSuggerito.qualifica != richiesteContratto[index].qualifica) {
                    erroreQualifica = true;
                    arrayMessaggi.push({ messaggio: "Attenzione dipendente con qualifica non adeguata!" })
                }
                if (erroreDisponibilita || erroreOccupato || erroreOreResidue || erroreQualifica || errore11OreRiposo || erroreAccavallamento || erroreRiposo) {
                    setDipendenteSelected(idDipendente)
                    setOpenPopUpAssociaDipendente({ open: true, messaggi: arrayMessaggi, tipologia: "alert_dipendente_suggerito", id: id, recuperoRiposo: false, dataRecuperoRiposo: new Date(), prestato: false })
                    if (erroreRiposo) {
                        setOpenPopUpAssociaDipendente({ ...openPopUpAssociaDipendente, recuperoRiposo: true })
                    }
                } else {
                    associaDipendenteRichiesta({ id: richiesteContratto[index].id, idDipendente, idRichiesta: richiesteContratto[index].richiestaId, idContratto: richiesteContratto[index].contrattoId, dataRichiesta: dataInput, oraInizio: richiesteContratto[index].contrattoOraInizio, oraFine: richiesteContratto[index].contrattoOraFine, resti: restiChecked, disponibilita: richiesteContratto[index].dipendenteSuggerito.calcoli.disponibilita, continuativo: richiesteContratto[index].continuativo, numeroContinuativo: richiesteContratto[index].numeroContinuativo, recuperoRiposo: false, dataRecuperoRiposo: dataInputRR, prestato: false })
                }
            }
        }
    }

    const onClickAssociaDipendenteSuggeritoSenzaCheck = ({ idDipendente, id }) => {
        if (openModificaServizio) {
            setOpenPopUpAssociaDipendente({ open: true, messaggi: [{ messaggio: "Prima di associare il dipendente devi salvare la modifica orario." }], tipologia: "alert_modifica_orario", recuperoRiposo: false, dataRecuperoRiposo: new Date(), prestato: false })
        } else {
            let index = richiesteContratto.findIndex(el => el.id == id);
            if (index >= 0) {
                associaDipendenteRichiesta({ id: richiesteContratto[index].id, idDipendente, idRichiesta: richiesteContratto[index].richiestaId, idContratto: richiesteContratto[index].contrattoId, dataRichiesta: dataInput, oraInizio: richiesteContratto[index].contrattoOraInizio, oraFine: richiesteContratto[index].contrattoOraFine, resti: restiChecked, disponibilita: richiesteContratto[index].dipendenteSuggerito.calcoli.disponibilita, continuativo: richiesteContratto[index].continuativo, numeroContinuativo: richiesteContratto[index].numeroContinuativo, recuperoRiposo: false, dataRecuperoRiposo: dataInputRR, prestato: false })
            }
        }
    }

    //*
    const onClickAssociaRichiestaWithCheck = (idDipendente, prestatoCheck) => {
        if (openModificaServizio) {
            setOpenPopUpAssociaDipendente({ open: true, messaggi: [{ messaggio: "Prima di associare il dipendente devi salvare la modifica orario." }], tipologia: "alert_modifica_orario", recuperoRiposo: false, dataRecuperoRiposo: new Date(), prestato: false })
        } else {
            let index = richiesteContratto.findIndex(el => el.id == rowSelected.id);
            if (index >= 0) {
                let erroreDisponibilita = false;
                let erroreRiposo = false
                let erroreOccupato = false;
                let erroreOreResidue = false;
                let erroreQualifica = false;
                let errore11OreRiposo = false;
                let erroreAccavallamento = false;
                let arrayMessaggi = [];
                let indexDipendente = dipendentiList.findIndex(el => el._id == idDipendente);
                let turnoGiornoPrecedente = dipendentiList[indexDipendente].calcoli.turnoGiornoPrecedente
                if (turnoGiornoPrecedente.oraInizio || turnoGiornoPrecedente.oraFine) {
                    //if (checkIfOrarioAccavallato(turnoGiornoPrecedente.oraInizio, turnoGiornoPrecedente.oraFine, richiesteContratto[index].contrattoOraInizio)) {
                    if (checkIfOrarioTurnoPrecedenteAccavvallati(turnoGiornoPrecedente.oraInizio, turnoGiornoPrecedente.oraFine, richiesteContratto[index].contrattoOraInizio, richiesteContratto[index].contrattoOraFine)) {
                        erroreAccavallamento = true;
                        arrayMessaggi.push({ messaggio: "Attenzione accavallamento orario con giorno precedentee!" })
                    } else if (!checkRiposo11Ore(turnoGiornoPrecedente.oraInizio, turnoGiornoPrecedente.oraFine, richiesteContratto[index].contrattoOraInizio)) {
                        errore11OreRiposo = true;
                        arrayMessaggi.push({ messaggio: "Attenzione riposo inferiore a 11 ore!" })
                    }
                }
                if (dipendentiList[indexDipendente].calcoli.disponibilita !== "DI" && dipendentiList[indexDipendente].calcoli.disponibilita !== "RP") {
                    erroreDisponibilita = true;
                    arrayMessaggi.push({ messaggio: "Attenzione dipendente NON disponibile!" })
                }

                if (dipendentiList[indexDipendente].calcoli.disponibilita == "RP") {
                    const RRExist = findRRGiaEsistente(dipendentiList[indexDipendente], recuperiRiposiDipednenti)
                    if (!RRExist) {
                        erroreRiposo = true;
                        arrayMessaggi.push({ messaggio: "Attenzione dipendente in giorno di riposo!" })
                    }
                }
                if (dipendentiList[indexDipendente].calcoli.occupato) {
                    erroreOccupato = true;
                    arrayMessaggi.push({ messaggio: "Attenzione dipendente già occupato!" })
                }
                if (!checkIsFirstAssociazione(dipendentiList[indexDipendente]._id) && dipendentiList[indexDipendente].calcoli.resOrd < differenzaInOreDaStringheOre(richiesteContratto[index].contrattoOraInizio, richiesteContratto[index].contrattoOraFine)) {
                    erroreOreResidue = true;
                    arrayMessaggi.push({ messaggio: "Attenzione numero ore residue minore della durata del turno!" })
                }
                if (dipendentiList[indexDipendente].qualifica != richiesteContratto[index].qualifica) {
                    erroreQualifica = true;
                    arrayMessaggi.push({ messaggio: "Attenzione dipendente con qualifica non adeguata!" })
                }
                console.log("1")
                if (erroreDisponibilita || erroreOccupato || erroreOreResidue || erroreQualifica || errore11OreRiposo || erroreAccavallamento || erroreRiposo) {
                    console.log("2")
                    setDipendenteSelected(idDipendente)
                    if (erroreRiposo) {
                        console.log("3")
                        setOpenPopUpAssociaDipendente({ open: true, messaggi: arrayMessaggi, tipologia: "alert_dipendente", recuperoRiposo: true, dataRecuperoRiposo: new Date(), prestato: prestatoCheck })
                    } else {
                        setOpenPopUpAssociaDipendente({ open: true, messaggi: arrayMessaggi, tipologia: "alert_dipendente", recuperoRiposo: false, dataRecuperoRiposo: new Date(), prestato: prestatoCheck })
                    }
                } else {
                    console.log("3")
                    associaDipendenteRichiesta({ id: richiesteContratto[index].id, idDipendente, idRichiesta: richiesteContratto[index].richiestaId, idContratto: richiesteContratto[index].contrattoId, dataRichiesta: dataInput, oraInizio: richiesteContratto[index].contrattoOraInizio, oraFine: richiesteContratto[index].contrattoOraFine, resti: restiChecked, disponibilita: dipendentiList[indexDipendente].calcoli.disponibilita, continuativo: richiesteContratto[index].continuativo, numeroContinuativo: richiesteContratto[index].numeroContinuativo, recuperoRiposo: false, dataRecuperoRiposo: dataInputRR, prestato: prestatoCheck })
                }
            }
        }
    }

    //*
    const onClickAssociaRichiestaPrestatoWithCheck = (idDipendente) => {
        if (openModificaServizio) {
            setOpenPopUpAssociaDipendente({ open: true, messaggi: [{ messaggio: "Prima di associare il dipendente devi salvare la modifica orario." }], tipologia: "alert_modifica_orario", recuperoRiposo: false, dataRecuperoRiposo: new Date(), prestato: true })
        } else {
            let index = richiesteContratto.findIndex(el => el.id == rowSelected.id);
            if (index >= 0) {
                let erroreDisponibilita = false;
                let erroreRiposo = false
                let erroreOccupato = false;
                let erroreOreResidue = false;
                let erroreQualifica = false;
                let errore11OreRiposo = false;
                let erroreAccavallamento = false;
                let arrayMessaggi = [];
                let indexDipendente = dipendentiPrestati.findIndex(el => el._id == idDipendente);
                let turnoGiornoPrecedente = dipendentiPrestati[indexDipendente].calcoli.turnoGiornoPrecedente
                if (turnoGiornoPrecedente.oraInizio || turnoGiornoPrecedente.oraFine) {
                    //if (checkIfOrarioAccavallato(turnoGiornoPrecedente.oraInizio, turnoGiornoPrecedente.oraFine, richiesteContratto[index].contrattoOraInizio)) {
                    if (checkIfOrarioTurnoPrecedenteAccavvallati(turnoGiornoPrecedente.oraInizio, turnoGiornoPrecedente.oraFine, richiesteContratto[index].contrattoOraInizio, richiesteContratto[index].contrattoOraFine)) {
                        erroreAccavallamento = true;
                        arrayMessaggi.push({ messaggio: "Attenzione accavallamento orario con giorno precedentee!" })
                    } else if (!checkRiposo11Ore(turnoGiornoPrecedente.oraInizio, turnoGiornoPrecedente.oraFine, richiesteContratto[index].contrattoOraInizio)) {
                        errore11OreRiposo = true;
                        arrayMessaggi.push({ messaggio: "Attenzione riposo inferiore a 11 ore!" })
                    }
                }
                if (dipendentiPrestati[indexDipendente].calcoli.disponibilita !== "DI" && dipendentiPrestati[indexDipendente].calcoli.disponibilita !== "RP") {
                    erroreDisponibilita = true;
                    arrayMessaggi.push({ messaggio: "Attenzione dipendente NON disponibile!" })
                }
                if (dipendentiPrestati[indexDipendente].calcoli.disponibilita == "RP") {
                    const RRExist = findRRGiaEsistente(dipendentiPrestati[indexDipendente], recuperiRiposiDipednenti)
                    if (!RRExist) {
                        erroreRiposo = true;
                        arrayMessaggi.push({ messaggio: "Attenzione dipendente in giorno di riposo!" })
                    }
                }
                if (dipendentiPrestati[indexDipendente].calcoli.occupato) {
                    erroreOccupato = true;
                    arrayMessaggi.push({ messaggio: "Attenzione dipendente già occupato!" })
                }
                if (!checkIsFirstAssociazione(dipendentiPrestati[indexDipendente]._id) && dipendentiPrestati[indexDipendente].calcoli.resOrd < differenzaInOreDaStringheOre(richiesteContratto[index].contrattoOraInizio, richiesteContratto[index].contrattoOraFine)) {
                    erroreOreResidue = true;
                    arrayMessaggi.push({ messaggio: "Attenzione numero ore residue minore della durata del turno!" })
                }
                if (dipendentiPrestati[indexDipendente].qualifica != richiesteContratto[index].qualifica) {
                    erroreQualifica = true;
                    arrayMessaggi.push({ messaggio: "Attenzione dipendente con qualifica non adeguata!" })
                }
                if (erroreDisponibilita || erroreOccupato || erroreOreResidue || erroreQualifica || errore11OreRiposo || erroreAccavallamento || erroreRiposo) {
                    setDipendenteSelected(idDipendente)
                    if (erroreRiposo) {
                        setOpenPopUpAssociaDipendente({ open: true, messaggi: arrayMessaggi, tipologia: "alert_dipendente", recuperoRiposo: true, dataRecuperoRiposo: new Date(), prestato: true })
                    } else {
                        setOpenPopUpAssociaDipendente({ open: true, messaggi: arrayMessaggi, tipologia: "alert_dipendente", recuperoRiposo: false, dataRecuperoRiposo: new Date(), prestato: true })
                    }
                } else {
                    console.log("3")
                    associaDipendenteRichiesta({ id: richiesteContratto[index].id, idDipendente, idRichiesta: richiesteContratto[index].richiestaId, idContratto: richiesteContratto[index].contrattoId, dataRichiesta: dataInput, oraInizio: richiesteContratto[index].contrattoOraInizio, oraFine: richiesteContratto[index].contrattoOraFine, resti: restiChecked, disponibilita: dipendentiPrestati[indexDipendente].calcoli.disponibilita, continuativo: richiesteContratto[index].continuativo, numeroContinuativo: richiesteContratto[index].numeroContinuativo, recuperoRiposo: false, dataRecuperoRiposo: dataInputRR, prestato: true })
                }
            }
        }
    }

    //*
    const onClickGetContrattiRichiestePuntiVendita = () => {
        setDipendenteSelected(null)
        setDipendentiList([])
        setOpenListaDipendenti(false)
        setGiorniEccezioneData([])
        cleanIncarichiDipendente()
        setLoadingContrattiRichieste(true)
        setRichiesteContratto([])
        setCheckBoxAssenze([])
        cleanDipendenti()
        setRowSelected({ id: '', idRichiesta: '', stato: '', contrattoMancante: false })
        getContrattiRichiestePuntiVendita({ idCliente: idCliente, dataInizio: dataInput, idFiliale: filtraFiliale })
        getGiorniEccezione({ dataInizio: dataInput, dataFine: dataInput, idFiliale: filtraFiliale })
    }


    //*
    const onClickDuplicaRiga = () => {
        let index = richiesteContratto.findIndex(el => el.id == rowSelected.id);
        if (index >= 0) {
            let temp_state = [...richiesteContratto];
            let temp_element = { ...richiesteContratto[index] };
            temp_element.dipendente = null;
            temp_element.id = uuid();
            temp_element.duplicato = true;
            temp_element.richiestaId = null;
            temp_state.splice(index + 1, 0, temp_element);
            setRichiesteContratto(temp_state)
            setElementoDuplicato(temp_element)
            setIncarichiAlert({ ...incarichiAlert, incarichiTotali: incarichiAlert.incarichiTotali + 1 })
        }
    }

    

    //*
    const onClickOpenServizioSporadico = () => {
        setServizioSporadicoOpen(!servizioSporadicoOpen)
    }

    //*
    const onClickCloseListaDipendenti = () => {
        setDipendenteSelected(null)
        setOpenListaDipendenti(false)
        setCambiaDisponibilitaOpen(false)
        cleanDipendenti([])
        setRowSelected({ id: '', idRichiesta: '', stato: '', contrattoMancante: false });
    }


    //*
    const onClickOpenCalendario = () => {
        cleanCalendario()
        getCalendarioContratti({ dataRichiesta: dataInput, giornoSettimana: giornoSettimanaSelected });
        setOpenCalendario(true)
    }


    //*
    const onClickSetRowSelected = async ({ id, idRichiesta }) => {
        if (!waitingJob) {
            let index = richiesteContratto.findIndex(el => el.id == id);
            if (index >= 0) {
                if (richiesteContratto[index].stato != 'nascosta') {
                    if (dipendentiList && dipendentiList.length == 0) {
                        setWaitingJob(true);
                        setOpenListaDipendenti(true);
                    }
                }
                setRowSelected({ id, idRichiesta, stato: richiesteContratto[index].stato, contrattoMancante: richiesteContratto[index].contrattoMancante })
            }
        }
    }

    //*
    const onClickDissociaDipendente = async () => {
        if (rowSelected.id) {
            if (rowSelected.idRichiesta && rowSelected.idRichiesta.length > 0) {
                dissociaDipendenteRichiesta({ id: rowSelected.id, idRichiesta: rowSelected.idRichiesta, resti: restiChecked })
                setIncarichiAlert({ ...incarichiAlert, incarichiAssegnati: incarichiAlert.incarichiAssegnati - 1 })
                setElementoEliminato({ id: rowSelected.id, tipologia: "parziale" })
                setRowSelected({ ...rowSelected, idRichiesta: null })
            } else {
                const index = richiesteContratto.findIndex(el => el.id == rowSelected.id);
                if (index >= 0) {
                    nascondiContratto({ idContratto: richiesteContratto[index].contrattoId, dataRichiesta: dataInput, id: richiesteContratto[index].id, continuativo: richiesteContratto[index].continuativo, numeroContinuativo: richiesteContratto[index].numeroContinuativo, oraInizio: richiesteContratto[index].contrattoOraInizio, oraFine: richiesteContratto[index].contrattoOraFine })
                    setIncarichiAlert({ ...incarichiAlert, incarichiTotali: incarichiAlert.incarichiTotali - 1 })
                    setElementoEliminato({ id: rowSelected.id, tipologia: "totale" })
                    setRowSelected({ id: '', idRichiesta: '', stato: '', contrattoMancante: false });
                }
            }
        }
    }

    const onClickEliminaAnomalo = async () => {
        eliminaRichiestaAnomala({ idRichiesta: rowSelected.idRichiesta, id: rowSelected.id })
    }


    //*
    const onClickCancDissociaDipendente = async () => {
        if (rowSelected.id) {
            if (rowSelected.idRichiesta && rowSelected.idRichiesta.length > 0) {
                dissociaDipendenteRichiesta({ id: rowSelected.id, idRichiesta: rowSelected.idRichiesta, resti: restiChecked })
                setIncarichiAlert({ ...incarichiAlert, incarichiAssegnati: incarichiAlert.incarichiAssegnati - 1 })
                setElementoEliminato({ id: rowSelected.id, tipologia: "parziale" })
                setRowSelected({ ...rowSelected, idRichiesta: null })
            }
        }
    }


    //*
    const onClickArchiviaRichieste = (() => {
        if (checkBoxServizi.length > 0) {
            archiviaRichieste({ richieste: checkBoxServizi })
        }
    })

    const onClickRipristinaRichiesteArchiviate = (() => {
        if (checkBoxServizi.length > 0) {
            annullaArchiviaRichieste({ richieste: checkBoxServizi })
        }
    })



    //*
    const onClickEliminaGiornoEccezione = (() => {
        let index = richiesteContratto.findIndex(el => el.id == rowSelected.id);
        if (index >= 0) {
            eliminaGiornoEccezioneLungoRichieste({idGiornoEccezione: rowSelectedGiornoEccezione.id, dataRichiesta: dataInput, oraInizio: richiesteContratto[index].contrattoOraInizio, oraFine: richiesteContratto[index].contrattoOraFine, resti: restiChecked})
        }
    })

    //*
    const onClickConfermaGiornoEccezione = (() => {
        confermaGiorniEccezione({ giorniEccezione: checkBoxAssenze, check: true })
    })

    //*
    const onClickSconfermaGiornoEccezione = (() => {
        confermaGiorniEccezione({ giorniEccezione: checkBoxAssenze, check: false })
    })

    //*
    const onClickRipristinaContrattoNascosto = (() => {
        if (rowSelected.id && rowSelected.stato == "nascosta") {
            let index = richiesteContratto.findIndex(el => el.id == rowSelected.id);
            if (index >= 0) {
                ripristinaContrattoNascosto({ id: richiesteContratto[index].id, idRichiesta: richiesteContratto[index].richiestaNascostaId })
                setRowSelected({ id: '', idRichiesta: '', stato: '', contrattoMancante: false });
                setIncarichiAlert({ ...incarichiAlert, incarichiTotali: incarichiAlert.incarichiTotali + 1 })
            }
        }
    })


    //*
    const onClickAttivaModificaServizio = (() => {
        let index = richiesteContratto.findIndex(el => el.id == rowSelected.id);
        if (index >= 0) {
            if (richiesteContratto[index].richiestaId) {
                let indexRichiesta = richieste.findIndex(el => el._id == richiesteContratto[index].richiestaId);
                if (indexRichiesta >= 0) {
                    setModificaServizio({ oraInizio: richieste[indexRichiesta].oraInizio, oraFine: richieste[indexRichiesta].oraFine })
                } else if (richiesteContratto[index].richiestaId == richiestaUpdate.richiesta._id) {
                    setModificaServizio({ oraInizio: richiestaUpdate.richiesta.oraInizio, oraFine: richiestaUpdate.richiesta.oraFine })
                }
            } else {
                setModificaServizio({ oraInizio: richiesteContratto[index].contrattoOraInizio, oraFine: richiesteContratto[index].contrattoOraFine })
            }
        }
        setOpenModificaServizio(true);
    })


    //*
    const onClickAnnullaModificaServizio = (() => {
        setOpenModificaServizio(false);
    })

    //*
    const onClickAddGiornoEccezione = (() => {
        aggiungiGiornoEccezioneLungo({ idDipendente: dipendenteGiornoEccezioneSelected, disponibilita: tipologiaSelected, dataInizio: nuovaDataInizioInput, dataFine: nuovaDataFineInput, nProtocollo: nProtocollo })
    })


    
    //*
    const onClickSelectDipendente = ((e, idDipendente, prestatoCheck) => {
        switch (e.detail) {
            case 1:
                setDipendenteSelected(idDipendente);
                break;
            case 2:
                onClickAssociaRichiestaWithCheck(idDipendente, prestatoCheck)
                break;
            case 3:
                onClickAssociaRichiestaWithCheck(idDipendente, prestatoCheck)
                break;
            default:
                return;
        }
        if (idDipendente) {
            //Carica turni dipendente della giornata
            setWaitingTurniDipendente(true)
            cleanIncarichiDipendente()
            let dataStart = startOfWeek(setHours(setMinutes(dataInput, 0), 0), { weekStartsOn: 1 })
            dataStart = subDays(dataStart, 1)
            let dataEnd = endOfWeek(setHours(setMinutes(dataInput, 59), 23), { weekStartsOn: 1 })
            getIncarichiDipendente({ dataInizio: dataStart, dataFine: dataEnd, idDipendente: idDipendente });
        }
    })

    //*
    const onClickSelectDipendenteNonAssegnato = ((idDipendente) => {
        setDipendenteNonAssegnatoSelected(idDipendente);
    })

    //*
    const onClickSalvaDisponibilita = (() => {
        let index = richiesteContratto.findIndex(el => el.id == rowSelected.id);
        if (index >= 0) {
            let indexDipendente = dipendentiList.findIndex(el => dipendenteSelected == el._id);
            if (indexDipendente >= 0) {
                if (dipendentiList[indexDipendente].calcoli.occupato) {
                    setOpenPopUpAssociaDipendente({ open: true, messaggi: [{ messaggio: "Dipendente già occupato nel giorno selezionato!" }], tipologia: "alert_modifica_orario", recuperoRiposo: false, dataRecuperoRiposo: new Date(), prestato: false })
                } else {
                    if(dipendentiList[indexDipendente].calcoli.disponibilita == "FE" && disponibilitaSelected == "DI"){
                        togliFerieAggiungiGiornoEccezione({ idDipendente: dipendenteSelected, disponibilita: disponibilitaSelected, dataRichiesta: dataInput, oraInizio: richiesteContratto[index].contrattoOraInizio, oraFine: richiesteContratto[index].contrattoOraFine, resti: restiChecked, nProtocollo: nProtocollo })
                    }else{
                        aggiungiGiornoEccezione({ idDipendente: dipendenteSelected, disponibilita: disponibilitaSelected, dataRichiesta: dataInput, oraInizio: richiesteContratto[index].contrattoOraInizio, oraFine: richiesteContratto[index].contrattoOraFine, resti: restiChecked, nProtocollo: nProtocollo })
                    }
                }
            }
        }
    })

    //*
    const onClickSalvaDisponibilitaNonAssegnati = (() => {
        let index = richiesteContratto.findIndex(el => el.id == rowSelected.id);
        if (index >= 0) {
            let indexDipendente = dipendentiLiberi.findIndex(el => dipendenteNonAssegnatoSelected == el._id);
            if (indexDipendente >= 0) {
                aggiungiGiornoEccezione({ idDipendente: dipendenteNonAssegnatoSelected, disponibilita: disponibilitaNonAssegnatoSelected, dataRichiesta: dataInput, oraInizio: richiesteContratto[index].contrattoOraInizio, oraFine: richiesteContratto[index].contrattoOraFine, resti: restiChecked, nProtocollo: nProtocollo })
            }
        }
    })

    //Versione con check occupato giornaliero
    const onClickSalvaModificaServizio = (() => {
        let index = richiesteContratto.findIndex(el => el.id == rowSelected.id);
        if (index >= 0) {
            let oraInizioSplitted = modificaServizio.oraInizio.split(":");
            let oraFineSplitted = modificaServizio.oraFine.split(":");
            if ((parseInt(oraInizioSplitted[0]) <= 23 && parseInt(oraInizioSplitted[0]) >= 0) && (parseInt(oraInizioSplitted[1]) <= 59 && parseInt(oraInizioSplitted[1]) >= 0)
                &&
                (parseInt(oraFineSplitted[0]) <= 23 && parseInt(oraFineSplitted[0]) >= 0) && (parseInt(oraFineSplitted[1]) <= 59 && parseInt(oraFineSplitted[1]) >= 0)
            ) {
                let temp_state = [...richiesteContratto];
                let temp_element = { ...richiesteContratto[index] };
                temp_element.contrattoOldOraInizio = temp_element.contrattoOraInizio
                temp_element.contrattoOraInizio = modificaServizio.oraInizio
                temp_element.contrattoOldOraFine = temp_element.contrattoOraFine
                temp_element.contrattoOraFine = modificaServizio.oraFine
                temp_state[index] = temp_element;
                setRichiesteContratto(temp_state)
                setElementoModificato(temp_element)
                if (richiesteContratto[index].dipendente) {
                    modificaOrarioRichiesta({ id: temp_element.id, idRichiesta: temp_element.richiestaId, dataRichiesta: dataInput, oraInizio: modificaServizio.oraInizio, oraFine: modificaServizio.oraFine, resti: restiChecked })
                } else {

                    let arrayDipendenti = dipendentiList;
                    let occupato = false
                    temp_state.map((elemento) => {
                        if (elemento.richiestaId) {
                            let indexDipendente = dipendentiList.findIndex(el => elemento.dipendente && elemento.dipendente._id == el._id);
                            if (indexDipendente >= 0) {
                                //QUI
                                //arrayDipendenti[indexDipendente].calcoli.occupato = !checkIsFirstAssociazione(arrayDipendenti[indexDipendente]._id);
                                if (checkOrarioAccavvallati(temp_element.contrattoOraInizio, temp_element.contrattoOraFine, elemento.contrattoOraInizio, elemento.contrattoOraFine)) {
                                    occupato = true
                                }
                                arrayDipendenti[indexDipendente].calcoli.occupato = occupato
                            }
                        }
                    })
                    setDipendentiList(arrayDipendenti)
                }
            }
        }
        setModificaServizio({ oraInizio: "", oraFine: "" })
        setOpenModificaServizio(false);
    })


    //*
    const onClickSetGiornoEccezioneRowSelected = async ({ id }) => {
        setGiornoEccezioneRowSelected({ id })
    }

    //Versione con check occupato orario
    const onClickSalvaModificaServizioOLD = (() => {
        let index = richiesteContratto.findIndex(el => el.id == rowSelected.id);
        if (index >= 0) {
            let oraInizioSplitted = modificaServizio.oraInizio.split(":");
            let oraFineSplitted = modificaServizio.oraFine.split(":");
            if ((parseInt(oraInizioSplitted[0]) <= 23 && parseInt(oraInizioSplitted[0]) >= 0) && (parseInt(oraInizioSplitted[1]) <= 59 && parseInt(oraInizioSplitted[1]) >= 0)
                &&
                (parseInt(oraFineSplitted[0]) <= 23 && parseInt(oraFineSplitted[0]) >= 0) && (parseInt(oraFineSplitted[1]) <= 59 && parseInt(oraFineSplitted[1]) >= 0)
            ) {
                let temp_state = [...richiesteContratto];
                let temp_element = { ...richiesteContratto[index] };
                temp_element.contrattoOldOraInizio = temp_element.contrattoOraInizio
                temp_element.contrattoOraInizio = modificaServizio.oraInizio
                temp_element.contrattoOldOraFine = temp_element.contrattoOraFine
                temp_element.contrattoOraFine = modificaServizio.oraFine
                temp_state[index] = temp_element;
                setRichiesteContratto(temp_state)
                setElementoModificato(temp_element)
                if (richiesteContratto[index].dipendente) {
                    modificaOrarioRichiesta({ id: temp_element.id, idRichiesta: temp_element.richiestaId, dataRichiesta: dataInput, oraInizio: modificaServizio.oraInizio, oraFine: modificaServizio.oraFine, resti: restiChecked })
                } else {
                    let oraInizioNuovaRichiesta = temp_element.contrattoOraInizio.split(":");
                    let minutiInizioNuovaRichiesta = 60 * parseInt(oraInizioNuovaRichiesta[0]) + parseInt(oraInizioNuovaRichiesta[1]);
                    let oraFineNuovaRichiesta = temp_element.contrattoOraFine.split(":");
                    let minutiFineNuovaRichiesta = 60 * parseInt(oraFineNuovaRichiesta[0]) + parseInt(oraFineNuovaRichiesta[1]);
                    let oraInizioRichiestaIntermedia = '23:59'.split(":");
                    let minutiInizioRichiestaIntermedia = 60 * parseInt(oraInizioRichiestaIntermedia[0]) + parseInt(oraInizioRichiestaIntermedia[1]);
                    let arrayDipendenti = dipendentiList;
                    temp_state.map((elemento) => {
                        if (elemento.richiestaId) {
                            let indexDipendente = dipendentiList.findIndex(el => elemento.dipendente._id == el._id);
                            if (indexDipendente >= 0) {
                                let oraInizioRichiesta = elemento.contrattoOraInizio.split(":");
                                let minutiInizioRichiesta = 60 * parseInt(oraInizioRichiesta[0]) + parseInt(oraInizioRichiesta[1]);
                                let oraFineRichiesta = elemento.contrattoOraFine.split(":");
                                let minutiFineRichiesta = 60 * parseInt(oraFineRichiesta[0]) + parseInt(oraFineRichiesta[1]);
                                if (minutiInizioNuovaRichiesta >= minutiFineNuovaRichiesta) {
                                    if (
                                        (minutiInizioRichiesta >= minutiInizioNuovaRichiesta && minutiInizioRichiesta <= minutiInizioRichiestaIntermedia) ||
                                        (minutiInizioRichiesta >= minutiInizioRichiestaIntermedia && minutiInizioRichiesta <= minutiFineNuovaRichiesta) ||
                                        (minutiFineRichiesta >= minutiInizioNuovaRichiesta && minutiFineRichiesta <= minutiInizioRichiestaIntermedia) ||
                                        (minutiFineRichiesta >= minutiInizioRichiestaIntermedia && minutiFineRichiesta <= minutiFineNuovaRichiesta) ||
                                        (minutiInizioRichiesta <= minutiInizioNuovaRichiesta && minutiFineRichiesta >= minutiFineNuovaRichiesta)
                                    ) {
                                        arrayDipendenti[indexDipendente].calcoli.occupato = true
                                    } else {
                                        arrayDipendenti[indexDipendente].calcoli.occupato = false
                                    }
                                } else {
                                    if (
                                        (minutiInizioRichiesta >= minutiInizioNuovaRichiesta && minutiInizioRichiesta < minutiFineNuovaRichiesta) ||
                                        (minutiFineRichiesta > minutiInizioNuovaRichiesta && minutiFineRichiesta <= minutiFineNuovaRichiesta) ||
                                        (minutiInizioRichiesta <= minutiInizioNuovaRichiesta && minutiFineRichiesta >= minutiFineNuovaRichiesta) ||
                                        (minutiInizioRichiesta >= minutiInizioNuovaRichiesta && minutiFineRichiesta >= minutiFineNuovaRichiesta)
                                    ) {
                                        arrayDipendenti[indexDipendente].calcoli.occupato = true
                                    } else {
                                        arrayDipendenti[indexDipendente].calcoli.occupato = false
                                    }
                                }
                            }
                        }
                    })
                    setDipendentiList(arrayDipendenti)
                }
            }
        }
        setModificaServizio({ oraInizio: "", oraFine: "" })
        setOpenModificaServizio(false);
    })


    const onClickAggiungiServizioSporadico = () => {
        aggiungiServizioSporadico({ idPuntoVenditaSporadico, dataInizioServizioSporadico, dataFineServizioSporadico, oraInizioServizioSporadico, oraFineServizioSporadico, multiplo })
    }

    //FUNZIONI ONCHANGE

    //*
    const onChangeRangeDate = (data) => {
        setRowSelected({ id: '', idRichiesta: '', stato: '', contrattoMancante: false })
        setDataInput(data)
        setDatiServizioSporadico({ ...datiServizioSporadico, dataInizioServizioSporadico: data, dataFineServizioSporadico: data })

    }

    //*
    const onChangeRangeDateRR = (data) => {
        setDataInputRR(data)
    }

    //*
    const onChangeSetDipendente = (e) => {
        setDipendenteGiornoEccezioneSelected(e.target.value)
    }

    //*
    const onChangeSetTipologia = (e) => {
        setTipologiaSelected(e.target.value)
    }

    //*
    const onChangeSetNuovaDataInput = (e) => {
        if (e.target.name == 'nuovaDataInizio') {
            setNuovaDataInizioInput(e.target.value)
        } else {
            setNuovaDataFineInput(e.target.value)
        }
    }

    // *
    const onChangeSetCheckboxFiltraDisponibili = (e) => {
        setCheckBoxFiltraDisponibili(e.target.value)
        let arrayDipendenti = dipendenti;
        if (e.target.value === 'disponibili') {
            arrayDipendenti = arrayDipendenti.filter(dipendente => dipendente.calcoli.disponibilita == "DI");
        }
        if (testoFiltraDipendente.length > 0) {
            arrayDipendenti = arrayDipendenti.filter(function (dipendente) {
                return dipendente.nome.toLowerCase().includes(testoFiltraDipendente.toLowerCase())
                    || dipendente.cognome.toLowerCase().includes(testoFiltraDipendente.toLowerCase());
            });
        }
        arrayDipendenti = arrayDipendenti.filter(dipendente => dipendente.qualifica != "impiegato");
        arrayDipendenti = arrayDipendenti.filter(dipendente => dipendente.calcoli.prestato == false);
        arrayDipendenti = arrayDipendenti.sort(ordinaDipendenti)
        setDipendentiList(arrayDipendenti)
    }


    //*
    const onChangeSetCheckboxResti = (e) => {
        setRestiChecked(!restiChecked)
        let index = richiesteContratto.findIndex(el => el.id == rowSelected.id);
        if (index >= 0) {
            setWaitingJob(true)
            getDipendentiContrattoJob({ idContratto: richiesteContratto[index].contrattoId, dataRichiesta: dataInput, resti: !restiChecked, oraInizio: richiesteContratto[index].contrattoOraInizio, oraFine: richiesteContratto[index].contrattoOraFine, idFiliale: filtraFiliale });
        }
    }


    //*
    const onChangeFiltraDipendenti = e => {
        if (rowSelected.id) {
            setTestoFiltraDipendente(e.target.value)
            let arrayDipendenti = dipendenti.filter(function (dipendente) {
                return dipendente.nome.toLowerCase().includes(e.target.value.toLowerCase())
                    || dipendente.cognome.toLowerCase().includes(e.target.value.toLowerCase());
            });
            if (checkBoxFiltraDisponibili === 'disponibili') {
                arrayDipendenti = arrayDipendenti.filter(dipendente => dipendente.calcoli.disponibilita == "DI");
            }
            arrayDipendenti = arrayDipendenti.filter(dipendente => dipendente.qualifica != "impiegato");
            arrayDipendenti = arrayDipendenti.filter(dipendente => dipendente.calcoli.prestato == false);
            arrayDipendenti = arrayDipendenti.sort(ordinaDipendenti)
            if (arrayDipendenti && arrayDipendenti.length > 0) {
                setDipendentiList(arrayDipendenti)
            }
        }
    };


    //*
    const onChangeSetCheckboxServizi = ({ name, id, value }) => {
        let arrayCheckboxServizi = [];
        if (id) {
            arrayCheckboxServizi = [...checkBoxServizi];
            const index = checkBoxServizi.findIndex(el => el == id);
            if (index >= 0) {
                arrayCheckboxServizi.splice(index, 1);
            } else {
                arrayCheckboxServizi.push(id);
            }
        } else if (name === "tuttiContratti") {
            setCheckboxSelezionaTurni(!checkboxSelezionaTurni)
            if (value == "true") {
                arrayCheckboxServizi = []
            } else {
                richiesteContratto.map((el) => {
                    if ((el.stato === "assegnata" || el.stato === "archiviata") && el.richiestaId.length > 0) {
                        arrayCheckboxServizi.push(el.richiestaId)
                    }
                })
            }
        }
        setCheckBoxServizi(arrayCheckboxServizi)
    };


    //*
    const onChangeModificaOrarioServizio = ((e) => {
        setModificaServizio({ ...modificaServizio, [e.target.name]: e.target.value })
    })


    //*
    const onChangeSetDisponibilita = (e) => {
        setDisponibilitaSelected(e.target.value)
    }

     //*
     const onChangeSetDisponibilitaNonAssegnati = (e) => {
        setDisponibilitaNonAssegnatoSelected(e.target.value)
    }


    const onChangeSetDatiServizioSporadico = e => setDatiServizioSporadico({ ...datiServizioSporadico, [e.target.name]: e.target.value });

    const onChangeSetDatiServizioSporadicoPuntoVendita = e => {
        getContrattiPuntoVendita({ idPuntoVendita: e.target.value })
        setDatiServizioSporadico({ ...datiServizioSporadico, [e.target.name]: e.target.value })
    };

    const onChangeSetDatiServizioSporadicoContratto = e => {
        let contrattoSelected = contrattiPuntoVendita.find(contratto => {
            return contratto._id == e.target.value
        })
        if (contrattoSelected) {
            setDatiServizioSporadico({ ...datiServizioSporadico, lunedi: contrattoSelected.cadenza.lunedi, martedi: contrattoSelected.cadenza.martedi, mercoledi: contrattoSelected.cadenza.mercoledi, giovedi: contrattoSelected.cadenza.giovedi, venerdi: contrattoSelected.cadenza.venerdi, sabato: contrattoSelected.cadenza.sabato, domenica: contrattoSelected.cadenza.domenica, festivi: contrattoSelected.cadenza.festivi, semifestivi: contrattoSelected.cadenza.semifestivi, oraInizioServizioSporadico: contrattoSelected.oraInizio, oraFineServizioSporadico: contrattoSelected.oraFine })
        }
    };

    const onChangeSetDatiGiornoServizioSporadico = e => {
        setDatiServizioSporadico({ ...datiServizioSporadico, [e.target.name]: e.target.value === 'false' ? true : false });
    };

    const onChangesetCheckBoxAssenze = ({ name, id, value }) => {
        let arrayCheckBoxAssenze = [];
        if (id) {
            arrayCheckBoxAssenze = [...checkBoxAssenze];
            const index = checkBoxAssenze.findIndex(el => el == id);
            if (index >= 0) {
                arrayCheckBoxAssenze.splice(index, 1);
            } else {
                arrayCheckBoxAssenze.push(id);
            }
        } else if (name === "tuttiContratti") {
            setCheckboxSelezionaAssenze(!checkboxSelezionaAssenze)
            if (value == "true") {
                arrayCheckBoxAssenze = []
            } else {
                giorniEccezioneData.map((el) => {
                    arrayCheckBoxAssenze.push(el._id)
                })
            }
        }
        setCheckBoxAssenze(arrayCheckBoxAssenze)
    };

    //*
    const onClickSetRowSelectedGiornoEccezione = async ({ id, dipendente, dataInizio, dataFine, disponibilita, nProtocollo }) => {
        setRowSelectedGiornoEccezione({ id, dipendente, dataInizio: new Date(dataInizio), dataFine: new Date(dataFine), disponibilita, nProtocollo })
    }

    const checkResOrd = (ore) => {
        let number = Number(ore)
        if (number > 0) {
            return true
        } else {
            return false
        }
    }

    const onClickAssociaPredefiniti = () => {
        if (richiesteContratto && richiesteContratto.length > 0) {
            richiesteContratto.map(el => {
                if (el.isDipendenteSuggerito && el.ririchiestaId == null) {
                    onClickAssociaDipendenteSuggeritoSenzaCheck({ idDipendente: el.dipendenteSuggerito._id, id: el.id })
                }
            })
        }
    }

    const onChangeFiltraDipendentiPerFiliale = e => {
        setFiltraFiliale(e.target.value)
    };

    const componentRef = useRef();

    const print = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: "dipendenti non assegnati"
    });


    const tableStyle2 = {
        textAlign: "left",
        border: "1px solid",
        width: "6%",
        padding: "2px 2px",
        wordBreak: "break-all"
    }

    const tableStyle1 = {
        textAlign: "left",
        border: "1px solid",
        width: "30%",
        padding: "2px 2px"
    }

    const tableStyle3 = {
        textAlign: "left",
        border: "1px solid",
        width: "15%",
        padding: "2px 2px"
    }

    const tableStyle = {
        width: "100%",
        border: "1px solid"
    };
    
    const tableInt1 = {
        fontSize: "12px",
        lineHeight: "1.3",
        background: "#8ebde6"
    };

    const getLogo = (idOrganizzazione) => {
        if (idOrganizzazione == '63e618049a5309e9f6f3ff9a') {
            return 'logo_emilpol.jpg'
        } else if (idOrganizzazione == '63e618279a5309e9f6f3ff9b') {
            return 'new_koop.png'
        } else {
            return 'logo_vigili_dell_ordine.png'
        }
    }

    return (
        <div className="body wf-section">
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`Incarichi ${dataInput.toLocaleString().split(',')[0]} - Gestionale Vigili dell'ordine`}</title>
            </Helmet>
            <div className="div_wrap">
                <div className="div_wrap_100">
                    <div className="div_wrap_50">
                        <div className="div_container">
                            <div className="title_window">
                                <div className="title_window_p">Assegnazione incarichi</div>
                                <span className={`${incarichiAlert.incarichiTotali === 0 ? "" : incarichiAlert.incarichiAssegnati < incarichiAlert.incarichiTotali ? "red_label" : "green_label"} label_incarichi`}>{`${incarichiAlert.incarichiAssegnati}/${incarichiAlert.incarichiTotali}`}</span>
                            </div>
                            <div className="dati_container">
                                <div className="fun_assegnazione_incarichi">
                                    <div className="row row_datapicker">
                                        <div className="item_1 filtro_wrap_incarichi">
                                            <div id="w-node-_7b40e076-1929-a489-3ae4-e7e497d010f9-867f153d" className="div_item_1">
                                                <div id="w-node-_7b40e076-1929-a489-3ae4-e7e497d010fa-867f153d" className="label_text">Azienda di Lavoro</div>
                                                <div data-hover="false" data-delay="0" className="select w-dropdown">
                                                    <select className="form-control select" name="idPuntoVendita" onChange={e => setIdCliente(e.target.value)} required>
                                                        <option value={''}>Tutti i clienti</option>
                                                        {clienti.map((cliente, i) => (
                                                            <option key={"cliente" + i} value={cliente._id}>{cliente.ragioneSoc}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div id="w-node-_284812ee-7924-9f38-86bb-8d2cc43dff3c-867f153d" className="div_item_1">
                                                <div id="w-node-_80bb2acb-3043-3649-0498-379a652f3b04-867f153d" className="label_text">Data</div>
                                                <div data-hover="false" data-delay="0" className="select w-dropdown">
                                                    <DatePicker
                                                        value={dataInput}
                                                        format="dd/MM/y"
                                                        weekPicker
                                                        onChange={onChangeRangeDate}
                                                        //showTodayButton={false}
                                                        //weekDays={weekDays}
                                                        //months={months}
                                                        locale="it-IT"
                                                        clearIcon={null}
                                                        weekStartDayIndex={1}
                                                    />
                                                </div>
                                            </div>
                                            <div id="w-node-_98d32d21-f772-2386-6ce9-47c8e0ec064d-867f153d" className="div_item_1">
                                                <div className="w-form">
                                                    <div id="email-form" name="email-form" data-name="Email Form" method="get">
                                                        <label className="w-checkbox checkbox-field">
                                                            <input type="checkbox" id="resti" name="resti" data-name="resti" value={restiChecked} checked={restiChecked} className="w-checkbox-input" onChange={e => onChangeSetCheckboxResti(e)} />
                                                            <span className="label_text w-form-label" htmlFor="checkbox">Resti</span>
                                                        </label>
                                                        <button id="w-node-a7f8735f-5a06-66e1-52ab-69f728a73208-867f153d" onClick={() => { onClickGetContrattiRichiestePuntiVendita() }} className="button w-button" disabled={disableButton}>Esplodi servizi</button>
                                                    </div>
                                                </div>
                                            </div>
                                            {user && user.role == 'organizzazione' ?
                                            <div id="w-node-_284812ee-7924-9f38-86bb-8d2cc43dff3c-867f153d" className="div_item_1">
                                            <div id="w-node-_7b40e076-1929-a489-3ae4-e7e497d010fa-867f153d" className="label_text">Filiale</div>
                                            <div data-hover="false" data-delay="0" className="select w-dropdown">
                                                        <select className="form-control select" name="idCliente" onChange={e => onChangeFiltraDipendentiPerFiliale(e)} required>
                                                            {filialiList.map((elementoFiliale, i) => (
                                                                <option key={i} value={elementoFiliale._id} selected={elementoFiliale._id == filtraFiliale} >{elementoFiliale.nome}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                            </div>
                                            :
                                            <div id="w-node-bf561da2-25a7-b3ef-c44c-a819295560e3-867f153d" className="div_item_empty"></div>
                                            }
                                            <div id="w-node-bf561da2-25a7-b3ef-c44c-a819295560e3-867f153d" className="div_item_empty"></div>
                                        </div>
                                    </div>
                                    <div className="row row_datapicker">
                                        <div className="item_1 filtro_wrap_incarichi">
                                            <div id="w-node-_714e71a9-0550-fe0e-26e9-8b45ac42ab89-867f153d" className="div_item_1">
                                                <div className="w-form">
                                                    <form id="email-form" name="email-form" data-name="Email Form" method="get">
                                                        <label className="w-checkbox checkbox-field"><span className="label_text w-form-label" htmlFor="checkbox-2"></span></label>
                                                        <a id="w-node-_714e71a9-0550-fe0e-26e9-8b45ac42ab90-867f153d" onClick={() => { setCheckOpenNascosti(!checkOpenNascosti) }} className={`button w-button${checkOpenNascosti ? " red" : ""}`}>{checkOpenNascosti ? "Chiudi nascosti" : "Apri nascosti"}</a>
                                                    </form>
                                                </div>
                                            </div>
                                            <div id="w-node-_714e71a9-0550-fe0e-26e9-8b45ac42ab89-867f153d" className="div_item_1">
                                                <div className="w-form">
                                                    <form id="email-form" name="email-form" data-name="Email Form" method="get">
                                                        <label className="w-checkbox checkbox-field"><span className="label_text w-form-label" htmlFor="checkbox-2"></span></label>
                                                        <a id="w-node-_714e71a9-0550-fe0e-26e9-8b45ac42ab90-867f153d" onClick={() => { onClickAssociaPredefiniti() }} className={`button w-button${richiesteContratto && richiesteContratto.length <= 0 ? " button_disabled" : ""}`}>Associa predefiniti</a>
                                                    </form>
                                                </div>
                                            </div>
                                            <div id="w-node-_32403d1b-d20e-1341-c5d5-395ced6b5d87-867f153d" className="div_item_1">
                                                <div className="w-form">
                                                    <form id="email-form" name="email-form" data-name="Email Form" method="get">
                                                        <div id="w-node-_80bb2acb-3043-3649-0498-379a652f3b04-867f153d" className="label_text">Partenza calendario</div>
                                                        <div data-hover="false" data-delay="0" className="select w-dropdown">
                                                            <select className="form-control select" name="idPuntoVendita" onChange={e => setGiornoSettimanaSelected(e.target.value)} required>
                                                                <option value="0">Lunedì</option>
                                                                <option value="1">Martedì</option>
                                                                <option value="2">Mercoledì</option>
                                                                <option value="3">Giovedì</option>
                                                                <option value="4">Venerdì</option>
                                                                <option value="5">Sabato</option>
                                                                <option value="6">Domenica</option>
                                                            </select>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                            <div id="w-node-_714e71a9-0550-fe0e-26e9-8b45ac42ab89-867f153d" className="div_item_1">
                                                <div className="w-form">
                                                    <form id="email-form" name="email-form" data-name="Email Form" method="get">
                                                        <label className="w-checkbox checkbox-field"><span className="label_text w-form-label" htmlFor="checkbox-2"></span></label>
                                                        <a id="w-node-_714e71a9-0550-fe0e-26e9-8b45ac42ab90-867f153d" onClick={() => { onClickOpenCalendario() }} className="button w-button">Calendario</a>
                                                    </form>
                                                </div>
                                            </div>
                                            
                                            <div id="w-node-_714e71a9-0550-fe0e-26e9-8b45ac42ab89-867f153d" className="div_item_1">
                                                <div className="w-form">
                                                    <form id="email-form" name="email-form" data-name="Email Form" method="get">
                                                        <label className="w-checkbox checkbox-field"><span className="label_text w-form-label" htmlFor="checkbox-2"></span></label>
                                                        <p>↪️ riferito al giorno dopo</p>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="item_2">
                                            <div className="intestazione_table">
                                                <div className="w-layout-grid div_column_assegnazioni intestazione">
                                                    <div className="div_item_column">
                                                        <div className="item_table">
                                                            <input type="checkbox" id="checkboxSelezionaTutto" name="checkboxSelezionaTutto" data-name="checkboxSelezionaTutto" className="w-checkbox-input checkbox_selection" value={checkboxSelezionaTurni} onChange={e => onChangeSetCheckboxServizi({ name: "tuttiContratti", id: null, value: e.target.value })} />
                                                        </div>
                                                    </div>
                                                    <div className="div_item_column">
                                                        <div className="item_table">Qualifica</div>
                                                    </div>
                                                    <div className="div_item_column">
                                                        <div className="item_table">Servizio</div>
                                                    </div>
                                                    <div className="div_item_column">
                                                        <div className="item_table">Località</div>
                                                    </div>
                                                    <div className="div_item_column">
                                                        <div className="item_table">Dalle</div>
                                                    </div>
                                                    <div className="div_item_column">
                                                        <div className="item_table">Alle</div>
                                                    </div>
                                                    <div className="div_item_column">
                                                        <div className="item_table">Dipendente</div>
                                                    </div>
                                                    <div className="div_item_column">
                                                        <div className="item_table"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            {loadingContrattiRichieste ? <Spinner /> :
                                                <div className="body_table">
                                                    {richiesteContratto.map((contratto, i) => (
                                                        contratto.stato == "nascosta" && checkOpenNascosti ?
                                                            <div key={"contratto_nascosto" + contratto.id} className={`w-layout-grid div_column_assegnazioni cursor ${contratto.stato == "nascosta" ? "orange_label" : rowSelected.id == contratto.id ? 'contratto_selected' : ''}`}>
                                                                <div className="div_item_column">
                                                                    <div className="item_table"><input type="checkbox" id={`checkboxSeleziona${i}`} name={`checkboxSeleziona${i}`} data-name={`checkboxSeleziona${i}`} className="w-checkbox-input checkbox_selection" disabled /></div>
                                                                </div>
                                                                <div className="div_item_column" onClick={() => contratto.stato == "archiviata" ? "" : onClickSetRowSelected({ id: contratto.id, idRichiesta: contratto.richiestaId })}>
                                                                    <div className="item_table">{contratto.qualifica}</div>
                                                                </div>
                                                                <div className="div_item_column" onClick={() => contratto.stato == "archiviata" ? "" : onClickSetRowSelected({ id: contratto.id, idRichiesta: contratto.richiestaId })}>
                                                                    <div className="item_table align_left">{contratto.denominazione}</div>
                                                                </div>
                                                                <div className="div_item_column" onClick={() => contratto.stato == "archiviata" ? "" : onClickSetRowSelected({ id: contratto.id, idRichiesta: contratto.richiestaId })}>
                                                                    <div className="item_table align_left">{contratto.contrattoLocalita} - {contratto.indirizzo}</div>
                                                                </div>
                                                                <div className="div_item_column" onClick={() => contratto.stato == "archiviata" ? "" : onClickSetRowSelected({ id: contratto.id, idRichiesta: contratto.richiestaId })}>
                                                                    <div className="item_table">{contratto.contrattoOraInizio}</div>
                                                                </div>
                                                                <div className="div_item_column" onClick={() => contratto.stato == "archiviata" ? "" : onClickSetRowSelected({ id: contratto.id, idRichiesta: contratto.richiestaId })}>
                                                                    <div className="item_table">{contratto.contrattoOraFine}</div>
                                                                </div>
                                                                <div className="div_item_column" onClick={() => contratto.stato == "archiviata" ? "" : onClickSetRowSelected({ id: contratto.id, idRichiesta: contratto.richiestaId })}>
                                                                    <div className="item_table" >{contratto.dipendente ? contratto.dipendente.cognome + " " + contratto.dipendente.nome : ''}</div>
                                                                </div>
                                                                {false ?
                                                                <div className="div_item_column" onClick={() => contratto.stato == "archiviata" ? "" : onClickSetRowSelected({ id: contratto.id, idRichiesta: contratto.richiestaId })}>
                                                                    <div className="item_table">{contratto.contrattoMancante ? '❗' : ''}{contratto.riferitoGiornoDopo ? '↪️' : ''}</div>
                                                                </div>
                                                                :
                                                                <div className="div_item_column" onClick={() => contratto.stato == "archiviata" ? "" : onClickSetRowSelected({ id: contratto.id, idRichiesta: contratto.richiestaId })}>
                                                                    <div className="item_table">{contratto.contrattoMancante ? '❗' : ''}{contratto.riferitoGiornoDopo ? '↪️' : ''}</div>
                                                                </div>
                                                                }
                                                            </div>
                                                            :
                                                            contratto.stato != "nascosta" ?
                                                                <div key={"contratto" + contratto.id} className={`w-layout-grid div_column_assegnazioni cursor ${contratto.stato == "archiviata" ? "green_label" : rowSelected.id == contratto.id ? 'contratto_selected' : ''}`}>
                                                                    <div className="div_item_column">
                                                                        <div className="item_table"><input type="checkbox" id={`checkboxSeleziona${i}`} name={`checkboxSeleziona${i}`} data-name={`checkboxSeleziona${i}`} value={checkboxSelezionaTurni} className="w-checkbox-input checkbox_selection" checked={checkBoxServizi.findIndex(el => el == contratto.richiestaId) >= 0 ? true : false} onChange={(e) => contratto.stato == "assegnata" || contratto.stato == "archiviata" ? onChangeSetCheckboxServizi({ name: "contrattoSingolo", id: contratto.richiestaId, value: e.target.value }) : ""} /></div>
                                                                    </div>
                                                                    <div className="div_item_column" onClick={() => onClickSetRowSelected({ id: contratto.id, idRichiesta: contratto.richiestaId })}>
                                                                        <div className="item_table">{contratto.qualifica}</div>
                                                                    </div>
                                                                    <div className="div_item_column" onClick={() => onClickSetRowSelected({ id: contratto.id, idRichiesta: contratto.richiestaId })}>
                                                                        <div className="item_table align_left">{contratto.denominazione}</div>
                                                                    </div>
                                                                    <div className="div_item_column" onClick={() => onClickSetRowSelected({ id: contratto.id, idRichiesta: contratto.richiestaId })}>
                                                                        <div className="item_table align_left">{contratto.contrattoLocalita} - {contratto.indirizzo}</div>
                                                                    </div>
                                                                    {openModificaServizio && rowSelected.id == contratto.id ?
                                                                        <div className="div_item_column">
                                                                            <input type="time" className="w-input input_table" maxLength="256" name="oraInizio" data-name="oraInizio" value={modificaServizio.oraInizio} id="oraInizio" onChange={e => onChangeModificaOrarioServizio(e)} />
                                                                        </div>
                                                                        :
                                                                        <div className="div_item_column" onClick={() => onClickSetRowSelected({ id: contratto.id, idRichiesta: contratto.richiestaId })}>
                                                                            <div className="item_table">{contratto.contrattoOraInizio}</div>
                                                                        </div>
                                                                    }
                                                                    {openModificaServizio && rowSelected.id == contratto.id ?
                                                                        <div className="div_item_column">
                                                                            <input type="time" className="w-input input_table" maxLength="256" name="oraFine" data-name="oraFine" value={modificaServizio.oraFine} id="oraFine" onChange={e => onChangeModificaOrarioServizio(e)} />
                                                                        </div>
                                                                        :
                                                                        <div className="div_item_column" onClick={() => onClickSetRowSelected({ id: contratto.id, idRichiesta: contratto.richiestaId })}>
                                                                            <div className="item_table">{contratto.contrattoOraFine}</div>
                                                                        </div>
                                                                    }
                                                                    {contratto.isDipendenteSuggerito ?
                                                                        <div className="div_item_column" onClick={() => onClickSetRowSelected({ id: contratto.id, idRichiesta: contratto.richiestaId })}>
                                                                            <div className="item_table" >
                                                                                <div id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98d-867f153d" className="div_item_button">
                                                                                    <a id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98e-867f153d" className="button w-button" onClick={() => onClickAssociaDipendenteSuggerito({ idDipendente: contratto.dipendenteSuggerito._id, id: contratto.id, idRichiesta: contratto.richiestaId })}>Associa {contratto.dipendente ? contratto.dipendente.cognome + " " + contratto.dipendente.nome : ''}</a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        <div className="div_item_column" onClick={() => onClickSetRowSelected({ id: contratto.id, idRichiesta: contratto.richiestaId })}>
                                                                            <div className="item_table" >{contratto.dipendente ? contratto.dipendente.cognome + " " + contratto.dipendente.nome : ''}</div>
                                                                        </div>
                                                                    }
                                                                    <div className="div_item_column" onClick={() => contratto.stato == "archiviata" ? "" : onClickSetRowSelected({ id: contratto.id, idRichiesta: contratto.richiestaId })}>
                                                                        <div className="item_table">{contratto.contrattoMancante ? '❗' : ''}{contratto.riferitoGiornoDopo ? '↪️' : ''}</div>
                                                                    </div>
                                                                </div>
                                                                :
                                                                ''
                                                    ))}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="item_3 m_top_5">
                                            <div className="list_button">
                                                <div id="w-node-_571e9ca7-8650-59ca-aaad-79ac7369ac51-867f153d" className="div_item_button">
                                                    <a id="w-node-_571e9ca7-8650-59ca-aaad-79ac7369ac52-867f153d" onClick={(e) => { rowSelected.id.length === 0 || rowSelected.stato == 'nascosta' || rowSelected.stato == 'archiviata' ? e.preventDefault() : onClickDuplicaRiga() }} className={`button w-button ${!richiesteContratto.length > 0 || loadingContrattiRichieste || (rowSelected.id.length > 0 && waitingJob || rowSelected.id.length == 0) || rowSelected.stato == 'nascosta' || rowSelected.stato == 'archiviata' ? "button_disabled" : ""}`}>Sdoppia servizio</a>
                                                </div>
                                                <div id="w-node-_8af3fff2-d158-bb07-f4c8-6e208fda3793-867f153d" className="div_item_button">
                                                    <a id="w-node-_8af3fff2-d158-bb07-f4c8-6e208fda3794-867f153d" onClick={(e) => rowSelected.id.length === 0 || rowSelected.stato == 'nascosta' || rowSelected.stato == 'archiviata' ? e.preventDefault() : onClickDissociaDipendente()} className={`button w-button ${!richiesteContratto.length > 0 || loadingContrattiRichieste || (rowSelected.id.length > 0 && waitingJob || rowSelected.id.length == 0) || rowSelected.stato == 'nascosta' || rowSelected.stato == 'archiviata' ? "button_disabled" : ""}`}>Elimina servizio</a>
                                                </div>
                                                {rowSelected.contrattoMancante ?
                                                    <div id="w-node-_8af3fff2-d158-bb07-f4c8-6e208fda3793-867f153d" className="div_item_button">
                                                        <a id="w-node-_8af3fff2-d158-bb07-f4c8-6e208fda3794-867f153d" onClick={(e) => rowSelected.id.length === 0 ? e.preventDefault() : onClickEliminaAnomalo()} className={`button w-button ${!richiesteContratto.length > 0 || loadingContrattiRichieste || (rowSelected.id.length > 0 && waitingJob || rowSelected.id.length == 0) ? "button_disabled" : ""}`}>Elimina anomalo</a>
                                                    </div>
                                                    :
                                                    ''
                                                }
                                                <div id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98d-867f153d" className="div_item_button">
                                                    <a id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98e-867f153d" onClick={(e) => rowSelected.id.length === 0 || rowSelected.stato == 'nascosta' || rowSelected.stato == 'archiviata' ? e.preventDefault() : onClickAttivaModificaServizio()} className={`button w-button ${!richiesteContratto.length > 0 || loadingContrattiRichieste || (rowSelected.id.length > 0 && waitingJob || rowSelected.id.length == 0) || rowSelected.stato == 'nascosta' || rowSelected.stato == 'archiviata' ? "button_disabled" : ""}`}>Modifica orario servizio</a>
                                                </div>
                                                {openModificaServizio ?
                                                    <Fragment>
                                                        <div id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98d-867f153d" className="div_item_button">
                                                            <a id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98e-867f153d" onClick={(e) => rowSelected.id.length === 0 ? e.preventDefault() : onClickSalvaModificaServizio()} className={`button w-button ${!richiesteContratto.length > 0 || loadingContrattiRichieste || (rowSelected.id.length > 0 && waitingJob || rowSelected.id.length == 0) ? "button_disabled" : ""}`}>Salva modifica</a>
                                                        </div>
                                                        <div id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98d-867f153d" className="div_item_button">
                                                            <a id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98e-867f153d" onClick={(e) => rowSelected.id.length === 0 ? e.preventDefault() : onClickAnnullaModificaServizio()} className={`button w-button ${!richiesteContratto.length > 0 || loadingContrattiRichieste || (rowSelected.id.length > 0 && waitingJob || rowSelected.id.length == 0) ? "button_disabled" : ""}`}>Annulla modifica</a>
                                                        </div>
                                                    </Fragment>
                                                    :
                                                    <Fragment>
                                                        <div id="w-node-bf561da2-25a7-b3ef-c44c-a819295560e3-867f153d" className="div_item_empty"></div>
                                                        <div id="w-node-bf561da2-25a7-b3ef-c44c-a819295560e3-867f153d" className="div_item_empty"></div>
                                                    </Fragment>
                                                }
                                                {checkOpenNascosti ?
                                                    <div id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98d-867f153d" className="div_item_button">
                                                        <a id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98e-867f153d" className={`button w-button ${rowSelected.stato == 'nascosta' ? "" : "button_disabled"}`} onClick={(e) => rowSelected.stato == 'nascosta' ? onClickRipristinaContrattoNascosto() : e.preventDefault()}>Ripristina nascosto</a>
                                                    </div>
                                                    :
                                                    <div id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98d-867f153d" className="div_item_button">
                                                        <a id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98e-867f153d" className={`button w-button ${checkBoxServizi.length == 0 ? "button_disabled" : ""}`} onClick={(e) => checkBoxServizi.length == 0 ? e.preventDefault() : onClickRipristinaRichiesteArchiviate()}>Ripristina servizi confermati</a>
                                                    </div>}
                                                <div id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98d-867f153d" className="div_item_button">
                                                    <a id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98e-867f153d" className={`button w-button ${checkBoxServizi.length == 0 ? "button_disabled" : ""}`} onClick={(e) => checkBoxServizi.length == 0 ? e.preventDefault() : onClickArchiviaRichieste()}>Conferma servizi</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="item_3 m_top_5">
                                            <div className="list_button">
                                                <div id="w-node-_571e9ca7-8650-59ca-aaad-79ac7369ac51-867f153d" className="div_item_button">
                                                    <a id="w-node-_571e9ca7-8650-59ca-aaad-79ac7369ac52-867f153d" onClick={(e) => { onClickOpenServizioSporadico() }} className={`button w-button`}>Servizio sporadico</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {openListaDipendenti ?
                        <div className="div_wrap_50">
                            <div className="div_container">
                                <div className="title_window">
                                    <div className="title_window_p"><Moment format='DD/MM/YYYY'>{dataInput}</Moment></div>
                                </div>
                                <div className="dati_container">
                                    <div className="fun_assegnazione_incarichi">
                                        <div className="row row_datapicker">
                                            <div className="item_1">
                                                <div id="w-node-_7b40e076-1929-a489-3ae4-e7e497d010f9-867f153d" className="div_item_1">
                                                    <div id="w-node-_7b40e076-1929-a489-3ae4-e7e497d010fa-867f153d" className="label_text">Filtra dipendenti</div>
                                                    <div data-hover="false" data-delay="0" className="w-dropdown">
                                                        <input type="text" className="w-input select" maxLength="256" name="filtraDipendente" data-name="filtraDipendente" value={testoFiltraDipendente} placeholder="Inserisci dipendenti..." id="filtraDipendente" onChange={e => onChangeFiltraDipendenti(e)} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="item_2">
                                                <div className="intestazione_table">
                                                    <div className="w-layout-grid div_column_assegnazioni_dipendenti intestazione">
                                                        <div className="div_item_column">
                                                            <div className="item_table">Qual.</div>
                                                        </div>
                                                        <div className="div_item_column">
                                                            <div className="item_table">Dipendente</div>
                                                        </div>
                                                        <div className="div_item_column">
                                                            <div className="item_table">Res.Ord</div>
                                                        </div>
                                                        <div className="div_item_column">
                                                            <div className="item_table">Res.Str</div>
                                                        </div>
                                                        <div className="div_item_column">
                                                            <div className="item_table">Ass.Ord</div>
                                                        </div>
                                                        <div className="div_item_column">
                                                            <div className="item_table">Ass.Str</div>
                                                        </div>
                                                        <div className="div_item_column">
                                                            <div className="item_table">Dis</div>
                                                        </div>
                                                        <div className="div_item_column">
                                                            <div className="item_table">B.Ore</div>
                                                        </div>
                                                        <div className="div_item_column">
                                                            <div className="item_table">ONL Mese Prec</div>
                                                        </div>
                                                        <div className="div_item_column">
                                                            <div className="item_table">Ferie</div>
                                                        </div>
                                                        <div className="div_item_column">
                                                            <div className="item_table">Perm</div>
                                                        </div>
                                                        <div className="div_item_column">
                                                            <div className="item_table">Ore mese</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {rowSelected.id.length > 0 && waitingJob ? <Spinner /> :
                                                    <div className="body_table_2">
                                                        {dipendentiList && dipendentiList.length > 0 ?
                                                        dipendentiList.map((dipendente, i) => (
                                                            <div key={"dipendente" + i} className={`w-layout-grid div_column_assegnazioni_dipendenti cursor ${dipendenteSelected == dipendente._id ? 'dipendente_selected' : dipendente.calcoli.occupato ? "red_label" : ""}`}>
                                                                <div className="div_item_column" onClick={(e) => onClickSelectDipendente(e, dipendente._id, false)}>
                                                                    <div className="item_table">{dipendente.qualifica}</div>
                                                                </div>
                                                                <div className="div_item_column" onClick={(e) => onClickSelectDipendente(e, dipendente._id, false)}>
                                                                    <div className="item_table align_left">{dipendente && dipendente.cognome} {dipendente && dipendente.nome}</div>
                                                                </div>
                                                                <div className={`div_item_column${checkResOrd(dipendente.calcoli.resOrd) ? " orange" : ""}`} onClick={(e) => onClickSelectDipendente(e, dipendente._id, false)}>
                                                                    <div className="item_table">{dipendente.calcoli.resOrd}</div>
                                                                </div>
                                                                <div className="div_item_column" onClick={(e) => onClickSelectDipendente(e, dipendente._id, false)}>
                                                                    <div className="item_table">{dipendente.calcoli.resStr}</div>
                                                                </div>
                                                                <div className="div_item_column" onClick={(e) => onClickSelectDipendente(e, dipendente._id, false)}>
                                                                    <div className="item_table">{dipendente.calcoli.assOrd}</div>
                                                                </div>
                                                                <div className="div_item_column" onClick={(e) => onClickSelectDipendente(e, dipendente._id, false)}>
                                                                    <div className="item_table">{dipendente.calcoli.assStr}</div>
                                                                </div>
                                                                {cambiaDisponibilitaOpen && dipendenteSelected == dipendente._id ?
                                                                    <div className="div_item_column">
                                                                        <select className="form-control select" name="idPuntoVendita" onChange={e => onChangeSetDisponibilita(e)} required>
                                                                            {disponibilitaJSON.disponibilita.map((el) => (
                                                                                <option selected={el.codice == disponibilitaSelected} value={el.codice}>{el.codice} - {el.nome}</option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                    :
                                                                    <div className="div_item_column" onClick={(e) => onClickSelectDipendente(e, dipendente._id, false)}>
                                                                        <div className="item_table">{dipendente.calcoli.disponibilita}</div>
                                                                    </div>
                                                                }
                                                                <div className="div_item_column" onClick={(e) => onClickSelectDipendente(e, dipendente._id, false)}>
                                                                    <div className="item_table">{dipendente.bancaOreProgressivo && dipendente.bancaOreProgressivo}</div>
                                                                </div>
                                                                <div className="div_item_column" onClick={(e) => onClickSelectDipendente(e, dipendente._id, false)}>
                                                                    <div className="item_table">{dipendente.calcoli.oreNonLavMesePrec}</div>
                                                                </div>
                                                                <div className="div_item_column" onClick={(e) => onClickSelectDipendente(e, dipendente._id, false)}>
                                                                    <div className="item_table">{dipendente.calcoli.ferie}</div>
                                                                </div>
                                                                <div className="div_item_column" onClick={(e) => onClickSelectDipendente(e, dipendente._id, false)}>
                                                                    <div className="item_table">{dipendente.calcoli.permessi}</div>
                                                                </div>
                                                                <div className="div_item_column" onClick={(e) => onClickSelectDipendente(e, dipendente._id, false)}>
                                                                    <div className="item_table">{dipendente.calcoli.oreMensili}</div>
                                                                </div>
                                                            </div>
                                                        ))
                                                        : ''
                                                    }
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="item_4 m_top_5">
                                                <div className="item_4_function">
                                                    <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc3d-867f153d" className="div_item_button">
                                                        <div id="w-node-_7ae3fa04-88bc-8c3b-7fdb-28ba347410f1-867f153d" className="form-block w-form">
                                                            <form id="email-form-2" name="email-form-2" data-name="Email Form 2" method="get">
                                                                <label className="w-radio">
                                                                    <input type="radio" data-name="Radio" id="radio" name="radio" value="disponibili" className="w-form-formradioinput w-radio-input" onChange={e => onChangeSetCheckboxFiltraDisponibili(e)} checked={checkBoxFiltraDisponibili === 'disponibili' ? true : false} disabled={!rowSelected.id} />
                                                                    <span className="label_text w-form-label" htmlFor="radio">Disponibili</span>
                                                                </label>
                                                                <label className="w-radio">
                                                                    <input type="radio" data-name="Radio 2" id="radio-2" name="radio" value="tutti" className="w-form-formradioinput w-radio-input" onChange={e => onChangeSetCheckboxFiltraDisponibili(e)} checked={checkBoxFiltraDisponibili === 'tutti' ? true : false} />
                                                                    <span className="label_text w-form-label" htmlFor="radio-2">Tutti</span>
                                                                </label>
                                                            </form>
                                                        </div>
                                                    </div>

                                                    <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc40-867f153d" className="div_item_empty"></div>
                                                    {dipendenteSelected && !cambiaDisponibilitaOpen && rowSelected.stato != 'nascosta' && rowSelected.stato != 'archiviata' ?
                                                        <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc43-867f153d" className="div_item_button">
                                                            <a id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc44-867f153d" onClick={() => onClickAssociaRichiestaWithCheck(dipendenteSelected, false)} className="button w-button">Associa</a>
                                                        </div>
                                                        : dipendenteSelected && cambiaDisponibilitaOpen ?
                                                            <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc43-867f153d" className="div_item_button">
                                                                <a id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc44-867f153d" onClick={() => setCambiaDisponibilitaOpen(false)} className="button w-button">Annulla modifica</a>
                                                            </div>
                                                            :
                                                            <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc40-867f153d" className="div_item_empty"></div>
                                                    }
                                                    {dipendenteSelected && !cambiaDisponibilitaOpen ?
                                                        <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc43-867f153d" className="div_item_button">
                                                            <a id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc44-867f153d" onClick={() => setCambiaDisponibilitaOpen(true)} className="button w-button">Modifica disponibilità</a>
                                                        </div>
                                                        : dipendenteSelected && cambiaDisponibilitaOpen ?
                                                            <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc43-867f153d" className="div_item_button">
                                                                <a id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc44-867f153d" onClick={() => onClickSalvaDisponibilita()} className="button w-button">Salva</a>
                                                            </div>
                                                            :
                                                            <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc40-867f153d" className="div_item_empty"></div>
                                                    }
                                                    <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc43-867f153d" className="div_item_button">
                                                        <a id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc44-867f153d" onClick={() => onClickCloseListaDipendenti()} className="button w-button red">Chiudi</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        ''
                    }
                    {openPopUpAssociaDipendenteConRR ?
                        <div className="popup wf-section">
                            <div className="alert_popup">
                                <p>Scegli la data a cui associare un recupero riposo</p>
                                <div className="div-block">
                                    <>
                                        <div data-hover="false" data-delay="0" className="select w-dropdown">
                                            <DatePicker
                                                value={dataInputRR}
                                                format="dd/MM/y"
                                                weekPicker
                                                onChange={onChangeRangeDateRR}
                                                locale="it-IT"
                                                clearIcon={null}
                                                weekStartDayIndex={1}
                                            />
                                        </div>
                                        <a onClick={() => onClickAssociaRichiestaConRRPopUp(dipendenteSelected)} className="button m_left_5 w-button">Salva con RR</a>
                                        <a onClick={() => setOpenPopUpAssociaDipendenteConRR(false)} className="button red w-button">Annulla</a>
                                    </>
                                </div>
                            </div>
                        </div>
                        :
                        openPopUpAssociaDipendente.open && openPopUpAssociaDipendente.messaggi.length > 0 ?
                            <div className="popup wf-section">
                                <div className="alert_popup">
                                    <div>
                                        {openPopUpAssociaDipendente.messaggi.map((el) => (
                                            <p>{el.messaggio}</p>
                                        ))}
                                    </div>
                                    {openPopUpAssociaDipendente.tipologia == "alert_dipendente" ?
                                        <div className="div-block">
                                            {openPopUpAssociaDipendente.recuperoRiposo ?
                                                <>
                                                    <a onClick={() => setOpenPopUpAssociaDipendenteConRR(true)} className="button m_left_5 w-button">Associa con RR</a>
                                                    <a onClick={() => setOpenPopUpAssociaDipendenteSenzaRR(dipendenteSelected)} className="button m_left_5 w-button">Associa senza RR</a>
                                                    <a onClick={() => setOpenPopUpAssociaDipendente({ open: false, messaggi: [], tipologia: "", id: null, recuperoRiposo: false, dataRecuperoRiposo: new Date(), prestato: false })} className="button red w-button">No</a>
                                                </>
                                                : openPopUpAssociaDipendente.prestato ?
                                                    <>
                                                        <a onClick={() => onClickAssociaRichiestaPrestatoPopUp(dipendenteSelected)} className="button m_left_5 w-button">Si</a>
                                                        <a onClick={() => setOpenPopUpAssociaDipendente({ open: false, messaggi: [], tipologia: "", id: null, recuperoRiposo: false, dataRecuperoRiposo: new Date(), prestato: false })} className="button red w-button">No</a>
                                                    </>
                                                    :
                                                    <>
                                                        <a onClick={() => onClickAssociaRichiestaPopUp(dipendenteSelected)} className="button m_left_5 w-button">Si</a>
                                                        <a onClick={() => setOpenPopUpAssociaDipendente({ open: false, messaggi: [], tipologia: "", id: null, recuperoRiposo: false, dataRecuperoRiposo: new Date(), prestato: false })} className="button red w-button">No</a>
                                                    </>
                                            }
                                        </div>
                                        :
                                        ''
                                    }
                                    {openPopUpAssociaDipendente.tipologia == "alert_dipendente_suggerito" ?
                                        <div className="div-block">
                                            <a onClick={() => onClickAssociaRichiestaDipendenteSuggeritoPopUp(dipendenteSelected, openPopUpAssociaDipendente.id)} className="button m_left_5 w-button">Si</a>
                                            <a onClick={() => setOpenPopUpAssociaDipendente({ open: false, messaggi: [], tipologia: "", id: null, recuperoRiposo: false, dataRecuperoRiposo: new Date(), prestato: false })} className="button red w-button">No</a>
                                        </div>
                                        :
                                        ''
                                    }
                                    {openPopUpAssociaDipendente.tipologia == "alert_modifica_orario" ?
                                        <div className="div-block">
                                            <a onClick={() => setOpenPopUpAssociaDipendente({ open: false, messaggi: [], tipologia: "", id: null, recuperoRiposo: false, dataRecuperoRiposo: new Date(), prestato: false })} className="button red w-button">Chiudi</a>
                                        </div>
                                        :
                                        ''
                                    }
                                </div>
                            </div>
                            :
                            ''
                    }
                    {servizioSporadicoOpen ?
                        <div className="popup wf-section">
                            <div className="alert_popup">
                                <div className="dati_container">
                                    <div className="fun_assegnazione_incarichi">
                                        <div className="row">
                                            <div className="item_2">
                                                <h4>Crea servizio sporadico</h4>
                                            </div>
                                            <div className="w-form">
                                                <form id="nuovo_punto_servizio" name="email-form-2" data-name="Email Form 2" method="get" aria-label="Email Form 2">
                                                    <div className="div_wrap_100">
                                                        <div className="div_wrap_100">
                                                            <div className="col_input col_select">
                                                                <label htmlFor="name">Punto di servizio</label>
                                                                <div data-hover="false" data-delay="0" className="select w-dropdown">
                                                                    <select className="form-control select" name="idPuntoVenditaSporadico" onChange={e => onChangeSetDatiServizioSporadicoPuntoVendita(e)} required>
                                                                        <option selected value={null} disabled >Seleziona punto di servizio...</option>
                                                                        {puntiVendita.map((puntoVendita, i) => (
                                                                            <option key={i} value={puntoVendita._id}>{puntoVendita.denominazione}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="div_wrap_100">
                                                        <div className="div_wrap_50">
                                                            <div className="col_input"><label htmlFor="name-11">Data inizio</label>
                                                                <div data-hover="false" data-delay="0" className="select w-dropdown select_data">
                                                                    <DatePicker
                                                                        onChange={(value) => setDatiServizioSporadico({ ...datiServizioSporadico, dataInizioServizioSporadico: value })}
                                                                        value={new Date(dataInizioServizioSporadico)}
                                                                        calendarIcon={null}
                                                                        clearIcon={null}
                                                                        className={'form-control'}
                                                                    //disableCalendar={true}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="div_wrap_50">
                                                            <div className="col_input"><label htmlFor="name-11">Data fine</label>
                                                                <div data-hover="false" data-delay="0" className="select w-dropdown select_data">
                                                                    <DatePicker
                                                                        onChange={(value) => setDatiServizioSporadico({ ...datiServizioSporadico, dataFineServizioSporadico: value })}
                                                                        value={new Date(dataFineServizioSporadico)}
                                                                        calendarIcon={null}
                                                                        clearIcon={null}
                                                                        className={'form-control'}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="div_wrap_100">
                                                        <div className="div_wrap_50">
                                                            <div className="col_input"><label htmlFor="name-11">Orario inizio</label>
                                                                <input className="input w-input" type="time" value={oraInizioServizioSporadico} name="oraInizioServizioSporadico" id="oraInizioServizioSporadico" onChange={e => onChangeSetDatiServizioSporadico(e)} />
                                                            </div>
                                                        </div>
                                                        <div className="div_wrap_50">
                                                            <div className="col_input"><label htmlFor="name-11">Orario fine</label>
                                                                <input className="input w-input" type="time" value={oraFineServizioSporadico} name="oraFineServizioSporadico" id="oraFineServizioSporadico" onChange={e => onChangeSetDatiServizioSporadico(e)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="div_wrap_100">
                                                        <div className="div_wrap_50">
                                                            <div className="col_input"><label htmlFor="name-11">Multiplo</label>
                                                                <input className="input w-input" type="number" value={multiplo} name="multiplo" id="multiplo" onChange={e => onChangeSetDatiServizioSporadico(e)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="item_3 m_top_5">
                                                <div className="list_button">
                                                    <div id="w-node-_571e9ca7-8650-59ca-aaad-79ac7369ac51-867f153d" className="div_item_button">
                                                        <a id="w-node-_571e9ca7-8650-59ca-aaad-79ac7369ac52-867f153d" onClick={(e) => { setServizioSporadicoOpen(false) }} className={`button w-button red`}>Annulla</a>
                                                    </div>
                                                    <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc40-867f153d" className="div_item_empty"></div>
                                                    <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc40-867f153d" className="div_item_empty"></div>
                                                    <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc40-867f153d" className="div_item_empty"></div>
                                                    <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc40-867f153d" className="div_item_empty"></div>
                                                    <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc40-867f153d" className="div_item_empty"></div>
                                                    <div id="w-node-_8af3fff2-d158-bb07-f4c8-6e208fda3793-867f153d" className="div_item_button">
                                                        <a id="w-node-_571e9ca7-8650-59ca-aaad-79ac7369ac52-867f153d" onClick={(e) => { onClickAggiungiServizioSporadico() }} className={`button w-button`}>Salva</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        ''
                    }
                </div>

                <div className="div_wrap_100">
                    <div className="div_wrap_50">
                        <div className="div_container">
                            <div className="title_window">
                                <div className="title_window_p">Gestione assenze</div>
                            </div>
                            <div className="dati_container">
                                <div className="fun_assegnazione_incarichi">
                                    <div className="row">
                                        <div className="item_2">
                                            <div className="intestazione_table">
                                                <div className="w-layout-grid div_column_gestione_assenze intestazione">
                                                    <div className="div_item_column">
                                                        <div className="item_table">
                                                            <input type="checkbox" id="checkboxSelezionaTutto" name="checkboxSelezionaTutto" data-name="checkboxSelezionaTutto" className="w-checkbox-input checkbox_selection" value={checkboxSelezionaAssenze} onChange={e => onChangesetCheckBoxAssenze({ name: "tuttiContratti", id: null, value: e.target.value })} />
                                                        </div>
                                                    </div>
                                                    <div className="div_item_column">
                                                        <div className="item_table">Data inizio</div>
                                                    </div>
                                                    <div className="div_item_column">
                                                        <div className="item_table">Data fine</div>
                                                    </div>
                                                    <div className="div_item_column">
                                                        <div className="item_table">Dipendente</div>
                                                    </div>
                                                    <div className="div_item_column">
                                                        <div className="item_table">Tipologia</div>
                                                    </div>
                                                    <div className="div_item_column">
                                                        <div className="item_table">Confermata</div>
                                                    </div>
                                                    <div className="div_item_column">
                                                        <div className="item_table">N. protocollo</div>
                                                    </div>
                                                </div>
                                            </div>
                                            {loadingContrattiRichieste ? <Spinner /> :
                                                <div className="body_table">
                                                    {giorniEccezioneData.map((giorno, i) => (
                                                        <div key={"contratto" + i} className={`w-layout-grid div_column_gestione_assenze cursor ${rowSelectedGiornoEccezione.id == giorno._id ? 'giorno_selected' : ''}`}>
                                                            {giorno.autocreate ?
                                                                <div className="div_item_column"></div>
                                                                :
                                                                <div className="div_item_column">
                                                                    <div className="item_table"><input type="checkbox" id={`checkboxSeleziona${i}`} name={`checkboxSeleziona${i}`} data-name={`checkboxSeleziona${i}`} value={checkboxSelezionaAssenze} className="w-checkbox-input checkbox_selection" checked={checkBoxAssenze.findIndex(el => el == giorno._id) >= 0 ? true : false} onChange={(e) => onChangesetCheckBoxAssenze({ name: "contrattoSingolo", id: giorno._id, value: e.target.value })} /></div>
                                                                </div>
                                                            }
                                                            <div className="div_item_column" onClick={() => onClickSetRowSelectedGiornoEccezione({ id: giorno._id, dipendente: "" + giorno.dipendente.cognome + " " + giorno.dipendente.nome, dataInizio: giorno.dataInizio, dataFine: giorno.dataFine, disponibilita: giorno.disponibilita, nProtocollo: giorno.nProtocollo })}>
                                                                <div className="item_table"><Moment format='DD/MM/YYYY'>{giorno.dataInizio}</Moment></div>
                                                            </div>
                                                            <div className="div_item_column" onClick={() => onClickSetRowSelectedGiornoEccezione({ id: giorno._id, dipendente: "" + giorno.dipendente.cognome + " " + giorno.dipendente.nome, dataInizio: giorno.dataInizio, dataFine: giorno.dataFine, disponibilita: giorno.disponibilita, nProtocollo: giorno.nProtocollo })}>
                                                                <div className="item_table"><Moment format='DD/MM/YYYY'>{giorno.dataFine}</Moment></div>
                                                            </div>
                                                            <div className="div_item_column" onClick={() => onClickSetRowSelectedGiornoEccezione({ id: giorno._id, dipendente: "" + giorno.dipendente.cognome + " " + giorno.dipendente.nome, dataInizio: giorno.dataInizio, dataFine: giorno.dataFine, disponibilita: giorno.disponibilita, nProtocollo: giorno.nProtocollo })}>
                                                                <div className="item_table align_left">{giorno.dipendente.cognome} {giorno.dipendente.nome}</div>
                                                            </div>
                                                            <div className="div_item_column" onClick={() => onClickSetRowSelectedGiornoEccezione({ id: giorno._id, dipendente: "" + giorno.dipendente.cognome + " " + giorno.dipendente.nome, dataInizio: giorno.dataInizio, dataFine: giorno.dataFine, disponibilita: giorno.disponibilita, nProtocollo: giorno.nProtocollo })}>
                                                                <div className="item_table">{giorno.disponibilita}</div>
                                                            </div>
                                                            <div className={`div_item_column ${giorno.confermata ? "green" : "red"}`} onClick={() => onClickSetRowSelectedGiornoEccezione({ id: giorno._id, dipendente: "" + giorno.dipendente.cognome + " " + giorno.dipendente.nome, dataInizio: giorno.dataInizio, dataFine: giorno.dataFine, disponibilita: giorno.disponibilita, nProtocollo: giorno.nProtocollo })}>
                                                                <div className="item_table">{giorno.confermata ? "Si" : "No"}</div>
                                                            </div>
                                                            <div className="div_item_column" onClick={() => onClickSetRowSelectedGiornoEccezione({ id: giorno._id, dipendente: "" + giorno.dipendente.cognome + " " + giorno.dipendente.nome, dataInizio: giorno.dataInizio, dataFine: giorno.dataFine, disponibilita: giorno.disponibilita, nProtocollo: giorno.nProtocollo })}>
                                                                <div className="item_table">{giorno.nProtocollo}</div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="item_3 m_top_5">
                                            <div className="list_button list_button_2">
                                                <div id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98d-867f153d" className="div_item_button">
                                                    <a id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98e-867f153d" className={`button w-button red ${rowSelectedGiornoEccezione.id.length == 0 ? "button_disabled" : ""}`} onClick={(e) => rowSelectedGiornoEccezione.id.length == 0 ? e.preventDefault() : onClickEliminaGiornoEccezione()}>Elimina</a>
                                                </div>
                                                <div id="w-node-bf561da2-25a7-b3ef-c44c-a819295560e3-867f153d" className="div_item_empty"></div>
                                                <div id="w-node-bf561da2-25a7-b3ef-c44c-a819295560e3-867f153d" className="div_item_empty"></div>
                                                <div id="w-node-bf561da2-25a7-b3ef-c44c-a819295560e3-867f153d" className="div_item_empty"></div>
                                                <div id="w-node-bf561da2-25a7-b3ef-c44c-a819295560e3-867f153d" className="div_item_empty"></div>
                                                <div id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98d-867f153d" className="div_item_button">
                                                    <a id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98e-867f153d" className={`button w-button green ${checkBoxAssenze.length == 0 ? "button_disabled" : ""}`} onClick={(e) => checkBoxAssenze.length == 0 ? e.preventDefault() : onClickConfermaGiornoEccezione()}>Conferma</a>
                                                </div>
                                                <div id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98d-867f153d" className="div_item_button">
                                                    <a id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98e-867f153d" className={`button w-button red ${checkBoxAssenze.length == 0 ? "button_disabled" : ""}`} onClick={(e) => checkBoxAssenze.length == 0 ? e.preventDefault() : onClickSconfermaGiornoEccezione()}>Sconferma</a>
                                                </div>
                                                <div id="w-node-bf561da2-25a7-b3ef-c44c-a819295560e3-867f153d" className="div_item_empty"></div>
                                                <div id="w-node-bf561da2-25a7-b3ef-c44c-a819295560e3-867f153d" className="div_item_empty"></div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                        <div className="div_wrap_50">
                            <div className="div_container">
                                <div className="title_window">
                                    <div className="title_window_p">Incarichi dipendente selezionato</div>
                                </div>
                                <div className="dati_container">
                                    <div className="fun_assegnazione_incarichi">
                                        <div className="row">
                                            <div className="item_2">
                                                <div className="intestazione_table">
                                                    <div className="w-layout-grid div_column_incarichi_dipendente intestazione">
                                                        <div className="div_item_column">
                                                            <div className="item_table">Data</div>
                                                        </div>
                                                        <div className="div_item_column">
                                                            <div className="item_table">Disp.</div>
                                                        </div>
                                                        <div className="div_item_column">
                                                            <div className="item_table">Eff.</div>
                                                        </div>
                                                        <div className="div_item_column">
                                                            <div className="item_table">Servizio</div>
                                                        </div>
                                                        <div className="div_item_column">
                                                            <div className="item_table">Dalle</div>
                                                        </div>
                                                        <div className="div_item_column">
                                                            <div className="item_table">Alle</div>
                                                        </div>
                                                        <div className="div_item_column">
                                                            <div className="item_table">Ore fatte</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {waitingTurniDipendente ? <Spinner /> : dipendenteSelected && incarichiDipendente && incarichiDipendente.incarichi && incarichiDipendente.incarichi.length >= 0 &&  dipendenteSelected.length > 0 ?
                                                    <div className="body_table">
                                                        {incarichiDipendente.incarichi.map((el, i) => (
                                                            <div key={"contratto" + i} className={`w-layout-grid div_column_incarichi_dipendente cursor ${el.stato == "archiviata" ? "green_label" : ''}`}>
                                                                <div className="div_item_column">
                                                                    <div className="item_table"><Moment format='DD/MM/YYYY'>{el.data}</Moment></div>
                                                                </div>
                                                                <div className="div_item_column" >
                                                                    <div className="item_table">{el.disponibilita}</div>
                                                                </div>
                                                                <div className="div_item_column">
                                                                    <div className="item_table">{el.effettivo}</div>
                                                                </div>
                                                                <div className="div_item_column">
                                                                    <div className="item_table">{el.servizio}{el.localita ? " - " + el.localita : ""}</div>
                                                                </div>
                                                                <div className="div_item_column">
                                                                    <div className="item_table">{el.oraInizio}</div>
                                                                </div>
                                                                <div className="div_item_column">
                                                                    <div className="item_table">{el.oraFine}</div>
                                                                </div>
                                                                <div className="div_item_column">
                                                                    <div className="item_table" >{el.ore}</div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    :
                                                    ''
                                                }
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
                <div className="div_wrap_100">
                    <div className="div_wrap_50">
                    <div className="page">
                        <div className="div_container">
                            <div className="title_window">
                                <div className="title_window_p">Dipendenti non assegnati</div>
                            </div>
                            <div className="dati_container">
                                <div className="fun_assegnazione_incarichi">
                                    <div className="row">
                                        <div className="item_2">
                                            <div className="intestazione_table">
                                                <div className="w-layout-grid div_column_dipendenti_liberi intestazione">
                                                    <div className="div_item_column">
                                                        <div className="item_table">Dis.</div>
                                                    </div>
                                                    <div className="div_item_column">
                                                        <div className="item_table">Qualifica</div>
                                                    </div>
                                                    <div className="div_item_column">
                                                        <div className="item_table">Cognome</div>
                                                    </div>
                                                    <div className="div_item_column">
                                                        <div className="item_table">Nome</div>
                                                    </div>
                                                    <div className="div_item_column">
                                                        <div className="item_table"></div>
                                                    </div>
                                                    <div className="div_item_column">
                                                        <div className="item_table"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            {loadingContrattiRichieste ? <Spinner /> :
                                                <div className="body_table">
                                                    {dipendentiLiberi.map((dip, i) => (
                                                        <div key={"dipendente-libero" + i} className={`w-layout-grid div_column_dipendenti_liberi cursor ${dipendenteNonAssegnatoSelected == dip._id ? 'dipendente_selected' : ""}`}>
                                                            {cambiaDisponibilitaNonAssegnatoSelectedOpen && dipendenteNonAssegnatoSelected == dip._id ?
                                                                    <div className="div_item_column" onClick={(e) => onClickSelectDipendenteNonAssegnato(dip._id)}>
                                                                        <select className="form-control select" name="idPuntoVendita" onChange={e => onChangeSetDisponibilitaNonAssegnati(e)} required>
                                                                            {disponibilitaJSON.disponibilita.map((el) => (
                                                                                <option selected={el.codice == dip.disponibilita} value={el.codice}>{el.codice} - {el.nome}</option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                    :
                                                                    <div className="div_item_column" onClick={(e) => onClickSelectDipendenteNonAssegnato(dip._id)}>
                                                                        <div className="item_table">{dip.disponibilita}</div>
                                                                    </div>
                                                                }
                                                            <div className="div_item_column" onClick={(e) => onClickSelectDipendenteNonAssegnato(dip._id)}>
                                                                <div className="item_table">{dip.qualifica}</div>
                                                            </div>
                                                            <div className="div_item_column" onClick={(e) => onClickSelectDipendenteNonAssegnato(dip._id)}>
                                                                <div className="item_table align_left">{dip.cognome}</div>
                                                            </div>
                                                            <div className="div_item_column" onClick={(e) => onClickSelectDipendenteNonAssegnato(dip._id)}>
                                                                <div className="item_table align_left">{dip.nome}</div>
                                                            </div>
                                                            <div className="div_item_column" onClick={(e) => onClickSelectDipendenteNonAssegnato(dip._id)}>
                                                                <div className="item_table"></div>
                                                            </div>
                                                            <div className="div_item_column" onClick={(e) => onClickSelectDipendenteNonAssegnato(dip._id)}>
                                                                <div className="item_table"></div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            }
                                            <div className="row hide-element">
                                            <div className="item_4 m_top_5">
                                                <div className="item_4_function">
                                                <div id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98d-867f153d" className="div_item_button">
                                                    <a id="w-node-c4b74f58-890f-de16-94ea-fe95f11de98e-867f153d" className={`button w-button ${dipendentiLiberi.length == 0 ? "button_disabled" : ""}`} onClick={() => print()}>Stampa</a>
                                                 </div>
                                                    {dipendenteNonAssegnatoSelected && !cambiaDisponibilitaNonAssegnatoSelectedOpen ?
                                                        <>
                                                        <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc43-867f153d" className="div_item_button">
                                                            <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc40-867f153d" className="div_item_empty"></div>
                                                        </div>
                                                        <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc43-867f153d" className="div_item_button">
                                                            <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc40-867f153d" className="div_item_empty"></div>
                                                        </div>
                                                        <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc43-867f153d" className="div_item_button">
                                                            <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc40-867f153d" className="div_item_empty"></div>
                                                        </div>
                                                        <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc43-867f153d" className="div_item_button">
                                                            <a id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc44-867f153d" onClick={() => setCambiaDisponibilitaNonAssegnatoSelectedOpen(true)} className="button w-button">Modifica disponibilità</a>
                                                        </div>
                                                        </>
                                                        : dipendenteNonAssegnatoSelected && cambiaDisponibilitaNonAssegnatoSelectedOpen ?
                                                            <>
                                                            <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc43-867f153d" className="div_item_button">
                                                                <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc40-867f153d" className="div_item_empty"></div>
                                                            </div>
                                                            <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc43-867f153d" className="div_item_button">
                                                                <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc40-867f153d" className="div_item_empty"></div>
                                                            </div>
                                                            <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc43-867f153d" className="div_item_button">
                                                                <a id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc44-867f153d" onClick={() => onClickSalvaDisponibilitaNonAssegnati()} className="button w-button">Salva</a>
                                                            </div>
                                                            <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc43-867f153d" className="div_item_button">
                                                                <a id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc44-867f153d" onClick={() => setCambiaDisponibilitaNonAssegnatoSelectedOpen(false)} className="button w-button">Chiudi</a>
                                                             </div>
                                                            </>
                                                            :
                                                            <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc40-867f153d" className="div_item_empty"></div>
                                                    }
                                                </div>
                                            </div>
                                        </div> 
                                        <div>
                                        <div className="div_container font-bold hide" ref={componentRef} >
                                            <div className="dati_container">
                                            <div className="page">
                                                    <h3>{toIsoString(dataInput)}</h3>
                                                    <h5>Dipendenti non assegnati</h5>
                                                    <table style={tableStyle}>
                                                        <thead>
                                                            <tr style={tableInt1}>
                                                                <th style={tableStyle1}>Qualifica</th>
                                                                <th style={tableStyle1}>Dipendente</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                {dipendentiLiberi.map((elemento, i) => (
                                                    <>
                                                        <tr style={tableStyle2}>
                                                            <td style={tableStyle3}>{elemento.qualifica}</td>
                                                            <td style={tableStyle3}>{elemento.cognome} {elemento.nome}</td>
                                                        </tr>
                                                    </>
                                                ))}
                                                    </tbody>
                                                </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    {openListaDipendenti ?
                        <div className="div_wrap_50">
                            <div className="div_container">
                                <div className="title_window">
                                    <div className="title_window_p">Dipendenti prestabili</div>
                                </div>
                                <div className="dati_container">
                                    <div className="fun_assegnazione_incarichi">
                                        <div className="row">
                                            <div className="item_2">
                                                <div className="intestazione_table">
                                                    <div className="w-layout-grid div_column_assegnazioni_dipendenti_prestati intestazione">
                                                        <div className="div_item_column">
                                                            <div className="item_table">Qual.</div>
                                                        </div>
                                                        <div className="div_item_column">
                                                            <div className="item_table">Sus.</div>
                                                        </div>
                                                        <div className="div_item_column">
                                                            <div className="item_table">Filiale</div>
                                                        </div>
                                                        <div className="div_item_column">
                                                            <div className="item_table">Dipendente</div>
                                                        </div>
                                                        <div className="div_item_column">
                                                            <div className="item_table">Res.Ord</div>
                                                        </div>
                                                        <div className="div_item_column">
                                                            <div className="item_table">Res.Str</div>
                                                        </div>
                                                        <div className="div_item_column">
                                                            <div className="item_table">Ass.Ord</div>
                                                        </div>
                                                        <div className="div_item_column">
                                                            <div className="item_table">Ass.Str</div>
                                                        </div>
                                                        <div className="div_item_column">
                                                            <div className="item_table">Dis</div>
                                                        </div>
                                                        <div className="div_item_column">
                                                            <div className="item_table">B.Ore</div>
                                                        </div>
                                                        <div className="div_item_column">
                                                            <div className="item_table">Ferie</div>
                                                        </div>
                                                        <div className="div_item_column">
                                                            <div className="item_table">Perm</div>
                                                        </div>
                                                        <div className="div_item_column">
                                                            <div className="item_table">Ore mese</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {rowSelected.id.length > 0 && waitingJob ? <Spinner /> :
                                                    <div className="body_table_2">
                                                        {dipendentiPrestati.map((dipendente, i) => (
                                                            <div key={"dipendente" + i} className={`w-layout-grid div_column_assegnazioni_dipendenti_prestati cursor ${dipendenteSelected == dipendente._id ? 'dipendente_selected' : dipendente.calcoli.occupato ? "red_label" : ""}`}>
                                                                <div className="div_item_column" onClick={(e) => onClickSelectDipendente(e, dipendente._id, true)}>
                                                                    <div className="item_table">{dipendente.qualifica}</div>
                                                                </div>
                                                                <div className="div_item_column" onClick={(e) => onClickSelectDipendente(e, dipendente._id, true)}>
                                                                    <div className="item_table">{dipendente.sussidiari ? dipendente.nomeSussidiari ? dipendente.nomeSussidiari.slice(0, 3) : '' : ''}</div>
                                                                </div>
                                                                <div className="div_item_column" onClick={(e) => onClickSelectDipendente(e, dipendente._id, true)}>
                                                                    <div className="item_table">{dipendente && dipendente.filiale && dipendente.filiale.nome}</div>
                                                                </div>
                                                                <div className="div_item_column" onClick={(e) => onClickSelectDipendente(e, dipendente._id, true)}>
                                                                    <div className="item_table align_left">{dipendente && dipendente.cognome} {dipendente && dipendente.nome}</div>
                                                                </div>
                                                                <div className="div_item_column" onClick={(e) => onClickSelectDipendente(e, dipendente._id, true)}>
                                                                    <div className="item_table">{dipendente.calcoli.resOrd}</div>
                                                                </div>
                                                                <div className="div_item_column" onClick={(e) => onClickSelectDipendente(e, dipendente._id, true)}>
                                                                    <div className="item_table">{dipendente.calcoli.resStr}</div>
                                                                </div>
                                                                <div className="div_item_column" onClick={(e) => onClickSelectDipendente(e, dipendente._id, true)}>
                                                                    <div className="item_table">{dipendente.calcoli.assOrd}</div>
                                                                </div>
                                                                <div className="div_item_column" onClick={(e) => onClickSelectDipendente(e, dipendente._id, true)}>
                                                                    <div className="item_table">{dipendente.calcoli.assStr}</div>
                                                                </div>
                                                                {cambiaDisponibilitaOpen && dipendenteSelected == dipendente._id ?
                                                                    <div className="div_item_column">
                                                                        <select className="form-control select" name="idPuntoVendita" onChange={e => onChangeSetDisponibilita(e)} required>
                                                                            {disponibilitaJSON.disponibilita.map((el) => (
                                                                                <option selected={el.codice == disponibilitaSelected} value={el.codice}>{el.codice} - {el.nome}</option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                    :
                                                                    <div className="div_item_column" onClick={(e) => onClickSelectDipendente(e, dipendente._id, true)}>
                                                                        <div className="item_table">{dipendente.calcoli.disponibilita}</div>
                                                                    </div>
                                                                }
                                                                <div className="div_item_column" onClick={(e) => onClickSelectDipendente(e, dipendente._id, true)}>
                                                                    <div className="item_table">{dipendente.bancaOreProgressivo && dipendente.bancaOreProgressivo}</div>
                                                                </div>
                                                                <div className="div_item_column" onClick={(e) => onClickSelectDipendente(e, dipendente._id, true)}>
                                                                    <div className="item_table">{dipendente.calcoli.ferie}</div>
                                                                </div>
                                                                <div className="div_item_column" onClick={(e) => onClickSelectDipendente(e, dipendente._id, true)}>
                                                                    <div className="item_table">{dipendente.calcoli.permessi}</div>
                                                                </div>
                                                                <div className="div_item_column" onClick={(e) => onClickSelectDipendente(e, dipendente._id, true)}>
                                                                    <div className="item_table">{dipendente.calcoli.oreMensili}</div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="item_4 m_top_5">
                                                <div className="item_4_function">
                                                    <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc3d-867f153d" className="div_item_button">
                                                        <div id="w-node-_7ae3fa04-88bc-8c3b-7fdb-28ba347410f1-867f153d" className="form-block w-form">

                                                        </div>
                                                    </div>
                                                    <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc40-867f153d" className="div_item_empty"></div>
                                                    <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc40-867f153d" className="div_item_empty"></div>
                                                    <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc40-867f153d" className="div_item_empty"></div>
                                                    {dipendenteSelected && !cambiaDisponibilitaOpen && rowSelected.stato != 'nascosta' && rowSelected.stato != 'archiviata' ?
                                                        <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc43-867f153d" className="div_item_button">
                                                            <a id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc44-867f153d" onClick={() => onClickAssociaRichiestaPrestatoWithCheck(dipendenteSelected)} className="button w-button">Associa</a>
                                                        </div>
                                                        : dipendenteSelected && cambiaDisponibilitaOpen ?
                                                            <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc43-867f153d" className="div_item_button">
                                                                <a id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc44-867f153d" onClick={() => setCambiaDisponibilitaOpen(false)} className="button w-button">Annulla modifica</a>
                                                            </div>
                                                            :
                                                            <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc40-867f153d" className="div_item_empty"></div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        ''
                    }
                </div>
                {openCalendario ?
                    <Calendario
                        calendarioRichieste={calendarioRichieste}
                        calendarioLoading={calendarioLoading}
                        setOpenCalendario={setOpenCalendario}
                        openCalendario={openCalendario}
                        elementoDuplicato={elementoDuplicato}
                        dataInput={dataInput}
                        elementoEliminato={elementoEliminato}
                        elementoModificato={elementoModificato}
                    />
                    :
                    ''
                }
            </div>
        </div>
    )
}

Richieste.protoTypes = {
    ripristinaTuttiTurni: PropTypes.func.isRequired,
    getContrattiRichiestePuntiVendita: PropTypes.func.isRequired,
    getDipendentiContrattoJob: PropTypes.func.isRequired,
    cleanDipendenti: PropTypes.func.isRequired,
    associaDipendenteRichiesta: PropTypes.func.isRequired,
    dissociaDipendenteRichiesta: PropTypes.func.isRequired,
    getStateDipendentiContrattoJob: PropTypes.func.isRequired,
    archiviaRichieste: PropTypes.func.isRequired,
    aggiungiGiornoEccezione: PropTypes.func.isRequired,
    cleanCalendario: PropTypes.func.isRequired,
    getCalendarioContratti: PropTypes.func.isRequired,
    modificaOrarioRichiesta: PropTypes.func.isRequired,
    cleanRichieste: PropTypes.func.isRequired,
    cleanRichiesteArchiviate: PropTypes.func.isRequired,
    cleanGiorniEccezione: PropTypes.func.isRequired,
    nascondiContratto: PropTypes.func.isRequired,
    ripristinaContrattoNascosto: PropTypes.func.isRequired,
    annullaArchiviaRichieste: PropTypes.func.isRequired,
    eliminaGiornoEccezioneLungo: PropTypes.func.isRequired,
    eliminaGiornoEccezioneLungoRichieste: PropTypes.func.isRequired,
    aggiungiGiornoEccezioneLungo: PropTypes.func.isRequired,
    togliFerieAggiungiGiornoEccezione: PropTypes.func.isRequired,
    getGiorniEccezione: PropTypes.func.isRequired,
    aggiungiServizioSporadico: PropTypes.func.isRequired,
    getIncarichiDipendente: PropTypes.func.isRequired,
    cleanIncarichiDipendente: PropTypes.func.isRequired,
    getPuntiVendita: PropTypes.func.isRequired,
    getContrattiPuntoVendita: PropTypes.func.isRequired,
    eliminaRichiestaAnomala: PropTypes.func.isRequired,
    confermaGiorniEccezione: PropTypes.func.isRequired,
    getDipendentiPrestabiliOccupati: PropTypes.func.isRequired,
    getFiliali: PropTypes.func.isRequired,
    filiale: PropTypes.object.isRequired,
    richiesta: PropTypes.object.isRequired,
    puntoVendita: PropTypes.object.isRequired,
    dipendente: PropTypes.object.isRequired,
    alert: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    richiesta: state.richiesta,
    puntoVendita: state.puntoVendita,
    cliente: state.cliente,
    dipendente: state.dipendente,
    alert: state.alert,
    auth: state.auth,
    filiale: state.filiale
});

export default connect(mapStateToProps, { ripristinaTuttiTurni, getContrattiRichiestePuntiVendita, getDipendentiContrattoJob, cleanDipendenti, associaDipendenteRichiesta, dissociaDipendenteRichiesta, getStateDipendentiContrattoJob, archiviaRichieste, aggiungiGiornoEccezione, cleanCalendario, getCalendarioContratti, modificaOrarioRichiesta, cleanRichieste, cleanRichiesteArchiviate, cleanGiorniEccezione, nascondiContratto, ripristinaContrattoNascosto, annullaArchiviaRichieste, eliminaGiornoEccezioneLungo, aggiungiGiornoEccezioneLungo, getGiorniEccezione, aggiungiServizioSporadico, getIncarichiDipendente, cleanIncarichiDipendente, getPuntiVendita, getContrattiPuntoVendita, eliminaRichiestaAnomala, confermaGiorniEccezione, getDipendentiPrestabiliOccupati, eliminaGiornoEccezioneLungoRichieste, togliFerieAggiungiGiornoEccezione, getFiliali })(Richieste);