import { GET_ORGANIZZAZIONI, GET_ORGANIZZAZIONE, CREA_ORGANIZZAZIONE, MODIFICA_ORGANIZZAZIONE, ORGANIZZAZIONE_ERROR } from "../actions/types";

const initialState = {
    organizzazione: null,
    organizzazioni: [],
    numberOrganizzazioni: 0,
    error: {},
    loading: true
}

export default function(state = initialState, action){
    const { type, payload } = action;

    switch(type){
        case GET_ORGANIZZAZIONI:
            return {
                ...state,
                organizzazioni: payload.organizzazioni,
                numberOrganizzazioni: payload.numberOrganizzazioni,
                loading: false
            };
        case GET_ORGANIZZAZIONE:
            return {
                ...state,
                organizzazione: payload,
                loading: false
            };
        case CREA_ORGANIZZAZIONE:
            let arrayCreaOrganizzazione = state.organizzazioni;
            arrayCreaOrganizzazione.push(payload);
            arrayCreaOrganizzazione.sort((a,b) => (a.nome > b.nome) ? 1 : ((b.nome > a.nome) ? -1 : 0)); 
            return {
                ...state,
                organizzazioni: arrayCreaOrganizzazione,
                numberOrganizzazioni: arrayCreaOrganizzazione.length,
                loading: false
            };
        case MODIFICA_ORGANIZZAZIONE:
            const index = state.organizzazioni.findIndex(x => x._id == payload._id );
            let arrayModificaOrganizzazione = state.organizzazioni;
            arrayModificaOrganizzazione[index] = payload
            arrayModificaOrganizzazione.sort((a,b) => (a.nome > b.nome) ? 1 : ((b.nome > a.nome) ? -1 : 0)); 
            return {
                ...state,
                organizzazioni: arrayModificaOrganizzazione,
                loading: false
            };
        case ORGANIZZAZIONE_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            };
        default:
            return state;
    }
}