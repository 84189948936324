import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cestinoIMG from "../../../img/trash.png"
import DatePicker from 'react-date-picker';



const DocumentoTiroASegno = ({ id, nome, dataScadenza1, eseguito1, dataScadenza2, eseguito2, dataScadenza3, eseguito3, onChangeSetTiroASegno, eliminaDocumentoTiroASegno, bloccaAvviso, onChangeSetCheckboxTiroASegno }) => {

    return (
        <div className='mb-30'>
            <div className="div_wrap_100">
                <div className="div_wrap_25">
                    <div className="col_input">
                        <label htmlFor="nome-tiro-a-segno">Nome</label>
                        <input type="text" className="input w-input" name={nome} data-name={nome} value={nome} onChange={e => onChangeSetTiroASegno('nome', e.target.value, id)} required />
                    </div>
                </div>
                <div className="div_wrap_25">
                    <div className="col_input"><label htmlFor="name-11">Data scadenza 1</label>
                        <div data-hover="false" data-delay="0" className="select w-dropdown select_data">
                            <DatePicker
                                onChange={(value) => onChangeSetTiroASegno('dataScadenza1', value, id)}
                                value={dataScadenza1}
                                clearIcon={null}
                                className={'form-control'}
                                disableCalendar={false}
                                disabled={eseguito1}
                            />
                        </div>
                    </div>
                </div>
                <div className="div_wrap_25">
                    <div className="col_input">
                        <label htmlFor="partTime">Eseguito</label>
                        <label className="w-checkbox checkbox-field m_right_10">
                            <input type="checkbox" id={"eseguito1"} className="w-checkbox-input" name="eseguito1" checked={eseguito1} value={eseguito1} onChange={e => onChangeSetTiroASegno('eseguito1', e.target.value, id)} disabled={eseguito1}/>
                            <span className="label_text w-form-label" htmlFor="checkbox-3">Si</span>
                        </label>
                    </div>
                </div>
            </div>
            {eseguito1 ?
            <div className="div_wrap_100">
                <div className="div_wrap_25">
                    <div className="col_input">
                        <span></span>
                    </div>
                </div>
                <div className="div_wrap_25">
                    <div className="col_input"><label htmlFor="name-11">Data scadenza 2</label>
                        <div data-hover="false" data-delay="0" className="select w-dropdown select_data">
                            <DatePicker
                                onChange={(value) => onChangeSetTiroASegno('dataScadenza2', value, id)}
                                value={dataScadenza2}
                                clearIcon={null}
                                className={'form-control'}
                                disableCalendar={false}
                                disabled={eseguito2}
                            />
                        </div>
                    </div>
                </div>
                <div className="div_wrap_25">
                    <div className="col_input">
                        <label htmlFor="partTime">Eseguito</label>
                        <label className="w-checkbox checkbox-field m_right_10">
                            <input type="checkbox" id={"partTime"} className="w-checkbox-input" name="partTime" checked={eseguito2} value={eseguito2} onChange={e => onChangeSetTiroASegno('eseguito2', e.target.value, id)} />
                            <span className="label_text w-form-label" htmlFor="checkbox-3">Si</span>
                        </label>
                    </div>
                </div>
            </div>
                : 
            ''
            }
            {eseguito2 ?
            <div className="div_wrap_100">
                <div className="div_wrap_25">
                    <div className="col_input">
                        <span></span>
                    </div>
                </div>
                <div className="div_wrap_25">
                    <div className="col_input"><label htmlFor="name-11">Data scadenza 3</label>
                        <div data-hover="false" data-delay="0" className="select w-dropdown select_data">
                            <DatePicker
                                onChange={(value) => onChangeSetTiroASegno('dataScadenza3', value, id)}
                                value={dataScadenza3}
                                clearIcon={null}
                                className={'form-control'}
                                disableCalendar={false}
                                disabled={eseguito3}
                            />
                        </div>
                    </div>
                </div>
                <div className="div_wrap_25">
                    <div className="col_input">
                        <label htmlFor="partTime">Eseguito</label>
                        <label className="w-checkbox checkbox-field m_right_10">
                            <input type="checkbox" id={"partTime"} className="w-checkbox-input" name="partTime" checked={eseguito3} value={eseguito3} onChange={e => onChangeSetTiroASegno('eseguito3', e.target.value, id)} />
                            <span className="label_text w-form-label" htmlFor="checkbox-3">Si</span>
                        </label>
                    </div>
                </div>
            </div>
                : 
                ''
            }
            <div className='div_wrap_100'>
            <div className="div_wrap_25">
                <div className="col_input">
                    <label htmlFor="name">Blocca avviso</label>
                    <label className="w-checkbox checkbox-field m_right_10">
                    <input type="checkbox" id={"bloccaAvviso"} className="w-checkbox-input" name="bloccaAvviso" checked={bloccaAvviso} value={bloccaAvviso} onChange={e => onChangeSetCheckboxTiroASegno(e.target.value, id)} />
                    <span className="label_text w-form-label" htmlFor="checkbox-3">Si {bloccaAvviso}</span>
                    </label>
                </div>
            </div>
                <div className="div_wrap_25">
                    <span></span>
                </div>
                <div className="div_wrap_25">
                    <span></span>
                </div>
                <div className="div_wrap_25">
                    <div className="col_input">
                        {true ?
                        <div id="w-node-d409c487-9a0c-6b1f-803c-c7c11a8afa3e-b8dac9c0" onClick={() => eliminaDocumentoTiroASegno(id)} class="div_item_button"><a class="button w-button">elimina</a></div>
                        :
                        ''
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
DocumentoTiroASegno.protoTypes = {
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, {})(DocumentoTiroASegno);

