import axios from 'axios';
import { GET_CLIENTE, GET_CLIENTI, CREA_CLIENTE, MODIFICA_CLIENTE, CLIENTE_ERROR, CLEAR_CLIENTE } from './types';
import { setAlert } from './alert';


export const getClienti = () => async dispatch => {
    try{
        const res = await axios.get('/api/cliente/clienti');
        dispatch({
            type: GET_CLIENTI,
            payload: res.data
        });
    }catch(err){
        dispatch({
            type: CLIENTE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const getCliente = (idCliente) => async dispatch => {
    try{
        const res = await axios.get(`/api/cliente/cliente/${idCliente}`);
        dispatch({
            type: GET_CLIENTE,
            payload: res.data
        });
    }catch(err){
        dispatch({
            type: CLIENTE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const creaNuovoCliente = ({ codice, ragioneSoc, indirizzo, cap, localita, provincia, telefono, fax, piva, idAttivita, fatturazione }) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ codice, ragioneSoc, indirizzo, cap, localita, provincia, telefono, fax, piva, idAttivita, fatturazione });
    try{
        const res = await axios.post('/api/cliente/nuovoCliente', body, config);
        dispatch({
            type: CREA_CLIENTE,
            payload: res.data
        });
        dispatch(setAlert('Nuovo Cliente creato!', 'success'));

    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        
        dispatch({
            type: CLIENTE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};


export const modificaCliente = ({idCliente, codice, ragioneSoc, indirizzo, cap, localita, provincia, telefono, fax, piva, idAttivita, fatturazione}) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ idCliente, codice, ragioneSoc, indirizzo, cap, localita, provincia, telefono, fax, piva, idAttivita, fatturazione }); 
    try{
        const res = await axios.post('/api/cliente/modificaCliente', body, config);
        dispatch(setAlert('Cliente modificato con successo!', 'success'));
        dispatch({
            type: MODIFICA_CLIENTE,
            payload: res.data
        });
    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        dispatch({
            type: CLIENTE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const clearCliente = () => async dispatch => {
    try {
        dispatch({
            type: CLEAR_CLIENTE
        });
    } catch (err) {
        dispatch({
            type: CLIENTE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        });
    }
};