import { INVIA_TURNI, GET_ESITI_INVIO_TURNI, INVIO_TURNI_ERROR } from "../actions/types";

const initialState = {
    esitiInvioTurni: [],
    error: {},
    loading: true
}

export default function(state = initialState, action){
    const { type, payload } = action;

    switch(type){
        case GET_ESITI_INVIO_TURNI:
            return {
                ...state,
                esitiInvioTurni: payload,
                loading: false
            };
        case INVIA_TURNI:
            let arrayEsitoInvii = [...state.esitiInvioTurni];
            arrayEsitoInvii.push(payload)
            return {
                ...state,
                esitiInvioTurni: arrayEsitoInvii,
                loading: false
            };
        case INVIO_TURNI_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            };
        default:
            return state;
    }
}