import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {Helmet} from "react-helmet";
import Spinner from '../../layout/Spinner';
import ModificaUtenteEsterno from './ModificaUtenteEsterno';
import NuovoUtenteEsterno from './NuovoUtenteEsterno';
import { getUtentiEsterni } from '../../../actions/utenteEsterno';
import { getCategorieDocumentiUtentiEsterni, creaCategoriaDocumentoUtenteEsterno } from '../../../actions/impostazione';
const UtentiEsterni = ({ utenteEsterno: { utentiEsterni, loading }, alert, getUtentiEsterni, creaCategoriaDocumentoUtenteEsterno, getCategorieDocumentiUtentiEsterni, impostazione: {categorieDocumentiUtenteEsterno} }) => {


    const [openModificaUtenteEsterno, setOpenModificaUtenteEsterno] = useState(false);
    const [openCreaCategoriaDocumento, setOpenCreaCategoriaDocumento] = useState(false);
    const [openNuovoUtenteEsterno, setOpenNuovoUtenteEsterno] = useState(false);
    const [utenteSelected, setUtenteSelected] = useState({ _id: null });
    const [testoFiltraUtentiEsterni, setTestoFiltraUtentiEsterni] = useState("");
    const [utentiList, setUtentiList] = useState([]);
    const [testoNuovaCategoria, setTestoNuovaCategoria] = useState("");

    console.log(categorieDocumentiUtenteEsterno)

    useEffect(() => {
        getUtentiEsterni();
        getCategorieDocumentiUtentiEsterni();
    }, [getUtentiEsterni]);

    useEffect(() => {
        const size = alert.length;
        if (size > 0) {
            if (alert[size - 1].alertType === "success") {
                setTestoNuovaCategoria("");
            }
        }
    }, [alert]);


    useEffect(() => {
        let arrayUtentiEsterni = [...utentiEsterni]
        if (testoFiltraUtentiEsterni.length > 0) {
            arrayUtentiEsterni = arrayUtentiEsterni.filter(function (utente) {
                return utente.nome.toLowerCase().startsWith(testoFiltraUtentiEsterni.toLowerCase())
                    || utente.cognome.toLowerCase().startsWith(testoFiltraUtentiEsterni.toLowerCase());
            })
        }
        setUtentiList(arrayUtentiEsterni)
    }, [utentiEsterni]);


    const onClickOpenModificaUtenteEsterno = (idDipendente) => {
        if (idDipendente) {
            setOpenModificaUtenteEsterno(true)
            setOpenNuovoUtenteEsterno(false)
            setOpenCreaCategoriaDocumento(false)
        }
    }

    const onClickOpenCreaCategoriaDocumento = () => {
        setOpenModificaUtenteEsterno(false)
        setOpenNuovoUtenteEsterno(false)
        setOpenCreaCategoriaDocumento(true)
    }

    const onClickOpenNuovoUtenteEsterno = () => {
        setOpenModificaUtenteEsterno(false)
        setOpenNuovoUtenteEsterno(true)
        setOpenCreaCategoriaDocumento(false)
    }

    const onChangeFiltraUtentiEsterni = e => {
        setTestoFiltraUtentiEsterni(e.target.value)
        let parola = e.target.value.toLowerCase()
        let arrayUtentiEsterni = [];
        arrayUtentiEsterni = utentiEsterni.filter(function (utente) {
            return utente.nome.toLowerCase().startsWith(parola)
                || utente.cognome.toLowerCase().startsWith(parola);
        })
        setUtentiList(arrayUtentiEsterni)
    };

    const onChangeTestoCategoria= e => {
        setTestoNuovaCategoria(e.target.value)
    };

    const onSubmitCreaCategoria = async e => {
        e.preventDefault();
        creaCategoriaDocumentoUtenteEsterno({nome: testoNuovaCategoria});
    };

   
    return (loading ? <Spinner /> :
        <div className="body wf-section">
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`Utenti esterni - Gestionale Vigili dell'ordine`}</title>
            </Helmet>
            <div className="div_wrap">
                <div className="div_wrap_100">
                    <div className="div_wrap_50">
                        <div className="div_container">
                            <div className="title_window">
                                <div className="title_window_p">Utenti esterni</div>
                            </div>
                            <div className="dati_container">
                                <div className="fun_assegnazione_incarichi">
                                    <div className="row">
                                        <div className="item_1 filtro_wrap">
                                            <div id="w-node-_7b40e076-1929-a489-3ae4-e7e497d010f9-867f153d" className="div_item_1">
                                                <div id="w-node-_7b40e076-1929-a489-3ae4-e7e497d010fa-867f153d" className="label_text">Filtra utenti</div>
                                                <div data-hover="false" data-delay="0" className="w-dropdown">
                                                    <input type="text" className="w-input select" maxLength="256" name="filtraUtenteEsterno" data-name="filtraUtenteEsterno" value={testoFiltraUtentiEsterni} placeholder="Inserisci utente" id="filtraUtenteEsterno" onChange={e => onChangeFiltraUtentiEsterni(e)} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="item_2">
                                            <div className="intestazione_table">
                                                <div className="w-layout-grid div_column_dipendenti intestazione">
                                                    <div className="div_item_column">
                                                        <div className="item_table">Tipologia</div>
                                                    </div>
                                                    <div className="div_item_column">
                                                        <div className="item_table">Username</div>
                                                    </div>
                                                    <div className="div_item_column">
                                                        <div className="item_table">Nome</div>
                                                    </div>
                                                    <div className="div_item_column">
                                                        <div className="item_table">Cognome</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="body_table">
                                                {utentiList.map((utente, i) => (
                                                    <div key={i} className={`w-layout-grid div_column_dipendenti cursor ${utenteSelected._id == utente._id ? 'dipendente_selected' : ''}`} onClick={() => setUtenteSelected({ _id: utente._id })}>
                                                        <div className="div_item_column">
                                                            <div className="item_table">esterno</div>
                                                        </div>
                                                        <div className="div_item_column">
                                                            <div className="item_table">{utente.username}</div>
                                                        </div>
                                                        <div className="div_item_column">
                                                            <div className="item_table">{utente.nome}</div>
                                                        </div>
                                                        <div className="div_item_column">
                                                            <div className="item_table">{utente.cognome}</div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="item_3 m_top_5">
                                            <div className="list_button">
                                                <div id="w-node-_47761b2d-160d-03bc-cd09-8911d6401437-867f153d" className="div_item_button">
                                                    <a id="w-node-_12c52e6d-210d-5526-7356-6c64cce43733-867f153d" onClick={() => onClickOpenNuovoUtenteEsterno()} className={`button w-button`}>Nuovo</a>
                                                </div>
                                                <div id="w-node-_47761b2d-160d-03bc-cd09-8911d6401437-867f153d" className="div_item_button">
                                                    <a id="w-node-_12c52e6d-210d-5526-7356-6c64cce43733-867f153d" onClick={() => (utenteSelected._id ? onClickOpenModificaUtenteEsterno(utenteSelected._id) : null)} className={`button w-button ${utenteSelected._id ? "" : "button_disabled"}`}>Modifica</a>
                                                </div>
                                                <div id="w-node-bf561da2-25a7-b3ef-c44c-a819295560e3-867f153d" className="div_item_empty"></div>
                                                <div id="w-node-bf561da2-25a7-b3ef-c44c-a819295560e3-867f153d" className="div_item_empty"></div>
                                                <div id="w-node-bf561da2-25a7-b3ef-c44c-a819295560e3-867f153d" className="div_item_empty"></div>
                                                <div id="w-node-bf561da2-25a7-b3ef-c44c-a819295560e3-867f153d" className="div_item_empty"></div>
                                                <div id="w-node-_47761b2d-160d-03bc-cd09-8911d6401437-867f153d" className="div_item_button">
                                                    <a id="w-node-_12c52e6d-210d-5526-7356-6c64cce43733-867f153d" onClick={() => onClickOpenCreaCategoriaDocumento()} className={`button w-button`}>Crea cartella documento</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                    {openNuovoUtenteEsterno ?
                        <div className="div_wrap_50">
                            <div className="div_container">
                                <div className="title_window">
                                    <div className="title_window_p">Nuovo utente esterno</div>
                                </div>
                                <NuovoUtenteEsterno
                                    setOpenNuovoUtenteEsterno={setOpenNuovoUtenteEsterno}
                                />
                            </div>
                        </div>
                        :
                        ''
                    }
                    {openModificaUtenteEsterno ?
                        <div className="div_wrap_50">
                            <div className="div_container">
                                <div className="title_window">
                                    <div className="title_window_p">Modifica utente esterno</div>
                                </div>
                                <ModificaUtenteEsterno
                                    idUtenteEsterno={utenteSelected._id}
                                    setOpenModificaUtenteEsterno={setOpenModificaUtenteEsterno}
                                    categorieDocumentiUtenteEsterno={categorieDocumentiUtenteEsterno}
                                />
                            </div>
                        </div>
                        :
                        ''
                    }
                    {openCreaCategoriaDocumento ?
                        <div className="div_wrap_50">
                            <div className="div_container">
                                <div className="title_window">
                                    <div className="title_window_p">Crea cartella documento</div>
                                </div>
                                <div className="dati_container modifica_dipendente">
            <div className="fun_assegnazione_incarichi">
            <div className="row row_datapicker">
                        <div className="item_2">
                            <div>
                                <div className="w-form">
                                    <form id="nuovo_dipendente" name="email-form-2" data-name="Email Form 2" method="get" aria-label="Email Form 2">
                                        <div className="div_wrap_100">
                                            <div className="div_wrap_25">
                                                <div className="col_input">
                                                    <label htmlFor="name">Nome cartella</label>
                                                    <input type="text" className="input w-input" maxLength="256" name="nome" value={testoNuovaCategoria} data-name="testoNuovaCategoria" placeholder="Inserisci nome cartella" id="testoNuovaCategoria" onChange={e => onChangeTestoCategoria(e)} />
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                <div className="row">
                    <div className="item_4 m_top_5">
                        <div className="item_5_function">
                            <div id="w-node-d27a7e1c-7034-f43d-edeb-5fcab2bbc790-b8dac9c0" className="div_item_empty"></div>
                            <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc40-b8dac9c0" className="div_item_empty"></div>
                            <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc40-b8dac9c0" className="div_item_empty"></div>
                            <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc43-b8dac9c0" className="div_item_button">
                                <a id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc44-b8dac9c0" onClick={() => setOpenCreaCategoriaDocumento(false)} className="button w-button">Chiudi</a>
                            </div>
                            <div id="w-node-d409c487-9a0c-6b1f-803c-c7c11a8afa3e-b8dac9c0" className="div_item_button">
                                <a onClick={(e) => onSubmitCreaCategoria(e)} className="button w-button">Salva</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
                            </div>
                        </div>
                        :
                        ''
                    }
                </div>
            </div>
        </div>
    )
}

UtentiEsterni.protoTypes = {
    getUtentiEsterni: PropTypes.func.isRequired,
    utenteEsterno: PropTypes.object.isRequired,
    alert: PropTypes.object.isRequired,
    impostazione: PropTypes.object.isRequired,
    creaCategoriaDocumentoUtenteEsterno: PropTypes.func.isRequired,
    getCategorieDocumentiUtentiEsterni: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    utenteEsterno: state.utenteEsterno,
    alert: state.alert,
    impostazione: state.impostazione
});

export default connect(mapStateToProps, { getUtentiEsterni, creaCategoriaDocumentoUtenteEsterno, getCategorieDocumentiUtentiEsterni })(UtentiEsterni);