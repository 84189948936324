import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { logout } from '../../actions/auth';
import { Link } from "react-router-dom";

const Topbar = ({ auth: { user, loading }, logout }) => {

	const [utente, setUtente] = useState({
		username: '',
		nome: '',
		cognome: '',
		ruolo: '',
		vista: '',
		nomeFiliale: '',
		nomeOrganizzazione: '',
		idFiliale: ''
	});

	const [menuOpen, setMenuOpen] = useState(false)

	useEffect(() => {
		setUtente({
			username: loading || !user ? '' : user.username,
			nome: loading || !user ? '' : user.nome,
			cognome: loading || !user ? '' : user.cognome,
			ruolo: loading || !user ? '' : user.role,
			vista: loading || !user ? '' : user.vista,
			nomeFiliale: loading || !user || !user.filiale ? '' : user.filiale.nome,
			nomeOrganizzazione: loading || !user || !user.organizzazione ? '' : user.organizzazione.nome,
			idOrganizzazione: loading || !user || !user.organizzazione ? '' : user.organizzazione._id
		})
	}, [user, loading]);

	const { vista, ruolo, idOrganizzazione } = utente;

	const getLogo = (idOrganizzazione) => {
		if(idOrganizzazione == '63e618049a5309e9f6f3ff9a'){
			return 'logo_emilpol.jpg'
		}else if(idOrganizzazione == '63e618279a5309e9f6f3ff9b'){
			return 'new_koop.png'
		}else{
			return 'logo_vigili_dell_ordine.png'
		}
	}

	return (
		<div className="header wf-section">
			<div data-animation="default" data-collapse="medium" data-duration="400" data-easing="ease" data-easing2="ease" role="banner" className="navbar w-nav">
				<div className="menu_header w-container">
					<a href="/" className="brand w-nav-brand">
						<img src={`assets/media/logos/${getLogo(idOrganizzazione)}`} loading="lazy" alt="" className="logo_header" /></a>
						{vista == "scadenze" ?
						<nav role="navigation" className="nav-menu w-nav-menu">
						<Link to="/" className="menu_item w-nav-link">Home</Link>
						<div className='menu-children-submenu'>
							<Link to="/dipendenti" className="menu_item parent-menu w-nav-link">Dipendenti</Link>
						</div>
						<Link to="" onClick={() => logout()} className="menu_item w-nav-link">Logout</Link>
					</nav>
						:
						<nav role="navigation" className="nav-menu w-nav-menu">
						<Link to="/" className="menu_item w-nav-link">Home</Link>
						<div className='menu-children-submenu'>
							<Link to="/clienti" className="menu_item parent-menu w-nav-link">Anagrafica</Link>
							<ul className='submenu'>
								<li className='menu-children'>
								<Link to="/clienti" className="menu_item w-nav-link">Clienti</Link>
								<Link to="/punti-servizio" className="menu_item w-nav-link">Punti di servizio</Link>
								<Link to="/dipendenti" className="menu_item w-nav-link">Dipendenti</Link>
								</li>
							</ul>
						</div>
						<div className='menu-children-submenu'>
							<Link to="/gestione-assenze" className="menu_item parent-menu w-nav-link">Gestione</Link>
							<ul className='submenu'>
								<li className='menu-children'>
								<Link to="/gestione-assenze" className="menu_item w-nav-link">Gestione assenze</Link>
								<Link to="/invio-servizi" className="menu_item w-nav-link">Invio servizi</Link>
								</li>
							</ul>
						</div>
						<div className='menu-children-submenu'>
							<Link to="/report-cliente" className="menu_item parent-menu w-nav-link">Stampe</Link>
							<ul className='submenu'>
								<li className='menu-children'>
								<Link to="/report-cliente" className="menu_item w-nav-link">Report clienti</Link>
								<Link to="/report-cliente-data" className="menu_item w-nav-link">Report clienti per data</Link>
								<Link to="/filiale" className="menu_item w-nav-link">Filiale</Link>
								<Link to="/quadrature" className="menu_item w-nav-link">Quadratura ore</Link>
								<Link to="/assenteismo" className="menu_item w-nav-link">Assenteismo</Link>
								<Link to="/stampa-giornate" className="menu_item w-nav-link">Stampa giornate</Link>
								<Link to="/stampa-poligono" className="menu_item w-nav-link">Stampa poligono</Link>
								</li>
							</ul>
						</div>
						<Link to="/incarichi" className="menu_item w-nav-link">Incarichi</Link>
						 <Link to="/incarichi-calendario" className="menu_item w-nav-link">Incarichi Calendario</Link>
						{ruolo == 'organizzazione' ?
						<div className='menu-children-submenu'>
							<Link to="/amministrazione" className="menu_item parent-menu w-nav-link">Amministrazione</Link>
							<ul className='submenu'>
								<li className='menu-children'>
								<Link to="/amministrazione" className="menu_item w-nav-link">Amministrazione</Link>
								<Link to="/genera-trripa" className="menu_item w-nav-link">Trripa</Link>
								<Link to="/impostazioni" className="menu_item w-nav-link">Impostazioni</Link>	
								</li>
							</ul>
						</div>
						:
						''
						}
						<Link to="/utenti-esterni" className="menu_item w-nav-link">Utenti esterni</Link>
						<Link to="" onClick={() => logout()} className="menu_item w-nav-link">Logout</Link>
					</nav>
					}
					
					<div className={`menu-button w-nav-button${menuOpen ? ' w--open' : ''}`} onClick={() => setMenuOpen(!menuOpen)}>
						<div className="w-icon-nav-menu"></div>
					</div>
				</div>
				{menuOpen ?
				<>
				{vista == "scadenze" ?
				<div class="w-nav-overlay block-menu" data-wf-ignore="" id="w-nav-overlay-0" >
				<nav role="navigation" class="nav-menu w-nav-menu" data-nav-menu-open="" >
					<Link to="/" className="menu_item w-nav-link">Home</Link>
					<Link to="/dipendenti" className="menu_item w-nav-link">Dipendenti</Link>
					<Link to="" onClick={() => logout()} className="menu_item w-nav-link">Logout</Link>
				</nav>
			</div>
				:
				<div class="w-nav-overlay block-menu" data-wf-ignore="" id="w-nav-overlay-0" >
					<nav role="navigation" class="nav-menu w-nav-menu" data-nav-menu-open="" >
						<Link to="/" className="menu_item w-nav-link">Home</Link>
						<Link to="/clienti" className="menu_item w-nav-link">Clienti</Link>
						<Link to="/report-cliente" className="menu_item w-nav-link">Report clienti</Link>
						<Link to="/report-cliente-data" className="menu_item w-nav-link">Report clienti per data</Link>
						<Link to="/punti-servizio" className="menu_item w-nav-link">Punti di servizio</Link>
						<Link to="/dipendenti" className="menu_item w-nav-link">Dipendenti</Link>
						<Link to="/incarichi" className="menu_item w-nav-link">Incarichi</Link>
						<Link to="/filiale" className="menu_item w-nav-link">Filiale</Link>
						<Link to="/gestione-assenze" className="menu_item w-nav-link">Gestione assenze</Link>
						<Link to="/amministrazione" className="menu_item w-nav-link">Amministrazione</Link>
						<Link to="/invio-servizi" className="menu_item w-nav-link">Invio servizi</Link>
						<Link to="/quadrature" className="menu_item w-nav-link">Quadrature</Link>
						<Link to="/stampa-giornate" className="menu_item w-nav-link">Stampa giornate</Link>	
						<Link to="/utenti-esterni" className="menu_item w-nav-link">Utenti esterni</Link>
						<Link to="/impostazioni" className="menu_item w-nav-link">Impostazioni</Link>
						<Link to="" onClick={() => logout()} className="menu_item w-nav-link">Logout</Link>
					</nav>
				</div>
				}
				</>
				:
				''
				}
			</div>
		</div>
	)
}

Topbar.protoTypes = {
	logout: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
	auth: state.auth
});

export default connect(mapStateToProps, { logout })(Topbar);

