import React, { useEffect, useState } from 'react';
const QuadratureElement = ({quadraturaOreData, bold}) => {

    const [quadraturaOre, setQuadraturaOre] = useState([]);

    useEffect(() => {
        quadraturaOreData.map((el, i) => {
            el.gennaio.totale = fromMinutiToOreString(el.gennaio.totale)
            el.febbraio.totale = fromMinutiToOreString(el.febbraio.totale)
            el.marzo.totale = fromMinutiToOreString(el.marzo.totale)
            el.aprile.totale = fromMinutiToOreString(el.aprile.totale)
            el.maggio.totale = fromMinutiToOreString(el.maggio.totale)
            el.giugno.totale = fromMinutiToOreString(el.giugno.totale)
            el.luglio.totale = fromMinutiToOreString(el.luglio.totale)
            el.agosto.totale = fromMinutiToOreString(el.agosto.totale)
            el.settembre.totale = fromMinutiToOreString(el.settembre.totale)
            el.ottobre.totale = fromMinutiToOreString(el.ottobre.totale)
            el.novembre.totale = fromMinutiToOreString(el.novembre.totale)
            el.dicembre.totale = fromMinutiToOreString(el.dicembre.totale)
            el.totale.totale = fromMinutiToOreString(el.totale.totale)
        })
        setQuadraturaOre(quadraturaOreData)
    }, [quadraturaOreData]);

    const fromMinutiToOreString = (minutiTotali) => {
        let minutiTotaliAbs = Math.abs(minutiTotali);
        var minuti = minutiTotaliAbs % 60;
        var ore = (minutiTotaliAbs - minuti) / 60;
        if (minutiTotali >= 0) {
            if (ore == 0 && minuti == 0) {
                return "00:00"
            } else {
                return formattaOre(ore) + ":" + formattaMinuti(minuti)
            }
        } else {
            return "-" + formattaOre(ore) + ":" + formattaMinuti(minuti)

        }
    }

    const formattaOre = (ore) => {
        return (ore <= 9 ? "0" : "") + ore
    }

    const formattaMinuti = (minuti) => {
        return (minuti <= 9 ? "0" : "") + minuti;
    }

    const onChangeData = (i, mese, field, value) => {
        let array = [...quadraturaOre]
        array[i][mese][field] = value
        setQuadraturaOre(array)        
    }

    return (
        quadraturaOre.length > 0 ?
            <>
                {quadraturaOre.map((el, i) => (
                    <div key={"quadraturaOreElement" + i} className={`w-layout-grid div_column_quadrature_ore${bold ? ' testi-grassetto' : ''}`}>
                        <div className="div_item_column div_item_column_quadratura_ore">
                            <div className="item_table_quadratura">{el.descrizione}</div>
                        </div>
                        <div className="div_item_column div_item_column_quadratura_ore">
                            <div className="item_table_quadratura"></div>
                        </div>
                        <div className="div_item_column div_item_column_quadratura_ore">
                            <div className="item_table_quadratura">
                            <input type="text" name="gennaio.totale" value={el.gennaio.totale} onChange={(e) => {onChangeData(i, 'gennaio', 'totale', e.target.value)}} className="input w-input edit_quadratura item_table_quadratura"/>
                            </div>
                        </div>
                        <div className="div_item_column div_item_column_quadratura_ore">
                            <input type="text" name="febbraio.totale" value={el.febbraio.totale} onChange={(e) => {onChangeData(i, 'febbraio', 'totale', e.target.value)}} className="input w-input edit_quadratura item_table_quadratura"/>
                        </div>
                        <div className="div_item_column div_item_column_quadratura_ore">
                        <input type="text" name="marzo.totale" value={el.marzo.totale} onChange={(e) => {onChangeData(i, 'marzo', 'totale', e.target.value)}} className="input w-input edit_quadratura item_table_quadratura"/>
                        </div>
                        <div className="div_item_column div_item_column_quadratura_ore">
                        <input type="text" name="aprile.totale" value={el.aprile.totale} onChange={(e) => {onChangeData(i, 'aprile', 'totale', e.target.value)}} className="input w-input edit_quadratura item_table_quadratura"/>
                        </div>
                        <div className="div_item_column div_item_column_quadratura_ore">
                        <input type="text" name="maggio.totale" value={el.maggio.totale} onChange={(e) => {onChangeData(i, 'maggio', 'totale', e.target.value)}} className="input w-input edit_quadratura item_table_quadratura"/>
                        </div>
                        <div className="div_item_column div_item_column_quadratura_ore">
                        <input type="text" name="giugno.totale" value={el.giugno.totale} onChange={(e) => {onChangeData(i, 'giugno', 'totale', e.target.value)}} className="input w-input edit_quadratura item_table_quadratura"/>
                        </div>
                        <div className="div_item_column div_item_column_quadratura_ore">
                        <input type="text" name="luglio.totale" value={el.luglio.totale} onChange={(e) => {onChangeData(i, 'luglio', 'totale', e.target.value)}} className="input w-input edit_quadratura item_table_quadratura"/>
                        </div>
                        <div className="div_item_column div_item_column_quadratura_ore">
                        <input type="text" name="agosto.totale" value={el.agosto.totale} onChange={(e) => {onChangeData(i, 'agosto', 'totale', e.target.value)}} className="input w-input edit_quadratura item_table_quadratura"/>
                        </div>
                        <div className="div_item_column div_item_column_quadratura_ore">
                        <input type="text" name="settembre.totale" value={el.settembre.totale} onChange={(e) => {onChangeData(i, 'settembre', 'totale', e.target.value)}} className="input w-input edit_quadratura item_table_quadratura"/>
                        </div>
                        <div className="div_item_column div_item_column_quadratura_ore">
                        <input type="text" name="ottobre.totale" value={el.ottobre.totale} onChange={(e) => {onChangeData(i, 'ottobre', 'totale', e.target.value)}} className="input w-input edit_quadratura item_table_quadratura"/>
                        </div>
                        <div className="div_item_column div_item_column_quadratura_ore">
                        <input type="text" name="novembre.totale" value={el.novembre.totale} onChange={(e) => {onChangeData(i, 'novembre', 'totale', e.target.value)}} className="input w-input edit_quadratura item_table_quadratura"/>
                        </div>
                        <div className="div_item_column div_item_column_quadratura_ore">
                        <input type="text" name="dicembre.totale" value={el.dicembre.totale} onChange={(e) => {onChangeData(i, 'dicembre', 'totale', e.target.value)}} className="input w-input edit_quadratura item_table_quadratura"/>
                        </div>
                        <div className="div_item_column div_item_column_quadratura_ore">
                        <input type="text" name="totale.totale" value={el.totale.totale} onChange={(e) => {onChangeData(i, 'totatle', 'totale', e.target.value)}} className="input w-input edit_quadratura item_table_quadratura"/>
                        </div>
                    </div>
                ))}
            </>
            :
            ''
    )
}

export default QuadratureElement;