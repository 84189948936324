import React, { Fragment, useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import DatePicker from 'react-date-picker';
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import Moment from 'react-moment'
import { v4 as uuid } from 'uuid';
import {Helmet} from "react-helmet";
import Spinner from '../../layout/Spinner';
import { getEsitiInvioTurni, inviaTurni } from '../../../actions/invioTurni';
const InvioServizi = ({ invioTurni: { esitiInvioTurni }, dipendente: { dipendenti }, getEsitiInvioTurni, inviaTurni, alert }) => {



    const [dataInizioInput, setDataInizioInput] = useState((setHours(setMinutes(startOfMonth(new Date()), 0), 0)));
    const [dataFineInput, setDataFineInput] = useState((setHours(setMinutes(endOfMonth(new Date()), 59), 23)));
    const [dataInizioInputInvio, setDataInizioInputInvio] = useState((setHours(setMinutes(startOfMonth(new Date()), 0), 0)));
    const [dataFineInputInvio, setDataFineInputInvio] = useState((setHours(setMinutes(endOfMonth(new Date()), 59), 23)));
    const [openInviaServiziPopUp, setOpenInviaServiziPopUp] = useState(false);
    const [rowSelected, setRowSelected] = useState({ id: '', dipendenti: []});

    useEffect(() => {
        getEsitiInvioTurni({dataInizio: dataInizioInput, dataFine: dataFineInput})
    }, [dataInizioInput, dataFineInput]);


    const onChangeRangeDateInizio = (data) => {
        setDataFineInput(setHours(setMinutes(endOfMonth(data), 59), 23))
        setDataInizioInput(data)
    }


    const onChangeRangeDateFine = (data) => {
        setDataFineInput(data)
    }

    const onChangeDataInizioInputInvio = (data) => {
        setDataFineInputInvio(setHours(setMinutes(endOfMonth(data), 59), 23))
        setDataInizioInputInvio(data)
    }

    const onChangeDataFineInputInvio = (data) => {
        setDataFineInputInvio(data)
    }

    const onClickCercaEsitoInviiTurni = () => {
        getEsitiInvioTurni({dataInizio: dataInizioInput, dataFine: dataFineInput})
    }

    const onClickOpenInviaSeriviziPopUp = () => {
        setOpenInviaServiziPopUp(true)
    }

    const onClickInviaTurni = () => {
        inviaTurni({dataInizio: dataInizioInput, dataFine: dataFineInput})
        setOpenInviaServiziPopUp(false)
    }

    const onClickSetRowSelected = async ({ id, dipendenti }) => {
        setRowSelected({ id, dipendenti})
    }

    return (
        <div className="body wf-section">
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`Invio servizi - Gestionale Vigili dell'ordine`}</title>
            </Helmet>
            <div className="div_wrap">
                <div className="div_wrap_100">
                    <div className="div_wrap_50">
                        <div className="div_container">
                            <div className="title_window">
                                <div className="title_window_p">Invio servizi dipendenti</div>
                            </div>
                            <div className="dati_container">
                                <div className="fun_assegnazione_incarichi">
                                    <div className="row row_datapicker">
                                        <div className="item_1 filtro_wrap_incarichi">
                                            <div id="w-node-_284812ee-7924-9f38-86bb-8d2cc43dff3c-867f153d" className="div_item_1">
                                                <div id="w-node-_80bb2acb-3043-3649-0498-379a652f3b04-867f153d" className="label_text">Data inizio</div>
                                                <div data-hover="false" data-delay="0" className="select w-dropdown">
                                                    <DatePicker
                                                        value={dataInizioInput}
                                                        format="dd/MM/y"
                                                        weekPicker
                                                        onChange={onChangeRangeDateInizio}
                                                        locale="it-IT"
                                                        clearIcon={null}
                                                        weekStartDayIndex={1}
                                                    />
                                                </div>
                                            </div>
                                            <div id="w-node-_284812ee-7924-9f38-86bb-8d2cc43dff3c-867f153d" className="div_item_1">
                                                <div id="w-node-_80bb2acb-3043-3649-0498-379a652f3b04-867f153d" className="label_text">Data fine</div>
                                                <div data-hover="false" data-delay="0" className="select w-dropdown">
                                                    <DatePicker
                                                        value={dataFineInput}
                                                        format="dd/MM/y"
                                                        weekPicker
                                                        onChange={onChangeRangeDateFine}
                                                        locale="it-IT"
                                                        clearIcon={null}
                                                        weekStartDayIndex={1}
                                                    />
                                                </div>
                                            </div>
                                            <div id="w-node-_98d32d21-f772-2386-6ce9-47c8e0ec064d-867f153d" className="div_item_1">
                                                <div className="w-form">
                                                    <div id="email-form" name="email-form" data-name="Email Form" method="get">
                                                        <label className="w-checkbox checkbox-field">
                                                            <span className="label_text w-form-label" htmlFor="checkbox"></span>
                                                        </label>
                                                        <button id="w-node-a7f8735f-5a06-66e1-52ab-69f728a73208-867f153d" onClick={() => { onClickCercaEsitoInviiTurni() }} className="button w-button" >Aggiorna</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="item_2">
                                            <div className="intestazione_table">
                                                <div className="w-layout-grid div_column_invio_servizi intestazione">
                                                    <div className="div_item_column">
                                                        <div className="item_table">
                                                            <div className="item_table"></div>
                                                        </div>
                                                    </div>
                                                    <div className="div_item_column">
                                                        <div className="item_table">Data invio</div>
                                                    </div>
                                                    <div className="div_item_column">
                                                        <div className="item_table">Da</div>
                                                    </div>
                                                    <div className="div_item_column">
                                                        <div className="item_table">A</div>
                                                    </div>
                                                    <div className="div_item_column">
                                                        <div className="item_table">Esito</div>
                                                    </div>
                                                </div>
                                            </div>
                                                <div className="body_table">
                                                    {esitiInvioTurni.map((esitoInvio, i) => (
                                                            <div key={"esitoInvio" + i} className={`w-layout-grid div_column_invio_servizi cursor ${rowSelected.id == esitoInvio._id ? 'esito_selected' : ''}`}>
                                                                <div className="div_item_column" onClick={() => onClickSetRowSelected({ id: esitoInvio._id, dipendenti: esitoInvio.dipendenti })}>
                                                                    <div className="item_table"></div>
                                                                </div>
                                                                <div className="div_item_column" onClick={() => onClickSetRowSelected({ id: esitoInvio._id, dipendenti: esitoInvio.dipendenti })}>
                                                                    <div className="item_table"><Moment format='DD/MM/YYYY'>{esitoInvio.dataInvio}</Moment></div>
                                                                </div>
                                                                <div className="div_item_column" onClick={() => onClickSetRowSelected({ id: esitoInvio._id, dipendenti: esitoInvio.dipendenti })}>
                                                                    <div className="item_table"><Moment format='DD/MM/YYYY'>{esitoInvio.dataInizio}</Moment></div>
                                                                </div>
                                                                <div className="div_item_column" onClick={() => onClickSetRowSelected({ id: esitoInvio._id, dipendenti: esitoInvio.dipendenti })}>
                                                                    <div className="item_table"><Moment format='DD/MM/YYYY'>{esitoInvio.dataFine}</Moment></div>
                                                                </div>
                                                                <div className={`div_item_column ${esitoInvio.esito == "iniziato" ? "orange_label" : esitoInvio.esito == "finito" ? "green_label" : ""}`} onClick={() => onClickSetRowSelected({ id: esitoInvio._id, dipendenti: esitoInvio.dipendenti })}>
                                                                    <div className="item_table">{esitoInvio.esito}</div>
                                                                </div>
                                                            </div>
                                                    ))}
                                                </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="item_3 m_top_5">
                                            <div className="list_button">
                                                <div id="w-node-_571e9ca7-8650-59ca-aaad-79ac7369ac51-867f153d" className="div_item_button">
                                                    <a id="w-node-_571e9ca7-8650-59ca-aaad-79ac7369ac52-867f153d" onClick={(e) => { onClickOpenInviaSeriviziPopUp() }} className="button w-button">Invia Servizi</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {rowSelected.id.length > 0 ?
                        <div className="div_wrap_50">
                        <div className="div_container">
                            <div className="title_window">
                                <div className="title_window_p">Esito invio</div>
                            </div>
                            <div className="dati_container">
                                <div className="fun_assegnazione_incarichi">
                                    <div className="row">
                                        <div className="item_2">
                                            <div className="intestazione_table">
                                                <div className="w-layout-grid div_column_invio_servizi_dipendenti intestazione">
                                                    <div className="div_item_column">
                                                        <div className="item_table">
                                                            <div className="item_table"></div>
                                                        </div>
                                                    </div>
                                                    <div className="div_item_column">
                                                        <div className="item_table">Cognome</div>
                                                    </div>
                                                    <div className="div_item_column">
                                                        <div className="item_table">Nome</div>
                                                    </div>
                                                    <div className="div_item_column">
                                                        <div className="item_table">Email</div>
                                                    </div>
                                                    <div className="div_item_column">
                                                        <div className="item_table">Inviata</div>
                                                    </div>
                                                </div>
                                            </div>
                                                <div className="body_table">
                                                    {rowSelected.dipendenti.map((dipendente, i) => (
                                                            <div key={"dipendente-invio" + i} className="w-layout-grid div_column_invio_servizi_dipendenti">
                                                                <div className="div_item_column">
                                                                    <div className="item_table"></div>
                                                                </div>
                                                                <div className="div_item_column">
                                                                    <div className="item_table">{dipendente.cognome}</div>
                                                                </div>
                                                                <div className="div_item_column">
                                                                    <div className="item_table">{dipendente.nome}</div>
                                                                </div>
                                                                <div className="div_item_column">
                                                                    <div className="item_table">{dipendente.email}</div>
                                                                </div>
                                                                <div className={`div_item_column ${dipendente.esito ? "green_label" : "red_label"}`}>
                                                                    <div className="item_table">{dipendente.esito ? "Si" : "No"}</div>
                                                                </div>
                                                            </div>
                                                    ))}
                                                </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                        :
                        ''
                    }
                    {openInviaServiziPopUp ?
                        <div className="popup wf-section">
                            <div className="alert_popup">
                                <p>Scegli intervallo date </p>
                                <div className="div-block">
                                    <>
                                        <div data-hover="false" data-delay="0" className="select w-dropdown">
                                            <DatePicker
                                                value={dataInizioInput}
                                                format="dd/MM/y"
                                                weekPicker
                                                onChange={onChangeRangeDateInizio}
                                                locale="it-IT"
                                                clearIcon={null}
                                                weekStartDayIndex={1}
                                            />
                                        </div>
                                        <div data-hover="false" data-delay="0" className="select w-dropdown">
                                            <DatePicker
                                                value={dataFineInput}
                                                format="dd/MM/y"
                                                weekPicker
                                                onChange={onChangeRangeDateFine}
                                                locale="it-IT"
                                                clearIcon={null}
                                                weekStartDayIndex={1}
                                            />
                                        </div>
                                        <a onClick={() => onClickInviaTurni()} className="button m_left_5 w-button">Invia</a>
                                        <a onClick={() => setOpenInviaServiziPopUp(false)} className="button red w-button">Annulla</a>
                                    </>
                                </div>
                            </div>
                        </div>
                        :
                        ""
                    }
                </div>
            </div>
        </div>
    )
}

InvioServizi.protoTypes = {
    invioTurni: PropTypes.object.isRequired,
    dipendente: PropTypes.object.isRequired,
    alert: PropTypes.object.isRequired,
    getEsitiInvioTurni: PropTypes.func.isRequired,
    inviaTurni: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    invioTurni: state.invioTurni,
    cliente: state.cliente,
    dipendente: state.dipendente,
    alert: state.alert
});

export default connect(mapStateToProps, {getEsitiInvioTurni, inviaTurni})(InvioServizi);