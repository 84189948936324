import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import documentoIMG from "../../../img/documento.png"
import cestinoIMG from "../../../img/trash.png"
import DatePicker from 'react-date-picker';



const Documento = ({ id, nome, url, tipologieDocumenti, onChangeUploadFile, onChangeNumeroDocumento, numero, dataRilascio, onChangeSetDataRilascioDocumento, onChangeSetScadenzaDocumento, scadenza, onChangeTipologiaDocumento, eliminaDocumento, pulisciFileDocumento, bloccaAvviso, onChangeSetCheckboxDocumenti }) => {

    return (
        <div className='mb-30'>
            <div className="div_wrap_100">
                <div className="div_wrap_25">
                    <div className="col_input">
                        <label htmlFor="tagliaPantaloni">Numero</label>
                        <input type="text" className="input w-input" maxLength="256" name="numero" value={numero} data-name="numero" id="numero" onChange={e => onChangeNumeroDocumento(e, id)} />
                    </div>
                </div>
                {url ?
                            <div className="div_wrap_25">
                                <div className="col_input">
                                    <label htmlFor="tagliaPantaloni">File</label>
                                    <div className='item_doc_dip'>
                                    <span><a target="_blank" href={url}><img className='doc_dip_img' src={documentoIMG}></img></a><img onClick={() => pulisciFileDocumento(id)} className='doc_dip_img' src={cestinoIMG}></img></span>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="div_wrap_25">
                                <div className="col_input">
                                    <label htmlFor="tagliaPantaloni">File</label>
                                    <div className='item_doc_dip'>
                                        <input type="file" className="input w-input" name={nome} data-name={nome} id={id} onChange={(e) => onChangeUploadFile(e, id, "documento")} />
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="div_wrap_25">
                    <div className="col_input col_select">
                        <label htmlFor="name">Tipologia documento</label>
                        <div data-hover="false" data-delay="0" className="select w-dropdown">
                            <select className="form-control select" name="tipologia" onChange={e => onChangeTipologiaDocumento(e.target.value, id)} required>
                                <option selected={true} value={null} disabled>seleziona...</option>
                                {tipologieDocumenti.map((el, i)=> (
                                <option name={el.nome} selected={nome == el.nome} value={i}>{el.nome}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="div_wrap_25">
                    <div className="col_input"><label htmlFor="name-11">Data rilascio</label>
                        <div data-hover="false" data-delay="0" className="select w-dropdown select_data">
                            <DatePicker
                                onChange={(value) => onChangeSetDataRilascioDocumento(value, id)}
                                value={dataRilascio}
                                clearIcon={null}
                                className={'form-control'}
                                disableCalendar={false}
                            />
                        </div>
                    </div>
                </div>
                <div className="div_wrap_25">
                        <div className="col_input">
                            <label htmlFor="tagliaPantaloni">Scadenza (mesi)</label>
                            <div className='item_doc_dip'>
                                <input type="number" className="input w-input" name={scadenza} data-name={scadenza} value={scadenza} onChange={e => onChangeSetScadenzaDocumento(e.target.value, id)} required />
                            </div>
                        </div>
                    </div>
            </div>
            <div className='div_wrap_100'>
            <div className="div_wrap_25">
                <div className="col_input">
                    <label htmlFor="name">Blocca avviso</label>
                    <label className="w-checkbox checkbox-field m_right_10">
                    <input type="checkbox" id={"bloccaAvviso"} className="w-checkbox-input" name="bloccaAvviso" checked={bloccaAvviso} value={bloccaAvviso} onChange={e => onChangeSetCheckboxDocumenti(e.target.value, id)} />
                    <span className="label_text w-form-label" htmlFor="checkbox-3">Si {bloccaAvviso}</span>
                    </label>
                </div>
            </div>
                <div className="div_wrap_25">
                    <span></span>
                </div>
                <div className="div_wrap_25">
                    <span></span>
                </div>
                <div className="div_wrap_25">
                    <div className="col_input">
                        <div id="w-node-d409c487-9a0c-6b1f-803c-c7c11a8afa3e-b8dac9c0" onClick={() => eliminaDocumento(id)} class="div_item_button"><a class="button w-button">elimina</a></div>
                    </div>
                </div>
            </div>
        </div>
    )
}
Documento.protoTypes = {
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, {})(Documento);

