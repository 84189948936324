import axios from 'axios';
import { GET_PUNTO_VENDITA, GET_PUNTI_VENDITA, CREA_PUNTO_VENDITA, MODIFICA_PUNTO_VENDITA, ELIMINA_PUNTO_VENDITA, GET_CONTRATTI_PUNTI_VENDITA, GET_CONTRATTI_PUNTI_VENDITA_CALENDARIO, GET_CONTRATTI_PUNTO_VENDITA, CLEAR_PUNTO_VENDITA, PUNTO_VENDITA_ERROR, AGGIUNGI_SERVIZIO_SPORADICO, GET_RICHIESTE, GET_RICHIESTE_SENZA_CONTRATTO, GET_CALENDARIO_JOB, CLEAN_CALENDARIO_RICHIESTE } from './types';
import { setAlert } from './alert';

export const getTuttiPuntiVendita = () => async dispatch => {
    try {
        const res = await axios.get('/api/puntoVendita/tuttiPuntiVendita');
        dispatch({
            type: GET_PUNTI_VENDITA,
            payload: res.data
        });
    } catch (err) {
        dispatch({
            type: PUNTO_VENDITA_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        });
    }
};

export const getContrattiPuntoVendita = ({ idPuntoVendita }) => async dispatch => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const body = JSON.stringify({ idPuntoVendita });
        const res = await axios.post('/api/puntoVendita/contrattiPuntoVendita', body, config);
        dispatch({
            type: GET_CONTRATTI_PUNTO_VENDITA,
            payload: res.data
        });
    } catch (err) {
        dispatch({
            type: PUNTO_VENDITA_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        });
    }
};

export const getPuntiVendita = ({ idCliente }) => async dispatch => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const body = JSON.stringify({ idCliente });
        const res = await axios.post('/api/puntoVendita/puntiVendita', body, config);
        dispatch({
            type: GET_PUNTI_VENDITA,
            payload: res.data
        });
    } catch (err) {
        dispatch({
            type: PUNTO_VENDITA_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        });
    }
};

export const getPuntoVendita = (idPuntoVendita) => async dispatch => {
    try {
        const res = await axios.get(`/api/puntoVendita/puntoVendita/${idPuntoVendita}`);
        dispatch({
            type: GET_PUNTO_VENDITA,
            payload: res.data
        });
    } catch (err) {
        dispatch({
            type: PUNTO_VENDITA_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        });
    }
};

export const creaNuovoPuntoVendita = ({ denominazione, idZonaRes, idServiziEff, idIndennitaDiFunz, idRaggruppa, qualifica, indirizzo, cap, localita, provincia, telefono, email, idCliente, contratti }) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ denominazione, idZonaRes, idServiziEff, idIndennitaDiFunz, idRaggruppa, qualifica, indirizzo, cap, localita, provincia, telefono, email, idCliente, contratti });
    try {
        const res = await axios.post('/api/puntoVendita/nuovoPuntoVendita', body, config);
        dispatch({
            type: CREA_PUNTO_VENDITA,
            payload: res.data
        });
        dispatch(setAlert('Nuovo Punto di servizio creato!', 'success'));

    } catch (err) {
        const errors = err.response.data.errors;
        if (errors) {
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }

        dispatch({
            type: PUNTO_VENDITA_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        });
    }
};


export const modificaPuntoVendita = ({ idPuntoVendita, denominazione, idZonaRes, idServiziEff, idIndennitaDiFunz, idRaggruppa, qualifica, indirizzo, cap, localita, provincia, telefono, email, contratti, controllo, idCliente }) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ idPuntoVendita, denominazione, idZonaRes, idServiziEff, idIndennitaDiFunz, idRaggruppa, qualifica, indirizzo, cap, localita, provincia, telefono, email, contratti, controllo, idCliente });
    try {
        const res = await axios.post('/api/puntoVendita/modificaPuntoVendita', body, config);
        dispatch(setAlert('Punto di servizio modificato con successo!', 'success'));
        dispatch({
            type: MODIFICA_PUNTO_VENDITA,
            payload: res.data
        });
    } catch (err) {
        const errors = err.response.data.errors;
        if (errors) {
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        dispatch({
            type: PUNTO_VENDITA_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        });
    }
};

export const eliminaPuntoVendita = (idPuntoVendita) => async dispatch => {
    try {
        const res = await axios.get(`/api/puntoVendita/eliminaPuntoVendita/${idPuntoVendita}`);
        dispatch(setAlert('Punto di servizio eliminato con successo!', 'success'));
        dispatch({
            type: ELIMINA_PUNTO_VENDITA,
            payload: res.data
        });
    } catch (err) {
        dispatch({
            type: PUNTO_VENDITA_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        });
    }
};

export const getContrattiRichiestePuntiVendita = ({ idCliente, dataInizio, idFiliale }) => async dispatch => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const body = JSON.stringify({ idCliente, dataInizio, idFiliale });
        const res = await axios.post('/api/puntoVendita/contrattiRichiestePuntiVendita', body, config);
        dispatch({
            type: GET_CONTRATTI_PUNTI_VENDITA,
            payload: res.data.contratti
        });
        dispatch({
            type: GET_RICHIESTE,
            payload: res.data.richieste
        });
        dispatch({
            type: GET_RICHIESTE_SENZA_CONTRATTO,
            payload: res.data.richiesteSenzaContratto
        });
    } catch (err) {
        dispatch({
            type: PUNTO_VENDITA_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        });
    }
};

export const getContrattiRichiestePuntiVenditaCalendario = ({ dataInizio, giorni }) => async dispatch => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        const body = JSON.stringify({ dataInizio, giorni });
        const res = await axios.post('/api/puntoVendita/getContrattiRichiestePuntiVenditaCalendario', body, config);
        dispatch({
            type: GET_CONTRATTI_PUNTI_VENDITA_CALENDARIO,
            payload: res.data
        });
        
    } catch (err) {
        dispatch({
            type: PUNTO_VENDITA_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        });
    }
};

export const getStateContrattiRichiestePuntiVenditaCalendario = (id) => async dispatch => {
    try{
        const res = await axios.get(`/api/puntoVendita/getStateContrattiRichiestePuntiVenditaCalendario/${id}`);
        if(res.data.state == 'completed'){
            dispatch({
                type: GET_CALENDARIO_JOB,
                payload: res.data
            });
        }
    }catch(err){
        console.log(err)
        dispatch({
            type: PUNTO_VENDITA_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};


export const clearPuntoVendita = () => async dispatch => {
    try {
        dispatch({
            type: CLEAR_PUNTO_VENDITA
        });
    } catch (err) {
        dispatch({
            type: PUNTO_VENDITA_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        });
    }
};

export const aggiungiServizioSporadico = ({ idPuntoVenditaSporadico, dataInizioServizioSporadico, dataFineServizioSporadico, oraInizioServizioSporadico, oraFineServizioSporadico, multiplo }) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ idPuntoVenditaSporadico, dataInizioServizioSporadico, dataFineServizioSporadico, oraInizioServizioSporadico, oraFineServizioSporadico, multiplo });
    try {
        const res = await axios.post('/api/puntoVendita/aggiungiServizioSporadico', body, config);
        dispatch(setAlert('Servizio sporadico aggiunto con successo!', 'success', 'nuovo_servizio_sporadico'));
        dispatch({
            type: AGGIUNGI_SERVIZIO_SPORADICO,
            payload: res.data
        });
    } catch (err) {
        const errors = err.response.data.errors;
        if (errors) {
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        dispatch({
            type: PUNTO_VENDITA_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status }
        });
    }
};

export const cleanCalendarioRichieste = () => async dispatch => {
    dispatch({
        type: CLEAN_CALENDARIO_RICHIESTE
    });
};