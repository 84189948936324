import { GET_TIPOLOGIE_CORSI, CREA_TIPOLOGIA_CORSO, DOCUMENTO_ERROR } from "../actions/types";

const initialState = {
    tipologieCorsi: [],
    error: {},
    loading: true
}

export default function(state = initialState, action){
    const { type, payload } = action;

    switch(type){
        case GET_TIPOLOGIE_CORSI: 
            return {
                ...state,
                tipologieCorsi: payload.tipologieCorsi,
                tipologieDocumenti: payload.tipologieDocumenti,
                tipologieDocumentiDipendenti: payload.tipologieDocumentiDipendenti,
                loading: false
            };
        case CREA_TIPOLOGIA_CORSO:
            let arrayTipologieCorsi = [...state.tipologieCorsi];
            arrayTipologieCorsi.push(payload);
            return {
                ...state,
                tipologieCorsi: arrayTipologieCorsi,
                loading: false
            };
        case DOCUMENTO_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            };
        default:
            return state;
    }
}