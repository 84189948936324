import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {Helmet} from "react-helmet";
import Spinner from '../../layout/Spinner';
import ModificaDipendente from './ModificaDipendente';
import NuovoDipendente from './NuovoDipendente';
import IncarichiDipendente from './IncarichiDipendente';
import { getDipendenti } from '../../../actions/dipendente';
import { getFiliali } from '../../../actions/filiale';
const Dipendenti = ({ filiale: { filiali }, getFiliali, auth: {user}, dipendente: { dipendenti, loading }, getDipendenti }) => {


    const [utente, setUtente] = useState({
		username: '',
		nome: '',
		cognome: '',
		ruolo: '',
		vista: '',
		nomeFiliale: '',
		nomeOrganizzazione: '',
		idFiliale: ''
	});

    const [filialiList, setFilialiList] = useState([]);
    const [filtraFiliale, setFiltraFiliale] = useState("tutti");


    useEffect(() => {
		setUtente({
			username: loading || !user ? '' : user.username,
			nome: loading || !user ? '' : user.nome,
			cognome: loading || !user ? '' : user.cognome,
			ruolo: loading || !user ? '' : user.role,
			vista: loading || !user ? '' : user.vista,
			nomeFiliale: loading || !user || !user.filiale ? '' : user.filiale.nome,
			nomeOrganizzazione: loading || !user || !user.organizzazione ? '' : user.organizzazione.nome,
			idOrganizzazione: loading || !user || !user.organizzazione ? '' : user.organizzazione._id
		})
	}, [user, loading]);

    useEffect(() => {
        if (user && user.role == "organizzazione") {
            getFiliali();
        }
    }, [user]);

    useEffect(() => {
        if (filiali.length > 0) {
            let array = [];
            filiali.map(filiale => {
                const index = user.vistaFiliali.findIndex(el => el._id == filiale._id);
                if(index >= 0){
                    array.push(filiale)
                }
            })
            setFilialiList(array)
        }
    }, [filiali]);

	const { vista, ruolo } = utente;

    const [openNuovoDipendente, setOpenNuovoDipendente] = useState(false);
    const [openModificaDipendente, setOpenModificaDipendente] = useState(false);
    const [openIncarichiDipendente, setOpenIncarichiDipendente] = useState(false);
    const [page, setPage] = useState(0);
    const pagination = 10;
    const [visualizzaNonAttivi, setVisualizzaNonAttivi] = useState(false);
    const [dipendenteSelected, setDipendenteSelected] = useState({ _id: null });
    const [testoFiltraDipendenti, setTestoFiltraDipendenti] = useState("");
    const [dipendentiList, setDipendentiList] = useState([]);
    const [checkBoxFiltraScaduti, setCheckBoxFiltraScaduti] = useState(false);


    useEffect(() => {
        getDipendenti(page, pagination, visualizzaNonAttivi);
    }, [getDipendenti]);


    useEffect(() => {
        let arrayDipendenti = [...dipendenti]
        if(ruolo == 'organizzazione'){
            if(filtraFiliale != "tutti"){
                arrayDipendenti = filtraPerFiliale(arrayDipendenti, filtraFiliale)
            }else{
                arrayDipendenti = filtraPerFilialeTutti(arrayDipendenti)
    
            } 
        }
        if (testoFiltraDipendenti.length > 0) {
            arrayDipendenti = arrayDipendenti.filter(function (dipendente) {
                return dipendente.nome.toLowerCase().includes(testoFiltraDipendenti.toLowerCase())
                    || dipendente.cognome.toLowerCase().includes(testoFiltraDipendenti.toLowerCase());
            });
        } 
        if(!checkBoxFiltraScaduti){
            let dataOggi = new Date()
            arrayDipendenti = arrayDipendenti.filter(function (dipendente) {
                return dipendente.dataFineAss == null || new Date(dipendente.dataFineAss) > dataOggi
            })
        }

        setDipendentiList(arrayDipendenti)
    }, [dipendenti]);


    const onClickOpenNuovoDipendente = () => {
        setDipendenteSelected({ _id: null })
        setOpenModificaDipendente(false)
        setOpenNuovoDipendente(true)
        setOpenIncarichiDipendente(false)
    }

    const onClickOpenModificaDipendente = (idDipendente) => {
        if (idDipendente) {
            setOpenModificaDipendente(true)
            setOpenNuovoDipendente(false)
            setOpenIncarichiDipendente(false)
        }
    }

    const onClickOpenIncarichiDipendente = (idDipendente) => {
        if (idDipendente) {
            setOpenModificaDipendente(false)
            setOpenNuovoDipendente(false)
            setOpenIncarichiDipendente(true)
        }
    }


    const onChangeFiltraDipendenti = e => {
        setTestoFiltraDipendenti(e.target.value)
        let parola = e.target.value.toLowerCase()
        let arrayDipendenti = [...dipendenti];
        arrayDipendenti = dipendenti.filter(function (dipendente) {
            return dipendente.nome.toLowerCase().includes(parola)
                || dipendente.cognome.toLowerCase().includes(parola);
        });
        if(ruolo == 'organizzazione'){
            if(filtraFiliale != "tutti"){
                arrayDipendenti = filtraPerFiliale(arrayDipendenti, filtraFiliale)
            }else{
                arrayDipendenti = filtraPerFilialeTutti(arrayDipendenti)
            }
        } 
        if (arrayDipendenti.length > 0) {
            console.log(checkBoxFiltraScaduti)
            if(!checkBoxFiltraScaduti){
                let dataOggi = new Date()
                arrayDipendenti = arrayDipendenti.filter(function (dipendente) {
                    return dipendente.dataFineAss == null || new Date(dipendente.dataFineAss) > dataOggi
                })
            }
            setDipendentiList(arrayDipendenti)
        }
    };

    const onChangeFiltraDipendentiPerFiliale = e => {
        let arrayDipendenti = [...dipendenti];
        if(testoFiltraDipendenti.length > 0){
            arrayDipendenti = arrayDipendenti.filter(function (dipendente) {
                return dipendente.nome.toLowerCase().includes(testoFiltraDipendenti.toLowerCase())
                    || dipendente.cognome.toLowerCase().includes(testoFiltraDipendenti.toLowerCase());
            });
        }
        
        if(e.target.value != "tutti"){
            arrayDipendenti = filtraPerFiliale(arrayDipendenti, e.target.value)
        }else{
            arrayDipendenti = filtraPerFilialeTutti(arrayDipendenti)

        } 
        if (arrayDipendenti.length > 0) {
            if(!checkBoxFiltraScaduti){
                let dataOggi = new Date()
                arrayDipendenti = arrayDipendenti.filter(function (dipendente) {
                    return dipendente.dataFineAss == null || new Date(dipendente.dataFineAss) > dataOggi
                })
            }
            setDipendentiList(arrayDipendenti)
        }
    };

    const filtraPerFiliale = (dipendenti, filialeId) => {
        let array = dipendenti.filter(function (dipendente) {
            return dipendente.filiale._id == filialeId;
        })
        return array
    }

    const filtraPerFilialeTutti = (puntiVendita) => {
        let array;
        if (user && user.role == "organizzazione") {
            array = puntiVendita.filter(function (puntoVendita) {
                const index = user.vistaFiliali.findIndex(el => el._id == puntoVendita.filiale._id);
                if(index >= 0){
                    return true
                }else {
                    return false
                }           
            })
        }else{
            array = puntiVendita.filter(function (puntoVendita) {
                return  user.filiale._id == puntoVendita.filiale._id
            });
        }
        return array
    }

    const onChangeSetCheckboxFiltraScaduti = (e) => {
        setCheckBoxFiltraScaduti(!checkBoxFiltraScaduti)
        setTestoFiltraDipendenti("")
        let arrayDipendenti = [...dipendenti];
        if(ruolo == 'organizzazione'){
            if(filtraFiliale != "tutti"){
                arrayDipendenti = filtraPerFiliale(arrayDipendenti, filtraFiliale)
            }else{
                arrayDipendenti = filtraPerFilialeTutti(arrayDipendenti)
            } 
        }

        if(checkBoxFiltraScaduti){
            let dataOggi = new Date()
            arrayDipendenti = arrayDipendenti.filter(function (dipendente) {
                return dipendente.dataFineAss == null || new Date(dipendente.dataFineAss) > dataOggi
            })
        }
        setDipendentiList(arrayDipendenti)
    }


    return (loading ? <Spinner /> :
        <div className="body wf-section">
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`Dipendenti - Gestionale Vigili dell'ordine`}</title>
            </Helmet>
            <div className="div_wrap">
                <div className="div_wrap_100">
                    <div className="div_wrap_50">
                        <div className="div_container">
                            <div className="title_window">
                                <div className="title_window_p">Dipendenti</div>
                            </div>
                            <div className="dati_container">
                                <div className="fun_assegnazione_incarichi">
                                    <div className="row">
                                        <div className="item_1 filtro_wrap">
                                            <div id="w-node-_714e71a9-0550-fe0e-26e9-8b45ac42ab89-867f153d" className="div_item_1">
                                                <div className="w-form">
                                                    <div id="email-form" name="email-form" data-name="Email Form" method="get">
                                                        <a id="w-node-_714e71a9-0550-fe0e-26e9-8b45ac42ab90-867f153d" onClick={() => { onClickOpenNuovoDipendente() }} className="button w-button button_filtro_senza_label">Nuovo Dipendente</a>
                                                    </div>
                                                </div>
                                            </div>
                                            {ruolo == "organizzazione" || ruolo == "admin" ? 
                                            <div id="w-node-_284812ee-7924-9f38-86bb-8d2cc43dff3c-867f153d" className="div_item_1">
                                            <div id="w-node-_7b40e076-1929-a489-3ae4-e7e497d010fa-867f153d" className="label_text">Filiale</div>
                                            <div data-hover="false" data-delay="0" className="select w-dropdown">
                                                        <select className="form-control select" name="idCliente" onChange={e => onChangeFiltraDipendentiPerFiliale(e)} required>
                                                        <option selected={'tutti' == filtraFiliale} value={'tutti'} >Tutti</option>
                                                            {filialiList.map((elementoFiliale, i) => (
                                                                <option key={i} value={elementoFiliale._id} selected={elementoFiliale._id == filtraFiliale} >{elementoFiliale.nome}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                            </div>
                                            :
                                                         ''   }
                                            <div id="w-node-_7b40e076-1929-a489-3ae4-e7e497d010f9-867f153d" className="div_item_1">
                                                <div id="w-node-_7b40e076-1929-a489-3ae4-e7e497d010fa-867f153d" className="label_text">Filtra dipendenti</div>
                                                <div data-hover="false" data-delay="0" className="w-dropdown">
                                                    <input type="text" className="w-input select" maxLength="256" name="filtraDipendenti" data-name="filtraDipendenti" value={testoFiltraDipendenti} placeholder="Inserisci dipendente" id="filtraDipendenti" onChange={e => onChangeFiltraDipendenti(e)} />
                                                </div>
                                            </div>
                                            <label className="w-radio">
                                                <input type="checkbox" data-name="Radio 2" id="radio-2" name="radio" value={checkBoxFiltraScaduti} className="w-form-formradioinput w-radio-input" onChange={e => onChangeSetCheckboxFiltraScaduti(e)} checked={checkBoxFiltraScaduti} />
                                                    <span className="label_text w-form-label" htmlFor="radio-2">contratti scaduti</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="item_2">
                                            <div className="intestazione_table">
                                                <div className="w-layout-grid div_column_dipendenti intestazione">
                                                    <div className="div_item_column">
                                                        <div className="item_table">Qualifica</div>
                                                    </div>
                                                    <div className="div_item_column">
                                                        <div className="item_table">Cognome</div>
                                                    </div>
                                                    <div className="div_item_column">
                                                        <div className="item_table">Nome</div>
                                                    </div>
                                                    <div className="div_item_column">
                                                        <div className="item_table">Codice Fiscale</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="body_table">
                                                {dipendentiList.map((dipendente, i) => (
                                                    <div key={i} className={`w-layout-grid div_column_dipendenti cursor ${dipendenteSelected._id == dipendente._id ? 'dipendente_selected' : ''}`} onClick={() => setDipendenteSelected({ _id: dipendente._id })}>
                                                        <div className="div_item_column">
                                                            <div className="item_table">{dipendente.qualifica}</div>
                                                        </div>
                                                        <div className="div_item_column">
                                                            <div className="item_table">{dipendente.cognome}</div>
                                                        </div>
                                                        <div className="div_item_column">
                                                            <div className="item_table">{dipendente.nome}</div>
                                                        </div>
                                                        <div className="div_item_column">
                                                            <div className="item_table">{dipendente.codicefiscale}</div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="item_3 m_top_5">
                                            <div className="list_button">
                                                <div id="w-node-_47761b2d-160d-03bc-cd09-8911d6401437-867f153d" className="div_item_button">
                                                    <a id="w-node-_12c52e6d-210d-5526-7356-6c64cce43733-867f153d" onClick={() => (dipendenteSelected._id ? onClickOpenModificaDipendente(dipendenteSelected._id) : null)} className={`button w-button ${dipendenteSelected._id ? "" : "button_disabled"}`}>Modifica dipendente</a>
                                                </div>
                                                {vista == "scadenze" ?
                                                <div id="w-node-bf561da2-25a7-b3ef-c44c-a819295560e3-867f153d" className="div_item_empty"></div>
                                                :
                                                <div id="w-node-_47761b2d-160d-03bc-cd09-8911d6401437-867f153d" className="div_item_button">
                                                    <a id="w-node-_12c52e6d-210d-5526-7356-6c64cce43733-867f153d" onClick={() => (dipendenteSelected._id ? onClickOpenIncarichiDipendente(dipendenteSelected._id) : null)} className={`button w-button ${dipendenteSelected._id ? "" : "button_disabled"}`}>Vedi incarichi dipendente</a>
                                                </div>
                                                }
                                                <div id="w-node-bf561da2-25a7-b3ef-c44c-a819295560e3-867f153d" className="div_item_empty"></div>
                                                <div id="w-node-bf561da2-25a7-b3ef-c44c-a819295560e3-867f153d" className="div_item_empty"></div>
                                                <div id="w-node-bf561da2-25a7-b3ef-c44c-a819295560e3-867f153d" className="div_item_empty"></div>
                                                <div id="w-node-bf561da2-25a7-b3ef-c44c-a819295560e3-867f153d" className="div_item_empty"></div>
                                                <div id="w-node-bf561da2-25a7-b3ef-c44c-a819295560e3-867f153d" className="div_item_empty"></div>
                                            </div>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                    {openModificaDipendente ?
                        <div className="div_wrap_50">
                            <div className="div_container">
                                <div className="title_window">
                                    <div className="title_window_p">Modifica Dipendente</div>
                                </div>
                                <ModificaDipendente
                                    idDipendente={dipendenteSelected._id}
                                    setOpenModificaDipendente={setOpenModificaDipendente}
                                />
                            </div>
                        </div>
                        :
                        ''
                    }
                    {openNuovoDipendente ?
                        <div className="div_wrap_50">
                            <div className="div_container">
                                <div className="title_window">
                                    <div className="title_window_p">Nuovo Dipendente</div>
                                </div>
                                <NuovoDipendente
                                    openNuovoDipendente={openNuovoDipendente}
                                    setOpenNuovoDipendente={setOpenNuovoDipendente}
                                />
                            </div>
                        </div>
                        :
                        ''
                    }
                </div>
                <div className="div_wrap_100">
                {openIncarichiDipendente ?
                        <div className="div_wrap_50">
                            <div className="div_container">
                                <div className="title_window">
                                    <div className="title_window_p">Incarichi dipendente</div>
                                </div>
                                <IncarichiDipendente
                                    idDipendente={dipendenteSelected._id}
                                    setOpenIncarichiDipendente={setOpenIncarichiDipendente}
                                />
                            </div>
                        </div>
                        :
                        ''
                    }
                </div>
            </div>
        </div>
    )
}

Dipendenti.protoTypes = {
    getDipendenti: PropTypes.func.isRequired,
    getFiliali: PropTypes.func.isRequired,
    filiale: PropTypes.object.isRequired,
    dipendente: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    dipendente: state.dipendente,
    filiale: state.filiale,
    auth: state.auth
});

export default connect(mapStateToProps, { getDipendenti, getFiliali })(Dipendenti);