import axios from 'axios';
import { GET_FESTIVITA, CREA_FESTIVITA, GET_CATEGORIE_DOCUMENTI_UTENTE_ESTERNO, CREA_CATEGORIA_DOCUMENTO_UTENTE_ESTERNO, ELIMINA_FESTIVITA, IMPOSTAZIONE_ERROR } from './types';
import { setAlert } from './alert';


export const getFestivita = () => async dispatch => {
    try{
        const res = await axios.get('/api/impostazione/getFestivita');
        dispatch({
            type: GET_FESTIVITA,
            payload: res.data
        });
    }catch(err){
        dispatch({
            type: IMPOSTAZIONE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const getCategorieDocumentiUtentiEsterni = () => async dispatch => {
    try{
        const res = await axios.get('/api/impostazione/getCategorieDocumentiUtentiEsterni');
        dispatch({
            type: GET_CATEGORIE_DOCUMENTI_UTENTE_ESTERNO,
            payload: res.data
        });
    }catch(err){
        dispatch({
            type: IMPOSTAZIONE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const creaCategoriaDocumentoUtenteEsterno = ({ nome }) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ nome }); 
    try{
        const res = await axios.post('/api/impostazione/creaCategoriaDocumentoUtenteEsterno', body, config);
        dispatch(setAlert('Cartella creata con successo!', 'success'));
        dispatch({
            type: CREA_CATEGORIA_DOCUMENTO_UTENTE_ESTERNO,
            payload: res.data
        });
    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        dispatch({
            type: IMPOSTAZIONE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const creaFestivita = ({ arrayFestivita }) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ arrayFestivita });
    try{
        const res = await axios.post('/api/impostazione/creaFestivita', body, config);
        dispatch({
            type: CREA_FESTIVITA,
            payload: res.data
        });
        dispatch(setAlert('Festività creata!', 'success'));

    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        
        dispatch({
            type: IMPOSTAZIONE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const eliminaFestivita = ({ arrayFestivita }) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ arrayFestivita });
    try{
        const res = await axios.post('/api/impostazione/eliminaFestivita', body, config);
        dispatch({
            type: ELIMINA_FESTIVITA,
            payload: res.data
        });
        dispatch(setAlert('Festività eliminata!', 'success'));

    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        
        dispatch({
            type: IMPOSTAZIONE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};