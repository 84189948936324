import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { login } from '../../actions/auth';
import {Helmet} from "react-helmet";


const Login = ({ login, isAuthenticated }) => {
    const [formData, setFormData] = useState({
        username: '',
        password: ''
    });


    const { username, password } = formData;

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });
    const onSubmit = async e => {
        e.preventDefault();
        login({ username, password });
    };

    if (isAuthenticated) {
        return <Redirect to="/" />
    }


    return (
        <div class="full_screen wf-section">
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`Login - Gestionale Vigili dell'ordine`}</title>
            </Helmet>
            <div>
                <div class="container_login">
                    <div class="logo_login">
                        <img src="https://uploads-ssl.webflow.com/61f517e9104fdf48427f153c/61f55227818426cdca4de894_1502189911.png" loading="lazy" alt="" class="logo_body" />
                    </div>
                    <div class="form_container">
                        <div class="w-form">
                            <form id="email-form" name="email-form" data-name="Email Form" method="get" aria-label="Email Form" onSubmit={e => onSubmit(e)}>
                                <label for="username">Username</label>
                                <input type="text" class="input w-input" maxlength="256" name="username" value={username} data-name="username" placeholder="" id="username" onChange={e => onChange(e)} required />
                                <label for="password" class="label_form">Password</label>
                                <input type="password" class="input w-input" maxlength="256" name="password" value={password} data-name="password" placeholder="" id="password" onChange={e => onChange(e)} required />
                                <input type="submit" value="Login" data-wait="Inviando..." class="button w-button" required />
                            </form>
                            <div class="w-form-done" tabindex="-1" role="region" aria-label="Email Form success">
                                <div>Thank you! Your submission has been received!</div>
                            </div>
                            <div class="w-form-fail" tabindex="-1" role="region" aria-label="Email Form failure">
                                <div>Oops! Something went wrong while submitting the form.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

Login.propTypes = {
    login: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { login })(Login);