import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import DatePicker from 'react-date-picker';
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";
import addDays from "date-fns/addDays";
import Spinner from '../../layout/Spinner';
import {Helmet} from "react-helmet";
import disponibilitaJSON from "../../../utils/disponibilita.json"
import { useReactToPrint } from 'react-to-print';
import { getRichiesteStampaGiornate, cleanRichieste } from '../../../actions/richiesta';

const StampaGiornate = ({ auth: { user }, richiesta: { richieste }, getRichiesteStampaGiornate, cleanRichieste, alert }) => {

    const [dataInizioInput, setDataInizioInput] = useState((setHours(setMinutes(startOfWeek(new Date()), 0), 0)));
    const [dataFineInput, setDataFineInput] = useState((setHours(setMinutes(endOfWeek(new Date()), 59), 23)));


    const [elementi, setElementi] = useState([]);
    const [loadingRichieste, setLoadingRichieste] = useState(false);
    const [nomeFiliale, setNomeFiliale] = useState("");
    const [idOrganizzazione, setIdOrganizzazione] = useState("");
    const giorniArray = ['Domenica', 'Lunedi', 'Martedi', 'Mercoledi', 'Giovedi', 'Venerdi', 'Sabato'];


    useEffect(() => {
        if (user && user.filiale && user.filiale.nome) {
            setNomeFiliale(user.filiale.nome)
        }
        if (user && user.organizzazione) {
            setIdOrganizzazione(user.organizzazione._id)
        }
    }, [user]);

    useEffect(() => {
        return () => {
            cleanRichieste()
        };
    }, []);

   

    useEffect(() => {
        let array = []
        if (richieste && richieste.richieste && richieste.giorniEccezione && richieste.dipendenti && richieste.richiestePrestati && richieste.contratti  && richieste.richiesteNascoste) {
            let differenzaInGiorni = getDaysBetweenDates(dataInizioInput, dataFineInput)
            if(differenzaInGiorni > 1){
                for (let i = 0; i < differenzaInGiorni; i++) {
                    let newData = addDays(new Date(dataInizioInput), i)
                    let giorno = toIsoString(newData);
                    let giornoSettimana = new Date(newData).getDay();
                    array.push({ data: giorno, date: new Date(newData), giornoSettimana: giorniArray[giornoSettimana], richieste: [], giorniEccezione: [], richiestePrestati: [], dipendentiNonAssociati: [], contratti: [], richiesteNascoste: [] })
                }
            }else if(differenzaInGiorni <= 1){
                for (let i = 0; i <= differenzaInGiorni; i++) {
                    let newData = addDays(new Date(dataInizioInput), i)
                    let giorno = toIsoString(newData);
                    let giornoSettimana = new Date(newData).getDay();
                    array.push({ data: giorno, date: new Date(newData), giornoSettimana: giorniArray[giornoSettimana], richieste: [], giorniEccezione: [], richiestePrestati: [], dipendentiNonAssociati: [], contratti: [], richiesteNascoste: [] })
                }
            }else{
                let newDataSingola = new Date(dataInizioInput)
                let giornoSingolo = toIsoString(newDataSingola);
                let giornoSettimanaSingolo = new Date(newDataSingola).getDay();
                array.push({ data: giornoSingolo, date: newDataSingola, giornoSettimana: giorniArray[giornoSettimanaSingolo], richieste: [], giorniEccezione: [], richiestePrestati: [], dipendentiNonAssociati: [], contratti: [], richiesteNascoste: [] })
            }                
            richieste.richieste.map(el => {
                let data = toIsoString(new Date(el.dataInizio))
                let index = array.findIndex(a => { return a.data == data })
                if (index > -1) {
                    array[index].richieste.push(el)
                } 
            })
            richieste.richiesteNascoste.map(el => {
                let data = toIsoString(new Date(el.dataInizio))
                let index = array.findIndex(a => { return a.data == data })
                if (index > -1) {
                    array[index].richiesteNascoste.push(el)
                } 
            })
            richieste.contratti.map(giorno => {
                giorno.contratti.map(el => {
                    let index = array.findIndex(a => { return a.data == giorno.data })
                    if (index > -1) {
                        let indexRichiesta = array[index].richieste.findIndex(richiesta => { return String(richiesta.contratto._id) == String(el._id) })
                        if(indexRichiesta == -1){
                            let indexRichiestaNascosta = array[index].richiesteNascoste.findIndex(richiesta => { return String(richiesta.contratto._id) == String(el._id) })
                            if(indexRichiestaNascosta == -1){
                                array[index].contratti.push(el)
                            }
                        }
                    } 
                })
            })
            richieste.giorniEccezione.map(el => {
                let differenzaInGiorniEccezione = getDaysBetweenDates(new Date(el.dataInizio), new Date(el.dataFine))
                if(differenzaInGiorniEccezione <= 1){
                    let newData = new Date(el.dataInizio)
                    let giorno = toIsoString(newData);
                    let index = array.findIndex(a => { return a.data == giorno })
                    if (index > -1) {
                        array[index].giorniEccezione.push(el)
                    }
                }else{
                    for (let i = 0; i <= differenzaInGiorniEccezione; i++) {                        
                        let newData = addDays(new Date(el.dataInizio), i)
                        let giorno = toIsoString(newData);
                        let index = array.findIndex(a => { return a.data == giorno })
                        if (index > -1) {
                            array[index].giorniEccezione.push(el)
                        }
                    }
                }
            })

            

    
            richieste.richiestePrestati.map(el => {
                let data = toIsoString(new Date(el.dataInizio))
                let index = array.findIndex(a => { return a.data == data })
                if (index > -1) {
                    array[index].richiestePrestati.push(el)
                }
            })

            array.map(el => {
                richieste.dipendenti.map(dipendente => {
                    let indexRichiesta = el.richieste.findIndex(a => { return String(a.dipendente._id) == String(dipendente._id) })
                    if (indexRichiesta == -1) {
                        console.log(el.giorniEccezione)
                        let indexGiornoEccezione = el.giorniEccezione.findIndex(a => { return String(a.dipendente._id) == String(dipendente._id) })
                        if (indexGiornoEccezione == -1) {
                            let indexPrestati = el.richiestePrestati.findIndex(a => { return String(a.dipendente._id) == String(dipendente._id) })
                            if (indexPrestati == -1) {
                                el.dipendentiNonAssociati.push(dipendente)
                            }
                        }
                    }
                })
            })
            array.map(el => {
                el.dipendentiNonAssociati.map((dipendente, i) => {
                    if(dipendente.qualifica != 'impiegato'){
                        let dipsonibilita = calcolaDisponibilitaDipendente(dipendente, el.date)
                        if(dipsonibilita == "RP"){
                            let obj = {dipendente: dipendente, disponibilita: dipsonibilita}
                            el.giorniEccezione.push(obj)
                        }
                        if(dipsonibilita == "PC"){
                            let obj = {dipendente: dipendente, disponibilita: dipsonibilita}
                            el.giorniEccezione.push(obj)
                        }
                    } 
                })
            })
            array.map(el => {
                let array = []
                el.dipendentiNonAssociati.map(dipendente => {
                    if(dipendente.qualifica != 'impiegato'){
                        let index = el.giorniEccezione.findIndex(el => { return String(el.dipendente._id) == String(dipendente._id)})
                        if(index == -1){
                            array.push(dipendente)
                        }
                    } 
                })
                el.dipendentiNonAssociati = array
            })
        }
        array.map(giorno => {
            giorno.richieste.sort( function(a , b){
                if(a.puntoVendita.denominazione == b.puntoVendita.denominazione){
                    if(a.oraInizio > b.oraInizio){
                        return 1;
                    }else{
                        return -1
                    }
                }else{
                    return a.puntoVendita.denominazione > b.puntoVendita.denominazione ? 1 : -1;
                }
            });
            giorno.contratti.sort( function(a , b){
                if(a.puntoVendita.denominazione == b.puntoVendita.denominazione){
                    if(a.oraInizio > b.oraInizio){
                        return 1;
                    }else{
                        return -1
                    }
                }else{
                    return a.puntoVendita.denominazione > b.puntoVendita.denominazione ? 1 : -1;
                }
            });
        })

         //Inizio Pulizia giorni eccezione
         array.map(giorno => {
            giorno.giorniEccezione = cleanDipendentiGiorniEccezione(giorno.giorniEccezione)
        })
        array.map(giorno => {
            giorno.giorniEccezione = cleanDipendentiGiorniEccezioneRR(giorno.giorniEccezione)
        })
        //Fine Pulizia giorni eccezione

       


        array = eliminaDipendentiDataContrattoNonValida(array)
        setElementi(array)
        array = dividiGiorniEccezionePertipologia(array)
        setLoadingRichieste(false)
    }, [richieste]);

    

    useEffect(() => {
        const size = alert.length;
        if (size > 0) {
            setLoadingRichieste(false)
        }
    }, [alert]);

    const cleanDipendentiGiorniEccezione = (dipendenti) => {
        let array = []
        dipendenti.map(el => {
            let index = array.findIndex(a => { return (a.disponibilita === el.disponibilita) && (a.dipendente._id == el.dipendente._id)})
            if(index === -1){
                array.push(el)
            }
        })
        return array
    }

    const cleanDipendentiGiorniEccezioneRR = (dipendenti) => {
        let array = []
        dipendenti.map(el => {
            if(el.disponibilita == "FE"){
                let index = array.findIndex(a => { return (a.dipendente._id == el.dipendente._id)})
                    if(index === -1){
                        array.push(el)
                    }  
            }else{
                let index = array.findIndex(a => { return (a.dipendente._id == el.dipendente._id) && (a.disponibilita == 'FE')})
                    if(index === -1){
                        array.push(el)
                    }else{
                        array[index] = el
                    }
            }
        })
        return array
    }

    const eliminaDipendentiDataContrattoNonValida = (array) => {
        array.map(giorno => {
            let newArrayDipendentiNonAssociati = []
            let dataGiorno = new Date(giorno.date)
            giorno.dipendentiNonAssociati.map(el => {
                let dataDipendente = new Date(el.dataInizioAss)
                if(dataDipendente <= dataGiorno){
                    newArrayDipendentiNonAssociati.push(el)
                }
            })
            giorno.dipendentiNonAssociati = newArrayDipendentiNonAssociati
        })
        return array
    }

    const dividiGiorniEccezionePertipologia = (elementi) => {
        elementi.map(giorno => {
            let giorniEccezioneArray = []
            giorno.giorniEccezione.map(eccezione => {
                let index = giorniEccezioneArray.findIndex(el => { return el.disponibilita == eccezione.disponibilita})
                if(index >= 0){
                    giorniEccezioneArray[index].elementi.push(eccezione)
                }else{
                    let obj = {elementi: [], disponibilita: eccezione.disponibilita, label: sostituisciVociStampaGiornate(eccezione.disponibilita)}
                    obj.elementi.push(eccezione)
                    giorniEccezioneArray.push(obj)
                }
            })
            giorno.giorniEccezione = giorniEccezioneArray
        })
        return elementi
    }

    const sostituisciVociStampaGiornate = (sigla) => {
        let index = disponibilitaJSON.disponibilita.findIndex((el) => (el.codice == sigla ))
        if(index >= 0){
            return disponibilitaJSON.disponibilita[index].nome.charAt(0).toUpperCase() + disponibilitaJSON.disponibilita[index].nome.slice(1).toLocaleLowerCase();
        }else{
            return sigla
        }
    }
    
    const calcolaDisponibilitaDipendente = (dipendente, data) => {
            let disponibilita;
            let dataRichiesta = new Date(data)
            dataRichiesta.setHours(0, 0, 0)
            let dataInizioCadenza = new Date(dipendente.dataInizioCadenza)
            dataInizioCadenza.setHours(0, 0, 0)
            const diffDays = getDaysBetweenDates(dataRichiesta, dataInizioCadenza); 
           // const dif = differenceInDays(dataRichiesta, dataInizioCadenza);
            const avanzo = diffDays % dipendente.cadenza.length;
            if(avanzo < 0){
                disponibilita = dipendente.cadenza[dipendente.cadenza.length + avanzo]
            }else{
                disponibilita = dipendente.cadenza[avanzo]
            }
            if(disponibilita == 'D'){
                disponibilita = 'DI'
            }
            if(disponibilita == 'R'){
                disponibilita = 'RP'
            }
            if(disponibilita == 'C'){
                disponibilita = 'PC'
            }
            return disponibilita;
    }


    const convertMsToDays = ms => {
        const msInOneSecond = 1000
        const secondsInOneMinute = 60
        const minutesInOneHour = 60
        const hoursInOneDay = 24

        const minutesInOneDay = hoursInOneDay * minutesInOneHour
        const secondsInOneDay = secondsInOneMinute * minutesInOneDay
        const msInOneDay = msInOneSecond * secondsInOneDay

        return Math.ceil(ms / msInOneDay)
    }

    const getDaysBetweenDates = (dateOne, dateTwo) => {
        var start = new Date(dateTwo);
        var end = new Date(dateOne);
        var days = (end - start) / 1000 / 60 / 60 / 24;
        days = days - (end.getTimezoneOffset() - start.getTimezoneOffset()) / (60 * 24);
        days = Math.round(days)
        return Math.abs(days)
    }


    const toIsoString = (date) => {
        const pad = function (num) {
            return (num < 10 ? '0' : '') + num;
        };

        return pad(date.getDate()) +
            '-' + pad(date.getMonth() + 1) +
            '-' + date.getFullYear()
    }


    //FUNZIONI ONCLICK

    const onClickGeneraGiornate = () => {
        getRichiesteStampaGiornate({ dataInizio: dataInizioInput, dataFine: dataFineInput })
        setLoadingRichieste(true)
    }
    //FUNZIONI ONCHANGE

    //*
    const onChangeRangeDateInizio = (data) => {
        if(data <= dataFineInput){
            setDataInizioInput(data)
        }
        //setDataFineInput(setHours(setMinutes(endOfWeek(data), 59), 23))
    }

    //*
    const onChangeRangeDateFine = (data) => {
        if(data >= dataInizioInput){
            setDataFineInput(data)
        }
    }



    const getLogo = (idOrganizzazione) => {
        if (idOrganizzazione == '63e618049a5309e9f6f3ff9a') {
            return 'logo_emilpol.jpg'
        } else if (idOrganizzazione == '63e618279a5309e9f6f3ff9b') {
            return 'new_koop.png'
        } else {
            return 'logo_vigili_dell_ordine.png'
        }
    }

    const tableStyle = {
        width: "100%",
        border: "1px solid"
    };



    const tableInt1 = {
        fontSize: "12px",
        lineHeight: "1.3",
        background: "#8ebde6"
    };
    const tableInt2 = {
        fontSize: "12px",
        lineHeight: "1.3",
        background: "rgb(142 230 155)"
    };
    const tableInt3 = {
        fontSize: "12px",
        lineHeight: "1.3",
        background: "rgb(230 223 142)"
    };
    const tableInt4 = {
        fontSize: "12px",
        lineHeight: "1.3",
        background: "rgb(250 173 167)"
    };
    const tableInt5 = {
        fontSize: "12px",
        lineHeight: "1.3",
        background: "rgb(255 206 249)"
    };
    const tableInt6 = {
        fontSize: "12px",
        lineHeight: "1.3",
        background: "rgb(184 186 255)"
    };

    const tableInt7 = {
        fontSize: "12px",
        lineHeight: "1.3",
        background: "#dbdbdb"
    };

    const tableStyle2 = {
        textAlign: "left",
        border: "1px solid",
        width: "6%",
        padding: "2px 2px",
        wordBreak: "break-all"
    }

    const tableStyle1 = {
        textAlign: "left",
        border: "1px solid",
        width: "30%",
        padding: "2px 2px"
    }

    const tableStyle3 = {
        textAlign: "left",
        border: "1px solid",
        width: "15%",
        padding: "2px 2px"
    }

    const componentRef = useRef();

    const print = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: "giornate"
    });

    return (
        <div className="body wf-section">
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`Stampa giornate - Gestionale Vigili dell'ordine`}</title>
            </Helmet>
            <div className="div_wrap">
                <div className="div_wrap_100">
                    <div className="div_wrap_inner_100">
                        <div className="div_container">
                            <div className="title_window">
                                <div className="title_window_p">Stampa giornate</div>
                            </div>
                            <div className="dati_container">
                                <div className="fun_quadrature_ore">
                                    <div className="row row_datapicker">
                                        <div className="item_1 filtro_wrap_incarichi">
                                            <div id="w-node-_284812ee-7924-9f38-86bb-8d2cc43dff3c-867f153d" className="div_item_1">
                                                <div id="w-node-_80bb2acb-3043-3649-0498-379a652f3b04-867f153d" className="label_text">Data inizio</div>
                                                <div data-hover="false" data-delay="0" className="select w-dropdown">
                                                    <DatePicker
                                                        value={dataInizioInput}
                                                        format="dd/MM/y"
                                                        weekPicker
                                                        onChange={onChangeRangeDateInizio}
                                                        locale="it-IT"
                                                        clearIcon={null}
                                                        weekStartDayIndex={1}
                                                    />
                                                </div>
                                            </div>
                                            <div id="w-node-_284812ee-7924-9f38-86bb-8d2cc43dff3c-867f153d" className="div_item_1">
                                                <div id="w-node-_80bb2acb-3043-3649-0498-379a652f3b04-867f153d" className="label_text">Data fine</div>
                                                <div data-hover="false" data-delay="0" className="select w-dropdown">
                                                    <DatePicker
                                                        value={dataFineInput}
                                                        format="dd/MM/y"
                                                        weekPicker
                                                        onChange={onChangeRangeDateFine}
                                                        locale="it-IT"
                                                        clearIcon={null}
                                                        weekStartDayIndex={1}
                                                    />
                                                </div>
                                            </div>
                                            <div id="w-node-_714e71a9-0550-fe0e-26e9-8b45ac42ab89-867f153d" className="div_item_1">
                                                <div className="w-form">
                                                    <form id="email-form" name="email-form" data-name="Email Form" method="get">
                                                        <label className="w-checkbox checkbox-field"><span className="label_text w-form-label" htmlFor="checkbox-2"></span></label>
                                                        <a id="w-node-_714e71a9-0550-fe0e-26e9-8b45ac42ab90-867f153d" onClick={() => { onClickGeneraGiornate() }} className="button w-button">Genera giornate</a>
                                                    </form>
                                                </div>
                                            </div>
                                            <div id="w-node-_714e71a9-0550-fe0e-26e9-8b45ac42ab89-867f153d" className="div_item_1">
                                                <div className="w-form">
                                                    <form id="email-form" name="email-form" data-name="Email Form" method="get">
                                                        <label className="w-checkbox checkbox-field"><span className="label_text w-form-label" htmlFor="checkbox-2"></span></label>
                                                        <a id="w-node-_714e71a9-0550-fe0e-26e9-8b45ac42ab90-867f153d" onClick={print} className="button w-button">Stampa</a>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {loadingRichieste ? <Spinner /> :
                                    <>
                                    <div>
                                        <div className="div_container font-bold" ref={componentRef}>
                                            <div className="dati_container">
                                            <div className="page">
                                                    <div className="div_wrap_100 mt-30">
                                                        <div className="div_wrap_50">
                                                            <img src={`assets/media/logos/${getLogo(idOrganizzazione)}`} loading="lazy" alt="" className="logo_header" />
                                                            <h3>{nomeFiliale}</h3>
                                                        </div>
                                                    </div>
                                                {elementi.map((elemento, i) => (
                                                    <>
                                                    {i == 0 ? '' :
                                                    <div className="div_wrap_100">
                                                    <div className="div_wrap_50">
                                                        <img src={`assets/media/logos/${getLogo(idOrganizzazione)}`} loading="lazy" alt="" className="logo_header" />
                                                        <h3>{nomeFiliale}</h3>
                                                    </div>
                                                </div>
                                                    }
                                                    <h3>{elemento.giornoSettimana} - {elemento.data}</h3>
                                                    <h5>Servizi</h5>
                                                    <table style={tableStyle}>
                                                        <thead>
                                                            <tr style={tableInt1}>
                                                                <th style={tableStyle1}>Servizio</th>
                                                                <th style={tableStyle1}>Dipendente</th>
                                                                <th style={tableStyle3}>Ora Inizio</th>
                                                                <th style={tableStyle3}>Ora Fine.</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <>
                                                            {elemento.richieste.map(el => (
                                                                <tr style={tableStyle2}>
                                                                    <td style={tableStyle1}>{el.puntoVendita.denominazione} {el.puntoVendita.localita} - {el.puntoVendita.indirizzo}</td>
                                                                    <td style={tableStyle1}>{el.dipendente.cognome} {el.dipendente.nome}</td>
                                                                    <td style={tableStyle3}>{el.oraInizio}</td>
                                                                    <td style={tableStyle3}>{el.oraFine}</td>
                                                                </tr>
                                                            ))}
                                                            {elemento.contratti.map(el => (
                                                                <tr style={tableStyle2}>
                                                                    <td style={tableStyle1}>{el.puntoVendita.denominazione} {el.puntoVendita.localita} - {el.puntoVendita.indirizzo}</td>
                                                                    <td style={tableStyle1}></td>
                                                                    <td style={tableStyle3}>{el.oraInizio}</td>
                                                                    <td style={tableStyle3}>{el.oraFine}</td>
                                                                </tr>
                                                            ))}
                                                            </>
                                                        </tbody>
                                                    </table>
                                                    {elemento.dipendentiNonAssociati.length > 0 ?
                                                    <>
                                                    <h5>Disponibile</h5>
                                                    <table style={tableStyle}>
                                                        <thead>
                                                            <tr style={tableInt2}>
                                                                <th style={tableStyle1}></th>
                                                                <th style={tableStyle1}>Dipendente</th>
                                                                <th style={tableStyle3}></th>
                                                                <th style={tableStyle3}></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {elemento.dipendentiNonAssociati.map(el => (
                                                                el.qualifica !== 'impiegato' ?
                                                                <tr style={tableStyle2}>
                                                                    <td style={tableStyle1}>Disponibile</td>
                                                                    <td style={tableStyle1}>{el.cognome} {el.nome}</td>
                                                                    <td style={tableStyle3}></td>
                                                                    <td style={tableStyle3}></td>
                                                                </tr>
                                                                :
                                                                ''
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                    </>
                                                    :
                                                    ''
                                                    }
                                                    {elemento.giorniEccezione.map(eccezione => (
                                                    eccezione.disponibilita == 'MA' && eccezione.elementi.length > 0 ?
                                                    <>
                                                    <h5>Malattia</h5>
                                                    <table style={tableStyle}>
                                                        <thead>
                                                            <tr style={tableInt4}>
                                                                <th style={tableStyle1}></th>
                                                                <th style={tableStyle1}>Dipendente</th>
                                                                <th style={tableStyle3}></th>
                                                                <th style={tableStyle3}></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {eccezione.elementi.map(el => (
                                                                <tr style={tableStyle2}>
                                                                    <td style={tableStyle1}>Malattia</td>
                                                                    <td style={tableStyle1}>{el.dipendente.cognome} {el.dipendente.nome}</td>
                                                                    <td style={tableStyle3}></td>
                                                                    <td style={tableStyle3}></td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                    </>
                                                    :
                                                    ''
                                                    ))}
                                                    {elemento.giorniEccezione.map(eccezione => (
                                                    eccezione.disponibilita == 'RP' && eccezione.elementi.length > 0 ?
                                                    <>
                                                    <h5>Riposo</h5>
                                                    <table style={tableStyle}>
                                                        <thead>
                                                            <tr style={tableInt5}>
                                                                <th style={tableStyle1}></th>
                                                                <th style={tableStyle1}>Dipendente</th>
                                                                <th style={tableStyle3}></th>
                                                                <th style={tableStyle3}></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {eccezione.elementi.map(el => (
                                                                <tr style={tableStyle2}>
                                                                    <td style={tableStyle1}>Riposo</td>
                                                                    <td style={tableStyle1}>{el.dipendente.cognome} {el.dipendente.nome}</td>
                                                                    <td style={tableStyle3}></td>
                                                                    <td style={tableStyle3}></td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                    </>
                                                    :
                                                    ''
                                                    ))}
                                                    {elemento.giorniEccezione.map(eccezione => (
                                                    eccezione.disponibilita == 'RR' && eccezione.elementi.length > 0 ?
                                                    <>
                                                    <h5>Recupero riposo</h5>
                                                    <table style={tableStyle}>
                                                        <thead>
                                                            <tr style={tableInt6}>
                                                                <th style={tableStyle1}></th>
                                                                <th style={tableStyle1}>Dipendente</th>
                                                                <th style={tableStyle3}></th>
                                                                <th style={tableStyle3}></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {eccezione.elementi.map(el => (
                                                                <tr style={tableStyle2}>
                                                                    <td style={tableStyle1}>Recupero riposo</td>
                                                                    <td style={tableStyle1}>{el.dipendente.cognome} {el.dipendente.nome}</td>
                                                                    <td style={tableStyle3}></td>
                                                                    <td style={tableStyle3}></td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                    </>
                                                    :
                                                    ''
                                                    ))}
                                                   {elemento.giorniEccezione.map(eccezione => (
                                                    eccezione.disponibilita == 'FE' && eccezione.elementi.length > 0 ?
                                                    <>
                                                    <h5>Ferie</h5>
                                                    <table style={tableStyle}>
                                                        <thead>
                                                            <tr style={tableInt6}>
                                                                <th style={tableStyle1}></th>
                                                                <th style={tableStyle1}>Dipendente</th>
                                                                <th style={tableStyle3}></th>
                                                                <th style={tableStyle3}></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {eccezione.elementi.map(el => (
                                                                <tr style={tableStyle2}>
                                                                    <td style={tableStyle1}>Ferie</td>
                                                                    <td style={tableStyle1}>{el.dipendente.cognome} {el.dipendente.nome}</td>
                                                                    <td style={tableStyle3}></td>
                                                                    <td style={tableStyle3}></td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                    </>
                                                    :
                                                    ''
                                                    ))}
                                                    {elemento.giorniEccezione.map(eccezione => (
                                                                eccezione.disponibilita != 'FE' && eccezione.disponibilita != 'MA' && eccezione.disponibilita != 'RP' && eccezione.disponibilita != 'RR' ?
                                                                <>
                                                                <h5>{eccezione.label}</h5>
                                                                <table style={tableStyle}>
                                                                    <thead>
                                                                        <tr style={tableInt7}>
                                                                            <th style={tableStyle1}></th>
                                                                            <th style={tableStyle1}>Dipendente</th>
                                                                            <th style={tableStyle3}></th>
                                                                            <th style={tableStyle3}></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {eccezione.elementi.map(el => (
                                                                        <tr style={tableStyle2}>
                                                                            <td style={tableStyle1}>{sostituisciVociStampaGiornate(el.disponibilita)}</td>
                                                                            <td style={tableStyle1}>{el.dipendente.cognome} {el.dipendente.nome}</td>
                                                                            <td style={tableStyle3}></td>
                                                                            <td style={tableStyle3}></td>
                                                                        </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                                </>
                                                                :
                                                                ''
                                                            ))}
                                                    <div class="pagebreak mb-30"> </div>
                                                    </>
                                                ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

StampaGiornate.protoTypes = {
    getRichiesteStampaGiornate: PropTypes.func.isRequired,
    cleanRichieste: PropTypes.func.isRequired,
    richiesta: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    alert: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    richiesta: state.richiesta,
    auth: state.auth,
    alert: state.alert
});

export default connect(mapStateToProps, { getRichiesteStampaGiornate, cleanRichieste })(StampaGiornate);