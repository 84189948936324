import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Moment from 'react-moment'
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import Spinner from '../layout/Spinner';

const DashboardUtenteEsterno = ({ auth: {user, loading}}) => {

    const [documentiUtenteEsterno, setDocumentiUtenteEsterno] = useState([]);

    //*
    useEffect(() => {
        if(user && user.documenti.length > 0){
            setDocumentiUtenteEsterno(user.documenti)
        }
    }, [user]);



    return (loading ? <Spinner /> :
        <div className="body wf-section">
            <div className="div_wrap">
                <div className="div_wrap_100">
                    <div className="div_wrap_50">
                        <div className="div_container">
                            <div className="title_window">
                                <div className="title_window_p">Documenti</div>
                            </div>
                            <div className="dati_container">
                                <div className="fun_assegnazione_incarichi">
                                    <div className="row">
                                        <div className="item_2">
                                            <div className="intestazione_table">
                                                <div className="w-layout-grid div_column_alert_documenti_esterni intestazione">
                                                    <div className="div_item_column">
                                                        <div className="item_table">Data</div>
                                                    </div>
                                                    <div className="div_item_column">
                                                        <div className="item_table">Tipologia</div>
                                                    </div>
                                                    <div className="div_item_column">
                                                        <div className="item_table">Nome</div>
                                                    </div>
                                                    <div className="div_item_column">
                                                        <div className="item_table">Link</div>
                                                    </div>
                                                </div>
                                            </div>
                                                <div className="body_table">
                                                    {documentiUtenteEsterno.map((documento, i) => (
                                                        <div key={"documento" + i} className={`w-layout-grid div_column_alert_documenti_esterni cursor`}>
                                                            <div className="div_item_column">
                                                                <div className="item_table"><Moment format='DD/MM/YYYY'>{documento.upload_date}</Moment></div>
                                                            </div>
                                                            <div className="div_item_column">
                                                                <div className="item_table">Documento amministrativo</div>
                                                            </div>
                                                            <div className="div_item_column">
                                                                <div className="item_table">{documento && documento.nome}</div>
                                                            </div>
                                                            <div className="div_item_column">
                                                                <div className="item_table"><a href={documento && documento.url} target="_blank">apri documento</a></div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="item_3 m_top_5">
                                            <div className="list_button list_button_2">
                                                <div id="w-node-bf561da2-25a7-b3ef-c44c-a819295560e3-867f153d" className="div_item_empty"></div>
                                                <div id="w-node-bf561da2-25a7-b3ef-c44c-a819295560e3-867f153d" className="div_item_empty"></div>
                                                <div id="w-node-bf561da2-25a7-b3ef-c44c-a819295560e3-867f153d" className="div_item_empty"></div>
                                                <div id="w-node-bf561da2-25a7-b3ef-c44c-a819295560e3-867f153d" className="div_item_empty"></div>
                                                <div id="w-node-bf561da2-25a7-b3ef-c44c-a819295560e3-867f153d" className="div_item_empty"></div>
                                                <div id="w-node-bf561da2-25a7-b3ef-c44c-a819295560e3-867f153d" className="div_item_empty"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

DashboardUtenteEsterno.propTypes = {
    auth: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, { })(DashboardUtenteEsterno);