import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import Moment from 'react-moment'
const PDFStampa = ({ auth: { user }, incarichi }) => {

    const [loadingData, setLoadingData] = useState(false);

    const tableStyle = {
        width: "100%",
        border: "1px solid"
    };

    const tableStyleIndennita = {
        width: "100%",
        border: "1px solid",
        marginTop: "10px"
    };

    const tableStyleNote = {
        width: "100%",
        border: "1px solid",
        marginTop: "10px"
    };

    const tableInt = {
        fontSize: "12px",
        lineHeight: "1.3"
    };

    const tableStyle2 = {
        textAlign: "center",
        border: "1px solid",
        width: "6%",
        padding: "2px 2px",
        wordBreak: "break-all"
    }

    const tableStyle3 = {
        textAlign: "center",
        border: "1px solid",
        width: "4%",
        padding: "2px 2px"
    }
    const tableStyle4 = {
        textAlign: "center",
        border: "1px solid",
        width: "2.5%",
        padding: "2px 2px"
    }

    const tableStyle5 = {
        textAlign: "center",
        border: "1px solid",
        width: "19%",
        padding: "2px 2px"
    }

    const tableStyleIndennitaVoce = {
        textAlign: "center",
        border: "1px solid",
        width: "10%",
        padding: "2px 2px"
    }

    const tableStyleIndennitaOreGiorni = {
        textAlign: "center",
        border: "1px solid",
        width: "5%",
        padding: "2px 2px"
    }

    const tableStyleIndennitaImporto = {
        textAlign: "center",
        border: "1px solid",
        width: "15%",
        padding: "2px 2px"
    }

    const tableStyleIndennitaNote = {
        textAlign: "center",
        border: "1px solid",
        width: "60%",
        padding: "2px 2px"
    }


    const calcolaStraordinari48 = (incarico) => {
        let minutiStraordinari = fromStringToMinuti(incarico.oreStraordinarie)
        let diffSumMinTotInt;
        let minutiNotte = 0;
        if (minutiStraordinari > 0) {
            if (incarico.sumMinTotInt > 2880) {
                diffSumMinTotInt = incarico.sumMinTotInt - minutiStraordinari
                if (diffSumMinTotInt < 2880) {
                    let calcoloStr2 = incarico.sumMinTotInt - 2880
                    let calcoloStr1 = 2880 - diffSumMinTotInt
                    return { primaColonna: fromMinutiToOreString(calcoloStr1), secondaColonna: fromMinutiToOreString(calcoloStr2) }
                } else {
                    return { primaColonna: "", secondaColonna: fromMinutiToOreString(minutiStraordinari) }
                }
            } else {
                return { primaColonna: fromMinutiToOreString(minutiStraordinari), secondaColonna: "" }
            }
        } else {
            return { primaColonna: "", secondaColonna: "" }
        }
    }

    const fromStringToMinuti = (oreString) => {
        var minuti = getMinutiInt(oreString);
        var ore = getOreInt(oreString) * 60
        return ore + minuti
    }

    const fromMinutiToOreString = (minutiTotali) => {
        if (minutiTotali > 0) {
            var minuti = minutiTotali % 60;
            var ore = (minutiTotali - minuti) / 60;
            if (ore == 0 && minuti == 0) {
                return "00:00"
            } else {
                return formattaOre(ore) + ":" + formattaMinuti(minuti)
            }
        } else {
            return "00:00"
        }
    }

    const calcoloTotaliOre = (incarichi, field) => {
        let hours = 0, minutes = 0;
        incarichi.forEach((incarico) => {
            let split;
            if(field == "flessibilita"){
                let flessNum = incarico.flessibilita ? incarico.flessibilita : 0
                let fless = fromMinutiToOreString(flessNum)
                split = fless.split(':');
                hours += parseInt(split[0]);
                minutes += parseInt(split[1]);

            }else
            if (incarico[field]) {
                split = incarico[field].split(':');
                hours += parseInt(split[0]);
                minutes += parseInt(split[1]);
            }
        });
        hours += Math.floor(minutes / 60);
        minutes = minutes % 60;
        if (hours == 0 && minutes == 0) {
            return "00:00"
        } else {
            return hours + ':' + formattedNumber(minutes)
        }
    }

    const formattedNumber = (num) => ("0" + num).slice(-2);


    const getFirstLetterOfDay = (day) => {
        switch (day) {
            case 0:
                day = "D";
                break;
            case 1:
                day = "L";
                break;
            case 2:
                day = "M";
                break;
            case 3:
                day = "M";
                break;
            case 4:
                day = "G";
                break;
            case 5:
                day = "V";
                break;
            case 6:
                day = "S";
        }
        return day
    }

    const findColonnaPersonalizzata1 = (array) => {
        let arrayColonnePersonallizate = [];
        array.incarichi.map((incarico) => {
            if (incarico.oreSciopero) {
                if (!arrayColonnePersonallizate.includes("oreSciopero")) {
                    arrayColonnePersonallizate.push("oreSciopero")
                }
            }
            if (incarico.oreAllattamento) {
                if (!arrayColonnePersonallizate.includes("oreAllattamento")) {
                    arrayColonnePersonallizate.push("oreAllattamento")
                }
            }
            if (incarico.permessiL104) {
                if (!arrayColonnePersonallizate.includes("permessiL104")) {
                    arrayColonnePersonallizate.push("permessiL104")
                }
            }
            if (incarico.oreROLGoduti) {
                if (!arrayColonnePersonallizate.includes("oreROLGoduti")) {
                    arrayColonnePersonallizate.push("oreROLGoduti")
                }
            }
            if (incarico.bancaOreGoduta) {
                if (!arrayColonnePersonallizate.includes("bancaOreGoduta")) {
                    arrayColonnePersonallizate.push("bancaOreGoduta")
                }
            }
        }
        )
        if (arrayColonnePersonallizate.length == 0) {
            arrayColonnePersonallizate.push("")
        }
        if (arrayColonnePersonallizate.length == 1) {
            arrayColonnePersonallizate.push("")
        }
        return arrayColonnePersonallizate
    }
   

    const calcoloOreLavorateString = (incarichi) => {
        let hours = 0, minutes = 0;
        incarichi.forEach((incarico) => {
            if (incarico.ore) {
                const split = incarico.ore.split(':');
                hours += parseInt(split[0]);
                minutes += parseInt(split[1]);
            }
        });
        hours += Math.floor(minutes / 60);
        minutes = minutes % 60;
        return formattaOre(hours) + ':' + formattaMinuti(minutes)
    }

    const formattaOre = (ore) => {
        return (ore <= 9 ? "0" : "") + ore
    }

    const formattaMinuti = (minuti) => {
        return (minuti <= 9 ? "0" : "") + minuti;
    }


    const getLogo = (idOrganizzazione) => {
		if(idOrganizzazione == '63e618049a5309e9f6f3ff9a'){
			return 'logo_emilpol.jpg'
		}else if(idOrganizzazione == '63e618279a5309e9f6f3ff9b'){
			return 'new_koop.png'
		}else{
			return 'logo_vigili_dell_ordine.png'
		}
	}

    const calcoloTotaliOreStrOTS = (incarichi, field) => {
        let somma = 0;
        incarichi.forEach((incarico) => {
            let minutiStraordinari = fromStringToMinuti(incarico.oreStraordinarie)
            let diffSumMinTotInt;
            if (minutiStraordinari > 0) {
                if (field == 'oreStraordinarieNotte') {
                    if (incarico.oreStraordinarieNotteOTS.length > 0) {
                        somma = somma + fromStringToMinuti(incarico.oreStraordinarieNotteOTS)
                    }
                } else {
                    if (incarico.sumMinTotInt > 2880) {
                        diffSumMinTotInt = incarico.sumMinTotInt - minutiStraordinari
                        if (diffSumMinTotInt < 2880) {
                            let calcoloStr2 = incarico.sumMinTotInt - 2880
                            let calcoloStr1 = 2880 - diffSumMinTotInt
                            if (field == 'oreStraordinarieMin48') {
                                somma = somma + calcoloStr1
                            }
                            if (field == 'oreStraordinarieMax48') {
                                somma = somma + calcoloStr2
                            }
                        } else {
                            if (field == 'oreStraordinarieMax48') {
                                somma = somma + minutiStraordinari
                            }
                        }
                    } else {
                        if (field == 'oreStraordinarieMin48') {
                            somma = somma + minutiStraordinari
                        }
                    }
                }

            }
        });
        if (somma == 0) {
            return "00:00"
        } else {
            return fromMinutiToOreString(somma)
        }
    }

    const getMinutiInt = (ore) => {
        if (ore && ore.length > 0) {
            const split = ore.split(':');
            return parseFloat(split[1])
        } else {
            return 0
        }
    }

    const getOreInt = (ore) => {
        if (ore && ore.length > 0) {
            const split = ore.split(':');
            return parseInt(split[0])
        } else {
            return 0
        }
    }

    const calcoloGiorniLavorati = (incarichi) => {
        let giorni = 0;
        incarichi.forEach((incarico) => {
            if (incarico.effettivo == "AS") {
                if (!incarico.multiplo || (incarico.multiplo && incarico.isLastMultiplo)) {
                    giorni = giorni + 1
                }
            }
        });
        return giorni
    }
    

    return (
        incarichi.length > 0 ?
        incarichi.map((elemento, i) => (
                <div className="div_container ">
                    <div className="dati_container">
                        <div className="page">
                            <div className="div_wrap_100">
                                <div className="div_wrap_50">
                                    <img src={`assets/media/logos/${getLogo(user.organizzazione._id)}`} loading="lazy" alt="" className="logo_header" />
                                    <h5>{user.filiale.nome}</h5>
                                </div>
                                <div className="div_wrap_50">
                                            <h4>{elemento.dipendente.cognome} {elemento.dipendente.nome} - mat. {elemento.dipendente.matricola}</h4>
                                            <h5>Cod. Fisc: {elemento.dipendente.codicefiscale}</h5>
                                            <h5>Incarichi di <Moment format='MMMM'>{elemento.dataInizio}</Moment></h5>
                                        </div>
                            </div>
                            <table style={tableStyle}>
                            {elemento.dipendente.qualifica == 'ots' ?
                                        <thead>
                                        <tr style={tableInt}>
                                            <th style={tableStyle4}></th>
                                            <th style={tableStyle4}></th>
                                            <th style={tableStyle3}>Dis.</th>
                                            <th style={tableStyle3}>Eff.</th>
                                            <th style={tableStyle2}>Dalle</th>
                                            <th style={tableStyle2}>Alle</th>
                                            <th style={tableStyle2}>Ore<br />ordinarie<br />120</th>
                                            <th style={tableStyle2}>Ore<br />str. 1.<br />570</th>
                                            <th style={tableStyle2}>Ore<br />str. 2<br />351</th>
                                            <th style={tableStyle2}>Ore str.<br />notte<br />578</th>
                                            <th style={tableStyle2}>Ore str<br />fest. giorno<br />572</th>
                                            <th style={tableStyle2}>Ore str<br />fest. notte<br />577</th>
                                            <th style={tableStyle2}>Dom.<br />giorno<br />890</th>
                                            <th style={tableStyle2}>Dom.<br />notte<br />891</th>
                                            <th style={tableStyle2}>Ore sup.<br />part-time<br />072</th>
                                            <th style={tableStyle2}>Fless.</th>
                                            <th style={tableStyle2}>Ore<br />Non Lav.</th>
                                            <th style={tableStyle2}>{findColonnaPersonalizzata1(elemento)[0]}</th>
                                        </tr>
                                    </thead>
                                        :
                                        <thead>
                                                <tr style={tableInt}>
                                                    <th style={tableStyle4}></th>
                                                    <th style={tableStyle4}></th>
                                                    <th style={tableStyle3}>Dis.</th>
                                                    <th style={tableStyle3}>Eff.</th>
                                                    <th style={tableStyle2}>Dalle</th>
                                                    <th style={tableStyle2}>Alle</th>
                                                    <th style={tableStyle2}>Ore<br />ordinarie<br />120</th>
                                                    <th style={tableStyle2}>BancaOre<br />Retrib.<br />159</th>
                                                    <th style={tableStyle2}>BancaOre<br />da Fru.<br />160</th>
                                                    <th style={tableStyle2}>Ore Str.<br />082</th>
                                                    <th style={tableStyle2}>Ore<br />Str.Fest.<br />053</th>
                                                    <th style={tableStyle2}>Ore<br />Str.Rip.Sp<br />014</th>
                                                    <th style={tableStyle2}>Ind.<br />Dom.<br />061</th>
                                                    <th style={tableStyle2}>Ore sup.<br />part-time</th>
                                                    <th style={tableStyle2}>Fless.</th>
                                                    <th style={tableStyle2}>Ore<br />Non Lav.</th>
                                                    <th style={tableStyle2}>{findColonnaPersonalizzata1(elemento)[0]}</th>
                                                    <th style={tableStyle2}>{findColonnaPersonalizzata1(elemento)[1]}</th>
                                                </tr>
                                            </thead>
                                        }
                                <tbody>
                                {elemento.incarichi.map((el, i) => (
                                                elemento.dipendente.qualifica == 'ots' ?
                                                <tr style={tableStyle2} className={el.isLastMultiplo ? "grey" : ""}>
                                                        <td style={tableStyle4}>{getFirstLetterOfDay(parseInt(el.giornoSettimana))}</td>
                                                        <td style={tableStyle4}>{el.data.split('-')[0]}</td>
                                                        <td style={tableStyle3}>{el.disponibilita}</td>
                                                        <td style={tableStyle3}>{el.effettivo}</td>
                                                        <td style={tableStyle2}>{el.oraInizio}</td>
                                                        <td style={tableStyle2}>{el.oraFine}</td>
                                                        <td style={tableStyle2}>{el.oreOrdinarie}</td>
                                                        {elemento.calcoloStrDivisoOTS == true ? 
                                                        <>
                                                        <td style={tableStyle2}>{el.oreStraordinarieOTS1}</td>
                                                        <td style={tableStyle2}>{el.oreStraordinarieOTS2}</td>
                                                        </>
                                                        :
                                                        <>
                                                        <td style={tableStyle2}>{calcolaStraordinari48(el).primaColonna}</td>
                                                        <td style={tableStyle2}>{calcolaStraordinari48(el).secondaColonna}</td>
                                                        </>
                                                        }
                                                        {false ? <td style={tableStyle2}>{el.sumMinStrInt > 2880 ? null : el.oreStraordinarie}</td>: ''}
                                                        {false ? <td style={tableStyle2}>{el.sumMinStrInt > 2880 ? el.oreStraordinarie : null}</td>: ''}
                                                        <td style={tableStyle2}>{el.oreStraordinarieNotteOTS}</td>
                                                        <td style={tableStyle2}>{el.oreStraordinarieFestiveOTSGiorno}</td>
                                                        <td style={tableStyle2}>{el.oreStraordinarieFestiveOTSNotte}</td>
                                                        <td style={tableStyle2}>{el.indennitaDomenicaleOTSGiorno}</td>
                                                        <td style={tableStyle2}>{el.indennitaDomenicaleOTSNotte}</td>
                                                        <td style={tableStyle2}>{el.oreSupPartTime}</td>
                                                        <td style={tableStyle2}>{el.flessibilita}</td>
                                                        <td style={tableStyle2}>{el.oreNonLavorate}</td>
                                                        <td style={tableStyle2}>{el[findColonnaPersonalizzata1(elemento)[0]]}</td>

                                                    </tr>
                                                :
                                                <tr style={tableStyle2} className={el.isLastMultiplo ? "grey" : ""}>
                                                        <td style={tableStyle4}>{getFirstLetterOfDay(parseInt(el.giornoSettimana))}</td>
                                                        <td style={tableStyle4}>{el.data.split('-')[0]}</td>
                                                        <td style={tableStyle3}>{el.disponibilita}</td>
                                                        <td style={tableStyle3}>{el.effettivo}</td>
                                                        <td style={tableStyle2}>{el.oraInizio}</td>
                                                        <td style={tableStyle2}>{el.oraFine}</td>
                                                        <td style={tableStyle2}>{el.oreOrdinarie}</td>
                                                        <td style={tableStyle2}>{el.bancaOreRetrib}</td>
                                                        <td style={tableStyle2}>{el.bancaOreDaFruire}</td>
                                                        <td style={tableStyle2}>{el.oreStraordinarie}</td>
                                                        <td style={tableStyle2}>{el.oreStraordinarieFestive}</td>
                                                        <td style={tableStyle2}>{el.oreStrRipSp}</td>
                                                        <td style={tableStyle2}>{el.indennitaDomenicale}</td>
                                                        <td style={tableStyle2}>{el.oreSupPartTime}</td>
                                                        <td style={tableStyle2}>{el.flessibilita}</td>
                                                        <td style={tableStyle2}>{el.oreNonLavorate}</td>
                                                        <td style={tableStyle2}>{el[findColonnaPersonalizzata1(elemento)[0]]}</td>
                                                        <td style={tableStyle2}>{el[findColonnaPersonalizzata1(elemento)[1]]}</td>

                                                    </tr>
                                            ))}
                                </tbody>
                            </table>
                            <span></span>
                            <table style={tableStyle}>
                            {elemento.dipendente.qualifica == 'ots' ?
                                        <thead>
                                            <tr style={tableStyle2}>
                                                    <th style={tableStyle5}>TOTALE ORE:</th>
                                                    <th style={tableStyle2}>{calcoloOreLavorateString(elemento.incarichi)}</th>
                                                    <th style={tableStyle2}>{calcoloTotaliOre(elemento.incarichi, "oreOrdinarie")}</th>
                                                    {elemento.calcoloStrDivisoOTS == true ? 
                                                        <>
                                                        <th style={tableStyle2}>{calcoloTotaliOre(elemento.incarichi, "oreStraordinarieOTS1")}</th>
                                                        <th style={tableStyle2}>{calcoloTotaliOre(elemento.incarichi, "oreStraordinarieOTS2")}</th>
                                                        </>
                                                        :
                                                        <>
                                                        <th style={tableStyle2}>{calcoloTotaliOreStrOTS(elemento.incarichi, "oreStraordinarieMin48")}</th>
                                                        <th style={tableStyle2}>{calcoloTotaliOreStrOTS(elemento.incarichi, "oreStraordinarieMax48")}</th>
                                                        </>
                                                        }
                                                    <th style={tableStyle2}>{calcoloTotaliOreStrOTS(elemento.incarichi, "oreStraordinarieNotte")}</th>
                                                    <th style={tableStyle2}>{calcoloTotaliOre(elemento.incarichi, "oreStraordinarieFestiveOTSGiorno")}</th>
                                                    <th style={tableStyle2}>{calcoloTotaliOre(elemento.incarichi, "oreStraordinarieFestiveOTSNotte")}</th>
                                                    <th style={tableStyle2}>{calcoloTotaliOre(elemento.incarichi, "indennitaDomenicaleOTSGiorno")}</th>
                                                    <th style={tableStyle2}>{calcoloTotaliOre(elemento.incarichi, "indennitaDomenicaleOTSNotte")}</th>
                                                    <th style={tableStyle2}>{calcoloTotaliOre(elemento.incarichi, "oreSupPartTime")}</th>
                                                    <th style={tableStyle2}>{calcoloTotaliOre(elemento.incarichi, "flessibilita")}</th>
                                                    <th style={tableStyle2}>{calcoloTotaliOre(elemento.incarichi, "oreNonLavorate")}</th>
                                                    <th style={tableStyle2}>{calcoloTotaliOre(elemento.incarichi, findColonnaPersonalizzata1(elemento)[0])}</th>
                                                </tr>
                                        </thead>
                                        :
                                        <thead>
                                            <tr style={tableStyle2}>
                                                    <th style={tableStyle5}>TOTALE ORE:</th>
                                                    <th style={tableStyle2}>{calcoloOreLavorateString(elemento.incarichi)}</th>
                                                    <th style={tableStyle2}>{calcoloTotaliOre(elemento.incarichi, "oreOrdinarie")}</th>
                                                    <th style={tableStyle2}>{calcoloTotaliOre(elemento.incarichi, "bancaOreRetrib")}</th>
                                                    <th style={tableStyle2}>{calcoloTotaliOre(elemento.incarichi, "bancaOreDaFruire")}</th>
                                                    <th style={tableStyle2}>{calcoloTotaliOre(elemento.incarichi, "oreStraordinarie")}</th>
                                                    <th style={tableStyle2}>{calcoloTotaliOre(elemento.incarichi, "oreStraordinarieFestive")}</th>
                                                    <th style={tableStyle2}>{calcoloTotaliOre(elemento.incarichi, "oreStrRipSp")}</th>
                                                    <th style={tableStyle2}>{calcoloTotaliOre(elemento.incarichi, "indennitaDomenicale")}</th>
                                                    <th style={tableStyle2}>{calcoloTotaliOre(elemento.incarichi, "oreSupPartTime")}</th>
                                                    <th style={tableStyle2}>{calcoloTotaliOre(elemento.incarichi, "flessibilita")}</th>
                                                    <th style={tableStyle2}>{calcoloTotaliOre(elemento.incarichi, "oreNonLavorate")}</th>
                                                    <th style={tableStyle2}>{calcoloTotaliOre(elemento.incarichi, findColonnaPersonalizzata1(elemento)[0])}</th>
                                                    <th style={tableStyle2}>{calcoloTotaliOre(elemento.incarichi, findColonnaPersonalizzata1(elemento)[1])}</th>
                                                </tr>
                                        </thead>
                                        }
                            </table>
                            <table style={tableStyle}>
                                        <thead>
                                            <tr style={tableStyle2}>
                                                <th style={tableStyle5}>TOTALE GIORNI:</th>
                                                <th style={tableStyle2}>{calcoloGiorniLavorati(elemento.incarichi)}</th>
                                                <th style={tableStyle2}></th>
                                                <th style={tableStyle2}></th>
                                                <th style={tableStyle2}></th>
                                                <th style={tableStyle2}></th>
                                                <th style={tableStyle2}></th>
                                                <th style={tableStyle2}></th>
                                                <th style={tableStyle2}></th>
                                                <th style={tableStyle2}></th>
                                                <th style={tableStyle2}></th>
                                                <th style={tableStyle2}></th>
                                                <th style={tableStyle2}></th>
                                                <th style={tableStyle2}></th>
                                            </tr>
                                        </thead>
                                    </table>
                                    <div class="pagebreak"> </div>
                                    <div className="div_wrap_100 headerPrint">
                                        <div className="div_wrap_50">
                                        <img src={`assets/media/logos/${getLogo(user.organizzazione._id)}`} loading="lazy" alt="" className="logo_header" />
                                            <h5>{user.filiale.nome}</h5>
                                        </div>
                                        <div className="div_wrap_50">
                                            <h4>{elemento.dipendente.cognome} {elemento.dipendente.nome} - mat. {elemento.dipendente.matricola}</h4>
                                            <h5>Cod. Fisc: {elemento.dipendente.codicefiscale}</h5>
                                            <h5>Incarichi di <Moment format='MMMM'>{elemento.dataInizio}</Moment></h5>
                                        </div>
                                    </div>
                                    <div className="div_wrap_100">
                                        <div className="div_wrap_50">
                                            <div className="div_container minimal first">
                                                <table style={tableStyleIndennita}>
                                                    <thead>
                                                        <tr style={tableInt}>
                                                            <th style={tableStyleIndennitaVoce}>Voce</th>
                                                            <th style={tableStyleIndennitaOreGiorni}>Ore/Giorni</th>
                                                            <th style={tableStyleIndennitaImporto}>Importo</th>
                                                            <th style={tableStyleIndennitaNote}>Annotazioni</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {elemento.indennita.map((el, i) => (
                                                            el ?
                                                            <>
                                                            {i <= 16 ?
                                                                <tr style={tableStyle2}>
                                                                    <td style={tableStyleIndennitaVoce}>{el.codice}</td>
                                                                    <td style={tableStyleIndennitaOreGiorni}>{el.modalita == 'ore' ? el.oreGiorni > 0 ? (Number(el.oreGiorni) / 60).toFixed(2) : 0 : el.oreGiorni}</td>
                                                                    <td style={tableStyleIndennitaImporto}>{el.importo}</td>
                                                                    <td style={tableStyleIndennitaNote}>{el.annotazioni}</td>
                                                                </tr>
                                                                :
                                                                ''
                                                        }
                                                            </>
                                                            :
                                                            ''
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="div_wrap_50">
                                            <div className="div_container minimal last">
                                                <table style={tableStyleIndennita}>
                                                    <thead>
                                                        <tr style={tableInt}>
                                                            <th style={tableStyleIndennitaVoce}>Voce</th>
                                                            <th style={tableStyleIndennitaOreGiorni}>Ore/Giorni</th>
                                                            <th style={tableStyleIndennitaImporto}>Importo</th>
                                                            <th style={tableStyleIndennitaNote}>Annotazioni</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {elemento.indennita.map((el, i) => (
                                                            el ?
                                                            <>
                                                            {i > 16 ?
                                                                <tr style={tableStyle2}>
                                                                    <td style={tableStyleIndennitaVoce}>{el.codice}</td>
                                                                    <td style={tableStyleIndennitaOreGiorni}>{el.modalita == 'ore' ? el.oreGiorni > 0 ? (Number(el.oreGiorni) / 60).toFixed(2) : 0 : el.oreGiorni}</td>
                                                                    <td style={tableStyleIndennitaImporto}>{el.importo}</td>
                                                                    <td style={tableStyleIndennitaNote}>{el.annotazioni}</td>
                                                                </tr>
                                                                :
                                                                ''
                                                        }
                                                            </>
                                                            :
                                                            ''
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <table style={tableStyleNote}>
                                        <thead>
                                            <tr style={{ textAlign: "left" }}>
                                                <th>NOTE: <span style={{ fontWeight: "400" }}>{elemento.note}</span></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div class="pagebreak"> </div>
                        </div>
                    </div>
                </div>
            ))

            :
            ''
        
    )
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, {  })(PDFStampa);