import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Spinner from '../../layout/Spinner';
import { getFiliale, modificaFiliale } from '../../../actions/filiale';
const ModificaFiliale = ({ filiale: { filiale, loading }, alert, getFiliale, modificaFiliale, idFiliale, setModificaFiliale}) => {

    useEffect(() => {
        const size = alert.length;
        if(size>0){
            if(alert[size-1].alertType === "success"){
                setModificaFiliale(false);
            }
        }
    }, [setModificaFiliale, alert]);

    useEffect(() => {
        getFiliale(idFiliale);
    }, [getFiliale, idFiliale]);

    const filialeInitialState = {
        nome: '',
        indirizzo: ''
       }
    const [datiFiliale, setDatiFiliale] = useState(filialeInitialState);

    useEffect(() => {
        setDatiFiliale({
            nome: loading || !filiale ? '' : filiale.nome,
            indirizzo: loading || !filiale ? '' : filiale.indirizzo
        });
    }, [filiale, loading]);
    const { nome, indirizzo } = datiFiliale;


    const onChangesetDatiFiliale = e => setDatiFiliale({ ...datiFiliale, [e.target.name]: e.target.value });

    const onSubmitModificaFiliale = async e => {
        e.preventDefault();
        modificaFiliale(idFiliale, nome, indirizzo );
     };

    return ( loading ? <Spinner /> :
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card card-custom example example-compact">
                                <div className="card-header">
                                    <h3 className="card-title">
                                        Modifica Filiale
				                    </h3>
                                </div>
                                <form className="form" onSubmit= {e => onSubmitModificaFiliale(e)} >
                                    <div className="card-body">
                                        <h3 className="font-size-lg text-dark font-weight-bold mb-6">Dati filiale:</h3>
                                        <div className="mb-15">
                                            <div className="form-group row">
                                                <div className="col-lg-6">
							                        <label>Nome:</label>
							                        <input type="text" className="form-control" name="nome" value={nome} onChange={e => onChangesetDatiFiliale(e)} placeholder="Inserisci nome"/>
						                        </div>
                                                <div className="col-lg-6">
						                            <label>indirizzo:</label>
						                            <input type="text" className="form-control" name="indirizzo" value={indirizzo} onChange={e => onChangesetDatiFiliale(e)} placeholder="Inserisci indirizzo"/>
					                            </div>
                                        </div>
                                    </div>
                                    <div className="card-footer">
                                        <div className="row">
                                        <div className="col-lg-6 text-left">
                                                <p onClick={() => setModificaFiliale(false)} className="btn btn-secondary mr-2">Chiudi</p>
                                            </div>
                                            <div className="col-lg-6 text-right">
                                                <button type="submit" className="btn btn-success mr-2">Modifica filiale</button>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
    )
}

ModificaFiliale.protoTypes = {
    getFiliale: PropTypes.func.isRequired,
    modificaFiliale: PropTypes.func.isRequired,
    filiale: PropTypes.object.isRequired,
    alert: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    filiale: state.filiale,
    alert: state.alert
});

export default connect(mapStateToProps, { getFiliale, modificaFiliale })(ModificaFiliale);

