// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBM0lnujX4zkhB9rt6-8PFSY9DQzm6q5Mk",
  authDomain: "vigilanza-savona-storage.firebaseapp.com",
  projectId: "vigilanza-savona-storage",
  storageBucket: "vigilanza-savona-storage.appspot.com",
  messagingSenderId: "115968080196",
  appId: "1:115968080196:web:0deabc45a87827718f7f11"
};
// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);