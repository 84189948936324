import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf'
import DatePicker from 'react-date-picker';
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import Spinner from '../../layout/Spinner';
import { useReactToPrint } from 'react-to-print';
import PDFStampa from './PDFStampa';
import { getIncarichiTuttiDipendentiSalvati, cleanIncarichiPDFDipendenti } from '../../../actions/dipendente';
const InvioTuttiDipendentiPDF = ({ dipendente: { incarichiDipendenteSalvati }, alert, setOpenStampaTuttiDipendentiPDF, getIncarichiTuttiDipendentiSalvati, cleanIncarichiPDFDipendenti }) => {

    const [dataInizioInput, setDataInizioInput] = useState((setHours(setMinutes(startOfMonth(new Date()), 0), 0)));
    const [dataFineInput, setDataFineInput] = useState((setHours(setMinutes(endOfMonth(new Date()), 59), 23)));
    const [loadingData, setLoadingData] = useState(false);
    const [arrayIncarichiDipendentiSalvati, setArrayIncarichiDipendentiSalvati] = useState([]);


    const componentRef = useRef();

    useEffect(() => {
        if (incarichiDipendenteSalvati) {
            incarichiDipendenteSalvati.sort( function(a , b){
                if(a.dipendente.cognome == b.dipendente.denominazione){
                    if(a.dipendente.nome > b.dipendente.nome){
                        return 1;
                    }else{
                        return -1
                    }
                }else{
                    return a.dipendente.cognome > b.dipendente.cognome ? 1 : -1;
                }
            });
            setArrayIncarichiDipendentiSalvati(incarichiDipendenteSalvati)
        }
    }, [incarichiDipendenteSalvati]);

    useEffect(() => {
        const size = alert.length;
        if (size > 0) {
            if (alert[size - 1].alertType === "success" && alert[size - 1].field == 'tutti_incarichi_dipendenti_salvati') {
                setLoadingData(false);
            }
            if (alert[size - 1].alertType === "error" && alert[size - 1].field == 'nessuno_incarico_dipendenti_salvato') {
                setLoadingData(false);
            }
        }
    }, [alert]);


    const print = useReactToPrint({
        content: () => componentRef.current
    });



    const onChangeSetNuovaDataInput = (e) => {
        cleanIncarichiPDFDipendenti()
        if (e.target.name == 'dataInizio') {
            setDataInizioInput(e.target.value)
            setDataFineInput(setHours(setMinutes(endOfMonth(e.target.value), 59), 23))
        } else {
            setDataFineInput(e.target.value)
        }
    }

    const onClickStampaTuttiDipendentiPDF = () => {
        getIncarichiTuttiDipendentiSalvati({ dataInizio: dataInizioInput, dataFine: dataFineInput })
        setLoadingData(true)
    }

    const printRef = useRef([]);

    const handleDownloadPdf = async () => {
        let elements = printRef.current;
        const pdf = new jsPDF();
        let canvas;
        let data;
        let imgProperties;
        let pdfWidth = pdf.internal.pageSize.getWidth();
        let pdfHeight;
        elements = elements.filter(el => { return el })
        elements = elements.filter((c, index) => {
            return elements.indexOf(c) === index;
        });
        for (let i = 0; i < elements.length; i++) {
            canvas = await html2canvas(elements[i]);
            data = canvas.toDataURL('image/png');
            imgProperties = pdf.getImageProperties(data);
            pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
            pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight);
            if (i < elements.length) {
                pdf.addPage();
            }
        }
        pdf.save('print.pdf');
    };

    return (
        <div className="div_wrap_50">
            <div className="div_container">
                <div className="title_window">
                    <div className="title_window_p">PDF tutti dipendenti</div>
                </div>
                <div className="dati_container assenze">
                    <div className="fun_assegnazione_incarichi">
                        <div className="row">
                            <div className="item_2">
                                <h4>Stampa PDF tutti i dipendenti</h4>
                                <div>
                                    <div className="w-form">
                                        <form id="email-form-2" name="email-form-2" data-name="Email Form 2" method="get" aria-label="Email Form 2">
                                            {loadingData ? <Spinner /> :
                                                <div className="div_wrap_100">
                                                    <div className="div_wrap_25">
                                                        <div className="col_input"><label htmlFor="name-11">Data inizio</label>
                                                            <div data-hover="false" data-delay="0" className="select w-dropdown">
                                                                <DatePicker
                                                                    onChange={(value) => onChangeSetNuovaDataInput({ target: { value: value, name: 'dataInizio' } })}
                                                                    value={dataInizioInput}
                                                                    //calendarIcon={null}
                                                                    clearIcon={null}
                                                                    className={'form-control'}
                                                                //disableCalendar={true}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="div_wrap_25">
                                                        <div className="col_input"><label htmlFor="name-11">Data fine</label>
                                                            <div data-hover="false" data-delay="0" className="select w-dropdown">
                                                                <DatePicker
                                                                    onChange={(value) => onChangeSetNuovaDataInput({ target: { value: value, name: 'dataFine' } })}
                                                                    value={dataFineInput}
                                                                    //calendarIcon={null}
                                                                    clearIcon={null}
                                                                    className={'form-control'}
                                                                //disableCalendar={true}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="div_wrap_25">
                                                    </div>
                                                    <div className="div_wrap_25">
                                                    </div>
                                                </div>
                                            }
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="item_3 m_top_5">
                                <div className="list_button list_button_2">
                                    <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc43-b8dac9c0" className="div_item_button">
                                        <a id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc44-b8dac9c0" onClick={() => setOpenStampaTuttiDipendentiPDF(false)} className="button w-button red">Chiudi</a>
                                    </div>
                                    <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc3d-b8dac9c0" className="div_item_empty"></div>
                                    <div id="w-node-d27a7e1c-7034-f43d-edeb-5fcab2bbc790-b8dac9c0" className="div_item_empty"></div>
                                    <div id="w-node-d27a7e1c-7034-f43d-edeb-5fcab2bbc790-b8dac9c0" className="div_item_empty"></div>
                                    <div id="w-node-d27a7e1c-7034-f43d-edeb-5fcab2bbc790-b8dac9c0" className="div_item_empty"></div>
                                    {arrayIncarichiDipendentiSalvati.length > 0 ?
                                        <div id="w-node-d409c487-9a0c-6b1f-803c-c7c11a8afa3e-b8dac9c0" className="div_item_button">
                                            <a onClick={() => print()} className="button w-button">Scarica</a>
                                        </div>
                                        :
                                        <div id="w-node-_50eb0eee-bc22-e398-1b25-daa2065fcc40-b8dac9c0" className="div_item_empty"></div>
                                    }
                                    <div id="w-node-d409c487-9a0c-6b1f-803c-c7c11a8afa3e-b8dac9c0" className="div_item_button">
                                        <a onClick={() => onClickStampaTuttiDipendentiPDF()} className="button w-button">Genera PDF</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div ref={componentRef} className="hide">
                <PDFStampa
                    incarichi={arrayIncarichiDipendentiSalvati}
                />
            </div>

        </div>
    )
}

InvioTuttiDipendentiPDF.protoTypes = {
    dipendente: PropTypes.object.isRequired,
    alert: PropTypes.object.isRequired,
    getIncarichiTuttiDipendentiSalvati: PropTypes.func.isRequired,
    cleanIncarichiPDFDipendenti: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    dipendente: state.dipendente,
    alert: state.alert
});

export default connect(mapStateToProps, { getIncarichiTuttiDipendentiSalvati, cleanIncarichiPDFDipendenti })(InvioTuttiDipendentiPDF);